<template>
  <div class="importDialog">
    <el-dialog :title="$t('messages.import')" :visible.sync="importDialogVisible" :modal="false" :close-on-click-modal="false" :before-close="importDialogClose">

      <el-form :model="importForm" :rules="rules" ref="importForm" class="demo-ruleForm">
        <div class="inputArea">
          <span class="inputInfo">{{$t('messages.itemBelong')}}:</span>
          <el-form-item prop="form_projectRadio">
            <div class="radioArea">
              <el-radio v-model="importForm.form_projectRadio" label="1" @change="importProjectBelong">{{$t('messages.person')}}</el-radio>
              <el-radio v-model="importForm.form_projectRadio" label="2" @change="importProjectBelong">{{$t('messages.team')}}</el-radio>
            </div>
          </el-form-item>
        </div>

        <div class="inputArea">
          <span class="inputInfo">{{$t('messages.userAndTeamName')}}:</span>
          <el-form-item prop="form_projectRadio" v-if="importForm.form_projectRadio==='1'">
            <el-select class="userSelect" v-model="importForm.form_userName" filterable :placeholder="$t('messages.placeholder_0')" disabled>
              <el-option v-for="item in options_userName" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="form_projectRadio" v-if="importForm.form_projectRadio==='2'">
            <el-select v-model="importForm.form_teamName" :placeholder="$t('messages.placeholder_0')" @change="teamNameSelect">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name" :value="item.snow_id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="inputArea">
          <span class="inputInfo">{{$t('messages.projectName')}}:</span>
          <!-- <i class="el-icon-arrow-down projectNameIcon" ref="projectNameIcon"></i> -->
          <el-form-item prop="form_projectName" ref="projectNameRule" v-if="importForm.form_projectRadio==='1'">
            <el-select v-model="importForm.form_projectName" :placeholder="$t('messages.placeholder_0')" @change="userProNameSelect" @focus="getUserProName">
              <el-option v-for="item in options_userProName" :key="item.project_gns" :label="item.value" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="form_projectName" ref="projectNameRule" v-if="importForm.form_projectRadio==='2'">
            <el-select v-model="importForm.form_projectName" :placeholder="$t('messages.placeholder_0')" @change="teamProNameSelect" @focus="getTeamProjectName">
              <el-option v-for="item in options_teamProName" :key="item.project_gns" :label="item.value" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <!-- 上传 -->
        <div class="uploadArea">
          <el-upload name="importBtn" ref="importBtn" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-change="uploadFile" :auto-upload="false" :limit="1" :multiple="false">
            <span class="inputInfo" style="margin-right:40px">{{$t('messages.uploadFile')}}:</span>
            <el-button size="small" type="primary">{{$t('messages.upload')}}</el-button>
          </el-upload>
        </div>

        <!-- 版图/原理图名称 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t('messages.fileName')}}:</span>
          <el-form-item prop="form_fileName" ref="fileNameRule">
            <el-input class="inline-input" v-model="importForm.form_fileName" :placeholder="$t('messages.placeholder_1')" :maxlength="50"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <span slot="footer" class="dialog-footer">

        <el-button type="primary" @click="importDialogSureBtn" :loading="importLoading" size="mini" class="deactivate" ref="importDialogBtn" disabled>{{$t("messages.sure")}}</el-button>
        <el-button @click="importDialogCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import {
  disableBtn,
  activateBtn,
  binaryDataToUTF8,
  getUserProNameList,
  getTeamProjectNameList,
  fileImport,
  schemaImport,
} from "./function/fileListPublicFn.js";
import {
  batchCreateCellFn,
  normalFileCopyTo,
} from "./function/fileListApiFn.js";
import {
  getObjectData,
  batchGetObjectDataFn,
  getCurrentBoardFolderName,
  downloadFileData,
  // getCompleteProjectData
} from "./function/objectStorageFn";
export default {
  data() {
    let projectNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else if (!/^[\s&()~+-_\[\]0-9a-zA-Z\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else {
        callback();
      }
    };
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    return {
      importLoading: false,
      cellNames: [],
      importDialogVisible: false,
      importType: "1",
      importProject: {},
      importForm: {
        form_projectRadio: "1",
        form_teamName: "",
        form_userName: "",
        form_projectName: "",
        form_fileName: "",
      },

      options_userName: [],
      options_teamName: [],
      options_userProName: [],
      options_teamProName: [],

      //规则检查
      rules: {
        form_projectName: [{ validator: projectNameRule, trigger: "blur" }],
        form_fileName: [{ validator: fileNameRule, trigger: "blur" }],
      },

      userId: "", //个人id
      user_gns: "", //个人GNS
      team_gns: "", //团队GNS
      teamId: "", //团队id
      project_gns: "", //项目GNS
      file_gns: "", //文件GNS
      select_project: null,
    };
  },
  mounted() {
    bus.$on("importDialog", (data) => {
      this.importType = data;
      this.importDialogVisible = true;
      this.importForm.form_userName = localStorage.getItem("userName");
      this.userId = localStorage.getItem("userId");
      this.options_teamName = this.$store.state.teamNameList;
    });
  },
  methods: {
    ImportDialogBtnActive() {
      if (this.importDialogVisible) {
        if (
          (this.importForm.form_userName !== "" ||
            this.importForm.form_teamName !== "") &&
          this.importForm.form_projectName !== "" &&
          this.importForm.form_fileName !== ""
        ) {
          activateBtn(this.$refs.importDialogBtn);
        } else {
          disableBtn(this.$refs.importDialogBtn);
        }
      }
    },
    //重置导入页面
    resetImportDialog() {
      this.$refs.importForm.resetFields();
      this.$refs.importBtn.clearFiles();
    },

    //导入页面关闭
    importDialogClose() {
      this.importDialogVisible = false;
      this.resetImportDialog();
    },

    //设置表单数据
    setFormTeam(teamName, teamId, team_gns) {
      this.importForm.form_teamName = teamName;
      this.teamId = teamId;
      this.team_gns = team_gns;
    },

    setFormProject(projectRadio, projectName, projectGns) {
      this.importForm.form_projectRadio = projectRadio;
      this.importForm.form_projectName = projectName;
      this.project_gns = projectGns;
    },

    setFormFile(fileName, fileGns) {
      this.importForm.form_fileName = fileName;
      this.file_gns = fileGns;
    },

    //项目所属
    importProjectBelong() {
      if (this.importForm.form_projectRadio === "1") {
        this.value_userName = this.userName;
      } else if (this.importForm.form_projectRadio === "2") {
        this.value_teamName = this.teamName;
      }
      this.importForm.form_projectName = "";
      this.importForm.form_fileName = "";
      this.$refs.importBtn.clearFiles();
    },

    //团队选择
    teamNameSelect(data) {
      this.options_teamName.forEach((item) => {
        if (item.snow_id === data) {
          this.setFormTeam(item.name, item.snow_id, item.gns);
          this.setFormProject(this.importForm.form_projectRadio, "", "");
          this.setFormFile("", "");
        }
      });
    },

    //获取个人项目列表
    async getUserProName() {
      this.options_userProName = await getUserProNameList(0, 50);
    },

    //切换个人项目选择
    userProNameSelect(data) {
      let index = this.options_userProName.findIndex(
        (item) => item.value === data
      );
      this.project_gns = this.options_userProName[index].project_gns;
      this.select_project = this.options_userProName[index];
    },

    async getTeamProjectName() {
      this.options_teamProName = await getTeamProjectNameList(
        0,
        50,
        this.importForm.form_teamName,
        this.teamId,
        this.team_gns
      );
    },

    //切换团队项目选择
    teamProNameSelect(data) {
      let index = this.options_teamProName.findIndex(
        (item) => item.value === data
      );
      this.project_gns = this.options_teamProName[index].project_gns;
      this.select_project = this.options_teamProName[index];
    },

    //上传文件
    async uploadFile(file) {
      let fileName = file.name.split(".")[0];
      this.importForm.form_fileName = fileName;
      // let reader = new FileReader();
      // reader.readAsArrayBuffer(file);
      // reader.onload = function (e) {
      //   const data_u8 = new Uint8Array(reader.result);

      if (this.importType == "1") {
        // let id = "1";
        // window.QGdstk.FS.writeFile(id, data_u8);
        // this.Qlib = window.QGdstk.read_gds(id);
        // expand_repetition(this.Qlib);
        // this.cellNames = this.Qlib.cells.map((c) => c.name);
      } else if (this.importType == "2") {
      } else if (this.importType == "3") {
      }
    },

    getExpandData(file_lib) {
      let topData = {
        topId: this.importForm.form_projectRadio == "2" ? this.teamId : "",
        isTeam: this.importForm.form_projectRadio == "2" ? true : false,
      };
      let fileData = {
        name: this.importForm.form_fileName,
        snow_id: file_lib.snow_id,
        gns: `${this.project_gns}/${file_lib.snow_id}`,
        isTeam: this.importForm.form_projectRadio == "2",
      };
      let projectData = {
        gns: this.project_gns,
        isTeam: this.importForm.form_projectRadio == "2",
        name: this.select_project.value,
        snow_id:
          this.project_gns.split("/")[this.project_gns.split("/").length - 1],
      };
      let category = "文件";
      return [{ category: category, topData, projectData, fileData }];
    },

    //导入确认
    async importDialogSureBtn() {
      this.$refs.importForm.validate(async (valid) => {
        if (valid) {
          let topID =
            this.importForm.form_projectRadio == "2" ? this.teamId : "";
          let isTeam = this.importForm.form_projectRadio == "2" ? true : false;
          if (this.importType == "1") {
            this.importLoading = true;
            bus.$emit("screen_lock", true);
            let files = this.$refs.importBtn.uploadFiles[0];
            if (files.size / 1024 / 1024 > 25) {
              this.$message.error(this.$t("messages.importGDSSizeError"));
              bus.$emit("screen_lock", false);
              this.importLoading = false;
              return;
            }
            let reader = new FileReader();
            reader.readAsArrayBuffer(files.raw);
            reader.onload = async (e) => {
              // 读取文件内容
              const fileBuffer = e.target.result;
              let data_u8 = new Uint8Array(fileBuffer);
              Kernel.FS.writeFile(this.importForm.form_fileName, data_u8);
              let LibraryFile = new Kernel.Library(
                this.importForm.form_fileName
              );
              LibraryFile.read_gds(this.importForm.form_fileName);

              // 接下来可对文件内容进行处理
              try {
                let import_status = await fileImport(
                  LibraryFile,
                  this.project_gns,
                  this.importForm.form_fileName,
                  "LAYOUT_FILE",
                  topID,
                  isTeam
                );
                if (import_status) {
                  this.importLoading = false;
                  bus.$emit("screen_lock", false);
                  // this.importDialogVisible = false;
                  this.$message.success(this.$t("messages.operationSuccess"));
                  // bus.$emit("refreshNode", this.project_gns.split("gns://")[this.project_gns.split("gns://").length - 1])
                  // 刷新Node树
                  let expandData = this.getExpandData(LibraryFile);
                  bus.$emit("refreshNode", expandData);
                  this.importDialogClose();
                } else {
                  this.importLoading = false;
                  bus.$emit("screen_lock", false);
                  this.$message.error(this.$t("messages.operationFailed"));
                }
              } catch (e) {
                this.importLoading = false;
                bus.$emit("screen_lock", false);
                this.$message.error(this.$t("messages.operationFailed"));
              }
            };
          } else if (this.importType == "3") {
            this.importLoading = true;
            bus.$emit("screen_lock", true);
            let files = this.$refs.importBtn.uploadFiles[0];
            if (files.size / 1024 / 1024 > 5) {
              this.$message.error(this.$t("messages.importSymbolSizeError"));
              bus.$emit("screen_lock", false);
              this.importLoading = false;
              return;
            }
            let reader = new FileReader();
            reader.readAsArrayBuffer(files.raw);
            reader.onload = async (e) => {
              // 读取文件内容
              const fileBuffer = e.target.result;
              let data_u8 = new Uint8Array(fileBuffer);
              let schemaFile =
                Kernel.parse_file(data_u8)[0].deep_copy_logic()[0];
              // // 接下来可对文件内容进行处理
              try {
                let import_status = await schemaImport(
                  schemaFile,
                  this.project_gns,
                  this.importForm.form_fileName,
                  this.importType,
                  topID,
                  isTeam
                );
                if (import_status) {
                  this.importLoading = false;
                  bus.$emit("screen_lock", false);
                  this.$message.success(this.$t("messages.operationSuccess"));
                  // 刷新Node树
                  let expandData = this.getExpandData(schemaFile);
                  bus.$emit("refreshNode", expandData);
                  this.importDialogClose();
                } else {
                  this.importLoading = false;
                  bus.$emit("screen_lock", false);
                  this.$message.error(this.$t("messages.operationFailed"));
                }
              } catch {
                this.importLoading = false;
                bus.$emit("screen_lock", false);
                this.$message.error(this.$t("messages.operationFailed"));
              }
            };
          } else if (this.importType == "2") {
            this.importLoading = true;
            bus.$emit("screen_lock", true);
            let files = this.$refs.importBtn.uploadFiles[0];

            if (files.size / 1024 / 1024 > 2) {
              this.$message.error(this.$t("messages.importDXFSizeError"));
              bus.$emit("screen_lock", false);
              this.importLoading = false;
              return;
            }

            let reader = new FileReader();
            reader.readAsArrayBuffer(files.raw);
            reader.onload = async (e) => {
              // 读取文件内容
              const fileBuffer = e.target.result;
              let data_u8 = new Uint8Array(fileBuffer);

              Kernel.FS.writeFile(this.importForm.form_fileName, data_u8);

              let dxfConvertor = new Kernel.DxfConvertor();
              let dxfData = dxfConvertor.read_dxf(
                this.importForm.form_fileName,
                "topCell"
              );

              let LibraryFile = new Kernel.Library("");
              for (let i = 0; i < dxfData.size(); i++) {
                LibraryFile.add_cell(dxfData.get(i));
              }

              try {
                let import_status = await fileImport(
                  LibraryFile,
                  this.project_gns,
                  this.importForm.form_fileName,
                  "DXF_FILE",
                  topID,
                  isTeam
                );
                if (import_status) {
                  this.importLoading = false;
                  bus.$emit("screen_lock", false);
                  // this.importDialogVisible = false;

                  this.$message.success(this.$t("messages.operationSuccess"));
                  // bus.$emit("refreshNode", this.project_gns.split("gns://")[this.project_gns.split("gns://").length - 1])
                  // 刷新Node树
                  let expandData = this.getExpandData(LibraryFile);
                  bus.$emit("refreshNode", expandData);
                  this.importDialogClose();
                } else {
                  this.importLoading = false;
                  bus.$emit("screen_lock", false);
                  this.$message.error(this.$t("messages.operationFailed"));
                }
              } catch (e) {
                this.importLoading = false;
                bus.$emit("screen_lock", false);
                this.$message.error(this.$t("messages.operationFailed"));
              }
            };
          }
        }
      });
    },

    //导入取消
    importDialogCancelButton() {
      this.importDialogVisible = false;
      this.resetImportDialog();
    },

  },
  watch: {
    "importForm.form_userName"(val) {
      this.ImportDialogBtnActive();
    },
    "importForm.form_teamName"(val) {
      this.ImportDialogBtnActive();
    },
    "importForm.form_projectName"(val) {
      this.ImportDialogBtnActive();
    },
    "importForm.form_fileName"(val) {
      this.ImportDialogBtnActive();
    },
  },
};
</script>

<style lang="less" scoped>
.importDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 376px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .userSelect {
      /deep/.el-input__inner {
        color: #c0c4cc !important;
      }
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .projectNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .fileNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
    }
  }
  .uploadArea {
    width: 100%;
    min-height: 60px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    .upload-demo {
      width: 100%;
      /deep/.el-upload-list__item-name {
        margin-left: 120px;
      }
    }
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>