<template>
  <div style="height: 100%">
    <div class="file">
      <!-- 上半 -->
      <el-button v-if="createButtonVisible" :key="0" type="primary" plain class="createButton" @click="headerCreate">
        <i class="iconfont icon-xinjian"></i>
        {{ $t('messages.new') }}
      </el-button>
      <div class="top-half" ref="topHalf" style="max-height: 850px">
        <el-tree v-if="treeVisible" :key="1" class="tree-line" :indent="0" node-key="snow_id" ref="tree"
          highlight-current :props="props" @node-contextmenu="rightClick" @node-click="fileListNodeClick"
          :load="loadNode" lazy :expand-on-click-node="false">
          <!-- 文件列表状态记录 -->
          <!-- <el-tree v-if="treeVisible" :key="1" class="tree-line" :indent="0" node-key="snow_id" ref="tree" highlight-current :props="props" @node-contextmenu="rightClick" @node-click="fileListNodeClick" :load="loadNode" lazy :expand-on-click-node="false" @node-expand="nodeExpand" @node-collapse="nodeCollapse" :default-expanded-keys="expandedList"> -->
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <img class="treeIcon" :src="treeNodeIcon(node)" />
            <span @dblclick="dblclickFn(data, node)" v-if="!isWork && !node.data.showInput"
              @contextmenu.prevent="onContextmenu($event, node)">
              {{ node.label }}&nbsp;
              <span v-if="node.level == 4 && node.data.cell_type == '5'">{{ node.data.epschemaName }}</span>
            </span>
            <span @dblclick="dblclickFn(data, node)" v-if="isWork">
              {{ node.label }}&nbsp;
              <span v-if="node.level == 4 && node.data.cell_type == '5'">{{ node.data.epschemaName }}</span>
            </span>
            <el-input class="resetNameInput" ref="resetNameInput" v-if="node.data.showInput" v-model="node.data.name"
              @keyup.enter.native="resetNameEnter($event)" @blur="resetNameBlur(node)"
              @focus="resetNameFocus($event, node)" :autofocus="true"></el-input>
          </span>
        </el-tree>
      </div>
    </div>
    <!-- 新建弹窗 -->
    <div class="newProjectDialog">
      <el-dialog :modal="false" :title="$t('messages.new')" :visible.sync="newDialogVisible"
        :close-on-click-modal="false" :before-close="newDialogClose">
        <el-form :model="newProjectRuleForm" :rules="rules" ref="newProjectRuleForm" class="demo-ruleForm">
          <!-- 项目归属 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.itemBelong') }}:</span>
            <div class="radioArea">
              <el-radio v-model="projectRadio" label="1" @change="projectBelong()"
                :disabled="userListRClick ? false : true">{{ $t('messages.person') }}</el-radio>
              <el-radio v-model="projectRadio" label="2" @change="projectBelong()"
                :disabled="teamListRClick ? false : true">{{ $t('messages.team') }}</el-radio>
            </div>
          </div>

          <!-- 用户/团队名 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.userAndTeamName') }}:</span>
            <el-select class="userSelect" v-if="projectRadio === '1'" v-model="value_userName" filterable
              :placeholder="$t('messages.personName')" disabled>
              <el-option v-for="item in options_userName" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
            <el-select v-else-if="projectRadio === '2'" v-model="value_teamName" :placeholder="$t('messages.teamName')"
              @change="teamNameSelect" :disabled="fileCreate ? false : true">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name"
                :value="item.snow_id"></el-option>
            </el-select>
          </div>

          <!-- 项目名 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.projectName') }}:</span>
            <i class="el-icon-arrow-down projectNameIcon" ref="projectNameIcon"></i>
            <el-form-item prop="form_projectName" ref="projectNameRule">
              <el-autocomplete @focus="projectNameFocus" @blur="projectNameBlur" class="inline-input"
                v-model="newProjectRuleForm.form_projectName" :fetch-suggestions="projectNameSearch"
                :placeholder="$t('messages.pleaseEnterProjectName')" @select="handleSelectProName"
                :maxlength="50"></el-autocomplete>
            </el-form-item>
          </div>

          <!-- 文件类型 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.fileType') }}:</span>
            <div class="radioArea">
              <el-radio v-model="fileRadio" label="1"
                @change="changeFileType">{{ $t('messages.LayoutFile') }}</el-radio>
              <el-radio v-model="fileRadio" label="3"
                @change="changeFileType">{{ $t('messages.SchematicFile') }}</el-radio>
            </div>
          </div>

          <!-- 文件名 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.fileName') }}:</span>
            <i class="el-icon-arrow-down fileNameIcon" ref="fileNameIcon"></i>
            <el-form-item prop="form_fileName" ref="fileNameRule">
              <el-autocomplete @focus="fileNameFocus" @blur="fileNameBlur" class="inline-input"
                v-model="newProjectRuleForm.form_fileName" :fetch-suggestions="fileNameSearch"
                :placeholder="$t('messages.pleaseEnterFileName')" @select="handleSelectFileName"
                :maxlength="50"></el-autocomplete>
            </el-form-item>
          </div>

          <!-- 单元类型 -->
          <div class="inputArea" v-if="fileRadio === '1'">
            <span class="inputInfo">{{ $t('messages.cellType') }}:</span>
            <div class="radioArea">
              <el-radio v-model="unitRadio" label="1" @change="changeCellType">{{ $t('messages.fileCell') }}</el-radio>
              <el-radio v-model="unitRadio" label="2"
                @change="changeCellType">{{ $t('messages.componentCell') }}</el-radio>
            </div>
          </div>

          <!-- 版图单元名称 -->
          <div class="inputArea" v-if="fileRadio === '1'">
            <span class="inputInfo">{{ $t('messages.cellName') }}:</span>
            <el-form-item prop="form_cellName">
              <el-input v-model="newProjectRuleForm.form_cellName" :placeholder="$t('messages.pleaseEnterCellName')"
                :maxlength="50"></el-input>
            </el-form-item>
          </div>

          <!-- 原理图名称 -->
          <div class="inputArea" v-if="fileRadio === '3'">
            <span class="inputInfo">{{ $t('messages.SchematicName') }}:</span>
            <el-form-item prop="form_schematicName">
              <el-input v-model="newProjectRuleForm.form_schematicName"
                :placeholder="$t('messages.pleaseEnterSchematicName')" :maxlength="50"></el-input>
            </el-form-item>
          </div>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="deactivate" ref="newDialogBtn" @click="newDialogSureButton" size="mini"
            disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="newDialogCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 删除弹窗 -->
    <div class="deleteWindow">
      <el-dialog :modal="false" :title="$t('messages.Warning')" :visible.sync="delDialogVisible"
        :close-on-click-modal="false">
        <div class="info">
          <i style="width: 22px; height: 22px; padding-right: 10px; color: #ec3838; font-size: 22px"
            class="iconfont icon-jinggao"></i>
          <span>{{ $t('messages.powerTips_22') }}</span>
          <br />
        </div>
        <span class="deleteName">{{ deleteName }}</span>
        <span slot="footer" class="dialog-footer">
          <el-checkbox v-model="userKnowChecked" @change="userKnow">{{ $t('messages.powerTips_13') }}</el-checkbox>
          <div>
            <el-button type="primary" class="deactivate" ref="sureBtn" @click="delDialogSureButton" disabled
              size="mini">{{ $t('messages.sure') }}</el-button>
            <el-button @click="delDialogVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
    <!-- 存到器件库弹窗 -->
    <div class="saveToLibraryDialog">
      <el-dialog :modal="false" :title="$t('messages.SavetoLibrary')" :visible.sync="saveToLibraryDialogVisible"
        :close-on-click-modal="false" :before-close="saveToLibraryDialogClose">
        <el-form :model="saveToLibraryForm" :rules="rules" ref="saveToLibraryForm" class="demo-ruleForm">
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.Group') }}:</span>
            <el-select v-model="value_group" filterable :placeholder="$t('messages.placeholder_0')"
              @change="groupChange">
              <el-option v-for="item in options_group" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="inputArea" v-if="value_group == $t('messages.TeamLibrary')">
            <span class="inputInfo">{{ $t('messages.teamSelected') }}:</span>
            <el-select v-model="value_teamGroup" filterable :placeholder="$t('messages.placeholder_0')"
              @change="groupSelectTeam">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name"
                :value="item.snow_id"></el-option>
            </el-select>
          </div>
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.name') }}:</span>
            <el-form-item prop="form_deviceName">
              <el-input v-model="saveToLibraryForm.form_deviceName" :placeholder="$t('messages.placeholder_1')"
                :maxlength="50"></el-input>
            </el-form-item>
            <!-- <el-input v-model="saveToLibraryForm.form_deviceName" placeholder="请输入器件名称"></el-input> -->
          </div>

          <div class="labelSelect">
            <span class="inputInfo">{{ $t('messages.Label') }}:</span>
            <el-form-item prop="form_deviceLabel">
              <el-select v-model="saveToLibraryForm.form_deviceLabel" multiple :multiple-limit="15" filterable
                allow-create default-first-option :placeholder="$t('messages.placeholder_0')">
                <el-option v-for="item in options_deviceCategory" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="textAreaSelect">
            <span class="inputInfo">{{ $t('messages.note') }}:</span>
            <el-input type="textarea" v-model="value_notes" autosize maxlength="128" show-word-limit></el-input>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="deactivate" ref="saveToLibraryBtn" @click="saveToLibrarySureButton"
            size="mini" disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="saveToLibraryCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 存到器件库覆盖提醒 -->
    <div class="errWindow">
      <el-dialog :modal="false" :title="$t('messages.coverWarning')" :visible.sync="coverDialogVisible"
        :close-on-click-modal="false">
        <div class="info">
          <i style="width: 22px; height: 22px; padding-right: 10px; color: #ec3838; font-size: 22px"
            class="iconfont icon-jinggao"></i>
          <span>{{ $t('messages.powerTips_23') }}</span>
          <br />
        </div>
        <span slot="footer" class="dialog-footer">
          <div>
            <el-button type="primary" @click="coverDialogSureBtn" size="mini">{{ $t('messages.sure') }}</el-button>
            <el-button @click="coverDialogCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>

    <!-- 复制到弹窗 -->
    <div class="copyDialog">
      <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="copyDialogClose">
        <el-form :model="copyForm" :rules="rules" ref="copyForm" class="demo-ruleForm">
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.belong') }}:</span>
            <el-form-item prop="treeNodeInfo">
              <el-select v-model="copyForm.treeNodeInfo" :placeholder="$t('messages.placeholder_0')"
                @focus="scrollToTopOption">
                <el-option :value="copyForm.treeNodeInfo" id="copyTreeOption">
                  <el-tree class="tree-line" :indent="0" node-key="snow_id" ref="tree2" highlight-current :props="props"
                    @node-click="handleNodeClick2" :load="copyLoadNode2" lazy v-if="copyTreeRefresh === '2'">
                    <span class="custom-tree-node" slot-scope="{ node }">
                      <img class="treeIcon" :src="treeNodeIcon(node)" />
                      <span>{{ node.label }}</span>
                    </span>
                  </el-tree>
                  <el-tree class="tree-line" :indent="0" node-key="snow_id" ref="tree3" highlight-current :props="props"
                    @node-click="handleNodeClick3" :load="copyLoadNode3" lazy v-else-if="copyTreeRefresh === '3'">
                    <span class="custom-tree-node" slot-scope="{ node }">
                      <img class="treeIcon" :src="treeNodeIcon(node)" />
                      <span>{{ node.label }}</span>
                    </span>
                  </el-tree>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.name') }}:</span>
            <el-form-item prop="form_copyName">
              <el-input v-model="copyForm.form_copyName" :placeholder="$t('messages.placeholder_1')"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="copyDialogSureBtn" class="deactivate" ref="copyDialogSureBtn" disabled
            size="mini">{{ $t('messages.sure') }}</el-button>
          <el-button @click="copyDialogCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 错误弹窗 -->
    <div class="errWindow">
      <el-dialog :modal="false" :title="errTitle" :visible.sync="errDialogVisible" :close-on-click-modal="false">
        <div class="info">
          <i style="width: 22px; height: 22px; padding-right: 10px; color: #ec3838; font-size: 22px"
            :class="isError ? 'iconfont icon-sousuoshanchu' : 'iconfont icon-jinggao'"></i>
          <span>{{ errInfo }}</span>
          <br />
        </div>
        <span slot="footer" class="dialog-footer">
          <div>
            <el-button type="primary" @click="errDialogVisible = false"
              size="mini">{{ $t('messages.sure') }}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>

    <!-- 导入GDS -->
    <div class="importDialog">
      <el-dialog :title="$t('messages.import')" :visible.sync="importDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="importDialogClose">
        <el-form :model="newProjectRuleForm" :rules="rules" ref="newProjectRuleForm" class="demo-ruleForm">
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.itemBelong') }}:</span>
            <div class="radioArea">
              <el-radio v-model="projectRadio" label="1"
                @change="importProjectBelong()">{{ $t('messages.person') }}</el-radio>
              <el-radio v-model="projectRadio" label="2"
                @change="importProjectBelong()">{{ $t('messages.team') }}</el-radio>
            </div>
          </div>

          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.userAndTeamName') }}:</span>
            <el-select class="userSelect" v-if="projectRadio === '1'" v-model="value_userName" filterable
              :placeholder="$t('messages.placeholder_0')" disabled>
              <el-option v-for="item in options_userName" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
            <el-select v-else-if="projectRadio === '2'" v-model="value_teamName"
              :placeholder="$t('messages.placeholder_0')" @change="teamNameSelect">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name"
                :value="item.snow_id"></el-option>
            </el-select>
          </div>

          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.projectName') }}:</span>
            <!-- <i class="el-icon-arrow-down projectNameIcon" ref="projectNameIcon"></i> -->
            <el-form-item prop="form_projectName" ref="projectNameRule">
              <el-select v-if="projectRadio === '1'" v-model="newProjectRuleForm.form_projectName"
                :placeholder="$t('messages.placeholder_0')" @change="userProNameSelect" @focus="getUserProName">
                <el-option v-for="item in options_userProName" :key="item.project_gns" :label="item.value"
                  :value="item.value"></el-option>
              </el-select>
              <el-select v-else-if="projectRadio === '2'" v-model="newProjectRuleForm.form_projectName"
                :placeholder="$t('messages.placeholder_0')" @change="teamProNameSelect" @focus="getTeamProjectNameList">
                <el-option v-for="item in options_teamProName" :key="item.project_gns" :label="item.value"
                  :value="item.value"></el-option>
              </el-select>
              <!-- <el-autocomplete @focus="projectNameFocus" @blur="projectNameBlur" class="inline-input" v-model="newProjectRuleForm.form_projectName" :fetch-suggestions="projectNameSearch" placeholder="请输入项目名" @select="handleSelectProName" :maxlength="50">
                </el-autocomplete> -->
            </el-form-item>
          </div>

          <!-- 上传 -->
          <div class="uploadArea">
            <el-upload name="gds" ref="importGDS" class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/" :on-change="uploadFile" :auto-upload="false"
              :limit="1" :multiple="false">
              <span class="inputInfo" style="margin-right: 40px">{{ $t('messages.uploadFile') }}:</span>
              <el-button size="small" type="primary">{{ $t('messages.upload') }}</el-button>
            </el-upload>
          </div>

          <!-- 版图/原理图名称 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.fileName') }}:</span>
            <el-form-item prop="form_fileName" ref="fileNameRule">
              <el-input class="inline-input" v-model="newProjectRuleForm.form_fileName"
                :placeholder="$t('messages.placeholder_1')" :maxlength="50"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="importDialogSureBtn" size="mini" class="deactivate" ref="importDialogBtn"
            disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="importDialogCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 另存为弹窗 -->
    <div class="saveAsDialog">
      <el-dialog :modal="false" :title="$t('messages.saveAs')" :visible.sync="saveAsDialogVisible"
        :close-on-click-modal="false" :before-close="saveAsDialogClose">
        <el-form :model="newProjectRuleForm" :rules="rules" ref="newProjectRuleForm" class="demo-ruleForm">
          <!-- 项目归属 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.itemBelong') }}:</span>
            <div class="radioArea">
              <el-radio v-model="projectRadio" label="1"
                @change="projectBelong()">{{ $t('messages.person') }}</el-radio>
              <el-radio v-model="projectRadio" label="2" @change="projectBelong()">{{ $t('messages.team') }}</el-radio>
            </div>
          </div>

          <!-- 用户/团队名 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.userAndTeamName') }}:</span>
            <el-select class="userSelect" v-if="projectRadio === '1'" v-model="value_userName" filterable
              :placeholder="$t('messages.personName')" disabled>
              <el-option v-for="item in options_userName" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
            <el-select v-else-if="projectRadio === '2'" v-model="value_teamName" :placeholder="$t('messages.teamName')"
              @change="teamNameSelect">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name"
                :value="item.snow_id"></el-option>
            </el-select>
          </div>

          <!-- 项目名 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.projectName') }}:</span>
            <i class="el-icon-arrow-down projectNameIcon" ref="projectNameIcon"></i>
            <el-form-item prop="form_projectName" ref="projectNameRule">
              <el-autocomplete @focus="projectNameFocus" @blur="projectNameBlur" class="inline-input"
                v-model="newProjectRuleForm.form_projectName" :fetch-suggestions="projectNameSearch"
                :placeholder="$t('messages.pleaseEnterProjectName')" @select="handleSelectProName"
                :maxlength="50"></el-autocomplete>
            </el-form-item>
          </div>

          <!-- 文件名 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.fileName') }}:</span>
            <i class="el-icon-arrow-down fileNameIcon" ref="fileNameIcon"></i>
            <el-form-item prop="form_fileName" ref="fileNameRule">
              <el-autocomplete @focus="fileNameFocus" @blur="fileNameBlur" class="inline-input"
                v-model="newProjectRuleForm.form_fileName" :fetch-suggestions="fileNameSearch"
                :placeholder="$t('messages.pleaseEnterFileName')" @select="handleSelectFileName"
                :maxlength="50"></el-autocomplete>
            </el-form-item>
          </div>

          <!-- 原理图名称 -->
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.SchematicName') }}:</span>
            <el-form-item prop="form_schematicName">
              <el-input v-model="newProjectRuleForm.form_schematicName"
                :placeholder="$t('messages.pleaseEnterSchematicName')" :maxlength="50"></el-input>
            </el-form-item>
          </div>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="deactivate" ref="saveAsDialogBtn" @click="saveAsDialogSureButton" size="mini"
            disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="saveAsDialogCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 打开符号 -->
    <OpenSymbol></OpenSymbol>

    <!-- 封装 -->
    <Encapsulation></Encapsulation>

    <!-- 输出网表 -->
    <OutputNetlist></OutputNetlist>

    <!-- 上传到符号库 -->
    <SaveToSymbolLibrary></SaveToSymbolLibrary>

    <!-- 符号替换 -->
    <ReplaceSymbol></ReplaceSymbol>
  </div>
</template>
<script>
import "@/assets/iconfont/iconfont.css";
import Md5 from "js-md5";
import OpenSymbol from "./OpenSymbol.vue";
import Encapsulation from "./Encapsulation.vue";
import OutputNetlist from "./OutputNetlist.vue";
import SaveToSymbolLibrary from "./SaveToSymbolLibrary.vue";
import ReplaceSymbol from "./ReplaceSymbol.vue";
import {
  //引入api
  createNewPFC_api, //新建项目/文件/单元
  getUserProjectList_api, //获取个人用户项目目录
  getFileList_api, //获取文件列表
  getCellList_api, //获取cell列表
  deletePFC_api, //删除项目/文件/单元
  searchFile_api, //搜索项目/文件
  updataName_api, //重命名
  getTeamList_api, //获取团队名列表
  getTeamProjectList_api, //获取团队项目列表
  getTeamPermissionList_api, //团队下项目权限
  copyFileToOtherProject_api, //复制文件到项目
  batchCreatePWD_api, //创建导入的文件结构
  getLayerList_api,
  getCellFile_api, //获取文件内容
  getBlobFile, //返回二进制
  saveCellFile_api, //保存文件内容
  createMyComponent_api, //保存到个人器件库
  createTeamComponent_api, //保存到团队器件库
  lockCell_api, //锁文件
  selectLockCell_api,
  getTree_api, //获取文件树
  getUserPermission_api, //2.1获取团队成员权限
} from "@/api/file/file.js";
import {
  get_user_config_api,
  set_user_config_api,
} from "@/api/setting/setting.js";
import {
  putObject_api,
  getDiagramFile_api,
  saveDiagramFile_api,
  removeObject_api,
  getCopySnowId_api, //获取新snowid映射表
} from "@/api/objectStorage/objectStorage.js";

import bus from "@/components/common/bus";
import router from "@/router";
import { getNode } from "../../../utils/pixi/qeda-pixi-data";
import {
  getObjectDataFn,
  downloadFileData,
  getFileDataFn,
  getCompleteDataFn,
  saveAllSchemaFileData,
  getSchemaFileData,
  getWholeFileData,
  transOldIdToNewId,
} from "./fileUtils";
import { newSymbolName } from "../../../utils/graphic_schema/layout/layout-util";
import { deepClone } from "../../../utils/utils";
import { expand_repetition } from "./gdstk_filter.js";
const urlAlphabet =
  "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict";
export default {
  components: {
    OpenSymbol,
    Encapsulation,
    OutputNetlist,
    SaveToSymbolLibrary,
    ReplaceSymbol,
  },
  data() {
    var projectNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else if (!/^[\s&()~+-_\[\]0-9a-zA-Z\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else {
        callback();
      }
    };
    var fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    var labelNameRule = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error(this.$t("messages.fileTip4")));
      } else {
        var show = true;
        for (let i in value) {
          if (value[i].name == undefined) {
            var tempValue = value[i];
          } else {
            var tempValue = value[i].name;
          }

          var len = 0;
          for (let j = 0; j < tempValue.length; j++) {
            // var c = tempValue.charCodeAt(j);
            // //单字节加1
            // if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            //   len++;
            // } else {
            //   len += 2;
            // }
            len++;
          }

          if (len > 10) {
            show = false;
          }
        }
        if (!show) {
          callback(new Error(this.$t("messages.fileTip3")));
        } else {
          callback();
        }
      }
    };
    return {
      //搜索框
      searchAll: "",

      //遮罩
      // loading: false,

      //用户信息
      userName: "",
      teamName: "",
      userId: "",
      teamId: "",
      createButtonVisible: false,

      //文件树
      treeNameList: [],
      gns: "", //当前gns
      team_gns: "", //当前team gns
      project_gns: "",
      file_gns: "",
      category: "",
      treeVisible: false,
      node: "",
      userNode: "",
      proNode: "",
      fileNode: "",
      cellNode: "",
      noData: true,
      projectData: "",
      fileData: "",
      cellData: "",
      refreshUserNode: false,
      refreshProjectNode: false,
      isCreate: false,
      teamPermission: "可见",
      //文件列表状态记录
      // expandedList: [],//存储上一次打开的状态

      // 新建弹窗
      unitType: "Layout",
      newDialogVisible: false,
      userListRClick: true,
      teamListRClick: true,
      options_userName: [
        {
          value: "",
        },
      ],
      options_teamName: [],
      options_userProName: [],
      options_teamProName: [],
      options_fileName: [],
      value_userName: "",
      value_teamName: "",
      projectRadio: "1", //项目归属选择
      fileRadio: "1", //文件类型选择
      unitRadio: "1", //版图单元选择
      newProjectRuleForm: {
        form_projectName: "",
        form_fileName: "",
        form_cellName: "",
        form_schematicName: "",
      },
      fileCreate: false,

      //输入校验
      rules: {
        form_projectName: [{ validator: projectNameRule, trigger: "blur" }],
        form_importProjectName: [
          { validator: projectNameRule, trigger: "blur" },
        ],
        form_fileName: [{ validator: fileNameRule, trigger: "blur" }],
        form_cellName: [{ validator: fileNameRule, trigger: "blur" }],
        form_schematicName: [{ validator: fileNameRule, trigger: "blur" }],
        form_copyName: [{ validator: fileNameRule, trigger: "blur" }],
        form_deviceName: [{ validator: fileNameRule, trigger: "blur" }],
        form_deviceLabel: [
          { validator: labelNameRule, trigger: "change", type: "array" },
        ],
      },
      //删除弹窗
      delDialogVisible: false,
      userKnowChecked: false,
      deleteName: "",
      delRouteArr: [],
      delSnowIdArr: [],
      isDelete: false,

      //存到器件库弹窗
      saveToLibraryDialogVisible: false,
      value_group: "",
      value_teamGroup: "",
      saveToDeviceTeamInfo: {},
      saveToLibraryForm: {
        form_deviceName: "",
        form_deviceLabel: [],
      },
      value_notes: "",
      options_group: [
        {
          // 我的器件库
          value: this.$t("messages.MyLibrary"),
        },
        {
          // 团队器件库
          value: this.$t("messages.TeamLibrary"),
        },
        // {
        //   value: "公用器件库",
        // },
        // {
        //   value: "官方器件库",
        // },
      ],
      options_deviceCategory: [
        {
          value: "超导",
        },
        {
          value: "半导",
        },
      ],

      //存到器件库覆盖弹窗
      coverDialogVisible: false,
      coverFormData: null,

      //复制到
      copyDialogVisible: false,
      options_copyName: [
        {
          value: "gxyf",
        },
        {
          value: "团队1",
        },
        {
          value: "团队2",
        },
      ],
      // value_userName: "",
      props: {
        label: "name",
        children: "zones",
        isLeaf: "isLeaf",
      },
      copyForm: {
        treeNodeInfo: "",
        form_copyName: "",
      },
      copyTreeRefresh: "2",
      isCopyFile: false, //是否是复制文件

      file_snow_id: "",
      original_project_gns: "",
      target_project_gns: "",
      copy_type: "",
      copy_level: "",
      copyFile_url: "",
      copyToFile_url: "",

      //右键菜单
      //调用
      deferentFileCall: false,
      isFileCall: false,

      //错误弹窗
      errDialogVisible: false,
      errTitle: "",
      errInfo: "",
      isError: true,

      //导入GDS
      cellNames: [],
      importDialogVisible: false,
      isImport: false,
      importType: "1",
      importSchemaFile: {},

      //另存为
      saveAsDialogVisible: false,

      //防抖
      debounce: true,

      //重命名
      isReName: false,
      oldName: "",

      //另存为单元
      currentFile_url: "",
      saveAsCellFile_url: "",
      boxData: {}, //框选

      //关闭且不保存
      cellUnsaved: false,

      //是否是拆分版图
      isSplit: false,

      // 以只读模式打开
      isReadOnly: false,
      //存到符号库

      //模拟数据
      Kernel: null,

      //原理图文件数据
      schemaFileArr: [],
      // 原理图文件下原理图/符号url的数组
      schemaSymbolUrlArr: [],
      isCreateSchemaFile: false, //是否是创建的原理图文件
      //原理图递归获取的要保存的数组
      recFileCallArr: [],
      //封装原理图
      isCreateCapSymbol: false,

      //默认的检查表
      defaultTableData: [
        {
          type: this.$t("messages.net"),
          content: "网络是否连接到两个端口",
          level: this.$t("messages.Warning"),
          id: "net_1",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: "连接到网络上的引脚，网络和引脚名称不一致",
          level: this.$t("messages.Warning"),
          id: "net_2",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: "引脚不能悬空",
          level: this.$t("messages.Warning"),
          id: "net_3",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: "符号的端口未连接",
          level: this.$t("messages.Warning"),
          id: "symbol_1",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: "符号端口连接类型错误",
          level: this.$t("messages.Warning"),
          id: "symbol_2",
          selector: 3,
          children: [
            {
              // type: this.$t('messages.Symbol'),
              content: "总线端口未连接Cp1",
              // level: this.$t('messages.Warning'),
              // id: "symbol_2",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cp1端口未连接总线和谐振腔",
              // level: this.$t('messages.Warning'),
              // id: "symbol_3",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "谐振腔端口未连接Cp1和Cp2",
              // level: this.$t('messages.Warning'),
              // id: "symbol_4",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cp2端口未连接谐振腔和比特",
              // level: this.$t('messages.Warning'),
              // id: "symbol_5",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "比特端口（三端口）未连接Cp2、Cp3和Cz1",
              // level: this.$t('messages.Warning'),
              // id: "symbol_6",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "比特端口（四端口）未连接Cp2、Cp3、Cz1和Cb1",
              // level: this.$t('messages.Warning'),
              // id: "symbol_7",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cp3端口未连接比特和X线",
              // level: this.$t('messages.Warning'),
              // id: "symbol_8",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cb1端口未连接两个比特",
              // level: this.$t('messages.Warning'),
              // id: "symbol_9",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "X线端口未连接Cp3",
              // level: this.$t('messages.Warning'),
              // id: "symbol_10",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cz1端口未连接比特和z线",
              // level: this.$t('messages.Warning'),
              // id: "symbol_11",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Z线端口未连接Cz1线",
              // level: this.$t('messages.Warning'),
              // id: "symbol_12",
              // selector: 3,
            },
          ],
        },
      ],
      //检查映射表
      checkMappingTable: [
        {
          content: "网络是否连接到两个端口",
          id: "net_1",
        },
        {
          content: "连接到网络上的引脚，网络和引脚名称不一致",
          id: "net_2",
        },
        {
          content: "引脚不能悬空",
          id: "net_3",
        },
        {
          content: "符号的端口未连接",
          id: "symbol_1",
        },
        {
          content: "符号端口连接类型错误",
          id: "symbol_2",
        },
      ],
    };
  },
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },

    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },

    //文件列表图标显示
    treeNodeIcon(node) {
      if (node.level == 1 && node.data.isTeam == false) {
        return require("@/assets/icons/file/个人用户名.svg");
      } else if (node.level == 1 && node.data.isTeam == true) {
        return require("@/assets/icons/file/团队名.svg");
      } else if (node.level == 2) {
        return require("@/assets/icons/file/项目名.svg");
      } else if (node.level == 3 && node.data.file_type == "1") {
        return require("@/assets/icons/file/版图文件.svg");
      } else if (node.level == 3 && node.data.file_type == "3") {
        return require("@/assets/icons/file/原理图文件名称.svg");
      } else if (node.level == 4 && node.data.cell_type == "1") {
        return require("@/assets/icons/file/版图名.svg");
      } else if (node.level == 4 && node.data.cell_type == "2") {
        return require("@/assets/icons/file/器件名.svg");
      } else if (node.level >= 4 && node.data.cell_type == "3") {
        return require("@/assets/icons/file/原理图.svg");
      } else if (
        node.level >= 4 &&
        (node.data.cell_type == "4" || node.data.cell_type == "5")
      ) {
        return require("@/assets/icons/file/打开符号.svg");
      } else if (node.level > 4 && !node.data.hasOwnProperty("cell_type")) {
        return require("@/assets/icons/file/器件名.svg");
      }
    },

    //文件列表状态记录
    //项目展开保存状态
    // nodeExpand(data, node) {
    //   if (node.level <= 3) {
    //     let flag = false
    //     this.expandedList.some(item => {
    //       if (item === data.snow_id) { // 判断当前节点是否存在， 存在不做处理
    //         flag = true
    //         return
    //       }
    //     })
    //     if (!flag) { // 不存在则存到数组里
    //       if (node.level === 3) {
    //         this.expandedList.push(data.snow_id)
    //       } else {
    //         this.expandedList.push(data.snow_id)
    //         this.childNodeExpand(node)
    //       }
    //     }
    //     localStorage.setItem('expandedList', JSON.stringify(this.expandedList))
    //   }
    // },

    //展开父节点,递归展开其下所有已被展开节点
    // childNodeExpand(node) {
    //   node.childNodes.forEach(item => {
    //     if (item.loaded) {
    //       const index = this.expandedList.indexOf(item.data.snow_id)
    //       if (index === -1) {
    //         this.expandedList.push(item.data.snow_id)
    //       }
    //       this.childNodeExpand(item)
    //     }
    //   })
    // },

    //项目收缩删除状态
    // nodeCollapse(data, node) {
    //   if (node.level <= 3) {
    //     this.expandedList.some((item, i) => {
    //       if (item === data.snow_id) {
    //         this.expandedList.splice(i, 1)
    //       }
    //     })
    //     this.removeChildren(node)
    //     localStorage.setItem('expandedList', JSON.stringify(this.expandedList))
    //   }
    // },

    //收缩父节点,递归删除其下所有节点
    // removeChildren(node) {
    //   if (node.childNodes) {
    //     node.childNodes.forEach(item => {
    //       const index = this.expandedList.indexOf(item.data.snow_id)
    //       if (index !== -1) {
    //         this.expandedList.splice(index, 1)
    //       }
    //       this.removeChildren(item)
    //     })
    //   }
    // },

    ////右键菜单
    async onContextmenu(event, node) {
      let locked = false;
      if (node.data.isTeam) {
        let isOpened = false;
        let permissionRes = await getUserPermission_api({
          gns: node.data.gns,
        });
        if (permissionRes.code === 200000) {
          node.data.teamRule = permissionRes.data.permission;
          if (
            node.level == 4 &&
            (node.data.cell_type == "3" ||
              node.data.cell_type == "4" ||
              node.data.cell_type == "5")
          ) {
            let fileOpenedTabArr = [];
            this.$store.state.openedTab.forEach((item) => {
              let gnsFileId = item.route
                .split("=")
                [item.route.split("=").length - 1].split("/")[0];

              if (gnsFileId === node.parent.data.snow_id) {
                fileOpenedTabArr.push(item);
              }
            });

            if (fileOpenedTabArr.some((item) => item.isReadOnly === false)) {
              isOpened = true;
              locked = false;
            }
            if (!isOpened) {
              let lockCellRes = await selectLockCell_api(
                node.parent.data.snow_id
              );
              if (lockCellRes.code === 200000) {
                if (lockCellRes.message == "not lock") {
                  node.data.teamRule = permissionRes.data.permission;
                  locked = false;
                } else if (lockCellRes.message == "locked") {
                  node.data.teamRule = "只读";
                  locked = true;
                }
              }
            }
          }
        }
      }
      if (node.level == 2 && node.data.isTeam == true) {
        //获取团队权限
        if (node.data.teamRule !== "") {
          if (node.data.teamRule === "可删除、编辑") {
            this.$contextmenu({
              items: [
                {
                  label: this.$t("messages.new"),
                  divided: true,
                  onClick: () => {
                    this.newDialogVisible = true;
                    this.userName = localStorage.getItem("userName");
                    this.userId = localStorage.getItem("userId");
                    this.fileCreate = false;
                    if (this.projectRadio == "1") {
                      this.teamListRClick = false;
                      this.userListRClick = true;
                    } else {
                      this.userListRClick = false;
                      this.teamListRClick = true;
                    }
                  },
                  icon: "iconfont icon-xinjian",
                  customClass: "contextMenuIcon",
                },
                {
                  label: this.$t("messages.rename"),
                  onClick: () => {
                    this.node.data.showInput = true;
                    this.$nextTick(() => {
                      this.$refs.resetNameInput.focus();
                      this.$refs.resetNameInput.select();
                    });
                  },
                  icon: "iconfont icon-zhongmingming",
                },
                {
                  label: this.$t("messages.delete"),
                  onClick: () => {
                    this.userKnowChecked = false;
                    this.userKnow();
                    this.delDialogVisible = true;
                    this.deleteName = this.node.data.name;
                  },
                  icon: "iconfont icon-shanchu",
                },
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          } else {
            this.$contextmenu({
              items: [
                {
                  label: this.$t("messages.new"),
                  divided: true,
                  onClick: () => {},
                  icon: "iconfont icon-xinjian",
                  customClass: "contextMenuIcon",
                  disabled: true,
                },
                {
                  label: this.$t("messages.rename"),
                  onClick: () => {},
                  icon: "iconfont icon-zhongmingming",
                  disabled: true,
                },
                {
                  label: this.$t("messages.delete"),
                  onClick: () => {},
                  icon: "iconfont icon-shanchu",
                  disabled: true,
                },
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          }
        } else {
          this.$message.error("用户不在当前项目中");
        }
      } else if (node.level == 3 && node.data.isTeam == true) {
        if (node.data.teamRule !== "") {
          if (
            this.$route.fullPath.split("%2F")[0].split("id=")[1] ===
            node.data.snow_id
          ) {
            if (node.data.teamRule === "可删除、编辑") {
              if (node.data.file_type == "1") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
              //右键原理图文件
              else if (node.data.file_type == "3") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "3";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            } else {
              if (node.data.file_type == "1") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
              //右键原理图文件
              else if (node.data.file_type == "3") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "3";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          } else {
            if (node.data.teamRule === "可删除、编辑") {
              if (node.data.file_type == "1") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      // disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
              //右键原理图文件
              else if (node.data.file_type == "3") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "3";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            } else {
              if (node.data.file_type == "1") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
              //右键原理图文件
              else if (node.data.file_type == "3") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "3";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "2";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          }
        }
      } else if (node.level == 4 && node.data.isTeam == true) {
        //团队单元
        if (node.data.cell_type == "1" || node.data.cell_type == "2") {
          if (node.data.isTemporary) {
            if (node.data.teamRule !== "") {
              if (node.data.teamRule === "可删除、编辑") {
                //完整权限
                // let openedCellId = []
                // this.$store.state.openedTab.forEach(item => {
                //   openedCellId.push(item.split("/")[item.split("/").length - 1])
                // })
                if (
                  this.$route.fullPath.split("%2F")[1] === node.data.snow_id
                ) {
                  //是当前打开的
                  this.$contextmenu({
                    items: [
                      {
                        label: this.$t("messages.new"),
                        divided: true,
                        onClick: () => {
                          this.newDialogVisible = true;
                          this.userName = localStorage.getItem("userName");
                          this.userId = localStorage.getItem("userId");
                          this.fileCreate = false;
                          this.fileRadio = "1";
                          if (this.projectRadio == "1") {
                            this.teamListRClick = false;
                            this.userListRClick = true;
                          } else {
                            this.userListRClick = false;
                            this.teamListRClick = true;
                          }
                        },
                        icon: "iconfont icon-xinjian",
                      },
                      {
                        label: this.$t("messages.copyTo"),
                        onClick: () => {
                          this.copyDialogVisible = true;
                          this.copyTreeRefresh = "3";
                        },
                        icon: "iconfont icon-fuzhi",
                        disabled: true,
                      },
                      {
                        label: this.$t("messages.SavetoLibrary"),
                        onClick: () => {
                          this.saveToLibraryDialogVisible = true;
                        },
                        icon: "iconfont icon-cundaoqijianku",
                      },
                      {
                        label: this.$t("messages.rename"),
                        onClick: () => {
                          this.node.data.showInput = true;
                          this.$nextTick(() => {
                            this.$refs.resetNameInput.focus();
                            this.$refs.resetNameInput.select();
                          });
                        },
                        icon: "iconfont icon-zhongmingming",
                        disabled: true,
                      },
                      {
                        label: this.$t("messages.delete"),
                        onClick: () => {
                          this.userKnowChecked = false;
                          this.userKnow();
                          this.delDialogVisible = true;
                          this.deleteName = this.node.data.name;
                        },
                        icon: "iconfont icon-shanchu",
                      },
                      {
                        label: this.$t("messages.instance"),
                        onClick: () => {},
                        icon: "iconfont icon-tiaoyong",
                        disabled: true,
                      },
                    ],
                    event, // 鼠标事件信息
                    customClass: "custom-class", // 自定义菜单 class
                    zIndex: 1000, // 菜单样式 z-index
                    minWidth: 170, // 主菜单最小宽度
                  });
                } else {
                  // let currentCellSnow_id = this.$store.state.cellInfo.cellId

                  if (this.$route.fullPath == "/home/layout") {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.new"),
                          divided: true,
                          onClick: () => {
                            this.newDialogVisible = true;
                            this.userName = localStorage.getItem("userName");
                            this.userId = localStorage.getItem("userId");
                            this.fileCreate = false;
                            this.fileRadio = "1";
                            if (this.projectRadio == "1") {
                              this.teamListRClick = false;
                              this.userListRClick = true;
                            } else {
                              this.userListRClick = false;
                              this.teamListRClick = true;
                            }
                          },
                          icon: "iconfont icon-xinjian",
                        },
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.SavetoLibrary"),
                          onClick: () => {
                            this.saveToLibraryDialogVisible = true;
                          },
                          icon: "iconfont icon-cundaoqijianku",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.instance"),
                          onClick: () => {},
                          icon: "iconfont icon-tiaoyong",
                          disabled: true,
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  } else {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.new"),
                          divided: true,
                          onClick: () => {
                            this.newDialogVisible = true;
                            this.userName = localStorage.getItem("userName");
                            this.userId = localStorage.getItem("userId");
                            this.fileCreate = false;
                            this.fileRadio = "1";
                            if (this.projectRadio == "1") {
                              this.teamListRClick = false;
                              this.userListRClick = true;
                            } else {
                              this.userListRClick = false;
                              this.teamListRClick = true;
                            }
                          },
                          icon: "iconfont icon-xinjian",
                        },
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.SavetoLibrary"),
                          onClick: () => {
                            this.saveToLibraryDialogVisible = true;
                          },
                          icon: "iconfont icon-cundaoqijianku",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.instance"),
                          onClick: () => {
                            this.isFileCall = true;
                            let fileId =
                              node.data.gns.split("/")[
                                node.data.gns.split("/").length - 2
                              ];
                            if (fileId == this.$store.state.fileInfo.fileId) {
                              bus.$emit("deviceCallName", node.data.name);
                            } else {
                              this.deferentFileCall = true;
                              let cell = {};
                              getCellFile_api({ file_snow_id: fileId })
                                .then((fileRes) => {
                                  if (fileRes.data.file_url) {
                                    getObjectDataFn(fileRes.data.file_url).then(
                                      (u8_data) => {
                                        window.QGdstk.FS.writeFile(
                                          fileId,
                                          u8_data
                                        );
                                        let callFile =
                                          window.QGdstk.read_gds(fileId);
                                        expand_repetition(callFile);

                                        callFile.cells.forEach((item) => {
                                          if (item.name == node.data.name) {
                                            cell = item;
                                          }
                                        });
                                        let new_lib = new window.QGdstk.Library(
                                          node.data.name,
                                          1e-6,
                                          1e-9
                                        );
                                        new_lib.add(cell);
                                        new_lib.add(cell.dependencies(true));

                                        bus.$emit("addDevice", {
                                          Library: new_lib,
                                          rotation: 0,
                                        });
                                      }
                                    );
                                  } else {
                                  }
                                })
                                .catch((fileErr) => {
                                  this.errDialog(
                                    this.$t("messages.wrong"),
                                    this.$t("messages.code_" + fileErr.code),
                                    true
                                  );
                                });
                            }
                          },
                          icon: "iconfont icon-tiaoyong",
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  }
                }
              } else {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {},
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {},
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {},
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {},
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {},
                      icon: "iconfont icon-tiaoyong",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          } else {
            if (node.data.teamRule !== "") {
              //是可编辑的权限
              if (node.data.teamRule === "可删除、编辑") {
                //是当前已打开的画板
                if (
                  this.$route.fullPath.split("%2F")[1] === node.data.snow_id
                ) {
                  this.$contextmenu({
                    items: [
                      {
                        label: this.$t("messages.new"),
                        divided: true,
                        onClick: () => {
                          this.newDialogVisible = true;
                          this.userName = localStorage.getItem("userName");
                          this.userId = localStorage.getItem("userId");
                          this.fileCreate = false;
                          this.fileRadio = "1";
                          if (this.projectRadio == "1") {
                            this.teamListRClick = false;
                            this.userListRClick = true;
                          } else {
                            this.userListRClick = false;
                            this.teamListRClick = true;
                          }
                        },
                        icon: "iconfont icon-xinjian",
                      },
                      {
                        label: this.$t("messages.copyTo"),
                        onClick: () => {
                          this.copyDialogVisible = true;
                          this.copyTreeRefresh = "3";
                        },
                        icon: "iconfont icon-fuzhi",
                      },
                      {
                        label: this.$t("messages.SavetoLibrary"),
                        onClick: () => {
                          this.saveToLibraryDialogVisible = true;
                        },
                        icon: "iconfont icon-cundaoqijianku",
                      },
                      {
                        label: this.$t("messages.rename"),
                        onClick: () => {
                          this.node.data.showInput = true;
                          this.$nextTick(() => {
                            this.$refs.resetNameInput.focus();
                            this.$refs.resetNameInput.select();
                          });
                        },
                        icon: "iconfont icon-zhongmingming",
                      },
                      {
                        label: this.$t("messages.delete"),
                        onClick: () => {
                          this.userKnowChecked = false;
                          this.userKnow();
                          this.delDialogVisible = true;
                          this.deleteName = this.node.data.name;
                        },
                        icon: "iconfont icon-shanchu",
                      },
                      {
                        label: this.$t("messages.instance"),
                        onClick: () => {},
                        icon: "iconfont icon-tiaoyong",
                        disabled: true,
                      },
                    ],
                    event, // 鼠标事件信息
                    customClass: "custom-class", // 自定义菜单 class
                    zIndex: 1000, // 菜单样式 z-index
                    minWidth: 170, // 主菜单最小宽度
                  });
                  //右键不是当前单元对应的画板
                } else {
                  // 完全没有打开
                  if (this.$route.fullPath == "/home/layout") {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.new"),
                          divided: true,
                          onClick: () => {
                            this.newDialogVisible = true;
                            this.userName = localStorage.getItem("userName");
                            this.userId = localStorage.getItem("userId");
                            this.fileCreate = false;
                            this.fileRadio = "1";
                            if (this.projectRadio == "1") {
                              this.teamListRClick = false;
                              this.userListRClick = true;
                            } else {
                              this.userListRClick = false;
                              this.teamListRClick = true;
                            }
                          },
                          icon: "iconfont icon-xinjian",
                        },
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.SavetoLibrary"),
                          onClick: () => {
                            this.saveToLibraryDialogVisible = true;
                          },
                          icon: "iconfont icon-cundaoqijianku",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.instance"),
                          onClick: () => {},
                          icon: "iconfont icon-tiaoyong",
                          disabled: true,
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  }
                  // 右键的画板对应单元不是当前单元
                  else {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.new"),
                          divided: true,
                          onClick: () => {
                            this.newDialogVisible = true;
                            this.userName = localStorage.getItem("userName");
                            this.userId = localStorage.getItem("userId");
                            this.fileCreate = false;
                            this.fileRadio = "1";
                            if (this.projectRadio == "1") {
                              this.teamListRClick = false;
                              this.userListRClick = true;
                            } else {
                              this.userListRClick = false;
                              this.teamListRClick = true;
                            }
                          },
                          icon: "iconfont icon-xinjian",
                        },
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.SavetoLibrary"),
                          onClick: () => {
                            this.saveToLibraryDialogVisible = true;
                          },
                          icon: "iconfont icon-cundaoqijianku",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          disabled: true,
                        },
                        {
                          label: this.$t("messages.instance"),
                          onClick: () => {
                            this.isFileCall = true;
                            let fileId =
                              node.data.gns.split("/")[
                                node.data.gns.split("/").length - 2
                              ];
                            if (fileId == this.$store.state.fileInfo.fileId) {
                              bus.$emit("deviceCallName", node.data.name);
                            } else {
                              this.deferentFileCall = true;
                              let cell = {};
                              getCellFile_api({ file_snow_id: fileId })
                                .then((fileRes) => {
                                  if (fileRes.data.file_url) {
                                    getObjectDataFn(fileRes.data.file_url).then(
                                      (u8_data) => {
                                        window.QGdstk.FS.writeFile(
                                          fileId,
                                          u8_data
                                        );
                                        let callFile =
                                          window.QGdstk.read_gds(fileId);
                                        expand_repetition(callFile);

                                        callFile.cells.forEach((item) => {
                                          if (item.name == node.data.name) {
                                            cell = item;
                                          }
                                        });
                                        let new_lib = new window.QGdstk.Library(
                                          node.data.name,
                                          1e-6,
                                          1e-9
                                        );
                                        new_lib.add(cell);
                                        new_lib.add(cell.dependencies(true));

                                        bus.$emit("addDevice", {
                                          Library: new_lib,
                                          rotation: 0,
                                        });
                                      }
                                    );
                                  } else {
                                  }
                                })
                                .catch((fileErr) => {
                                  this.errDialog(
                                    this.$t("messages.wrong"),
                                    this.$t("messages.code_" + fileErr.code),
                                    true
                                  );
                                });
                            }
                          },
                          icon: "iconfont icon-tiaoyong",
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  }
                }
                //不能编辑的权限
              } else {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {},
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {},
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {},
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {},
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {},
                      icon: "iconfont icon-tiaoyong",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          }
        } else if (
          node.data.cell_type == "3" ||
          node.data.cell_type == "4" ||
          node.data.cell_type == "5"
        ) {
          //原理图等是团队的
          if (node.data.teamRule !== "") {
            //可操作的
            if (!locked) {
              if (node.data.teamRule === "可删除、编辑") {
                // 是否已有映射表
                if (this.$route.fullPath == "/home/layout") {
                  //完全没有映射表
                  if (node.data.cell_type == "3") {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.new"),
                          divided: true,
                          onClick: () => {
                            this.newDialogVisible = true;
                            this.userName = localStorage.getItem("userName");
                            this.userId = localStorage.getItem("userId");
                            this.fileCreate = false;
                            this.fileRadio = "3";
                            if (this.projectRadio == "1") {
                              this.teamListRClick = false;
                              this.userListRClick = true;
                            } else {
                              this.userListRClick = false;
                              this.teamListRClick = true;
                            }
                          },
                          icon: "iconfont icon-xinjian",
                        },
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                        },
                        {
                          label: this.$t("messages.OpeninReadOnly"),
                          onClick: () => {
                            this.isReadOnly = true;
                            this.openCellFn(node);
                            // bus.$emit("readOnlySidebar")
                          },
                          icon: "iconfont icon-yizhidudakai",
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          // disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          // disabled: true,
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  } else if (
                    node.data.cell_type == "4" ||
                    node.data.cell_type == "5"
                  ) {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                          // disabled: true,
                        },
                        {
                          label: this.$t("messages.saveToSymbolLibrary"),
                          onClick: () => {
                            bus.$emit("uploadToSymbolLibrary", node);
                          },
                          icon: "iconfont icon-cundaofuhaoku",
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          // disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          // disabled: true,
                        },
                        {
                          label: this.$t("messages.instance"),
                          onClick: () => {
                            this.instanceSymbol(node);
                          },
                          icon: "iconfont icon-tiaoyong",
                          disabled: true,
                        },
                        {
                          label: this.$t("符号替换"),
                          onClick: () => {
                            bus.$emit("replaceSymbol", node);
                          },
                          icon: "iconfont icon-tihuan",
                          // disabled: true
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  }
                } else if (
                  this.$store.state.schemaFileTable.findIndex(
                    (item) => item.fileId === node.parent.data.snow_id
                  ) !== -1
                ) {
                  //是已有映射表的
                  if (
                    this.$route.fullPath.split("%2F")[
                      this.$route.fullPath.split("%2F").length - 1
                    ] === node.data.snow_id ||
                    this.$store.state.openedTab.some(
                      (item) =>
                        item.route.split("/")[
                          item.route.split("/").length - 1
                        ] == node.data.snow_id
                    )
                  ) {
                    //是当前画板对应的
                    if (node.data.cell_type == "3") {
                      this.$contextmenu({
                        items: [
                          {
                            label: this.$t("messages.new"),
                            divided: true,
                            onClick: () => {
                              this.newDialogVisible = true;
                              this.userName = localStorage.getItem("userName");
                              this.userId = localStorage.getItem("userId");
                              this.fileCreate = false;
                              this.fileRadio = "3";
                              if (this.projectRadio == "1") {
                                this.teamListRClick = false;
                                this.userListRClick = true;
                              } else {
                                this.userListRClick = false;
                                this.teamListRClick = true;
                              }
                            },
                            icon: "iconfont icon-xinjian",
                          },
                          {
                            label: this.$t("messages.copyTo"),
                            onClick: () => {
                              this.copyDialogVisible = true;
                              this.copyTreeRefresh = "3";
                            },
                            icon: "iconfont icon-fuzhi",
                          },
                          {
                            label: this.$t("messages.rename"),
                            onClick: () => {
                              this.node.data.showInput = true;
                              this.$nextTick(() => {
                                this.$refs.resetNameInput.focus();
                                this.$refs.resetNameInput.select();
                              });
                            },
                            icon: "iconfont icon-zhongmingming",
                          },
                          {
                            label: this.$t("messages.delete"),
                            onClick: () => {
                              this.userKnowChecked = false;
                              this.userKnow();
                              this.delDialogVisible = true;
                              this.deleteName = this.node.data.name;
                            },
                            icon: "iconfont icon-shanchu",
                          },
                        ],
                        event, // 鼠标事件信息
                        customClass: "custom-class", // 自定义菜单 class
                        zIndex: 1000, // 菜单样式 z-index
                        minWidth: 170, // 主菜单最小宽度
                      });
                    }
                  } else {
                    //不是当前画板对应的
                    if (node.data.cell_type == "3") {
                      this.$contextmenu({
                        items: [
                          {
                            label: this.$t("messages.new"),
                            divided: true,
                            onClick: () => {
                              this.newDialogVisible = true;
                              this.userName = localStorage.getItem("userName");
                              this.userId = localStorage.getItem("userId");
                              this.fileCreate = false;
                              this.fileRadio = "3";
                              if (this.projectRadio == "1") {
                                this.teamListRClick = false;
                                this.userListRClick = true;
                              } else {
                                this.userListRClick = false;
                                this.teamListRClick = true;
                              }
                            },
                            icon: "iconfont icon-xinjian",
                          },
                          {
                            label: this.$t("messages.copyTo"),
                            onClick: () => {
                              this.copyDialogVisible = true;
                              this.copyTreeRefresh = "3";
                            },
                            icon: "iconfont icon-fuzhi",
                          },
                          {
                            label: this.$t("messages.OpeninReadOnly"),
                            onClick: () => {
                              this.isReadOnly = true;
                              this.openCellFn(node);
                              // bus.$emit("readOnlySidebar")
                            },
                            icon: "iconfont icon-yizhidudakai",
                          },
                          {
                            label: this.$t("messages.rename"),
                            onClick: () => {
                              this.node.data.showInput = true;
                              this.$nextTick(() => {
                                this.$refs.resetNameInput.focus();
                                this.$refs.resetNameInput.select();
                              });
                            },
                            // disabled: true,
                            icon: "iconfont icon-zhongmingming",
                          },
                          {
                            label: this.$t("messages.delete"),
                            onClick: () => {
                              this.userKnowChecked = false;
                              this.userKnow();
                              this.delDialogVisible = true;
                              this.deleteName = this.node.data.name;
                            },
                            icon: "iconfont icon-shanchu",
                            // disabled: true,
                          },
                        ],
                        event, // 鼠标事件信息
                        customClass: "custom-class", // 自定义菜单 class
                        zIndex: 1000, // 菜单样式 z-index
                        minWidth: 170, // 主菜单最小宽度
                      });
                    } else if (
                      node.data.cell_type == "4" ||
                      node.data.cell_type == "5"
                    ) {
                      this.$contextmenu({
                        items: [
                          {
                            label: this.$t("messages.copyTo"),
                            onClick: () => {
                              this.copyDialogVisible = true;
                              this.copyTreeRefresh = "3";
                            },
                            icon: "iconfont icon-fuzhi",
                            // disabled: true,
                          },
                          {
                            label: this.$t("messages.saveToSymbolLibrary"),
                            onClick: () => {
                              bus.$emit("uploadToSymbolLibrary", node);
                            },
                            icon: "iconfont icon-cundaofuhaoku",
                          },
                          {
                            label: this.$t("messages.rename"),
                            onClick: () => {
                              this.node.data.showInput = true;
                              this.$nextTick(() => {
                                this.$refs.resetNameInput.focus();
                                this.$refs.resetNameInput.select();
                              });
                            },
                            // disabled: true,
                            icon: "iconfont icon-zhongmingming",
                          },
                          {
                            label: this.$t("messages.delete"),
                            onClick: () => {
                              this.userKnowChecked = false;
                              this.userKnow();
                              this.delDialogVisible = true;
                              this.deleteName = this.node.data.name;
                            },
                            icon: "iconfont icon-shanchu",
                            // disabled: true,
                          },
                          {
                            label: this.$t("messages.instance"),
                            onClick: () => {
                              this.instanceSymbol(node);
                            },
                            icon: "iconfont icon-tiaoyong",
                          },
                          {
                            label: this.$t("符号替换"),
                            onClick: () => {
                              bus.$emit("replaceSymbol", node);
                            },
                            icon: "iconfont icon-tihuan",
                            // disabled: true
                          },
                        ],
                        event, // 鼠标事件信息
                        customClass: "custom-class", // 自定义菜单 class
                        zIndex: 1000, // 菜单样式 z-index
                        minWidth: 170, // 主菜单最小宽度
                      });
                    }
                  }
                } else {
                  if (node.data.cell_type == "3") {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.new"),
                          divided: true,
                          onClick: () => {},
                          icon: "iconfont icon-xinjian",
                          customClass: "contextMenuIcon",
                          // disabled: true,
                        },
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                        },
                        {
                          label: this.$t("messages.OpeninReadOnly"),
                          onClick: () => {
                            this.isReadOnly = true;
                            this.openCellFn(node);
                            // bus.$emit("readOnlySidebar")
                          },
                          icon: "iconfont icon-yizhidudakai",
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          // disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          // disabled: true,
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  } else {
                    this.$contextmenu({
                      items: [
                        {
                          label: this.$t("messages.copyTo"),
                          onClick: () => {
                            this.copyDialogVisible = true;
                            this.copyTreeRefresh = "3";
                          },
                          icon: "iconfont icon-fuzhi",
                        },
                        {
                          label: this.$t("messages.saveToSymbolLibrary"),
                          onClick: () => {
                            bus.$emit("uploadToSymbolLibrary", node);
                          },
                          icon: "iconfont icon-cundaofuhaoku",
                        },
                        {
                          label: this.$t("messages.rename"),
                          onClick: () => {
                            this.node.data.showInput = true;
                            this.$nextTick(() => {
                              this.$refs.resetNameInput.focus();
                              this.$refs.resetNameInput.select();
                            });
                          },
                          // disabled: true,
                          icon: "iconfont icon-zhongmingming",
                        },
                        {
                          label: this.$t("messages.delete"),
                          onClick: () => {
                            this.userKnowChecked = false;
                            this.userKnow();
                            this.delDialogVisible = true;
                            this.deleteName = this.node.data.name;
                          },
                          icon: "iconfont icon-shanchu",
                          // disabled: true,
                        },
                        {
                          label: this.$t("messages.instance"),
                          onClick: () => {
                            this.instanceSymbol(node);
                          },
                          icon: "iconfont icon-tiaoyong",
                        },
                      ],
                      event, // 鼠标事件信息
                      customClass: "custom-class", // 自定义菜单 class
                      zIndex: 1000, // 菜单样式 z-index
                      minWidth: 170, // 主菜单最小宽度
                    });
                  }
                }
              } else {
                if (node.data.cell_type == "3") {
                  this.$contextmenu({
                    items: [
                      {
                        label: this.$t("messages.new"),
                        divided: true,
                        onClick: () => {},
                        icon: "iconfont icon-xinjian",
                        customClass: "contextMenuIcon",
                        // disabled: true,
                      },
                      {
                        label: this.$t("messages.copyTo"),
                        onClick: () => {
                          this.copyDialogVisible = true;
                          this.copyTreeRefresh = "3";
                        },
                        icon: "iconfont icon-fuzhi",
                        // disabled: true,
                      },
                      {
                        label: this.$t("messages.OpeninReadOnly"),
                        onClick: () => {
                          this.isReadOnly = true;
                          this.openCellFn(node);
                        },
                        icon: "iconfont icon-yizhidudakai",
                        // disabled: true,
                      },
                      {
                        label: this.$t("messages.rename"),
                        onClick: () => {},
                        disabled: true,
                        icon: "iconfont icon-zhongmingming",
                      },
                      {
                        label: this.$t("messages.delete"),
                        onClick: () => {},
                        icon: "iconfont icon-shanchu",
                        disabled: true,
                      },
                    ],
                    event, // 鼠标事件信息
                    customClass: "custom-class", // 自定义菜单 class
                    zIndex: 1000, // 菜单样式 z-index
                    minWidth: 170, // 主菜单最小宽度
                  });
                } else {
                  this.$contextmenu({
                    items: [
                      {
                        label: this.$t("messages.copyTo"),
                        onClick: () => {
                          this.copyDialogVisible = true;
                          this.copyTreeRefresh = "3";
                        },
                        icon: "iconfont icon-fuzhi",
                        // disabled: true,
                      },
                      {
                        label: this.$t("messages.saveToSymbolLibrary"),
                        onClick: () => {
                          bus.$emit("uploadToSymbolLibrary", node);
                        },
                        icon: "iconfont icon-cundaofuhaoku",
                        // disabled: true,
                      },
                      {
                        label: this.$t("messages.rename"),
                        onClick: () => {},
                        icon: "iconfont icon-zhongmingming",
                        disabled: true,
                      },
                      {
                        label: this.$t("messages.delete"),
                        onClick: () => {},
                        icon: "iconfont icon-shanchu",
                        disabled: true,
                      },
                      {
                        label: this.$t("messages.instance"),
                        onClick: () => {
                          this.instanceSymbol(node);
                        },
                        icon: "iconfont icon-tiaoyong",
                        disabled: true,
                      },
                      {
                        label: this.$t("符号替换"),
                        onClick: () => {
                          bus.$emit("replaceSymbol", node);
                        },
                        icon: "iconfont icon-tihuan",
                        // disabled: true
                      },
                    ],
                    event, // 鼠标事件信息
                    customClass: "custom-class", // 自定义菜单 class
                    zIndex: 1000, // 菜单样式 z-index
                    minWidth: 170, // 主菜单最小宽度
                  });
                }
              }
            } else {
              if (node.data.cell_type == "3") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {},
                      icon: "iconfont icon-xinjian",
                      customClass: "contextMenuIcon",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.OpeninReadOnly"),
                      onClick: () => {
                        this.isReadOnly = true;
                        this.openCellFn(node);
                      },
                      icon: "iconfont icon-yizhidudakai",
                      // disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {},
                      disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {},
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              } else {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.saveToSymbolLibrary"),
                      onClick: () => {
                        bus.$emit("uploadToSymbolLibrary", node);
                      },
                      icon: "iconfont icon-cundaofuhaoku",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {},
                      icon: "iconfont icon-zhongmingming",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {},
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {
                        this.instanceSymbol(node);
                      },
                      icon: "iconfont icon-tiaoyong",
                      disabled: true,
                    },
                    {
                      label: this.$t("符号替换"),
                      onClick: () => {
                        bus.$emit("replaceSymbol", node);
                      },
                      icon: "iconfont icon-tihuan",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          }
        }
      } else if (node.level == 2 && node.data.isTeam == false) {
        this.$contextmenu({
          items: [
            {
              label: this.$t("messages.new"),
              divided: true,
              onClick: () => {
                this.newDialogVisible = true;
                this.userName = localStorage.getItem("userName");
                this.userId = localStorage.getItem("userId");
                this.fileCreate = false;
                if (this.projectRadio == "1") {
                  this.teamListRClick = false;
                  this.userListRClick = true;
                } else {
                  this.userListRClick = false;
                  this.teamListRClick = true;
                }
              },
              icon: "iconfont icon-xinjian",
              customClass: "contextMenuIcon",
            },
            {
              label: this.$t("messages.rename"),
              onClick: () => {
                this.node.data.showInput = true;
                this.$nextTick(() => {
                  this.$refs.resetNameInput.focus();
                  this.$refs.resetNameInput.select();
                });
              },
              icon: "iconfont icon-zhongmingming",
            },
            {
              label: this.$t("messages.delete"),
              onClick: () => {
                this.userKnowChecked = false;
                this.userKnow();
                this.delDialogVisible = true;
                this.deleteName = this.node.data.name;
              },
              icon: "iconfont icon-shanchu",
            },
          ],
          event, // 鼠标事件信息
          customClass: "custom-class", // 自定义菜单 class
          zIndex: 1000, // 菜单样式 z-index
          minWidth: 170, // 主菜单最小宽度
        });
      } else if (node.level == 3 && node.data.isTeam == false) {
        //右键的文件已被打开
        if (
          this.$route.fullPath.split("%2F")[0].split("id=")[1] ===
          node.data.snow_id
        ) {
          //右键版图文件
          if (node.data.file_type == "1") {
            this.$contextmenu({
              items: [
                {
                  label: this.$t("messages.new"),
                  divided: true,
                  onClick: () => {
                    this.newDialogVisible = true;
                    this.userName = localStorage.getItem("userName");
                    this.userId = localStorage.getItem("userId");
                    this.fileCreate = false;
                    this.fileRadio = "1";
                    if (this.projectRadio == "1") {
                      this.teamListRClick = false;
                      this.userListRClick = true;
                    } else {
                      this.userListRClick = false;
                      this.teamListRClick = true;
                    }
                  },
                  icon: "iconfont icon-xinjian",
                  customClass: "contextMenuIcon",
                },
                {
                  label: this.$t("messages.copyTo"),
                  onClick: () => {
                    this.copyDialogVisible = true;
                    this.copyTreeRefresh = "2";
                  },
                  icon: "iconfont icon-fuzhi",
                },
                {
                  label: this.$t("messages.rename"),
                  onClick: () => {
                    this.node.data.showInput = true;
                    this.$nextTick(() => {
                      this.$refs.resetNameInput.focus();
                      this.$refs.resetNameInput.select();
                    });
                  },
                  icon: "iconfont icon-zhongmingming",
                },
                {
                  label: this.$t("messages.delete"),
                  onClick: () => {
                    this.userKnowChecked = false;
                    this.userKnow();
                    this.delDialogVisible = true;
                    this.deleteName = this.node.data.name;
                  },
                  icon: "iconfont icon-shanchu",
                },
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          }
          //右键原理图文件
          else if (node.data.file_type == "3") {
            this.$contextmenu({
              items: [
                {
                  label: this.$t("messages.new"),
                  divided: true,
                  onClick: () => {
                    this.newDialogVisible = true;
                    this.userName = localStorage.getItem("userName");
                    this.userId = localStorage.getItem("userId");
                    this.fileCreate = false;
                    this.fileRadio = "3";
                    if (this.projectRadio == "1") {
                      this.teamListRClick = false;
                      this.userListRClick = true;
                    } else {
                      this.userListRClick = false;
                      this.teamListRClick = true;
                    }
                  },
                  icon: "iconfont icon-xinjian",
                  customClass: "contextMenuIcon",
                },
                {
                  label: this.$t("messages.copyTo"),
                  onClick: () => {
                    this.copyDialogVisible = true;
                    this.copyTreeRefresh = "2";
                  },
                  icon: "iconfont icon-fuzhi",
                },
                {
                  label: this.$t("messages.rename"),
                  onClick: () => {
                    this.node.data.showInput = true;
                    this.$nextTick(() => {
                      this.$refs.resetNameInput.focus();
                      this.$refs.resetNameInput.select();
                    });
                  },
                  icon: "iconfont icon-zhongmingming",
                },
                {
                  label: this.$t("messages.delete"),
                  onClick: () => {
                    this.userKnowChecked = false;
                    this.userKnow();
                    this.delDialogVisible = true;
                    this.deleteName = this.node.data.name;
                  },
                  icon: "iconfont icon-shanchu",
                },
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          }
        }
        //右键的文件没被打开
        else {
          this.$contextmenu({
            items: [
              {
                label: this.$t("messages.new"),
                divided: true,
                onClick: () => {
                  this.newDialogVisible = true;
                  this.userName = localStorage.getItem("userName");
                  this.userId = localStorage.getItem("userId");
                  this.fileCreate = false;
                  this.fileRadio = node.data.file_type;
                  if (this.projectRadio == "1") {
                    this.teamListRClick = false;
                    this.userListRClick = true;
                  } else {
                    this.userListRClick = false;
                    this.teamListRClick = true;
                  }
                },
                icon: "iconfont icon-xinjian",
                customClass: "contextMenuIcon",
              },
              {
                label: this.$t("messages.copyTo"),
                onClick: () => {
                  this.copyDialogVisible = true;
                  this.copyTreeRefresh = "2";
                },
                icon: "iconfont icon-fuzhi",
              },
              {
                label: this.$t("messages.rename"),
                onClick: () => {
                  this.node.data.showInput = true;
                  this.$nextTick(() => {
                    this.$refs.resetNameInput.focus();
                    this.$refs.resetNameInput.select();
                  });
                },
                disabled: node.data.file_type == "3" ? false : true,
                icon: "iconfont icon-zhongmingming",
              },
              {
                label: this.$t("messages.delete"),
                onClick: () => {
                  this.userKnowChecked = false;
                  this.userKnow();
                  this.delDialogVisible = true;
                  this.deleteName = this.node.data.name;
                },
                icon: "iconfont icon-shanchu",
              },
            ],
            event, // 鼠标事件信息
            customClass: "custom-class", // 自定义菜单 class
            zIndex: 1000, // 菜单样式 z-index
            minWidth: 170, // 主菜单最小宽度
          });
        }
      } else if (node.level == 4 && node.data.isTeam == false) {
        if (node.data.cell_type == "1" || node.data.cell_type == "2") {
          // 右键个人临时单元

          if (node.data.isTemporary) {
            if (
              this.$route.fullPath.split("%2F")[
                this.$route.fullPath.split("%2F").length - 1
              ] === node.data.snow_id
            ) {
              this.$contextmenu({
                items: [
                  {
                    label: this.$t("messages.new"),
                    divided: true,
                    onClick: () => {
                      this.newDialogVisible = true;
                      this.userName = localStorage.getItem("userName");
                      this.userId = localStorage.getItem("userId");
                      this.fileCreate = false;
                      this.fileRadio = "1";
                      if (this.projectRadio == "1") {
                        this.teamListRClick = false;
                        this.userListRClick = true;
                      } else {
                        this.userListRClick = false;
                        this.teamListRClick = true;
                      }
                    },
                    icon: "iconfont icon-xinjian",
                  },
                  {
                    label: this.$t("messages.copyTo"),
                    onClick: () => {
                      this.copyDialogVisible = true;
                      this.copyTreeRefresh = "3";
                    },
                    icon: "iconfont icon-fuzhi",
                    disabled: true,
                  },
                  {
                    label: this.$t("messages.SavetoLibrary"),
                    onClick: () => {
                      this.saveToLibraryDialogVisible = true;
                      // bus.$emit("save", true)
                    },
                    icon: "iconfont icon-cundaoqijianku",
                  },
                  {
                    label: this.$t("messages.rename"),
                    onClick: () => {
                      this.node.data.showInput = true;
                      this.$nextTick(() => {
                        this.$refs.resetNameInput.focus();
                        this.$refs.resetNameInput.select();
                      });
                    },
                    icon: "iconfont icon-zhongmingming",
                    disabled: true,
                  },
                  {
                    label: this.$t("messages.delete"),
                    onClick: () => {
                      this.userKnowChecked = false;
                      this.userKnow();
                      this.delDialogVisible = true;
                      this.deleteName = this.node.data.name;
                    },
                    icon: "iconfont icon-shanchu",
                  },
                  {
                    label: this.$t("messages.instance"),
                    onClick: () => {},
                    icon: "iconfont icon-tiaoyong",
                    disabled: true,
                  },
                ],
                event, // 鼠标事件信息
                customClass: "custom-class", // 自定义菜单 class
                zIndex: 1000, // 菜单样式 z-index
                minWidth: 170, // 主菜单最小宽度
              });
            } else {
              if (this.$route.fullPath == "/home/layout") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.SavetoLibrary"),
                      onClick: () => {
                        this.saveToLibraryDialogVisible = true;
                      },
                      icon: "iconfont icon-cundaoqijianku",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {},
                      icon: "iconfont icon-tiaoyong",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              } else {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.SavetoLibrary"),
                      onClick: () => {
                        this.saveToLibraryDialogVisible = true;
                      },
                      icon: "iconfont icon-cundaoqijianku",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {
                        this.isFileCall = true;
                        let fileId =
                          node.data.gns.split("/")[
                            node.data.gns.split("/").length - 2
                          ];
                        if (fileId == this.$store.state.fileInfo.fileId) {
                          this.deferentFileCall = false; //同文件下调用
                          bus.$emit("deviceCallName", node.data.name);
                        } else {
                          let cell = {};
                          this.deferentFileCall = true; //是不同文件调用进来的
                          getCellFile_api({ file_snow_id: fileId })
                            .then((fileRes) => {
                              if (fileRes.data.file_url) {
                                getObjectDataFn(fileRes.data.file_url).then(
                                  (u8_data) => {
                                    window.QGdstk.FS.writeFile(fileId, u8_data);
                                    let callFile =
                                      window.QGdstk.read_gds(fileId);
                                    expand_repetition(callFile);

                                    callFile.cells.forEach((item) => {
                                      if (item.name == node.data.name) {
                                        cell = item;
                                      }
                                    });
                                    let new_lib = new window.QGdstk.Library(
                                      node.data.name,
                                      1e-6,
                                      1e-9
                                    );
                                    new_lib.add(cell);
                                    new_lib.add(cell.dependencies(true));

                                    bus.$emit("addDevice", {
                                      Library: new_lib,
                                      rotation: 0,
                                    });
                                  }
                                );
                              } else {
                              }
                            })
                            .catch((fileErr) => {
                              this.errDialog(
                                this.$t("messages.wrong"),
                                this.$t("messages.code_" + fileErr.code),
                                true
                              );
                            });
                        }
                      },
                      icon: "iconfont icon-tiaoyong",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          }
          // 右键个人非临时单元
          else {
            //右键的单元是当前画板
            if (
              this.$route.fullPath.split("%2F")[
                this.$route.fullPath.split("%2F").length - 1
              ] === node.data.snow_id
            ) {
              //右键打开的个人非临时版图单元
              this.$contextmenu({
                items: [
                  {
                    label: this.$t("messages.new"),
                    divided: true,
                    onClick: () => {
                      this.newDialogVisible = true;
                      this.userName = localStorage.getItem("userName");
                      this.userId = localStorage.getItem("userId");
                      this.fileCreate = false;
                      this.fileRadio = "1";
                      if (this.projectRadio == "1") {
                        this.teamListRClick = false;
                        this.userListRClick = true;
                      } else {
                        this.userListRClick = false;
                        this.teamListRClick = true;
                      }
                    },
                    icon: "iconfont icon-xinjian",
                  },
                  {
                    label: this.$t("messages.copyTo"),
                    onClick: () => {
                      this.copyDialogVisible = true;
                      this.copyTreeRefresh = "3";
                    },
                    icon: "iconfont icon-fuzhi",
                  },
                  {
                    label: this.$t("messages.SavetoLibrary"),
                    onClick: () => {
                      this.saveToLibraryDialogVisible = true;
                      // bus.$emit("save", true)
                    },
                    icon: "iconfont icon-cundaoqijianku",
                  },
                  {
                    label: this.$t("messages.rename"),
                    onClick: () => {
                      this.node.data.showInput = true;
                      this.$nextTick(() => {
                        this.$refs.resetNameInput.focus();
                        this.$refs.resetNameInput.select();
                      });
                    },
                    icon: "iconfont icon-zhongmingming",
                  },
                  {
                    label: this.$t("messages.delete"),
                    onClick: () => {
                      this.userKnowChecked = false;
                      this.userKnow();
                      this.delDialogVisible = true;
                      this.deleteName = this.node.data.name;
                    },
                    icon: "iconfont icon-shanchu",
                  },
                  {
                    label: this.$t("messages.instance"),
                    onClick: () => {},
                    icon: "iconfont icon-tiaoyong",
                    disabled: true,
                  },
                ],
                event, // 鼠标事件信息
                customClass: "custom-class", // 自定义菜单 class
                zIndex: 1000, // 菜单样式 z-index
                minWidth: 170, // 主菜单最小宽度
              });
            } else {
              // 右键的单元不是当前画板
              //未打开画板
              if (this.$route.fullPath == "/home/layout") {
                //右键未打开的原理图
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.SavetoLibrary"),
                      onClick: () => {
                        this.saveToLibraryDialogVisible = true;
                      },
                      icon: "iconfont icon-cundaoqijianku",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {},
                      icon: "iconfont icon-tiaoyong",
                      disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
              // 已打开画板
              //右键的单元不是当前画板
              else {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "1";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.SavetoLibrary"),
                      onClick: () => {
                        this.saveToLibraryDialogVisible = true;
                      },
                      icon: "iconfont icon-cundaoqijianku",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {
                        this.isFileCall = true;
                        let fileId =
                          node.data.gns.split("/")[
                            node.data.gns.split("/").length - 2
                          ];
                        if (fileId == this.$store.state.fileInfo.fileId) {
                          this.deferentFileCall = false; //同文件下调用
                          bus.$emit("deviceCallName", node.data.name);
                        } else {
                          let cell = {};
                          this.deferentFileCall = true; //是不同文件调用进来的

                          getCellFile_api({ file_snow_id: fileId })
                            .then((fileRes) => {
                              if (fileRes.data.file_url) {
                                getObjectDataFn(fileRes.data.file_url).then(
                                  (u8_data) => {
                                    window.QGdstk.FS.writeFile(fileId, u8_data);
                                    let callFile =
                                      window.QGdstk.read_gds(fileId);
                                    expand_repetition(callFile);

                                    callFile.cells.forEach((item) => {
                                      if (item.name == node.data.name) {
                                        cell = item;
                                      }
                                    });
                                    let new_lib = new window.QGdstk.Library(
                                      node.data.name,
                                      1e-6,
                                      1e-9
                                    );
                                    new_lib.add(cell);
                                    new_lib.add(cell.dependencies(true));

                                    bus.$emit("addDevice", {
                                      Library: new_lib,
                                      rotation: 0,
                                    });
                                  }
                                );
                              } else {
                              }
                            })
                            .catch((fileErr) => {
                              this.errDialog(
                                this.$t("messages.wrong"),
                                this.$t("messages.code_" + fileErr.code),
                                true
                              );
                            });
                        }
                      },
                      icon: "iconfont icon-tiaoyong",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          }
        } else if (
          node.data.cell_type == "3" ||
          node.data.cell_type == "4" ||
          node.data.cell_type == "5"
        ) {
          // 判断是否已有映射表
          if (this.$route.fullPath == "/home/layout") {
            //完全没有映射表
            if (node.data.cell_type == "3") {
              this.$contextmenu({
                items: [
                  {
                    label: this.$t("messages.new"),
                    divided: true,
                    onClick: () => {
                      this.newDialogVisible = true;
                      this.userName = localStorage.getItem("userName");
                      this.userId = localStorage.getItem("userId");
                      this.fileCreate = false;
                      this.fileRadio = "3";
                      if (this.projectRadio == "1") {
                        this.teamListRClick = false;
                        this.userListRClick = true;
                      } else {
                        this.userListRClick = false;
                        this.teamListRClick = true;
                      }
                    },
                    icon: "iconfont icon-xinjian",
                  },
                  {
                    label: this.$t("messages.copyTo"),
                    onClick: () => {
                      this.copyDialogVisible = true;
                      this.copyTreeRefresh = "3";
                    },
                    icon: "iconfont icon-fuzhi",
                  },
                  {
                    label: this.$t("messages.OpeninReadOnly"),
                    onClick: () => {
                      this.isReadOnly = true;
                      this.openCellFn(node);
                      // bus.$emit("readOnlySidebar")
                    },
                    icon: "iconfont icon-yizhidudakai",
                  },
                  {
                    label: this.$t("messages.rename"),
                    onClick: () => {
                      this.node.data.showInput = true;
                      this.$nextTick(() => {
                        this.$refs.resetNameInput.focus();
                        this.$refs.resetNameInput.select();
                      });
                    },
                    // disabled: true,
                    icon: "iconfont icon-zhongmingming",
                  },
                  {
                    label: this.$t("messages.delete"),
                    onClick: () => {
                      this.userKnowChecked = false;
                      this.userKnow();
                      this.delDialogVisible = true;
                      this.deleteName = this.node.data.name;
                    },
                    icon: "iconfont icon-shanchu",
                    // disabled: true,
                  },
                ],
                event, // 鼠标事件信息
                customClass: "custom-class", // 自定义菜单 class
                zIndex: 1000, // 菜单样式 z-index
                minWidth: 170, // 主菜单最小宽度
              });
            } else if (
              node.data.cell_type == "4" ||
              node.data.cell_type == "5"
            ) {
              this.$contextmenu({
                items: [
                  {
                    label: this.$t("messages.copyTo"),
                    onClick: () => {
                      this.copyDialogVisible = true;
                      this.copyTreeRefresh = "3";
                    },
                    icon: "iconfont icon-fuzhi",
                  },
                  {
                    label: this.$t("messages.saveToSymbolLibrary"),
                    onClick: () => {
                      bus.$emit("uploadToSymbolLibrary", node);
                    },
                    icon: "iconfont icon-cundaofuhaoku",
                  },
                  {
                    label: this.$t("messages.rename"),
                    onClick: () => {
                      this.node.data.showInput = true;
                      this.$nextTick(() => {
                        this.$refs.resetNameInput.focus();
                        this.$refs.resetNameInput.select();
                      });
                    },
                    // disabled: true,
                    icon: "iconfont icon-zhongmingming",
                  },
                  {
                    label: this.$t("messages.delete"),
                    onClick: () => {
                      this.userKnowChecked = false;
                      this.userKnow();
                      this.delDialogVisible = true;
                      this.deleteName = this.node.data.name;
                    },
                    icon: "iconfont icon-shanchu",
                    // disabled: true,
                  },
                  {
                    label: this.$t("messages.instance"),
                    onClick: () => {},
                    icon: "iconfont icon-tiaoyong",
                    disabled: true,
                  },
                  {
                    label: this.$t("符号替换"),
                    onClick: () => {
                      bus.$emit("replaceSymbol", node);
                    },
                    icon: "iconfont icon-tihuan",
                    // disabled: true
                  },
                ],
                event, // 鼠标事件信息
                customClass: "custom-class", // 自定义菜单 class
                zIndex: 1000, // 菜单样式 z-index
                minWidth: 170, // 主菜单最小宽度
              });
            }
          }
          //是否已存入映射表的
          else if (
            this.$store.state.schemaFileTable.findIndex(
              (item) => item.fileId === node.parent.data.snow_id
            ) !== -1
          ) {
            //是已有映射表的
            if (
              this.$route.fullPath.split("%2F")[
                this.$route.fullPath.split("%2F").length - 1
              ] === node.data.snow_id ||
              this.$store.state.openedTab.some(
                (item) =>
                  item.route.split("/")[item.route.split("/").length - 1] ==
                  node.data.snow_id
              )
            ) {
              //是当前画板对应的
              //右键打开的个人原理图单元
              if (node.data.cell_type == "3") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "3";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              } else {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.saveToSymbolLibrary"),
                      onClick: () => {
                        bus.$emit("uploadToSymbolLibrary", node);
                      },
                      icon: "iconfont icon-cundaofuhaoku",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      // disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      // disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {
                        this.instanceSymbol(node);
                      },
                      icon: "iconfont icon-tiaoyong",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            } else {
              //不是当前画板对应的
              if (node.data.cell_type == "3") {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.new"),
                      divided: true,
                      onClick: () => {
                        this.newDialogVisible = true;
                        this.userName = localStorage.getItem("userName");
                        this.userId = localStorage.getItem("userId");
                        this.fileCreate = false;
                        this.fileRadio = "3";
                        if (this.projectRadio == "1") {
                          this.teamListRClick = false;
                          this.userListRClick = true;
                        } else {
                          this.userListRClick = false;
                          this.teamListRClick = true;
                        }
                      },
                      icon: "iconfont icon-xinjian",
                    },
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.OpeninReadOnly"),
                      onClick: () => {
                        this.isReadOnly = true;
                        this.openCellFn(node);
                      },
                      icon: "iconfont icon-yizhidudakai",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      // disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      // disabled: true,
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              } else {
                this.$contextmenu({
                  items: [
                    {
                      label: this.$t("messages.copyTo"),
                      onClick: () => {
                        this.copyDialogVisible = true;
                        this.copyTreeRefresh = "3";
                      },
                      icon: "iconfont icon-fuzhi",
                    },
                    {
                      label: this.$t("messages.saveToSymbolLibrary"),
                      onClick: () => {
                        bus.$emit("uploadToSymbolLibrary", node);
                      },
                      icon: "iconfont icon-cundaofuhaoku",
                    },
                    {
                      label: this.$t("messages.rename"),
                      onClick: () => {
                        this.node.data.showInput = true;
                        this.$nextTick(() => {
                          this.$refs.resetNameInput.focus();
                          this.$refs.resetNameInput.select();
                        });
                      },
                      // disabled: true,
                      icon: "iconfont icon-zhongmingming",
                    },
                    {
                      label: this.$t("messages.delete"),
                      onClick: () => {
                        this.userKnowChecked = false;
                        this.userKnow();
                        this.delDialogVisible = true;
                        this.deleteName = this.node.data.name;
                      },
                      icon: "iconfont icon-shanchu",
                      // disabled: true,
                    },
                    {
                      label: this.$t("messages.instance"),
                      onClick: () => {
                        this.instanceSymbol(node);
                      },
                      icon: "iconfont icon-tiaoyong",
                    },
                    {
                      label: this.$t("符号替换"),
                      onClick: () => {
                        bus.$emit("replaceSymbol", node);
                      },
                      icon: "iconfont icon-tihuan",
                    },
                  ],
                  event, // 鼠标事件信息
                  customClass: "custom-class", // 自定义菜单 class
                  zIndex: 1000, // 菜单样式 z-index
                  minWidth: 170, // 主菜单最小宽度
                });
              }
            }
          } else {
            if (node.data.cell_type == "3") {
              this.$contextmenu({
                items: [
                  {
                    label: this.$t("messages.new"),
                    divided: true,
                    onClick: () => {
                      this.newDialogVisible = true;
                      this.userName = localStorage.getItem("userName");
                      this.userId = localStorage.getItem("userId");
                      this.fileCreate = false;
                      this.fileRadio = "3";
                      if (this.projectRadio == "1") {
                        this.teamListRClick = false;
                        this.userListRClick = true;
                      } else {
                        this.userListRClick = false;
                        this.teamListRClick = true;
                      }
                    },
                    icon: "iconfont icon-xinjian",
                    customClass: "contextMenuIcon",
                    // disabled: true,
                  },
                  {
                    label: this.$t("messages.copyTo"),
                    onClick: () => {
                      this.copyDialogVisible = true;
                      this.copyTreeRefresh = "3";
                    },
                    icon: "iconfont icon-fuzhi",
                  },
                  {
                    label: this.$t("messages.OpeninReadOnly"),
                    onClick: () => {
                      this.isReadOnly = true;
                      this.openCellFn(node);
                      // bus.$emit("readOnlySidebar")
                    },
                    icon: "iconfont icon-yizhidudakai",
                  },
                  {
                    label: this.$t("messages.rename"),
                    onClick: () => {
                      this.node.data.showInput = true;
                      this.$nextTick(() => {
                        this.$refs.resetNameInput.focus();
                        this.$refs.resetNameInput.select();
                      });
                    },
                    // disabled: true,
                    icon: "iconfont icon-zhongmingming",
                  },
                  {
                    label: this.$t("messages.delete"),
                    onClick: () => {
                      this.userKnowChecked = false;
                      this.userKnow();
                      this.delDialogVisible = true;
                      this.deleteName = this.node.data.name;
                    },
                    icon: "iconfont icon-shanchu",
                    // disabled: true,
                  },
                ],
                event, // 鼠标事件信息
                customClass: "custom-class", // 自定义菜单 class
                zIndex: 1000, // 菜单样式 z-index
                minWidth: 170, // 主菜单最小宽度
              });
            } else {
              this.$contextmenu({
                items: [
                  {
                    label: this.$t("messages.copyTo"),
                    onClick: () => {
                      this.copyDialogVisible = true;
                      this.copyTreeRefresh = "3";
                    },
                    icon: "iconfont icon-fuzhi",
                  },
                  {
                    label: this.$t("messages.saveToSymbolLibrary"),
                    onClick: () => {
                      bus.$emit("uploadToSymbolLibrary", node);
                    },
                    icon: "iconfont icon-cundaofuhaoku",
                  },
                  {
                    label: this.$t("messages.rename"),
                    onClick: () => {
                      this.node.data.showInput = true;
                      this.$nextTick(() => {
                        this.$refs.resetNameInput.focus();
                        this.$refs.resetNameInput.select();
                      });
                    },
                    // disabled: true,
                    icon: "iconfont icon-zhongmingming",
                  },
                  {
                    label: this.$t("messages.delete"),
                    onClick: () => {
                      this.userKnowChecked = false;
                      this.userKnow();
                      this.delDialogVisible = true;
                      this.deleteName = this.node.data.name;
                    },
                    icon: "iconfont icon-shanchu",
                    // disabled: true,
                  },
                  {
                    label: this.$t("messages.instance"),
                    onClick: () => {
                      this.instanceSymbol(node);
                    },
                    icon: "iconfont icon-tiaoyong",
                  },
                  {
                    label: this.$t("符号替换"),
                    onClick: () => {
                      bus.$emit("replaceSymbol", node);
                    },
                    icon: "iconfont icon-tihuan",
                  },
                ],
                event, // 鼠标事件信息
                customClass: "custom-class", // 自定义菜单 class
                zIndex: 1000, // 菜单样式 z-index
                minWidth: 170, // 主菜单最小宽度
              });
            }
          }
        }
      } else if (node.level == 5) {
      }
      return false;
    },

    ////顶部菜单创建
    headerCreate() {
      this.node = "";
      this.clearDialog();
      this.fileCreate = true;
      this.userListRClick = true;
      this.teamListRClick = true;
      this.newDialogVisible = true;
      this.value_userName = this.userName;
    },

    ////错误弹窗测试
    errDialog(title, info, err) {
      this.errTitle = title;
      this.errInfo = info;
      this.isError = err;
      this.errDialogVisible = true;
    },

    ////新建弹窗
    //项目所属
    projectBelong() {
      if (this.fileCreate) {
        this.value_userName = this.userName;
        this.value_teamName = "";
      } else if (this.projectRadio == "1") {
        this.value_userName = this.userName;
      } else {
        this.value_teamName = this.teamName;
      }
      this.newProjectRuleForm.form_projectName = "";
      this.newProjectRuleForm.form_fileName = "";
      this.newProjectRuleForm.form_cellName = "";
      this.newProjectRuleForm.form_schematicName = "";
      this.unitRadio = "1";
    },

    //点击团队名
    teamNameSelect(data) {
      this.options_teamName.forEach((item) => {
        if (item.snow_id == data) {
          this.team_gns = item.gns;
          this.teamId = item.snow_id;
          this.teamName = item.name;
          this.newProjectRuleForm.form_projectName = "";
          this.newProjectRuleForm.form_fileName = "";
          this.newProjectRuleForm.form_cellName = "";
          this.newProjectRuleForm.form_schematicName = "";
          this.unitRadio = "1";
        }
      });
    },
    userProNameSelect(data) {
      let index = this.options_userProName.findIndex(
        (item) => item.value === data
      );
      this.project_gns = this.options_userProName[index].project_gns;
    },

    //获取用户项目列表
    async getUserProName() {
      this.options_userProName.splice(0, this.options_userProName.length);
      let res = await searchFile_api({
        parent_gns: "",
        category: "项目",
        category_child: "个人项目",
        start: 0,
        rows: 50,
        key_words: "",
        types: null,
      });
      if (res.code == 200000) {
        this.project_gns = "";
        res.data.data.forEach((item) => {
          this.options_userProName.push({
            value: item._source.name,
            project_gns: item._source.gns,
          });
        });

        // this.options_userProName.sort()
      } else {
        this.errDialog(
          this.$t("messages.wrong"),
          this.$t("messages.code_" + res.code),
          true
        );
      }
    },

    teamProNameSelect(data) {
      let index = this.options_teamProName.findIndex(
        (item) => item.value === data
      );
      this.project_gns = this.options_teamProName[index].project_gns;
    },

    //获取团队项目列表
    async getTeamProjectNameList() {
      if (this.teamName === "") {
        // this.project_gns = "";
        // this.newProjectRuleForm.form_projectName = "";
        this.errDialog(
          this.$t("messages.wrong"),
          this.$t("messages.fileTip5"),
          true
        );
      } else {
        this.options_teamProName.splice(0, this.options_teamProName.length);
        let proGnsList = [];
        let res = await getTeamProjectList_api({
          team_gns: this.team_gns,
          snow_team_id: this.teamId,
          start: 0,
          rows: 50,
          key_words: "",
        });
        if (res.code == 200000) {
          this.project_gns = "";
          res.data.data.forEach(async (item) => {
            this.options_teamProName.push({
              value: item._source.name,
              project_gns: item._source.gns,
            });
            proGnsList.push(item._source.gns);
          });
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      }
    },

    //项目名搜索
    projectNameSearch(queryString, cb) {
      let options = [];
      if (this.projectRadio == "1") {
        options = this.options_userProName;
      } else {
        options = this.options_teamProName;
      }
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      // callback 返回建议列表的数据

      if (results.length != 0) {
        if (results[0].value === this.newProjectRuleForm.form_projectName) {
          this.project_gns = results[0].project_gns;
        } else {
          this.project_gns = "";
        }
      } else {
        this.project_gns = "";
      }

      this.file_gns = "";
      this.newProjectRuleForm.form_fileName = "";
      cb(results);
    },

    //获取项目名列表
    // async getProjectNameList() {

    //   this.options_projectName.splice(0, this.options_projectName.length);
    //   // if (this.projectRadio == "1") {
    //   let res = await searchFile_api({
    //     parent_gns: "",
    //     category: "项目",
    //     category_child: "个人项目",
    //     start: 0,
    //     rows: 50,
    //     key_words: "",
    //   });
    //   if (res.code == 200000) {
    //     this.project_gns = "";
    //     res.data.data.forEach((item) => {
    //       this.options_projectName.push({
    //         value: item._source.name,
    //         project_gns: item._source.gns,
    //       });
    //     });
    //   } else {
    //     this.errDialog(this.$t('messages.wrong'), this.$t('messages.code_' + res.code), true);
    //   }
    //   // }
    //   // else if (this.projectRadio == "2") {

    //   //   let res = await searchFile_api({
    //   //     parent_gns: this.team_gns,
    //   //     category: "项目",
    //   //     category_child: "团队项目",
    //   //     start: 0,
    //   //     rows: 50,
    //   //     key_words: "",
    //   //   });
    //   //   if (res.code == 200000) {
    //   //     this.project_gns = "";
    //   //     res.data.data.forEach((item) => {
    //   //       this.options_projectName.push({
    //   //         value: item._source.name,
    //   //         project_gns: item._source.gns,
    //   //       });
    //   //     });
    //   //   } else {
    //   //     this.errDialog(this.$t('messages.wrong'), this.$t('messages.code_' + res.code), true);
    //   //   }
    //   // }
    // },

    //文件名搜索
    fileNameSearch(queryString, cb) {
      let options = this.options_fileName;
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      if (this.project_gns != "") {
        if (results.length != 0) {
          if (results[0].value === this.newProjectRuleForm.form_fileName) {
            this.file_gns = results[0].file_gns;
          } else {
            this.file_gns = "";
          }
        } else {
          this.file_gns = "";
        }
      } else {
        this.file_gns = "";
      }
      // callback 返回建议列表的数据
      cb(results);
    },
    //获取文件名列表
    async getFileNameList() {
      this.options_fileName.splice(0, this.options_fileName.length);
      if (this.projectRadio == "1") {
        if (this.project_gns != "") {
          let res = await searchFile_api({
            parent_gns: this.project_gns,
            category: "文件",
            category_child: "个人文件",
            start: 0,
            rows: 50,
            key_words: "",
            types: this.fileRadio,
          });

          if (res.code == 200000) {
            this.file_gns = "";
            res.data.data.forEach((item) => {
              this.options_fileName.push({
                value: item._source.name,
                file_gns: item._source.gns,
              });
            });
          } else {
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + res.code),
              true
            );
          }
        }
      } else if (this.projectRadio == "2") {
        if (this.project_gns != "") {
          let res = await searchFile_api({
            parent_gns: this.project_gns,
            category: "文件",
            category_child: "团队文件",
            start: 0,
            rows: 50,
            key_words: "",
            types: this.fileRadio,
          });

          if (res.code == 200000) {
            this.file_gns = "";
            res.data.data.forEach((item) => {
              this.options_fileName.push({
                value: item._source.name,
                file_gns: item._source.gns,
              });
            });
          } else {
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + res.code),
              true
            );
          }
        }
      }
    },

    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.match(queryString) !== null;
      };
    },
    //下拉框选择项目名
    handleSelectProName(item) {
      this.newProjectRuleForm.form_projectName = item.value;
      this.project_gns = item.project_gns;
      setTimeout(() => {
        this.$refs.projectNameRule.clearValidate();
      }, 500);
    },
    //下拉框选择文件名
    async handleSelectFileName(item) {
      let permissionRes = await getUserPermission_api({
        gns: item.gns,
      });
      // if (permissionRes.code === 200000) {

      //   node.data.teamRule = permissionRes.data.permission
      //   if (node.data.teamRule == "可删除、编辑") {
      //     let lockCellRes = await selectLockCell_api(node.data.snow_id);
      // if (lockCellRes.code === 200000) {
      //   if (lockCellRes.message == "not lock") {
      //     node.data.teamRule = '可删除、编辑'
      //   } else if (lockCellRes.message == "locked") {
      //     node.data.teamRule = "只读"
      //   }
      // }
      //   }

      // }

      this.newProjectRuleForm.form_fileName = item.value;
      this.file_gns = item.file_gns;
      this.$refs.fileNameRule.clearValidate();
    },
    // 项目名搜索框点击
    projectNameFocus() {
      this.$refs.projectNameIcon.classList.add("inputActive");
      if (this.projectRadio == "1") {
        // this.getProjectNameList();
        this.getUserProName();
      } else {
        this.getTeamProjectNameList();
      }
    },
    //项目名失焦
    projectNameBlur() {
      this.$refs.projectNameIcon.classList.remove("inputActive");
      // setTimeout(() => {
      //   this.options_projectName.splice(0, this.options_projectName.length);
      // }, 500);
    },

    //切换版图文件/原理图文件
    changeFileType() {
      this.file_gns = "";
      this.newProjectRuleForm.form_fileName = "";
      this.newProjectRuleForm.form_cellName = "";
      this.newProjectRuleForm.form_schematicName = "";
    },

    //文件名搜索框点击
    fileNameFocus() {
      this.$refs.fileNameIcon.classList.add("inputActive");
      this.getFileNameList();
    },
    //文件名失焦
    fileNameBlur() {
      this.$refs.fileNameIcon.classList.remove("inputActive");
      // setTimeout(() => {
      //   this.options_fileName.splice(0, this.options_fileName.length);
      // }, 500);
    },
    //切换版图单元/器件单元
    changeCellType() {
      if (this.unitRadio == "1") {
        this.unitType = "Layout";
        this.newProjectRuleForm.form_cellName = "";
      } else {
        this.unitType = "component";
        this.newProjectRuleForm.form_cellName = "";
      }
    },

    //所有输入框全部填写才能激活确定
    newDialogBtnActive() {
      if (this.newDialogVisible || this.importDialogVisible) {
        if (this.isImport) {
          if (
            (this.value_userName !== "" || this.value_teamName !== "") &&
            this.newProjectRuleForm.form_projectName !== "" &&
            this.newProjectRuleForm.form_fileName !== ""
          ) {
            this.activateBtn(this.$refs.importDialogBtn);
          } else {
            this.disableBtn(this.$refs.importDialogBtn);
          }
        } else {
          if (
            (this.value_userName !== "" || this.value_teamName !== "") &&
            this.newProjectRuleForm.form_projectName !== "" &&
            this.newProjectRuleForm.form_fileName !== "" &&
            (this.newProjectRuleForm.form_cellName !== "" ||
              this.newProjectRuleForm.form_schematicName !== "")
          ) {
            this.activateBtn(this.$refs.newDialogBtn);
          } else {
            this.disableBtn(this.$refs.newDialogBtn);
          }
        }
      }
    },
    //确定按钮
    newDialogSureButton() {
      this.$refs.newProjectRuleForm.validate(async (valid) => {
        if (valid) {
          this.newDialogVisible = false;
          let accessor_id = "";
          if (this.projectRadio == "1") {
            accessor_id = this.userId;
            await this.createNewPFCFn(accessor_id);
          } else if (this.projectRadio == "2") {
            accessor_id = this.teamId;

            await this.createNewPFCFn(accessor_id);
          }
          this.fileCreate = false;
        } else {
          return false;
        }
      });
    },
    //新建请求
    async createNewPFCFn(accessor_id) {
      bus.$emit("loading", true);
      if (this.fileRadio === "1") {
        createNewPFC_api({
          accessor_type: this.projectRadio,
          accessor_id,
          project_name: this.newProjectRuleForm.form_projectName,
          project_gns: this.project_gns,
          file_name: this.newProjectRuleForm.form_fileName,
          file_type: this.fileRadio,
          file_gns: this.file_gns,
          cell_type: this.unitRadio,
          cell_name: this.newProjectRuleForm.form_cellName,
        })
          .then((res) => {
            this.createButtonVisible = false;
            this.noData = false;
            this.treeVisible = true;
            if (res.code === 405027) {
              //项目没有权限
              this.errDialog(
                this.$t("messages.wrong"),
                this.$t("messages.code_" + res.code),
                true
              );
              bus.$emit("ending", true);
            } else {
              this.refreshTreeNode(res.data);
            }
          })
          .catch((err) => {
            bus.$emit("ending", true);
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + err.code),
              true
            );
            this.resetNewDialog();
          });
      } else if (this.fileRadio === "3") {
        let fileGns = JSON.parse(JSON.stringify(this.file_gns));
        this.isCreateSchemaFile = true;
        let res = await createNewPFC_api({
          accessor_type: this.projectRadio,
          accessor_id,
          project_name: this.newProjectRuleForm.form_projectName,
          project_gns: this.project_gns,
          file_name: this.newProjectRuleForm.form_fileName,
          file_type: "3",
          file_gns: this.file_gns,
          cell_type: "3",
          cell_name: this.newProjectRuleForm.form_schematicName,
        });
        if (res.code === 405027) {
          //项目没有权限
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
          bus.$emit("ending", true);
        } else if (res.code === 200000) {
          this.createButtonVisible = false;
          this.noData = false;
          this.treeVisible = true;
          this.isReadOnly = false;

          let fileId = res.data.file.snow_id;
          let projectGns = res.data.project.gns;
          let schemaName = res.data.cell.name;
          // 同步创建相关原理图结构
          // 判断是否是已存在的原理图文件
          if (fileGns === "") {
            //是完全新建

            let newSchemaFile = this.createNewSchemaFile(res.data.file);
            newSchemaFile.name = schemaName;
            this.createNewSchema(
              res.data.cell.snow_id,
              schemaName,
              newSchemaFile
            );
            let data = {
              fileId,
              fileData: newSchemaFile,
            };
            this.$store.commit("setDataToschemaFileTable", data);
          } else {
            //已存在原理图文件
            //判断是否已被存入映射表
            let schemaSymbolArr = [];
            let index = this.$store.state.schemaFileTable.findIndex(
              (item) => item.fileId === fileId
            );
            if (index !== -1) {
              // 已存入映射表,直接添加

              this.createNewSchema(
                res.data.cell.snow_id,
                schemaName,
                this.$store.state.schemaFileTable[index].fileData
              );
            } else {
              //未存入映射表,获取数据添加

              if (projectGns.split("/").length === 3) {
                let res2 = await getFileList_api({
                  start: 0,
                  rows: 200,
                  parent_gns: fileGns,
                  category: "CELL",
                  category_child: "个人cell",
                });
                if (res2.code === 200000) {
                  res2.data.data.forEach((item) => {
                    schemaSymbolArr.push([
                      item._source.snow_id,
                      item._source.name,
                    ]);
                  });
                  await this.getSchemaFile(
                    schemaSymbolArr,
                    this.file_gns,
                    false
                  );
                }
              } else {
                let res2 = await getFileList_api({
                  start: 0,
                  rows: 200,
                  parent_gns: fileGns,
                  category: "CELL",
                  category_child: "团队cell",
                });
                if (res2.code === 200000) {
                  res2.data.data.forEach((item) => {
                    schemaSymbolArr.push([
                      item._source.snow_id,
                      item._source.name,
                    ]);
                  });
                  await this.getSchemaFile(
                    schemaSymbolArr,
                    this.file_gns,
                    false
                  );
                }
              }
            }
          }
          this.refreshTreeNode(res.data);
        } else {
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
          this.resetNewDialog();
        }
      }
    },

    //创建新原理图文件
    createNewSchemaFile(data) {
      let newSchemaFile = new window.Kernel.SchemaFile();
      newSchemaFile.snow_id = data.snow_id;
      return newSchemaFile;
    },

    //创建新原理图
    createNewSchema(snow_id, name, schemaFile) {
      let newSchame = new window.Kernel.Schema();
      newSchame.snow_id = snow_id;
      newSchame.name = name;
      newSchame.js_obj.isSave = true;
      schemaFile.add_schema(newSchame);
      return newSchame;
    },

    //创建封装符号
    newCapSymbolFn(capSymbol, capSymbolName) {
      this.isCreateCapSymbol = true;
      this.node = this.$refs.tree.getNode(this.$store.state.cellInfo.cellId);

      let file_gns = this.node.parent.data.gns;
      let file_name = this.node.parent.data.name;
      let project_gns = this.node.parent.parent.data.gns;
      let project_name = this.node.parent.parent.data.name;
      this.fileNode = this.$refs.tree.getNode(this.node.parent.data.snow_id);
      this.proNode = this.$refs.tree.getNode(
        this.node.parent.parent.data.snow_id
      );
      this.userNode = this.$refs.tree.getNode(
        this.node.parent.parent.parent.data.snow_id
      );
      let accessor_id = "";
      let accessor_type = "";
      let category_child = "";
      if (this.node.data.isTeam) {
        accessor_id = this.node.parent.parent.parent.data.snow_id;
        accessor_type = "2";
      } else {
        accessor_id = this.userId;
        accessor_type = "1";
      }
      createNewPFC_api({
        accessor_type,
        accessor_id,
        project_name,
        project_gns,
        file_name,
        file_type: "3",
        file_gns,
        cell_type: "5",
        cell_name: capSymbolName,
      }).then((res) => {
        if (res.code === 200000) {
          let schFile = this.getSchFileFromTable(this.node.parent.data.snow_id);
          capSymbol.snow_id = res.data.cell.snow_id;
          capSymbol.name = capSymbolName;
          capSymbol.short_name = capSymbolName;
          schFile.add_cap_symbol(capSymbol);

          if (this.node.data.isTeam) {
            category_child = "团队cell";
          } else {
            category_child = "个人cell";
          }

          this.$refs.tree.append(
            {
              name: res.data.cell.name,
              isLeaf: false,
              isTemporary: false,
              cell_type: "5",
              gns: res.data.cell.gns,
              category: "CELL",
              category_child,
              snow_id: res.data.cell.snow_id,
              isTeam: this.node.data.isTeam,
              teamRule: this.node.data.teamRule,
              epschemaName: `(${capSymbol.children[0].name})`,
              showInput: false,
            },
            this.node.parent
          );
          this.saveSchema(capSymbol, schFile);
          // this.fileNode.loaded = false;
          // this.fileNode.expand();
        }
      });
    },

    //取消按钮
    newDialogCancelButton() {
      this.resetNewDialog();
      if (this.noData) {
        this.createButtonVisible = true;
      }
    },

    newDialogClose() {
      this.resetNewDialog();
      if (this.noData) {
        this.createButtonVisible = true;
      }
    },

    //新建初始化
    resetNewDialog() {
      this.newDialogVisible = false;
      this.fileCreate = false;
      this.projectRadio = "1";
      this.value_teamName = "";
      this.unitRadio = "1";
      this.fileRadio = "1";
      this.isCreate = false;
      this.unitType = "Layout";

      this.$refs.newProjectRuleForm.resetFields();
    },

    ////删除弹窗
    //删除确定
    async delDialogSureButton() {
      if (this.debounce) {
        bus.$emit("loading", true);
        this.debounce = false;
        this.isDelete = true;
        this.delDialogVisible = false;
        this.delRouteArr.splice(0, this.delRouteArr.length);
        if (this.node.data.category == "CELL") {
          let route = `/home/layout/board?id=${this.node.parent.data.snow_id}/${this.node.data.snow_id}`;
          this.delRouteArr.push(route);
          bus.$emit("deleteCell", this.node.data.name);
        } else if (this.node.data.category == "文件") {
          this.$store.state.openedTab.forEach((item) => {
            if (
              item.route
                .split("/")
                [item.route.split("/").length - 2].split("id=")[1] ==
              this.node.data.snow_id
            ) {
              this.delRouteArr.push(item.route);
            }
          });
          this.delSnowIdArr.push(this.node.data.snow_id);
        } else if (this.node.data.category == "项目") {
          this.node.childNodes.forEach((item) => {
            this.delSnowIdArr.push(item.data.snow_id);
            if (item.childNodes.length > 0) {
              item.childNodes.forEach((v) => {
                let fileId =
                  v.data.gns.split("/")[v.data.gns.split("/").length - 2];
                let cellId =
                  v.data.gns.split("/")[v.data.gns.split("/").length - 1];
                let route = `/home/layout/board?id=${fileId}/${cellId}`;
                this.delRouteArr.push(route);
              });
            }
          });
          this.delSnowIdArr.push(this.node.data.snow_id);
        }
        if (this.node.data.isTemporary) {
          this.delRouteArr.forEach((item) => {
            setTimeout(() => {
              this.$store.commit("removeTab", item);
            }, 100);
          });
          this.remove(this.node);
          setTimeout(() => {
            this.$message({
              type: "success",
              message: this.$t("messages.deleteSuccess"),
            });
            bus.$emit("ending", true);
            this.isDelete = false;
          }, 1000);
        } else {
          this.useDeleteApi();
        }
      }
      setTimeout(() => {
        this.debounce = true;
      }, 1000);
    },

    //文件列表状态记录
    //删除操作时同时删除文件列表的历史记录保存
    // clearExpandedListHistory(delSnowIdArr) {

    //   let expandedFilterList = this.expandedList.filter((item) => delSnowIdArr.indexOf(item) == -1);
    //   this.expandedList.splice(0, this.expandedList.length)
    //   this.expandedList = expandedFilterList
    //   localStorage.setItem('expandedList', JSON.stringify(this.expandedList))

    // },

    //删除接口调用
    async useDeleteApi() {
      let res = await deletePFC_api({
        gns: this.gns,
        category: this.category,
      });
      if (res.code == 200000) {
        bus.$emit("updateHistory", "all");
        //同步修改原理图文件结构
        if (this.node.level === 3 && this.node.data.file_type == "3") {
          this.$store.commit(
            "delDataFromschemaFileTable",
            this.node.data.snow_id
          );
        } else if (
          this.node.level === 4 &&
          (this.node.data.cell_type == "3" ||
            this.node.data.cell_type == "4" ||
            this.node.data.cell_type == "5")
        ) {
          //先获取一次原理图文件数据
          let schFile = this.getSchFileFromTable(this.node.parent.data.snow_id);
          if (schFile == null) {
            schFile = await getWholeFileData(
              this.node.parent.data,
              this.node.data.category_child
            );
          }
          //删除对象存储对应的文件数据

          let urlData = await getDiagramFile_api({
            file_snow_id: [this.node.data.snow_id],
          });
          if (urlData.code === 200000) {
            if (urlData.data.length) {
              let time = parseInt(new Date().getTime() / 1000);
              let apiKey = localStorage.getItem("apiKey");
              let bucket_name = localStorage.getItem("bucket_name");
              let url = urlData.data[0].file_url;
              removeObject_api(
                {
                  time,
                  bucket_name,
                  object_path: url,
                },
                {
                  sign: Md5(`${apiKey}_${time}`),
                  username: "test",
                }
              )
                .then((res) => {})
                .catch((err) => {});
            }
            if (this.node.data.cell_type == "3") {
              this.delSchemaFromSchFile(
                schFile,
                this.node.data.snow_id,
                this.node.parent.data.gns
              );
            } else if (this.node.data.cell_type == "4") {
              this.delSymbolFromSchFile(
                schFile,
                this.node.data.snow_id,
                this.node.parent.data.gns
              );
            } else if (this.node.data.cell_type == "5") {
              this.delCapSymbolFromSchFile(
                schFile,
                this.node.data.snow_id,
                this.node.parent.data.gns
              );
            }
          }
        }

        this.remove(this.node);
        this.delRouteArr.forEach((item) => {
          setTimeout(() => {
            this.$store.commit("removeTab", item);
          }, 100);
        });

        setTimeout(() => {
          this.$message({
            type: "success",
            message: this.$t("messages.deleteSuccess"),
          });
          //文件列表状态记录
          // this.clearExpandedListHistory(this.delSnowIdArr)
          bus.$emit("ending", true);
          this.isDelete = false;
        }, 1000);
      } else {
        this.isDelete = false;
        bus.$emit("ending", true);
        this.errDialog(
          this.$t("messages.wrong"),
          this.$t("messages.code_" + res.code),
          true
        );
      }
    },

    //删除原理图文件中的原理图
    async delSchemaFromSchFile(schemaFile, schemaId, fileGns) {
      let schema = {};
      let capSymbol = {};
      let capSymbolArr = [];
      for (let i = 0; i < schemaFile.schemas.size; i++) {
        if (schemaFile.schemas.get(i).snow_id === schemaId) {
          schema = schemaFile.schemas.get(i);
          break;
        }
      }

      for (let i = 0; i < schemaFile.cap_symbols.size; i++) {
        capSymbol = schemaFile.cap_symbols.get(i);
        if (capSymbol.children.length) {
          for (const j in capSymbol.children) {
            if (capSymbol.children[j].snow_id === schema.snow_id) {
              capSymbolArr.push(capSymbol);
            }
          }
        }
      }

      schemaFile.remove_schema(schema);

      await this.saveSchemaPublicFn(schemaFile, "DIAGRAM_FILE", fileGns);
      if (capSymbolArr.length) {
        for (const x in capSymbolArr) {
          await this.saveSchemaPublicFn(
            capSymbolArr[x],
            "SYMBOLS_FILE",
            [fileGns, capSymbolArr[x].snow_id].join("/")
          );
        }
      }
      this.$refs.tree.getNode(schemaFile.snow_id).loaded = false;
      this.$refs.tree.getNode(schemaFile.snow_id).expand();
    },
    //删除原理图文件中的符号
    async delSymbolFromSchFile(schemaFile, symbolId, fileGns) {
      let symbol = {};
      for (let i = 0; i < schemaFile.symbols.size; i++) {
        if (schemaFile.symbols.get(i).snow_id === symbolId) {
          symbol = schemaFile.symbols.get(i);
          break;
        }
      }
      schemaFile.remove_symbol(symbol);
      await this.saveSchemaPublicFn(schemaFile, "DIAGRAM_FILE", fileGns);

      this.$refs.tree.getNode(schemaFile.snow_id).loaded = false;
      this.$refs.tree.getNode(schemaFile.snow_id).expand();
    },
    //删除原理图文件中的封装符号
    async delCapSymbolFromSchFile(schemaFile, capSymbolId, fileGns) {
      let capSymbol = {};
      for (let i = 0; i < schemaFile.cap_symbols.size; i++) {
        if (schemaFile.cap_symbols.get(i).snow_id === capSymbolId) {
          capSymbol = schemaFile.cap_symbols.get(i);
          break;
        }
      }
      schemaFile.remove_cap_symbol(capSymbol);
      await this.saveSchemaPublicFn(schemaFile, "DIAGRAM_FILE", fileGns);

      this.$refs.tree.getNode(schemaFile.snow_id).loaded = false;
      this.$refs.tree.getNode(schemaFile.snow_id).expand();
    },

    //用户确认已知
    userKnow() {
      if (!this.userKnowChecked) {
        this.disableBtn(this.$refs.sureBtn);
      } else {
        this.activateBtn(this.$refs.sureBtn);
      }
    },

    //存到器件库弹窗
    saveToLibrarySureButton() {
      if (this.debounce) {
        this.$refs.saveToLibraryForm.validate((valid) => {
          if (valid) {
            this.debounce = false;
            this.saveToLibraryDialogVisible = false;
            let cell = {};
            let id = this.node.parent.data.snow_id;
            this.Qlib = window.QGdstk.read_gds(id);

            expand_repetition(this.Qlib);
            if (this.Qlib.cells.length) {
              this.Qlib.cells.forEach((item) => {
                if (item.name == this.node.data.name) {
                  cell = item;
                }
              });
              cell.name = this.saveToLibraryForm.form_deviceName;
            } else {
              cell = new window.QGdstk.Cell(
                this.saveToLibraryForm.form_deviceName
              );
            }
            let new_lib = new window.QGdstk.Library(
              this.saveToLibraryForm.form_deviceName,
              1e-6,
              1e-9
            );

            new_lib.add(cell);
            new_lib.add(cell.dependencies(true));

            new_lib.write_gds(this.saveToLibraryForm.form_deviceName);
            let u8ArrFile = window.QGdstk.FS.readFile(
              this.saveToLibraryForm.form_deviceName
            );

            let label_list = this.saveToLibraryForm.form_deviceLabel.join(",");

            var formData = new FormData();
            formData.append("name", this.saveToLibraryForm.form_deviceName);
            formData.append("unit", this.Qlib.unit);
            formData.append("precision", this.Qlib.precision);
            formData.append("label_list", label_list);
            formData.append("notes", this.value_notes);
            formData.append("content", new Blob([u8ArrFile]));
            formData.append("is_cover", 0);

            if (this.value_group == this.$t("messages.MyLibrary")) {
              createMyComponent_api(formData)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: this.$t("messages.saveSuccess"),
                  });
                  this.resetSaveToLibrary();
                })
                .catch((err) => {
                  if (err.code === 400006) {
                    this.coverDialogVisible = true;
                    this.coverFormData = formData;
                  } else {
                    this.errDialog(
                      this.$t("messages.wrong"),
                      this.$t("messages.code_" + err.code),
                      true
                    );
                  }
                });
            } else if (this.value_group == this.$t("messages.TeamLibrary")) {
              // 团队器件库 测试gxyf器件1 (4) ['超导', '半导', '111', '222', __ob__: Observer] 测试用器件1
              formData.append("team_id", this.saveToDeviceTeamInfo.snow_id);
              createTeamComponent_api(formData)
                .then((res) => {
                  if (res.code == 200000) {
                    this.$message({
                      type: "success",
                      message: this.$t("messages.saveSuccess"),
                    });
                  } else {
                    this.errDialog(
                      this.$t("messages.wrong"),
                      this.$t("messages.code_" + res.code),
                      true
                    );
                  }
                  this.resetSaveToLibrary();
                })
                .catch((err) => {
                  if (err.code === 400006) {
                    this.coverDialogVisible = true;
                    this.coverFormData = formData;
                  } else {
                    this.errDialog(
                      this.$t("messages.wrong"),
                      this.$t("messages.code_" + err.code),
                      true
                    );
                  }
                });
            } else if (this.value_group == "公用器件库") {
            } else if (this.value_group == "官方器件库") {
            }
          }
        });
      }
      setTimeout(() => {
        this.debounce = true;
      }, 1000);
    },

    //存到器件库取消按钮
    saveToLibraryCancelButton() {
      this.saveToLibraryDialogVisible = false;
      this.resetSaveToLibrary();
    },

    saveToLibraryDialogClose() {
      this.saveToLibraryDialogVisible = false;
      this.resetSaveToLibrary();
    },

    //存到器件库初始化
    resetSaveToLibrary() {
      this.value_group = "";
      this.value_teamGroup = "";
      // this.saveToLibraryForm.form_deviceName = "";
      // this.saveToLibraryForm.form_deviceLabel.splice(
      //   0,
      //   this.saveToLibraryForm.form_deviceLabel.length
      // );
      this.value_notes = "";
      this.$refs.saveToLibraryForm.resetFields();
      // setTimeout(() => {
      //   this.$refs.saveToLibraryForm.clearValidate();

      // }, 500);
    },

    // 器件库切换
    groupChange(data) {
      this.value_group = data;
    },

    //团队切换
    groupSelectTeam(data) {
      this.value_teamGroup = data;
      this.options_teamName.forEach((item) => {
        if (item.snow_id == data) {
          this.saveToDeviceTeamInfo = item;
        }
      });
    },

    // //器件标签
    // categoryChange(data) {
    //   this.saveToLibraryForm.form_deviceLabel = data;
    // },

    //所有输入框全部填写才能激活确定
    saveToLibraryBtnActive() {
      if (
        this.value_group !== "" &&
        this.saveToLibraryForm.form_deviceName !== "" &&
        this.saveToLibraryForm.form_deviceLabel.length > 0
      ) {
        this.activateBtn(this.$refs.saveToLibraryBtn);
      } else {
        this.disableBtn(this.$refs.saveToLibraryBtn);
      }
    },

    //存到器件库覆盖弹窗
    async coverDialogSureBtn() {
      let formData = this.coverFormData;
      formData.set("is_cover", 1);
      if (this.value_group == this.$t("messages.MyLibrary")) {
        try {
          let res = await createMyComponent_api(formData);
          if (res.code === 200000) {
            this.$message({
              type: "success",
              message: this.$t("messages.saveSuccess"),
            });
            this.resetSaveToLibrary();
          }
        } catch (err) {
          if (res.code === 400006) {
            this.coverDialogVisible = true;
            this.coverFormData = formData;
          }
        }
      } else if (this.value_group == this.$t("messages.TeamLibrary")) {
        try {
          let res = await createTeamComponent_api(formData);
          if (res.code === 200000) {
            this.$message({
              type: "success",
              message: this.$t("messages.saveSuccess"),
            });
            this.resetSaveToLibrary();
          }
        } catch (err) {
          if (res.code === 400006) {
            this.coverDialogVisible = true;
            this.coverFormData = formData;
          }
        }
      }

      this.coverDialogVisible = false;
    },

    //覆盖器件取消按钮
    coverDialogCancelBtn() {
      this.coverDialogVisible = false;
    },

    ////复制到
    //option跳转到顶部
    scrollToTopOption() {},
    copyDialogBtnActive() {
      if (
        this.copyForm.form_copyName !== "" &&
        this.copyForm.treeNodeInfo !== ""
      ) {
        this.activateBtn(this.$refs.copyDialogSureBtn);
      } else {
        this.disableBtn(this.$refs.copyDialogSureBtn);
      }
    },

    //复制到确认
    async copyDialogSureBtn() {
      if (this.debounce) {
        this.debounce = false;
        await this.$refs.copyForm.validate(async (valid) => {
          if (valid) {
            this.copyDialogVisible = false;
            if (this.copy_type == "user" && this.copy_level == "2") {
              this.userNode = this.$refs.tree.$children[0].node;
              bus.$emit("loading", true);
              await this.copyFile(this.userNode);
            } else if (this.copy_type == "user" && this.copy_level == "3") {
              this.userNode = this.$refs.tree.$children[0].node;

              bus.$emit("loading", true);
              await this.copyCell(this.userNode, "1", this.userId);
            } else if (this.copy_type == "team" && this.copy_level == "2") {
              if (this.projectData.teamRule == "可删除、编辑") {
                let index = this.$refs.tree.$children.findIndex(
                  (item) => item.node.data.gns === this.proNode.parent.data.gns
                );
                this.teamNode = this.$refs.tree.$children[index].node;

                bus.$emit("loading", true);
                await this.copyFile(this.teamNode);
              } else {
                this.errDialog(
                  this.$t("messages.wrong"),
                  this.$t("messages.userProjectPermission"),
                  "1"
                );
              }
            } else if (this.copy_type == "team" && this.copy_level == "3") {
              if (this.fileData.teamRule == "可删除、编辑") {
                let index = this.$refs.tree.$children.findIndex(
                  (item) => item.node.data.gns === this.proNode.parent.data.gns
                );

                this.teamNode = this.$refs.tree.$children[index].node;

                bus.$emit("loading", true);
                await this.copyCell(
                  this.teamNode,
                  "2",
                  this.teamNode.data.snow_id
                );
              } else {
                this.errDialog(
                  this.$t("messages.wrong"),
                  this.$t("messages.userProjectPermission"),
                  "1"
                );
              }
            }

            this.copyTreeRefresh = false;
            this.isCopyFile = false;
            this.$refs.copyForm.resetFields();
          } else {
            return false;
          }
        });
      }
      setTimeout(() => {
        this.debounce = true;
      }, 1000);
    },

    //文件复制
    async copyFile(fatherNode) {
      this.isCopyFile = true;
      let file_type = "";
      if (this.node.data.file_type == "1") {
        file_type = "1";
      } else if (this.node.data.file_type == "3") {
        file_type = "3";
      }
      let params = {
        file_snow_id: this.file_snow_id,
        original_project_gns: this.original_project_gns,
        target_project_gns: this.target_project_gns,
        copy_type: this.copy_type,
        file_name: this.copyForm.form_copyName,
        file_type,
      };

      if (fatherNode.loaded) {
        for (let i = 0; i < fatherNode.childNodes.length; i++) {
          if (fatherNode.childNodes[i].data.gns == this.proNode.data.gns) {
            let res = await copyFileToOtherProject_api(params);
            if (res.code == 200000) {
              if (fatherNode.childNodes[i].loaded) {
                fatherNode.childNodes[i].loaded = false;
                fatherNode.childNodes[i].expand();
              } else {
                fatherNode.childNodes[i].expand();
              }
              this.$message({
                type: "success",
                message: this.$t("messages.fileCopySucceeded"),
              });
              bus.$emit("ending", true);
            } else {
              bus.$emit("ending", true);
              this.errDialog(
                this.$t("messages.wrong"),
                this.$t("messages.code_" + res.code),
                "1"
              );
            }
          }
        }
      } else {
        fatherNode.expand();
        try {
          let res = await copyFileToOtherProject_api(params);
          if (res.code == 200000) {
            setTimeout(() => {
              for (let i = 0; i < fatherNode.childNodes.length; i++) {
                if (
                  fatherNode.childNodes[i].data.gns == this.proNode.data.gns
                ) {
                  fatherNode.childNodes[i].expand();
                  this.$message({
                    type: "success",
                    message: this.$t("messages.fileCopySucceeded"),
                  });
                  bus.$emit("ending", true);
                }
              }
            }, 1000);
          }
        } catch (err) {
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + err.code),
            "1"
          );
        }
      }
    },

    //单元复制
    async copyCell(fatherNode, accessor_type, accessor_id) {
      if (this.fileNode.data.file_type == "1") {
        let params = {
          accessor_type,
          accessor_id,
          project_name: this.proNode.data.name,
          project_gns: this.proNode.data.gns,
          file_name: this.fileNode.data.name,
          file_gns: this.fileNode.data.gns,
          file_type: "1",
          cell_type: "1",
          cell_name: this.copyForm.form_copyName,
        };
        let message = this.$t("messages.cellCopySucceeded");
        let cell = {};
        let copyCellName = this.node.data.name;
        let copyToFileName = this.fileNode.data.name;
        let copyToCellName = this.copyForm.form_copyName;
        let copySnow_id = this.node.parent.data.snow_id;
        let copyToSnow_id = this.fileData.snow_id;

        let copyFile = window.QGdstk.read_gds(copySnow_id);
        expand_repetition(copyFile);

        copyFile.cells.forEach((item) => {
          if (item.name == copyCellName) {
            cell = item;
          }
        });
        cell.name = copyToCellName;

        let cellNames = [];
        getCellFile_api({ file_snow_id: copyToSnow_id })
          .then((copyToFileRes) => {
            if (copyToFileRes.code === 200000) {
              this.copyToFile_url = copyToFileRes.data.file_url;

              //文件中有数据
              if (this.copyToFile_url) {
                // getBlobFile
                getObjectDataFn(this.copyToFile_url).then((u8_data) => {
                  window.QGdstk.FS.writeFile(copyToSnow_id, u8_data);
                  let copyToFile = window.QGdstk.read_gds(copyToSnow_id);
                  expand_repetition(copyToFile);

                  let oldDependenciesCells = cell.dependencies(true);
                  for (let i = 0; i < oldDependenciesCells.length; i++) {
                    if (
                      copyToFile.cells.some(
                        (v) => v.name == oldDependenciesCells[i].name
                      )
                    ) {
                      oldDependenciesCells[i].name = this.removeDuplicateNames(
                        oldDependenciesCells[i].name
                      );
                    }
                  }
                  cellNames = this.renameCellNameArr(oldDependenciesCells);

                  copyToFile.add(cell);
                  copyToFile.add(cell.dependencies(true));

                  copyToFile.write_gds(copyToSnow_id);
                  let index = this.$store.state.openedTab.findIndex(
                    (item) =>
                      item.route.split("id=")[1].split("/")[0] === copyToSnow_id
                  );
                  if (index !== -1) {
                    bus.$emit("addCellToOpenedFile", [
                      copyToSnow_id,
                      this.copyForm.form_copyName,
                    ]);
                  }
                  this.batchCreateCells(
                    fatherNode,
                    params,
                    cellNames,
                    copyToSnow_id,
                    message
                  );
                });

                //文件没数据
              } else {
                let new_lib = new window.QGdstk.Library(
                  copyToFileName,
                  1e-6,
                  1e-9
                );
                let oldDependenciesCells = cell.dependencies(true);
                cellNames = this.renameCellNameArr(oldDependenciesCells);
                new_lib.add(cell);
                new_lib.add(cell.dependencies(true));
                new_lib.write_gds(copyToSnow_id);
                this.batchCreateCells(
                  fatherNode,
                  params,
                  cellNames,
                  copyToSnow_id,
                  message
                );
              }
            } else {
              bus.$emit("ending", true);
              this.errDialog(
                this.$t("messages.wrong"),
                this.$t("messages.code_" + copyToFileRes.code),
                true
              );
            }
            // })
            // })
            //   }).catch(file_blobErr => {
            //     bus.$emit("ending", true);
            //     this.errDialog(this.$t('messages.wrong'), this.$t('messages.code_' + file_blobErr.code), true);
            //   })
            // } else {

            // }
          })
          .catch((copyFileErr) => {
            bus.$emit("ending", true);
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + copyFileErr.code),
              true
            );
          });
      } else if (this.fileNode.data.file_type == "3") {
        let copySnow_id = this.node.data.snow_id;

        let copyToSchemaNameArr = [];
        let copyToSymbolNameArr = [];
        let copyToCapSymbolNameArr = [];
        let copySchemaNameArr = [];
        let copySymbolNameArr = [];
        let copyCapSymbolNameArr = [];
        let schemaSymbolArr = [];
        let newCopyData = {};

        let copyToCellData = await this.getFileChildrenList(this.fileNode);

        this.node.data;
        if (this.node.data.cell_type == "3") {
          copyToSchemaNameArr.push(this.copyForm.form_copyName);
        } else if (this.node.data.cell_type == "4") {
          copyToSymbolNameArr.push(this.copyForm.form_copyName);
        } else if (this.node.data.cell_type == "5") {
          copyToCapSymbolNameArr.push(this.copyForm.form_copyName);
        }
        copyToCellData.data.data.forEach((item) => {
          if (item._source.types == "3") {
            copyToSchemaNameArr.push(item._source.name);
          } else if (item._source.types == "4") {
            copyToSymbolNameArr.push(item._source.name);
          } else if (item._source.types == "5") {
            copyToCapSymbolNameArr.push(item._source.name);
          }
          schemaSymbolArr.push([item._source.snow_id, item._source.name]);
        });

        let urlData = await getDiagramFile_api({ file_snow_id: [copySnow_id] });
        if (urlData.code === 200000) {
          if (urlData.data.length) {
            let copyFile = await getCompleteDataFn(urlData.data[0].file_url);

            if (copyFile.schemas.size) {
              for (let i = 0; i < copyFile.schemas.size; i++) {
                if (this.node.data.snow_id == copyFile.schemas.get(i).snow_id) {
                  copyFile.schemas.get(i).name = this.copyForm.form_copyName;
                } else {
                  copyFile.schemas.get(i).name = newSymbolName(
                    copyFile.schemas.get(i).name,
                    copyToSchemaNameArr
                  );
                }
                copySchemaNameArr.push(copyFile.schemas.get(i).name);
              }
            }

            if (copyFile.symbols.size) {
              for (let i = 0; i < copyFile.symbols.size; i++) {
                if (this.node.data.snow_id == copyFile.symbols.get(i).snow_id) {
                  copyFile.symbols.get(i).name = this.copyForm.form_copyName;
                } else {
                  copyFile.symbols.get(i).name = newSymbolName(
                    copyFile.symbols.get(i).name,
                    copyToSymbolNameArr
                  );
                }
                copySymbolNameArr.push(copyFile.symbols.get(i).name);
              }
            }

            if (copyFile.cap_symbols.size) {
              for (let i = 0; i < copyFile.cap_symbols.size; i++) {
                if (
                  this.node.data.snow_id == copyFile.cap_symbols.get(i).snow_id
                ) {
                  copyFile.cap_symbols.get(i).name =
                    this.copyForm.form_copyName;
                } else {
                  copyFile.cap_symbols.get(i).name = newSymbolName(
                    copyFile.cap_symbols.get(i).name,
                    copyToCapSymbolNameArr
                  );
                }
                copyCapSymbolNameArr.push(copyFile.cap_symbols.get(i).name);
              }
            }

            let copySchemaRes = {};
            let copySymbolRes = {};
            let copyCapSymbolRes = {};
            let noErr = true;
            if (copySchemaNameArr.length) {
              copySchemaRes = await this.batchCreateFn(
                this.fileNode.data.snow_id,
                accessor_type,
                accessor_id,
                this.proNode.data.gns,
                this.fileNode.data.name,
                this.fileNode.data.gns,
                "3",
                "3",
                0,
                JSON.stringify(copySchemaNameArr)
              );
              if (copySchemaRes.code !== 200000) {
                noErr = false;
              }
            }
            if (copySymbolNameArr.length) {
              copySymbolRes = await this.batchCreateFn(
                this.fileNode.data.snow_id,
                accessor_type,
                accessor_id,
                this.proNode.data.gns,
                this.fileNode.data.name,
                this.fileNode.data.gns,
                "3",
                "4",
                0,
                JSON.stringify(copySymbolNameArr)
              );
              if (copySymbolRes.code !== 200000) {
                noErr = false;
              }
            }
            if (copyCapSymbolNameArr.length) {
              copyCapSymbolRes = await this.batchCreateFn(
                this.fileNode.data.snow_id,
                accessor_type,
                accessor_id,
                this.proNode.data.gns,
                this.fileNode.data.name,
                this.fileNode.data.gns,
                "3",
                "5",
                0,
                JSON.stringify(copyCapSymbolNameArr)
              );
              if (copyCapSymbolRes.code !== 200000) {
                noErr = false;
              }
            }

            if (noErr) {
              if (copyFile.schemas.size) {
                for (let i = 0; i < copyFile.schemas.size; i++) {
                  if (
                    copyFile.schemas.get(i).snow_id == this.node.data.snow_id
                  ) {
                    copyFile.schemas.get(i).snow_id =
                      copySchemaRes.data[copyFile.schemas.get(i).name].split(
                        "/"
                      )[
                        copySchemaRes.data[copyFile.schemas.get(i).name].split(
                          "/"
                        ).length - 1
                      ];
                    newCopyData = copyFile.schemas.get(i);
                  } else {
                    copyFile.schemas.get(i).snow_id =
                      copySchemaRes.data[copyFile.schemas.get(i).name].split(
                        "/"
                      )[
                        copySchemaRes.data[copyFile.schemas.get(i).name].split(
                          "/"
                        ).length - 1
                      ];
                  }
                }
              }

              if (copyFile.symbols.size) {
                for (let i = 0; i < copyFile.symbols.size; i++) {
                  if (
                    copyFile.symbols.get(i).snow_id == this.node.data.snow_id
                  ) {
                    copyFile.symbols.get(i).snow_id =
                      copySymbolRes.data[copyFile.symbols.get(i).name].split(
                        "/"
                      )[
                        copySymbolRes.data[copyFile.symbols.get(i).name].split(
                          "/"
                        ).length - 1
                      ];
                    newCopyData = copyFile.symbols.get(i);
                  } else {
                    copyFile.symbols.get(i).snow_id =
                      copySymbolRes.data[copyFile.symbols.get(i).name].split(
                        "/"
                      )[
                        copySymbolRes.data[copyFile.symbols.get(i).name].split(
                          "/"
                        ).length - 1
                      ];
                  }
                }
              }

              if (copyFile.cap_symbols.size) {
                for (let i = 0; i < copyFile.cap_symbols.size; i++) {
                  if (
                    copyFile.cap_symbols.get(i).snow_id ==
                    this.node.data.snow_id
                  ) {
                    copyFile.cap_symbols.get(i).snow_id =
                      copyCapSymbolRes.data[
                        copyFile.cap_symbols.get(i).name
                      ].split("/")[
                        copyCapSymbolRes.data[
                          copyFile.cap_symbols.get(i).name
                        ].split("/").length - 1
                      ];
                    newCopyData = copyFile.cap_symbols.get(i);
                  } else {
                    copyFile.cap_symbols.get(i).snow_id =
                      copyCapSymbolRes.data[
                        copyFile.cap_symbols.get(i).name
                      ].split("/")[
                        copyCapSymbolRes.data[
                          copyFile.cap_symbols.get(i).name
                        ].split("/").length - 1
                      ];
                  }
                }
              }

              if (copyFile.schemas.size) {
                for (let i = 0; i < copyFile.schemas.size; i++) {
                  await this.saveSchemaPublicFn(
                    copyFile.schemas.get(i),
                    "DIAGRAM_FILE",
                    copySchemaRes.data[copyFile.schemas.get(i).name]
                  );
                }
              }
              if (copyFile.symbols.size) {
                for (let i = 0; i < copyFile.symbols.size; i++) {
                  await this.saveSchemaPublicFn(
                    copyFile.symbols.get(i),
                    "SYMBOLS_FILE",
                    copySymbolRes.data[copyFile.symbols.get(i).name]
                  );
                }
              }
              if (copyFile.cap_symbols.size) {
                for (let i = 0; i < copyFile.cap_symbols.size; i++) {
                  await this.saveSchemaPublicFn(
                    copyFile.cap_symbols.get(i),
                    "SYMBOLS_FILE",
                    copyCapSymbolRes.data[copyFile.cap_symbols.get(i).name]
                  );
                }
              }

              let schFile = this.getSchFileFromTable(
                this.fileNode.data.snow_id
              );

              if (schFile !== null) {
                // 当目标原理图文件是打开的状态,重命名后-单独保存-添加进文件-保存整个文件

                this.recFileCallArr.splice(0, this.recFileCallArr.length);
                this.recFileCall(newCopyData);

                for (let i = 0; i < this.recFileCallArr.length; i++) {
                  if (this.recFileCallArr[i].constructor.name == "Schema") {
                    schFile.add_schema(this.recFileCallArr[i]);
                  } else if (
                    this.recFileCallArr[i].constructor.name == "Symbol"
                  ) {
                    schFile.add_symbol(this.recFileCallArr[i]);
                  } else if (
                    this.recFileCallArr[i].constructor.name == "CapSymbol"
                  ) {
                    schFile.add_cap_symbol(this.recFileCallArr[i]);
                  }
                }
              } else {
                //当目标原理图文件未获取,获取文件数据,重命名后-单独保存-添加进文件-保存整个文件
                await this.getSchemaFile(
                  schemaSymbolArr,
                  this.fileData.gns,
                  false
                );

                schFile = this.getSchFileFromTable(this.fileNode.data.snow_id);

                this.recFileCallArr.splice(0, this.recFileCallArr.length);
                this.recFileCall(newCopyData);

                for (let i = 0; i < this.recFileCallArr.length; i++) {
                  if (this.recFileCallArr[i].constructor.name == "Schema") {
                    schFile.add_schema(this.recFileCallArr[i]);
                  } else if (
                    this.recFileCallArr[i].constructor.name == "Symbol"
                  ) {
                    schFile.add_symbol(this.recFileCallArr[i]);
                  } else if (
                    this.recFileCallArr[i].constructor.name == "CapSymbol"
                  ) {
                    schFile.add_cap_symbol(this.recFileCallArr[i]);
                  }
                }
              }

              let proGns = this.proNode.data.gns;
              let fileGns = this.fileNode.data.gns;
              await this.saveSchemaPublicFn(schFile, "DIAGRAM_FILE", fileGns);

              if (fatherNode.loaded) {
                let i = fatherNode.childNodes.findIndex(
                  (item) => item.data.gns === proGns
                );
                if (i !== -1) {
                  if (fatherNode.childNodes[i].loaded) {
                    let index = fatherNode.childNodes[i].childNodes.findIndex(
                      (item) => item.data.gns === fileGns
                    );

                    if (index !== -1) {
                      fatherNode.childNodes[i].childNodes[index].loaded = false;
                      fatherNode.childNodes[i].childNodes[index].expand();
                      bus.$emit("ending", true);
                    } else {
                      fatherNode.childNodes[i].loaded = false;
                      fatherNode.childNodes[i].expand();
                      setTimeout(async () => {
                        index = fatherNode.childNodes[i].childNodes.findIndex(
                          (item) => item.data.gns === fileGns
                        );

                        fatherNode.childNodes[i].childNodes[
                          index
                        ].loaded = false;
                        fatherNode.childNodes[i].childNodes[index].expand();
                        bus.$emit("ending", true);
                      }, 1000);
                    }
                  } else {
                    fatherNode.childNodes[i].expand();
                    setTimeout(async () => {
                      for (
                        let index = 0;
                        index < fatherNode.childNodes[i].childNodes.length;
                        index++
                      ) {
                        if (
                          fatherNode.childNodes[i].childNodes[index].data.gns ==
                          fileGns
                        ) {
                          fatherNode.childNodes[i].childNodes[index].expand();
                          bus.$emit("ending", true);
                        }
                      }
                    }, 1000);
                  }
                } else {
                  fatherNode.loaded = false;
                  fatherNode.expand();
                  setTimeout(() => {
                    let i = fatherNode.childNodes.findIndex(
                      (item) => item.data.gns === proGns
                    );
                    if (i !== -1) {
                      fatherNode.childNodes[i].expand();
                      setTimeout(async () => {
                        for (
                          let index = 0;
                          index < fatherNode.childNodes[i].childNodes.length;
                          index++
                        ) {
                          if (
                            fatherNode.childNodes[i].childNodes[index].data
                              .gns == fileGns
                          ) {
                            fatherNode.childNodes[i].childNodes[index].expand();
                            bus.$emit("ending", true);
                          }
                        }
                      }, 1000);
                    }
                  }, 2000);
                }
              } else {
                fatherNode.expand();
                setTimeout(async () => {
                  for (let i = 0; i < fatherNode.childNodes.length; i++) {
                    if (fatherNode.childNodes[i].data.gns == proGns) {
                      fatherNode.childNodes[i].expand();
                      setTimeout(async () => {
                        for (
                          let index = 0;
                          index < fatherNode.childNodes[i].childNodes.length;
                          index++
                        ) {
                          if (
                            fatherNode.childNodes[i].childNodes[index].data
                              .gns == fileGns
                          ) {
                            fatherNode.childNodes[i].childNodes[index].expand();
                            bus.$emit("ending", true);
                          }
                        }
                      }, 2000);
                    }
                  }
                }, 1000);
              }
            }
          }
        }
      }
    },

    //递归children数据去重
    childrenDeDuplicationData() {
      let copyDataArr = [];
      for (var i = 0; i < this.recFileCallArr.length; i++) {
        var flag = true;
        for (var j = 0; j < copyDataArr.length; j++) {
          if (copyDataArr[j].snow_id == this.recFileCallArr[i].snow_id) {
            flag = false;
          }
        }
        if (flag) {
          copyDataArr.push(this.recFileCallArr[i]);
        }
      }
      return copyDataArr;
    },

    //单个批量创建一种文件方法
    async batchCreateFn(
      snow_file_id,
      accessor_type,
      accessor_id,
      project_gns,
      file_name,
      file_gns,
      file_type,
      cell_type,
      is_upload_file,
      cell_name_list
    ) {
      var formData = new FormData();
      formData.append("snow_file_id", snow_file_id);
      formData.append("accessor_type", accessor_type);
      formData.append("accessor_id", accessor_id);
      formData.append("project_gns", project_gns);
      formData.append("file_name", file_name);
      formData.append("file_gns", file_gns);
      formData.append("cell_type", cell_type);
      formData.append("file_type", file_type);
      formData.append("is_upload_file", is_upload_file);
      formData.append("cell_name_list", cell_name_list);
      formData.append("file", new Blob([]));
      let res = await batchCreatePWD_api(formData);
      if (res.code === 200000) {
        return res;
      } else {
      }
    },

    blob2uint8(blob) {
      return blob.arrayBuffer().then((buffer) => {
        return new Uint8Array(buffer);
      });
    },

    // 复制到/另存为图层变化
    layerChangeFn(fileId, message) {
      getLayerList_api({
        file_snow_id: fileId,
      })
        .then((layerInfoRes) => {
          let u8ArrFile = window.QGdstk.FS.readFile(fileId);

          if (layerInfoRes.code === 200000) {
            let newLayerDatas = layerInfoRes.data.data;

            this.$store.state.cellLayerDatas.forEach((item) => {
              if (
                !newLayerDatas.some((v) => v.layerNumber === item.layerNumber)
              ) {
                newLayerDatas.push(item);
              }
            });
            var formData = new FormData();
            formData.append("layer_infos", JSON.stringify(newLayerDatas));
            formData.append("file_snow_id", fileId);
            formData.append("file", new Blob([u8ArrFile]));
            saveCellFile_api(formData)
              .then((saveFileRes) => {
                this.$message({
                  type: "success",
                  message,
                });
                bus.$emit("ending", true);
              })
              .catch((saveFileErr) => {
                bus.$emit("ending", true);
                this.errDialog(
                  this.$t("messages.wrong"),
                  this.$t("messages.code_" + saveFileErr.code),
                  true
                );
              });
          }
        })
        .catch((layerInfoErr) => {
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + layerInfoErr.code),
            true
          );
        });
    },

    //批量创建单元
    batchCreateCells(fatherNode, params, cellNames, file_snow_id, message) {
      if (cellNames.length > 0) {
        let accessor_type = "";
        let accessor_id = "";
        if (this.fileNode.data.isTeam) {
          accessor_type = "2";
          accessor_id = this.proNode.parent.data.snow_id;
        } else {
          accessor_type = "1";
          accessor_id = this.userId;
        }
        var formData = new FormData(); //新建表单对象
        formData.append("snow_file_id", this.fileNode.data.snow_id);
        formData.append("accessor_type", accessor_type);
        formData.append("accessor_id", accessor_id);
        formData.append("project_gns", this.proNode.data.gns);
        formData.append("file_name", this.fileNode.data.name);
        formData.append("file_gns", this.fileNode.data.gns);
        formData.append("cell_type", "2");
        formData.append("file_type", "1");
        formData.append("is_upload_file", 0);
        formData.append("cell_name_list", JSON.stringify(cellNames));
        formData.append("file", new Blob([])); //把文件二进制对象添加到表单对象里
        batchCreatePWD_api(formData)
          .then((res) => {
            this.createAndRefreshCell(
              fatherNode,
              params,
              file_snow_id,
              message
            );
          })
          .catch((err) => {
            bus.$emit("ending", true);
          });
      } else {
        this.createAndRefreshCell(fatherNode, params, file_snow_id, message);
      }
    },

    //复制到取消
    copyDialogCancelButton() {
      this.copyDialogVisible = false;
      this.copyTreeRefresh = false;
      this.isCopyFile = false;
      this.$refs.copyForm.resetFields();
    },

    // 复制到关闭按钮刷新
    copyDialogClose() {
      this.copyDialogVisible = false;
      this.copyTreeRefresh = false;
      this.isCopyFile = false;
      this.$refs.copyForm.resetFields();
    },

    //用户名搜索过滤
    userNameSearch(queryString, cb) {
      var restaurants = this.options_copyName;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item) {},

    userNameFocus() {
      this.$refs.nameIcon.classList.add("inputActive");
    },
    userNameBlur() {
      this.$refs.nameIcon.classList.remove("inputActive");
    },

    // 复制到选择复制到位置
    handleNodeClick2(data, node) {
      this.file_snow_id = this.node.data.snow_id;
      this.original_project_gns = this.node.parent.data.gns;
      if (node.level == "2") {
        this.copy_level = "2";
        if (node.data.isTeam) {
          if (node.data.teamRule === "可删除、编辑") {
            this.target_project_gns = node.data.gns;
            this.projectData = node.data;
            this.proNode = node;
            this.copy_type = "team";
            let path = [node.parent.data.name, node.data.name].join("/");
            this.copyForm.treeNodeInfo = path;
          } else {
            this.copyForm.treeNodeInfo = "";
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.userProjectPermission"),
              true
            );
          }
        } else {
          this.target_project_gns = node.data.gns;
          this.projectData = node.data;
          this.proNode = node;
          this.copy_type = "user";
          let path = [node.parent.data.name, node.data.name].join("/");
          this.copyForm.treeNodeInfo = path;
        }
      } else {
        this.copyForm.treeNodeInfo = "";
      }
    },
    handleNodeClick3(data, node) {
      if (node.level === 2 && node.data.isTeam === true) {
        if (node.data.teamRule !== "可删除、编辑") {
          this.copyForm.treeNodeInfo = "";
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.userProjectPermission"),
            true
          );
        }
      }
      if (node.level == "3") {
        this.copy_level = "3";

        if (node.data.isTeam) {
          if (node.data.teamRule !== "可删除、编辑") {
            this.copyForm.treeNodeInfo = "";
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.userProjectPermission"),
              true
            );
          } else {
            this.projectData = node.parent.data;
            this.proNode = node.parent;
            this.fileNode = node;
            this.fileData = node.data;
            this.copy_type = "team";
            let path = [
              node.parent.parent.data.name,
              node.parent.data.name,
              node.data.name,
            ].join("/");
            this.copyForm.treeNodeInfo = path;
          }
        } else {
          this.copy_type = "user";
          this.projectData = node.parent.data;
          this.proNode = node.parent;
          this.fileNode = node;
          this.fileData = node.data;
          let path = [
            node.parent.parent.data.name,
            node.parent.data.name,
            node.data.name,
          ].join("/");
          this.copyForm.treeNodeInfo = path;
        }
      } else {
        this.copyForm.treeNodeInfo = "";
      }
    },

    ////文件列表
    async fileListNodeClick(data, node) {
      // if (
      //   node.level === 2 &&
      //   node.data.isTeam === true &&
      //   node.data.teamRule === ""
      // ) {
      //   this.errDialog(this.$t('messages.wrong'), this.$t('messages.userProjectPermission'), true);
      // }
    },

    //双击事件
    dblclickFn(data, node) {
      if (
        data.category === "CELL" &&
        data.cell_type != "4" &&
        data.cell_type != "5"
      ) {
        this.isReadOnly = false;
        this.openCellFn(node);
        let query = `${node.parent.data.snow_id}/${node.data.snow_id}`;
        if (this.$route.query.id === query) {
          if (node.level == 4) {
            node.loaded = false;
            node.expand();
          }
          return 0;
        } else {
          bus.$emit("loading", true);
        }
      }
    },

    //双击打开cell添加记录
    async openCellFn(node) {
      let fileId = node.parent.data.snow_id;
      let fileGns = node.parent.data.gns;
      let cellId = node.data.snow_id;
      let projectId = node.parent.parent.data.snow_id;
      let fileNodeData = node.parent.data;
      let proNodeData = node.parent.parent.data;
      let topNodeData = node.parent.parent.parent.data;
      let isReadOnly = this.isReadOnly;
      let lock = true;
      let isOpened = false;
      let schematicBoardSettings = {};
      let schematicCheckSettings = [];
      let schemaSymbolArr = [];
      let isCreate = this.isCreate;
      let canOpen = true;

      if (node.data.cell_type == "1" || node.data.cell_type == "2") {
        if (node.data.isTeam) {
          let permissionRes = await getUserPermission_api({
            gns: node.data.gns,
          });
          if (permissionRes.code === 200000) {
            node.data.teamRule = permissionRes.data.permission;
            if (node.data.teamRule == "可删除、编辑") {
              let lockCellRes = await selectLockCell_api(fileId);
              if (lockCellRes.code === 200000) {
                if (lockCellRes.message == "not lock") {
                  lock = false;
                } else if (lockCellRes.message == "locked") {
                  lock = true;
                  this.errDialog(
                    this.$t("messages.Warning"),
                    "其他人正在使用",
                    false
                  );
                  bus.$emit("ending", true);
                  return;
                }
              }
            } else if (node.data.teamRule == "只读") {
              isReadOnly = true;
            }
          }
        } else {
          lock = false;
        }
      } else if (node.data.cell_type == "3") {
        if (node.data.isTeam && !isReadOnly) {
          let permissionRes = await getUserPermission_api({
            gns: node.data.gns,
          });
          if (permissionRes.code === 200000) {
            node.data.teamRule = permissionRes.data.permission;
            if (node.data.teamRule == "可删除、编辑") {
              let fileOpenedTabArr = [];
              if (this.$store.state.openedTab.length) {
                for (const i in this.$store.state.openedTab) {
                  let gnsCellId = this.$store.state.openedTab[i].route
                    .split("=")
                    [
                      this.$store.state.openedTab[i].route.split("=").length - 1
                    ].split("/")[1];

                  if (
                    gnsCellId === node.data.snow_id &&
                    this.$store.state.openedTab[i].isReadOnly
                  ) {
                    this.errDialog(
                      this.$t("messages.wrong"),
                      "请先关闭以只读模式打开的画板",
                      true
                    );
                    canOpen = false;
                    break;
                  } else {
                    canOpen = true;
                  }
                }
              } else {
                canOpen = true;
              }

              if (canOpen) {
                for (const i in this.$store.state.openedTab) {
                  let gnsFileId = this.$store.state.openedTab[i].route
                    .split("=")
                    [
                      this.$store.state.openedTab[i].route.split("=").length - 1
                    ].split("/")[0];

                  if (gnsFileId === node.parent.data.snow_id) {
                    fileOpenedTabArr.push(this.$store.state.openedTab[i]);
                  }
                }
                if (
                  fileOpenedTabArr.some((item) => item.isReadOnly === false)
                ) {
                  isOpened = true;
                  lock = false;
                }
                if (!isOpened) {
                  let lockCellRes = await selectLockCell_api(fileId);
                  if (lockCellRes.code === 200000) {
                    if (lockCellRes.message == "not lock") {
                      lock = false;
                    } else if (lockCellRes.message == "locked") {
                      lock = true;
                      this.errDialog(
                        this.$t("messages.Warning"),
                        "其他人正在使用",
                        false
                      );
                      bus.$emit("ending", true);
                      return;
                    }
                  }
                }
              }
            } else {
              this.errDialog(
                this.$t("messages.Warning"),
                this.$t("messages.userProjectPermission"),
                false
              );
              bus.$emit("ending", true);
            }
          } else {
            bus.$emit("ending", true);
            this.errDialog(
              this.$t("messages.Warning"),
              this.$t("messages.code_" + permissionRes.code),
              false
            );
          }
        } else if (isReadOnly) {
          // lock = false
        } else if (node.data.isTeam == false && !isReadOnly) {
          if (this.$store.state.openedTab.length) {
            for (const i in this.$store.state.openedTab) {
              let gnsCellId = this.$store.state.openedTab[i].route
                .split("=")
                [
                  this.$store.state.openedTab[i].route.split("=").length - 1
                ].split("/")[1];

              if (
                gnsCellId === node.data.snow_id &&
                this.$store.state.openedTab[i].isReadOnly
              ) {
                this.errDialog(
                  this.$t("messages.wrong"),
                  "请先关闭以只读模式打开的画板",
                  true
                );
                lock = true;
                break;
              } else {
                lock = false;
              }
            }
          } else {
            lock = false;
          }
        } else {
          lock = false;
        }
      }

      if (lock === false || isReadOnly === true) {
        let userId = "";
        let teamId = "";
        if (node.data.isTeam) {
          teamId =
            node.data.gns.split("/")[node.data.gns.split("/").length - 4];
        }
        let query = `${fileId}/${cellId}`;

        let cellInfo = [];

        if (node.data.isTeam) {
          cellInfo = [
            {
              fileId,
              name: fileNodeData.name,
              teamRule: node.data.teamRule,
              // file_type:fileNodeData.file_type,
            },
            {
              cellId,
              name: node.data.name,
              teamRule: node.data.teamRule,
              isSaved: true,
              isReadOnly,
              cell_type: node.data.cell_type,
            },
            {
              projectId,
              name: proNodeData.name,
              teamRule: proNodeData.teamRule,
            },
            {
              teamId,
              name: topNodeData.name,
              teamRole: topNodeData.role,
            },
          ];
        } else {
          cellInfo = [
            {
              fileId: fileId,
              name: fileNodeData.name,
              // file_type:fileNodeData.file_type,
            },
            {
              cellId: cellId,
              name: node.data.name,
              isSaved: true,
              isReadOnly,
              cell_type: node.data.cell_type,
            },
            {
              projectId,
              name: proNodeData.name,
            },
            {
              userId,
              name: topNodeData.name,
            },
          ];
        }

        //是原理图相关操作
        if (node.data.cell_type == "3") {
          //判断是否已经存储过项目设置

          if (
            this.$store.state.schemaBoardSettingsArr.findIndex(
              (item) => item.projectId === projectId
            ) === -1
          ) {
            let userConfig = await get_user_config_api({
              project_id: projectId,
            });

            if (userConfig.code === 200000) {
              if (JSON.stringify(userConfig.data.show_set) === "{}") {
                schematicBoardSettings = {
                  value_backgroundColor: "#ffffff",
                  value_gridColor: "#000000",
                  gridVisible: "1",
                  adsorption: 1,
                  gridSize: 1,
                };
              } else {
                schematicBoardSettings = {
                  value_backgroundColor:
                    userConfig.data.show_set.value_backgroundColor,
                  value_gridColor: userConfig.data.show_set.value_gridColor,
                  gridVisible: userConfig.data.show_set.gridVisible,
                  adsorption: userConfig.data.show_set.adsorption,
                  gridSize: userConfig.data.show_set.gridSize,
                };
              }

              if (JSON.stringify(userConfig.data.check_set) !== "{}") {
                if (userConfig.data.check_set.tableData.length > 0) {
                  schematicCheckSettings = userConfig.data.check_set.tableData;
                } else {
                  schematicCheckSettings = this.defaultTableData;
                }
              } else {
                schematicCheckSettings = this.defaultTableData;
              }
              let schematicBoardSet = {
                projectId,
                schematicBoardSettings,
              };
              let schematicCheckSet = {
                projectId,
                schematicCheckSettings,
              };
              this.$store.commit("setShemaBoardSettings", schematicBoardSet);
              this.$store.commit("setSchemaCheckSettings", schematicCheckSet);
            }
          }
          node.parent.childNodes.forEach((item) => {
            schemaSymbolArr.push([item.data.snow_id, item.data.name]);
          });

          if (!this.isCreateSchemaFile) {
            // 判断是否已存入映射表

            if (
              this.$store.state.schemaFileTable.findIndex(
                (item) => item.fileId === fileId
              ) === -1
            ) {
              // 没有
              await this.getSchemaFile(schemaSymbolArr, fileGns, false);
            } else {
              //有
              await this.getSchemaFile(schemaSymbolArr, fileGns, true);
            }
            this.isCreateSchemaFile = false;
          } else {
            this.isCreateSchemaFile = false;
          }
        }

        let route = `/home/layout/board?id=${query}`;

        if (this.$route.query.id === query) {
          return 0;
        } else {
          //切换文件菜单/工具栏
          // bus.$emit("controlRight", false);
          bus.$emit("changeMenuAndSidebar", node.data.cell_type);
          bus.$emit("openCell", cellInfo);

          this.openedTab = this.$store.state.openedTab;

          let tabNum = this.openedTab.findIndex((item) => item.route == route);

          this.$store.commit("setCellInfo", cellInfo);
          setTimeout(() => {
            let routeInfo = {
              route,
              isReadOnly,
            };
            if (tabNum === -1) {
              // 该标签当前没有打开

              this.$store.commit("addTab", routeInfo);
              // if (node.data.cell_type == "1" || node.data.cell_type == "2") {
              //   bus.$emit("getFileId", query);
              // }
            } else {
              // 该标签是已经打开过的，需要激活此标签页

              this.$store.commit("activeTab", routeInfo);
            }
            router.push({
              path: "/home/layout/board",
              query: {
                id: query,
              },
            });

            if (node.data.cell_type == "1") {
              bus.$emit("fileBarIcon", "1");
            } else if (node.data.cell_type == "2") {
              bus.$emit("fileBarIcon", "2");
            } else if (node.data.cell_type == "3") {
              bus.$emit("fileBarIcon", "3");
            }

            //获取显示设置,检查设置
            if (isCreate && node.data.cell_type == "3") {
              let saveFile = this.getSchFileFromTable(fileId);
              let saveSch = this.getSchemaFromSchFile(saveFile, cellId);
              saveSch.js_obj.isSave = false;
              this.saveSchema(saveSch, saveFile);
            }

            node.isLeaf = false;
            node.data.isLeaf = false;

            this.updateFileTree();

            setTimeout(() => {
              if (node.data.cell_type == "1" || node.data.cell_type == "2") {
                if (!node.data.isTemporary) {
                  if (isReadOnly) {
                    return 0;
                  } else {
                    bus.$emit("updateHistory", node.data.snow_id);
                  }
                }
              } else if (node.data.cell_type == "3") {
                if (isReadOnly) {
                  return 0;
                } else {
                  bus.$emit("updateHistory", node.data.snow_id);
                }
              }
            }, 500);
          }, 500);
        }
      }
    },

    //清除弹框数据
    clearDialog() {
      this.value_userName = "";
      this.projectRadio = "1";
      this.teamName = "";
      this.team_gns = "";
      this.newProjectRuleForm.form_projectName = "";
      this.newProjectRuleForm.form_fileName = "";
      this.category = "";
      this.project_gns = "";
      this.file_gns = "";
      this.newProjectRuleForm.form_cellName = "";
    },

    //右击事件
    rightClick(event, data, node) {
      this.node = node;
      this.gns = data.gns;
      this.clearDialog();
      if (node.level !== 5) {
        let gnsArr = data.gns.split("/");
        if (!data.isTeam) {
          this.projectRadio = "1";

          if (gnsArr.length == 3) {
            this.value_userName = node.parent.data.name;
            this.category = node.data.category;
            this.project_gns = "";
            this.file_gns = "";
          } else if (gnsArr.length == 4) {
            this.value_userName = node.parent.parent.data.name;
            this.newProjectRuleForm.form_projectName = node.parent.data.name;
            this.category = node.data.category;
            gnsArr.pop();
            let proGns = gnsArr.join("/");
            this.project_gns = proGns;
            this.file_gns = "";
          } else if (gnsArr.length == 5) {
            this.value_userName = node.parent.parent.parent.data.name;
            this.newProjectRuleForm.form_projectName =
              node.parent.parent.data.name;
            this.newProjectRuleForm.form_fileName = node.parent.data.name;
            this.category = node.data.category;
            gnsArr.pop();
            let fileGns = gnsArr.join("/");
            gnsArr.pop();
            let proGns = gnsArr.join("/");
            this.project_gns = proGns;
            this.file_gns = fileGns;
          }
        } else if (data.isTeam) {
          this.projectRadio = "2";
          if (gnsArr.length == 4) {
            this.team_gns = node.parent.data.gns;
            this.teamName = node.parent.data.name;
            this.teamId = node.parent.data.snow_id;
            this.value_teamName = node.parent.data.name;
            this.category = node.data.category;
            this.project_gns = "";
            this.file_gns = "";
          } else if (gnsArr.length == 5) {
            this.team_gns = node.parent.parent.data.gns;
            this.teamId = node.parent.parent.data.snow_id;
            this.teamName = node.parent.parent.data.name;
            this.value_teamName = node.parent.parent.data.name;
            this.newProjectRuleForm.form_projectName = node.parent.data.name;
            this.category = node.data.category;
            gnsArr.pop();
            let proGns = gnsArr.join("/");
            this.project_gns = proGns;
            this.file_gns = "";
          } else if (gnsArr.length == 6) {
            this.team_gns = node.parent.parent.parent.data.gns;
            this.teamId = node.parent.parent.parent.data.snow_id;
            this.teamName = node.parent.parent.parent.data.name;
            this.value_teamName = node.parent.parent.parent.data.name;
            this.newProjectRuleForm.form_projectName =
              node.parent.parent.data.name;
            this.newProjectRuleForm.form_fileName = node.parent.data.name;
            this.category = node.data.category;
            gnsArr.pop();
            let fileGns = gnsArr.join("/");
            gnsArr.pop();
            let proGns = gnsArr.join("/");
            this.project_gns = proGns;
            this.file_gns = fileGns;
          }
        }
      }
    },

    //文件树懒加载
    async loadNode(node, resolve) {
      if (node.level == "0") {
        let data = [];
        this.treeNameList.forEach((item) => {
          data.push(item);
        });
        return resolve(data);
      } else if (node.level == "1" && node.data.isTeam == false) {
        //个人结点的展开
        //文件列表状态记录
        // this.nodeExpand(node.data, node)
        let res = await getUserProjectList_api({
          start: 0,
          rows: 50,
        });
        if (res.code == 200000) {
          let data = [];
          res.data.data.forEach((item) => {
            data.push({
              name: item._source.name,
              gns: item._source.gns,
              category: item._source.category,
              category_child: item._source.category_child,
              snow_id: item._source.snow_id,
              showInput: false,
              isTeam: false,
            });
          });

          setTimeout(async () => {
            if (this.refreshUserNode) {
              await this.expandProjectNode(500);
            }
          }, 500);
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "1" && node.data.isTeam == true) {
        //文件列表状态记录
        // this.nodeExpand(node.data, node)
        let res = await getTeamProjectList_api({
          start: 0,
          rows: 50,
          snow_team_id: node.data.snow_id,
          team_gns: node.data.gns,
          key_word: "",
        });
        if (res.code == 200000) {
          let data = [];
          res.data.data.forEach((item) => {
            data.push({
              name: item._source.name,
              gns: item._source.gns,
              category: item._source.category,
              category_child: item._source.category_child,
              snow_id: item._source.snow_id,
              showInput: false,
              isTeam: true,
              teamRule: "",
              // isLeaf: true,
            });
          });
          setTimeout(async () => {
            if (this.refreshUserNode) {
              await this.expandProjectNode(500);
            }
          }, 500);
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "2" && node.data.isTeam == false) {
        //文件列表状态记录
        // this.nodeExpand(node.data, node)
        let res = await getFileList_api({
          start: 0,
          rows: 200,
          parent_gns: node.data.gns,
          category: "文件",
          category_child: "个人文件",
        });
        if (res.code == 200000) {
          let data = [];
          res.data.data.forEach((item) => {
            data.push({
              name: item._source.name,
              gns: item._source.gns,
              category: item._source.category,
              category_child: item._source.category_child,
              snow_id: item._source.snow_id,
              showInput: false,
              isTeam: false,
              file_type: item._source.types,
            });
          });

          setTimeout(async () => {
            if (this.refreshProjectNode) {
              await this.expandFileNode(500);
              if (this.copyFile) {
                bus.$emit("ending", true);
              }
            }
          }, 500);
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "2" && node.data.isTeam == true) {
        //文件列表状态记录
        // this.nodeExpand(node.data, node)
        let res = await getFileList_api({
          start: 0,
          rows: 200,
          parent_gns: node.data.gns,
          category: "文件",
          category_child: "团队文件",
        });
        if (res.code == 200000) {
          let data = [];
          res.data.data.forEach((item) => {
            data.push({
              name: item._source.name,
              gns: item._source.gns,
              category: item._source.category,
              category_child: item._source.category_child,
              snow_id: item._source.snow_id,
              showInput: false,
              isTeam: true,
              teamRule: "",
              file_type: item._source.types,
              // isLeaf: true,
            });
          });

          setTimeout(async () => {
            if (this.refreshProjectNode) {
              await this.expandFileNode(500);
              if (this.copyFile) {
                bus.$emit("ending", true);
              }
            }
          }, 500);
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "3" && node.data.isTeam == false) {
        //文件列表状态记录
        // this.nodeExpand(node.data, node)
        let res = await getCellList_api({
          start: 0,
          rows: 200,
          parent_gns: node.data.gns,
          category: "CELL",
          category_child: "个人cell",
        });

        if (res.code == 200000) {
          let data = [];

          if (node.data.file_type == "1") {
            res.data.data.forEach((item) => {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                cell_type: item._source.types,
                isTeam: false,
                isLeaf: false,
                isTemporary: false,
              });
            });
          } else if (node.data.file_type == "3") {
            res.data.data.forEach((item) => {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                cell_type: item._source.types,
                isTeam: false,
                isLeaf: true,
                isTemporary: false,
                children: [],
                epschemaName: "",
              });
            });
            let urlData = await getDiagramFile_api({
              file_snow_id: [node.data.snow_id],
            });
            if (urlData.data.length > 0) {
              let u8_data = await getObjectDataFn(urlData.data[0].file_url);
              let newSchemaFile = new Kernel.SchemaFile();
              newSchemaFile.load(u8_data);

              if (newSchemaFile.cap_symbols.size) {
                for (let i = 0; i < newSchemaFile.cap_symbols.size; i++) {
                  for (let j = 0; j < data.length; j++) {
                    if (
                      newSchemaFile.cap_symbols.get(i).snow_id ===
                      data[j].snow_id
                    ) {
                      if (newSchemaFile.cap_symbols.get(i).children.length) {
                        data[j].epschemaName = `(${
                          newSchemaFile.cap_symbols.get(i).children[0].name
                        })`;
                      } else {
                      }
                    }
                  }
                }
              }
            }
          }

          setTimeout(async () => {
            if (
              this.isCreate &&
              !this.isImport &&
              !this.isSplit &&
              node.data.snow_id === this.fileData.snow_id
            ) {
              let cellNode = node.childNodes.filter(
                (item) => item.data.snow_id === this.cellData.snow_id
              )[0];
              this.openCellFn(cellNode);
              this.isCreate = false;
              this.$message({
                type: "success",
                message: this.$t("messages.createSucceeded"),
              });
            } else if (this.isImport) {
              this.$message({
                type: "success",
                message: this.$t("messages.importSucceeded"),
              });
              this.resetImportDialog();
              this.isCreate = false;
            } else if (this.isSplit) {
              this.$message({
                type: "success",
                message: this.$t("messages.layoutSplitSuccessfully"),
              });
              this.isSplit = false;
              this.isCreate = false;
            }
            bus.$emit("ending", true);
          }, 1000);
          return resolve(data);
        } else {
          this.isSplit = false;
          this.isImport = false;
          this.isCreate = false;
          this.resetImportDialog();
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "3" && node.data.isTeam == true) {
        //文件列表状态记录
        // this.nodeExpand(node.data, node)
        let res = await getCellList_api({
          start: 0,
          rows: 200,
          parent_gns: node.data.gns,
          category: "CELL",
          category_child: "团队cell",
        });
        if (res.code == 200000) {
          let data = [];

          if (node.data.file_type == "1") {
            res.data.data.forEach((item) => {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                cell_type: item._source.types,
                isTeam: true,
                teamRule: "",
                // isLeaf: true,
                isTemporary: false,
              });
            });
          } else if (node.data.file_type == "3") {
            res.data.data.forEach((item) => {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                cell_type: item._source.types,
                isTeam: true,
                teamRule: "",
                isLeaf: true,
                isTemporary: false,
                children: [],
                epschemaName: "",
              });
            });
            let urlData = await getDiagramFile_api({
              file_snow_id: [node.data.snow_id],
            });
            if (urlData.data.length > 0) {
              let u8_data = await getObjectDataFn(urlData.data[0].file_url);
              let newSchemaFile = new Kernel.SchemaFile();
              newSchemaFile.load(u8_data);

              if (newSchemaFile.cap_symbols.size) {
                for (let i = 0; i < newSchemaFile.cap_symbols.size; i++) {
                  for (let j = 0; j < data.length; j++) {
                    if (
                      newSchemaFile.cap_symbols.get(i).snow_id ===
                      data[j].snow_id
                    ) {
                      if (newSchemaFile.cap_symbols.get(i).children.length) {
                        data[j].epschemaName = `(${
                          newSchemaFile.cap_symbols.get(i).children[0].name
                        })`;
                      } else {
                      }
                    }
                  }
                }
              }
            }
          }

          setTimeout(() => {
            if (
              this.isCreate &&
              !this.isImport &&
              !this.isSplit &&
              node.data.snow_id === this.fileData.snow_id
            ) {
              let cellNode = node.childNodes.filter(
                (item) => item.data.snow_id === this.cellData.snow_id
              )[0];
              this.openCellFn(cellNode);
              this.isCreate = false;
              this.$message({
                type: "success",
                message: this.$t("messages.createSucceeded"),
              });
            } else if (this.isImport) {
              this.$message({
                type: "success",
                message: this.$t("messages.importSucceeded"),
              });
              this.resetImportDialog();
              this.isCreate = false;
            } else if (this.isSplit) {
              this.$message({
                type: "success",
                message: this.$t("messages.layoutSplitSuccessfully"),
              });
              this.isSplit = false;
              this.isCreate = false;
            }
            bus.$emit("ending", true);
          }, 1000);
          return resolve(data);
        } else {
          this.isSplit = false;
          this.isImport = false;
          this.isCreate = false;
          this.resetImportDialog();
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "4" && node.data.cell_type == "1") {
        let cell = null;
        let id = node.parent.data.snow_id;
        this.Qlib = window.QGdstk.read_gds(id);
        expand_repetition(this.Qlib);

        this.Qlib.cells.forEach((item) => {
          if (item.name == node.data.name) {
            cell = item;
          }
        });
        if (cell) {
          let callingDevice = cell.dependencies(false);

          let data = [];

          if (callingDevice.length > 0) {
            node.data.isLeaf = false;
            callingDevice.forEach((item) => {
              // 随机数
              let id = Math.random().toString(36).substr(2, 10);
              data.push({ name: item.name, isLeaf: true, snow_id: id });
            });
          } else {
          }
          return resolve(data);
        } else {
          return resolve([]);
        }
      } else if (node.level >= "4" && node.data.cell_type != "1") {
        // 获取当前节点下级结构树
        let data = [];
        let currentData = {};
        let father_id = "";
        if (node.level == "4") {
          father_id = node.parent.data.snow_id;
        } else {
          father_id = node.data.father_id;
        }
        let schFile = this.getSchFileFromTable(father_id);

        if (schFile === null) {
          return resolve([]);
        } else {
          if (node.level == "4") {
            schFile.children.forEach((item) => {
              if (item.snow_id === node.data.snow_id) {
                currentData = item;
              }
            });
            if (node.data.cell_type == "3") {
              node.isLeaf = false;
            } else {
              node.isLeaf = true;
            }
          } else {
            schFile.children.forEach((item) => {
              if (item.snow_id === node.data.ins_snow_id) {
                currentData = item;
              }
            });
          }

          if (currentData.children.length) {
            currentData.children.forEach((item) => {
              let type = this.getCtorType(item.type);
              if (type == "5") {
                if (item.children.length) {
                  let cap_schema = item.children[0];
                  data.push({
                    name: item.name,
                    isLeaf: true,
                    father_id,
                    ins_snow_id: item.snow_id,
                    children: item.children,
                    cell_type: type,
                  });
                  data.push({
                    name: cap_schema.name,
                    isLeaf: false,
                    father_id,
                    ins_snow_id: cap_schema.snow_id,
                    children: cap_schema.children,
                    cell_type: "3",
                  });
                  node.data.isLeaf = false;
                } else {
                  data.push({
                    name: item.name,
                    isLeaf: true,
                    father_id,
                    ins_snow_id: item.snow_id,
                    children: [],
                    cell_type: type,
                  });
                  node.data.isLeaf = true;
                }
              } else {
                if (item.children.length) {
                  data.push({
                    name: item.name,
                    isLeaf: false,
                    father_id,
                    ins_snow_id: item.snow_id,
                    children: item.children,
                    cell_type: type,
                  });
                  node.data.isLeaf = false;
                } else {
                  data.push({
                    name: item.name,
                    isLeaf: true,
                    father_id,
                    ins_snow_id: item.snow_id,
                    children: [],
                    cell_type: type,
                  });
                  node.data.isLeaf = true;
                }
              }
            });
          }

          return resolve(data);
        }
      }
      this.refreshUserNode = false;
      this.refreshProjectNode = false;
    },

    // 查找枚举类,获取对应类别
    getCtorType(ctor) {
      if (ctor.value === 1) {
        //符号
        return "4";
      } else if (ctor.value === 2) {
        //封装符号
        return "5";
      } else if (ctor.value === 3) {
        //原理图
        return "3";
      }
    },

    //获取文件列表下级结构
    async getFileChildrenList(node) {
      let category = "CELL";
      let category_child = "";
      if (node.data.isTeam) {
        category_child = "团队cell";
      } else {
        category_child = "个人cell";
      }
      let res = await getCellList_api({
        start: 0,
        rows: 200,
        parent_gns: node.data.gns,
        category,
        category_child,
      });
      return res;
    },

    async getFileChildrenList(node) {
      let category = "CELL";
      let category_child = "";
      if (node.data.isTeam) {
        category_child = "团队cell";
      } else {
        category_child = "个人cell";
      }
      let res = await getCellList_api({
        start: 0,
        rows: 200,
        parent_gns: node.data.gns,
        category,
        category_child,
      });
      return res;
    },

    async copyLoadNode2(node, resolve) {
      if (node.level == "0") {
        let data = [];
        this.treeNameList.forEach((item) => {
          data.push(item);
        });
        return resolve(data);
      } else if (node.level == "1" && node.data.isTeam == false) {
        let res = await getUserProjectList_api({
          start: 0,
          rows: 50,
        });
        if (res.code == 200000) {
          let data = [];
          res.data.data.forEach((item) => {
            data.push({
              name: item._source.name,
              gns: item._source.gns,
              category: item._source.category,
              category_child: item._source.category_child,
              snow_id: item._source.snow_id,
              showInput: false,
              isTeam: false,
              isLeaf: true,
            });
          });
          if (data.length === 0) {
            this.errDialog(
              this.$t("messages.Warning"),
              this.$t("messages.NoProjectsUnderUser"),
              false
            );
          }

          setTimeout(async () => {
            if (this.refreshUserNode) {
              await this.expandProjectNode(500);
            }
          }, 500);
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "1" && node.data.isTeam == true) {
        let res = await getTeamProjectList_api({
          start: 0,
          rows: 50,
          snow_team_id: node.data.snow_id,
          team_gns: node.data.gns,
          key_word: "",
        });
        if (res.code == 200000) {
          let data = [];
          let projectGnsList = [];

          for (let i = 0; i < res.data.data.length; i++) {
            projectGnsList.push(res.data.data[i]._source.gns);
          }

          let permissionRes = await getTeamPermissionList_api({
            project_gns_list: projectGnsList,
            snow_team_id: node.data.snow_id,
          });
          if (permissionRes.code === 200000) {
            res.data.data.forEach((item) => {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                isTeam: true,
                teamRule: "",
                isLeaf: true,
              });
            });
            if (data.length === 0) {
              this.errDialog(
                this.$t("messages.Warning"),
                this.$t("messages.NoProjectsUnderTeam"),
                false
              );
            }
            data.forEach((item) => {
              permissionRes.data.forEach((value) => {
                if (item.gns == value.gns) {
                  item.teamRule = value.permission;
                }
              });
            });

            setTimeout(async () => {
              if (this.refreshUserNode) {
                await this.expandProjectNode(500);
              }
            }, 500);
            return resolve(data);
          } else {
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + permissionRes.code),
              true
            );
          }
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level >= "2") {
        return resolve([]);
      }
      this.refreshUserNode = false;
      this.refreshProjectNode = false;
    },

    async copyLoadNode3(node, resolve) {
      if (node.level == "0") {
        let data = [];
        this.treeNameList.forEach((item) => {
          data.push(item);
        });
        return resolve(data);
      } else if (node.level == "1" && node.data.isTeam == false) {
        let res = await getUserProjectList_api({
          start: 0,
          rows: 50,
        });
        if (res.code == 200000) {
          let data = [];
          res.data.data.forEach((item) => {
            data.push({
              name: item._source.name,
              gns: item._source.gns,
              category: item._source.category,
              category_child: item._source.category_child,
              snow_id: item._source.snow_id,
              showInput: false,
              isTeam: false,
            });
          });

          setTimeout(async () => {
            if (this.refreshUserNode) {
              await this.expandProjectNode(500);
            }
          }, 500);
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "1" && node.data.isTeam == true) {
        let res = await getTeamProjectList_api({
          start: 0,
          rows: 50,
          snow_team_id: node.data.snow_id,
          team_gns: node.data.gns,
          key_word: "",
        });
        if (res.code == 200000) {
          let data = [];
          let projectGnsList = [];

          for (let i = 0; i < res.data.data.length; i++) {
            projectGnsList.push(res.data.data[i]._source.gns);
          }

          let permissionRes = await getTeamPermissionList_api({
            project_gns_list: projectGnsList,
            snow_team_id: node.data.snow_id,
          });
          if (permissionRes.code === 200000) {
            res.data.data.forEach((item) => {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                isTeam: true,
                teamRule: "",
                isLeaf: true,
              });
            });

            data.forEach((item) => {
              permissionRes.data.forEach((value) => {
                if (item.gns == value.gns) {
                  item.teamRule = value.permission;
                  if (value.permission === "可删除、编辑") {
                    item.isLeaf = false;
                  }
                }
              });
            });

            setTimeout(async () => {
              if (this.refreshUserNode) {
                await this.expandProjectNode(500);
              }
            }, 500);
            return resolve(data);
          } else {
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + permissionRes.code),
              true
            );
          }
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "2" && node.data.isTeam == false) {
        let data = [];
        let res = await getFileList_api({
          start: 0,
          rows: 200,
          parent_gns: node.data.gns,
          category: "文件",
          category_child: "个人文件",
        });

        if (res.code == 200000) {
          if (
            this.node.data.cell_type == "3" ||
            this.node.data.cell_type == "4" ||
            this.node.data.cell_type == "5"
          ) {
            res.data.data.forEach((item) => {
              if (item._source.types == "3") {
                data.push({
                  name: item._source.name,
                  gns: item._source.gns,
                  category: item._source.category,
                  category_child: item._source.category_child,
                  snow_id: item._source.snow_id,
                  showInput: false,
                  isTeam: false,
                  isLeaf: true,
                  file_type: item._source.types,
                });
              }
            });
          } else if (
            this.node.data.cell_type == "1" ||
            this.node.data.cell_type == "2"
          ) {
            res.data.data.forEach((item) => {
              if (item._source.types == "1") {
                data.push({
                  name: item._source.name,
                  gns: item._source.gns,
                  category: item._source.category,
                  category_child: item._source.category_child,
                  snow_id: item._source.snow_id,
                  showInput: false,
                  isTeam: false,
                  isLeaf: true,
                  file_type: item._source.types,
                });
              }
            });
          }

          setTimeout(async () => {
            if (this.refreshProjectNode) {
              await this.expandFileNode(500);
            }
          }, 500);
          return resolve(data);
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + res.code),
            true
          );
        }
      } else if (node.level == "2" && node.data.isTeam == true) {
        if (node.data.teamRule === "可删除、编辑") {
          let res = await getFileList_api({
            start: 0,
            rows: 200,
            parent_gns: node.data.gns,
            category: "文件",
            category_child: "团队文件",
          });
          if (res.code == 200000) {
            let data = [];
            let fileGnsList = [];
            for (let i = 0; i < res.data.data.length; i++) {
              fileGnsList.push(res.data.data[i]._source.gns);
            }

            let permissionRes = await getTeamPermissionList_api({
              project_gns_list: fileGnsList,
              snow_team_id: node.parent.data.snow_id,
            });
            if (permissionRes.code === 200000) {
              if (
                this.node.data.cell_type == "3" ||
                this.node.data.cell_type == "4" ||
                this.node.data.cell_type == "5"
              ) {
                res.data.data.forEach((item) => {
                  if (item._source.types == "3") {
                    data.push({
                      name: item._source.name,
                      gns: item._source.gns,
                      category: item._source.category,
                      category_child: item._source.category_child,
                      snow_id: item._source.snow_id,
                      showInput: false,
                      isTeam: true,
                      isLeaf: true,
                      file_type: item._source.types,
                    });
                  }
                });
              } else if (
                this.node.data.cell_type == "1" ||
                this.node.data.cell_type == "2"
              ) {
                res.data.data.forEach((item) => {
                  if (item._source.types == "1") {
                    data.push({
                      name: item._source.name,
                      gns: item._source.gns,
                      category: item._source.category,
                      category_child: item._source.category_child,
                      snow_id: item._source.snow_id,
                      showInput: false,
                      isTeam: true,
                      isLeaf: true,
                      teamRule: node.data.teamRule,
                      file_type: item._source.types,
                    });
                  }
                });
              }

              data.forEach((item) => {
                permissionRes.data.forEach((value) => {
                  if (item.gns == value.gns) {
                    item.teamRule = value.permission;
                  }
                });
              });
            }

            setTimeout(async () => {
              if (this.refreshProjectNode) {
                await this.expandFileNode(500);
              }
            }, 500);
            return resolve(data);
          } else {
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + res.code),
              true
            );
          }
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.userProjectPermission"),
            true
          );
          return resolve([]);
        }
      } else if (node.level >= "3") {
        return resolve([]);
      }
      this.refreshUserNode = false;
      this.refreshProjectNode = false;
    },

    ////刷新节点

    //移除
    remove(node) {
      const parent = node.parent;
      const children = parent.childNodes;

      const index = children.findIndex((d) => d.id === node.id);
      children.splice(index, 1);
      if (
        parent.data.name == this.userName &&
        parent.childNodes.length == 0 &&
        this.treeNameList.length == 0
      ) {
        this.createButtonVisible = true;
        this.treeVisible = false;
        this.noData = true;
        this.node = "";
        // bus.$emit("openCell", false);
      }
    },

    //添加
    async refreshTreeNode(data) {
      if (!this.isImport && !this.isSplit) {
        this.projectData = data.project;
        this.fileData = data.file;
        this.cellData = data.cell;
      }

      this.isCreate = true;
      if (this.node == "" || this.node.data.category == "") {
        if (this.node == "" && this.treeNameList.length == 0) {
          if (this.treeNameList.length == 0) {
            this.treeNameList.push({
              name: this.userName,
              gns: "",
              category: "",
              showInput: false,
              snow_id: "",
              isTeam: false,
            });
          }
          await this.newFatherNode(500);
        } else {
          this.$refs.tree.$children.forEach((item) => {
            if (
              item.node.data.name == this.userName &&
              this.projectRadio == "1"
            ) {
              this.node = item.node;
            } else if (
              item.node.data.name == this.teamName &&
              this.projectRadio == "2"
            ) {
              this.node = item.node;
            }
          });
        }
        this.refreshProjectNode = true;
        this.node.loaded = false;
        this.node.expand();
        this.refreshUserNode = true;
        this.userNode = this.node;
      } else if (this.node.data.category == "项目") {
        if (this.newProjectRuleForm.form_projectName !== this.node.data.name) {
          this.refreshProjectNode = true;
          this.node.parent.loaded = false;
          this.node.parent.expand();
          this.refreshUserNode = true;
        } else {
          this.refreshProjectNode = true;
          this.node.loaded = false;
          this.node.expand();
        }
        this.userNode = this.node.parent;
        this.proNode = this.node;
      } else if (this.node.data.category == "文件") {
        if (
          this.newProjectRuleForm.form_projectName ==
            this.node.parent.data.name &&
          this.newProjectRuleForm.form_fileName !== this.node.data.name
        ) {
          this.refreshProjectNode = true;
          this.node.parent.loaded = false;
          this.node.parent.expand();
        } else if (
          this.newProjectRuleForm.form_projectName !==
          this.node.parent.data.name
        ) {
          this.refreshProjectNode = true;
          this.node.parent.parent.loaded = false;
          this.node.parent.parent.expand();
          this.refreshUserNode = true;
        } else {
          if (this.node.data.file_type != this.fileData.file_type) {
            this.refreshProjectNode = true;
            this.node.parent.loaded = false;
            this.node.parent.expand();
          } else {
            this.node.loaded = false;
            this.node.expand();
          }
        }
        this.userNode = this.node.parent.parent;
        this.proNode = this.node.parent;
      } else if (this.node.data.category == "CELL") {
        if (
          this.newProjectRuleForm.form_projectName !==
          this.node.parent.parent.data.name
        ) {
          this.refreshUserNode = true;
          this.refreshProjectNode = true;
          this.node.parent.parent.parent.loaded = false;
          this.node.parent.parent.parent.expand();
        } else if (
          this.newProjectRuleForm.form_projectName ==
            this.node.parent.parent.data.name &&
          this.newProjectRuleForm.form_fileName !== this.node.parent.data.name
        ) {
          this.refreshProjectNode = true;
          this.node.parent.parent.loaded = false;
          this.node.parent.parent.expand();
        } else {
          if (this.node.data.cell_type != this.cellData.cell_type) {
            this.refreshProjectNode = true;
            this.node.parent.parent.loaded = false;
            this.node.parent.parent.expand();
          } else {
            this.node.parent.loaded = false;
            this.node.parent.expand();
          }
        }
        this.userNode = this.node.parent.parent.parent;
        this.proNode = this.node.parent.parent;
      }
      if (!this.isSplit) {
        this.$refs.newProjectRuleForm.resetFields();
      }
    },

    //新建个人项目总父节点
    newFatherNode(time) {
      return new Promise((res) => {
        setTimeout(() => {
          this.node = this.$refs.tree.$children[0].node;
          res();
        }, time);
      });
    },
    //项目节点展开
    expandProjectNode(time) {
      return new Promise((res) => {
        setTimeout(() => {
          this.userNode.childNodes.forEach((item) => {
            if (item.data.name == this.projectData.name) {
              this.proNode = item;
            }
          });
          this.proNode.loaded = false;
          this.proNode.expand();
          res();
        }, time);
      });
    },

    //文件节点展开
    expandFileNode(time) {
      return new Promise((res) => {
        setTimeout(() => {
          this.proNode.childNodes.forEach((item) => {
            if (item.data.snow_id == this.fileData.snow_id) {
              this.fileNode = item;
            }
          });

          this.fileNode.loaded = false;
          this.fileNode.expand();
          res();
        }, time);
      });
    },

    //上传文件
    uploadFile(file) {
      var event = event || window.event;
      var file = event.target.files[0];

      let fileName = file.name.split(".")[0];
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      let that = this;
      reader.onload = function (e) {
        const u8_data = new Uint8Array(reader.result);

        if (that.importType == "1") {
          let id = "1";
          window.QGdstk.FS.writeFile(id, u8_data);
          that.Qlib = window.QGdstk.read_gds(id);
          expand_repetition(that.Qlib);

          that.cellNames = that.Qlib.cells.map((c) => c.name);
        } else if (that.importType == "3") {
          var schema_file = new Kernel.SchemaFile();
          schema_file.from_file(u8_data);

          that.importSchemaFile = schema_file;
          schema_file.children.forEach((item) => {
            that.cellNames.push(item.name);
          });
        }
      };
      this.newProjectRuleForm.form_fileName = fileName;
      this.file_gns = "";
    },

    //导入GDS
    importGDS() {
      this.node = "";
      this.value_teamName = "";
      this.newProjectRuleForm.form_projectName = "";
      this.newProjectRuleForm.form_fileName = "";
      this.projectRadio = "1";
      this.isImport = true;
      this.value_userName = this.userName;
      this.importDialogVisible = true;
    },

    //导入项目所属
    importProjectBelong() {
      if (this.fileCreate) {
        this.value_userName = this.userName;
        this.value_teamName = "";
      } else if (this.projectRadio == "1") {
        this.value_userName = this.userName;
      } else {
        this.value_teamName = this.teamName;
      }
      this.newProjectRuleForm.form_projectName = "";
      this.newProjectRuleForm.form_fileName = "";
      this.newProjectRuleForm.form_cellName = "";
      this.unitRadio = "1";
      this.$refs.importGDS.clearFiles();
    },

    // 获取上传文件的图层
    getImportFileLayers(data) {
      let ImportFileLayers = [];
      data.layers_and_datatypes().forEach((item) => {
        ImportFileLayers.push(item[0]);
      });

      return ImportFileLayers;
    },

    importDialogSureBtn() {
      if (this.debounce) {
        this.debounce = false;
        this.$refs.newProjectRuleForm.validate(async (valid) => {
          if (valid) {
            if (this.importType == "1") {
              this.importDialogVisible = false;
              let res = {};
              this.Qlib = window.QGdstk.read_gds("1");
              expand_repetition(this.Qlib);
              let ImportFileLayers = this.getImportFileLayers(this.Qlib);
              this.Qlib.name = this.newProjectRuleForm.form_fileName;
              this.Qlib.write_gds("1");
              if (this.projectRadio == "1") {
                bus.$emit("loading", true);
                this.importDialogVisible = false;
                let file = window.QGdstk.FS.readFile("1");
                var formData = new FormData(); //新建表单对象
                formData.append("snow_file_id", "");
                formData.append("accessor_type", this.projectRadio);
                formData.append("accessor_id", this.userId);
                formData.append("project_gns", this.project_gns);
                formData.append("layer_info", JSON.stringify(ImportFileLayers));
                formData.append(
                  "file_name",
                  this.newProjectRuleForm.form_fileName
                );
                formData.append("file_gns", "");
                formData.append("file_type", "1");
                formData.append("cell_type", this.importType);
                formData.append("is_upload_file", 1);
                formData.append(
                  "cell_name_list",
                  JSON.stringify(this.cellNames)
                );
                formData.append("file", new Blob([file])); //把文件二进制对象添加到表单对象里

                res = await batchCreatePWD_api(formData);
                if (res.code === 200000) {
                  let name = this.cellNames[0];
                  this.createButtonVisible = false;
                  this.projectData = {
                    name: this.newProjectRuleForm.form_projectName,
                    snow_id:
                      res.data[name].split("/")[
                        res.data[name].split("/").length - 3
                      ],
                  };
                  this.fileData = {
                    name: this.newProjectRuleForm.form_fileName,
                    snow_id:
                      res.data[name].split("/")[
                        res.data[name].split("/").length - 2
                      ],
                  };
                  this.noData = false;
                  this.treeVisible = true;
                  this.userNode = this.$refs.tree.$children[0].node;
                  this.refreshTreeNode();
                } else {
                  this.resetImportDialog();
                  bus.$emit("ending", true);
                  this.errDialog(
                    this.$t("messages.wrong"),
                    this.$t("messages.code_" + res.code),
                    true
                  );
                }
              } else if (this.projectRadio == "2") {
                let selectPro = this.options_teamProName.filter(
                  (item) =>
                    item.value === this.newProjectRuleForm.form_projectName
                )[0];
                let teamRule = "";
                let permissionRes = await getUserPermission_api({
                  gns: selectPro.project_gns,
                });
                if (permissionRes.code === 200000) {
                  teamRule = permissionRes.data.permission;
                }

                if (teamRule === "可删除、编辑") {
                  bus.$emit("loading", true);
                  this.importDialogVisible = false;
                  let file = window.QGdstk.FS.readFile("1");

                  var formData = new FormData(); //新建表单对象
                  formData.append("snow_file_id", "");
                  formData.append("accessor_type", this.projectRadio);
                  formData.append("accessor_id", this.teamId);
                  formData.append("project_gns", this.project_gns);
                  formData.append(
                    "file_name",
                    this.newProjectRuleForm.form_fileName
                  );
                  formData.append(
                    "layer_info",
                    JSON.stringify(ImportFileLayers)
                  );
                  formData.append("file_gns", "");
                  formData.append("file_type", "1");
                  formData.append("cell_type", this.importType);
                  formData.append("is_upload_file", 1);
                  formData.append(
                    "cell_name_list",
                    JSON.stringify(this.cellNames)
                  );
                  formData.append("file", new Blob([file])); //把文件二进制对象添加到表单对象里

                  res = await batchCreatePWD_api(formData);
                  if (res.code === 200000) {
                    let name = this.cellNames[0];
                    this.createButtonVisible = false;
                    this.projectData = {
                      name: this.newProjectRuleForm.form_projectName,
                      snow_id:
                        res.data[name].split("/")[
                          res.data[name].split("/").length - 3
                        ],
                    };
                    this.fileData = {
                      name: this.newProjectRuleForm.form_fileName,
                      snow_id:
                        res.data[name].split("/")[
                          res.data[name].split("/").length - 2
                        ],
                    };
                    this.noData = false;
                    this.treeVisible = true;
                    let index = this.$refs.tree.$children.findIndex(
                      (item) => item.node.data.gns === this.team_gns
                    );
                    this.userNode = this.$refs.tree.$children[index].node;
                    this.refreshTreeNode();
                  } else {
                    this.resetImportDialog();
                    bus.$emit("ending", true);
                    this.errDialog(
                      this.$t("messages.wrong"),
                      this.$t("messages.code_" + res.code),
                      true
                    );
                  }
                } else {
                  this.resetImportDialog();
                  bus.$emit("ending", true);
                  this.errDialog(
                    this.$t("messages.wrong"),
                    this.$t("messages.cannotImport"),
                    true
                  );
                }
              }
            } else if (this.importType == "3") {
              let iptSchemaRes = {};
              let iptSymbolRes = {};
              let iptCapSymbolRes = {};
              let iptSchemaArr = [];
              let iptSymbolArr = [];
              let iptCapSymbolArr = [];
              let iptSchemaInfoArr = [];
              let iptSymbolInfoArr = [];
              let iptCapSymbolInfoArr = [];
              let iptSchemaNameArr = [];
              let iptSymbolNameArr = [];
              let iptCapSymbolNameArr = [];
              let file_snow_id = "";
              let file_gns = "";
              if (this.projectRadio == "1") {
                bus.$emit("loading", true);
                this.importDialogVisible = false;
                for (
                  let i = 0;
                  i < this.importSchemaFile.children.length;
                  i++
                ) {
                  if (
                    this.importSchemaFile.children[i].constructor.name ==
                    "Schema"
                  ) {
                    iptSchemaNameArr.push(
                      this.importSchemaFile.children[i].name
                    );
                    iptSchemaArr.push(this.importSchemaFile.children[i]);
                  } else if (
                    this.importSchemaFile.children[i].constructor.name ==
                    "Symbol"
                  ) {
                    iptSymbolNameArr.push(
                      this.importSchemaFile.children[i].name
                    );
                    iptSymbolArr.push(this.importSchemaFile.children[i]);
                  } else if (
                    this.importSchemaFile.children[i].constructor.name ==
                    "CapSymbol"
                  ) {
                    iptCapSymbolNameArr.push(
                      this.importSchemaFile.children[i].name
                    );
                    iptCapSymbolArr.push(this.importSchemaFile.children[i]);
                  }
                }
                if (iptSchemaNameArr.length) {
                  iptSchemaRes = await this.batchCreateFn(
                    file_snow_id,
                    this.projectRadio,
                    this.userId,
                    this.project_gns,
                    this.newProjectRuleForm.form_fileName,
                    file_gns,
                    "3",
                    "3",
                    0,
                    JSON.stringify(iptSchemaNameArr)
                  );
                  if (iptSchemaRes.code === 200000) {
                    iptSchemaNameArr.forEach((item) => {
                      iptSchemaInfoArr.push({
                        name: item,
                        snow_id:
                          iptSchemaRes.data[item].split("/")[
                            iptSchemaRes.data[item].split("/").length - 1
                          ],
                      });
                      file_snow_id =
                        iptSchemaRes.data[item].split("/")[
                          iptSchemaRes.data[item].split("/").length - 2
                        ];
                      file_gns = [this.project_gns, file_snow_id].join("/");
                    });

                    iptSchemaArr.forEach((item) => {
                      iptSchemaInfoArr.forEach((value) => {
                        if (item.name == value.name) {
                          item.snow_id = value.snow_id;
                        }
                      });
                    });
                  }
                }
                if (iptSymbolNameArr.length) {
                  iptSymbolRes = await this.batchCreateFn(
                    file_snow_id,
                    this.projectRadio,
                    this.userId,
                    this.project_gns,
                    this.newProjectRuleForm.form_fileName,
                    file_gns,
                    "3",
                    "4",
                    0,
                    JSON.stringify(iptSymbolNameArr)
                  );
                  if (iptSymbolRes.code === 200000) {
                    iptSymbolNameArr.forEach((item) => {
                      iptSymbolInfoArr.push({
                        name: item,
                        snow_id:
                          iptSymbolRes.data[item].split("/")[
                            iptSymbolRes.data[item].split("/").length - 1
                          ],
                      });
                      file_snow_id =
                        iptSymbolRes.data[item].split("/")[
                          iptSymbolRes.data[item].split("/").length - 2
                        ];
                      file_gns = [this.project_gns, file_snow_id].join("/");
                    });

                    iptSymbolArr.forEach((item) => {
                      iptSymbolInfoArr.forEach((value) => {
                        if (item.name == value.name) {
                          item.snow_id = value.snow_id;
                        }
                      });
                    });
                  }
                }
                if (iptCapSymbolNameArr.length) {
                  iptCapSymbolRes = await this.batchCreateFn(
                    file_snow_id,
                    this.projectRadio,
                    this.userId,
                    this.project_gns,
                    this.newProjectRuleForm.form_fileName,
                    file_gns,
                    "3",
                    "5",
                    0,
                    JSON.stringify(iptCapSymbolNameArr)
                  );
                  if (iptCapSymbolRes.code === 200000) {
                    iptCapSymbolNameArr.forEach((item) => {
                      iptCapSymbolInfoArr.push({
                        name: item,
                        snow_id:
                          iptCapSymbolRes.data[item].split("/")[
                            iptCapSymbolRes.data[item].split("/").length - 1
                          ],
                      });
                    });

                    iptCapSymbolArr.forEach((item) => {
                      iptCapSymbolInfoArr.forEach((value) => {
                        if (item.name == value.name) {
                          item.snow_id = value.snow_id;
                        }
                      });
                    });
                  }
                }

                for (let i = 0; i < iptSchemaArr.length; i++) {
                  await this.saveSchemaPublicFn(
                    iptSchemaArr[i],
                    "DIAGRAM_FILE",
                    iptSchemaRes.data[iptSchemaArr[i].name]
                  );
                }
                for (let i = 0; i < iptSymbolArr.length; i++) {
                  await this.saveSchemaPublicFn(
                    iptSymbolArr[i],
                    "SYMBOLS_FILE",
                    iptSymbolRes.data[iptSymbolArr[i].name]
                  );
                }
                for (let i = 0; i < iptCapSymbolArr.length; i++) {
                  await this.saveSchemaPublicFn(
                    iptCapSymbolArr[i],
                    "SYMBOLS_FILE",
                    iptCapSymbolRes.data[iptCapSymbolArr[i].name]
                  );
                }
                this.importSchemaFile.snow_id = file_snow_id;
                await this.saveSchemaPublicFn(
                  this.importSchemaFile,
                  "DIAGRAM_FILE",
                  file_gns
                );

                this.userNode = this.$refs.tree.$children[0].node;
              } else if (this.projectRadio == "2") {
                let selectPro = this.options_teamProName.filter(
                  (item) =>
                    item.value === this.newProjectRuleForm.form_projectName
                )[0];
                let teamRule = "";
                let permissionRes = await getUserPermission_api({
                  gns: selectPro.project_gns,
                });
                if (permissionRes.code === 200000) {
                  teamRule = permissionRes.data.permission;
                }
                if (teamRule === "可删除、编辑") {
                  bus.$emit("loading", true);
                  this.importDialogVisible = false;
                  for (
                    let i = 0;
                    i < this.importSchemaFile.children.length;
                    i++
                  ) {
                    if (
                      this.importSchemaFile.children[i].constructor.name ==
                      "Schema"
                    ) {
                      iptSchemaNameArr.push(
                        this.importSchemaFile.children[i].name
                      );
                      iptSchemaArr.push(this.importSchemaFile.children[i]);
                    } else if (
                      this.importSchemaFile.children[i].constructor.name ==
                      "Symbol"
                    ) {
                      iptSymbolNameArr.push(
                        this.importSchemaFile.children[i].name
                      );
                      iptSymbolArr.push(this.importSchemaFile.children[i]);
                    } else if (
                      this.importSchemaFile.children[i].constructor.name ==
                      "CapSymbol"
                    ) {
                      iptCapSymbolNameArr.push(
                        this.importSchemaFile.children[i].name
                      );
                      iptCapSymbolArr.push(this.importSchemaFile.children[i]);
                    }
                  }
                  if (iptSchemaNameArr.length) {
                    iptSchemaRes = await this.batchCreateFn(
                      file_snow_id,
                      this.projectRadio,
                      this.teamId,
                      this.project_gns,
                      this.newProjectRuleForm.form_fileName,
                      file_gns,
                      "3",
                      "3",
                      0,
                      JSON.stringify(iptSchemaNameArr)
                    );
                    if (iptSchemaRes.code === 200000) {
                      iptSchemaNameArr.forEach((item) => {
                        iptSchemaInfoArr.push({
                          name: item,
                          snow_id:
                            iptSchemaRes.data[item].split("/")[
                              iptSchemaRes.data[item].split("/").length - 1
                            ],
                        });
                        file_snow_id =
                          iptSchemaRes.data[item].split("/")[
                            iptSchemaRes.data[item].split("/").length - 2
                          ];
                        file_gns = [this.project_gns, file_snow_id].join("/");
                      });

                      iptSchemaArr.forEach((item) => {
                        iptSchemaInfoArr.forEach((value) => {
                          if (item.name == value.name) {
                            item.snow_id = value.snow_id;
                          }
                        });
                      });
                    }
                  }
                  if (iptSymbolNameArr.length) {
                    iptSymbolRes = await this.batchCreateFn(
                      file_snow_id,
                      this.projectRadio,
                      this.teamId,
                      this.project_gns,
                      this.newProjectRuleForm.form_fileName,
                      file_gns,
                      "3",
                      "4",
                      0,
                      JSON.stringify(iptSymbolNameArr)
                    );
                    if (iptSymbolRes.code === 200000) {
                      iptSymbolNameArr.forEach((item) => {
                        iptSymbolInfoArr.push({
                          name: item,
                          snow_id:
                            iptSymbolRes.data[item].split("/")[
                              iptSymbolRes.data[item].split("/").length - 1
                            ],
                        });
                        file_snow_id =
                          iptSymbolRes.data[item].split("/")[
                            iptSymbolRes.data[item].split("/").length - 2
                          ];
                        file_gns = [this.project_gns, file_snow_id].join("/");
                      });

                      iptSymbolArr.forEach((item) => {
                        iptSymbolInfoArr.forEach((value) => {
                          if (item.name == value.name) {
                            item.snow_id = value.snow_id;
                          }
                        });
                      });
                    }
                  }
                  if (iptCapSymbolNameArr.length) {
                    iptCapSymbolRes = await this.batchCreateFn(
                      file_snow_id,
                      this.projectRadio,
                      this.teamId,
                      this.project_gns,
                      this.newProjectRuleForm.form_fileName,
                      file_gns,
                      "3",
                      "5",
                      0,
                      JSON.stringify(iptCapSymbolNameArr)
                    );
                    if (iptCapSymbolRes.code === 200000) {
                      iptCapSymbolNameArr.forEach((item) => {
                        iptCapSymbolInfoArr.push({
                          name: item,
                          snow_id:
                            iptCapSymbolRes.data[item].split("/")[
                              iptCapSymbolRes.data[item].split("/").length - 1
                            ],
                        });
                      });

                      iptCapSymbolArr.forEach((item) => {
                        iptCapSymbolInfoArr.forEach((value) => {
                          if (item.name == value.name) {
                            item.snow_id = value.snow_id;
                          }
                        });
                      });
                    }
                  }

                  for (let i = 0; i < iptSchemaArr.length; i++) {
                    await this.saveSchemaPublicFn(
                      iptSchemaArr[i],
                      "DIAGRAM_FILE",
                      iptSchemaRes.data[iptSchemaArr[i].name]
                    );
                  }
                  for (let i = 0; i < iptSymbolArr.length; i++) {
                    await this.saveSchemaPublicFn(
                      iptSymbolArr[i],
                      "SYMBOLS_FILE",
                      iptSymbolRes.data[iptSymbolArr[i].name]
                    );
                  }
                  for (let i = 0; i < iptCapSymbolArr.length; i++) {
                    await this.saveSchemaPublicFn(
                      iptCapSymbolArr[i],
                      "SYMBOLS_FILE",
                      iptCapSymbolRes.data[iptCapSymbolArr[i].name]
                    );
                  }
                  this.importSchemaFile.snow_id = file_snow_id;
                  await this.saveSchemaPublicFn(
                    this.importSchemaFile,
                    "DIAGRAM_FILE",
                    file_gns
                  );

                  let index = this.$refs.tree.$children.findIndex(
                    (item) => item.node.data.gns === this.team_gns
                  );
                  this.userNode = this.$refs.tree.$children[index].node;
                } else {
                  this.resetImportDialog();
                  bus.$emit("ending", true);
                  this.errDialog(
                    this.$t("messages.wrong"),
                    this.$t("messages.cannotImport"),
                    true
                  );
                }
              }

              this.createButtonVisible = false;
              this.projectData = {
                name: this.newProjectRuleForm.form_projectName,
                snow_id: file_gns.split("/")[file_gns.split("/").length - 2],
              };
              this.fileData = {
                name: this.newProjectRuleForm.form_fileName,
                snow_id: file_gns.split("/")[file_gns.split("/").length - 1],
              };
              this.noData = false;
              this.treeVisible = true;
              this.refreshTreeNode();
            }
          } else {
            return false;
          }
        });
      }
      setTimeout(() => {
        this.debounce = true;
      }, 1000);
    },

    importDialogCancelButton() {
      this.resetImportDialog();
    },
    importDialogClose() {
      this.resetImportDialog();
    },

    resetImportDialog() {
      this.node = "";
      this.value_teamName = "";
      this.newProjectRuleForm.form_projectName = "";
      this.newProjectRuleForm.form_fileName = "";
      this.projectRadio = "1";
      this.isImport = false;
      this.$refs.importGDS.clearFiles();
      this.$refs.newProjectRuleForm.resetFields();
      this.importDialogVisible = false;
    },

    ////重命名

    //重命名聚焦
    resetNameFocus(e, node) {
      this.oldName = this.node.data.name;
    },

    //重命名回车
    resetNameEnter(event) {
      event.target.blur();
    },

    //重命名失焦
    async resetNameBlur(node) {
      this.isReName = true;
      if (node.data.name !== this.oldName) {
        if (
          node.data.category === "项目" &&
          /^[\s&()~+-_\[\]0-9a-zA-Z\u4e00-\u9fa5]+$/.test(node.data.name) &&
          [...node.data.name][0] !== " " &&
          [...node.data.name][[...node.data.name].length - 1] !== " "
        ) {
          let res = null;
          try {
            if (node.data.isTeam) {
              if (node.data.category == "项目") {
                res = await updataName_api({
                  category: node.data.category,
                  snow_id: node.data.snow_id,
                  name: node.data.name,
                  parent_gns: "",
                  project_gns: node.data.gns,
                  user_type: "team",
                });
              } else {
                res = await updataName_api({
                  category: node.data.category,
                  snow_id: node.data.snow_id,
                  name: node.data.name,
                  parent_gns: node.parent.data.gns,
                  project_gns: node.data.gns,
                  user_type: "team",
                });
              }
            } else {
              if (node.data.category == "项目") {
                res = await updataName_api({
                  category: node.data.category,
                  snow_id: node.data.snow_id,
                  name: node.data.name,
                  parent_gns: "",
                  project_gns: node.data.gns,
                  user_type: "user",
                });
              } else {
                res = await updataName_api({
                  category: node.data.category,
                  snow_id: node.data.snow_id,
                  name: node.data.name,
                  parent_gns: node.parent.data.gns,
                  project_gns: node.data.gns,
                  user_type: "user",
                });
              }
            }
            if (res.code === 200000) {
              if (this.$store.state.proInfo !== "") {
                if (this.$store.state.proInfo.projectId == node.data.snow_id)
                  this.resetPWCNameSync(node);
              }
            }
          } catch (error) {
            node.data.name = this.oldName;
            this.isReName = false;
          }
        } else if (
          node.data.category !== "项目" &&
          /^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(node.data.name) &&
          [...node.data.name][0] !== " " &&
          [...node.data.name][[...node.data.name].length - 1] !== " "
        ) {
          let res = null;

          if (node.data.isTeam && node.data.category == "文件") {
            try {
              res = await updataName_api({
                category: node.data.category,
                snow_id: node.data.snow_id,
                name: node.data.name,
                parent_gns: node.parent.data.gns,
                project_gns: node.parent.data.gns,
                user_type: "team",
                type: node.data.file_type,
              });
              if (res.code === 200000) {
                if (node.data.file_type == "3") {
                  await this.syncRenameSchFileName(node.data);
                } else if (node.data.file_type == "1") {
                  this.resetPWCNameSync(res, node);
                }
              }
            } catch (error) {
              node.data.name = this.oldName;
              this.isReName = false;
            }
          } else if (node.data.isTeam && node.data.category == "CELL") {
            try {
              res = await updataName_api({
                category: node.data.category,
                snow_id: node.data.snow_id,
                name: node.data.name,
                parent_gns: node.parent.data.gns,
                project_gns: node.parent.parent.data.gns,
                user_type: "team",
                type: node.data.cell_type,
              });
              if (res.code === 200000) {
                if (
                  node.data.cell_type == "3" ||
                  node.data.cell_type == "4" ||
                  node.data.cell_type == "5"
                ) {
                  await this.syncRenameSchName(node.parent.data, node.data);
                } else if (
                  node.data.cell_type == "1" ||
                  node.data.cell_type == "2"
                ) {
                  this.resetPWCNameSync(res, node);
                }
              }
            } catch (error) {
              node.data.name = this.oldName;
              this.isReName = false;
            }
          } else if (!node.data.isTeam && node.data.category == "文件") {
            try {
              res = await updataName_api({
                category: node.data.category,
                snow_id: node.data.snow_id,
                name: node.data.name,
                parent_gns: node.parent.data.gns,
                project_gns: node.parent.data.gns,
                user_type: "user",
                type: node.data.file_type,
              });
              if (res.code === 200000) {
                if (node.data.file_type == "3") {
                  await this.syncRenameSchFileName(node.data);
                } else if (node.data.file_type == "1") {
                  this.resetPWCNameSync(res, node);
                }
              }
            } catch (error) {
              node.data.name = this.oldName;
              this.isReName = false;
            }
          } else if (!node.data.isTeam && node.data.category == "CELL") {
            try {
              res = await updataName_api({
                category: node.data.category,
                snow_id: node.data.snow_id,
                name: node.data.name,
                parent_gns: node.parent.data.gns,
                project_gns: node.parent.parent.data.gns,
                user_type: "user",
                type: node.data.cell_type,
              });
              if (res.code === 200000) {
                //读取文件数据,修改对应文件名,然后保存
                if (
                  node.data.cell_type == "3" ||
                  node.data.cell_type == "4" ||
                  node.data.cell_type == "5"
                ) {
                  //获取文件数据
                  await this.syncRenameSchName(node.parent.data, node.data);
                } else if (
                  node.data.cell_type == "1" ||
                  node.data.cell_type == "2"
                ) {
                  this.resetPWCNameSync(res, node);
                }
              }
            } catch (error) {
              node.data.name = this.oldName;
              this.isReName = false;
            }
          }
        } else if (node.data.category === "项目") {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.fileTip1"),
            true
          );
          node.data.name = this.oldName;
          this.isReName = false;
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.fileTip2"),
            true
          );
          node.data.name = this.oldName;
          this.isReName = false;
        }
      }

      node.data.showInput = false;
    },

    resetPWCNameSync(res, node) {
      if (res.code === 200000 && this.isReName) {
        this.$message({
          message: this.$t("messages.successfullyModified"),
          type: "success",
        });
        let params = [
          this.oldName,
          node.data.name,
          node.data.snow_id,
          node.data.category,
          node.parent.data.snow_id,
        ];
        bus.$emit("resetName", params);
        bus.$emit("resetNameFileBar", params);
        if (node.data.category == "项目") {
          if (this.$store.state.proInfo == "项目") {
            this.$store.state.proInfo.name = node.data.name;
          }
        } else if (node.data.category == "文件") {
          if (this.$store.state.fileInfo) {
            this.$store.state.fileInfo.name = node.data.name;
          }
        } else if (node.data.category == "CELL") {
          if (this.$store.state.cellInfo) {
            this.$store.state.cellInfo.name = node.data.name;
          }
        }
        setTimeout(() => {
          this.isReName = false;
          bus.$emit("updateHistory", "all");
        }, 1000);
      }
    },

    //同步修改原理图文件名
    async syncRenameSchFileName(fileData) {
      let fileId = fileData.snow_id;
      let fileGns = fileData.gns;
      //获取文件数据
      let schemaFile = this.getSchFileFromTable(fileId);
      let syncSchemaFile = await getSchemaFileData(fileId);
      syncSchemaFile.name = fileData.name;
      if (schemaFile !== undefined) {
        let fileData = {
          fileId,
          fileData: syncSchemaFile,
        };
        this.$store.commit("changeDataToSchemaFileTable", fileData);
      }

      await this.saveSchemaPublicFn(syncSchemaFile, "DIAGRAM_FILE", fileGns);
    },

    //同步修改原理图/符号/封装符号名
    async syncRenameSchName(fileData, cellData) {
      let fileId = fileData.snow_id;
      let cellId = cellData.snow_id;
      let fileGns = fileData.gns;
      let cellGns = cellData.gns;
      let isSaveToTable = false;
      let schemaFile = this.getSchFileFromTable(fileId);

      if (schemaFile == null) {
        schemaFile = await getWholeFileData(fileData, cellData.category_child);
        isSaveToTable = false;
      } else {
        isSaveToTable = true;
      }
      let renameCell = {};
      let saveType = "";
      if (cellData.cell_type == "3") {
        saveType = "DIAGRAM_FILE";
        renameCell = this.getSchemaFromSchFile(schemaFile, cellId);
      } else if (cellData.cell_type == "4") {
        renameCell = this.getSymbolFromSchFile(schemaFile, cellId);
        saveType = "SYMBOLS_FILE";
        renameCell.short_name = cellData.name;
      } else if (cellData.cell_type == "5") {
        renameCell = this.getCapSymbolFromSchFile(schemaFile, cellId);
        saveType = "SYMBOLS_FILE";
        renameCell.short_name = cellData.name;
      }

      // schemaFile.children.forEach(item => {
      //   if (item.snow_id === cellId) {
      //     item.name = cellData.name
      //   }
      // })
      renameCell.name = cellData.name;
      await this.saveSchemaPublicFn(renameCell, saveType, cellGns);
      await this.saveSchemaPublicFn(schemaFile, "DIAGRAM_FILE", fileGns);
      if (isSaveToTable) {
        let fileData = {
          fileId,
          fileData: schemaFile,
        };
        this.$store.commit("changeDataToSchemaFileTable", fileData);
      }

      if (cellData.cell_type == "3") {
        if (JSON.stringify(this.$route.query) !== "{}") {
          if (cellData.snow_id == this.$route.query.id.split("/")[1]) {
            this.$store.state.cellInfo.name = cellData.name;
          }
          let params = [
            this.oldName,
            cellData.name,
            cellData.snow_id,
            cellData.category,
            fileData.snow_id,
          ];
          bus.$emit("resetNameFileBar", params);
        }
      }
      setTimeout(() => {
        let fileNode = this.$refs.tree.getNode(fileId);

        fileNode.loaded = false;
        fileNode.expand();
      }, 500);
    },

    //另存为单元新建cell
    saveAsCellCreateFn(data) {
      let node = data[0];
      let cell_type = data[2];
      let message = this.$t("messages.saveAsUnitSucceeded");
      this.proNode = node.parent;
      this.fileNode = node;
      let params = null;

      let fatherNode = null;
      if (node.parent.parent.data.isTeam) {
        fatherNode = this.$refs.tree.getNode(node.parent.parent.data.snow_id);
      } else {
        fatherNode = this.$refs.tree.getNode(node.parent.parent.data.snow_id);
      }
      let saveAsCellName = data[1];
      let saveAsCellSnow_id = this.fileNode.data.snow_id;

      if (node.data.isTeam) {
        params = {
          accessor_type: "2",
          accessor_id: node.parent.parent.data.snow_id,
          project_name: this.proNode.data.name,
          project_gns: this.proNode.data.gns,
          file_name: this.fileNode.data.name,
          file_gns: this.fileNode.data.gns,
          cell_type,
          cell_name: saveAsCellName,
        };
      } else {
        params = {
          accessor_type: "1",
          accessor_id: this.userId,
          project_name: this.proNode.data.name,
          project_gns: this.proNode.data.gns,
          file_name: this.fileNode.data.name,
          file_gns: this.fileNode.data.gns,
          cell_type,
          cell_name: saveAsCellName,
        };
      }
      let timer = setInterval(async () => {
        if (this.boxData !== null) {
          clearInterval(timer);

          getCellFile_api({ file_snow_id: saveAsCellSnow_id })
            .then((saveAsCellRes) => {
              this.saveAsCellFile_url = saveAsCellRes.data.file_url;
              if (this.saveAsCellFile_url) {
                let saveAsCellFile = null;

                if (saveAsCellSnow_id == this.$store.state.fileInfo.fileId) {
                  saveAsCellFile = window.QGdstk.read_gds(saveAsCellSnow_id);
                  expand_repetition(saveAsCellFile);
                  let cells = this.boxData.cells;
                  saveAsCellFile.add(cells);
                  saveAsCellFile.write_gds(saveAsCellSnow_id);
                  let index = this.$store.state.openedTab.findIndex(
                    (item) =>
                      item.route.split("id=")[1].split("/")[0] ===
                      saveAsCellSnow_id
                  );
                  if (index !== -1) {
                    bus.$emit("addCellToOpenedFile", [
                      saveAsCellSnow_id,
                      saveAsCellName,
                    ]);
                  }
                  this.createAndRefreshCell(
                    fatherNode,
                    params,
                    saveAsCellSnow_id,
                    message
                  );
                } else {
                  getObjectDataFn(this.saveAsCellFile_url).then((u8_data) => {
                    window.QGdstk.FS.writeFile(saveAsCellSnow_id, u8_data);

                    saveAsCellFile = window.QGdstk.read_gds(saveAsCellSnow_id);
                    expand_repetition(saveAsCellFile);

                    let cells = this.boxData.cells;
                    let cellNames = [];
                    let oldDependenciesCells = null;
                    for (let index = 0; index < cells.length; index++) {
                      if (cells[index].name === saveAsCellName) {
                        oldDependenciesCells = cells[index].dependencies(true);
                      }
                    }

                    for (let i = 0; i < oldDependenciesCells.length; i++) {
                      if (
                        saveAsCellFile.cells.some(
                          (v) => v.name == oldDependenciesCells[i].name
                        )
                      ) {
                        oldDependenciesCells[i].name =
                          this.removeDuplicateNames(
                            oldDependenciesCells[i].name
                          );
                      }
                    }
                    cellNames = this.renameCellNameArr(oldDependenciesCells);

                    saveAsCellFile.add(cells);
                    saveAsCellFile.write_gds(saveAsCellSnow_id);

                    this.batchCreateCells(
                      fatherNode,
                      params,
                      cellNames,
                      saveAsCellSnow_id,
                      message
                    );
                  });
                }
              } else {
                let new_lib = new window.QGdstk.Library(
                  saveAsCellSnow_id,
                  1e-6,
                  1e-9
                );
                new_lib.add(this.boxData.cells);

                new_lib.write_gds(saveAsCellSnow_id);

                let cells = this.boxData.cells;

                let cellNames = [];
                cells.forEach((item) => {
                  if (item.name !== saveAsCellName) {
                    cellNames.push(item.name);
                  }
                });

                this.batchCreateCells(
                  fatherNode,
                  params,
                  cellNames,
                  saveAsCellSnow_id,
                  message
                );
              }
            })
            .catch((saveAsCellErr) => {
              bus.$emit("ending", true);
              this.errDialog(
                this.$t("messages.wrong"),
                this.$t("messages.code_" + saveAsCellErr.code),
                true
              );
            });
        } else if (this.boxData === null) {
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.unframedData"),
            true
          );
          clearInterval(timer);
        }
      }, 100);
    },

    //节点刷新与创建
    createAndRefreshCell(fatherNode, params, file_snow_id, message) {
      if (fatherNode.loaded) {
        for (let i = 0; i < fatherNode.childNodes.length; i++) {
          if (fatherNode.childNodes[i].data.gns == this.proNode.data.gns) {
            if (fatherNode.childNodes[i].loaded) {
              for (
                let index = 0;
                index < fatherNode.childNodes[i].childNodes.length;
                index++
              ) {
                if (
                  fatherNode.childNodes[i].childNodes[index].data.gns ==
                  this.fileNode.data.gns
                ) {
                  createNewPFC_api(params).then((res) => {
                    if (res.code === 200000) {
                      if (fatherNode.childNodes[i].childNodes[index].loaded) {
                        fatherNode.childNodes[i].childNodes[
                          index
                        ].loaded = false;
                        fatherNode.childNodes[i].childNodes[index].expand();
                      } else {
                        fatherNode.childNodes[i].childNodes[index].expand();
                      }
                      this.layerChangeFn(file_snow_id, message);
                    } else {
                      bus.$emit("ending", true);
                      this.errDialog(
                        this.$t("messages.wrong"),
                        this.$t("messages.code_" + res.code),
                        true
                      );
                    }
                  });
                }
              }
            } else {
              fatherNode.childNodes[i].expand();

              setTimeout(async () => {
                for (
                  let index = 0;
                  index < fatherNode.childNodes[i].childNodes.length;
                  index++
                ) {
                  if (
                    fatherNode.childNodes[i].childNodes[index].data.gns ==
                    this.fileNode.data.gns
                  ) {
                    createNewPFC_api(params).then((res) => {
                      if (res.code === 200000) {
                        fatherNode.childNodes[i].childNodes[index].expand();
                        this.layerChangeFn(file_snow_id, message);
                      } else {
                        bus.$emit("ending", true);
                        this.errDialog(
                          this.$t("messages.wrong"),
                          this.$t("messages.code_" + res.code),
                          true
                        );
                      }
                    });
                  }
                }
              }, 1000);
            }
          }
        }
      } else {
        fatherNode.expand();
        setTimeout(() => {
          for (let i = 0; i < fatherNode.childNodes.length; i++) {
            if (fatherNode.childNodes[i].data.gns == this.proNode.data.gns) {
              fatherNode.childNodes[i].expand();
              setTimeout(async () => {
                for (
                  let index = 0;
                  index < fatherNode.childNodes[i].childNodes.length;
                  index++
                ) {
                  if (
                    fatherNode.childNodes[i].childNodes[index].data.gns ==
                    this.fileNode.data.gns
                  ) {
                    createNewPFC_api(params).then((res) => {
                      if (res.code === 200000) {
                        fatherNode.childNodes[i].childNodes[index].expand();
                        this.layerChangeFn(file_snow_id, message);
                      } else {
                        bus.$emit("ending", true);
                        this.errDialog(
                          this.$t("messages.wrong"),
                          this.$t("messages.code_" + res.code),
                          true
                        );
                      }
                    });
                  }
                }
              }, 2000);
            }
          }
        }, 1000);
      }
    },

    //去除未保存的cell
    delUnsavedCell(currentCellNameList, data, cellNode) {
      currentCellNameList.forEach((item) => {
        if (!data.some((v) => v._source.name === item.name)) {
          this.$refs.tree.remove(item.snow_id);
        }
        this.cellUnsaved = false;
      });
    },

    //生成唯一cell名
    generateUniqueCellName(name) {
      function nanoid(size = 21) {
        let id = "";
        let i = size;
        while (i--) {
          id += urlAlphabet[(Math.random() * 63) | 0];
        }
        return id;
      }
      return name + "_" + nanoid(6);
    },

    // 去除重名
    removeDuplicateNames(oldName) {
      let oldNameArr = oldName.split("_");
      oldNameArr.splice(oldNameArr.length - 2, 2);
      let newHalfName = oldNameArr.join("_");

      let newName = this.generateUniqueCellName(
        this.generateUniqueCellName(newHalfName)
      );

      return newName;
    },

    // 获取重命名数组
    renameCellNameArr(oldDependenciesCells) {
      let cellNames = [];
      oldDependenciesCells.forEach((item) => {
        cellNames.push(item.name);
      });

      return cellNames;
    },

    //调用
    async instanceSymbol(node) {
      let schFile = this.getSchFileFromTable(node.parent.data.snow_id);
      if (schFile === null) {
        let urlData = await getDiagramFile_api({
          file_snow_id: [node.data.snow_id],
        });
        if (urlData.code === 200000) {
          schFile = await getCompleteDataFn(urlData.data[0].file_url);
        }
      }
      let symbol = {};
      if (node.data.cell_type == "4") {
        symbol = this.getSymbolFromSchFile(schFile, node.data.snow_id);
      } else if (node.data.cell_type == "5") {
        symbol = this.getCapSymbolFromSchFile(schFile, node.data.snow_id);
      }

      if (symbol === undefined) {
        this.$message.error("当前符号不存在");
      } else {
        //判断是否存在闭环
        let hasCircle = false;
        this.recFileCallArr.splice(0, this.recFileCallArr.length);
        this.recFileCall(symbol);

        for (const i in this.recFileCallArr) {
          if (
            this.recFileCallArr[i].snow_id == this.$store.state.cellInfo.cellId
          ) {
            hasCircle = true;
            this.errDialog(this.$t("messages.wrong"), "调用存在闭环", true);
            break;
          }
        }
        if (!hasCircle) {
          let symbolIns = new Kernel.SymbolIns(symbol);

          symbolIns.ins_name = symbol.name;
          if (node.parent.data.snow_id !== this.$store.state.fileInfo.fileId) {
            bus.$emit("addSymbol", symbolIns);
          } else {
            bus.$emit("instanceCapSymbol", symbolIns);
          }
        }
      }
    },

    ////另存为
    //另存为关闭按钮
    saveAsDialogClose() {
      this.resetSaveAsDialog();
    },

    //另存为确定
    saveAsDialogSureButton() {
      this.$refs.newProjectRuleForm.validate(async (valid) => {
        if (valid) {
          bus.$emit("loading", true);
          this.saveAsDialogVisible = false;
          let fileId = this.$store.state.fileInfo.fileId;
          let cellId = this.$store.state.cellInfo.cellId;
          let schemaOldName = this.$store.state.cellInfo.name;
          let schFile = this.getSchFileFromTable(fileId);
          let saveSchFile = {};
          let schema = this.getSchemaFromSchFile(schFile, cellId);

          this.recFileCallArr.splice(0, this.recFileCallArr.length);
          this.recFileCall(schema);

          let category = "CELL";
          let category_child = "";
          let dumpDataArr = [];
          let saveSchemaNameArr = [];
          let saveSymbolNameArr = [];
          let saveCapSymbolNameArr = [];
          let saveToSchemaNameArr = [];
          let saveToSymbolNameArr = [];
          let saveToCapSymbolNameArr = [];
          let schemaSymbolArr = [];
          let saveSchemaRes = {};
          let saveSymbolRes = {};
          let saveCapSymbolRes = {};
          let file_snow_id =
            this.file_gns.split("/")[this.file_gns.split("/").length - 1];
          let file_name = "";
          let accessor_id = "";
          if (this.projectRadio == "2") {
            category_child = "团队cell";
            accessor_id = this.teamId;
          } else {
            category_child = "个人cell";
            accessor_id = this.userId;
          }
          if (this.file_gns !== "") {
            saveSchFile = this.getSchFileFromTable(file_snow_id);
            if (fileId == file_snow_id) {
              let res = await createNewPFC_api({
                accessor_type: this.projectRadio,
                accessor_id,
                project_name: this.newProjectRuleForm.form_projectName,
                project_gns: this.project_gns,
                file_name: this.newProjectRuleForm.form_fileName,
                file_type: "3",
                file_gns: this.file_gns,
                cell_type: "3",
                cell_name: this.newProjectRuleForm.form_schematicName,
              });
              if (res.code === 200000) {
                let dumpSchema = new window.Kernel.Schema();
                downloadFileData(schema.dump(), "1", "oqpro");
                dumpSchema.load(schema.dump());
                downloadFileData(dumpSchema.dump(), "2", "oqpro");
                dumpSchema.name = res.data.cell.name;
                dumpSchema.snow_id = res.data.cell.snow_id;
                saveSchFile.add_schema(dumpSchema);
                await this.saveSchemaPublicFn(
                  dumpSchema,
                  "DIAGRAM_FILE",
                  res.data.cell.gns
                );
              }
            } else {
              if (saveSchFile !== null) {
                // 当目标原理图文件是打开的状态,重命名后-单独保存-添加进文件-保存整个文件
              } else {
                //当目标原理图文件未获取,获取文件数据,重命名后-单独保存-添加进文件-保存整个文件
                let saveToCellData = await getCellList_api({
                  start: 0,
                  rows: 200,
                  parent_gns: this.file_gns,
                  category,
                  category_child,
                });

                saveToCellData.data.data.forEach((item) => {
                  if (item._source.types == "3") {
                    saveToSchemaNameArr.push(item._source.name);
                  } else if (item._source.types == "4") {
                    saveToSymbolNameArr.push(item._source.name);
                  } else if (item._source.types == "5") {
                    saveToCapSymbolNameArr.push(item._source.name);
                  }
                  schemaSymbolArr.push([
                    item._source.snow_id,
                    item._source.name,
                  ]);
                });
                saveToSchemaNameArr.push(
                  this.newProjectRuleForm.form_schematicName
                );

                await this.getSchemaFile(schemaSymbolArr, this.file_gns, false);
                saveSchFile = this.getSchFileFromTable(file_snow_id);
              }

              //深拷贝所有相关数据
              this.recFileCallArr.forEach((item) => {
                dumpDataArr.push(item.dump());
              });
              saveSchFile.assemble(dumpDataArr);
              for (let i = 0; i < saveSchFile.schemas.size; i++) {
                if (saveSchFile.schemas.get(i).snow_id === cellId) {
                  this.recFileCallArr.splice(0, this.recFileCallArr.length);
                  this.recFileCall(saveSchFile.schemas.get(i));
                }
              }

              for (let i = 0; i < this.recFileCallArr.length; i++) {
                if (this.recFileCallArr[i].constructor.name == "Schema") {
                  if (this.recFileCallArr[i].snow_id === cellId) {
                    this.recFileCallArr[i].name =
                      this.newProjectRuleForm.form_schematicName;
                  } else {
                    this.recFileCallArr[i].name = newSymbolName(
                      this.recFileCallArr[i].name,
                      saveToSchemaNameArr
                    );
                  }
                  saveSchemaNameArr.push(this.recFileCallArr[i].name);
                } else if (
                  this.recFileCallArr[i].constructor.name == "Symbol"
                ) {
                  this.recFileCallArr[i].name = newSymbolName(
                    this.recFileCallArr[i].name,
                    saveToSymbolNameArr
                  );
                  saveSymbolNameArr.push(this.recFileCallArr[i].name);
                } else if (
                  this.recFileCallArr[i].constructor.name == "CapSymbol"
                ) {
                  this.recFileCallArr[i].name = newSymbolName(
                    this.recFileCallArr[i].name,
                    saveToCapSymbolNameArr
                  );
                  saveCapSymbolNameArr.push(this.recFileCallArr[i].name);
                }
              }

              let noErr = true;

              if (saveSchemaNameArr.length) {
                saveSchemaRes = await this.batchCreateFn(
                  file_snow_id,
                  this.projectRadio,
                  accessor_id,
                  this.project_gns,
                  this.newProjectRuleForm.form_fileName,
                  this.file_gns,
                  "3",
                  "3",
                  0,
                  JSON.stringify(saveSchemaNameArr)
                );
                if (saveSchemaRes.code !== 200000) {
                  noErr = false;
                }
              }
              if (saveSymbolNameArr.length) {
                saveSymbolRes = await this.batchCreateFn(
                  file_snow_id,
                  this.projectRadio,
                  accessor_id,
                  this.project_gns,
                  this.newProjectRuleForm.form_fileName,
                  this.file_gns,
                  "3",
                  "4",
                  0,
                  JSON.stringify(saveSymbolNameArr)
                );
                if (saveSymbolRes.code !== 200000) {
                  noErr = false;
                }
              }
              if (saveCapSymbolNameArr.length) {
                saveCapSymbolRes = await this.batchCreateFn(
                  file_snow_id,
                  this.projectRadio,
                  accessor_id,
                  this.project_gns,
                  this.newProjectRuleForm.form_fileName,
                  this.file_gns,
                  "3",
                  "5",
                  0,
                  JSON.stringify(saveCapSymbolNameArr)
                );
                if (saveCapSymbolRes.code !== 200000) {
                  noErr = false;
                }
              }

              if (noErr) {
                for (let i = 0; i < this.recFileCallArr.length; i++) {
                  if (this.recFileCallArr[i].constructor.name == "Schema") {
                    this.recFileCallArr[i].snow_id =
                      saveSchemaRes.data[this.recFileCallArr[i].name].split(
                        "/"
                      )[
                        saveSchemaRes.data[this.recFileCallArr[i].name].split(
                          "/"
                        ).length - 1
                      ];
                  } else if (
                    this.recFileCallArr[i].constructor.name == "Symbol"
                  ) {
                    this.recFileCallArr[i].snow_id =
                      saveSymbolRes.data[this.recFileCallArr[i].name].split(
                        "/"
                      )[
                        saveSymbolRes.data[this.recFileCallArr[i].name].split(
                          "/"
                        ).length - 1
                      ];
                  } else if (
                    this.recFileCallArr[i].constructor.name == "CapSymbol"
                  ) {
                    this.recFileCallArr[i].snow_id =
                      saveCapSymbolRes.data[this.recFileCallArr[i].name].split(
                        "/"
                      )[
                        saveCapSymbolRes.data[
                          this.recFileCallArr[i].name
                        ].split("/").length - 1
                      ];
                  }
                }

                for (let i = 0; i < this.recFileCallArr.length; i++) {
                  if (this.recFileCallArr[i].constructor.name == "Schema") {
                    saveSchFile.add_schema(this.recFileCallArr[i]);
                  } else if (
                    this.recFileCallArr[i].constructor.name == "Symbol"
                  ) {
                    saveSchFile.add_symbol(this.recFileCallArr[i]);
                  } else if (
                    this.recFileCallArr[i].constructor.name == "CapSymbol"
                  ) {
                    saveSchFile.add_cap_symbol(this.recFileCallArr[i]);
                  }
                }
              }

              if (saveSchFile.schemas.size) {
                for (let i = 0; i < saveSchFile.schemas.size; i++) {
                  if (saveSchemaRes.hasOwnProperty("data")) {
                    if (
                      saveSchemaRes.data.hasOwnProperty(
                        saveSchFile.schemas.get(i).name
                      )
                    ) {
                      await this.saveSchemaPublicFn(
                        saveSchFile.schemas.get(i),
                        "DIAGRAM_FILE",
                        saveSchemaRes.data[saveSchFile.schemas.get(i).name]
                      );
                    }
                  }
                }
              }
              if (saveSchFile.symbols.size) {
                for (let i = 0; i < saveSchFile.symbols.size; i++) {
                  if (saveSymbolRes.hasOwnProperty("data")) {
                    if (
                      saveSymbolRes.data.hasOwnProperty(
                        saveSchFile.symbols.get(i).name
                      )
                    ) {
                      await this.saveSchemaPublicFn(
                        saveSchFile.symbols.get(i),
                        "SYMBOLS_FILE",
                        saveSymbolRes.data[saveSchFile.symbols.get(i).name]
                      );
                    }
                  }
                }
              }
              if (saveSchFile.cap_symbols.size) {
                for (let i = 0; i < saveSchFile.cap_symbols.size; i++) {
                  if (saveCapSymbolRes.hasOwnProperty("data")) {
                    if (
                      saveCapSymbolRes.data.hasOwnProperty(
                        saveSchFile.cap_symbols.get(i).name
                      )
                    ) {
                      await this.saveSchemaPublicFn(
                        saveSchFile.cap_symbols.get(i),
                        "SYMBOLS_FILE",
                        saveCapSymbolRes.data[
                          saveSchFile.cap_symbols.get(i).name
                        ]
                      );
                    }
                  }
                }
              }
            }
          } else {
            for (let i = 0; i < this.recFileCallArr.length; i++) {
              if (this.recFileCallArr[i].constructor.name == "Schema") {
                if (this.recFileCallArr[i].snow_id === cellId) {
                  this.recFileCallArr[i].name =
                    this.newProjectRuleForm.form_schematicName;
                } else {
                  this.recFileCallArr[i].name = newSymbolName(
                    this.recFileCallArr[i].name,
                    [this.newProjectRuleForm.form_schematicName]
                  );
                  saveSchemaNameArr.push(this.recFileCallArr[i].name);
                }
              } else if (this.recFileCallArr[i].constructor.name == "Symbol") {
                saveSymbolNameArr.push(this.recFileCallArr[i].name);
              } else if (
                this.recFileCallArr[i].constructor.name == "CapSymbol"
              ) {
                saveCapSymbolNameArr.push(this.recFileCallArr[i].name);
              }
            }

            let accessor_id = "";
            if (this.projectRadio == "1") {
              accessor_id = this.userId;
            } else if (this.projectRadio == "2") {
              accessor_id = this.teamId;
            }
            let noErr = true;

            let res = await createNewPFC_api({
              accessor_type: this.projectRadio,
              accessor_id,
              project_name: this.newProjectRuleForm.form_projectName,
              project_gns: this.project_gns,
              file_name: this.newProjectRuleForm.form_fileName,
              file_type: "3",
              file_gns: this.file_gns,
              cell_type: "3",
              cell_name: this.newProjectRuleForm.form_schematicName,
            });

            if (res.code === 200000) {
              this.file_gns = res.data.file.gns;
              file_snow_id = res.data.file.snow_id;
              file_name = res.data.file.name;
              this.project_gns = res.data.project.gns;
              if (saveSchemaNameArr.length) {
                saveSchemaRes = await this.batchCreateFn(
                  file_snow_id,
                  this.projectRadio,
                  accessor_id,
                  this.project_gns,
                  this.newProjectRuleForm.form_fileName,
                  this.file_gns,
                  "3",
                  "3",
                  0,
                  JSON.stringify(saveSchemaNameArr)
                );
                if (saveSchemaRes.code !== 200000) {
                  noErr = false;
                }
              }
              if (saveSymbolNameArr.length) {
                saveSymbolRes = await this.batchCreateFn(
                  file_snow_id,
                  this.projectRadio,
                  accessor_id,
                  this.project_gns,
                  this.newProjectRuleForm.form_fileName,
                  this.file_gns,
                  "3",
                  "4",
                  0,
                  JSON.stringify(saveSymbolNameArr)
                );
                if (saveSymbolRes.code !== 200000) {
                  noErr = false;
                }
              }
              if (saveCapSymbolNameArr.length) {
                saveCapSymbolRes = await this.batchCreateFn(
                  file_snow_id,
                  this.projectRadio,
                  accessor_id,
                  this.project_gns,
                  this.newProjectRuleForm.form_fileName,
                  this.file_gns,
                  "3",
                  "5",
                  0,
                  JSON.stringify(saveCapSymbolNameArr)
                );
                if (saveCapSymbolRes.code !== 200000) {
                  noErr = false;
                }
              }

              if (noErr) {
                for (let i = 0; i < this.recFileCallArr.length; i++) {
                  if (this.recFileCallArr[i].constructor.name == "Schema") {
                    if (this.recFileCallArr[i].snow_id === cellId) {
                      this.recFileCallArr[i].snow_id = res.data.cell.snow_id;
                    } else {
                      this.recFileCallArr[i].snow_id =
                        saveSchemaRes.data[this.recFileCallArr[i].name].split(
                          "/"
                        )[
                          saveSchemaRes.data[this.recFileCallArr[i].name].split(
                            "/"
                          ).length - 1
                        ];
                    }
                  } else if (
                    this.recFileCallArr[i].constructor.name == "Symbol"
                  ) {
                    this.recFileCallArr[i].snow_id =
                      saveSymbolRes.data[this.recFileCallArr[i].name].split(
                        "/"
                      )[
                        saveSymbolRes.data[this.recFileCallArr[i].name].split(
                          "/"
                        ).length - 1
                      ];
                  } else if (
                    this.recFileCallArr[i].constructor.name == "CapSymbol"
                  ) {
                    this.recFileCallArr[i].snow_id =
                      saveCapSymbolRes.data[this.recFileCallArr[i].name].split(
                        "/"
                      )[
                        saveCapSymbolRes.data[
                          this.recFileCallArr[i].name
                        ].split("/").length - 1
                      ];
                  }
                }
              }

              saveSchFile = new window.Kernel.SchemaFile();
              saveSchFile.snow_id = file_snow_id;
              saveSchFile.name = file_name;

              for (let i = 0; i < this.recFileCallArr.length; i++) {
                if (this.recFileCallArr[i].constructor.name == "Schema") {
                  saveSchFile.add_schema(this.recFileCallArr[i]);
                } else if (
                  this.recFileCallArr[i].constructor.name == "Symbol"
                ) {
                  saveSchFile.add_symbol(this.recFileCallArr[i]);
                } else if (
                  this.recFileCallArr[i].constructor.name == "CapSymbol"
                ) {
                  saveSchFile.add_cap_symbol(this.recFileCallArr[i]);
                }
              }

              if (saveSchFile.schemas.size) {
                for (let i = 0; i < saveSchFile.schemas.size; i++) {
                  if (
                    saveSchFile.schemas.get(i).snow_id === res.data.cell.snow_id
                  ) {
                    await this.saveSchemaPublicFn(
                      saveSchFile.schemas.get(i),
                      "DIAGRAM_FILE",
                      res.data.cell.gns
                    );
                  } else {
                    await this.saveSchemaPublicFn(
                      saveSchFile.schemas.get(i),
                      "DIAGRAM_FILE",
                      saveSchemaRes.data[saveSchFile.schemas.get(i).name]
                    );
                  }
                }
              }
              if (saveSchFile.symbols.size) {
                for (let i = 0; i < saveSchFile.symbols.size; i++) {
                  await this.saveSchemaPublicFn(
                    saveSchFile.symbols.get(i),
                    "SYMBOLS_FILE",
                    saveSymbolRes.data[saveSchFile.symbols.get(i).name]
                  );
                }
              }
              if (saveSchFile.cap_symbols.size) {
                for (let i = 0; i < saveSchFile.cap_symbols.size; i++) {
                  await this.saveSchemaPublicFn(
                    saveSchFile.cap_symbols.get(i),
                    "SYMBOLS_FILE",
                    saveCapSymbolRes.data[saveSchFile.cap_symbols.get(i).name]
                  );
                }
              }
            }
          }

          await this.saveSchemaPublicFn(
            saveSchFile,
            "DIAGRAM_FILE",
            this.file_gns
          );
          let fatherNode = {};
          if (this.projectRadio == "1") {
            fatherNode = this.$refs.tree.$children[0].node;
          } else if (this.projectRadio == "2") {
            let index = this.$refs.tree.$children.findIndex(
              (item) => item.node.data.gns === this.team_gns
            );
            fatherNode = this.$refs.tree.$children[index].node;
          }

          let proGns = this.project_gns;
          let fileGns = this.file_gns;

          if (fatherNode.loaded) {
            let i = fatherNode.childNodes.findIndex(
              (item) => item.data.gns === proGns
            );
            if (i !== -1) {
              if (fatherNode.childNodes[i].loaded) {
                let index = fatherNode.childNodes[i].childNodes.findIndex(
                  (item) => item.data.gns === fileGns
                );

                if (index !== -1) {
                  fatherNode.childNodes[i].childNodes[index].loaded = false;
                  fatherNode.childNodes[i].childNodes[index].expand();
                } else {
                  fatherNode.childNodes[i].loaded = false;
                  fatherNode.childNodes[i].expand();
                  setTimeout(async () => {
                    index = fatherNode.childNodes[i].childNodes.findIndex(
                      (item) => item.data.gns === fileGns
                    );

                    fatherNode.childNodes[i].childNodes[index].loaded = false;
                    fatherNode.childNodes[i].childNodes[index].expand();
                  }, 1000);
                }
              } else {
                fatherNode.childNodes[i].expand();
                setTimeout(async () => {
                  for (
                    let index = 0;
                    index < fatherNode.childNodes[i].childNodes.length;
                    index++
                  ) {
                    if (
                      fatherNode.childNodes[i].childNodes[index].data.gns ==
                      fileGns
                    ) {
                      fatherNode.childNodes[i].childNodes[index].expand();
                    }
                  }
                }, 1000);
              }
            } else {
              fatherNode.loaded = false;
              fatherNode.expand();
              setTimeout(() => {
                let i = fatherNode.childNodes.findIndex(
                  (item) => item.data.gns === proGns
                );
                if (i !== -1) {
                  fatherNode.childNodes[i].expand();
                  setTimeout(async () => {
                    for (
                      let index = 0;
                      index < fatherNode.childNodes[i].childNodes.length;
                      index++
                    ) {
                      if (
                        fatherNode.childNodes[i].childNodes[index].data.gns ==
                        fileGns
                      ) {
                        fatherNode.childNodes[i].childNodes[index].expand();
                      }
                    }
                  }, 1000);
                }
              }, 2000);
            }
          } else {
            fatherNode.expand();
            setTimeout(async () => {
              for (let i = 0; i < fatherNode.childNodes.length; i++) {
                if (fatherNode.childNodes[i].data.gns == proGns) {
                  fatherNode.childNodes[i].expand();
                  setTimeout(async () => {
                    for (
                      let index = 0;
                      index < fatherNode.childNodes[i].childNodes.length;
                      index++
                    ) {
                      if (
                        fatherNode.childNodes[i].childNodes[index].data.gns ==
                        fileGns
                      ) {
                        fatherNode.childNodes[i].childNodes[index].expand();
                      }
                    }
                  }, 2000);
                }
              }
            }, 1000);
          }

          this.resetSaveAsDialog();
        } else {
          return false;
        }
      });
    },

    //另存为取消
    saveAsDialogCancelButton() {
      this.resetSaveAsDialog();
    },

    //另存为确认按钮激活
    saveAsDialogBtnActive() {
      if (
        (this.value_userName !== "" || this.value_teamName !== "") &&
        this.newProjectRuleForm.form_projectName !== "" &&
        this.newProjectRuleForm.form_fileName !== "" &&
        this.newProjectRuleForm.form_schematicName !== ""
      ) {
        this.activateBtn(this.$refs.saveAsDialogBtn);
      } else {
        this.disableBtn(this.$refs.saveAsDialogBtn);
      }
    },

    //另存为重置
    resetSaveAsDialog() {
      this.projectRadio = "1";
      this.team_gns = "";
      this.project_gns = "";
      this.file_gns = "";
      this.value_teamName = "";
      this.newProjectRuleForm.form_projectName = "";
      this.newProjectRuleForm.form_fileName = "";
      this.newProjectRuleForm.form_schematicName = "";
      this.saveAsDialogVisible = false;
      bus.$emit("ending", true);
    },

    //保存原理图文件上传文件流
    async putObject(formData, file_type, snow_id, gns) {
      let folder_name = "";
      if (gns) {
        folder_name = this.getCurrentBoardFolderName(snow_id, gns);
      } else {
        folder_name = this.getCurrentBoardFolderName(snow_id);
      }
      let bucket_name = localStorage.getItem("bucket_name");
      let time = parseInt(new Date().getTime() / 1000);
      formData.append("time", time);
      formData.append("bucket_name", bucket_name);
      formData.append("folder_name", folder_name);
      formData.append("file_type", file_type);
      formData.append("object_name", "");
      let apiKey = localStorage.getItem("apiKey");
      let res = await putObject_api(formData, {
        sign: Md5(`${apiKey}_${time}`),
        username: "test",
      });
      if (res.code === 200000) {
        let paramsArr = [{ file_url: res.data.object_path, snow_id }];
        let res2 = await saveDiagramFile_api({ file_data: paramsArr });
        if (this.isReName) {
          this.$message({
            message: this.$t("messages.successfullyModified"),
            type: "success",
          });
          this.isReName = false;
        }
      }

      //保存当前返回的url
    },

    //获取文件夹名称,一个用户一个文件夹,一个团队一个文件夹
    getCurrentBoardFolderName(snow_id, gns) {
      let cellGns = "";
      if (gns) {
        cellGns = gns;
      } else {
        let cellNode = this.$refs.tree.getNode(snow_id);
        cellGns = cellNode.data.gns;
      }
      if (this.$store.state.proInfo.hasOwnProperty("teamRule")) {
        //团队项目
        return `team_${this.$store.state.topInfo.teamId}/${cellGns}`;
      } else {
        //个人项目
        return `user_${this.userId}/${cellGns}`;
      }
      // if (this.$store.state.proInfo.hasOwnProperty("teamRule")) {
      //   //团队项目
      //   return `team_${this.$store.state.topInfo.teamId}`
      // } else {
      //   //个人项目
      //   return `user_${this.userId}`
      // }
    },

    filterNode(value, data) {
      // return data.label.indexOf(value) !== -1;
    },

    // 保存当前画板原理图
    async saveSchema(saveData, saveFile) {
      //递归获取当前原理图画板对应的原理图调用的文件
      this.recFileCallArr.splice(0, this.recFileCallArr.length);
      this.recFileCall(saveData);

      if (this.recFileCallArr.length) {
        for (let i = 0; i < this.recFileCallArr.length; i++) {
          let saveNode = this.$refs.tree.getNode(
            this.recFileCallArr[i].snow_id
          );

          let file_gns = saveNode.parent.data.gns;
          let file_name = saveNode.parent.data.name;
          let project_gns = saveNode.parent.parent.data.gns;
          let project_name = saveNode.parent.parent.data.name;
          let accessor_id = "";
          let accessor_type = "";
          if (saveNode.data.isTeam) {
            accessor_id = saveNode.parent.parent.parent.data.snow_id;
            accessor_type = "2";
          } else {
            accessor_id = this.userId;
            accessor_type = "1";
          }

          if (this.recFileCallArr[i].constructor.name === "Schema") {
            if (saveNode.data.isTemporary) {
            } else {
              if (!this.recFileCallArr[i].js_obj.isSave) {
                this.recFileCallArr[i].js_obj.isSave = true;
                await this.saveSchemaPublicFn(
                  this.recFileCallArr[i],
                  "DIAGRAM_FILE",
                  [file_gns, this.recFileCallArr[i].snow_id].join("/")
                );
              } else {
              }
              bus.$emit(
                "saveSchemaFileSuccess",
                this.recFileCallArr[i].snow_id
              );
            }
          } else {
            if (this.recFileCallArr[i].constructor.name === "Symbol") {
              await this.saveSchemaPublicFn(
                this.recFileCallArr[i],
                "SYMBOLS_FILE",
                [file_gns, this.recFileCallArr[i].snow_id].join("/")
              );
            } else {
              await this.saveSchemaPublicFn(
                this.recFileCallArr[i],
                "SYMBOLS_FILE",
                [file_gns, this.recFileCallArr[i].snow_id].join("/")
              );
            }
          }
        }

        let fileGns = this.$refs.tree.getNode(saveFile.snow_id).data.gns;
        await this.saveSchemaPublicFn(saveFile, "DIAGRAM_FILE", fileGns);
      }
      if (this.isCreateCapSymbol) {
        this.$message({
          message: "封装成功",
          type: "success",
        });
        this.isCreateCapSymbol = false;
      }

      bus.$emit("ending", true);
    },

    // 单个原理图/符号/封装符号保存方法
    async saveSchemaPublicFn(fileData, type, gns) {
      let data = fileData.dump();
      // 使用data构造blob，然后发送到后端
      let blob = new Blob([data]);
      let formData = new FormData(); //新建表单对象
      formData.append("file", blob);
      await this.putObject(formData, type, fileData.snow_id, gns);
    },

    //获取原理图文件
    async getSchemaFile(schemaSymbolArr, fileGns, isSaveToTable) {
      if (!isSaveToTable) {
        //如果映射表无该文件数据,请求
        let newSchemaFile = new window.Kernel.SchemaFile();
        let fileId = fileGns.split("/")[fileGns.split("/").length - 1];
        let SchemaFileStreamArr = [];
        let SchemaFileDataArr = [];
        newSchemaFile.snow_id = fileId;
        if (schemaSymbolArr.length) {
          let newDataArr = await getFileDataFn(schemaSymbolArr);

          SchemaFileStreamArr = newDataArr[0];
          SchemaFileDataArr = newDataArr[1];

          // SchemaFileStreamArr.forEach(item=>{
          //   downloadFileData(item, "u8Data", "txt")
          // })
          if (SchemaFileStreamArr.length > 0) {
            newSchemaFile.assemble(SchemaFileStreamArr);
            await this.transOldIdToNewId(
              newSchemaFile,
              schemaSymbolArr,
              fileGns
            );
          }

          SchemaFileDataArr.forEach((item) => {
            newSchemaFile.add_schema(item);
          });

          let fileData = {
            fileId,
            fileData: newSchemaFile,
          };
          this.$store.commit("setDataToschemaFileTable", fileData);

          for (let i = 0; i < newSchemaFile.schemas.size; i++) {
            newSchemaFile.schemas.get(i).js_obj.isSave = true;
          }
          if (this.$refs.tree.getNode(fileId) !== null) {
            this.$refs.tree.getNode(fileId).childNodes.forEach((item) => {
              if (item.data.cell_type == "3") {
                item.loaded = false;
                item.expanded = false;
                item.isLeaf = false;
              }
            });
          }
        } else {
          let fileData = {
            fileId,
            fileData: newSchemaFile,
          };
          this.$store.commit("setDataToschemaFileTable", fileData);
        }
      } else {
        //如果映射表已有数据,不请求
        return 0;
      }
    },

    //获取文件数据旧snow_id对应的新snow_id
    async transOldIdToNewId(newSchemaFile, schemaSymbolArr, fileGns) {
      let newSnowIdArr = [];
      let oldSnowIdArr = [];
      let needTransId = false;
      for (let i = 0; i < schemaSymbolArr.length; i++) {
        newSnowIdArr.push(schemaSymbolArr[i][0]);
      }
      for (let i = 0; i < newSchemaFile.children.length; i++) {
        oldSnowIdArr.push(newSchemaFile.children[i].snow_id);
      }

      let newSnowIdArrRes = await getCopySnowId_api({ snow_ids: newSnowIdArr });
      if (newSnowIdArrRes.code === 200000) {
        if (newSnowIdArrRes.data.length > 0) {
          // 判断是否是已经改变后的文件

          for (const i in oldSnowIdArr) {
            let index = newSnowIdArr.findIndex(
              (item) => item == oldSnowIdArr[i]
            );
            if (index === -1) {
              needTransId = true;
              break;
            }
          }
          if (needTransId) {
            for (let i = 0; i < newSnowIdArrRes.data.length; i++) {
              for (let j = 0; j < newSnowIdArr.length; j++) {
                if (newSnowIdArr[j] === newSnowIdArrRes.data[i].snow_id) {
                  this.changeSchemaFileContentInfo(
                    newSchemaFile,
                    newSnowIdArrRes.data[i].relation_key.old_snow_id,
                    newSnowIdArr[j]
                  );
                }
              }
            }

            await saveAllSchemaFileData(newSchemaFile, fileGns);
            let fileId = fileGns.split("/")[fileGns.split("/").length - 1];
            this.$refs.tree.getNode(fileId).loaded = false;
            this.$refs.tree.getNode(fileId).expand();
          }
        }
      }
    },

    // 修改原理图文件中数据对应的id
    changeSchemaFileContentInfo(newSchemaFile, oldId, newId) {
      for (let i = 0; i < newSchemaFile.schemas.size; i++) {
        if (newSchemaFile.schemas.get(i).snow_id === oldId) {
          newSchemaFile.schemas.get(i).snow_id = newId;
          return;
        }
      }
      for (let i = 0; i < newSchemaFile.symbols.size; i++) {
        if (newSchemaFile.symbols.get(i).snow_id === oldId) {
          newSchemaFile.symbols.get(i).snow_id = newId;
          return;
        }
      }
      for (let i = 0; i < newSchemaFile.cap_symbols.size; i++) {
        if (newSchemaFile.cap_symbols.get(i).snow_id === oldId) {
          newSchemaFile.cap_symbols.get(i).snow_id = newId;
          return;
        }
      }
    },

    //原理图映射表中获取原理图文件
    getSchFileFromTable(schFileId) {
      let schFile = {};
      if (this.$store.state.schemaFileTable.length) {
        let index = this.$store.state.schemaFileTable.findIndex(
          (item) => item.fileId === schFileId
        );
        if (index == -1) {
          schFile = null;
        } else {
          schFile = this.$store.state.schemaFileTable[index].fileData;
        }
      } else {
        schFile = null;
      }
      return schFile;
    },

    //原理图文件中获取原理图数据
    getSchemaFromSchFile(schemaFile, schemaId) {
      for (let i = 0; i < schemaFile.schemas.size; i++) {
        if (schemaFile.schemas.get(i).snow_id === schemaId) {
          return schemaFile.schemas.get(i);
        }
      }
    },

    //原理图文件中获取符号
    getSymbolFromSchFile(schemaFile, symbolId) {
      for (let i = 0; i < schemaFile.symbols.size; i++) {
        if (schemaFile.symbols.get(i).snow_id === symbolId) {
          return schemaFile.symbols.get(i);
        }
      }
    },

    //原理图文件中获取封装符号
    getCapSymbolFromSchFile(schemaFile, capSymbolId) {
      for (let i = 0; i < schemaFile.cap_symbols.size; i++) {
        if (schemaFile.cap_symbols.get(i).snow_id === capSymbolId) {
          return schemaFile.cap_symbols.get(i);
        }
      }
    },

    // 递归获取文件调用关系
    recFileCall(data) {
      this.recFileCallArr.unshift(data);
      if (data.children.length) {
        data.children.forEach((item) => {
          this.recFileCall(item);
        });
      }
    },

    //更新调用相关树结构
    updateFileTree() {
      let cellNode = this.$refs.tree.getNode(this.$store.state.cellInfo.cellId);
      cellNode.loaded = false;
      cellNode.expand();
    },

    async updateSchemaFileTree() {
      bus.$emit("loading", true);
      let cellNode = this.$refs.tree.getNode(this.$store.state.cellInfo.cellId);
      let fileNode = cellNode.parent;
      let proNode = cellNode.parent.parent;
      let teamNode = cellNode.parent.parent.parent;
      let childrenIdArr = [];
      let project_name = proNode.data.name;
      let project_gns = proNode.data.gns;
      let file_name = fileNode.data.name;
      let file_gns = fileNode.data.gns;
      let accessor_id = "";
      let accessor_type = "";

      // if (this.$store.state.cellInfo.cell_type == "3") {

      let currentSchemaFile = this.getSchFileFromTable(
        this.$store.state.fileInfo.fileId
      );
      let currentSchema = this.getSchemaFromSchFile(
        currentSchemaFile,
        this.$store.state.cellInfo.cellId
      );
      this.recFileCallArr.splice(0, this.recFileCallArr.length);
      this.recFileCall(currentSchema);
      fileNode.childNodes.forEach((item) => {
        childrenIdArr.push(item.data.snow_id);
      });
      // let newCreateDataArr = this.recFileCallArr
      let newCreateDataArr = this.recFileCallArr.filter(
        (item) => !childrenIdArr.some((value) => value === item.snow_id)
      );

      for (let i = 0; i < newCreateDataArr.length; i++) {
        let id = Math.random().toString(36).substr(2, 10);
        let gns = `${fileNode.data.gns}/${id}`;
        let cell_type = "";
        let epschemaName = "";
        if (newCreateDataArr[i].constructor.name == "Schema") {
          cell_type = "3";
          epschemaName = "";
        } else if (newCreateDataArr[i].constructor.name == "Symbol") {
          cell_type = "4";
          epschemaName = "";
        } else if (newCreateDataArr[i].constructor.name == "CapSymbol") {
          cell_type = "5";
          if (newCreateDataArr[i].children[0]) {
            epschemaName = `(${newCreateDataArr[i].children[0].name})`;
          }
        }
        newCreateDataArr[i].snow_id = id;

        if (fileNode.data.isTeam) {
          accessor_id = teamNode.data.snow_id;
          accessor_type = "2";
        } else {
          accessor_id = this.userId;
          accessor_type = "1";
        }

        let res = await createNewPFC_api({
          accessor_type,
          accessor_id,
          project_name,
          project_gns,
          file_name,
          file_type: "3",
          file_gns,
          cell_type,
          cell_name: newCreateDataArr[i].name,
        });
        if (res.code === 200000) {
          newCreateDataArr[i].snow_id = res.data.cell.snow_id;
          let newData = {
            name: res.data.cell.name,
            gns: res.data.cell.gns,
            category: "CELL",
            snow_id: res.data.cell.snow_id,
            showInput: false,
            cell_type,
            isTeam: fileNode.data.isTeam,
            isLeaf: false,
            isTemporary: false,
            epschemaName,
            showInput: false,
          };
          this.$refs.tree.append(newData, fileNode);
        }
      }
      this.saveSchema(currentSchema, currentSchemaFile);

      cellNode.loaded = false;
      cellNode.expand();
    },
  },

  async created() {
    this.userName = localStorage.getItem("userName");
    this.userId = localStorage.getItem("userId");

    let res = await getUserProjectList_api({
      start: 0,
      rows: 50,
    });
    if (res.code !== 200000) {
      this.errDialog(
        this.$t("messages.wrong"),
        this.$t("messages.code_" + res.code),
        true
      );
      return;
    }
    let teamList = await getTeamList_api({
      start: 0,
      rows: 50,
      key_word: "",
    });
    if (teamList.code !== 200000) {
      this.errDialog(
        this.$t("messages.wrong"),
        this.$t("messages.code_" + teamList.code),
        true
      );
      return;
    }

    if (
      res.data.total !== 0 ||
      (res.data.total === 0 && teamList.data.data.length !== 0)
    ) {
      this.createButtonVisible = false;
      this.noData = false;
      this.treeVisible = true;
      this.treeNameList.push({
        name: this.userName,
        gns: "",
        category: "",
        showInput: false,
        snow_id: "",
        isTeam: false,
      });
    } else {
      this.createButtonVisible = true;
      this.noData = true;
      this.treeVisible = false;
    }
    this.options_userName[0].value = this.userName;

    if (teamList.code === 200000) {
      this.options_teamName.splice(0, this.options_teamName.length);
      teamList.data.data.forEach((item) => {
        this.options_teamName.push({
          category: "",
          creator_id: item.creator_id,
          gns: item.gns,
          role: item.role,
          snow_id: item.snow_team_id,
          name: item.team_name,
          isTeam: true,
        });
      });
      this.options_teamName.forEach((item) => {
        this.treeNameList.push(item);
      });
    } else {
      this.errDialog(this.$t("messages.wrong"), teamList.message, true);
    }
  },

  mounted() {
    //文件列表状态记录
    // if (JSON.parse(localStorage.getItem('expandedList'))) {
    //   this.expandedList = JSON.parse(localStorage.getItem('expandedList'))
    // }

    this.$bus.$on("changeLang", (data) => {
      this.options_group = [
        {
          // 我的器件库
          value: this.$t("messages.MyLibrary"),
        },
        {
          // 团队器件库
          value: this.$t("messages.TeamLibrary"),
        },
      ];

      let projectNameRule = (rule, value, callback) => {
        if (value === "") {
          callback();
        } else if (
          [...value][0] == " " ||
          [...value][[...value].length - 1] == " "
        ) {
          callback(new Error(this.$t("messages.fileTip1")));
        } else if (!/^[\s&()~+-_\[\]0-9a-zA-Z\u4e00-\u9fa5]+$/.test(value)) {
          callback(new Error(this.$t("messages.fileTip1")));
        } else {
          callback();
        }
      };
      let fileNameRule = (rule, value, callback) => {
        if (value === "") {
          callback();
        } else if (
          [...value][0] == " " ||
          [...value][[...value].length - 1] == " "
        ) {
          callback(this.$t("messages.fileTip2"));
        } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
          callback(this.$t("messages.fileTip2"));
        } else {
          callback();
        }
      };
      this.rules.form_projectName[0].validator = projectNameRule;
      this.rules.form_importProjectName[0].validator = projectNameRule;
      this.rules.form_fileName[0].validator = fileNameRule;
      this.rules.form_cellName[0].validator = fileNameRule;
      this.rules.form_schematicName[0].validator = fileNameRule;
      this.rules.form_copyName[0].validator = fileNameRule;
      this.rules.form_deviceName[0].validator = fileNameRule;
    });

    this.$store.commit("getTreeNameList", this.treeNameList);
    this.$refs.topHalf.style.maxHeight = window.innerHeight - 125 + "px";
    let self = this;
    bus.$on("saveFileSuccessToFile", () => {
      if (!this.isDelete) {
        this.$message({
          message: this.$t("messages.saveSuccess"),
          type: "success",
        });
      }
    });
    bus.$on("openNewDialog", (data) => {
      this.headerCreate();
      // this.errDialog(this.$t('messages.wrong'), "里面包含特殊字符或者字符数小于1", true);
    });
    bus.$on("saveFile", async (data) => {
      let fileNode = this.$refs.tree.getNode(this.$store.state.fileInfo.fileId);
      let batchCellNodeArr = fileNode.childNodes.filter(
        (item) => item.data.isTemporary === true
      );
      let res = {};
      let oldCellNameList = [];
      if (fileNode.data.hasOwnProperty("teamRule")) {
        res = await getCellList_api({
          start: 0,
          rows: 200,
          parent_gns: fileNode.data.gns,
          category: "CELL",
          category_child: "团队cell",
        });
      } else {
        res = await getCellList_api({
          start: 0,
          rows: 200,
          parent_gns: fileNode.data.gns,
          category: "CELL",
          category_child: "个人cell",
        });
      }

      if (res.code === 200000) {
        res.data.data.forEach((item) => {
          oldCellNameList.push(item._source.name);
        });

        let cell_name_list = [];
        batchCellNodeArr.forEach((item) => {
          cell_name_list.push(item.data.name);
        });
        // for (let x = 0; x < 200000.length; x++) {
        //   if (oldCellNameList.findIndex(item => item === cell_name_list[x]) !== -1) {
        //     cell_name_list.splice(x, 1)
        //   }

        // }
        cell_name_list = cell_name_list.filter(
          (name) => oldCellNameList.indexOf(name) == -1
        );
        if (cell_name_list.length > 0) {
          let file_snow_id = this.$store.state.fileInfo.fileId;
          let project_gns = fileNode.parent.data.gns;
          let file_name = fileNode.data.name;
          let file_gns = fileNode.data.gns;
          let accessor_type = null;
          let accessor_id = null;
          if (fileNode.parent.parent.data.isTeam) {
            accessor_type = "2";
            accessor_id = fileNode.parent.parent.snow_id;
          } else {
            accessor_type = "1";
            accessor_id = this.userId;
          }

          var formData = new FormData(); //新建表单对象
          formData.append("accessor_type", accessor_type);
          formData.append("accessor_id", accessor_id);
          formData.append("project_gns", project_gns);
          formData.append("snow_file_id", file_snow_id);
          formData.append("file_name", file_name);
          formData.append("file_gns", file_gns);
          formData.append("file_type", "1");
          formData.append("cell_type", "2");
          formData.append("is_upload_file", 0);
          formData.append("cell_name_list", JSON.stringify(cell_name_list));

          batchCreatePWD_api(formData)
            .then((res) => {})
            .catch((err) => {
              this.errDialog(
                this.$t("messages.wrong"),
                this.$t("messages.code_" + err.code),
                true
              );
            });
        }
      } else {
        this.errDialog(
          this.$t("messages.wrong"),
          this.$t("messages.code_" + res.code),
          true
        );
        return;
      }
    });
    bus.$on("errDialog", (data) => {
      this.errDialog(this.$t("messages.wrong"), data, true);
    });

    bus.$on("importDialog", (data) => {
      this.importType = data;
      this.importGDS();
    });
    bus.$on("importSchDialog", (data) => {
      // this.importGDS();
    });
    bus.$on("resetImportDialog", (data) => {
      this.resetImportDialog();
    });
    bus.$on("updateFileTree", (data) => {
      if (data == true) {
        this.updateFileTree();
      } else if (Array.isArray(data)) {
        let fileNode = this.$refs.tree.getNode(
          this.$store.state.fileInfo.fileId
        );
        let cellNode = this.$refs.tree.getNode(
          this.$store.state.cellInfo.cellId
        );

        //读取文件数据
        let file_snow_id = this.$store.state.fileInfo.fileId;
        let file = window.QGdstk.read_gds(file_snow_id);
        expand_repetition(file);

        let cellsNameArr = [];
        file.cells.forEach((item) => {
          cellsNameArr.push(item.name);
        });

        if (this.isFileCall) {
          data.forEach((item) => {
            if (this.deferentFileCall) {
              //生成随机数
              let id = Math.random().toString(36).substr(2, 10);

              let gns = `${fileNode.data.gns}/${id}`;

              if (fileNode.data.isTeam) {
                this.$refs.tree.append(
                  {
                    name: item,
                    isLeaf: true,
                    isTemporary: true,
                    cell_type: 2,
                    gns,
                    category: "CELL",
                    snow_id: id,
                    isTeam: true,
                    teamRule: fileNode.data.teamRule,
                  },
                  fileNode
                );
              } else {
                this.$refs.tree.append(
                  {
                    name: item,
                    isLeaf: true,
                    isTemporary: true,
                    cell_type: 2,
                    gns,
                    category: "CELL",
                    snow_id: id,
                    isTeam: false,
                  },
                  fileNode
                );
              }
            }
          });
        } else {
          data.forEach((item) => {
            //生成随机数
            let id = Math.random().toString(36).substr(2, 10);

            let gns = `${fileNode.data.gns}/${id}`;

            if (fileNode.data.isTeam) {
              this.$refs.tree.append(
                {
                  name: item,
                  isLeaf: true,
                  isTemporary: true,
                  cell_type: 2,
                  gns,
                  category: "CELL",
                  snow_id: id,
                  isTeam: true,
                  teamRule: fileNode.data.teamRule,
                },
                fileNode
              );
            } else {
              this.$refs.tree.append(
                {
                  name: item,
                  isLeaf: true,
                  isTemporary: true,
                  cell_type: 2,
                  gns,
                  category: "CELL",
                  snow_id: id,
                  isTeam: false,
                },
                fileNode
              );
            }
          });
        }

        this.isFileCall = false;
        this.deferentFileCall = false;
        cellNode.loaded = false;
        cellNode.expand();
      } else if (
        typeof data == "string" &&
        data.constructor == String &&
        this.cellUnsaved
      ) {
        let file_snow_id = data.split("/")[0];
        let cell_snow_id = data.split("/")[1];
        let fileNode = this.$refs.tree.getNode(file_snow_id);
        let cellNode = this.$refs.tree.getNode(cell_snow_id);
        let currentCellNameList = [];
        let currentCellChildrenNameList = [];
        fileNode.childNodes.forEach((item) => {
          currentCellNameList.push({
            name: item.data.name,
            snow_id: item.data.snow_id,
          });
        });
        cellNode.childNodes.forEach((item) => {
          currentCellChildrenNameList.push({
            name: item.data.name,
            snow_id: item.data.snow_id,
          });
        });

        getCellFile_api({ file_snow_id })
          .then((fileRes) => {
            if (fileRes.data.file_url) {
              getObjectDataFn(fileRes.data.file_url).then((u8_data) => {
                window.QGdstk.FS.writeFile(file_snow_id, u8_data);
                let callFile = window.QGdstk.read_gds(file_snow_id);
                expand_repetition(callFile);

                if (fileNode.data.isTeam) {
                  getCellList_api({
                    start: 0,
                    rows: 200,
                    parent_gns: fileNode.data.gns,
                    category: "CELL",
                    category_child: "团队cell",
                  }).then((cellListRes) => {
                    if (cellListRes.code === 200000) {
                      this.delUnsavedCell(
                        currentCellNameList,
                        cellListRes.data.data
                      );
                      cellNode.loaded = false;
                      cellNode.expand();
                    } else {
                      this.errDialog(
                        this.$t("messages.wrong"),
                        this.$t("messages.code_" + cellListRes.code),
                        true
                      );
                    }
                  });
                } else {
                  getCellList_api({
                    start: 0,
                    rows: 200,
                    parent_gns: fileNode.data.gns,
                    category: "CELL",
                    category_child: "个人cell",
                  }).then((cellListRes) => {
                    if (cellListRes.code === 200000) {
                      this.delUnsavedCell(
                        currentCellNameList,
                        cellListRes.data.data
                      );
                      cellNode.loaded = false;
                      cellNode.expand();
                    } else {
                      this.errDialog(
                        this.$t("messages.wrong"),
                        this.$t("messages.code_" + cellListRes.code),
                        true
                      );
                    }
                  });
                }
              });
            } else {
              if (fileNode.data.isTeam) {
                getCellList_api({
                  start: 0,
                  rows: 200,
                  parent_gns: fileNode.data.gns,
                  category: "CELL",
                  category_child: "团队cell",
                }).then((cellListRes) => {
                  if (cellListRes.code === 200000) {
                    this.delUnsavedCell(
                      currentCellNameList,
                      cellListRes.data.data
                    );
                  } else {
                    this.errDialog(
                      this.$t("messages.wrong"),
                      this.$t("messages.code_" + cellListRes.code),
                      true
                    );
                  }
                });
              } else {
                getCellList_api({
                  start: 0,
                  rows: 200,
                  parent_gns: fileNode.data.gns,
                  category: "CELL",
                  category_child: "个人cell",
                }).then((cellListRes) => {
                  if (cellListRes.code === 200000) {
                    currentCellChildrenNameList.forEach((item) => {
                      this.$refs.tree.remove(item.snow_id);
                    });
                    this.delUnsavedCell(
                      currentCellNameList,
                      cellListRes.data.data
                    );
                  } else {
                    this.errDialog(
                      this.$t("messages.wrong"),
                      this.$t("messages.code_" + cellListRes.code),
                      true
                    );
                  }
                });
              }
            }
          })
          .catch((fileErr) => {
            this.errDialog(
              this.$t("messages.wrong"),
              this.$t("messages.code_" + fileErr.code),
              true
            );
          });
      } else {
        return;
      }
    });
    bus.$on("labelInfo", (data) => {});
    bus.$on("textGraphicsInfo", (data) => {});
    bus.$on("keyPointInfo", (data) => {});
    bus.$on("saveAsCellFn", (data) => {
      bus.$emit("loading", true);
      this.saveAsCellCreateFn(data);
    });
    bus.$on("getBoxData", (data) => {
      this.boxData = data;
    });
    bus.$on("getPrecision", (data) => {});
    bus.$on("deviceCall", (data) => {});
    bus.$on("closeAndUnsaved", (data) => {
      this.cellUnsaved = data;
    });
    bus.$on("syncCellList", (fileId) => {
      let currentCellChildrenNameList = [];
      let fileNode = this.$refs.tree.getNode(fileId);

      if (fileNode) {
        window.QGdstk.read_gds(fileId).cells.forEach((item) => {
          if (item.name === this.$store.state.cellInfo.name) {
            item.dependencies(true).forEach((v) => {
              currentCellChildrenNameList.push(v.name);
            });
          }
        });

        fileNode.childNodes.forEach((value) => {
          let index = currentCellChildrenNameList.findIndex(
            (item) => item === value.data.name
          );
          if (index !== -1) {
            currentCellChildrenNameList.splice(index, 1);
          }
        });

        if (currentCellChildrenNameList.length > 0) {
          // 生成随机数
          currentCellChildrenNameList.forEach((item) => {
            let id = Math.random().toString(36).substr(2, 10);

            let gns = `${fileNode.data.gns}/${id}`;

            if (fileNode.data.isTeam) {
              this.$refs.tree.append(
                {
                  name: item,
                  isLeaf: true,
                  isTemporary: true,
                  cell_type: 2,
                  gns,
                  category: "CELL",
                  snow_id: id,
                  isTeam: true,
                  teamRule: fileNode.data.teamRule,
                },
                fileNode
              );
            } else {
              this.$refs.tree.append(
                {
                  name: item,
                  isLeaf: true,
                  isTemporary: true,
                  cell_type: 2,
                  gns,
                  category: "CELL",
                  snow_id: id,
                  isTeam: false,
                },
                fileNode
              );
            }
          });

          let project_gns = fileNode.parent.data.gns;
          let file_name = fileNode.data.name;
          let file_gns = fileNode.data.gns;
          let accessor_type = null;
          let accessor_id = null;
          if (fileNode.parent.parent.data.isTeam) {
            accessor_type = "2";
            accessor_id = fileNode.parent.parent.snow_id;
          } else {
            accessor_type = "1";
            accessor_id = this.userId;
          }

          var formData = new FormData(); //新建表单对象
          formData.append("accessor_type", accessor_type);
          formData.append("accessor_id", accessor_id);
          formData.append("project_gns", project_gns);
          formData.append("snow_file_id", fileId);
          formData.append("file_name", file_name);
          formData.append("file_gns", file_gns);
          formData.append("file_type", "1");
          formData.append("cell_type", "2");
          formData.append("is_upload_file", 0);
          formData.append(
            "cell_name_list",
            JSON.stringify(currentCellChildrenNameList)
          );
          // formData.append('file', new Blob([file])) //把文件二进制对象添加到表单对象里

          batchCreatePWD_api(formData)
            .then((res) => {})
            .catch((err) => {
              this.errDialog(
                this.$t("messages.wrong"),
                this.$t("messages.code_" + err.code),
                true
              );
            });
        }
      }
    });
    bus.$on("syncLayerList", (data) => {
      setTimeout(() => {
        if (!window.historyClick) {
          let node = this.$refs.tree.getNode(this.$store.state.cellInfo.cellId);
          if (node) {
            node.loaded = false;
            node.expand();

            setTimeout(() => {
              if (node.childNodes.length == 0) {
                node.isLeaf = true;
              }
            }, 1000);
          }
        } else {
          window.historyClick = false;
        }
      }, 500);
    });

    // 拆分版图文件
    bus.$on("splitCell", async (data) => {
      this.isSplit = true;

      this.cellNames = data.cells.map((c) => c.name);

      let proData = this.$store.state.proInfo;
      let topData = this.$store.state.topInfo;

      let team_gns = `gns://${topData.teamId}`;
      let id = Math.random().toString(36).substr(2, 10);
      let fileName = `${data.name}_${id}`;
      data.name = fileName;
      data.write_gds("1");

      let file = QGdstk.FS.readFile("1");
      let ImportFileLayers = this.getImportFileLayers(data);

      if (proData.hasOwnProperty("teamRule")) {
        let project_gns = `gns://${topData.teamId}/${proData.projectId}`;
        bus.$emit("loading", true);
        var formData = new FormData(); //新建表单对象
        formData.append("snow_file_id", "");
        formData.append("accessor_type", "2");
        formData.append("accessor_id", topData.teamId);
        formData.append("project_gns", project_gns);
        formData.append("file_name", fileName);
        formData.append("file_gns", "");
        formData.append("file_type", "1");
        formData.append("cell_type", "1");
        formData.append("layer_info", JSON.stringify(ImportFileLayers));
        formData.append("is_upload_file", 1);
        formData.append("cell_name_list", JSON.stringify(this.cellNames));
        formData.append("file", new Blob([file])); //把文件二进制对象添加到表单对象里

        try {
          let res = await batchCreatePWD_api(formData);

          if (res.code === 200000) {
            let project_snow_id =
              res.data["cell_1"].split("/")[
                res.data["cell_1"].split("/").length - 3
              ];
            let file_snow_id =
              res.data["cell_1"].split("/")[
                res.data["cell_1"].split("/").length - 2
              ];
            this.createButtonVisible = false;
            this.projectData = {
              name: proData.name,
              snow_id: project_snow_id,
            };
            this.fileData = {
              name: fileName,
              snow_id: file_snow_id,
            };
            this.noData = false;
            this.treeVisible = true;
            // let index = this.$refs.tree.$children.findIndex(item => item.node.data.gns === team_gns)
            // this.userNode = this.$refs.tree.$children[index].node
            // this.teamName = topData.name
            this.projectRadio = "2";
            this.node = this.$refs.tree.getNode(proData.projectId);
            this.refreshTreeNode();
          }
        } catch (err) {
          this.isSplit = false;
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + err.code),
            true
          );
        }
      } else {
        bus.$emit("loading", true);
        let project_gns = `gns://${proData.projectId}`;
        var formData = new FormData(); //新建表单对象
        formData.append("snow_file_id", "");
        formData.append("accessor_type", "1");
        formData.append("accessor_id", this.userId);
        formData.append("project_gns", project_gns);
        formData.append("file_name", fileName);
        formData.append("file_gns", "");
        formData.append("cell_type", "1");
        formData.append("file_type", "1");
        formData.append("layer_info", JSON.stringify(ImportFileLayers));
        formData.append("is_upload_file", 1);
        formData.append("cell_name_list", JSON.stringify(this.cellNames));
        formData.append("file", new Blob([file])); //把文件二进制对象添加到表单对象里
        try {
          let res = await batchCreatePWD_api(formData);
          if (res.code === 200000) {
            let project_snow_id =
              res.data["cell_1"].split("/")[
                res.data["cell_1"].split("/").length - 3
              ];
            let file_snow_id =
              res.data["cell_1"].split("/")[
                res.data["cell_1"].split("/").length - 2
              ];
            this.createButtonVisible = false;
            this.projectData = {
              name: proData.name,
              snow_id: project_snow_id,
            };
            this.fileData = {
              name: fileName,
              snow_id: file_snow_id,
            };
            this.noData = false;
            this.treeVisible = true;
            this.projectRadio = "1";
            // this.userNode = this.$refs.tree.$children[0].node
            this.node = this.$refs.tree.getNode(proData.projectId);

            this.refreshTreeNode();
          }
        } catch (err) {
          this.isSplit = false;
          bus.$emit("ending", true);
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + err.code),
            true
          );
        }
      }
    });

    //另存为
    bus.$on("saveAs", (data) => {
      this.fileRadio = "3";
      this.saveAsDialogVisible = true;
      this.value_userName = this.userName;
      this.projectRadio = "1";
      this.team_gns = "";
      this.teamName = "";
      this.project_gns = "";
      this.file_gns = "";
      this.value_teamName = "";
      this.newProjectRuleForm.form_projectName = "";
      this.newProjectRuleForm.form_fileName = "";
      this.newProjectRuleForm.form_schematicName = "";
    });

    //保存原理图
    bus.$on("saveSchema", (data) => {
      // this.saveSchemaFile()
      let saveFile = this.getSchFileFromTable(
        this.$store.state.fileInfo.fileId
      );
      let saveSch = this.getSchemaFromSchFile(
        saveFile,
        this.$store.state.cellInfo.cellId
      );
      this.saveSchema(saveSch, saveFile);
    });

    //接收创建封装符号
    bus.$on("newCapSymbolFn", (data) => {
      bus.$emit("loading", true);
      this.newCapSymbolFn(data[0], data[1]);
    });

    //更新文件列表
    bus.$on("refreshFileNode", (data) => {
      let fileId = data.split("/")[data.split("/").length - 1];
      this.$refs.tree.getNode(fileId).loaded = false;
      this.$refs.tree.getNode(fileId).expand();
    });

    bus.$on("updateSchemaFileTree", async (data) => {
      await this.updateSchemaFileTree();
    });
  },
  beforeDestroy() {
    bus.$off("openNewDialog");
    bus.$off("saveFile");
    bus.$off("errDialog");
    bus.$off("saveFileSuccessToFile");
    bus.$off("importDialog");
    bus.$off("resetImportDialog");
    bus.$off("updateFileTree");
    bus.$off("updateSchemaFileTree");
    bus.$off("labelInfo");
    bus.$off("textGraphicsInfo");
    bus.$off("keyPointInfo");
    bus.$off("saveAsCellFn");
    bus.$off("getBoxData");
    bus.$off("getPrecision");
    bus.$off("deviceCall");
    bus.$off("closeAndUnsaved");
    bus.$off("syncCellList");
    bus.$off("syncLayerList");
    bus.$off("splitCell");
    bus.$off("newCapSymbolFn");
    bus.$off("refreshFileNode");
  },
  computed: {
    //激活的页面
    activeTab() {
      return this.$store.state.activeTab;
    },
  },
  watch: {
    //监听激活页面
    activeTab(val) {
      let id = val.route.split("=")[1];

      if (
        this.$store.state.cellInfo.cell_type == "1" ||
        this.$store.state.cellInfo.cell_type == "2"
      ) {
        bus.$emit("getFileId", id);
      }
    },

    value_userName(val) {
      this.newDialogBtnActive();
      this.saveAsDialogBtnActive();
    },
    value_teamName(val) {
      this.newDialogBtnActive();
      this.saveAsDialogBtnActive();
    },
    "newProjectRuleForm.form_projectName"(val) {
      this.newDialogBtnActive();
      this.saveAsDialogBtnActive();
    },
    "newProjectRuleForm.form_fileName"(val) {
      this.newDialogBtnActive();
      this.saveAsDialogBtnActive();
    },
    "newProjectRuleForm.form_cellName"(val) {
      this.newDialogBtnActive();
    },
    "newProjectRuleForm.form_schematicName"(val) {
      this.newDialogBtnActive();
      this.saveAsDialogBtnActive();
    },
    value_group(val) {
      this.saveToLibraryBtnActive();
    },
    "saveToLibraryForm.form_deviceLabel"(val) {
      this.saveToLibraryBtnActive();
    },
    value_notes(val) {
      this.saveToLibraryBtnActive();
    },
    "saveToLibraryForm.form_deviceName"(val) {
      this.saveToLibraryBtnActive();
    },
    "copyForm.form_copyName"(val) {
      this.copyDialogBtnActive();
    },
    "copyForm.treeNodeInfo"(val) {
      this.copyDialogBtnActive();
    },
  },
};
</script>

<style lang="less" scoped>
.file {
  width: 98%;
  height: 100%;
  // min-height: 205px;
  display: flex;
  position: relative;
  // overflow-y: hidden;
  flex-direction: column;
  // background-color: #e9e9e9;
  overflow-y: scroll;

  // 布局
  > div {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    // height: 50%;
  }
  // 上半部分
  .top-half {
    position: relative;
    overflow-y: auto;
    flex: 1;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    /deep/.el-tree__empty-text {
      color: #fff;
    }
  }

  //搜索框
  .searchAll {
    padding: 20px 10px 10px;
    /deep/.el-input__inner {
      width: 196px;
      height: 26px;
      line-height: 26px;
      background: #fff !important;
    }
    /deep/.el-input__inner::placeholder {
      color: #999999 !important;
    }
    /deep/.el-input__icon {
      line-height: 26px;
    }
  }

  //新建按钮
  /deep/.el-button--primary.is-plain {
    position: absolute;
    top: 70px;
    left: 55px;
    width: 110px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    background-color: #ecf5ff;
    border: 1px solid #0c7de6;
    color: #0c7de6;
    cursor: pointer;
    // z-index: 1000;
    span {
      display: inline-block;
      line-height: 28px;
    }
  }
  /deep/.el-button--primary.is-plain:hover,
  /deep/.el-button--primary.is-plain:focus {
    background: #ecf5ff;
    color: #409eff;
    cursor: pointer;
  }
}
//新建弹窗
.newProjectDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 480px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .userSelect {
      /deep/.el-input__inner {
        color: #c0c4cc !important;
      }
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .projectNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .fileNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
    }
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//删除弹窗
.deleteWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 249px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 90px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  .deleteName {
    padding-left: 32px;
    color: #0c7de6;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//存到器件库弹窗
.saveToLibraryDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 347px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 20px 20px 8px 20px;
    font-weight: 400;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 566px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 566px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
  }
  .labelSelect {
    position: relative;
    width: 100%;
    min-height: 30px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 566px;
      border-radius: 2px;
      .el-input__inner {
        height: 30px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .el-select__tags {
        .el-tag {
          background-color: #ffffff;
          color: #333;
        }
      }
    }
  }
  .textAreaSelect {
    position: relative;
    width: 100%;
    min-height: 30px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-textarea {
      width: 566px;
      .el-textarea__inner {
        background-color: #f4f4f4;
      }
      .el-input__count {
        background-color: #f4f4f4;
      }
    }
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//复制到
.copyDialog {
  color: #333333;
  /deep/.el-dialog {
    width: 686px;
    min-height: 261px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__header {
    height: 41px;
    width: 100%;
    padding: 0;
    background-color: #f4f4f4;
  }
  /deep/.el-dialog__title {
    padding: 14px 13px 12px 13px;
    display: inline-block;
    line-height: 14px;
  }
  /deep/.el-dialog__headerbtn {
    top: 0;
    right: 0;
  }
  /deep/.el-dialog__close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.el-dialog__close::before {
    width: 16px;
    height: 16px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 40px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .nameIcon {
      position: absolute;
      top: 24px;
      right: 13px;
      z-index: 1000;
      transition: transform 0.3s;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//树形列表
.tree-line {
  font-size: 14px;
  i {
    padding-right: 5px;
  }
  /deep/.el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  /deep/.el-tree-node__children {
    padding-left: 15px; // 缩进量
  }
  /deep/.el-tree-node > .el-tree-node__children {
    overflow: visible;
  }

  // 竖线
  /deep/.el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -10px;
    top: -12px;
    border-width: 1px;
    border-left: 1px dashed #dddddd;
  }
  // 当前层最后一个节点的竖线高度固定
  /deep/.el-tree-node:last-child::before {
    height: 30px; // 可以自己调节到合适数值
  }

  // 横线
  /deep/.el-tree-node::after {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    left: -8px;
    top: 17px;
    border-width: 1px;
    border-top: 1px dashed #dddddd;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  /deep/& > .el-tree-node::after {
    border-top: none;
  }
  /deep/& > .el-tree-node::before {
    border-left: none;
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 35px;
    line-height: 35px;
    width: 100%;
    span {
      display: inline-block;
      width: 100%;
    }
  }
  .treeIcon {
    padding-right: 4px;
    position: relative;
    width: 22px;
    height: 20px;
    left: -80px;
    filter: drop-shadow(#0c7de6 80px 0);
    color: #0c7de6;
  }

  // 展开关闭的icon
  /deep/.el-tree-node__expand-icon {
    font-size: 16px;
    // 叶子节点（无子节点）
    /deep/&.is-leaf {
      color: transparent;
      // display: none; // 也可以去掉
    }
  }
  /deep/.el-tree-node__content > .el-tree-node__expand-icon {
    padding: 0 3px 0 0px;
  }
  /deep/.el-tree-node__content {
    height: 35px;
  }

  //更换图标
  /deep/.el-icon-caret-right:before {
    content: "";
    background: url("../../../assets/zhankai.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  /deep/.el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "";
    background: url("../../../assets/zhankai_1.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  //叶子结点样式
  /deep/.el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
    background: transparent;
    width: 0px;
  }
  //禁止转向
  /deep/.el-tree-node__expand-icon {
    transform: none;
  }
  /deep/.el-tree-node__expand-icon.expanded {
    transform: none;
  }
}

//重命名
.resetNameInput {
  font-size: 14px;
  height: 27px;
  line-height: 27px;
  width: 137px;
  /deep/.el-input__inner {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background: #ffffff !important;
    border: 1px solid #0c7de6 !important;
    border-radius: 2px;
  }
}

//错误弹窗
.errWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 132px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//导入GDS
.importDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 376px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .userSelect {
      /deep/.el-input__inner {
        color: #c0c4cc !important;
      }
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .projectNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .fileNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
    }
  }
  .uploadArea {
    width: 100%;
    min-height: 60px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    .upload-demo {
      width: 100%;
      /deep/.el-upload-list__item-name {
        margin-left: 120px;
      }
    }
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//另存为
.saveAsDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 480px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .userSelect {
      /deep/.el-input__inner {
        color: #c0c4cc !important;
      }
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .projectNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .fileNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
    }
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

.createButton {
  z-index: 50;
}

//遮罩
.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
//输入校验
/deep/.el-form-item.is-error .el-input__inner {
  border-color: #ff5b5b !important;
  background-color: #ffeded !important;
}
//禁止输入
/deep/.el-input.is-disabled .el-input__inner {
  color: #c0c4cc !important;
}
</style>
