<template>
  <div>
    <div class="log-label">
      <div @click="setIndex(k)" :class="{ active: selectIndex == k, 'log-label__item': true }" v-for="(v, k) in logLabels" :key="k">{{ v }}</div>
    </div>
    <div style="font-size: 12px" class="log-body" v-if="selectIndex == 0">
      <div class="log-body__tree">
        <el-tree :data="logValue" :props="defaultProps" @node-click="handleNodeClick" :highlight-current="true"></el-tree>
      </div>
    </div>
    <div style="font-size: 12px" class="log-body" v-else>
      <div class="log-body__item" v-for="k in Object.keys(logValue)" :key="k">
        <div class="item-label">{{ k }}</div>
        <div class="item-value">{{ logValue[k] }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import bus from '@/components/common/bus'
import i18n from '../../common/lang/i18n'
export default {
  data() {
    return {
      logLabels: ['CheckLine', 'WBPad', 'InLinePad', 'AirBridge', 'TiNPad&Inpillar'],
      logValue: null,
      selectIndex: 0,
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      AirBridgeLog: { numbers: 0 },
      CheckLineLog: [],
      WBPadLog: { numbers: 0 },
      InLinePadLog: { numbers: 0 },
      TiNPadInPillarLog: {
        inpillar_number: 0,
        inpillar_radius: 0,
        inpillar_shape: '',
        inpillar_total_area: 0,
        roi_area: 0,
        account: 0,
      },
    }
  },
  mounted() {
    bus.$on('TOOL_CHECK_LINE_LOG', data => {
      this.CheckLineLog = data
      this.setIndex(0)
    })
    bus.$on('TOOL_WBPAD_LOG', data => {
      this.WBPadLog.numbers = data.numbers
      this.setIndex(1)
    })
    bus.$on('TOOL_INLINEPAD_LOG', data => {
      this.InLinePadLog.numbers = data.numbers
      this.setIndex(2)
    })
    bus.$on('TOOL_AIRBRIDGE_LOG', data => {
      this.AirBridgeLog.numbers = data.numbers
      this.setIndex(3)
    })
    bus.$on('TOOL_TINPAD_INPILLAR_LOG', data => {
      this.TiNPadInPillarLog = data
      this.setIndex(4)
    })
  },
  methods: {
    handleNodeClick() {},
    setIndex(k) {
      this.selectIndex = k
      this.logValue = this.getLogData(this.logLabels[k])
    },
    getLogData(name) {
      let ob = {}
      switch (name) {
        case 'CheckLine':
          ob = []
          this.CheckLineLog.forEach(element => {
            let err_points = element.err.points//NOSONAR
            let err_paths = element.err.paths
            let children_points = []//NOSONAR
            let children_paths = []
            err_paths.forEach(path => {
              children_paths.push({
                label: `path:${JSON.stringify(path)}`,
              })
            })
            ob.push({
              label: `Layer:${element.layer}`,
              children: [
                {
                  label: 'err_paths',
                  children: children_paths,
                },
              ],
            })
          })
          break
        case 'WBPad':
          ob = JSON.parse(`{"${i18n.t('messages.toolLogWBPadCount')}": "${this.WBPadLog.numbers}"}`)
          break
        case 'InLinePad':
          ob = JSON.parse(`{"${i18n.t('messages.toolLogInlinepadCount')}": "${this.InLinePadLog.numbers}"}`)
          break
        case 'AirBridge':
          ob = JSON.parse(`{"${i18n.t('messages.toolLogAirBridgeCount')}": "${this.AirBridgeLog.numbers}"}`)
          break
        case 'TiNPad&Inpillar':
          ob = JSON.parse(`{
            "${i18n.t('messages.toolLogInpillarCount')}": "${this.TiNPadInPillarLog.inpillar_number}",
            "${i18n.t('messages.toolLogInpillarRadius')}": "${this.TiNPadInPillarLog.inpillar_radius.toFixed(2)}",
            "${i18n.t('messages.toolLogInpillarShape')}": "${this.TiNPadInPillarLog.inpillar_shape}",
            "${i18n.t('messages.toolLogInpillarAreaTotal')}": "${this.TiNPadInPillarLog.inpillar_total_area.toFixed(4)}",
            "${i18n.t('messages.toolLogROIAreaTotal')}": "${this.TiNPadInPillarLog.roi_area.toFixed(4)}",
            "${i18n.t('messages.toolLogInpillarProp')}": "≈${this.TiNPadInPillarLog.account.toFixed(4)}%" }`)
          break
      }
      return ob
    },
  },
}
</script>
<style lang="less" scoped>
.log-label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #333;
  .log-label__item {
    cursor: pointer;
    padding: 10px;
    width: auto;
    border: 1px solid #dddddd;
  }
  .active {
    background: #0c7de6;
    color: #fff;
  }
}
.log-body {
  .log-body__tree {
    margin: 10px;
  }
  .log-body__item {
    width: 100%;
    display: flex;
    .item-label {
      min-width: 130px;
      display: inline-block;
      padding: 10px 0;
      border: 1px solid #dddddd;
      text-indent: 10px;
    }
    .item-value {
      min-width: calc(100% - 133px);
      display: inline-block;
      padding: 10px 0;
      border: 1px solid #dddddd;
      text-indent: 10px;
    }
  }
}
</style>
