<template>
  <div :class="{ scale: true, 'de-scale': !showFiles }" onselectstart="javascript:return false;">
    <div class="cardHeader">
      <!-- 头部标题 -->
      <span>{{$t('messages.fileList')}}</span>
    </div>
    <!-- 折叠面板主体 -->
    <el-collapse-transition v-if="noFiles" :key="box1">
      <el-tabs type="border-card" v-if="cellBarSeen">
        <div class="tab-content">{{$t('messages.noFiles')}}</div>
      </el-tabs>
    </el-collapse-transition>
    <el-collapse-transition v-else :key="box2">
      <el-tabs type="border-card" v-if="cellBarSeen">
        <!-- <Files v-show="showFiles" /> -->
        <FileList v-show="showFiles" />
      </el-tabs>
    </el-collapse-transition>
  </div>
</template>
<script>
import Files from "@/components/homes/fileList/Files.vue";
import FileList from "@/components/homes/fileList/FileList.vue";
export default {
  components: {
    Files,
    FileList,
  },
  data() {
    return {
      cellBarSeen: true,
      showFiles: true,
      showDevice: false,
      noFiles: false,
      box1: "",
      box2: "",
    };
  },
  methods: {
    setCellBar() {
      this.cellBarSeen = !this.cellBarSeen;
    },
  },
  created() {
    this.$bus.$on("openList", (data) => {
      this.noFiles = data;
    });
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.cardHeader {
  height: 34px;
  line-height: 34px;
  width: 100%;
  background: #f4f4f4;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  cursor: move;
  span {
    height: 100%;
    display: inline-block;
    margin-left: 20px;
  }
  .el-icon-plus:before,
  .el-icon-minus:before {
    height: 100%;
    display: inline-block;
    line-height: 40px;
    margin-right: 20px;
  }
}
.scale {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.cellBar {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/deep/ .el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
  flex: 1;
  overflow: visible;
  .tab-content {
    font-size: 12px;
    width: 100%;
    /*     display: flex;
    align-items: center;
    justify-content: center; */
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
  }
}
/deep/ .el-tabs--border-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  border: none;
}
/deep/.el-tabs--border-card > .el-tabs__header {
  border-bottom: none;
}
/* // 器件库单独生效
.de-scale {
  /deep/ .el-tabs--border-card > .el-tabs__content {
    overflow: hidden !important;
  }
  /deep/ .el-tabs--border-card {
    display: flex;
    background-color: rgb(238, 238, 238);
    flex-direction: column;
    overflow: hidden !important;
  }
}  */
</style>