<template>
  <div class="copyDialog">
    <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyDialogVisible" :modal="false" :close-on-click-modal="false" :before-close="copyDialogClose">
      <el-form :model="copyForm" :rules="rules" ref="copyForm" class="demo-ruleForm">
        <div class="inputArea">
          <span class="inputInfo">{{$t('messages.belong')}}:</span>
          <el-form-item prop="treeNodeInfo">
            <el-select v-model="copyForm.treeNodeInfo" :placeholder="$t('messages.placeholder_0')" @focus="scrollToTopOption">
              <el-option :value="copyForm.treeNodeInfo" id="copyTreeOption">
                <el-tree class="tree-line" :indent="0" node-key="snow_id" ref="tree1" highlight-current :props="props" @node-click="handleNodeClick" :load="copyLoadNode" lazy v-if="copyTreeRefresh===1">
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <img class="treeIcon" :src="treeNodeIcon(node)">
                    <span>{{ node.label }}</span>
                  </span>
                </el-tree>
                <el-tree class="tree-line" :indent="0" node-key="snow_id" ref="tree2" highlight-current :props="props" @node-click="handleNodeClick2" :load="copyLoadNode" lazy v-else-if="copyTreeRefresh===2">
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <img class="treeIcon" :src="treeNodeIcon(node)">
                    <span>{{ node.label }}</span>
                  </span>
                </el-tree>
                <el-tree class="tree-line" :indent="0" node-key="snow_id" ref="tree3" highlight-current :props="props" @node-click="handleNodeClick3" :load="copyLoadNode" lazy v-else-if="copyTreeRefresh===3">
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <img class="treeIcon" :src="treeNodeIcon(node)">
                    <span>{{ node.label }}</span>
                  </span>
                </el-tree>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="inputArea">
          <span class="inputInfo">{{$t('messages.name')}}:</span>
          <el-form-item prop="form_copyName">
            <el-input v-model="copyForm.form_copyName" :placeholder="$t('messages.placeholder_1')"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">

        <el-button type="primary" @click="copyDialogSureBtn" class="deactivate" ref="copyDialogSureBtn" disabled size="mini">{{$t("messages.sure")}}</el-button>
        <el-button @click="copyDialogCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import {
  getUserProjectList,
  getTeamProjectList,
  getFileList,
  normalFileCopyTo,
  batchGetPermission,
} from "./function/fileListApiFn.js";
import {
  disableBtn,
  activateBtn,
  UserProjectNode,
  TeamProjectNode,
  UserFileNode,
  TeamFileNode,
  // setDataToProjectTable,
  setDataToCellTable,
  getCellFromTable,
  projectCopyTo,
  fileCopyTo,
  cellCopyTo,
} from "./function/fileListPublicFn.js";
import {
  // getCompleteProjectData,
  getObjectData,
} from "./function/objectStorageFn";
export default {
  data() {
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    return {
      copyDialogVisible: false,
      node: {},
      props: {
        label: "name",
        children: "zones",
        isLeaf: "isLeaf",
      },
      copyForm: {
        treeNodeInfo: "",
        form_copyName: "",
        node_data: null,
      },
      copyTreeRefresh: 0,
      isCopyFile: false, //是否是复制文件
      copyFromData: null,
      userNode: {},
      userId: "",
      teamNode: {},
      teamId: "",
      fileNode: {},
      fileData: {},
      file_snow_id: "",
      original_project_gns: "",
      target_project_gns: "",
      copy_type: "",
      copy_level: "", //复制操作的层级
      copyFile_url: "",
      copyToFile_url: "",

      rules: {
        form_copyName: [{ validator: fileNameRule, trigger: "blur" }],
      },

      treeNameList: [],
    };
  },
  created() { },
  mounted() {
    bus.$on("contextMenuCopy", (data) => {
      // { copy_type: this.copy_type, copy_data: this.copy_data }
      this.copyTreeRefresh = data.copy_type;

      this.copyDialogVisible = true;
      this.treeNameList = this.$store.state.treeNameList;
      this.copyFromData = data.copy_data.data;
      this.copyFromNode = data.copy_data;
    });
    // bus.$on("transmitCopyToData", data => {
    //   this.copyFromData = data;
    // })
  },
  methods: {
    copyDialogBtnActive() {
      if (
        this.copyForm.form_copyName !== "" &&
        this.copyForm.treeNodeInfo !== ""
      ) {
        activateBtn(this.$refs.copyDialogSureBtn);
      } else {
        disableBtn(this.$refs.copyDialogSureBtn);
      }
    },

    //复制到关闭按钮
    copyDialogClose() {
      this.copyTreeRefresh = 0;
      this.isCopyFile = false;
      this.$refs.copyForm.resetFields();
      this.copyDialogVisible = false;
      this.treeNameList.forEach((item) => {
        item.isLeaf = false;
      });
    },

    //选项跳转到顶部
    scrollToTopOption() { },

    //项目复制到选择复制到位置
    handleNodeClick(data, node) {
      if (node.level === 1) {
        this.userNode = node;
        this.copyForm.treeNodeInfo = node.data.name;
        this.copyForm.node_data = node;
      }
    },

    //文件复制到选择复制到位置
    handleNodeClick2(data, node) {
      // if (node.level === 1 && node.data.isTeam === true) {
      //   // if (node.data.role != "所有者" || node.data.role != "管理员") {
      //   //   this.copyForm.treeNodeInfo = "";
      //   //   bus.$emit('errDialog', this.$t('messages.userProjectPermission'))
      //   // }
      // }
      if (node.level === 2) {
        this.copy_level = "2";
        if (node.data.isTeam) {
          if (node.data.teamRule === "可删除、编辑") {
            this.target_project_gns = node.data.gns;
            this.projectData = node.data;
            this.proNode = node;
            this.copy_type = "team";
            let path = [node.parent.data.name, node.data.name].join("/");
            this.copyForm.treeNodeInfo = path;
            this.copyForm.node_data = node;
          } else {
            this.copyForm.treeNodeInfo = "";
            this.copyForm.node_data = null;
            bus.$emit("errDialog", this.$t("messages.userProjectPermission"));
          }
        } else {
          this.target_project_gns = node.data.gns;
          this.projectData = node.data;
          this.proNode = node;
          this.copy_type = "user";
          let path = [node.parent.data.name, node.data.name].join("/");
          this.copyForm.treeNodeInfo = path;
          this.copyForm.node_data = node;
        }
      } else {
        this.copyForm.treeNodeInfo = "";
        this.copyForm.node_data = null;
      }
    },

    //单元复制到选择复制到位置
    handleNodeClick3(data, node) {
      // if (node.level === 1 && node.data.isTeam === true) {
      //   // if (node.data.role != "所有者" || node.data.role != "管理员") {

      //   //   node.loaded.false
      //   //   this.copyForm.treeNodeInfo = "";
      //   //   this.copyForm.node_data = null;
      //   //   bus.$emit('errDialog', this.$t('messages.userProjectPermission'))
      //   // }
      // } else if (node.level === 2 && node.data.isTeam === true) {
      //   // if (node.data.teamRule !== "可删除、编辑") {
      //   //   this.copyForm.treeNodeInfo = "";
      //   //   this.copyForm.node_data = null;
      //   //   bus.$emit('errDialog', this.$t('messages.userProjectPermission'))
      //   // }
      // } else
      if (node.level == "3") {
        this.copy_level = "3";

        if (node.data.isTeam) {
          if (node.data.teamRule !== "可删除、编辑") {
            this.copyForm.treeNodeInfo = "";
            this.copyForm.node_data = null;
            bus.$emit("errDialog", this.$t("messages.userFilePermission"));
          } else {
            this.projectData = node.parent.data;
            this.proNode = node.parent;
            this.fileNode = node;
            this.fileData = node.data;
            this.copy_type = "team";
            let path = [
              node.parent.parent.data.name,
              node.parent.data.name,
              node.data.name,
            ].join("/");
            this.copyForm.treeNodeInfo = path;
            this.copyForm.node_data = node;
            this.copyForm.copyFromNode = this.copyFromNode;
          }
        } else {
          this.copy_type = "user";
          this.projectData = node.parent.data;
          this.proNode = node.parent;
          this.fileNode = node;
          this.fileData = node.data;
          let path = [
            node.parent.parent.data.name,
            node.parent.data.name,
            node.data.name,
          ].join("/");
          this.copyForm.treeNodeInfo = path;
          this.copyForm.node_data = node;
          this.copyForm.copyFromNode = this.copyFromNode;
        }
      } else {
        this.copyForm.treeNodeInfo = "";
        this.copyForm.node_data = null;
      }
    },

    //节点加载
    async copyLoadNode(node, resolve) {
      if (node.level === 0) {
        let data = [];
        data = this.topNodeRender_normal();

        if (this.copyTreeRefresh === 1) {
          data.forEach((item) => {
            item.isLeaf = true;
          });
        }

        return resolve(data);
      } else if (node.level === 1 && node.data.isTeam == false) {
        if (this.copyTreeRefresh > 1) {
          let data = [];
          data = await this.projectNodeRender_normal_user(node);
          if (this.copyTreeRefresh === 2) {
            data.forEach((item) => {
              item.isLeaf = true;
            });
          }
          return resolve(data);
        } else {
          return resolve([]);
        }
      } else if (node.level === 1 && node.data.isTeam == true) {
        if (this.copyTreeRefresh > 1) {
          let data = [];
          data = await this.projectNodeRender_normal_team(node);
          if (this.copyTreeRefresh === 2) {
            data.forEach((item) => {
              item.isLeaf = true;
            });
          }
          return resolve(data);
        } else {
          return resolve([]);
        }
      } else if (node.level === 2 && node.data.isTeam == false) {
        if (this.copyTreeRefresh > 2) {
          let data = [];
          data = await this.fileNodeRender_user(node);
          data.forEach((item) => {
            item.isLeaf = true;
          });
          return resolve(data);
        } else {
          return resolve([]);
        }
      } else if (node.level === 2 && node.data.isTeam == true) {
        if (this.copyTreeRefresh > 2) {
          let data = [];
          data = await this.fileNodeRender_team(node);
          data.forEach((item) => {
            item.isLeaf = true;
          });
          return resolve(data);
        } else {
          return resolve([]);
        }
      } else {
        return resolve([]);
      }
    },

    // 顶层节点渲染
    topNodeRender_normal() {
      let data = [];
      this.treeNameList.forEach((item) => {
        data.push(item);
      });
      return data;
    },

    //个人项目层节点渲染
    async projectNodeRender_normal_user() {
      let data = [];
      let res = await getUserProjectList(0, 50);
      if (res.code === 200000) {
        res.data.data.forEach((item) => {
          data.push(
            new UserProjectNode(
              item._source.name,
              item._source.snow_id,
              item._source.gns,
              item._source.category,
              item._source.category_child
            )
          );
        });
        return data;
      }
    },

    //团队项目层节点渲染
    async projectNodeRender_normal_team(node) {
      let data = [];
      let res = await getTeamProjectList(
        0,
        50,
        node.data.snow_id,
        node.data.gns
      );
      if (res.code === 200000) {
        let projectGnsList = [];
        for (let i = 0; i < res.data.data.length; i++) {
          projectGnsList.push(res.data.data[i]._source.gns);
        }
        let permissionRes = await batchGetPermission({
          project_gns_list: projectGnsList,
          snow_team_id: node.data.snow_id,
        });
        if (permissionRes.code === 200000) {
          res.data.data.forEach((item) => {
            data.push(
              new TeamProjectNode(
                item._source.name,
                item._source.snow_id,
                item._source.gns,
                item._source.category,
                item._source.category_child
              )
            );
          });

          let realData = [];
          data.forEach((item) => {
            permissionRes.data.forEach((value) => {
              if (item.gns == value.gns) {
                if (value.permission != "不可见") {
                  item.teamRule = value.permission;
                  realData.push(item);
                }
              }
            });
          });

          return realData;
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + permissionRes.code),
            true
          );
          return [];
        }
      }
    },

    //个人文件层节点渲染
    async fileNodeRender_user(node) {
      let res = await getFileList(0, 200, node.data.gns, "个人文件");
      if (res.code === 200000) {
        let data = [];
        let fileData = [];
        if (
          this.copyFromData.cell_type === 3 ||
          this.copyFromData.cell_type === 4 ||
          this.copyFromData.cell_type === 5
        ) {
          fileData = res.data.data.filter((item) => item._source.types === 3);
        } else if (
          this.copyFromData.cell_type === 1 ||
          this.copyFromData.cell_type === 2
        ) {
          fileData = res.data.data.filter((item) => item._source.types === 1);
        }
        fileData.forEach((item) => {
          data.push(
            new UserFileNode(
              item._source.name,
              item._source.snow_id,
              item._source.gns,
              item._source.category,
              item._source.category_child,
              item._source.types
            )
          );
        });
        return data;
      }
    },

    //团队文件层节点渲染
    async fileNodeRender_team(node) {
      let data = [];
      let res = await getFileList(0, 200, node.data.gns, "团队文件");
      if (res.code === 200000) {
        let fileGnsList = [];
        for (let i = 0; i < res.data.data.length; i++) {
          fileGnsList.push(res.data.data[i]._source.gns);
        }
        let permissionRes = await batchGetPermission({
          project_gns_list: fileGnsList,
          snow_team_id: node.parent.data.snow_id,
        });
        if (permissionRes.code === 200000) {
          let fileData = [];
          if (
            this.copyFromData.cell_type === 3 ||
            this.copyFromData.cell_type === 4 ||
            this.copyFromData.cell_type === 5
          ) {
            fileData = res.data.data.filter((item) => item._source.types === 3);
          } else if (
            this.copyFromData.cell_type === 1 ||
            this.copyFromData.cell_type === 2
          ) {
            fileData = res.data.data.filter((item) => item._source.types === 1);
          }
          fileData.forEach((item) => {
            data.push(
              new TeamFileNode(
                item._source.name,
                item._source.snow_id,
                item._source.gns,
                item._source.category,
                item._source.category_child,
                item._source.types
              )
            );
          });
          let realData = [];
          data.forEach((item) => {
            permissionRes.data.forEach((value) => {
              if (item.gns == value.gns) {
                if (value.permission != "不可见") {
                  item.teamRule = value.permission;
                  realData.push(item);
                }
              }
            });
          });

          return realData;
        } else {
          this.errDialog(
            this.$t("messages.wrong"),
            this.$t("messages.code_" + permissionRes.code),
            true
          );
          return [];
        }
      }
    },

    //复制到确认按钮
    async copyDialogSureBtn() {
      this.copyDialogVisible = false;
      let all_data = {
        project_list: [],
        file_list: [],
        cell_list: [],
        snow_team_id: "",
        accessor_type: "",
      };
      let topData = {};
      let projectData = {};
      let fileData = {};
      bus.$emit("screen_lock", true);
      if (this.copyFromNode.data.category == "项目") {
        let success = await projectCopyTo(this.copyFromNode, this.copyForm);
        if (success) {
          topData = {
            topId: this.copyForm.node_data.data.snow_id,
            isTeam: this.copyForm.node_data.data.isTeam,
          };
        } else {
          this.copyDialogCancelButton();
          bus.$emit("screen_lock", false);
          return;
        }
      } else if (this.copyFromNode.data.category == "文件") {
        // 文件结构对应Library
        let success = await fileCopyTo(this.copyFromNode, this.copyForm);
        if (success) {
          topData = {
            topId: this.copyForm.node_data.parent.data.snow_id,
            isTeam: this.copyForm.node_data.parent.data.isTeam,
          };
          projectData = {
            name: this.copyForm.node_data.data.name,
            snow_id: this.copyForm.node_data.data.snow_id,
            gns: this.copyForm.node_data.data.gns,
            isTeam: this.copyForm.node_data.data.isTeam,
          };
        } else {
          this.copyDialogCancelButton();
          bus.$emit("screen_lock", false);
          return;
        }
      } else {
        let success = await cellCopyTo(this.copyFromNode, this.copyForm);
        if (success) {
          topData = {
            topId: this.copyForm.node_data.parent.parent.data.snow_id,
            isTeam: this.copyForm.node_data.parent.parent.data.isTeam,
          };
          projectData = {
            name: this.copyForm.node_data.parent.data.name,
            snow_id: this.copyForm.node_data.parent.data.snow_id,
            gns: this.copyForm.node_data.parent.data.gns,
            isTeam: this.copyForm.node_data.parent.data.isTeam,
          };
          fileData = {
            name: this.copyForm.node_data.data.name,
            snow_id: this.copyForm.node_data.data.snow_id,
            gns: this.copyForm.node_data.data.gns,
            isTeam: this.copyForm.node_data.data.isTeam,
          };

        } else {
          this.copyDialogCancelButton();
          bus.$emit("screen_lock", false);
          return;
        }
      }

      this.copyDialogCancelButton();
      // 刷新Node树
      let expandData = [
        {
          category: this.copyFromNode.data.category,
          topData,
          projectData,
          fileData,
        },
      ];

      bus.$emit("refreshNode", expandData);
      this.$message.success(this.$t("messages.operationSuccess"));
      bus.$emit("screen_lock", false);
    },

    //复制到取消按钮
    copyDialogCancelButton() {
      this.copyTreeRefresh = 0;
      this.isCopyFile = false;
      this.$refs.copyForm.resetFields();
      this.copyDialogVisible = false;
      this.treeNameList.forEach((item) => {
        item.isLeaf = false;
      });
    },

    //节点图标
    treeNodeIcon(node) {
      if (node.level == 1 && node.data.isTeam == false) {
        return require("@/assets/icons/file/个人用户名.svg");
      } else if (node.level === 1 && node.data.isTeam == true) {
        return require("@/assets/icons/file/团队名.svg");
      } else if (node.level === 2) {
        return require("@/assets/icons/file/项目名.svg");
      } else if (node.level === 3 && node.data.file_type === 1) {
        return require("@/assets/icons/file/版图文件.svg");
      } else if (node.level === 3 && node.data.file_type === 3) {
        return require("@/assets/icons/file/原理图文件名称.svg");
      } else if (node.level === 4 && node.data.cell_type === 1) {
        return require("@/assets/icons/file/版图名.svg");
      } else if (node.level === 4 && node.data.cell_type === 2) {
        return require("@/assets/icons/file/器件名.svg");
      } else if (node.level >= 4 && node.data.cell_type === 3) {
        return require("@/assets/icons/file/原理图.svg");
      } else if (
        node.level >= 4 &&
        (node.data.cell_type === 4 || node.data.cell_type === 5)
      ) {
        return require("@/assets/icons/file/打开符号.svg");
      } else if (node.level > 4 && !node.data.hasOwnProperty("cell_type")) {
        return require("@/assets/icons/file/器件名.svg");
      }
    },
  },
  watch: {
    "copyForm.form_copyName"(val) {
      this.copyDialogBtnActive();
    },
    "copyForm.treeNodeInfo"(val) {
      this.copyDialogBtnActive();
    },
  },
};
</script>

<style lang="less" scoped>
.copyDialog {
  color: #333333;
  /deep/.el-dialog {
    width: 686px;
    min-height: 261px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__header {
    height: 41px;
    width: 100%;
    padding: 0;
    background-color: #f4f4f4;
  }
  /deep/.el-dialog__title {
    padding: 14px 13px 12px 13px;
    display: inline-block;
    line-height: 14px;
  }
  /deep/.el-dialog__headerbtn {
    top: 0;
    right: 0;
  }
  /deep/.el-dialog__close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.el-dialog__close::before {
    width: 16px;
    height: 16px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 40px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .nameIcon {
      position: absolute;
      top: 24px;
      right: 13px;
      z-index: 1000;
      transition: transform 0.3s;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//树形列表
.tree-line {
  font-size: 14px;
  i {
    padding-right: 5px;
  }
  /deep/.el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  /deep/.el-tree-node__children {
    padding-left: 15px; // 缩进量
  }
  /deep/.el-tree-node > .el-tree-node__children {
    overflow: visible;
  }

  // 竖线
  /deep/.el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -10px;
    top: -12px;
    border-width: 1px;
    border-left: 1px dashed #dddddd;
  }
  // 当前层最后一个节点的竖线高度固定
  /deep/.el-tree-node:last-child::before {
    height: 30px; // 可以自己调节到合适数值
  }

  // 横线
  /deep/.el-tree-node::after {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    left: -8px;
    top: 17px;
    border-width: 1px;
    border-top: 1px dashed #dddddd;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  /deep/& > .el-tree-node::after {
    border-top: none;
  }
  /deep/& > .el-tree-node::before {
    border-left: none;
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 35px;
    line-height: 35px;
    width: 100%;
    span {
      display: inline-block;
      width: 100%;
    }
  }
  .treeIcon {
    padding-right: 4px;
    position: relative;
    width: 22px;
    height: 20px;
    left: -80px;
    filter: drop-shadow(#0c7de6 80px 0);
    color: #0c7de6;
  }

  // 展开关闭的icon
  /deep/.el-tree-node__expand-icon {
    font-size: 16px;
    // 叶子节点（无子节点）
    /deep/&.is-leaf {
      color: transparent;
      // display: none; // 也可以去掉
    }
  }
  /deep/.el-tree-node__content > .el-tree-node__expand-icon {
    padding: 0 3px 0 0px;
  }
  /deep/.el-tree-node__content {
    height: 35px;
  }

  //更换图标
  /deep/.el-icon-caret-right:before {
    content: "";
    background: url("../../../assets/zhankai.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  /deep/.el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "";
    background: url("../../../assets/zhankai_1.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  //叶子结点样式
  /deep/.el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
    background: transparent;
    width: 0px;
  }
  //禁止转向
  /deep/.el-tree-node__expand-icon {
    transform: none;
  }
  /deep/.el-tree-node__expand-icon.expanded {
    transform: none;
  }
}
</style>