<template>
  <div style="height:100%">
    <div class="file">
      <!-- <button @click="logState">发送消息</button> -->
      <!-- 新建按钮 -->
      <el-button v-if="createButtonVisible" :key="0" type="primary" plain class="createButton" @click="headerCreate"><i class="iconfont icon-xinjian"></i>{{$t('messages.new')}}</el-button>
      <!-- 树结构 -->
      <div class="top-half" ref="topHalf" style="max-height:850px">
        <!-- <el-tree v-if="treeVisible" :key="1" class="tree-line" :indent="0" node-key="snow_id" ref="tree" highlight-current :props="props" @node-contextmenu="fileListRightClick" @node-click="fileListNodeClick" :load="loadNode" lazy :expand-on-click-node="true"> -->
        <!-- 文件列表状态记录 -->
        <Tree v-if="treeVisible" :key="1" class="tree-line" :indent="0" node-key="snow_id" ref="tree" highlight-current :props="props" @node-contextmenu="fileListRightClick" @node-click="fileListNodeClick" :load="loadNode" lazy :expand-on-click-node="isResetName?false:true" @node-expand="nodeExpand" @node-collapse="nodeCollapse" :default-expanded-keys="expandedList">
          <!-- <Tree v-if="treeVisible" :key="1" class="tree-line" :indent="0" node-key="snow_id" ref="tree" highlight-current :props="props" @node-contextmenu="fileListRightClick" @node-click="fileListNodeClick" :load="loadNode" lazy :expand-on-click-node="false" @node-expand="nodeExpand" @node-collapse="nodeCollapse" :default-expanded-keys="expandedList"> -->
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <img class="treeIcon" :src="treeNodeIcon(node)">
            <span @dblclick="fileListDBlclick(data,node)" v-if="!isHomeWork && !node.data.showInput" @contextmenu.prevent="onContextMenu($event,node)">{{ node.label }}&nbsp;<span v-if="node.level==4 && node.data.cell_type=='5'">{{node.data.epschemaName}}</span></span>
            <el-input class="resetNameInput" ref="resetNameInput" v-if="node.data.showInput" v-model="node.data.name" @keyup.enter.native="resetNameEnter($event)" @blur="resetNameBlur(node)" @focus="resetNameFocus($event,node)" :autofocus="true"></el-input>
          </span>
        </Tree>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import router from "@/router";
import Tree from "./tree/index";
import { deepCopy } from "@/utils/utils";
import { addFileListMessageServe } from "../../../utils/message_serve/fileList-message.js";
import { getObjectDataFn } from "@/components/homes/fileList/function/objectStorageFn";
import {
  contrastCellDataByLayerId,
  saveNewLayerFn,
} from "../rightList/layerListFn.js";
import {
  getUserProjectList,
  getTeamList,
  getTeamProjectList,
  getFileList,
  getCellList,
  renameFileName,
  createNewCell,
  getPermission,
  normalCreateFileFn,
  setFileParams,
  selectLockCell,
  getUserConfig,
  deleteCell,
} from "./function/fileListApiFn.js";
import {
  getObjectData,
  getCurrentBoardFolderName,
  getFolderName,
  batchSaveDataFn,
  batchSaveDataFn_fileSize,
} from "./function/objectStorageFn";
import {
  getTeamListInfo,
  UserNode,
  UserProjectNode,
  TeamProjectNode,
  UserFileNode,
  TeamFileNode,
  UserLayoutCellNode,
  UserSchemaCellNode,
  TeamLayoutCellNode,
  TeamSchemaCellNode,
  setDataToCellTable,
  getCellFromTable,
  saveProjectFn,
  saveFileFn,
  saveCellFn,
  getCompleteCellData,
  getCellIndexData,
  binaryDataToUTF8,
  deepCopyRemaneCell,
  deepCopyRemaneSymbol,
  deepCopyRemaneCapSymbol,
  returnFileType,
  fileLayerListChange_CellListLayerChange,
  AssembleCellFn,
  isCircle,
  contrastCellDataByLayerNumber,
  permissionsJudgmentFn,
  isCellOpenedReadOnly,
  isCellOpened,
  importLayerList,
  isCellEmpty,
  getPDKFileFn,
  getCapSymbolIdByEncapsFrom,
  cellcalledBy,
  tranformToNewPeoject,
} from "./function/fileListPublicFn.js";
import {
  CreateCellOption,
  ReNameOption,
  DeleteOption,
  CopyOption,
  SaveToLibOption,
  InstanceOption,
  ReadOnlyOption,
  SaveToSymbolLibOption,
  SymbolReplaceOption,
} from "./function/contextMenu.js";
export default {
  components: {
    Tree,
  },
  data() {
    return {
      node: {}, //当前操作的节点
      createButtonVisible: false, //文件列表新建按钮显示
      treeVisible: false, //文件列表显示
      noFileListData: false, //文件列表没有任何数据
      props: {
        label: "name",
        children: "zones",
        isLeaf: "isLeaf",
      }, //树结构默认数据
      treeNameList: [], //顶层节点列表
      options_teamName: [], //团队信息列表
      userName: "",
      userId: "",
      isReadOnly: false,
      expandTopData: {}, //需要展开的顶层节点数据
      expandProjectData: {}, //需要展开的项目数据
      expandFileData: {}, //需要展开的文件数据
      expandCellData: {}, //需要展开的单元数据
      expandTopNode: {}, //需要展开的顶层节点
      expandProNode: {}, //需要展开的项目节点
      expandFileNode: {}, //需要展开的文件节点
      expandDataArr: [],
      refreshProjectNode: false, //重新展开项目节点
      refreshFileNode: false, //重新展开文件节点
      oldNodeName: "", //重命名-旧节点名
      isResetName: false, //是否是重命名状态
      boxData: null, //版图框选的数据
      timer: null, //防抖

      //文件列表状态记录
      expandedList: [], //存储上一次打开的状态
      isDelete: false,
      nodeCollapseOver: false, //列表记录删除结束

      //作业批改
      isHomeWork: false,
      homework_read_only: false,
      homework_gns: "",
      homework_name: "",
      homework_topId: null,
      homework_nodeType: null,

      //递归单元数据
      recFileCallArr: [],

      //加载时的部分状态判断
      isCreateNewFile: false,
      isWaitEs: false,

      //默认的检查表
      defaultTableData: [
        {
          type: this.$t("messages.net"),
          content: "网络是否连接到两个端口",
          level: this.$t("messages.Warning"),
          id: "net_1",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: "连接到网络上的引脚，网络和引脚名称不一致",
          level: this.$t("messages.Warning"),
          id: "net_2",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: "引脚不能悬空",
          level: this.$t("messages.Warning"),
          id: "net_3",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: "符号的端口未连接",
          level: this.$t("messages.Warning"),
          id: "symbol_1",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: "符号端口连接类型错误",
          level: this.$t("messages.Warning"),
          id: "symbol_2",
          selector: 3,
          children: [
            {
              // type: this.$t('messages.Symbol'),
              content: "总线端口未连接Cp1",
              // level: this.$t('messages.Warning'),
              // id: "symbol_2",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cp1端口未连接总线和谐振腔",
              // level: this.$t('messages.Warning'),
              // id: "symbol_3",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "谐振腔端口未连接Cp1和Cp2",
              // level: this.$t('messages.Warning'),
              // id: "symbol_4",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cp2端口未连接谐振腔和比特",
              // level: this.$t('messages.Warning'),
              // id: "symbol_5",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "比特端口（三端口）未连接Cp2、Cp3和Cz1",
              // level: this.$t('messages.Warning'),
              // id: "symbol_6",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "比特端口（四端口）未连接Cp2、Cp3、Cz1和Cb1",
              // level: this.$t('messages.Warning'),
              // id: "symbol_7",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cp3端口未连接比特和X线",
              // level: this.$t('messages.Warning'),
              // id: "symbol_8",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cb1端口未连接两个比特",
              // level: this.$t('messages.Warning'),
              // id: "symbol_9",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "X线端口未连接Cp3",
              // level: this.$t('messages.Warning'),
              // id: "symbol_10",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Cz1端口未连接比特和z线",
              // level: this.$t('messages.Warning'),
              // id: "symbol_11",
              // selector: 3,
            },
            {
              // type: this.$t('messages.Symbol'),
              content: "Z线端口未连接Cz1线",
              // level: this.$t('messages.Warning'),
              // id: "symbol_12",
              // selector: 3,
            },
          ],
        },
      ],
    };
  },
  async created() {
    //先获取用户数据
    this.userName = localStorage.getItem("userName");
    this.userId = localStorage.getItem("userId");
    //获取用户项目列表
    let userListRes = await getUserProjectList(0, 50);

    //获取团队列表
    let teamListRes = await getTeamList(0, 50);
    //得到文件列表顶层节点列表
    if (userListRes.code === 200000 && teamListRes.code === 200000) {
      if (userListRes.data.total !== 0 || teamListRes.data.data.length !== 0) {
        this.createButtonVisible = false;
        this.noFileListData = false;
        this.treeVisible = true;
        this.treeNameList.push(new UserNode(this.userName, "", "", "", ""));
        this.options_teamName.splice(0, this.options_teamName.length);
        this.options_teamName = getTeamListInfo(teamListRes.data.data);
        this.$store.commit("setTeamNameList", this.options_teamName);
        this.options_teamName.forEach((item) => {
          this.treeNameList.push(item);
        });
        this.$store.commit("setTreeNameList", this.treeNameList);
      } else {
        this.createButtonVisible = true;
        this.noFileListData = true;
        this.treeVisible = false;
      }
    } else {
      this.createButtonVisible = true;
      this.noFileListData = true;
      this.treeVisible = false;
    }
  },
  mounted() {
    //文件列表状态记录
    if (JSON.parse(localStorage.getItem("expandedList"))) {
      this.expandedList = JSON.parse(localStorage.getItem("expandedList"));
    }
    bus.$on("createButtonVisible", (data) => { });

    bus.$on("contextMenuReName", (data) => {
      this.node.data.showInput = true;
      this.isResetName = true;
      this.$nextTick(() => {
        this.$refs.resetNameInput.focus();
        this.$refs.resetNameInput.select();
      });
    });

    bus.$on("getNodeById", (id) => {
      let node = this.$refs.tree.getNode(id);
      this.$store.commit("setNodeById", node);
    });

    bus.$on("createCellRefreshTreeNode", (data) => {
      this.expandDataArr = data;
      this.expandTopData = data.topData;
      this.expandProjectData = data.projectData;
      this.expandFileData = data.fileData;
      this.expandCellData = data.cellData;
      setTimeout(() => {
        this.createNewCellRefreshTreeNodeFn();
      }, 1000);
    });

    bus.$on("saveFile", () => {
      let cellId = this.$store.state.cellInfo.cellId;
      let cellNode = this.$refs.tree.getNode(cellId);
      saveCellFn(cellId, cellNode.data.gns);
    });

    bus.$on("createNewFile", (data) => {
      this.isCreateNewFile = data;
    });

    bus.$on("deleteNode", (data) => {
      this.isDelete = true;
      let node = data.delNode;
      if (node.data.isTeam) {
        this.sendDeleteNodeMsg(node);
      }

      if (node.level < 4 && node.level > 1) {
        this.nodeCollapse(node.data, node);

        if (this.nodeCollapseOver) {
          this.$refs.tree.remove(node);
          bus.$emit("ending", true);
          this.nodeCollapseOver = false;
          this.isDelete = false;
        }
      } else {
        this.$refs.tree.remove(node);
        let refreshCellList = data.refreshCellList;
        for (let i = 0; i < refreshCellList.length; i++) {
          let cellNode = this.$refs.tree.getNode(refreshCellList[i].snow_id);
          if (cellNode.loaded) {
            this.isWaitEs = true;
            cellNode.loaded = false;
            cellNode.expand();
          }
        }
        this.isDelete = false;
        bus.$emit("ending", true);
      }
    });

    bus.$on("newCapSymbolFn", async (newCapSymbol) => {
      let schemaNode = this.$refs.tree.getNode(
        this.$store.state.cellInfo.cellId
      );
      let project_gns = schemaNode.parent.parent.data.gns;
      let project_name = schemaNode.parent.parent.data.name;
      let projectId = this.$store.state.proInfo.projectId;
      let fileId = this.$store.state.fileInfo.fileId;
      let file_gns = schemaNode.parent.data.gns;
      let file_name = schemaNode.parent.data.name;
      let newCapSymbolName = newCapSymbol.name;
      let accessor_id = schemaNode.data.isTeam
        ? schemaNode.parent.parent.parent.data.snow_id
        : this.userId;
      let accessor_type = schemaNode.data.isTeam ? 2 : 1;
      let batch_data = {};

      //保存原理图
      let currentSchemaGns = `${file_gns}/${newCapSymbol.schema.snow_id}`;
      await saveCellFn(newCapSymbol.schema.snow_id, currentSchemaGns);

      //修改index数据
      setTimeout(async () => {
        let cellIndexData = await getCellIndexData(fileId);
        cellIndexData.push({
          snow_id: newCapSymbol.snow_id,
          cells: [{ snow_id: newCapSymbol.schema.snow_id }],
        });

        //创建封装符号节点
        let res = await createNewCell(
          accessor_type,
          accessor_id,
          project_name,
          project_gns,
          projectId,
          file_name,
          3,
          file_gns,
          fileId,
          cellIndexData,
          newCapSymbolName,
          5,
          newCapSymbol.snow_id
        );

        if (res.code === 200000) {
          newCapSymbol.short_name = newCapSymbolName;
          let newCapSymbolGns = res.data.cell.gns;

          let file = await getObjectData(fileId);

          file.add_capsymbol(newCapSymbol);

          //保存文件和封装符号数据
          // batch_data[newCapSymbol.snow_id] = {
          //   folder_name: getCurrentBoardFolderName(newCapSymbolGns),
          //   file_type: "SYMBOLS_FILE",
          // };

          // batch_data[fileId] = {
          //   folder_name: getCurrentBoardFolderName(file_gns),
          //   file_type: "DIAGRAM_FILE",
          // };
          let binaryDataObj = newCapSymbol.dump_file(true, 0);

          let fileBinaryDataObj = file.dump_file(true, 0);
          fileBinaryDataObj = { ...fileBinaryDataObj, ...binaryDataObj };
          let u8Array = binaryDataToUTF8(fileBinaryDataObj);
          // await batchSaveDataFn(u8Array, batch_data);
          await setFileParams(fileId, cellIndexData);
          await batchSaveDataFn_fileSize(u8Array, projectId, fileId, 'DIAGRAM_FILE', [newCapSymbol.snow_id]);

          let capSymbolData = schemaNode.data.isTeam
            ? new TeamSchemaCellNode(
              newCapSymbol.name,
              newCapSymbol.snow_id,
              newCapSymbolGns,
              "CELL",
              "团队cell",
              5,
              false,
              `(${schemaNode.data.name})`
            )
            : new UserSchemaCellNode(
              newCapSymbol.name,
              newCapSymbol.snow_id,
              newCapSymbolGns,
              "CELL",
              "个人cell",
              5,
              false,
              `(${schemaNode.data.name})`
            );
          let fileNode = schemaNode.parent;
          this.addCellNodeFn(capSymbolData, fileNode);
          setDataToCellTable(projectId, fileId, newCapSymbol);
          this.$message.success(this.$t("messages.encapsulateSuccess"));
          bus.$emit("ending", true);
          //封装符号消息同步
          if (fileNode.data.isTeam) {
            this.sendRefreshNodeMsg(fileNode);
          }
        } else {
          bus.$emit("errDialog", this.$t("messages.code_" + res.code));
          bus.$emit("ending", true);
        }
      }, 1000);
    });

    bus.$on("refreshNode", (expandData) => {
      this.refreshNode(expandData);
    });

    bus.$on("refreshCellRef", (cellId) => {
      setTimeout(() => {
        let cellNode = this.$refs.tree.getNode(cellId);
        this.isWaitEs = true;
        cellNode.loaded = false;
        cellNode.expand();
      }, 500);
    });

    bus.$on("replaceSymbolRefresh", (fileId) => {
      let fileNode = this.$refs.tree.getNode(fileId);
      fileNode.loaded = false;
      fileNode.expand();
      if (fileNode.data.isTeam) {
        this.sendRefreshNodeMsg(fileNode);
      }
    });

    //消息接收刷新节点
    bus.$on("msg_refreshTargetNode", (data) => {
      let targetNode = this.$refs.tree.getNode(data);
      targetNode.loaded = false;
      targetNode.expand();
    });

    //消息接收删除子节点
    bus.$on("msg_deleteTargetNode", (data) => {
      let targetNode = this.$refs.tree.getNode(data);

      // if (targetNode.level === 4) {
      //   this.deleteRefRefresh(targetNode)
      // }
      if (targetNode) {
        this.$refs.tree.remove(targetNode);
      }
    });

    //消息接收重命名子节点
    bus.$on("msg_renameTargetNode", (data) => {
      let targetNode = this.$refs.tree.getNode(data.child_snow_id);
      targetNode.data.name = data.child_name;
      let cell = getCellFromTable(targetNode.data.snow_id);
      cell.name = data.child_name;
      this.resetNameRefRefresh(targetNode);
    });

    //消息接收符号替换
    bus.$on("msg_replaceSymbol", (data) => {
      let targetNode = this.$refs.tree.getNode(data);
      targetNode.loaded = false;
      targetNode.expand();

      //拿到当前缓存的原理图数据,和indexData中索引关系比较,如果不同,进行修改
    });

    bus.$on("msg_refreshRef", (data) => {
      let targetNode = this.$refs.tree.getNode(data.child_snow_id);

      if (targetNode.loaded) {
        targetNode.loaded = false;
        targetNode.expand();
      }
    });

    //调用
    bus.$on("contextMenuInstance", async (data) => {
      bus.$emit("loading", true);
      let currentProjectId = this.$store.state.proInfo.projectId;
      let currentFileId = this.$store.state.fileInfo.fileId; //当前单元的文件
      let currentCellId = this.$store.state.cellInfo.cellId;
      let targetProjectId = this.node.parent.parent.data.snow_id;
      let targetFileId = this.node.parent.data.snow_id; //被调用单元的文件
      let targetCellId = this.node.data.snow_id;
      let currentFileNode = this.$refs.tree.getNode(currentFileId);

      if (currentFileId === targetFileId) {
        let cell = getCellFromTable(targetCellId);
        if (cell === null) {
          //不存在,从对象存储获取
          cell = await getCompleteCellData(
            targetProjectId,
            targetFileId,
            targetCellId
          );
        }

        //判断是否数据为空
        let isEmpty = isCellEmpty(cell);
        if (isEmpty) {
          bus.$emit("errDialog", "文件内容为空无法进行调用");
          return;
        } else {
          //先进行闭环判断
          let insIsCircle = isCircle(cell, this.$store.state.activeCell);

          if (insIsCircle) {
            bus.$emit("errDialog", this.$t("messages.callClosedLoop"));
            return;
          } else {
            if (cell.type.value === 5) {
              //和图层列表比对,修改单元中的数据
              let associatedCellList = cell.depend_cells(-1);
              associatedCellList.push(cell);
              //和原本的文件图层先行比较,改正数据
              associatedCellList = await contrastCellDataByLayerId(
                targetFileId,
                associatedCellList
              );
              let index = associatedCellList.findIndex(
                (item) => item.snow_id === cell.snow_id
              );
              cell = associatedCellList.splice(index, 1)[0];
              // cell = AssembleCellFn(cell, associatedCellList);

              let ref = new Kernel.Reference();
              ref.cell = cell;

              bus.$emit("addSelfDevice", ref);
            } else if (cell.type.value === 1 || cell.type.value === 2) {
              let symbolIns = new Kernel.SymbolIns();

              symbolIns.symbol = cell;

              bus.$emit("addSelfSymbol", symbolIns);
            }
          }
        }
      } else {
        //先获取当前文件权限
        let permissionRes = await getPermission(currentFileNode.data.gns);
        if (permissionRes.code === 200000) {
          currentFileNode.data.teamRule = permissionRes.data.permission;
        }

        if (
          currentFileNode.data.isTeam &&
          currentFileNode.data.teamRule != "可删除、编辑"
        ) {
          bus.$emit("errDialog", this.$t("messages.userFilePermission"));
          return;
        }

        let cell = getCellFromTable(targetCellId);
        if (cell === null) {
          cell = await getCompleteCellData(
            targetProjectId,
            targetFileId,
            targetCellId
          );
        }

        let isEmpty = isCellEmpty(cell);
        if (isEmpty) {
          bus.$emit("errDialog", "文件内容为空无法进行调用");
          return;
        } else {
          if (cell.type.value === 5) {
            //和图层列表比对,修改单元中的数据
            let associatedCellList = cell.depend_cells(-1);
            associatedCellList.push(cell);
            //和原本的文件图层先行比较,改正数据
            associatedCellList = await contrastCellDataByLayerId(
              targetFileId,
              associatedCellList
            );
            let index = associatedCellList.findIndex(
              (item) => item.snow_id === cell.snow_id
            );
            cell = associatedCellList.splice(index, 1)[0];
            cell = AssembleCellFn(cell, associatedCellList);

            let newCell = await deepCopyRemaneCell(
              cell,
              this.node,
              currentFileNode,
              true
            );
            let ref = new Kernel.Reference();
            ref.cell = newCell;

            bus.$emit("addNewDevice", ref);
          } else if (cell.type.value === 1) {
            let newSymbol = deepCopyRemaneSymbol(cell, currentFileNode);

            let symbolIns = new Kernel.SymbolIns();
            symbolIns.symbol = newSymbol;
            bus.$emit("addNewSymbol", symbolIns);
          } else if (cell.type.value === 2) {
            let newCapSymbol = deepCopyRemaneCapSymbol(cell, currentFileNode);

            let symbolIns = new Kernel.SymbolIns();
            symbolIns.symbol = newCapSymbol;
            bus.$emit("addNewSymbol", symbolIns);
          }
        }
      }

      bus.$emit("ending", true);
    });

    bus.$on("updateFileTree", async (data) => {
      if (data === null || data.length === 0) {
        return;
      }
      let nextStep;
      if (data.nextStep) {
        nextStep = data.nextStep;
        data = data.cell;
      }
      let cellId = this.$store.state.cellInfo.cellId;
      let currentCellNode = this.$refs.tree.getNode(cellId);
      let cellNodeIdList = [];
      let isCorssFile = false;
      let fileId = this.$store.state.fileInfo.fileId;
      let fileNode = this.$refs.tree.getNode(fileId);
      let projectId = fileNode.parent.data.snow_id;
      let cellId_list = []//批量创建需要的cellid列表
      let batchSaveType = ""//批量创建单元的保存类型'LAYOUT_FILE'/'DIAGRAM_FILE'

      let all_data = {
        project_list: [],
        file_list: [],
        cell_list: [],
        snow_team_id: "",
        accessor_type: "",
      };

      if (Array.isArray(data)) {
        let fileGns = fileNode.data.gns;
        let file = await getObjectData(fileId);

        all_data.snow_team_id = fileNode.parent.parent.data.snow_id;
        all_data.accessor_type = fileNode.parent.parent.data.isTeam ? 2 : 1;
        let batch_data = {};
        let u8Array = [];

        if (data[0].$$.ptrType.name == "Cell*") {
          batchSaveType = 'LAYOUT_FILE'
          let topCell = new Kernel.Cell("upDateFileTree");
          for (let i = 0; i < data.length; i++) {
            let ref = new Kernel.Reference();
            ref.cell = data[i];
            topCell.add_reference(ref);
          }

          //添加调用关系到当前画板文件的索引数据中
          let cellIndexData = await getCellIndexData(fileId);

          let cellBinaryDataObj = {};
          let associatedCellList = topCell.depend_cells(-1);

          for (let i = 0; i < associatedCellList.length; i++) {
            let cellIndexNew = {
              snow_id: associatedCellList[i].snow_id,
              cells: [],
            };
            let cells = [];
            for (let j = 0; j < associatedCellList[i].referens.length; j++) {
              if (associatedCellList[i].referens[j].cell) {
                cells.push({
                  snow_id: associatedCellList[i].referens[j].cell.snow_id,
                });
              }
            }
            cellIndexNew.cells = cells;

            cellIndexData.push(cellIndexNew);
            let cellGns = `${fileGns}/${associatedCellList[i].snow_id}`;
            all_data.cell_list.push({
              name: associatedCellList[i].name,
              snow_id: associatedCellList[i].snow_id,
              gns: cellGns,
              parent_gns: fileGns,
              parent_snow_id: fileId,
              type: 2,
            });
            file.add_cell(associatedCellList[i]);
            // batch_data[associatedCellList[i].snow_id] = {
            //   folder_name: getCurrentBoardFolderName(cellGns),
            //   file_type: "LAYOUT_FILE",
            // };
            let binaryDataObj = associatedCellList[i].dump_file(true, 0);
            cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj };
            cellId_list.push(associatedCellList[i].snow_id)
          }
          // batch_data[fileId] = {
          //   folder_name: getCurrentBoardFolderName(fileGns),
          //   file_type: "LAYOUT_FILE",
          // };
          let fileBinaryDataObj = file.dump_file(true, 0);
          fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj };
          u8Array = binaryDataToUTF8(fileBinaryDataObj);

          let res = await normalCreateFileFn(all_data);
          if (res.code === 200000) {
            // await batchSaveDataFn(u8Array, batch_data);
            await setFileParams(fileId, cellIndexData);
            await batchSaveDataFn_fileSize(u8Array, projectId, fileId, batchSaveType, cellId_list);
            if (fileNode.data.isTeam) {
              this.sendRefreshNodeMsg(fileNode);
              if (fileNode.data.file_type === 1) {
                bus.$emit("layerUpdate", true);
              }
            }
            setTimeout(() => {
              fileNode.loaded = false;
              fileNode.expand();
              setTimeout(() => {
                associatedCellList.forEach((item) => {
                  setDataToCellTable(projectId, fileId, item);
                });
              }, 500);
            }, 500);
          }
        } else if (data[0].$$.ptrType.name == "SymbolIns*") {
          batchSaveType = 'DIAGRAM_FILE'
          let topSchema = new Kernel.Schema();
          for (let i = 0; i < data.length; i++) {
            topSchema.add_symbol_ins(data[i]);
          }

          let symbols = topSchema.symbols;
          let symbolsLength = symbols.length;

          let cellIndexData = await getCellIndexData(fileId);

          let associatedCellList = [];
          let cellBinaryDataObj = {};
          for (let j = 0; j < symbolsLength; j++) {
            if (symbols[j].type.value === 1) {
              let cellIndexNew = { snow_id: symbols[j].snow_id, cells: [] };
              cellIndexData.push(cellIndexNew);
              let cellGns = `${fileGns}/${symbols[j].snow_id}`;

              all_data.cell_list.push({
                name: symbols[j].name,
                snow_id: symbols[j].snow_id,
                gns: cellGns,
                parent_gns: fileGns,
                parent_snow_id: fileId,
                type: 4,
              });

              file.add_symbol(symbols[j]);
              let binaryDataObj = symbols[j].dump_file(true, 0);
              cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj };
              cellId_list.push(symbols[j].snow_id)
              // batch_data[symbols[j].snow_id] = {
              //   folder_name: getCurrentBoardFolderName(cellGns),
              //   file_type: "SYMBOLS_FILE",
              // };
            } else if (symbols[j].type.value === 2) {
              associatedCellList = symbols[j].depend_symbol_schemas(-1);
              associatedCellList.push(symbols[j]);

              for (let i = 0; i < associatedCellList.length; i++) {
                let cellIndexNew = {
                  snow_id: associatedCellList[i].snow_id,
                  cells: [],
                };
                let cellGns = `${fileGns}/${associatedCellList[i].snow_id}`;
                let cells = [];
                if (associatedCellList[i].type.value === 1) {
                  all_data.cell_list.push({
                    name: associatedCellList[i].name,
                    snow_id: associatedCellList[i].snow_id,
                    gns: cellGns,
                    parent_gns: fileGns,
                    parent_snow_id: fileId,
                    type: 4,
                  });
                  file.add_symbol(associatedCellList[i]);
                  // batch_data[associatedCellList[i].snow_id] = {
                  //   folder_name: getCurrentBoardFolderName(cellGns),
                  //   file_type: "SYMBOLS_FILE",
                  // };
                } else if (associatedCellList[i].type.value === 2) {
                  all_data.cell_list.push({
                    name: associatedCellList[i].name,
                    snow_id: associatedCellList[i].snow_id,
                    gns: cellGns,
                    parent_gns: fileGns,
                    parent_snow_id: fileId,
                    type: 5,
                  });
                  // batch_data[associatedCellList[i].snow_id] = {
                  //   folder_name: getCurrentBoardFolderName(cellGns),
                  //   file_type: "SYMBOLS_FILE",
                  // };
                  file.add_capsymbol(associatedCellList[i]);
                  if (associatedCellList[i].schema !== null)
                    cells.push({
                      snow_id: associatedCellList[i].schema.snow_id,
                    });
                } else if (associatedCellList[i].type.value === 3) {
                  all_data.cell_list.push({
                    name: associatedCellList[i].name,
                    snow_id: associatedCellList[i].snow_id,
                    gns: cellGns,
                    parent_gns: fileGns,
                    parent_snow_id: fileId,
                    type: 3,
                  });
                  // batch_data[associatedCellList[i].snow_id] = {
                  //   folder_name: getCurrentBoardFolderName(cellGns),
                  //   file_type: "DIAGRAM_FILE",
                  // };
                  file.add_schema(associatedCellList[i]);
                  let symbolIns = [
                    ...associatedCellList[i].symbol_ins,
                    ...associatedCellList[i].capsymbol_ins,
                  ];
                  for (let j = 0; j < symbolIns.length; j++) {
                    cells.push({
                      snow_id: symbolIns[j].symbol.snow_id,
                    });
                  }
                }
                cellId_list.push(associatedCellList[i].snow_id)
                cellIndexNew.cells = cells;
                cellIndexData.push(cellIndexNew);
                let binaryDataObj = associatedCellList[i].dump_file(true, 0);
                cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj };
              }
            }

            let fileBinaryDataObj = file.dump_file(true, 0);
            fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj };
            u8Array = binaryDataToUTF8(fileBinaryDataObj);
            // batch_data[fileId] = {
            //   folder_name: getCurrentBoardFolderName(fileGns),
            //   file_type: "DIAGRAM_FILE",
            // };

            let res = await normalCreateFileFn(all_data);
            if (res.code === 200000) {
              // await batchSaveDataFn(u8Array, batch_data);
              await setFileParams(fileId, cellIndexData);
              await batchSaveDataFn_fileSize(u8Array, projectId, fileId, batchSaveType, cellId_list);
              if (fileNode.data.isTeam) {
                this.sendRefreshNodeMsg(fileNode);
                if (fileNode.data.file_type === 1) {
                  bus.$emit("layerUpdate", true);
                }
              }
              setTimeout(() => {
                fileNode.loaded = false;
                fileNode.expand();
                setTimeout(() => {
                  associatedCellList.forEach((item) => {
                    setDataToCellTable(projectId, fileId, item);
                  });
                }, 500);
              }, 500);
            }
          }
        }
      } else {
        let instanceCellId = data.snow_id;

        fileNode.childNodes.forEach((item) => {
          cellNodeIdList.push(item.data.snow_id);
        });

        if (!cellNodeIdList.some((item) => item === instanceCellId)) {
          isCorssFile = true;
        }
        if (isCorssFile) {
          let cell = data;
          let fileGns = fileNode.data.gns;
          let file = await getObjectData(fileId);

          all_data.snow_team_id = fileNode.parent.parent.data.snow_id;
          all_data.accessor_type = fileNode.parent.parent.data.isTeam ? 2 : 1;
          let batch_data = {};
          let u8Array = [];
          //添加调用关系到当前画板文件的索引数据中
          let cellIndexData = await getCellIndexData(fileId);

          let associatedCellList = [];
          let cellBinaryDataObj = {};

          if (cell.type.value === 5) {
            // 调用单元
            batchSaveType = 'LAYOUT_FILE'
            associatedCellList = cell.depend_cells(-1);
            associatedCellList.push(cell);

            for (let i = 0; i < associatedCellList.length; i++) {
              let cellIndexNew = {
                snow_id: associatedCellList[i].snow_id,
                cells: [],
              };
              let cells = [];
              for (let j = 0; j < associatedCellList[i].referens.length; j++) {
                if (associatedCellList[i].referens[j].cell) {
                  cells.push({
                    snow_id: associatedCellList[i].referens[j].cell.snow_id,
                  });
                }
              }
              cellIndexNew.cells = cells;

              cellIndexData.push(cellIndexNew);
              let cellGns = `${fileGns}/${associatedCellList[i].snow_id}`;
              all_data.cell_list.push({
                name: associatedCellList[i].name,
                snow_id: associatedCellList[i].snow_id,
                gns: cellGns,
                parent_gns: fileGns,
                parent_snow_id: fileId,
                type: 2,
              });
              file.add_cell(associatedCellList[i]);
              // batch_data[associatedCellList[i].snow_id] = {
              //   folder_name: getCurrentBoardFolderName(cellGns),
              //   file_type: "LAYOUT_FILE",
              // };
              let binaryDataObj = associatedCellList[i].dump_file(true, 0);
              cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj };
              cellId_list.push(associatedCellList[i].snow_id)
            }
            // batch_data[fileId] = {
            //   folder_name: getCurrentBoardFolderName(fileGns),
            //   file_type: "LAYOUT_FILE",
            // };
            let fileBinaryDataObj = file.dump_file(true, 0);
            fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj };
            u8Array = binaryDataToUTF8(fileBinaryDataObj);
          } else if (cell.type.value === 1) {
            // 调用符号
            batchSaveType = 'DIAGRAM_FILE'
            let cellIndexNew = { snow_id: cell.snow_id, cells: [] };
            cellIndexData.push(cellIndexNew);
            let cellGns = `${fileGns}/${cell.snow_id}`;

            all_data.cell_list.push({
              name: cell.name,
              snow_id: cell.snow_id,
              gns: cellGns,
              parent_gns: fileGns,
              parent_snow_id: fileId,
              type: 4,
            });

            file.add_symbol(cell);
            let cellBinaryDataObj = cell.dump_file(true, 0);
            let fileBinaryDataObj = file.dump_file(true, 0);
            fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj };
            u8Array = binaryDataToUTF8(fileBinaryDataObj);

            // batch_data[cell.snow_id] = {
            //   folder_name: getCurrentBoardFolderName(cellGns),
            //   file_type: "SYMBOLS_FILE",
            // };
            // batch_data[fileId] = {
            //   folder_name: getCurrentBoardFolderName(fileGns),
            //   file_type: "DIAGRAM_FILE",
            // };
            cellId_list.push(cell.snow_id)
          } else if (cell.type.value === 2) {
            // 调用封装符号
            batchSaveType = 'DIAGRAM_FILE'
            let cellBinaryDataObj = {};
            associatedCellList = cell.depend_symbol_schemas(-1);
            associatedCellList.push(cell);

            for (let i = 0; i < associatedCellList.length; i++) {
              let cellIndexNew = {
                snow_id: associatedCellList[i].snow_id,
                cells: [],
              };
              let cellGns = `${fileGns}/${associatedCellList[i].snow_id}`;
              let cells = [];
              if (associatedCellList[i].type.value === 1) {
                all_data.cell_list.push({
                  name: associatedCellList[i].name,
                  snow_id: associatedCellList[i].snow_id,
                  gns: cellGns,
                  parent_gns: fileGns,
                  parent_snow_id: fileId,
                  type: 4,
                });
                file.add_symbol(associatedCellList[i]);
                // batch_data[associatedCellList[i].snow_id] = {
                //   folder_name: getCurrentBoardFolderName(cellGns),
                //   file_type: "SYMBOLS_FILE",
                // };
              } else if (associatedCellList[i].type.value === 2) {
                all_data.cell_list.push({
                  name: associatedCellList[i].name,
                  snow_id: associatedCellList[i].snow_id,
                  gns: cellGns,
                  parent_gns: fileGns,
                  parent_snow_id: fileId,
                  type: 5,
                });
                // batch_data[associatedCellList[i].snow_id] = {
                //   folder_name: getCurrentBoardFolderName(cellGns),
                //   file_type: "SYMBOLS_FILE",
                // };
                file.add_capsymbol(associatedCellList[i]);
                if (associatedCellList[i].schema !== null)
                  cells.push({ snow_id: associatedCellList[i].schema.snow_id });
              } else if (associatedCellList[i].type.value === 3) {
                all_data.cell_list.push({
                  name: associatedCellList[i].name,
                  snow_id: associatedCellList[i].snow_id,
                  gns: cellGns,
                  parent_gns: fileGns,
                  parent_snow_id: fileId,
                  type: 3,
                });
                // batch_data[associatedCellList[i].snow_id] = {
                //   folder_name: getCurrentBoardFolderName(cellGns),
                //   file_type: "DIAGRAM_FILE",
                // };
                file.add_schema(associatedCellList[i]);
                let symbolIns = [
                  ...associatedCellList[i].symbol_ins,
                  ...associatedCellList[i].capsymbol_ins,
                ];
                for (let j = 0; j < symbolIns.length; j++) {
                  cells.push({
                    snow_id: symbolIns[j].symbol.snow_id,
                  });
                }
              }
              cellIndexNew.cells = cells;
              cellIndexData.push(cellIndexNew);
              let binaryDataObj = associatedCellList[i].dump_file(true, 0);
              cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj };
              cellId_list.push(associatedCellList[i].snow_id)
            }
            let fileBinaryDataObj = file.dump_file(true, 0);
            fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj };
            u8Array = binaryDataToUTF8(fileBinaryDataObj);
            // batch_data[fileId] = {
            //   folder_name: getCurrentBoardFolderName(fileGns),
            //   file_type: "DIAGRAM_FILE",
            // };
          }
          //保存对象存储数据，文件列表数据，索引数据

          let res = await normalCreateFileFn(all_data);
          if (res.code === 200000) {
            // await batchSaveDataFn(u8Array, batch_data);
            await setFileParams(fileId, cellIndexData);
            await batchSaveDataFn_fileSize(u8Array, projectId, fileId, batchSaveType, cellId_list);
            if (fileNode.data.isTeam) {
              this.sendRefreshNodeMsg(fileNode);
              if (fileNode.data.file_type === 1) {
                bus.$emit("layerUpdate", true);
              }
            }
            await new Promise((res) => {
              setTimeout(() => {
                fileNode.loaded = false;
                fileNode.expand();
                setTimeout(() => {
                  for (let i = 0; i < associatedCellList.length; i++) {
                    setDataToCellTable(
                      projectId,
                      fileId,
                      associatedCellList[i]
                    );
                  }
                  res();
                }, 500);
              }, 500);
            });
          }
        } else {
          setTimeout(() => {
            currentCellNode.loaded = false;
            currentCellNode.expand();

            if (fileNode.data.isTeam) {
              this.sendRefRefreshMsg(currentCellNode);
            }
            let cell = getCellFromTable(data.snow_id);
            if (cell === null) {
              setTimeout(() => {
                let associatedCellList = cell.depend_cells(-1);
                associatedCellList.push(cell);
                for (let i = 0; i < associatedCellList.length; i++) {
                  setDataToCellTable(projectId, fileId, associatedCellList[i]);
                }
              }, 500);
            }
          }, 500);
        }
      }

      if (nextStep) {
        nextStep();
      }

      setTimeout(() => {
        bus.$emit("ending", true);
      }, 1500);
    });

    bus.$on("changeActiveNode", (data) => {
      let activeNode = this.$refs.tree.getNode(data);

      this.$store.commit("setActiveNode", activeNode);
    });

    bus.$on("getBoxData", (data) => {
      this.boxData = data;
    });

    //另存为单元
    bus.$on("saveAsCellFn", async (data) => {
      bus.$emit("loading", true);

      if (this.boxData !== null) {
        let targetFileNode = this.$refs.tree.getNode(data[0].data.snow_id);
        let projectId = targetFileNode.parent.data.snow_id;
        let fileId = targetFileNode.data.snow_id;
        let fileGns = targetFileNode.data.gns;
        let activeNode = this.$store.state.activeNode;
        let cell_type = Number(data[2]);
        let all_data = {
          project_list: [],
          file_list: [],
          cell_list: [],
          snow_team_id: "",
          accessor_type: "",
        };
        all_data.snow_team_id = targetFileNode.parent.parent.data.snow_id;
        all_data.accessor_type = targetFileNode.parent.parent.data.isTeam
          ? 2
          : 1;
        // let batch_data = {};
        let u8Array = [];
        let cellId_list = [];

        let saveAsCellList = this.boxData.deep_copy_logic();
        let saveAsCell = saveAsCellList.filter(
          (item) => item.name === this.boxData.name
        )[0];

        saveAsCell.name = data[1];

        saveAsCell = await fileLayerListChange_CellListLayerChange(
          this.$store.state.fileInfo.fileId,
          fileGns,
          saveAsCell
        );

        saveAsCell = await deepCopyRemaneCell(
          saveAsCell,
          activeNode,
          targetFileNode,
          true
        );

        let associatedCellList = saveAsCell.depend_cells(-1);
        associatedCellList.push(saveAsCell);
        let cellIndexData = await getCellIndexData(fileId);
        if (cellIndexData === null) {
          cellIndexData = [];
        }

        let file = await getObjectData(fileId);
        let cellBinaryDataObj = {};
        for (let i = 0; i < associatedCellList.length; i++) {
          let cellIndexNew = {
            snow_id: associatedCellList[i].snow_id,
            cells: [],
          };
          let cells = [];
          for (let j = 0; j < associatedCellList[i].referens.length; j++) {
            if (associatedCellList[i].referens[j].cell) {
              cells.push({
                snow_id: associatedCellList[i].referens[j].cell.snow_id,
              });
            }
          }
          cellIndexNew.cells = cells;
          cellIndexData.push(cellIndexNew);
          let cellGns = `${fileGns}/${associatedCellList[i].snow_id}`;
          all_data.cell_list.push({
            name: associatedCellList[i].name,
            snow_id: associatedCellList[i].snow_id,
            gns: cellGns,
            parent_gns: fileGns,
            parent_snow_id: fileId,
            type: cell_type,
          });

          file.add_cell(associatedCellList[i]);
          // batch_data[associatedCellList[i].snow_id] = {
          //   folder_name: getFolderName(
          //     targetFileNode.parent.parent.data.snow_id,
          //     cellGns,
          //     targetFileNode.data.isTeam
          //   ),
          //   file_type: "LAYOUT_FILE",
          // };
          let binaryDataObj = associatedCellList[i].dump_file(true, 0);
          cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj };
          cellId_list.push(associatedCellList[i].snow_id)
        }

        // batch_data[fileId] = {
        //   folder_name: getFolderName(
        //     targetFileNode.parent.parent.data.snow_id,
        //     fileGns,
        //     targetFileNode.data.isTeam
        //   ),
        //   file_type: "LAYOUT_FILE",
        // };
        let fileBinaryDataObj = file.dump_file(true, 0);
        fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj };
        u8Array = binaryDataToUTF8(fileBinaryDataObj);

        let res = await normalCreateFileFn(all_data);
        if (res.code === 200000) {
          // await batchSaveDataFn(u8Array, batch_data);
          await setFileParams(fileId, cellIndexData);
          await batchSaveDataFn_fileSize(u8Array, projectId, fileId, 'DIAGRAM_FILE', cellId_list);
          let topData = {
            topId: targetFileNode.parent.parent.data.snow_id,
            isTeam: targetFileNode.parent.parent.data.isTeam,
          };
          let projectData = {
            name: targetFileNode.parent.data.name,
            snow_id: projectId,
            gns: targetFileNode.parent.data.gns,
            isTeam: targetFileNode.parent.data.isTeam,
          };
          let fileData = {
            name: targetFileNode.data.name,
            snow_id: fileId,
            gns: targetFileNode.data.gns,
            isTeam: targetFileNode.data.isTeam,
          };
          let expandData = [
            { category: "CELL", topData, projectData, fileData },
          ];

          await this.refreshNode(expandData);
          if (targetFileNode.data.isTeam) {
            let msgNode = this.$refs.tree.getNode(targetFileNode.data.snow_id);
            this.sendRefreshNodeMsg(msgNode);
          }
          bus.$emit("ending", true);
        }
      } else {
        bus.$emit("ending", true);
        bus.$emit("errDialog", this.$t("messages.unframedData"));
        return;
      }
    });

    //获取id对应的单元相关的数据
    bus.$on("getTargetNodeInfo", (idData) => {
      let fileId = idData.fileId;
      let cellId = idData.cellId;

      let fileNode = this.$refs.tree.getNode(fileId);

      let cellInfo = [];
      let topNodeData = fileNode.parent.parent.data;
      let proNodeData = fileNode.parent.data;
      let fileNodeData = fileNode.data;
      if (fileNode.data.isTeam) {
        cellInfo = [
          {
            teamId: topNodeData.snow_id,
            name: topNodeData.name,
            teamRole: topNodeData.role,
          },
          {
            projectId: proNodeData.snow_id,
          },
          {
            fileId: fileNodeData.snow_id,
            teamRule: fileNodeData.teamRule,
            file_type: fileNodeData.file_type,
          },
          {
            cellId,
          },
        ];
      } else {
        cellInfo = [
          {
            userId: "",
            name: topNodeData.name,
          },
          {
            projectId: proNodeData.snow_id,
          },
          {
            fileId: fileNodeData.snow_id,
            name: fileNodeData.name,
            file_type: fileNodeData.file_type,
          },
          {
            cellId,
          },
        ];
      }

      this.$store.commit("setTargetNodeInfo", cellInfo);
    });

    bus.$on("contextMenuReadOnly", () => {
      //以只读模式打开
      this.isReadOnly = true;
      this.openCellFn(this.node, false);
    });

    bus.$on("openCellInHeader", (obj) => {
      let cellNode = obj.cellNode;
      this.isReadOnly = obj.isReadOnly;
      let topData = {
        topId: cellNode.parent.parent.parent.data.snow_id,
        isTeam: cellNode.parent.parent.parent.data.isTeam,
      };
      let projectData = {
        name: cellNode.parent.parent.data.name,
        snow_id: cellNode.parent.parent.data.snow_id,
        gns: cellNode.parent.parent.data.gns,
        isTeam: cellNode.parent.parent.data.isTeam,
      };
      let fileData = {
        name: cellNode.parent.data.name,
        snow_id: cellNode.parent.data.snow_id,
        gns: cellNode.parent.data.gns,
        isTeam: cellNode.parent.data.isTeam,
      };
      let expandData = [{ category: "CELL", topData, projectData, fileData }];
      //同时展开到对应节点
      this.refreshNode(expandData, false);
      let node = {};
      let timer = setInterval(() => {
        node = this.$refs.tree.getNode(cellNode.data.snow_id);
        if (node !== null) {
          clearInterval(timer);
          this.openCellFn(node, false);
        }
      }, 100);
    });

    bus.$on("toolSplitCell", async (lib) => {
      let cellList = lib.cells;
      let newFileName =
        lib.name + "_" + Math.random().toString(36).substr(2, 5);
      let library = new Kernel.Library(newFileName);
      let fileLayerNumberList = [];
      let cellLayerNumberList = [];
      let cellId_list = [];
      cellList.forEach((item) => {
        cellLayerNumberList = [
          ...cellLayerNumberList,
          ...item.layers,
          ...item.depend_layers(),
        ];
      });
      fileLayerNumberList = [
        ...new Set([...fileLayerNumberList, ...cellLayerNumberList]),
      ];

      let newFileLayerList = importLayerList(fileLayerNumberList);

      let all_data = {
        project_list: [],
        file_list: [],
        cell_list: [],
        snow_team_id: "",
        accessor_type: "",
      };
      let activeNode = this.$store.state.activeNode;
      let projectNode = activeNode.parent.parent;
      all_data.snow_team_id = projectNode.parent.data.snow_id;
      all_data.accessor_type = projectNode.parent.data.isTeam ? 2 : 1;
      let projectGns = projectNode.data.gns;
      let project_snow_id = projectNode.data.snow_id;
      // let batch_data = {};
      let cellIndexData = [];
      let file_snow_id = library.snow_id;
      let fileGns = `${projectGns}/${file_snow_id}`;
      for (let i = 0; i < cellList.length; i++) {
        let cellGns = `${fileGns}/${cellList[i].snow_id}`;
        all_data.cell_list.push({
          name: cellList[i].name,
          snow_id: cellList[i].snow_id,
          gns: cellGns,
          parent_gns: fileGns,
          parent_snow_id: file_snow_id,
          type: 1,
        });

        // batch_data[cellList[i].snow_id] = {
        //   folder_name: getCurrentBoardFolderName(cellGns),
        //   file_type: returnFileType(5),
        // };

        let cells = [];
        let cellIndexNew = {
          snow_id: cellList[i].snow_id,
          cells: [],
        };
        for (let j = 0; j < cellList[i].referens.length; j++) {
          if (cellList[i].referens[j].cell) {
            cells.push({
              snow_id: cellList[i].referens[j].cell.snow_id,
            });
          }
        }
        cellIndexNew.cells = cells;
        cellIndexData.push(cellIndexNew);
        cellId_list.push(cellList[i].snow_id)
      }

      all_data.file_list.push({
        name: library.name,
        snow_id: file_snow_id,
        gns: fileGns,
        parent_gns: projectGns,
        parent_snow_id: project_snow_id,
        type: 1,
      });

      // batch_data[file_snow_id] = {
      //   folder_name: getCurrentBoardFolderName(fileGns),
      //   file_type: returnFileType(6),
      // };

      cellList = contrastCellDataByLayerNumber(newFileLayerList, cellList);

      for (let i = 0; i < cellList.length; i++) {
        library.add_cell(cellList[i]);
      }

      let binaryDataObj = library.dump_file(true, -1);

      let u8Array = binaryDataToUTF8(binaryDataObj);

      // 创建拆分的版图和版图文件
      let res = await normalCreateFileFn(all_data);
      if (res.code === 200000) {
        await saveNewLayerFn(newFileLayerList, fileGns);
        await setFileParams(file_snow_id, cellIndexData);
        // await batchSaveDataFn(u8Array, batch_data);
        await batchSaveDataFn_fileSize(u8Array, project_snow_id, file_snow_id, 'DIAGRAM_FILE', cellId_list);
        if (projectNode.data.isTeam) {
          this.sendRefreshNodeMsg(projectNode);
        }
        projectNode.loaded = false;
        projectNode.expand();
      }
    });

    bus.$on("successMessage", (msg) => {
      this.$message.success(msg);
    });
  },
  beforeDestroy() {
    bus.$off("contextMenuReName");
    bus.$off("getNodeById");
    bus.$off("createCellRefreshTreeNode");
    bus.$off("saveFile");
    bus.$off("createNewFile");
    bus.$off("deleteNode");
    bus.$off("newCapSymbolFn");
    bus.$off("refreshNode");
    bus.$off("refreshCellRef");
    bus.$off("replaceSymbolRefresh");
    bus.$off("msg_refreshTargetNode");
    bus.$off("msg_deleteTargetNode");
    bus.$off("msg_renameTargetNode");
    bus.$off("msg_replaceSymbol");
    bus.$off("msg_refreshRef");
    bus.$off("contextMenuInstance");
    bus.$off("updateFileTree");
    bus.$off("changeActiveNode");
    bus.$off("getBoxData");
    bus.$off("saveAsCellFn");
    bus.$off("getTargetNodeInfo");
    bus.$off("contextMenuReadOnly");
    bus.$off("openCellInHeader");
    bus.$off("toolSplitCell");
    bus.$off("successMessage");
  },
  methods: {
    //文件列表图标显示
    treeNodeIcon(node) {
      if (node.level == 1 && node.data.isTeam == false) {
        return require("@/assets/icons/file/个人用户名.svg");
      } else if (node.level === 1 && node.data.isTeam == true) {
        return require("@/assets/icons/file/团队名.svg");
      } else if (node.level === 2) {
        return require("@/assets/icons/file/项目名.svg");
      } else if (node.level === 3 && node.data.file_type === 1) {
        return require("@/assets/icons/file/版图文件.svg");
      } else if (node.level === 3 && node.data.file_type === 3) {
        return require("@/assets/icons/file/原理图文件名称.svg");
      } else if (node.level === 4 && node.data.cell_type === 1) {
        return require("@/assets/icons/file/版图名.svg");
      } else if (node.level === 4 && node.data.cell_type === 2) {
        return require("@/assets/icons/file/器件名.svg");
      } else if (node.level >= 4 && node.data.cell_type === 3) {
        return require("@/assets/icons/file/原理图.svg");
      } else if (
        node.level >= 4 &&
        (node.data.cell_type === 4 || node.data.cell_type === 5)
      ) {
        return require("@/assets/icons/file/打开符号.svg");
      } else if (
        node.level >= 4 &&
        (node.data.cell_type === 1 || node.data.cell_type === 2)
      ) {
        return require("@/assets/icons/file/器件名.svg");
      }
    },

    // logState() {

    //   importLayerList([1, 2, 4, 6, 9])
    // },

    //同菜单栏创建
    headerCreate() {
      bus.$emit("headerCreateCell", true);
    },

    ////消息机制
    //文件列表节点刷新消息发送方法
    sendRefreshNodeMsg(node) {
      let snow_id = node.data.snow_id;
      let data = {
        snow_id,
        func: "refreshNode",
      };

      let WS = null;
      if (node.data.fileWs === null) {
        WS = addFileListMessageServe(node);
      } else {
        WS = node.data.fileWs;
      }
      WS.sendMsg(data);
    },

    //文件列表引用关系刷新消息
    sendRefRefreshMsg(node) {
      let snow_id = node.data.snow_id;
      let fileNode = node.parent;
      let data = {
        snow_id: fileNode.data.snow_id,
        child_snow_id: snow_id,
        func: "refreshRef",
      };
      let WS = null;
      if (fileNode.data.fileWs === null) {
        WS = addFileListMessageServe(fileNode);
      } else {
        WS = fileNode.data.fileWs;
      }
      WS.sendMsg(data);
    },

    //文件列表节点删除消息
    sendDeleteNodeMsg(node) {
      let child_snow_id = node.data.snow_id;
      let data = {
        child_snow_id,
        func: "deleteChildNode",
      };
      node.parent.data.fileWs.sendMsg(data);
    },

    //文件列表节点重命名消息
    sendRenameNodeMsg(node, name) {
      let child_snow_id = node.data.snow_id;
      let data = {
        child_snow_id,
        child_name: name,
        func: "renameChildNode",
      };
      node.parent.data.fileWs.sendMsg(data);
    },

    //文件列表符号替换消息
    sendReplaceSymbolMsg(node) {
      let snow_id = node.data.snow_id;
      let data = {
        snow_id,
        func: "replaceSymbol",
      };

      let WS = null;
      if (node.data.fileWs === null) {
        WS = addFileListMessageServe(node);
      } else {
        WS = node.data.fileWs;
      }
      WS.sendMsg(data);
    },

    //文件列表历史记录
    nodeExpand(data, node) {
      if (node.level <= 3) {
        let flag = false;
        this.expandedList.some((item) => {
          if (item === data.snow_id) {
            // 判断当前节点是否存在， 存在不做处理
            flag = true;
            return;
          }
        });
        if (!flag) {
          // 不存在则存到数组里
          if (node.level === 3) {
            this.expandedList.push(data.snow_id);
          } else {
            this.expandedList.push(data.snow_id);
            this.childNodeExpand(node);
          }
        }
        localStorage.setItem("expandedList", JSON.stringify(this.expandedList));
      }
    },

    // 展开父节点, 递归展开其下所有已被展开节点
    childNodeExpand(node) {
      node.childNodes.forEach((item) => {
        if (item.loaded) {
          const index = this.expandedList.indexOf(item.data.snow_id);
          if (index === -1) {
            this.expandedList.push(item.data.snow_id);
          }
          this.childNodeExpand(item);
        }
      });
    },

    //项目收缩删除状态
    nodeCollapse(data, node) {
      if (node.level <= 3) {
        this.expandedList.some((item, i) => {
          if (item === data.snow_id) {
            this.expandedList.splice(i, 1);
          }
        });
        this.removeChildren(node);
        localStorage.setItem("expandedList", JSON.stringify(this.expandedList));
        if (this.isDelete) {
          this.nodeCollapseOver = true;
        }
      }
    },

    //收缩父节点,递归删除其下所有节点
    removeChildren(node) {
      if (node.childNodes) {
        node.childNodes.forEach((item) => {
          const index = this.expandedList.indexOf(item.data.snow_id);
          if (index !== -1) {
            this.expandedList.splice(index, 1);
          }
          this.removeChildren(item);
        });
      }
    },

    //文件列表右键
    async fileListRightClick(event, data, node) {
      if (node.level < 5) {
        this.$store.commit("RCNodeInfo", node);
      }
      // if (node.data.isTeam) {
      //   if (node.level > 1) {
      //     let permissionRes = await getPermission(node.data.gns);
      //     if (permissionRes.code === 200000) {
      //       node.data.teamRule = permissionRes.data.permission;
      //     }
      //   }
      // }
      this.node = node;
      // bus.$emit('fileListRightClick', true)
    },

    //右键菜单
    async onContextMenu(event, node) {
      // CreateCellOption, ReNameOption, DeleteOption, CopyOption, SaveToLibOption, InstanceOption, ReadOnlyOption, SaveToSymbolLibOption, SymbolReplaceOption
      //判断权限
      //先判断是否是可以操作
      //判断是否可以被打开
      let lock = false;

      let disabledCreate = false; //是否禁用新建
      let disabledCopy = false; //是否禁用复制
      let disabledRename = false; //是否禁用重命名
      let disabledDelete = false; //是否禁用删除
      let disabledOpenInReadOnly = false; //是否禁用已只读模式打开
      let disabledSaveToCellLib = false; //是否禁用存到器件库
      let disabledSaveToSymbolLib = false; //是否禁用存到符号库
      let disabledInstance = false; //是否禁用调用
      let disabledReplace = false; //是否禁用替换

      if (node.data.isTeam && node.level > 1 && node.level < 5) {
        //团队先要获取最新权限,判断是否有权限
        let permissionRes = await getPermission(node.data.gns);
        if (permissionRes.code === 200000) {
          let teamRule = permissionRes.data.permission;
          // teamRule = "只读"
          node.data.teamRule = teamRule;
          if (node.level === 4) {
            let lockCellRes = await selectLockCell(node.data.snow_id);

            if (lockCellRes.code === 200000) {
              if (lockCellRes.message == "not lock") {
                lock = false;
              } else if (lockCellRes.message == "locked") {
                if (this.$store.state.openedTab.length) {
                  for (const i in this.$store.state.openedTab) {
                    let gnsCellId = this.$store.state.openedTab[i].route
                      .split("=")
                    [
                      this.$store.state.openedTab[i].route.split("=").length -
                      1
                    ].split("/")[1];
                    if (gnsCellId === node.data.snow_id) {
                      lock = false;
                      break;
                    } else {
                      lock = true;
                    }
                  }
                } else {
                  lock = true;
                }
              }
            }
          }

          if (this.$store.state.openedTab.length) {
            for (const i in this.$store.state.openedTab) {
              let gnsCellId = this.$store.state.openedTab[i].route
                .split("=")
              [
                this.$store.state.openedTab[i].route.split("=").length - 1
              ].split("/")[1];

              if (
                gnsCellId === node.data.snow_id &&
                this.$store.state.openedTab[i].isReadOnly
              ) {
                disabledOpenInReadOnly = true;
                break;
              } else {
                disabledOpenInReadOnly = false;
              }
            }
          } else {
            disabledOpenInReadOnly = false;
          }

          if (teamRule == "可删除、编辑" && !lock) {
            disabledCreate = false;
            disabledCopy = false;
            disabledRename = false;
            disabledDelete = false;
            disabledOpenInReadOnly = false;
            disabledSaveToCellLib = false;
            disabledSaveToSymbolLib = false;
            disabledInstance = false;
            disabledReplace = false;
          } else if (teamRule == "可删除、编辑" && lock) {
            disabledCreate = false;
            disabledCopy = false;
            disabledRename = true;
            disabledDelete = true;
            disabledOpenInReadOnly = false;
            disabledSaveToCellLib = true;
            disabledSaveToSymbolLib = true;
            disabledInstance = false;
            disabledReplace = true;
          } else if (teamRule == "只读") {
            disabledCreate = true;
            disabledCopy = false;
            disabledRename = true;
            disabledDelete = true;
            disabledOpenInReadOnly = false;
            disabledSaveToCellLib = true;
            disabledSaveToSymbolLib = true;
            disabledInstance = true;
            disabledReplace = true;
          } else if (teamRule == "不可读") {
            disabledCreate = true;
            disabledCopy = true;
            disabledRename = true;
            disabledDelete = true;
            disabledOpenInReadOnly = true;
            disabledSaveToCellLib = true;
            disabledSaveToSymbolLib = true;
            disabledInstance = true;
            disabledReplace = true;
          }
        }
      }

      if (node.level === 4) {
        if (
          this.$store.state.fileInfo.file_type !== node.parent.data.file_type
        ) {
          disabledInstance = true;
        }
      }

      if (node.data.isTeam) {
        if (node.level === 2) {
          //团队项目层级
          this.$contextmenu({
            items: [
              new CreateCellOption(
                this.$t("messages.new"),
                true,
                "iconfont icon-xinjian",
                disabledCreate
              ),
              new CopyOption(
                this.$t("messages.copyTo"),
                false,
                "iconfont icon-fuzhi",
                disabledCopy,
                1,
                node
              ),
              new ReNameOption(
                this.$t("messages.rename"),
                false,
                "iconfont icon-zhongmingming",
                disabledRename
              ),
              new DeleteOption(
                this.$t("messages.delete"),
                false,
                "iconfont icon-shanchu",
                disabledDelete
              ),
            ],
            event, // 鼠标事件信息
            customClass: "custom-class", // 自定义菜单 class
            zIndex: 1000, // 菜单样式 z-index
            minWidth: 170, // 主菜单最小宽度
          });
        } else if (node.level === 3) {
          //团队文件层级
          this.$contextmenu({
            items: [
              new CreateCellOption(
                this.$t("messages.new"),
                true,
                "iconfont icon-xinjian",
                disabledCreate
              ),
              new CopyOption(
                this.$t("messages.copyTo"),
                false,
                "iconfont icon-fuzhi",
                disabledCopy,
                2,
                node
              ),
              new ReNameOption(
                this.$t("messages.rename"),
                false,
                "iconfont icon-zhongmingming",
                disabledRename
              ),
              new DeleteOption(
                this.$t("messages.delete"),
                false,
                "iconfont icon-shanchu",
                disabledDelete
              ),
            ],
            event, // 鼠标事件信息
            customClass: "custom-class", // 自定义菜单 class
            zIndex: 1000, // 菜单样式 z-index
            minWidth: 170, // 主菜单最小宽度
          });
        } else if (node.level === 4) {
          //团队单元层级
          if (node.data.cell_type === 1 || node.data.cell_type === 2) {
            this.$contextmenu({
              items: [
                new CreateCellOption(
                  this.$t("messages.new"),
                  true,
                  "iconfont icon-xinjian",
                  disabledCreate
                ),
                new CopyOption(
                  this.$t("messages.copyTo"),
                  false,
                  "iconfont icon-fuzhi",
                  disabledCopy,
                  3,
                  node
                ),
                new SaveToLibOption(
                  this.$t("messages.SavetoLibrary"),
                  false,
                  "iconfont icon-cundaoqijianku",
                  disabledSaveToCellLib,
                  node
                ),
                new ReadOnlyOption(
                  this.$t("messages.OpeninReadOnly"),
                  false,
                  "iconfont icon-yizhidudakai",
                  disabledOpenInReadOnly
                ),
                new ReNameOption(
                  this.$t("messages.rename"),
                  false,
                  "iconfont icon-zhongmingming",
                  disabledRename
                ),
                new DeleteOption(
                  this.$t("messages.delete"),
                  false,
                  "iconfont icon-shanchu",
                  disabledDelete
                ),
                new InstanceOption(
                  this.$t("messages.instance"),
                  false,
                  "iconfont icon-tiaoyong",
                  disabledInstance
                ),
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          } else if (node.data.cell_type === 3) {
            this.$contextmenu({
              items: [
                new CreateCellOption(
                  this.$t("messages.new"),
                  true,
                  "iconfont icon-xinjian",
                  disabledCreate
                ),
                new CopyOption(
                  this.$t("messages.copyTo"),
                  false,
                  "iconfont icon-fuzhi",
                  disabledCopy,
                  3,
                  node
                ),
                new ReadOnlyOption(
                  this.$t("messages.OpeninReadOnly"),
                  false,
                  "iconfont icon-yizhidudakai",
                  disabledOpenInReadOnly
                ),
                new ReNameOption(
                  this.$t("messages.rename"),
                  false,
                  "iconfont icon-zhongmingming",
                  disabledRename
                ),
                new DeleteOption(
                  this.$t("messages.delete"),
                  false,
                  "iconfont icon-shanchu",
                  disabledDelete
                ),
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          } else if (node.data.cell_type === 4 || node.data.cell_type === 5) {
            this.$contextmenu({
              items: [
                new CopyOption(
                  this.$t("messages.copyTo"),
                  false,
                  "iconfont icon-fuzhi",
                  disabledCopy,
                  3,
                  node
                ),
                new SaveToSymbolLibOption(
                  this.$t("messages.saveToSymbolLibrary"),
                  false,
                  "iconfont icon-cundaofuhaoku",
                  disabledSaveToSymbolLib
                ),
                new ReNameOption(
                  this.$t("messages.rename"),
                  false,
                  "iconfont icon-zhongmingming",
                  disabledRename
                ),
                new DeleteOption(
                  this.$t("messages.delete"),
                  false,
                  "iconfont icon-shanchu",
                  disabledDelete
                ),
                new InstanceOption(
                  this.$t("messages.instance"),
                  false,
                  "iconfont icon-tiaoyong",
                  disabledInstance
                ),
                new SymbolReplaceOption(
                  this.$t("符号替换"),
                  false,
                  "iconfont icon-tihuan",
                  disabledReplace
                ),
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          }
        }
      } else {
        if (node.level === 2) {
          //个人项目层级
          this.$contextmenu({
            items: [
              new CreateCellOption(
                this.$t("messages.new"),
                true,
                "iconfont icon-xinjian",
                false
              ),
              new CopyOption(
                this.$t("messages.copyTo"),
                false,
                "iconfont icon-fuzhi",
                false,
                1,
                node
              ),
              new ReNameOption(
                this.$t("messages.rename"),
                false,
                "iconfont icon-zhongmingming",
                false
              ),
              new DeleteOption(
                this.$t("messages.delete"),
                false,
                "iconfont icon-shanchu",
                false
              ),
            ],
            event, // 鼠标事件信息
            customClass: "custom-class", // 自定义菜单 class
            zIndex: 1000, // 菜单样式 z-index
            minWidth: 170, // 主菜单最小宽度
          });
        } else if (node.level === 3) {
          //个人文件层级
          this.$contextmenu({
            items: [
              new CreateCellOption(
                this.$t("messages.new"),
                true,
                "iconfont icon-xinjian",
                false
              ),
              new CopyOption(
                this.$t("messages.copyTo"),
                false,
                "iconfont icon-fuzhi",
                false,
                2,
                node
              ),
              new ReNameOption(
                this.$t("messages.rename"),
                false,
                "iconfont icon-zhongmingming",
                false
              ),
              new DeleteOption(
                this.$t("messages.delete"),
                false,
                "iconfont icon-shanchu",
                false
              ),
            ],
            event, // 鼠标事件信息
            customClass: "custom-class", // 自定义菜单 class
            zIndex: 1000, // 菜单样式 z-index
            minWidth: 170, // 主菜单最小宽度
          });
        } else if (node.level === 4) {
          if (node.data.cell_type === 1 || node.data.cell_type === 2) {
            this.$contextmenu({
              items: [
                new CreateCellOption(
                  this.$t("messages.new"),
                  true,
                  "iconfont icon-xinjian",
                  false
                ),
                new CopyOption(
                  this.$t("messages.copyTo"),
                  false,
                  "iconfont icon-fuzhi",
                  false,
                  3,
                  node
                ),
                new SaveToLibOption(
                  this.$t("messages.SavetoLibrary"),
                  false,
                  "iconfont icon-cundaoqijianku",
                  false,
                  node
                ),
                new ReadOnlyOption(
                  this.$t("messages.OpeninReadOnly"),
                  false,
                  "iconfont icon-yizhidudakai",
                  disabledOpenInReadOnly
                ),
                new ReNameOption(
                  this.$t("messages.rename"),
                  false,
                  "iconfont icon-zhongmingming",
                  false
                ),
                new DeleteOption(
                  this.$t("messages.delete"),
                  false,
                  "iconfont icon-shanchu",
                  false
                ),
                new InstanceOption(
                  this.$t("messages.instance"),
                  false,
                  "iconfont icon-tiaoyong",
                  disabledInstance
                ),
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          } else if (node.data.cell_type === 3) {
            this.$contextmenu({
              items: [
                new CreateCellOption(
                  this.$t("messages.new"),
                  true,
                  "iconfont icon-xinjian",
                  false
                ),
                new CopyOption(
                  this.$t("messages.copyTo"),
                  false,
                  "iconfont icon-fuzhi",
                  false,
                  3,
                  node
                ),
                new ReadOnlyOption(
                  this.$t("messages.OpeninReadOnly"),
                  false,
                  "iconfont icon-yizhidudakai",
                  disabledOpenInReadOnly
                ),
                new ReNameOption(
                  this.$t("messages.rename"),
                  false,
                  "iconfont icon-zhongmingming",
                  false
                ),
                new DeleteOption(
                  this.$t("messages.delete"),
                  false,
                  "iconfont icon-shanchu",
                  false
                ),
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          } else if (node.data.cell_type === 4 || node.data.cell_type === 5) {
            this.$contextmenu({
              items: [
                new CopyOption(
                  this.$t("messages.copyTo"),
                  false,
                  "iconfont icon-fuzhi",
                  false,
                  3,
                  node
                ),
                new SaveToSymbolLibOption(
                  this.$t("messages.saveToSymbolLibrary"),
                  false,
                  "iconfont icon-cundaofuhaoku",
                  false
                ),
                new ReNameOption(
                  this.$t("messages.rename"),
                  false,
                  "iconfont icon-zhongmingming",
                  false
                ),
                new DeleteOption(
                  this.$t("messages.delete"),
                  false,
                  "iconfont icon-shanchu",
                  false
                ),
                new InstanceOption(
                  this.$t("messages.instance"),
                  false,
                  "iconfont icon-tiaoyong",
                  disabledInstance
                ),
                new SymbolReplaceOption(
                  this.$t("符号替换"),
                  false,
                  "iconfont icon-tihuan",
                  false
                ),
              ],
              event, // 鼠标事件信息
              customClass: "custom-class", // 自定义菜单 class
              zIndex: 1000, // 菜单样式 z-index
              minWidth: 170, // 主菜单最小宽度
            });
          }
        }
      }
    },

    //文件列表单击
    fileListNodeClick(data, node) {
      let nodeList = document.querySelectorAll(".bar-button");

      nodeList.forEach((item) => {
        item.classList.remove("open");
      });
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (node.data.fileWs === null) {
          let WS = addFileListMessageServe(node);
          node.data.fileWs = WS;
        }
      }, 300);
    },

    //节点渲染
    async loadNode(node, resolve) {
      if (node.level > 0 && node.level < 4) {
        if (node.data.isTeam) {
          let WS = addFileListMessageServe(node);
          node.data.fileWs = WS;
        }
      }

      if (node.level === 0) {
        let data = [];
        data = this.topNodeRender_normal();
        return resolve(data);
      } else if (node.level === 1 && node.data.isTeam == false) {
        let data = [];
        data = await this.projectNodeRender_normal_user(node);
        this.nodeExpand(node.data, node);
        return resolve(data);
      } else if (node.level === 1 && node.data.isTeam == true) {
        let data = [];
        data = await this.projectNodeRender_normal_team(node);
        this.nodeExpand(node.data, node);
        return resolve(data);
      } else if (node.level === 2 && node.data.isTeam == false) {
        let data = [];
        data = await this.fileNodeRender_user(node);
        this.nodeExpand(node.data, node);
        return resolve(data);
      } else if (node.level === 2 && node.data.isTeam == true) {
        let data = [];
        data = await this.fileNodeRender_team(node);

        this.nodeExpand(node.data, node);
        return resolve(data);
      } else if (node.level === 3 && node.data.isTeam == false) {
        let data = [];
        data = await this.cellNodeRender_user(node);
        this.nodeExpand(node.data, node);
        if (this.isCreateNewFile) {
          //是新建文件,自动打开

          this.autoOpenCellFn();
          this.isCreateNewFile = false;
        }
        return resolve(data);
      } else if (node.level === 3 && node.data.isTeam == true) {
        let data = [];
        data = await this.cellNodeRender_team(node);
        this.nodeExpand(node.data, node);
        if (this.isCreateNewFile) {
          //是新建文件,自动打开

          this.autoOpenCellFn();
          this.isCreateNewFile = false;
        }
        return resolve(data);
      } else if (node.level === 4) {
        // let data = []
        let data = await this.cellReferenceRelationshipFn(node);
        return resolve(data);
      }
      //  else if (node.level >= 4 && node.data.cell_type !== 1) {
      //   let data = [];
      //   return resolve(data);
      // }
    },

    //文件列表引用关系获取
    async cellReferenceRelationshipFn(node) {
      let data = [];
      let isOpenCell = false;
      let fileNode = node.parent;

      for (let i = 0; i < this.$store.state.openedTab.length; i++) {
        let gnsCellId = this.$store.state.openedTab[i].route
          .split("=")
        [this.$store.state.openedTab[i].route.split("=").length - 1].split(
          "/"
        )[1];
        if (gnsCellId === node.data.snow_id) {
          isOpenCell = true;
          break;
        }
      }

      if (isOpenCell) {
        let cell = getCellFromTable(node.data.snow_id);
        if (node.data.cell_type === 3) {
          let ins = [...cell.symbol_ins, ...cell.capsymbol_ins];
          for (let j = 0; j < ins.length; j++) {
            if (ins[j].symbol) {
              let cell_type = ins[j].symbol.type.value === 1 ? 4 : 5;
              data.push({
                name: ins[j].symbol.name,
                cell_type,
                isLeaf: true,
              });

              if (cell_type === 5) {
                data.push({
                  name: ins[j].symbol.schema.name,
                  cell_type: 3,
                  isLeaf: true,
                });
              }
            }
          }
        } else {
          let refs = cell.depend_cells(1);

          for (let j = 0; j < refs.length; j++) {
            await new Promise((res) => {
              setTimeout(() => {
                let cellNode = fileNode.childNodes.find(
                  (item) => item.data.snow_id === refs[j].snow_id
                );
                let cell_type = cellNode.data.cell_type;
                data.push({
                  name: refs[j].name,
                  cell_type: cell_type,
                  isLeaf: true,
                });
                res();
              }, 500);
            });
          }
        }
      } else {
        let cellIndexData = [];
        let time = this.isWaitEs ? 1000 : 0;
        await new Promise((res) => {
          setTimeout(async () => {
            cellIndexData = await getCellIndexData(fileNode.data.snow_id);
            res();
          }, time);
        });
        this.isWaitEs = false;

        let cellIndex = cellIndexData.find(
          (item) => item.snow_id === node.data.snow_id
        );

        if (cellIndex !== undefined && cellIndex.cells) {
          for (let i = 0; i < cellIndex.cells.length; i++) {
            let leafNode = fileNode.childNodes.find(
              (item) => item.data.snow_id === cellIndex.cells[i].snow_id
            );
            data.push({
              name: leafNode.data.name,
              cell_type: leafNode.data.cell_type,
              isLeaf: true,
            });
          }
        }
      }
      const schemaMap = new Map();
      const symbolMap = new Map();
      const capSymbolMap = new Map();
      const cellMap = new Map();
      for (let x = 0; x < data.length; x++) {
        if (data[x].cell_type === 3) {
          if (!schemaMap.has(data[x].name)) {
            schemaMap.set(data[x].name, data[x]);
          }
        } else if (data[x].cell_type === 4) {
          if (!symbolMap.has(data[x].name)) {
            symbolMap.set(data[x].name, data[x]);
          }
        } else if (data[x].cell_type === 5) {
          if (!capSymbolMap.has(data[x].name)) {
            capSymbolMap.set(data[x].name, data[x]);
          }
        } else {
          if (!cellMap.has(data[x].name)) {
            cellMap.set(data[x].name, data[x]);
          }
        }
      }
      data = [
        ...schemaMap.values(),
        ...symbolMap.values(),
        ...capSymbolMap.values(),
        ...cellMap.values(),
      ];

      return data;
    },

    // 顶层作业节点渲染
    // topNodeRender_homework() {
    //   let data = [];
    //   let isTeamNode = false
    //   this.homework_gns = sessionStorage.getItem('homework_gns')
    //   this.homework_name = sessionStorage.getItem('homework_name')
    //   this.homework_read_only = JSON.parse(sessionStorage.getItem('homework_read_only'))
    //   this.homework_topId = sessionStorage.getItem('homework_topId')
    //   this.homework_nodeType = JSON.parse(sessionStorage.getItem('homework_nodeType'))
    //   this.isHomeWork = true

    //   if (this.homework_topId !== "") {
    //     isTeamNode = true
    //   }
    //   if (this.homework_nodeType === 0) {
    //     data.push({
    //       name: "作业",
    //       isTeam: isTeamNode,
    //       snow_id: this.homework_topId,
    //     });
    //   } else {
    //     data.push({
    //       name: "答案",
    //       isTeam: isTeamNode,
    //       snow_id: this.homework_topId,
    //     });
    //   }

    //   setTimeout(() => {

    //     this.$refs.tree.getNode(this.homework_topId).expand()
    //   }, 500);

    //   return data
    // },

    // 顶层节点渲染
    topNodeRender_normal() {
      let data = [];
      this.treeNameList.forEach((item) => {
        data.push(item);
      });
      return data;
    },

    //个人项目层节点渲染
    async projectNodeRender_normal_user() {
      let data = [];
      let res = await getUserProjectList(0, 50);
      if (res.code === 200000) {
        res.data.data.forEach((item) => {
          data.push(
            new UserProjectNode(
              item._source.name,
              item._source.snow_id,
              item._source.gns,
              item._source.category,
              item._source.category_child
            )
          );
        });
        // setTimeout(async () => {
        //   if (this.refreshProjectNode) {
        //     await this.expandProNodeFn(500);
        //   }
        // }, 500);
        return data;
      }
    },

    //个人项目层作业节点渲染
    // projectNodeRender_homework_user() {
    //   let data = [];
    //   data.push({
    //     name: this.homework_name,
    //     gns: this.homework_gns,
    //     category: "项目",
    //     category_child: "个人项目",
    //     isTeam: false,
    //   })
    //   return data
    // },

    //团队项目层节点渲染
    async projectNodeRender_normal_team(node) {
      let data = [];
      let res = await getTeamProjectList(
        0,
        50,
        node.data.snow_id,
        node.data.gns
      );
      if (res.code === 200000) {
        res.data.data.forEach((item) => {
          data.push(
            new TeamProjectNode(
              item._source.name,
              item._source.snow_id,
              item._source.gns,
              item._source.category,
              item._source.category_child
            )
          );
        });
        // setTimeout(async () => {
        //   if (this.refreshProjectNode) {
        //     await this.expandProNodeFn(500);
        //   }
        // }, 500);
        return data;
      }
    },

    //团队项目层作业节点渲染
    // projectNodeRender_homework_team() {
    //   let data = [];
    //   data.push({
    //     name: this.homework_name,
    //     gns: this.homework_gns,
    //     category: "项目",
    //     category_child: "团队项目",
    //     isTeam: true,
    //     teamRule: "",
    //   })
    //   return data
    // },

    //个人文件层节点渲染
    async fileNodeRender_user(node) {
      let res = await getFileList(0, 200, node.data.gns, "个人文件");
      if (res.code === 200000) {
        let data = [];
        res.data.data.forEach((item) => {
          data.push(
            new UserFileNode(
              item._source.name,
              item._source.snow_id,
              item._source.gns,
              item._source.category,
              item._source.category_child,
              item._source.types
            )
          );
        });

        setTimeout(async () => {
          if (this.refreshFileNode) {
            await this.expandFileNodeFn(500);
            // if (this.copyFile) {
            //   bus.$emit("ending", true);
            // }
          }
        }, 500);
        return data;
      }
    },

    //团队文件层节点渲染
    async fileNodeRender_team(node) {
      let res = await getFileList(0, 200, node.data.gns, "团队文件");
      if (res.code === 200000) {
        let data = [];
        res.data.data.forEach((item) => {
          data.push(
            new TeamFileNode(
              item._source.name,
              item._source.snow_id,
              item._source.gns,
              item._source.category,
              item._source.category_child,
              item._source.types
            )
          );
        });

        setTimeout(async () => {
          if (this.refreshFileNode) {
            await this.expandFileNodeFn(500);
            // if (this.copyFile) {
            //   bus.$emit("ending", true);
            // }
          }
        }, 500);
        return data;
      }
    },

    //版图单元层节点渲染
    async cellNodeRender_user(node) {
      let res = await getCellList(0, 500, node.data.gns, "个人cell");
      if (res.code === 200000) {
        let data = [];
        if (node.data.file_type === 1) {
          res.data.data.forEach((item) => {
            data.push(
              new UserLayoutCellNode(
                item._source.name,
                item._source.snow_id,
                item._source.gns,
                item._source.category,
                item._source.category_child,
                item._source.types,
                false,
                item._source.create_time
              )
            );
          });
        } else if (node.data.file_type === 3) {
          res.data.data.forEach((item) => {
            data.push(
              new UserSchemaCellNode(
                item._source.name,
                item._source.snow_id,
                item._source.gns,
                item._source.category,
                item._source.category_child,
                item._source.types,
                false,
                "",
                item._source.create_time
              )
            );
          });
        }

        if (node.data.file_type === 3) {
          let fileNode = node;
          let cellIndexData = [];
          await new Promise((res) => {
            setTimeout(async () => {
              cellIndexData = await getCellIndexData(fileNode.data.snow_id);
              res();
            }, 1000);
          });

          for (let i = 0; i < data.length; i++) {
            if (data[i].cell_type === 5) {
              let capSymbolIndex = cellIndexData.find(
                (item) => item.snow_id === data[i].snow_id
              );

              if (capSymbolIndex) {
                let schemaId = capSymbolIndex.cells.length
                  ? capSymbolIndex.cells[0].snow_id
                  : null;
                let schemaData = data.find((item) => item.snow_id === schemaId);

                data[i].epschemaName = schemaData ? `(${schemaData.name})` : "";
              }
            }
          }
        }

        return data;
      }
    },

    //原理图单元层节点渲染
    async cellNodeRender_team(node) {
      let res = await getCellList(0, 500, node.data.gns, "团队cell");
      if (res.code === 200000) {
        let data = [];
        if (node.data.file_type === 1) {
          res.data.data.forEach((item) => {
            data.push(
              new TeamLayoutCellNode(
                item._source.name,
                item._source.snow_id,
                item._source.gns,
                item._source.category,
                item._source.category_child,
                item._source.types,
                false,
                item._source.create_time
              )
            );
          });
        } else if (node.data.file_type === 3) {
          res.data.data.forEach((item) => {
            data.push(
              new TeamSchemaCellNode(
                item._source.name,
                item._source.snow_id,
                item._source.gns,
                item._source.category,
                item._source.category_child,
                item._source.types,
                false,
                "",
                item._source.create_time
              )
            );
          });
        }

        if (node.data.file_type === 3) {
          let fileNode = node;
          let cellIndexData = [];
          await new Promise((res) => {
            setTimeout(async () => {
              cellIndexData = await getCellIndexData(fileNode.data.snow_id);
              res();
            }, 1000);
          });

          for (let i = 0; i < data.length; i++) {
            if (data[i].cell_type === 5) {
              let capSymbolIndex = cellIndexData.find(
                (item) => item.snow_id === data[i].snow_id
              );

              if (capSymbolIndex) {
                let schemaId = capSymbolIndex.cells.length
                  ? capSymbolIndex.cells[0].snow_id
                  : null;
                let schemaData = data.find((item) => item.snow_id === schemaId);

                data[i].epschemaName = schemaData ? `(${schemaData.name})` : "";
              }
            }
          }
        }

        return data;
      }
    },

    //双击节点
    fileListDBlclick(data, node) {
      // 这里上线后需要改成上线日期的时间戳，用于判断是否为2.4版本之前的文件
      // let online_time = "2023-07-25 00:00:00"
      let time_stamp_new = parseInt(new Date(window.online_time).getTime());
      // let time_stamp_new = parseInt(new Date().getTime());
      const is_old = time_stamp_new > data.create_time;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.node = node;

      if (
        data.category === "CELL" &&
        data.cell_type !== 4 &&
        data.cell_type !== 5
      ) {
        if (data.category_child === "个人cell") {
          this.openCellFn(node, is_old);
        } else {
          this.isReadOnly = false;
          //获取当前节点的使用情况
          this.openCellFn(node, is_old);
          // if (this.isReadOnly) {
          //   this.openCellFn(node)
          // }
        }
      }
    },

    // 自动打开单元
    autoOpenCellFn() {
      setTimeout(() => {
        let cellNode = this.$refs.tree.getNode(this.expandCellData.snow_id);
        if (cellNode.data.isTeam) {
          let refreshCategory = this.expandDataArr.type;
          let msgRefreshNode = null;
          if (refreshCategory === 1) {
            msgRefreshNode = cellNode.parent.parent.parent;
          } else if (refreshCategory === 2) {
            msgRefreshNode = cellNode.parent.parent;
          } else if (refreshCategory === 3) {
            msgRefreshNode = cellNode.parent;
          }
          this.sendRefreshNodeMsg(msgRefreshNode);
        }
        this.openCellFn(cellNode, false);
      }, 500);
    },

    //打开单元方法
    async openCellFn(node, is_old) {
      let isReadOnly = this.isReadOnly;
      let topNodeData = node.parent.parent.parent.data;
      let proNodeData = node.parent.parent.data;
      let fileId = node.parent.data.snow_id;
      let fileNodeData = node.parent.data;
      let cellId = node.data.snow_id;
      let lock = false;
      let isOperable = false;
      let isOpendedInReadOnly = false;
      if (is_old) {
        bus.$emit("transforming", true);
      } else {
        bus.$emit("loading", true);
      }

      //判断是否可以被打开
      if (node.data.isTeam && !isReadOnly) {
        let teamRuleData = await permissionsJudgmentFn(node.data.gns);
        isOperable = teamRuleData.isOperable;
        node.data.teamRule = teamRuleData.teamRule;
        if (isOperable) {
          isOpendedInReadOnly = isCellOpenedReadOnly(cellId);
          if (!isOpendedInReadOnly) {
            lock = await isCellOpened(cellId);
            if (!lock) {
            } else {
              return;
            }
          } else {
            return;
          }
        } else {
          return;
        }
      } else if (isReadOnly) {
      } else if (node.data.isTeam == false && !isReadOnly) {
        isOpendedInReadOnly = isCellOpenedReadOnly(cellId);
        if (isOpendedInReadOnly) {
          return;
        }
      }

      if (isReadOnly || lock === false) {
        let query = `${fileId}/${cellId}`;
        let route = `/home/layout/board?id=${query}`;

        let cellInfo = [];
        if (node.data.isTeam) {
          cellInfo = [
            {
              teamId: topNodeData.snow_id,
              name: topNodeData.name,
              teamRole: topNodeData.role,
            },
            {
              projectId: proNodeData.snow_id,
              name: proNodeData.name,
              teamRule: proNodeData.teamRule,
            },
            {
              fileId: fileNodeData.snow_id,
              name: fileNodeData.name,
              teamRule: fileNodeData.teamRule,
              file_type: fileNodeData.file_type,
            },
            {
              cellId,
              name: node.data.name,
              teamRule: node.data.teamRule,
              isSaved: true,
              isReadOnly,
              cell_type: node.data.cell_type,
            },
          ];
        } else {
          cellInfo = [
            {
              userId: "",
              name: topNodeData.name,
            },
            {
              projectId: proNodeData.snow_id,
              name: proNodeData.name,
            },
            {
              fileId: fileNodeData.snow_id,
              name: fileNodeData.name,
              file_type: fileNodeData.file_type,
            },
            {
              cellId,
              name: node.data.name,
              isSaved: true,
              isReadOnly,
              cell_type: node.data.cell_type,
            },
          ];
        }

        this.$store.commit("setCellInfo", cellInfo); //设置cellInfo

        //获取显示设置,检查设置
        await this.getUserConfigFn(proNodeData.snow_id, node);

        //获取pdk数据
        await getPDKFileFn(proNodeData.snow_id);

        //获取文件数据,判断映射表中是否有保存
        if (!is_old) {
          let isCompleteData = await this.getCellData(node);
          console.log(isCompleteData);
          if (!isCompleteData) {
            return
          }
          this.openNewTabFn(node, route, isReadOnly, query, cellInfo);
        } else {
          let expandData = await tranformToNewPeoject(node);
          if (expandData) {
            await this.useDeleteApi(node.parent.parent);
            bus.$emit("refreshNode", expandData);
            this.$message.success(this.$t("messages.transformSuccess"));
          } else {
            this.$message.error(this.$t("messages.transformError"));
          }
          // bus.$emit("refreshNode", expandData);
          // bus.$emit("createCellRefreshTreeNode", { topData, projectData, fileData, cellData, type })
          bus.$emit("transforming", false);
        }

        // this.openNewTabFn(node, route, isReadOnly, query, cellInfo);
      }
    },

    async useDeleteApi(node) {
      let res = await deleteCell(node.data.gns, node.data.category);
      if (res.code === 200000) {
        // 更新历史记录

        this.$refs.tree.remove(node);
      }
    },

    //获取显示设置,检查设置
    async getUserConfigFn(projectId, node) {
      //判断是否已经存储过项目设置
      let schematicBoardSettings = {};
      let schematicCheckSettings = [];

      if (
        this.$store.state.schemaBoardSettingsArr.findIndex(
          (item) => item.projectId === projectId
        ) === -1
      ) {
        let userConfig = await getUserConfig(projectId);
        if (userConfig.code === 200000) {
          if (JSON.stringify(userConfig.data.show_set) === "{}") {
            schematicBoardSettings = {
              value_backgroundColor: "#ffffff",
              value_gridColor: "#000000",
              gridVisible: "1",
              adsorption: 1,
              gridSize: 1,
            };
          } else {
            schematicBoardSettings = {
              value_backgroundColor:
                userConfig.data.show_set.value_backgroundColor,
              value_gridColor: userConfig.data.show_set.value_gridColor,
              gridVisible: userConfig.data.show_set.gridVisible,
              adsorption: userConfig.data.show_set.adsorption,
              gridSize: userConfig.data.show_set.gridSize,
            };
          }

          if (node.data.cell_type === 3) {
            if (JSON.stringify(userConfig.data.check_set) !== "{}") {
              if (userConfig.data.check_set.tableData.length > 0) {
                schematicCheckSettings = userConfig.data.check_set.tableData;
              } else {
                schematicCheckSettings = this.defaultTableData;
              }
            } else {
              schematicCheckSettings = this.defaultTableData;
            }

            let schematicCheckSet = {
              projectId,
              schematicCheckSettings,
            };
            this.$store.commit("setSchemaCheckSettings", schematicCheckSet);
          }
          let schematicBoardSet = {
            projectId,
            schematicBoardSettings,
          };
          this.$store.commit("setShemaBoardSettings", schematicBoardSet);
        }
      }
    },

    async getCellData(node) {
      let cell = {};
      let projectId = node.parent.parent.data.snow_id;
      let fileId = node.parent.data.snow_id;
      let cellId = node.data.snow_id;
      let associatedCellArr = [];

      if (
        !this.$store.state.cellTable.some(
          (item) => item.cell.snow_id === cellId
        )
      ) {
        //无单元数据,获取单元数据
        cell = await getCompleteCellData(projectId, fileId, cellId);
        if (!cell) {
          return false
        }
        //获取单元数据后,将已获取的数据都缓存
        if (cell.type.value === 5) {
          associatedCellArr = cell.depend_cells(-1);
        } else if (cell.type.value === 3) {
          associatedCellArr = cell.depend_symbol_schemas(-1);
        }

        associatedCellArr.push(cell);
        if (node.data.cell_type === 1 || node.data.cell_type === 2) {
          //引用为空的清除
          for (let i = 0; i < associatedCellArr.length; i++) {
            let refs = associatedCellArr[i].referens;
            for (let j = 0; j < refs.length; j++) {
              if (refs[j].cell === null) {
                associatedCellArr[i].remove_reference(refs[j]);
              }
            }
          }
          //和现在的图层列表比较,修改单元数据
          associatedCellArr = await contrastCellDataByLayerId(
            fileId,
            associatedCellArr
          );
          cell = associatedCellArr.find(
            (item) => item.snow_id === cell.snow_id
          );
        }
      } else {
        //cell映射表中有存储数据,直接用

        cell = getCellFromTable(cellId);
      }
      this.$store.commit("setActiveCell", cell);
      return true
    },

    // 双击打开标签跳转
    openNewTabFn(node, route, isReadOnly, query, cellInfo) {
      bus.$emit("changeMenuAndSidebar", node.data.cell_type); //改变工具栏
      this.$store.commit("setActiveNode", node); //设置激活的节点
      if (node.data.cell_type === 1) {
        bus.$emit("openLayerList", [node, route, isReadOnly, query, cellInfo]);
        bus.$emit("updateDrcVisible", true);
      } else if (node.data.cell_type === 2) {
        bus.$emit("openLayerList", [node, route, isReadOnly, query, cellInfo]);
        bus.$emit("updateDrcVisible", true);
      } else if (node.data.cell_type === 3) {
        bus.$emit("openCell", cellInfo);
        bus.$emit("updateDrcVisible", false);
        let openedTab = this.$store.state.openedTab;
        let tabNum = openedTab.findIndex((item) => item.route == route);
        setTimeout(() => {
          let routeInfo = {
            route,
            isReadOnly,
          };
          if (tabNum === -1) {
            // 该标签当前没有打开

            this.$store.commit("addTab", routeInfo);
          } else {
            // 该标签是已经打开过的，需要激活此标签页

            this.$store.commit("activeTab", routeInfo);
            bus.$emit("ending", true);
          }
          router.push({
            path: "/home/layout/board",
            query: {
              id: query,
            },
          });
          bus.$emit("fileBarIcon", "3");
          bus.$emit("addHistoryCell", node.data.snow_id);
        }, 500);
      }

      this.isReadOnly = false;
    },

    //重命名enter键
    resetNameEnter(event) {
      event.target.blur();
    },
    //重命名聚焦
    resetNameFocus(event, node) {
      this.oldNodeName = node.data.name;
    },

    //重命名失焦
    async resetNameBlur(node) {
      if (node.data.name === this.oldNodeName) {
        node.data.showInput = false;
      } else {
        let isDuplication = false;
        let list_res = {};
        if (node.level === 2) {
          if (node.data.isTeam) {
            list_res = await getTeamProjectList(
              0,
              200,
              node.parent.data.snow_id,
              node.parent.data.gns
            );
          } else {
            list_res = await getUserProjectList(0, 200);
          }
        } else if (node.level === 3) {
          list_res = await getFileList(
            0,
            200,
            node.parent.data.gns,
            node.data.isTeam ? "团队文件" : "个人文件"
          );
        } else if (node.level === 4) {
          list_res = await getCellList(
            0,
            200,
            node.parent.data.gns,
            node.data.isTeam ? "团队cell" : "个人cell"
          );
        }

        if (list_res.code === 200000) {
          for (let i in list_res.data.data) {
            if (node.level === 3) {
              if (list_res.data.data[i]._source.types === node.data.file_type) {
                if (list_res.data.data[i]._source.name === node.data.name) {
                  isDuplication = true;
                  break;
                }
              }
            } else if (node.level === 4) {
              if (node.data.cell_type === 1 || node.data.cell_type === 2) {
                if (list_res.data.data[i]._source.name === node.data.name) {
                  isDuplication = true;
                  break;
                }
              } else if (node.data.cell_type === 3) {
                if (list_res.data.data[i]._source.types === 3) {
                  if (list_res.data.data[i]._source.name === node.data.name) {
                    isDuplication = true;
                    break;
                  }
                }
              } else if (
                node.data.cell_type === 4 ||
                node.data.cell_type === 5
              ) {
                if (
                  list_res.data.data[i]._source.types === 4 ||
                  list_res.data.data[i]._source.types === 5
                ) {
                  if (list_res.data.data[i]._source.name === node.data.name) {
                    isDuplication = true;
                    break;
                  }
                }
              }
            }
          }
          if (isDuplication) {
            this.$message.error(this.$t("messages.duplication"));
            node.data.name = this.oldNodeName;
            node.data.showInput = false;
            return;
          } else {
            let params = {
              category: node.data.category,
              snow_id: node.data.snow_id,
              name: node.data.name,
              parent_gns: node.parent.data.gns,
              project_gns: node.data.gns,
              user_type: node.data.isTeam ? "team" : "user",
              type: node.data.file_type || node.data.cell_type,
            };
            let rename_res = await renameFileName(params);
            if (rename_res.code == 200000) {
              if (node.level === 2) {
                let project = await getObjectData(node.data.snow_id);
                project.name = node.data.name;
                saveProjectFn(project, node);
                node.data.showInput = false;
                this.$message.success(this.$t("messages.operationSuccess"));
              } else if (node.level === 3) {
                let file = await getObjectData(node.data.snow_id);
                file.name = node.data.name;
                saveFileFn(file, node);
                node.data.showInput = false;
                this.$message.success(this.$t("messages.operationSuccess"));
              } else if (node.level === 4) {
                let batchSaveType = ""
                let cell = await getCompleteCellData(
                  node.parent.parent.data.snow_id,
                  node.parent.data.snow_id,
                  node.data.snow_id
                );
                cell.name = node.data.name;
                if (cell.type.value === 1 || cell.type.value === 2) {
                  cell.short_name = node.data.name;
                  batchSaveType = "LAYOUT_FILE"
                } else {
                  batchSaveType = "DIAGRAM_FILE"
                }

                let saveCellBinaryDataObj = cell.dump_file(true, 0);
                let u8Array = binaryDataToUTF8(saveCellBinaryDataObj);
                // let batch_data = {};
                // batch_data[node.data.snow_id] = {
                //   folder_name: getFolderName(
                //     node.parent.parent.parent.data.snow_id,
                //     node.data.gns,
                //     node.data.isTeam
                //   ),
                //   file_type: returnFileType(node.data.cell_type),
                // };
                // batchSaveDataFn(u8Array, batch_data);
                await batchSaveDataFn_fileSize(u8Array, node.parent.parent.data.snow_id, node.parent.data.snow_id, batchSaveType, [node.data.snow_id]);
                bus.$emit("saveCellSuccess", node.data.snow_id);
                this.$message.success(this.$t("messages.operationSuccess"));
                // let cacheCell = getCellFromTable(node.data.snow_id);

                // if (cacheCell !== null) {
                //   cacheCell.name = node.data.name;
                // }
                node.data.showInput = false;

                //获取引用关系准备刷新引用数据
                await this.resetNameRefRefresh(node);
              }
              if (node.data.isTeam) {
                this.sendRenameNodeMsg(node, node.data.name);
              }
              this.isResetName = false;
            }
          }
        }
      }
    },

    //重命名刷新引用关系
    async resetNameRefRefresh(node) {
      let cellIndexData = await getCellIndexData(node.parent.data.snow_id);

      if (
        node.data.cell_type == "1" ||
        node.data.cell_type == "2" ||
        node.data.cell_type == "3"
      ) {
        if (JSON.stringify(this.$route.query) !== "{}") {
          if (node.data.snow_id == this.$route.query.id.split("/")[1]) {
            this.$store.state.cellInfo.name = node.data.name;
          }
          let params = [
            this.oldNodeName,
            node.data.name,
            node.data.snow_id,
            node.data.category,
            node.parent.data.snow_id,
          ];
          bus.$emit("resetNameFileBar", params);
        }
        if (node.data.cell_type == "3") {
          let capSymbolIdList = await getCapSymbolIdByEncapsFrom(
            cellIndexData,
            node.parent.data.gns,
            node.data.snow_id,
            node.data.isTeam
          );

          for (let i = 0; i < node.parent.childNodes.length; i++) {
            for (let j = 0; j < capSymbolIdList.length; j++) {
              if (
                node.parent.childNodes[i].data.snow_id == capSymbolIdList[j]
              ) {
                node.parent.childNodes[
                  i
                ].data.epschemaName = `(${node.data.name})`;
              }
            }
          }
        }
      }

      //所有有调用关系的也需要更新
      let parentOfCellIdList = cellcalledBy(
        cellIndexData,
        node.parent.data.snow_id,
        node.data.snow_id
      );

      for (let i = 0; i < node.parent.childNodes.length; i++) {
        for (let j = 0; j < parentOfCellIdList.length; j++) {
          if (node.parent.childNodes[i].data.snow_id == parentOfCellIdList[j]) {
            if (node.parent.childNodes[i].loaded) {
              node.parent.childNodes[i].loaded = false;
              node.parent.childNodes[i].expand();
            }
          }
        }
      }
    },

    //删除刷新引用关系
    deleteRefRefresh(node) {
      //同步刷新其他引用关系的刷新
      //获取该文件下所有已展开的节点,判断有无引用当前要删除的单元
      //有,直接刷新

      let childNodes = node.parent.childNodes;
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i].loaded) {
          for (let j = 0; j < childNodes[i].childNodes.length; j++) {
            if (
              childNodes[i].childNodes[j].data.cell_type ===
              node.data.cell_type &&
              childNodes[i].childNodes[j].data.name === node.data.name
            ) {
              childNodes[i].loaded = false;
              childNodes[i].expand();
            }
          }
        }
      }
    },

    //复制到,导入,另存为调用的列表刷新
    async refreshNode(expandData, isEnding = true) {
      let refreshCategory = expandData[0].category;
      let topData = expandData[0].topData;
      let projectData = expandData[0].projectData;
      let fileData = expandData[0].fileData;
      if (refreshCategory === "项目") {
        let topNode = this.$refs.tree.getNode(topData.topId);
        //消息服务团队节点刷新
        if (topNode.data.isTeam) {
          this.sendRefreshNodeMsg(topNode);
        }
        if (topNode.loaded) {
          topNode.loaded = false;
        }
        topNode.expand();
      } else if (refreshCategory === "文件") {
        let proNode = this.$refs.tree.getNode(projectData.snow_id);
        if (proNode == null) {
          let topNode = this.$refs.tree.getNode(topData.topId);
          if (topNode.loaded) {
            topNode.loaded = false;
          }
          topNode.expand();
          await new Promise((res) => {
            let proTimer = setInterval(() => {
              proNode = this.$refs.tree.getNode(projectData.snow_id);
              if (proNode) {
                clearInterval(proTimer);
                proNode.expand();
                setTimeout(() => {
                  if (proNode.data.isTeam) {
                    this.sendRefreshNodeMsg(proNode);
                  }
                }, 500);
                res();
              }
            }, 100);
          });
          // await new Promise((res) => {
          //   setTimeout(() => {
          //     proNode = this.$refs.tree.getNode(projectData.snow_id);
          //     proNode.expand();
          //     setTimeout(() => {
          //       if (proNode.data.isTeam) {
          //         this.sendRefreshNodeMsg(proNode);
          //       }
          //     }, 500);
          //     res();
          //   }, 500);
          // });
        } else {
          //消息服务项目节点刷新
          if (proNode.data.isTeam) {
            this.sendRefreshNodeMsg(proNode);
          }
          if (proNode.loaded) {
            proNode.loaded = false;
          }
          proNode.expand();
        }
      } else if (refreshCategory === "CELL") {
        let fileNode = this.$refs.tree.getNode(fileData.snow_id);
        if (fileNode == null) {
          let proNode = this.$refs.tree.getNode(projectData.snow_id);
          if (proNode == null) {
            let topNode = this.$refs.tree.getNode(topData.topId);
            if (topNode.loaded) {
              topNode.loaded = false;
            }
            topNode.expand();

            await new Promise((res) => {
              let proTimer = setInterval(() => {
                proNode = this.$refs.tree.getNode(projectData.snow_id);
                if (proNode) {
                  clearInterval(proTimer);
                  proNode.expand();
                  let fileTimer = setInterval(() => {
                    fileNode = this.$refs.tree.getNode(fileData.snow_id);
                    if (fileNode) {
                      clearInterval(fileTimer);
                      fileNode.expand();
                      setTimeout(() => {
                        if (fileNode.data.isTeam) {
                          this.sendRefreshNodeMsg(fileNode);
                        }
                      }, 500);
                      res();
                    }
                  }, 100);
                }
              }, 100);
            });

            // await new Promise((res) => {
            //   setTimeout(async () => {
            //     proNode = this.$refs.tree.getNode(projectData.snow_id);
            //     proNode.expand();
            //     await new Promise((res2) => {
            //       setTimeout(() => {
            //         fileNode = this.$refs.tree.getNode(fileData.snow_id);
            //         fileNode.expand();
            //         setTimeout(() => {
            //           if (fileNode.data.isTeam) {
            //             this.sendRefreshNodeMsg(fileNode);
            //           }
            //         }, 500);
            //         res2();
            //       }, 500);
            //     });
            //     res();
            //   }, 500);
            // });
          } else {
            if (proNode.loaded) {
              proNode.loaded = false;
            }
            proNode.expand();
            await new Promise((res) => {
              let fileTimer = setInterval(() => {
                fileNode = this.$refs.tree.getNode(fileData.snow_id);
                if (fileNode) {
                  clearInterval(fileTimer);
                  fileNode.expand();
                  setTimeout(() => {
                    if (fileNode.data.isTeam) {
                      this.sendRefreshNodeMsg(fileNode);
                    }
                  }, 500);
                  res();
                }
              }, 100);
            });

            // await new Promise((res) => {
            //   setTimeout(() => {
            //     fileNode = this.$refs.tree.getNode(fileData.snow_id);
            //     fileNode.expand();
            //     setTimeout(() => {
            //       if (fileNode.data.isTeam) {
            //         this.sendRefreshNodeMsg(fileNode);
            //       }
            //     }, 500);
            //     res();
            //   }, 500);
            // });
          }
        } else {
          //消息服务文件节点刷新
          if (fileNode.data.isTeam) {
            this.sendRefreshNodeMsg(fileNode);
          }
          if (fileNode.loaded) {
            fileNode.loaded = false;
          }
          fileNode.expand();
        }
      }
      if (isEnding) {
        bus.$emit("ending", true);
      }
    },

    //新建节点刷新方法
    async createNewCellRefreshTreeNodeFn() {
      let topNode = {}; //目标顶层节点
      let topId = this.expandTopData.topId;
      let createFromHeader = this.expandTopData.createFromHeader;
      let projectNode = {}; //目标项目节点
      let projectIndex = -1;
      let fileNode = {}; //目标文件节点
      let fileIndex = -1;
      let currentProjectNode = { data: { snow_id: "" } }; //右键节点的项目节点
      let currentFileNode = { data: { snow_id: "" } }; //右键节点的文件节点
      if (createFromHeader) {
        if (this.treeNameList.length == 0) {
          this.treeNameList.push(new UserNode(this.userName, "", "", "", ""));
          this.createButtonVisible = false;
          this.noFileListData = false;
          this.treeVisible = true;
          await new Promise((res) => {
            setTimeout(async () => {
              topNode = this.$refs.tree.getNode("");
              this.node = topNode;
              await new Promise((res2) => {
                setTimeout(() => {
                  topNode.expand();
                  res2();
                }, 500);
              });
              res();
            }, 500);
          });
        } else {
          topNode = this.$refs.tree.getNode(topId);
          this.node = topNode;
          await new Promise((res2) => {
            setTimeout(() => {
              topNode.expand();
              res2();
            }, 500);
          });
        }
      }
      //判断顶层节点是否存在,是否打开过
      //先判断项目是否存在,是否打开过
      //判断文件是否存在,是否打开过
      if (this.node.data.category == "") {
        currentProjectNode = this.$refs.tree.getNode(
          this.expandProjectData.snow_id
        );
        if (currentProjectNode === null) {
          this.expandAddProjectNode(topNode);
          await new Promise((res3) => {
            let proTimer = setInterval(() => {
              currentProjectNode = this.$refs.tree.getNode(
                this.expandProjectData.snow_id
              );
              if (currentProjectNode) {
                clearInterval(proTimer);
                res3();
              }
            }, 100);
          });

          // await new Promise((res3) => {
          //   setTimeout(() => {
          //     currentProjectNode = this.$refs.tree.getNode(this.expandProjectData.snow_id);
          //     res3();
          //   }, 500);
          // });
        }
      } else if (this.node.data.category == "项目") {
        currentProjectNode = this.node;
      } else if (this.node.data.category == "文件") {
        currentProjectNode = this.node.parent;
        currentFileNode = this.node;
      } else if (this.node.data.category == "CELL") {
        currentProjectNode = this.node.parent.parent;
        currentFileNode = this.node.parent;
      }

      if (this.expandProjectData.snow_id !== currentProjectNode.data.snow_id) {
        topNode = currentProjectNode.parent;
        //项目是否存在
        projectIndex = topNode.childNodes.findIndex(
          (item) => item.data.snow_id === this.expandProjectData.snow_id
        );
        if (projectIndex !== -1) {
          //项目已存在
          //项目是否展开过
          projectNode = topNode.childNodes[projectIndex];
          if (projectNode.loaded) {
            //项目展开过的
            //文件是否存在

            fileIndex = projectNode.childNodes.findIndex(
              (item) => item.data.snow_id === this.expandFileData.snow_id
            );
            if (fileIndex !== -1) {
              //文件已存在

              fileNode = projectNode.childNodes[fileIndex];
              if (fileNode.loaded) {
                //展开过的

                this.expandAddCellNode(fileNode);
                if (this.isCreateNewFile) {
                  //是新建文件,自动打开

                  this.autoOpenCellFn();
                  this.isCreateNewFile = false;
                }
              } else {
                //未展开的,直接加载

                fileNode.expand();
              }
            } else {
              //文件不存在,创建文件节点,创建单元节点

              this.expandAddFileNode(projectNode);

              fileNode = this.$refs.tree.getNode(this.expandFileData.snow_id);

              fileNode.expand();
              // setTimeout(() => {
              //   this.expandAddCellNode(fileNode)
              // }, 500);
            }
          } else {
            //未展开的

            projectNode.expand();
            this.expandProNode = projectNode;
            this.refreshFileNode = true;
          }
        } else {
          //项目不存在,创建项目节点

          this.expandAddProjectNode(topNode);
          projectNode = this.$refs.tree.getNode(this.expandProjectData.snow_id);
          projectNode.expand();
          this.expandProNode = projectNode;
          this.refreshFileNode = true;
        }
        //文件是否展开过
      } else if (
        this.expandProjectData.snow_id === currentProjectNode.data.snow_id &&
        this.expandFileData.snow_id !== currentFileNode.data.snow_id
      ) {
        projectNode = currentProjectNode;

        if (projectNode.loaded) {
          //项目展开过的
          //文件是否存在

          fileIndex = projectNode.childNodes.findIndex(
            (item) => item.data.snow_id === this.expandFileData.snow_id
          );
          if (fileIndex !== -1) {
            //文件已存在

            fileNode = projectNode.childNodes[fileIndex];
            if (fileNode.loaded) {
              //展开过的

              this.expandAddCellNode(fileNode);
              if (this.isCreateNewFile) {
                //是新建文件,自动打开

                this.autoOpenCellFn();
                this.isCreateNewFile = false;
              }
            } else {
              //未展开的,直接加载

              fileNode.expand();
            }
          } else {
            //文件不存在,创建文件节点,创建单元节点

            this.expandAddFileNode(projectNode);

            fileNode = this.$refs.tree.getNode(this.expandFileData.snow_id);

            fileNode.expand();
          }
        } else {
          //未展开的

          projectNode.expand();
          this.expandProNode = projectNode;
          this.refreshFileNode = true;
        }
      } else {
        fileNode = currentFileNode;
        if (fileNode.loaded) {
          //展开过的

          this.expandAddCellNode(fileNode);
          if (this.isCreateNewFile) {
            //是新建文件,自动打开

            this.autoOpenCellFn();
            this.isCreateNewFile = false;
          }
        } else {
          //未展开的,直接加载

          fileNode.expand();
        }
      }
    },

    //新建项目节点
    addProjectNodeFn(projectData, topNode) {
      this.$refs.tree.append(projectData, topNode);
    },

    //展开列表新建文件节点
    expandAddProjectNode(topNode) {
      let projectData = {};
      if (this.expandProjectData.isTeam) {
        projectData = new TeamProjectNode(
          this.expandProjectData.name,
          this.expandProjectData.snow_id,
          this.expandProjectData.gns,
          "项目",
          "团队项目"
        );
      } else {
        projectData = new UserProjectNode(
          this.expandProjectData.name,
          this.expandProjectData.snow_id,
          this.expandProjectData.gns,
          "项目",
          "个人项目"
        );
      }
      this.addProjectNodeFn(projectData, topNode);
    },

    // 新建文件节点
    addFileNodeFn(fileData, projectNode) {
      this.$refs.tree.append(fileData, projectNode);
    },

    //展开列表新建文件节点
    expandAddFileNode(projectNode) {
      let fileData = {};
      if (this.expandFileData.isTeam) {
        fileData = new TeamFileNode(
          this.expandFileData.name,
          this.expandFileData.snow_id,
          this.expandFileData.gns,
          "文件",
          "团队文件",
          this.expandFileData.file_type
        );
      } else {
        fileData = new UserFileNode(
          this.expandFileData.name,
          this.expandFileData.snow_id,
          this.expandFileData.gns,
          "文件",
          "个人文件",
          this.expandFileData.file_type
        );
      }

      this.addFileNodeFn(fileData, projectNode);
    },

    // 新建单元节点
    addCellNodeFn(cellData, fileNode) {
      this.$refs.tree.append(cellData, fileNode);
    },

    //展开列表新建单元节点
    expandAddCellNode(fileNode) {
      let cellData = {};

      if (this.expandDataArr.cellData.isTeam) {
        if (
          this.expandDataArr.cellData.cell_type === 1 ||
          this.expandDataArr.cellData.cell_type === 2
        ) {
          cellData = new TeamLayoutCellNode(
            this.expandDataArr.cellData.name,
            this.expandDataArr.cellData.snow_id,
            this.expandDataArr.cellData.gns,
            "CELL",
            "团队cell",
            this.expandDataArr.cellData.cell_type,
            false
          );
        } else if (this.expandDataArr.cellData.cell_type === 3) {
          cellData = new TeamSchemaCellNode(
            this.expandDataArr.cellData.name,
            this.expandDataArr.cellData.snow_id,
            this.expandDataArr.cellData.gns,
            "CELL",
            "团队cell",
            3,
            false,
            ""
          );
        }
      } else {
        if (
          this.expandDataArr.cellData.cell_type === 1 ||
          this.expandDataArr.cellData.cell_type === 2
        ) {
          cellData = new UserLayoutCellNode(
            this.expandDataArr.cellData.name,
            this.expandDataArr.cellData.snow_id,
            this.expandDataArr.cellData.gns,
            "CELL",
            "个人cell",
            this.expandDataArr.cellData.cell_type,
            false
          );
        } else if (this.expandDataArr.cellData.cell_type === 3) {
          cellData = new UserSchemaCellNode(
            this.expandDataArr.cellData.name,
            this.expandDataArr.cellData.snow_id,
            this.expandDataArr.cellData.gns,
            "CELL",
            "个人cell",
            3,
            false,
            ""
          );
        }
      }
      this.addCellNodeFn(cellData, fileNode);
    },

    //新建用户节点
    // newUserNodeFn(time) {
    //   return new Promise((res) => {
    //     setTimeout(() => {
    //       this.node = this.$refs.tree.$children[0].node;
    //       res();
    //     }, time);
    //   });
    // },

    //项目节点展开
    // expandProNodeFn(time) {
    //   return new Promise((res) => {
    //     setTimeout(() => {
    //       this.expandTopNode.childNodes.forEach((item) => {
    //         if (item.data.name == this.expandProjectData.name) {
    //           this.expandProNode = item;
    //         }
    //       });
    //       this.expandProNode.loaded = false;
    //       this.expandProNode.expand();

    //       res();
    //     }, time);
    //   });
    // },

    //文件节点展开
    expandFileNodeFn(time) {
      return new Promise((res) => {
        setTimeout(() => {
          this.expandProNode.childNodes.forEach((item) => {
            if (item.data.snow_id == this.expandFileData.snow_id) {
              this.expandFileNode = item;
            }
          });

          this.expandFileNode.loaded = false;
          this.expandFileNode.expand();

          res();
        }, time);
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.file {
  width: 98%;
  height: 100%;
  // min-height: 205px;
  display: flex;
  position: relative;
  // overflow-y: hidden;
  flex-direction: column;
  // background-color: #e9e9e9;
  overflow-y: scroll;

  // 布局
  > div {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    // height: 50%;
  }
  // 上半部分
  .top-half {
    position: relative;
    overflow-y: auto;
    flex: 1;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    /deep/.el-tree__empty-text {
      color: #fff;
    }
  }

  //搜索框
  .searchAll {
    padding: 20px 10px 10px;
    /deep/.el-input__inner {
      width: 196px;
      height: 26px;
      line-height: 26px;
      background: #fff !important;
    }
    /deep/.el-input__inner::placeholder {
      color: #999999 !important;
    }
    /deep/.el-input__icon {
      line-height: 26px;
    }
  }

  //新建按钮
  /deep/.el-button--primary.is-plain {
    position: absolute;
    top: 70px;
    left: 55px;
    width: 110px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    background-color: #ecf5ff;
    border: 1px solid #0c7de6;
    color: #0c7de6;
    cursor: pointer;
    z-index: 1000;
    span {
      display: inline-block;
      line-height: 28px;
    }
  }
  /deep/.el-button--primary.is-plain:hover,
  /deep/.el-button--primary.is-plain:focus {
    background: #ecf5ff;
    color: #409eff;
    cursor: pointer;
  }
}

//树形列表
.tree-line {
  font-size: 14px;
  i {
    padding-right: 5px;
  }
  /deep/.el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  /deep/.el-tree-node__children {
    padding-left: 15px; // 缩进量
  }
  /deep/.el-tree-node > .el-tree-node__children {
    overflow: visible;
  }

  // 竖线
  /deep/.el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -10px;
    top: -12px;
    border-width: 1px;
    border-left: 1px dashed #dddddd;
  }
  // 当前层最后一个节点的竖线高度固定
  /deep/.el-tree-node:last-child::before {
    height: 30px; // 可以自己调节到合适数值
  }

  // 横线
  /deep/.el-tree-node::after {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    left: -8px;
    top: 17px;
    border-width: 1px;
    border-top: 1px dashed #dddddd;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  /deep/& > .el-tree-node::after {
    border-top: none;
  }
  /deep/& > .el-tree-node::before {
    border-left: none;
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 35px;
    line-height: 35px;
    width: 100%;
    span {
      display: inline-block;
      width: 100%;
    }
  }
  .treeIcon {
    padding-right: 4px;
    position: relative;
    width: 22px;
    height: 20px;
    left: -80px;
    filter: drop-shadow(#0c7de6 80px 0);
    color: #0c7de6;
  }

  // 展开关闭的icon
  /deep/.el-tree-node__expand-icon {
    font-size: 16px;
    // 叶子节点（无子节点）
    /deep/&.is-leaf {
      color: transparent;
      // display: none; // 也可以去掉
    }
  }
  /deep/.el-tree-node__content > .el-tree-node__expand-icon {
    padding: 0 3px 0 0px;
  }
  /deep/.el-tree-node__content {
    height: 35px;
  }

  //更换图标
  /deep/.el-icon-caret-right:before {
    content: "";
    background: url("../../../assets/zhankai.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  /deep/.el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "";
    background: url("../../../assets/zhankai_1.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  //叶子结点样式
  /deep/.el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
    background: transparent;
    width: 0px;
  }
  //禁止转向
  /deep/.el-tree-node__expand-icon {
    transform: none;
  }
  /deep/.el-tree-node__expand-icon.expanded {
    transform: none;
  }
}

.resetNameInput {
  font-size: 14px;
  height: 27px;
  line-height: 27px;
  width: 137px;
  /deep/.el-input__inner {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background: #ffffff !important;
    border: 1px solid #0c7de6 !important;
    border-radius: 2px;
  }
}
</style>