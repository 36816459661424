<template>
  <div class="side">
    <!-- 原理图工具栏 -->
    <div class="schematicDiagramToolbar">
      <!-- 原理图简单编辑图标 -->
      <div class="simpleEdit_schematicDiagram">
        <div class="toolbarIcon" v-for="(item, index) in simpleEditIcon_schematicDiagram" :key="index">
          <button @click="simpleEditActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
            @mouseleave="item.isShowInfo = false">
            <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
            <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
            <div class="dropInfo" v-if="item.isShowInfo">
              <div>{{ $t('messages.' + item.cn_en) }}</div>
            </div>
          </button>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="middleLine"></div>
      <!-- 原理图绘图图标 -->
      <div class="draw_schematicDiagram">
        <div class="toolbarIcon" v-for="(item, index) in drawIcon_schematicDiagram" :key="index">
          <button @click="drawActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
            @mouseleave="item.isShowInfo = false">
            <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
            <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
            <div class="dropInfo" v-if="item.isShowInfo">
              <div>{{ $t('messages.' + item.cn_en) }}</div>
            </div>
          </button>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="middleLine"></div>
      <!-- 原理图复杂操作图标  -->
      <div class="complexEditIcon_schematicDiagram">
        <div class="toolbarIcon" v-for="(item, index) in complexEditIcon_schematicDiagram" :key="index">
          <button @click="complexEditActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
            @mouseleave="item.isShowInfo = false">
            <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
            <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
            <div class="dropInfo" v-if="item.isShowInfo">
              <div>{{ $t('messages.' + item.cn_en) }}</div>
            </div>
          </button>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="middleLine"></div>
      <!-- 原理图图形操作图标 -->
      <div class="graphicOperation_schematicDiagram">
        <div class="toolbarIcon" v-for="(item, index) in graphicOperation_schematicDiagram" :key="index">
          <button @click="graphicOperationActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
            @mouseleave="item.isShowInfo = false">
            <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
            <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
            <div class="dropInfo" v-if="item.isShowInfo">
              <div>{{ $t('messages.' + item.cn_en) }}</div>
            </div>
          </button>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="middleLine"></div>
      <!-- 高亮控制图标 -->
      <div class="highlight_schematicDiagram">
        <div class="toolbarIcon" v-for="(item, index) in highlight_schematicDiagram" :key="index">
          <button @click="highlightActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
            @mouseleave="item.isShowInfo = false">
            <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
            <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
            <div class="dropInfo" v-if="item.isShowInfo">
              <div>{{ $t('messages.' + item.cn_en) }}</div>
            </div>
          </button>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="middleLine"></div>
      <!-- 坐标 -->
      <div class="coordinate_schematicDiagram" v-if="showCoordinate">
        <span style="padding-left: 10px">X:</span>
        <span style="padding-right: 12px; min-width: 40px">{{ x }}</span>
        <span>Y:</span>
        <span style="min-width: 40px">{{ y }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "../../common/bus";
export default {
  props: {
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      //只读
      isReadOnly: false,
      //原理图对应工具栏
      //原理图简单编辑图标
      simpleEditIcon_schematicDiagram: [
        {
          src1: require("../../../assets/icons/sidebar/保存.svg"), //未激活图标
          src2: require("../../../assets/icons/sidebar/保存2.svg"), //激活图标
          src3: require("../../../assets/icons/sidebar/保存3.svg"), //禁用图标
          label: "保存", //提示信息
          cn_en: "save", //中英文切换
          isActive: false, //是否激活
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "save", //调用方法
        },
        {
          src1: require("../../../assets/icons/sidebar/检查.svg"),
          src2: require("../../../assets/icons/sidebar/检查2.svg"),
          label: "检查",
          cn_en: "checkSchematicDiagram",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "check",
        },
        {
          src1: require("../../../assets/icons/sidebar/封装.svg"),
          src2: require("../../../assets/icons/sidebar/封装2.svg"),
          label: "封装",
          cn_en: "encapsulation",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "encapsulation",
        },
        {
          src1: require("../../../assets/icons/sidebar/剪切.svg"),
          src2: require("../../../assets/icons/sidebar/剪切2.svg"),
          src3: require("../../../assets/icons/sidebar/剪切3.svg"),
          label: "剪切",
          cn_en: "cut",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "cut",
        },
        {
          src1: require("../../../assets/icons/sidebar/复制.svg"),
          src2: require("../../../assets/icons/sidebar/复制2.svg"),
          label: "复制",
          cn_en: "copy",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "copy",
        },
        {
          src1: require("../../../assets/icons/sidebar/粘贴.svg"),
          src2: require("../../../assets/icons/sidebar/粘贴2.svg"),
          src3: require("../../../assets/icons/sidebar/粘贴3.svg"),
          label: "粘贴",
          cn_en: "paste",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "paste",
        },
        {
          src1: require("../../../assets/icons/sidebar/撤销.svg"),
          src2: require("../../../assets/icons/sidebar/撤销2.svg"),
          src3: require("../../../assets/icons/sidebar/撤销3.svg"),
          label: "撤销",
          cn_en: "undo",
          isActive: false,
          btnDisabled: true,
          isShowInfo: false,
          func: "undo",
        },
        {
          src1: require("../../../assets/icons/sidebar/恢复.svg"),
          src2: require("../../../assets/icons/sidebar/恢复2.svg"),
          src3: require("../../../assets/icons/sidebar/恢复3.svg"),
          label: "恢复",
          cn_en: "redo",
          isActive: false,
          btnDisabled: true,
          isShowInfo: false,
          func: "redo",
        },
      ],
      //原理图绘图图标
      drawIcon_schematicDiagram: [
        {
          src1: require("../../../assets/icons/sidebar/耦合线.svg"),
          src2: require("../../../assets/icons/sidebar/耦合线2.svg"),
          src3: require("../../../assets/icons/sidebar/耦合线3.svg"),
          label: "耦合线",
          cn_en: "CouplingLine",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "CouplingLine",
        },
        {
          src1: require("../../../assets/icons/sidebar/创建引脚.svg"),
          src2: require("../../../assets/icons/sidebar/创建引脚2.svg"),
          src3: require("../../../assets/icons/sidebar/创建引脚3.svg"),
          label: "创建引脚",
          cn_en: "CreatePin",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "CreatePin",
        },
        {
          src1: require("../../../assets/icons/sidebar/创建标签.svg"),
          src2: require("../../../assets/icons/sidebar/创建标签2.svg"),
          src3: require("../../../assets/icons/sidebar/创建标签3.svg"),
          label: "标签",
          cn_en: "createLabel",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "createLabel",
        },
      ],
      //原理图复杂操作图标
      complexEditIcon_schematicDiagram: [
        {
          src1: require("../../../assets/icons/sidebar/移动.svg"),
          src2: require("../../../assets/icons/sidebar/移动2.svg"),
          src3: require("../../../assets/icons/sidebar/移动3.svg"),
          label: "移动",
          cn_en: "Move",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "move",
        },
        {
          src1: require('../../../assets/icons/sidebar/阵列.svg'),
          src2: require('../../../assets/icons/sidebar/阵列2.svg'),
          src3: require('../../../assets/icons/sidebar/阵列3.svg'),
          label: '阵列复制',
          cn_en: 'ArrayCopy',
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "matrix",
        },
      ],
      //原理图图形操作图标
      graphicOperation_schematicDiagram: [
        {
          src1: require("../../../assets/icons/sidebar/顺时针旋转90度.svg"),
          src2: require("../../../assets/icons/sidebar/顺时针旋转90度2.svg"),
          src3: require("../../../assets/icons/sidebar/顺时针旋转90度3.svg"),
          label: "顺时针旋转90度",
          cn_en: "RotatedClockwise",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "RotatedClockwise",
        },
        {
          src1: require("../../../assets/icons/sidebar/逆时针旋转90度.svg"),
          src2: require("../../../assets/icons/sidebar/逆时针旋转90度2.svg"),
          src3: require("../../../assets/icons/sidebar/逆时针旋转90度3.svg"),
          label: "逆时针旋转90度",
          cn_en: "RotatedCounterclockwise",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "RotatedCounterclockwise",
        },
        {
          src1: require("../../../assets/icons/sidebar/左右翻转.svg"),
          src2: require("../../../assets/icons/sidebar/左右翻转2.svg"),
          src3: require("../../../assets/icons/sidebar/左右翻转3.svg"),
          label: "水平翻转",
          cn_en: "FlipHorizontal",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "FlipHorizontal",
        },
        {
          src1: require("../../../assets/icons/sidebar/上下翻转.svg"),
          src2: require("../../../assets/icons/sidebar/上下翻转2.svg"),
          src3: require("../../../assets/icons/sidebar/上下翻转3.svg"),
          label: "垂直翻转",
          cn_en: "FlipVertical",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "FlipVertical",
        },
        {
          src1: require("../../../assets/icons/sidebar/左对齐.svg"),
          src2: require("../../../assets/icons/sidebar/左对齐2.svg"),
          src3: require("../../../assets/icons/sidebar/左对齐3.svg"),
          label: "左对齐",
          cn_en: "alignLeft",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "alignLeft",
        },
        {
          src1: require("../../../assets/icons/sidebar/右对齐.svg"),
          src2: require("../../../assets/icons/sidebar/右对齐2.svg"),
          src3: require("../../../assets/icons/sidebar/右对齐3.svg"),
          label: "右对齐",
          cn_en: "alignRight",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "alignRight",
        },
        {
          src1: require("../../../assets/icons/sidebar/上对齐.svg"),
          src2: require("../../../assets/icons/sidebar/上对齐2.svg"),
          src3: require("../../../assets/icons/sidebar/上对齐3.svg"),
          label: "上对齐",
          cn_en: "alignTop",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "alignTop",
        },
        {
          src1: require("../../../assets/icons/sidebar/下对齐.svg"),
          src2: require("../../../assets/icons/sidebar/下对齐2.svg"),
          src3: require("../../../assets/icons/sidebar/下对齐3.svg"),
          label: "下对齐",
          cn_en: "alignBottom",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "alignBottom",
        },
        {
          src1: require("../../../assets/icons/sidebar/横向中线对齐.svg"),
          src2: require("../../../assets/icons/sidebar/横向中线对齐2.svg"),
          src3: require("../../../assets/icons/sidebar/横向中线对齐3.svg"),
          label: "横向中线对齐",
          cn_en: "alignHorizontalCenter",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "alignHorizontalCenter",
        },
        {
          src1: require("../../../assets/icons/sidebar/竖向中线对齐.svg"),
          src2: require("../../../assets/icons/sidebar/竖向中线对齐2.svg"),
          src3: require("../../../assets/icons/sidebar/竖向中线对齐3.svg"),
          label: "竖向中线对齐",
          cn_en: "alignVerticalCenter",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "alignVerticalCenter",
        },
      ],
      //原理图高亮控制图标
      highlight_schematicDiagram: [
        {
          src1: require("../../../assets/icons/sidebar/高亮网络.svg"),
          src2: require("../../../assets/icons/sidebar/高亮网络2.svg"),
          src3: require("../../../assets/icons/sidebar/高亮网络3.svg"),
          label: "高亮网络",
          cn_en: "HighlightNetwork",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "HighlightNetwork",
        },
        {
          src1: require("../../../assets/icons/sidebar/移除网络.svg"),
          src2: require("../../../assets/icons/sidebar/移除网络2.svg"),
          src3: require("../../../assets/icons/sidebar/移除网络3.svg"),
          label: "移除高亮",
          cn_en: "RemoveHighlights",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "RemoveHighlights",
        },
      ],
      isDraw: false,
      x: 0,
      y: 0,
      showCoordinate: true,
      FileActive: true,
      batchCreateCell: false, //是否添加了器件需要批量创建cell
    };
  },
  mounted() {
    let that = this;
    this.isReadOnly = that.readOnly;

    bus.$on("getMousePoint", (data) => {
      that.x = data[0];
      that.y = data[1];
    });
    bus.$on("getCurrentLayout", (data) => {
      if (data !== null) {
        let redoLen = data.editHistory.redoStack.length;
        let undoLen = data.editHistory.undoStack.length;
        // let redoLen = data.HISTORY.redoStack.length
        // let undoLen = data.HISTORY.undoStack.length
        if (redoLen === 0) {
          that.simpleEditIcon_schematicDiagram[7].btnDisabled = true;
        } else if (redoLen > 0) {
          that.simpleEditIcon_schematicDiagram[7].btnDisabled = false;
        }
        if (undoLen === 0) {
          that.simpleEditIcon_schematicDiagram[6].btnDisabled = true;
        } else if (undoLen > 0) {
          that.simpleEditIcon_schematicDiagram[6].btnDisabled = false;
        }
        // let redoLen = data.HISTORY.redoStack.length
        // let undoLen = data.HISTORY.undoStack.length
        if (redoLen === 0) {
          that.simpleEditIcon_schematicDiagram[7].btnDisabled = true;
        } else if (redoLen > 0) {
          that.simpleEditIcon_schematicDiagram[7].btnDisabled = false;
        }
        if (undoLen === 0) {
          that.simpleEditIcon_schematicDiagram[6].btnDisabled = true;
        } else if (undoLen > 0) {
          that.simpleEditIcon_schematicDiagram[6].btnDisabled = false;
        }
      }
    });

    bus.$on("escSchema", (data) => {
      that.cancelSelectAll();
    });

    bus.$on("schemaSiderBarLineActive", (data) => {
      that.cancelSelectAll();
      that.drawIcon_schematicDiagram[0].isActive = true;
    });
    bus.$on("schemaSiderBarPinActive", (data) => {
      that.cancelSelectAll();
      that.drawIcon_schematicDiagram[1].isActive = true;
    });
    bus.$on("schemaSiderBarLabelActive", (data) => {
      that.cancelSelectAll();
      that.drawIcon_schematicDiagram[2].isActive = true;
    });
    bus.$on("moveHighlight", (data) => {
      that.cancelSelectAll();
      that.highlight_schematicDiagram[1].isActive = true;
    });
    bus.$on("netHighlight", (data) => {
      that.cancelSelectAll();
      that.highlight_schematicDiagram[0].isActive = true;
    });
    bus.$on("cancelSchemaSidebarSelect", (data) => {
      this.cancelSelectAll();
    });

    document.addEventListener("keydown", this.keyDown);
  },
  computed: {
    cellInfo() {
      return JSON.parse(JSON.stringify(this.$store.state.cellInfo));
    },
  },
  beforeDestroy() {
    bus.$off("openCoordinate");
    bus.$off("getMousePoint");
    bus.$off("boardDesign");
    bus.$off("escSchema");
    bus.$off("getCurrentLayout");
    bus.$off("schemaSiderBarLineActive");
    bus.$off("schemaSiderBarPinActive");
    bus.$off("schemaSiderBarLabelActive");
    bus.$off("moveHighlight");
    bus.$off("netHighlight");
    bus.$off("cancelSchemaSidebarSelect");
    document.removeEventListener("keydown", this.keyDown);
  },
  methods: {
    keyDown(e) {
      let that = this;
      if (that.$store.state.cellInfo.cell_type == "3") {
        const key = e.key.toLowerCase();
        let isInput = false;
        let objs = document.querySelectorAll("div[aria-label]"); //''对象编辑
        //判断弹窗是否打开
        objs.forEach((obj) => {
          if (obj.getAttribute("role") == "dialog") {
            if (obj.parentNode.style.display !== "none") {
              isInput = true;
            }
          }
        });
        if (!isInput) {
          if (
            key == "q" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            bus.$emit("openSchematicAttrsDialog", true);
          }
          if (
            key == "s" &&
            e.shiftKey === false &&
            e.ctrlKey === true &&
            e.altKey === false
          ) {
            e.preventDefault();
            bus.$emit("saveFile", true);
          }
        }
      }
    },

    //简单操作
    simpleEditActiveFn(item, index) {
      if (!item.btnDisabled) {
        this.simpleEditIcon_schematicDiagram[index].isActive = true;
        if (this.simpleEditIcon_schematicDiagram[index].func == "save") {
          bus.$emit("saveFile", true);
        } else if (
          this.simpleEditIcon_schematicDiagram[index].func == "check"
        ) {
          let project_id = this.$store.state.proInfo.projectId;
          this.$store.commit("getShemaCheckSettings", project_id);
          let tableData = this.$store.state.currentSchemaCheckSettings;
          bus.$emit("checkSchema", tableData);
          this.cancelSelectAll();
        } else if (
          this.simpleEditIcon_schematicDiagram[index].func == "encapsulation"
        ) {
          bus.$emit("openEncapsulation", true);
          this.cancelSelectAll();
        } else if (this.simpleEditIcon_schematicDiagram[index].func == "cut") {
          if (this.isDraw) {
            this.cancelSelectAll();
          } else {
            bus.$emit("cut", true);
          }
        } else if (this.simpleEditIcon_schematicDiagram[index].func == "copy") {
          if (this.isDraw) {
            this.cancelSelectAll();
          } else {
            bus.$emit("copy", true);
          }
        } else if (
          this.simpleEditIcon_schematicDiagram[index].func == "paste"
        ) {
          if (this.isDraw) {
            this.cancelSelectAll();
          } else {
            bus.$emit("paste", true);
          }
        } else if (this.simpleEditIcon_schematicDiagram[index].func == "undo") {
          bus.$emit("undo", true);
        } else if (this.simpleEditIcon_schematicDiagram[index].func == "redo") {
          bus.$emit("redo", true);
        }
        setTimeout(() => {
          this.cancelSelectAll();
        }, 200);
      }
    },
    //绘图操作
    drawActiveFn(item, index) {
      if (!item.btnDisabled) {
        this.isDraw = true;
        bus.$emit("siderBarDrawActive", true);
        if (this.drawIcon_schematicDiagram[index].func == "CouplingLine") {
          this.cancelSelectAll();
          this.drawIcon_schematicDiagram[0].isActive = true;
          bus.$emit("selectCouplingLine", true);
        } else if (this.drawIcon_schematicDiagram[index].func == "CreatePin") {
          bus.$emit("openCreatePin", true);
        } else if (
          this.drawIcon_schematicDiagram[index].func == "createLabel"
        ) {
          bus.$emit("openLabel", true);
        }
      }
    },
    //复杂操作
    complexEditActiveFn(item, index) {
      if (!item.btnDisabled) {
        if (this.isDraw) {
          this.cancelSelectAll();
        } else {
          this.cancelSelectAll();
          this.complexEditIcon_schematicDiagram[index].isActive = true;
          if (this.complexEditIcon_schematicDiagram[index].func == "move") {
            bus.$emit("move", true);
          } else if (
            this.complexEditIcon_schematicDiagram[index].func == "matrix"
          ) {
            bus.$emit("matrixDialog", true);
          }
        }
      }
    },
    //图形操作
    graphicOperationActiveFn(item, index) {
      if (!item.btnDisabled) {
        if (this.isDraw) {
          this.cancelSelectAll();
        } else {
          this.cancelSelectAll();
          this.graphicOperation_schematicDiagram[index].isActive = true;
          if (
            this.graphicOperation_schematicDiagram[index].func ==
            "RotatedClockwise"
          ) {
            bus.$emit("RotatedClockwise", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func ==
            "RotatedCounterclockwise"
          ) {
            bus.$emit("RotatedCounterclockwise", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func ==
            "FlipHorizontal"
          ) {
            bus.$emit("FlipHorizontal", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func == "FlipVertical"
          ) {
            bus.$emit("FlipVertical", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func == "alignLeft"
          ) {
            bus.$emit("alignLeft", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func == "alignRight"
          ) {
            bus.$emit("alignRight", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func == "alignTop"
          ) {
            bus.$emit("alignTop", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func == "alignBottom"
          ) {
            bus.$emit("alignBottom", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func ==
            "alignHorizontalCenter"
          ) {
            bus.$emit("alignHorizontalCenter", true);
          } else if (
            this.graphicOperation_schematicDiagram[index].func ==
            "alignVerticalCenter"
          ) {
            bus.$emit("alignVerticalCenter", true);
          }
          setTimeout(() => {
            this.cancelSelectAll();
          }, 200);
        }
      }
    },
    // 高亮操作
    highlightActiveFn(item, index) {
      if (!item.btnDisabled) {
        this.cancelSelectAll();
        this.highlight_schematicDiagram[index].isActive = true;
        if (this.highlight_schematicDiagram[index].func == "HighlightNetwork") {
          bus.$emit("HighlightNetwork", true);
        } else if (
          this.highlight_schematicDiagram[index].func == "RemoveHighlights"
        ) {
          bus.$emit("RemoveHighlights", true);
        }
      }
    },
    //取消所有图标的激活状态,进入选择模式
    cancelSelectAll() {
      this.simpleEditIcon_schematicDiagram.forEach((item) => {
        item.isActive = false;
      });
      this.drawIcon_schematicDiagram.forEach((item) => {
        item.isActive = false;
      });
      this.complexEditIcon_schematicDiagram.forEach((item) => {
        item.isActive = false;
      });
      this.graphicOperation_schematicDiagram.forEach((item) => {
        item.isActive = false;
      });
      this.highlight_schematicDiagram.forEach((item) => {
        item.isActive = false;
      });
      this.isDraw = false;
    },
  },
  watch: {
    cellInfo(val) {
      this.isReadOnly = val.isReadOnly;
    },
    isReadOnly(val) {
      if (val) {
        this.simpleEditIcon_schematicDiagram.forEach((item) => {
          if (
            item.func !== "check" &&
            item.func !== "encapsulation" &&
            item.func !== "copy"
          ) {
            item.btnDisabled = true;
          }
        });

        this.drawIcon_schematicDiagram.forEach((item) => {
          item.btnDisabled = true;
        });
        this.complexEditIcon_schematicDiagram.forEach((item) => {
          item.btnDisabled = true;
        });
        this.graphicOperation_schematicDiagram.forEach((item) => {
          item.btnDisabled = true;
        });
        this.highlight_schematicDiagram.forEach((item) => {
          item.btnDisabled = true;
        });
      } else {
        this.simpleEditIcon_schematicDiagram.forEach((item) => {
          item.btnDisabled = false;
        });
        this.drawIcon_schematicDiagram.forEach((item) => {
          item.btnDisabled = false;
        });
        this.complexEditIcon_schematicDiagram.forEach((item) => {
          item.btnDisabled = false;
        });
        this.graphicOperation_schematicDiagram.forEach((item) => {
          item.btnDisabled = false;
        });
        this.highlight_schematicDiagram.forEach((item) => {
          item.btnDisabled = false;
        });
      }
    },
  },
};
</script>
<style>
/* 文字提示 */
.el-tooltip__popper.is-dark {
  background-color: rgb(189, 185, 185) !important;
  color: #fff !important;
}
</style>
<style lang="less" scoped>
.side {
  width: 100%;
  height: 30px;
  user-select: none;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: flex-start;
  .schematicDiagramToolbar {
    font-size: 12px;
    width: 100%;
    height: 30px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.17);
    z-index: 999;
    background: #ffffff;
    display: flex;
    align-items: center;
    .simpleEdit_layout,
    .simpleEdit_schematicDiagram,
    .boardProperty_layout,
    .precisionAdjustment,
    .draw_layout,
    .draw_schematicDiagram,
    .complexEdit_layout,
    .complexEdit_schematicDiagram,
    .graphicOperation_schematicDiagram,
    .highlight_schematicDiagram,
    .coordinate_layout,
    .coordinate_schematicDiagram {
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
    }
    .precisionAdjustment {
      .inputArea {
        width: 100%;
      }
    }
    .inputArea {
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 50px;
        margin-right: 5px;
        .el-input__inner {
          background-color: #ffffff !important;
          height: 24px;
          width: 50px;
          text-align: center;
          padding: 0;
        }
      }
    }
    /deep/.el-checkbox__label {
      font-size: 12px;
      padding: 0 5px;
    }
    /deep/.el-checkbox__input {
      padding-left: 5px;
    }
    .toolbarIcon {
      margin: 0 5px;
      display: inline-block;
      position: relative;
      height: 30px;
      line-height: 30px;
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        height: 30px;
        display: flex;
        width: 24px;
        align-items: center;
        // display: flex;
        // align-items: center;
        justify-content: center;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .dropInfo {
        z-index: 1000;
        position: absolute;
        // display: inline-block;
        top: 30px;
        left: 0;
        height: 24px;
        white-space: nowrap;
        padding: 0 10px;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
        background-color: #ffffff;
        display: flex;
        align-items: center;
        div {
          display: inline-block;
        }
      }
      .dropMenu {
        position: absolute;
        top: 30px;
        left: 0;
        width: 111px;
        height: 57px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
        background-color: #ffffff;
        .dropItem {
          height: 57px;
          width: 101px;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .dropItem:hover {
          background-color: #d7e1f2;
          color: #0c7de6;
          height: 57px;
          width: 101px;
        }
      }
    }
  }
}
.middleLine {
  width: 1px;
  height: 25px;
  background: #999999;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
