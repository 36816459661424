<template>
  <div class="encapsulation">
    <el-dialog :modal="false" :title="$t('messages.encapsulation')" :visible.sync="encapsulationDialogVisible"
      :close-on-click-modal="false" :before-close="closeDialog">
      <el-form :model="encapsulationSignRuleForm" :rules="rules" ref="encapsulationSignRuleForm" class="demo-ruleForm"
        @submit.native.prevent>
        <div class="bodyBox">
          <div class="encapsulationInfo">
            <!-- 符号名称 -->
            <div class="inputArea">
              <span class="inputInfo">{{ $t('messages.LabelName') }}:</span>
              <el-form-item prop="form_signName">
                <el-input v-model="encapsulationSignRuleForm.form_signName"
                  :placeholder="$t('messages.pleaseEnterSignName')" :maxlength="50"></el-input>
              </el-form-item>
            </div>

            <!-- 引脚 -->
            <div class="pinBox">
              <div class="inputInfo">
                <span>{{ $t('messages.Pin') }}:</span>
              </div>
              <!-- 上 -->
              <div class="pinInfo">
                <!-- 上 -->
                <div class="pinInputArea">
                  <span class="pinInputInfo">{{ $t('messages.Up') }}:</span>
                  <el-form-item prop="form_pinUp">
                    <el-input type="textarea" @blur="update2D" autosize v-model="encapsulationSignRuleForm.form_pinUp"
                      :placeholder="$t('messages.placeholder_5')"></el-input>
                  </el-form-item>
                </div>
                <!-- 左 -->
                <div class="pinInputArea">
                  <span class="pinInputInfo">{{ $t('messages.Left') }}:</span>
                  <el-form-item prop="form_pinLeft">
                    <el-input type="textarea" @blur="update2D" autosize v-model="encapsulationSignRuleForm.form_pinLeft"
                      :placeholder="$t('messages.placeholder_5')"></el-input>
                  </el-form-item>
                </div>
                <!-- 右 -->
                <div class="pinInputArea">
                  <span class="pinInputInfo">{{ $t('messages.Right') }}:</span>
                  <el-form-item prop="form_pinRight">
                    <el-input type="textarea" @blur="update2D" autosize
                      v-model="encapsulationSignRuleForm.form_pinRight"
                      :placeholder="$t('messages.placeholder_5')"></el-input>
                  </el-form-item>
                </div>

                <!-- 下 -->
                <div class="pinInputArea">
                  <span class="pinInputInfo">{{ $t('messages.Down') }}:</span>
                  <el-form-item prop="form_pinDown">
                    <el-input type="textarea" @blur="update2D" autosize v-model="encapsulationSignRuleForm.form_pinDown"
                      :placeholder="$t('messages.placeholder_5')"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="encapsulation2D">
            <div class="encapsulation2DTitle">{{ $t('messages.tool2dImage') }}</div>
            <div class="imgBox">
              <canvas id="encapsulation2D_canvas" @mousewheel="mouseWheel($event)" @mousedown="mouseDownCanvas($event)"
                @mousemove="mouseMoveCanvas($event)" @mouseup="mouseUpCanvas($event)"></canvas>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="encapsulationDialogSureBtn" size="mini" class="deactivate"
          ref="encapsulationDialogBtn" disabled>{{ $t('messages.sure') }}</el-button>
        <el-button @click="encapsulationDialogCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import { QedaSchemaStage } from "@/utils/graphic_schema/qeda-schema-stage";
import { SchemaDisplayConfig } from "@/utils/graphic_schema/render-util";
import QedaSchemaAxis from "@/utils/graphic_schema/qeda-schema-axis";
import { buildSymbolPath } from "@/utils/graphic_schema/layout/layout-util";
export default {
  data() {
    var signNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    return {
      encapsulationDialogVisible: false, //封装弹窗显示
      encapsulationSignRuleForm: {
        //封装弹窗表单数据
        form_signName: "",
        form_pinUp: "",
        form_pinLeft: "",
        form_pinRight: "",
        form_pinDown: "",
      },

      //输入校验
      rules: {
        form_signName: [{ validator: signNameRule, trigger: "blur" }],
      },

      axis: {},
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      stage: {},
      config: {},
      currentSchema: {},
      currentProject: {},
      capSymbol: {},
      oldPinUp: "",
      oldPinLeft: "",
      oldPinRight: "",
      oldPinDown: "",
    };
  },
  methods: {
    get2DCanvas(schema, symbol, width, height) {
      this.axis = new QedaSchemaAxis(width, height, 1.0e-6, 1.0e-9);
      this.axis.rulerX.width = width;
      this.axis.rulerY.height = height;
      this.config = new SchemaDisplayConfig();
      let data = this.getCenterTranFn(symbol.bounding_box, width, height);
      this.axis.scale = data.scale;
      this.axis.dx = data.dx;
      this.axis.dy = data.dy;
      this.axis.updateValue();
      buildSymbolPath(symbol);
      schema.add_symbol_ins(symbol);
      let containerId = "encapsulation2D";
      this.config.onMouseWheel(this.axis.scale);
      this.config.showGrid = false;
      this.config.showXY = false;
      setTimeout(() => {
        let canvas = document.getElementById("encapsulation2D_canvas");

        canvas.width = this.axis.width;
        canvas.height = this.axis.height;
        this.stage = new QedaSchemaStage(
          this.axis,
          containerId,
          schema,
          this.config
        );
        this.stage.render();
      }, 0);
    },
    mouseDownCanvas(event) {
      this.axis.setDrag(true, 1);
    },
    mouseMoveCanvas(event) {
      this.axis.onMouseMove(event);
      this.axis.updateValue();
      this.stage.render();
    },
    mouseUpCanvas(event) {
      this.axis.setDrag(false, 0);
    },
    mouseWheel(event) {
      this.axis.onMouseWheel(event, false);
      this.config.onMouseWheel(this.axis.scale);
      this.axis.updateValue();
      this.stage.render();
    },

    getCenterTranFn(box, width, height) {
      const s = 1 / 2;
      const window_width = width * s;
      const window_height = height * s;

      const box_width = box[1][0] - box[0][0];
      const box_height = box[1][1] - box[0][1];
      let scale = 1;
      if (box_height > box_width) {
        scale = window_height / box_height;
      } else {
        scale = window_width / box_width;
      }
      let dx = width / 2 - (box[0][0] + box_width / 2) * scale;
      let dy = height / 2 + (box[0][1] + box_height / 2) * scale;
      return {
        scale: scale,
        dx: dx,
        dy: dy,
      };
    },
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },

    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },

    //所有输入框全部填写才能激活确定
    encapsulationDialogBtnActive() {
      if (this.encapsulationSignRuleForm.form_signName !== "") {
        this.activateBtn(this.$refs.encapsulationDialogBtn);
      } else {
        this.disableBtn(this.$refs.encapsulationDialogBtn);
      }
    },
    //更新2d图
    update2D() {
      let pinUp = this.encapsulationSignRuleForm.form_pinUp
        .split(",")
        .filter((item) => item);
      let pinLeft = this.encapsulationSignRuleForm.form_pinLeft
        .split(",")
        .filter((item) => item);
      let pinRight = this.encapsulationSignRuleForm.form_pinRight
        .split(",")
        .filter((item) => item);
      let pinDown = this.encapsulationSignRuleForm.form_pinDown
        .split(",")
        .filter((item) => item);

      try {
        this.capSymbol = this.currentSchema.cap_symbol(
          this.encapsulationSignRuleForm.form_signName,
          pinUp,
          pinLeft,
          pinRight,
          pinDown
        );
        let capSymbolIns = new Kernel.SymbolIns();
        capSymbolIns.symbol = this.capSymbol;
        let width = 365;
        let height = 365;
        let schema = new Kernel.Schema();
        this.get2DCanvas(schema, capSymbolIns, width, height);
        this.oldPinLeft = this.encapsulationSignRuleForm.form_pinLeft;
        this.oldPinUp = this.encapsulationSignRuleForm.form_pinUp;
        this.oldPinRight = this.encapsulationSignRuleForm.form_pinRight;
        this.oldPinDown = this.encapsulationSignRuleForm.form_pinDown;
      } catch (error) {
        // bus.$emit("errDialog", error.message);
        bus.$emit("errDialog", this.$t("messages.inputPinNameErr_1"));

        this.encapsulationSignRuleForm.form_pinUp = this.oldPinUp;
        this.encapsulationSignRuleForm.form_pinLeft = this.oldPinLeft;
        this.encapsulationSignRuleForm.form_pinRight = this.oldPinRight;
        this.encapsulationSignRuleForm.form_pinDown = this.oldPinDown;
      }
    },

    encapsulationDialogSureBtn() {
      bus.$emit("loading", true);
      //文件列表新建封装符号,给符号数据添加id,添加数据到原理图文件,添加数据到被封装的原理图
      this.capSymbol.name = this.encapsulationSignRuleForm.form_signName;
      bus.$emit("newCapSymbolFn", this.capSymbol);
      this.resetEncapsulation();
    },
    encapsulationDialogCancelButton() {
      this.resetEncapsulation();
    },
    closeDialog() {
      this.resetEncapsulation();
    },

    //重置封装弹窗
    resetEncapsulation() {
      this.encapsulationDialogVisible = false;
      this.$refs.encapsulationSignRuleForm.resetFields();
    },
  },
  mounted() {
    bus.$on("openEncapsulation", (data) => {
      this.encapsulationDialogVisible = true;
      let pins = [];
      let pinsUp = [];
      let pinsLeft = [];
      let pinsRight = [];
      let pinsDown = [];

      this.currentSchema = this.$store.state.cellTable.filter(
        (item) => item.cellId === this.$store.state.cellInfo.cellId
      )[0].cell;

      this.encapsulationSignRuleForm.form_signName = this.currentSchema.name;
      pins = this.currentSchema.pins;

      for (let i = 0; i < pins.length; i++) {
        let type = pins[i].io.value;
        let name = pins[i].name.text;
        if (type === 1) {
          pinsLeft.push(name);
        } else if (type === 2) {
          pinsRight.push(name);
        } else if (type === 3) {
          pinsUp.push(name);
        } else {
          pinsDown.push(name);
        }
      }

      this.oldPinLeft = this.encapsulationSignRuleForm.form_pinLeft =
        pinsLeft.join(",");
      this.oldPinUp = this.encapsulationSignRuleForm.form_pinUp =
        pinsUp.join(",");
      this.oldPinRight = this.encapsulationSignRuleForm.form_pinRight =
        pinsRight.join(",");
      this.oldPinDown = this.encapsulationSignRuleForm.form_pinDown =
        pinsDown.join(",");

      this.update2D();
    });

    bus.$on("closeEncapsulation", (data) => {
      this.encapsulationDialogVisible = false;
    });
  },
  beforeDestroy() {
    bus.$off("openEncapsulation");
    bus.$off("closeEncapsulation");
  },
  watch: {
    "encapsulationSignRuleForm.form_signName"() {
      this.encapsulationDialogBtnActive();
    },
    // "encapsulationSignRuleForm.form_pinUp"() {
    //   this.encapsulationDialogBtnActive();
    // },
    // "encapsulationSignRuleForm.form_pinLeft"() {
    //   this.encapsulationDialogBtnActive();
    // },
    // "encapsulationSignRuleForm.form_pinRight"() {
    //   this.encapsulationDialogBtnActive();
    // },
    // "encapsulationSignRuleForm.form_pinDown"() {
    //   this.encapsulationDialogBtnActive();
    // },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__wrapper {
  z-index: 20000 !important;
}
.encapsulation {
  /deep/.el-dialog {
    width: 980px;
    min-height: 540px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 10px 20px;
    font-weight: 400;
    .bodyBox {
      display: flex;
      .encapsulationInfo {
        border: 1px solid #ddd;
        width: 560px;
        min-height: 433px;
        max-height: 800px;
        overflow-y: scroll;
        background-color: #f4f4f4;
        padding: 10px 20px 10px 10px;
        box-sizing: border-box;
        border-radius: 4px;
        .inputArea {
          position: relative;
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .el-input {
            width: 416px;
            height: 34px;
            background: #f4f4f4;
            border-radius: 2px;
          }
          .el-input__inner {
            height: 34px;
            background-color: #ffffff !important;
          }
        }

        .pinBox {
          display: flex;
          justify-content: space-between;
          .inputInfo {
            padding-top: 10px;
          }
          .pinInfo {
            width: 415px;
            .pinInputArea {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 10px 0;
              .el-textarea {
                width: 385px;
                min-height: 70px;
                background: #f4f4f4;
                border-radius: 2px;
                .el-textarea__inner {
                  min-height: 70px !important;
                  background-color: #ffffff !important;
                }
              }
            }
          }
        }
      }
      .encapsulation2D {
        border: 1px solid #ddd;
        border-radius: 4px;
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #f4f4f4;
        margin-left: 10px;
        .encapsulation2DTitle {
          width: 100%;
          line-height: 34px;
          text-align: left;
          padding-left: 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ddd;
        }
        .imgBox {
          width: 365px;
          height: 365px;
          #encapsulation2D_canvas {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
}
</style>
