<template>
  <div class="dproperties" onselectstart="javascript:return false;" id="dProperties">
    <div class="cardHeader" v-drag>
      <!-- 头部标题 -->
      <strong>{{$t('messages.componentProperty')}}</strong>
      <!-- +-号 -->
      <span v-if="cellBarSeen" style="float: right" class="el-icon-minus spanBtn" @click="setCellBar"></span>
      <span v-else style="float: right" class="el-icon-plus spanBtn" @click="setCellBar"></span>
    </div>
    <!-- 折叠面板主体 -->
    <el-collapse-transition v-if="cellBarSeen">
      <div class="noneDevice" v-if="noDevice">{{$t('messages.noneComponent')}}</div>
      <div class="deviceProperties" v-else ref="scroll">
        <!-- Basic Attributes -->
        <div class="scroll" style="position: relative">
          <!-- Annotations -->
          <attrs-container :title="$t('messages.annotationMap')" v-if="editingGeometricObject">
            <!-- <el-image v-if="editingGeometricObject" :src="getMarkPicture()" /> -->
            <div class="annotaions" v-if="editingGeometricObject" style="width: 250px; height: 250px">
              <vue-photo-zoom-pro v-for="(url, i) in getMarkPicture()" :key="i" :url="url" :scale="2" :width="150">
              </vue-photo-zoom-pro>
            </div>
          </attrs-container>
          <!-- <attrs-container :title="`Basic ${''} Attributes`" v-if="basicAttrsForm"> -->
          <attrs-container :title="$t('messages.basicProperties')" v-if="basicAttrsForm">
            <div style="height: 100%">
              <el-form ref="basicAttrsForm" class="basicAttrsForm" label-position="left" v-if="basicAttrsForm">
                <el-form-item :label="k" label-width="70px" v-for="(v, k) in basicAttrsForm" :key="k"
                  class="basicAttrsFormItem" size="mini">
                  <el-input v-if="k !== '旋转'&& k !=='Rotate'" v-model="basicAttrsForm[k]" label-width="30px"
                    oninput="value=value.replace(/[^0-9.-]/g,'')" @blur="inputBulr('basicAttrsForm', k)"><i
                      slot="suffix" style="font-style: normal; margin-right: 10px">{{ baseUnit }}</i></el-input>
                  <el-input v-else v-model="basicAttrsForm[k]" label-width="30px"
                    oninput="value=value.replace(/[^0-9.-]/g,'')" @blur="inputBulr('basicAttrsForm', k)"><i
                      slot="suffix" style="font-style: normal; margin-right: 10px">∠</i></el-input>
                </el-form-item>
              </el-form>
            </div>
          </attrs-container>
          <attrs-container :title="$t('messages.OProperties')" v-if="geometricOForm.length >= 1">
            <div>
              <div v-if="gapType!=null" class="formSectionHeader">
                <span title="">{{$t('messages.tipSelected')}}</span>
              </div>
              <el-form v-for="(oItem, idx) in geometricOForm" :key="idx" class="geometricOForm" label-width="16px"
                label-position="left">
                <div v-if="geometricOForm.length > 1" class="formSectionHeader">
                  <span title="Point O Attributes">{{ oItem.name }}</span>
                </div>
                <div v-else class="formSectionHeader">
                  <span title="Point O Attributes">O</span>
                </div>
                <el-form-item label="x" class="geometricOFormItem" size="mini" label-position="left">
                  <el-input v-model="oItem.x" label-width="30px" @change="basicAttrsChanged"
                    oninput="value=value.replace(/[^0-9.-]/g,'')" @blur="inputBulr('geometricOForm', idx)"><i
                      slot="suffix" style="font-style: normal; margin-right: 10px">um</i></el-input>
                </el-form-item>
                <el-form-item label="y" class="geometricOFormItem" size="mini" label-position="left">
                  <el-input v-model="oItem.y" label-width="30px" @change="basicAttrsChanged"
                    oninput="value=value.replace(/[^0-9.-]/g,'')" @blur="inputBulr('geometricOForm', idx)"><i
                      slot="suffix" style="font-style: normal; margin-right: 10px">um</i></el-input>
                </el-form-item>
              </el-form>
            </div>
          </attrs-container>
          <attrs-container :title="$t('messages.customProperties')" v-show="customAttrsForm.length >= 1">
            <div>
              <el-form ref="customAttrsForm" class="customAttrsForm formThree" label-width="26px" label-position="left">
                <!-- <el-radio-group v-model="waveLength" size="small">
                  <el-radio label="1" border>λ/4波长</el-radio>
                  <el-radio label="2" border>λ/2波长</el-radio>
                </el-radio-group> -->
                <el-form-item v-if="gapType != null" label="GapType" class="customAttrsFormItem" size="mini"
                  label-position="left">
                  <el-checkbox-group v-model="gapType" @change="handleCheckedGapType">
                    <el-checkbox label="1">{{$t('messages.topLeft')}}</el-checkbox>
                    <el-checkbox label="2">{{$t('messages.bottomLeft')}}</el-checkbox>
                    <el-checkbox
                      v-if="editingGeometricObject.obj_data.attrs.obj_type=='resistance_resonator_transmission_type'"
                      label="4">{{$t('messages.topRight')}}</el-checkbox>
                    <el-checkbox
                      v-if="editingGeometricObject.obj_data.attrs.obj_type=='resistance_resonator_transmission_type'"
                      label="8">{{$t('messages.bottomRight')}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item v-if="attrL.field != 'WaveLength' && attrL.field != 'GapType'"
                  v-for="(attrL, i) in customAttrsForm" :key="i" v-show="customAttrsForm[i].show" :label="attrL.field"
                  class="customAttrsFormItem" size="mini" label-position="left">
                  <el-tooltip class="ntips" effect="dark" :content="$t('messages.quantumDotsNumber')" placement="top"
                    v-if="customAttrsForm[i].field==='n' && editingGeometricObject.obj_data.attrs.obj_type ==='SiMos'">
                    <el-button>?</el-button>
                  </el-tooltip>
                  <el-input v-model="customAttrsForm[i].value" label-width="50px" @change="customAttrsChanged"
                    oninput="value=value.replace(/[^0-9.]/g,'')" @blur="inputBulr('customAttrsForm', i)"
                    :disabled="!customAttrsForm[i].editable">
                    <i slot="suffix" style="font-style: normal; margin-right: 10px">{{ customAttrsForm[i].unit }}</i>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </attrs-container>
        </div>
        <div class="devicePropertiesBtnDiv">
          <!-- <el-button type="primary" @click="application()">应用</el-button> -->
          <!-- <el-button type="info" @click="cancel()">{{$t("messages.cancel")}}</el-button> -->
          <el-button type="success" @click="sure()">{{$t('messages.OK')}}</el-button>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>
<script>
import bus from "../common/bus";
import vuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import {
  newStageFromSerializedData,//NOSONAR
  newComponent,//NOSONAR
  buildInitialLayers,//NOSONAR
  serializeCellData,//NOSONAR
  buildComponentOForm,
  buildComponentCustomAttrsForm,
  changeOPosition,
  buildGeometricBasicAttrsForm,
  changeBasicAttrs,
  redrawComponent,
  updateComponentData,
} from "@/components/common/pixiConvertor";
import AttrsContainer from "./AttrsContainer.vue";
import { postComponentPreview } from "@/api/api";
import { deepClone } from "../../utils/utils";
export default {
  components: {
    AttrsContainer,
    vuePhotoZoomPro,
  },
  data() {
    return {
      waveLength: null,
      gapType: null,
      cellBarSeen: true,
      baseUnit: "um",
      basicAttrsForm: {},
      geometricOForm: [
        { index: "0", name: "O1", x: 0, y: 0 },
        { index: "1", name: "O2", x: 0, y: 0 },
      ],
      customAttrsForm: [{ value: 0, unit: "um" }],
      noDevice: true,
      editingGeometricObject: null,
      sameTarget: null,
      rawData: "",
      compareData: "", //对比的数据
      attrWidth: 90,
      scrollContent: null,
      isflip: false,
      oldComponentData: null,
      customAttrsFlag: false,
      basicDataChange: [],
      geometricOChange: [],
      activeChange: [],
      changePart: null,
      customData: [],
      CustomAttrs: true,
      isClickComponent: false, //是否点击了器件(控制属性面板)
      hasApplication: false, //是否点击了应用
      clickFlag: false, //是否点击了器件(控制复制粘贴)
      lang: "zh", //默认中文
    };
  },
  mounted() {
    let self = this;
    document.getElementById("dProperties").oncontextmenu = function (e) {
      //阻止默认右击事件
      e.preventDefault();
    };
    this.addListenerKeyDown();
    bus.$on("componentDidClicked", (e, target) => {
      this.isClickComponent = true;
      //选择器件接受参数更新列表
      this.noDevice = false; //接受参数显示数据
      this.updateBasicAttrsForm(target);
      this.updateGeometricOForm(target);
      this.updateCustomAttrsForm(target);
      this.oldComponentData = {
        x: target.x,
        y: target.y,
        rotation: target.rotation,
        attrs: deepClone(target.obj_data.attrs),
      };
      if (target.obj_data && target.obj_data.attrs.obj_is_component) {
        this.editingGeometricObject = target;
      } else {
        setTimeout(() => {
          this.updateForm();
        }, 0);
      }
    });
    bus.$on("closeDeviceProperties", (data) => {
      if (this.isClickComponent) {
        this.$bus.$emit("clickJudge", true);
        this.clickFlag = true;
        if (data) {
          this.noDevice = true;
          this.clickFlag = false;
          this.editingGeometricObject = null;
          this.isClickComponent = false;
        }
      }
      if (!this.isClickComponent) {
        this.$bus.$emit("clickJudge", false);
        this.noDevice = true;
        this.clickFlag = false;
        this.editingGeometricObject = null;
        this.isClickComponent = false;
      }
      this.isClickComponent = false;
    });
    self.$bus.$on("flipGraph", () => {
      if (this.editingGeometricObject) {
        this.editingGeometricObject.obj_data.attrs.flip =
          !!!this.editingGeometricObject.obj_data.attrs.flip;
        this.editingGeometricObject = redrawComponent(
          this.editingGeometricObject
        );
        self.updateGeometricOForm(this.editingGeometricObject);
        this.$bus.$emit("flipstep", this.editingGeometricObject);
      }
    });
    self.$bus.$on("undoComponent", (data) => {
      this.editingGeometricObject = data.oldComponent;
      this.dataChange(data.oldComponentData);
    });
    self.$bus.$on("redoComponent", (data) => {
      this.editingGeometricObject = data.oldComponent;
      this.dataChange(data.oldComponentData);
    });
    self.$bus.$on("destoryComponent", (data) => {
      this.editingGeometricObject = this.oldComponentData;
      this.noDevice = true;
    });
    self.$bus.$on("createComponent", (data) => {
      this.editingGeometricObject = data;
      this.oldComponentData = data;
      this.updateData(this.editingGeometricObject);
      this.noDevice = false;
    });
    self.$bus.$on("updataData", (data) => {
      this.updateData(data);
    });
    self.$bus.$on("hCopyComponent", (data) => {
      this.copyComponent = {
        rotation: this.editingGeometricObject.rotation,
        attrs: deepClone(this.editingGeometricObject.obj_data.attrs),
      };
      this.$bus.$emit("copyComponent", this.copyComponent);
    });
    self.$bus.$on("hPasteComponent", (data) => {
      this.$bus.$emit("pasteComponent", this.copyComponent);
    });
    self.$bus.$on("changeLang", (data) => {
      this.lang = data;
      if (this.editingGeometricObject) {
        this.updateBasicAttrsForm(this.editingGeometricObject);
      }
    });
  },
  created() {
    this.lang = window.lang;
  },
  updated() {},
  methods: {
    getMarkPicture() {
      //标注图
      try {
        if (this.editingGeometricObject.obj_data.attrs.obj_type == "SiMos") {
          let urls = [];
          for (let name of [
            "SiGe-barrier",
            "Si-barrier-Model",
            "SiGe-plunger",
            "Si-plunger-Model",
            "Si-n-Model",
            "Si-Co",
            "SiGe-n",
          ]) {
            urls.push(require(`@/assets/img/SiMOS/${name}.svg`));
          }
          return urls;
        } else {
          return [
            require(`@/assets/img/${this.editingGeometricObject.obj_data.attrs.obj_type}.svg`),
          ];
        }
      } catch (error) {
        return [];
      }
    },
    setCellBar() {
      //控制列表垂直折叠
      this.cellBarSeen = !this.cellBarSeen;
    },
    handleChange(val) {},
    basicAttrsChanged(val) {
      //改变基本属性
      if (!/^\-?\d{1,}\.?\d*$/.test(val)) {//NOSONAR
        this.$message.error("Please input valid float type number");
        return;
      }
      let v = parseFloat(v);
      if (v != 0 && !v) {
        this.$message.error("Please input valid float type number");
      }
      return v;
    },
    // 回车改变参数
    //点击空白处
    inputBulr(formName, key) {
      //输入框失焦触发和回车一样的事件
      // 基本属性
      if (formName == "basicAttrsForm") {
        this.basicDataChange = [];
        for (const key in this.basicAttrsForm) {
          let value = this.basicAttrsForm[key];
          let params = { key, value };
          this.basicDataChange.push(params);
        }
        this.activeChange = this.basicDataChange;
        this.changePart = formName;
      }
      // O点
      if (formName == "geometricOForm") {
        this.geometricOChange = this.geometricOForm[key];
        this.activeChange = this.geometricOChange;
        this.changePart = formName;
      }
      // 自定义属性
      if (formName == "customAttrsForm") {
        this.customData = this.customAttrsForm;
        this.changePart = formName;
      }
    },
    flagChange() {
      if (this.changePart == "basicAttrsForm") {
        this.activeChange.forEach((item) => {
          changeBasicAttrs(this.editingGeometricObject, item.key, item.value);
          return this.updateGeometricOForm(this.editingGeometricObject);
        });
        this.customAttrsFlag = true;
      }
      if (this.changePart == "geometricOForm") {
        this.customAttrsFlag = true;
        return this.submitEditGeometricO(this.activeChange);
      }
      if (this.changePart == "customAttrsForm") {
        this.submitEditCustomAttrs();
      }
    },
    sure() {
      this.flagChange();
      if (this.customAttrsFlag) {
        this.newComponentData = {
          x: this.editingGeometricObject.x,
          y: this.editingGeometricObject.y,
          rotation: this.editingGeometricObject.rotation,
          attrs: deepClone(this.editingGeometricObject.obj_data.attrs),
        };
        let componentDataChange = {
          newComponentData: this.newComponentData,
          oldComponentData: this.oldComponentData,
        };
        this.$bus.$emit("changeComponentData", componentDataChange);
        this.oldComponentData = deepClone(this.newComponentData);
      }
      this.changePart = null;
      this.activeChange = [];
      this.customData = [];
      this.customAttrsFlag = false;
    },
    //数据改变
    dataChange(data) {
      this.editingGeometricObject.x = data.x;
      this.editingGeometricObject.y = data.y;
      this.editingGeometricObject.rotation = data.rotation;
      this.editingGeometricObject.obj_data.attrs = data.attrs;
      this.customData = this.editingGeometricObject.obj_data.attrs.custom_attrs;
      this.updateBasicAttrsForm(this.editingGeometricObject);
      this.updateGeometricOForm(this.editingGeometricObject);
      this.updateCustomAttrsForm(this.editingGeometricObject);
      this.submitEditCustomAttrs();
    },
    updateData(data) {
      this.updateBasicAttrsForm(data);
      this.updateGeometricOForm(data);
      this.updateCustomAttrsForm(data);
      this.submitEditCustomAttrs();
    },
    //  回调
    submitEditGeometricO(formItem) {
      //O点列表改变回调
      let { x: oX, y: oY, name, index } = formItem;//NOSONAR
      let obj_o = this.editingGeometricObject.obj_data.attrs.obj_O;
      let pointO = obj_o[index];
      let { x: newX, y: newY } = changeOPosition(
        this.editingGeometricObject,
        { x: oX, y: oY },
        pointO
      );
      //  x,y坐标*1000偏移量才正常
      this.editingGeometricObject.x = newX * 1000;
      this.editingGeometricObject.y = newY * 1000;
      this.updateBasicAttrsForm(this.editingGeometricObject);
      if (this.editingGeometricObject.obj_data.attrs.obj_O.length > 1) {
        this.updateGeometricOForm(this.editingGeometricObject);
      }
    },
    async submitEditCustomAttrs() {
      //自定义属性改变回调
      let editableCount = this.customData.filter(
        (attr) => attr.editable
      ).length;
      if (!editableCount) {
        return;
      }
      let needRaise = false;
      let formatCustomAttrs = this.customAttrsForm.map((attr) => {
        if (attr.field == "GapType") {
          let v = parseInt(
            this.gapType.reduce((a, b) => parseInt(a) + parseInt(b), 0)
          );
          if (v == 0) {
            needRaise = true;
          }
          return { ...attr, value: v };
        }
        return { ...attr, value: parseFloat(attr.value) };
      });
      if (needRaise) {
        return this.$message.error(this.$t("messages.GapTypeCannotEmpty"));
      }
      let assert = function (condition, message) {
        if (!condition) throw new Error(message);
      };
      if (
        this.editingGeometricObject.obj_data.attrs.obj_type ==
          "resonator_fp_design" ||
        this.editingGeometricObject.obj_data.attrs.obj_type == "resonator_1"
      ) {
        let nField = formatCustomAttrs.filter((attr) => attr.field == "n")[0];
        let rField = formatCustomAttrs.filter((attr) => attr.field == "r")[0];
        let w1Field = formatCustomAttrs.filter((attr) => attr.field == "W1")[0];
        let w2Field = formatCustomAttrs.filter((attr) => attr.field == "W2")[0];
        try {
          let n = nField.value;
          assert(
            Number.isInteger(n) && n >= 5,
            "must be integer and great than 5"
          );
          n = (n - 1) / 2;
          this.customAttrsFlag = true;
          assert(Number.isInteger(n) && n >= 2, "must be integer");
        } catch (error) {
          this.customAttrsFlag = false;
          return this.$message.error(this.$t("messages.nLimit"));
        }
        try {
          let r = rField.value;
          let w1 = w1Field.value;
          let w2 = w2Field.value;
          assert(
            Number.isInteger(r) &&
              r >= w1 + w2 &&
              Number.isInteger(w1) &&
              Number.isInteger(w2),
            "must be integer and r >= w1+w2"
          );
          this.customAttrsFlag = true;
        } catch (error) {
          this.customAttrsFlag = false;
          return this.$message.error(this.$t("messages.RW1W2"));
        }
      }
      this.editingGeometricObject.syncAttrsToObjectData();
      let obj_data = this.editingGeometricObject.obj_data;
      let attrs = updateComponentData(
        this.editingGeometricObject,
        formatCustomAttrs
      ).attrs;
      let params = {
        obj_type: obj_data.attrs.obj_type,
        attrs: attrs,
        custom_attrs: formatCustomAttrs,
      };
      // 器件修改
      let res = await postComponentPreview(params);
      if (res.code === 200000) {
        this.editingGeometricObject = redrawComponent(
          this.editingGeometricObject,
          res.data
        );
        this.updateBasicAttrsForm(this.editingGeometricObject);
        this.updateGeometricOForm(this.editingGeometricObject);
        this.updateCustomAttrsForm(this.editingGeometricObject);
      }
    },
    // 数据更新
    updateBasicAttrsForm(geometric) {
      this.basicAttrsForm = buildGeometricBasicAttrsForm(geometric, this.lang);
      for (let key in this.basicAttrsForm) {
        this.basicAttrsForm[key] =
          Math.round(parseFloat(this.basicAttrsForm[key]) * 1000) / 1000; //保留三位小数，四舍五入
      }
    },
    updateGeometricOForm(geometric) {
      this.geometricOForm = buildComponentOForm(geometric);
      for (let i = 0; i < this.geometricOForm.length; i++) {
        this.geometricOForm[i].x =
          Math.round(parseFloat(this.geometricOForm[i].x) * 1000) / 1000;
        this.geometricOForm[i].y =
          Math.round(parseFloat(this.geometricOForm[i].y) * 1000) / 1000;
      }
    },
    updateCustomAttrsForm(geometric) {
      let customAttrsForm = buildComponentCustomAttrsForm(geometric);
      if (
        geometric.obj_data.attrs.obj_type ==
          "resistance_resonator_reflection_type" ||
        geometric.obj_data.attrs.obj_type ==
          "resistance_resonator_transmission_type"
      ) {
        let i = 0,
          formLength = customAttrsForm.length;
        for (let i = 0; i < formLength; i++) {
          if (customAttrsForm[i].field == "WaveLength") {
            this.waveLength = customAttrsForm[i].value;
          }
          if (customAttrsForm[i].field == "GapType") {
            this.gapType = this.gapTypeValueToList(customAttrsForm[i].value);
          }
        }
      } else {
        this.waveLength = null;
        this.gapType = null;
      }
      this.customAttrsForm = customAttrsForm;
      for (let i = 0; i < this.customAttrsForm.length; i++) {
        if (this.customAttrsForm[i].unit == "um") {
          this.customAttrsForm[i].value =
            Math.round(parseFloat(this.customAttrsForm[i].value) * 1000) / 1000;
        }
      }
    },
    handleCheckedGapType(value) {
      this.customData = this.customAttrsForm;
      this.changePart = "customAttrsForm";
    },
    gapTypeValueToList(value) {
      let result = [];
      for (let v of [1, 2, 4, 8]) {
        if (parseInt(v & value) == v) {
          result.push(v + "");
        }
      }
      return result;
    },
    customAttrsChanged() {},
    // delete键，删除器件
    addListenerKeyDown() {
      document.body.onkeydown = (e) => {
        if (e.keyCode === 46) {//NOSONAR
          if (this.editingGeometricObject) {
            this.$bus.$emit("deleteComponent", this.editingGeometricObject);
            this.editingGeometricObject.parent.removeChild(
              this.editingGeometricObject
            );
            this.noDevice = true; //关闭器件属性列表
            this.editingGeometricObject = null;
          }
        }
        if (e.ctrlKey && e.key == "c" && this.clickFlag) {
          // 器件复制
          this.copyComponent = {
            rotation: this.editingGeometricObject.rotation,
            attrs: deepClone(this.editingGeometricObject.obj_data.attrs),
          };
          this.$bus.$emit("copyComponent", this.copyComponent);
        }
        if (e.ctrlKey && e.key == "x" && this.clickFlag) {
          //器件剪切
          this.cutComponent = {
            rotation: this.editingGeometricObject.rotation,
            attrs: deepClone(this.editingGeometricObject.obj_data.attrs),
          };
          this.$bus.$emit("cutComponent", this.cutComponent);
        }
        if (e.ctrlKey && e.key == "v" && this.clickFlag) {
          // 器件粘贴
          this.$bus.$emit("pasteComponent", this.copyComponent);
        }
      };
    },
    // 更新渲染
    updateForm() {
      // 接收数据不同重绘
      if (this.rawData != this.customAttrsForm && !this.noDevice) {
        let collCow = null;
        this.scrollContent = this.$refs.scroll;
        let children = this.scrollContent.children[0].children;
        collCow = children[children.length - 1];
        collCow.style.transition = "none"; //折叠时间
        let width = collCow.offsetWidth;
        collCow.style.width = this.attrWidth + "px";
        collCow.style.minWidth = "0px";
        setTimeout(() => {
          //设置延迟避免报错
          //展开
          collCow.style.transition = "width 0.3s"; //折叠时间
          collCow.style.width = "auto";
          width = collCow.offsetWidth;
          collCow.style.width = this.attrWidth + "px";
          let f = document.body.offsetWidth; // 必加//NOSONAR
          collCow.style.width = width + "px";
        }, 0);
        this.rawData = this.customAttrsForm; //赋值，停留在当前所选中的器件
      }
    },
    getChildByComponentId(componentId) {
      let componentList = this.rootContainer.children;
      for (let i = 0; i < componentList.length; i++) {
        if (componentList[i].obj_data) {
          if (componentList[i].obj_data.attrs.componentId === componentId) {
            return componentList[i];
          }
        }
      }
      return null;
    },
  },
  directives: {
    /*自定义拖拽*/
    drag: {
      inserted: function (el, binding, vnode) {//NOSONAR
        let odiv = el.parentNode; // 获取元素的父级 也就是这个移动的窗口
        el.onmousedown = function (eve) {
          eve = eve || window.event;//NOSONAR
          let mx = eve.pageX; // 鼠标点击时的x坐标位置
          let my = eve.pageY; // 鼠标点击时的y坐标位置
          let dleft = odiv.offsetLeft; // 窗口初始的位置
          let dtop = odiv.offsetTop;
          let clientWidth = document.documentElement.clientWidth; // 页面的宽
          let oWidth = odiv.clientWidth; // 窗口的宽度
          let maxX = clientWidth - oWidth; // x轴能移动的最大距离
          let clientHeight = document.documentElement.clientHeight; // 页面的高
          let oHeight = odiv.clientHeight; // 窗口的高度
          let maxY = clientHeight - oHeight; // y轴能移动的最大距离
          document.onmousemove = function (e) {
            e.preventDefault();
            let x = e.pageX; // 移动时鼠标X坐标
            let y = e.pageY; // 移动时鼠标Y坐标
            let left = x - mx + dleft; // 移动后对话框新的left值
            let Top = y - my + dtop; // 移动后对话框新的Top值
            if (left < 0) {
              left = 0;
            }
            if (left > maxX) {
              left = maxX;
            }
            if (Top < 0) {
              Top = 0;
            }
            if (Top > maxY) {
              Top = maxY;
            }
            odiv.style.left = left + "px";
            odiv.style.top = Top + "px";
            odiv.style.marginLeft = 0;
            odiv.style.marginTop = 0;
          };
          document.onmouseup = function () {
            document.onmouseup = "";
            document.onmousemove = "";
          };
        };
      },
    },
  },
};
</script>
<style lang="less" scoped>
.noneDevice {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.dproperties {
  position: fixed;
  left: 40px;
  top: 60%;
  z-index: 999;
  min-width: 260px;
  max-width: 98%;
  font-size: 12px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #eee;
  /deep/ .el-collapse-item__wrap {
    margin-top: 0 !important;
  }
}
.cardHeader {
  height: 40px;
  line-height: 40px;
  background: #eee;
  border-bottom: 1px solid #d1d1d1;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  cursor: move;
  strong {
    height: 100%;
    display: inline-block;
    margin-left: 20px;
  }
  .el-icon-plus:before,
  .el-icon-minus:before {
    height: 100%;
    display: inline-block;
    line-height: 40px;
    margin-right: 20px;
  }
}
.cellBar {
  height: 100%;
  display: flex;
  flex-direction: column;
}
//  折叠面板主题
.deviceProperties {
  width: 100%;
  background: #cccccc;
  z-index: 9999;
  height: 300px;
  /*   overflow-x: auto;
  overflow-y: hidden; */
  .scroll {
    display: flex;
    height: 250px;
    width: 100%;
    > div:nth-child(4) {
      /deep/ .content-attr {
        width: 100%;
        max-width: 100%;
      }
    }
    > div {
      height: 100%;
      display: flex;
      > div {
        height: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        // 表单
        > form {
          > form {
            display: flex;
          }
        }
      }
    }
  }
}
.formSectionHeader {
  margin: 0;
  padding: 5px 0 5px 5px;
  color: #72767b;
  font-size: 12px;
  background: #eee;
}
/deep/ .el-form {
  box-sizing: border-box;
  padding: 0 10px;
}
// 单位字体
i {
  color: #9b9999 !important;
}
// 通用属性
.basicAttrsForm {
  /deep/ .el-form-item {
    margin-bottom: 22px !important;
  }
}
.geometricOForm {
  /deep/ .el-form-item {
    margin-bottom: 10px !important;
  }
}
.customAttrsForm {
  display: flex;
  flex-wrap: wrap;
  width: 440px;
}
.formThree {
  padding-right: 0px !important;
  > form {
    display: flex;
    justify-content: space-around;
    width: 100% !important;
    /deep/ .el-input__inner {
      width: 100px !important;
    }
    /deep/ .el-form-item {
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }
}
// 滚动条
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  height: 10px;
}
.customAttrsFormItem {
  width: 200px;
  display: inline;
}
// 私有属性栏
.attrs {
  width: 120px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-right: 20px;
  height: 100%;
  > .el-form-item {
    margin-bottom: 15px !important;
  }
}
.attrs:last-child {
  margin-right: 0px !important;
}
.vue-photo-zoom-pro {
  width: 250px;
  height: 250px;
  display: flex;
  /deep/.selector {
    border-radius: 50%;
  }
}
.devicePropertiesBtnDiv {
  background-color: #eee;
  width: 100%;
  height: 49px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: right;
  /deep/ .el-button {
    font-size: 12px;
    margin: 0 10px;
  }
}
.ntips {
  position: absolute;
  left: -23px;
  top: 7px;
  cursor: pointer;
  background-color: #eee;
  border: 1px solid #fff;
  padding: 0px 3px;
  border-radius: 50%;
}
</style>