<template>
  <div class="app-container">
    <div class="topRegion">
      <div class="title">
        <span class="name">DRC</span>
        <el-link class="exportBtn1" icon="iconfont icon-daochu" v-if="logs.length > 0"
          @click="exportJson">{{ $t('messages.drcExport') }}</el-link>
        <el-link class="exportBtn2" icon="iconfont icon-daochu" v-else>{{ $t('messages.drcExport') }}</el-link>
      </div>
      <el-divider></el-divider>
      <div class="row">
        <el-progress :stroke-width="8" :percentage="progress_percent" :status="progress_status"></el-progress>
        <div class="stopBtn1" v-if="task_stop_enable === true && progress_status != 'success'" @click="taskStop">
          <span class="iconfont icon-zhongzhi"></span>
        </div>
        <div class="stopBtn2" v-if="task_stop_enable === false && progress_status != 'success'">
          <span class="iconfont icon-zhongzhi"></span>
        </div>
        <div class="stopBtn3" v-if="progress_status == 'success'">
          <span>{{ $t('messages.drcDone') }}</span>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="logs">
        <el-table ref="logsTable" :data="logs" :height="tableHeight" highlight-current-row :show-header="false"
          @current-change="handleCurrentChange">
          <el-table-column label="name" width="80">
            <template slot-scope="scope">
              <span style="color: green" v-if="scope.row.data.length === 0">[{{ $t('messages.drcCorrect') }}]</span>
              <span style="color: red" v-else>[{{ $t('messages.drcError') }}{{ scope.row.data.length }}]</span>
            </template>
          </el-table-column>
          <el-table-column label="name" prop="name" fit></el-table-column>
        </el-table>
      </div>
    </div>

    <div class="bottomRegion">
      <div class="cardHeader2">
        <strong>{{ $t('messages.drcRuleSpecification') }}</strong>
        <div class="arrow" @click="ruleSeen = !ruleSeen">
          <span class="el-icon-arrow-down" v-if="ruleSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="ruleSeenData" v-show="ruleSeen">{{ currentRow.description }}</div>

      <div class="cardHeader2">
        <strong>{{ $t('messages.drcErrorCode') }}</strong>
        <div class="arrow" @click="errorCodeSeen = !errorCodeSeen">
          <span class="el-icon-arrow-down" v-if="errorCodeSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="errorCodeSeenData" v-show="errorCodeSeen">
        <!-- <span v-for="(val, key, i) in currentRow.data" style="margin-left: 10px">1</span> -->
        <a class="number" v-for="(val, key, i) in currentRow.data" :key="i"
          @click="numberHandleClicked(val)">{{ key + 1 }}</a>
        <!-- <el-button style="width: 20px" size="mini" round v-for="(val, key, i) in currentRow.data" @click="numberHandleClicked(val)">{{ key + 1 }}</el-button> -->
      </div>

      <div class="cardHeader2">
        <strong>{{ $t('messages.drcErrorCoordinate') }}</strong>
        <div class="arrow" @click="errorCoordinateSeen = !errorCoordinateSeen">
          <span class="el-icon-arrow-down" v-if="errorCoordinateSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="errorCoordinateSeenData" v-if="errorCoordinateSeen && coordinateX && coordinateY">
        <span>X:{{ coordinateX }}</span>
        <span>Y:{{ coordinateY }}</span>
      </div>
      <div class="errorCoordinateSeenData" v-else></div>
    </div>

    <el-dialog class="import-dialog" :title="$t('messages.drcDesignRuleCheck')" :visible.sync="dialogVisible"
      width="550px" height="182px" :before-close="importDialogClose">
      <div class="dialog-body">
        <div style="margin-bottom: 20px">{{ $t('messages.drcLibraryPath') }}：{{ path }}</div>
        <el-upload class="upload-demo" ref="DRCImport" action="https://jsonplaceholder.typicode.com/posts/"
          :on-change="onImportDrcFile" :auto-upload="false" :show-file-list="true" :file-list="fileList" accept=".py">
          <el-button
            style="width: 58px; height: 30px; background: #f4f4f4; border: 1px solid #dddddd; border-radius: 2px">{{ $t('messages.drcSelect') }}</el-button>
        </el-upload>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleOk">{{ $t('messages.drcOk') }}</el-button>
        <el-button style="width: 79px; height: 30px; background: #b0b0b0; border-radius: 4px; color: #ffffff"
          @click="dialogVisibleCancel">{{ $t('messages.drcCancel') }}</el-button>
        <!-- <el-button class="cancel-button" @click="dialogVisible = false">取 消</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import i18n from "../../../common/lang/i18n";
import {
  apiDrcUpload,
  apiDrcTaskRun,
  apiDrcTaskResult,
  apiDrcTest,
} from "@/api/drc/drc.js";
import { getPDKInfo_api } from "@/api/objectStorage/objectStorage.js";
import { getObjectDataFn } from "@/components/homes/fileList/fileUtils.js";

export default {
  components: {},
  filters: {},
  model: {
    prop: "value",
  },
  data() {
    return {
      dialogVisible: false,
      path: "",
      cell_name: "",
      fileList: [],
      file_md5: "",
      layout: null,
      stage: null,
      drc_file: null,
      gds_file: null,
      progress_percent: 0,
      progress_status: "warning",
      logs: [],
      activeNames: ["1", "2", "3"],
      currentRow: {
        name: "",
        description: "",
        data: [],
      },
      task_timer: null,
      task_stop_enable: false,
      pdkdrc: null,
      ruleSeen: true,
      errorCodeSeen: true,
      errorCoordinateSeen: true,
      tableHeight: 350,
      coordinateX: "",
      coordinateY: "",
    };
  },
  watch: {
    ruleSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -74;
        } else {
          t_h = +74;
        }
      },
    },
    errorCodeSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -74;
        } else {
          t_h = +74;
        }
      },
    },
    errorCoordinateSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -84;
        } else {
          t_h = +84;
        }
      },
    },
  },

  mounted() {
    bus.$on("drcDialogOpen", async (data) => {
      this.layout = data;
      this.getGdsFile();
      // try load pkd drc.py
      this.fileList.splice(0, this.fileList.length);
      let proj_gns = this.$store.state.proInfo.projectId;
      let pdk_res = await getPDKInfo_api({ project_snow_id: proj_gns });
      if (pdk_res.code === 200000) {
        let config = pdk_res.data.url_data;
        if (config.drc) {
          // 获取drc文件内容
          let drc_u8 = await getObjectDataFn(config.lib);
          this.drc_file = new Blob([drc_u8]);
          this.fileList.push({ name: "drc.py" });
        }
      }

      let state = this.$store.state;

      this.path = `${state.username}/${state.proInfo.name}/${state.fileInfo.name}/${state.cellInfo.name}`;
      this.cell_name = state.cellInfo.name;

      this.dialogVisible = true;
    });

    bus.$on("initDrcTab", (msg) => {
      if (!msg.task_result) {
        return;
      }
      this.progress_percent = 100;
      this.progress_status = "success";
      this.task_stop_enable = false;

      this.logs = JSON.parse(msg.task_result);

      for (let i = 0; i < this.logs.length; i++) {
        this.logs[i].data = this.logs[i].data.splice(0, 999);
      }
      clearInterval(this.task_timer);
    });
  },
  created() {},
  methods: {
    onImportDrcFile(file, fileList) {
      if (fileList.length > 1 && file.status !== "fail") {
        fileList.splice(0, 1);
      } else if (file.status === "fail") {
        //NOSONAR
        fileList.splice(0, 1);
      }

      let self = this;
      let event = event || window.event;

      let reader = new FileReader();
      reader.readAsArrayBuffer(file.raw);
      reader.onload = function (e) {
        // 转Uint8Array
        const blob = new Uint8Array(reader.result);

        self.drc_file = new Blob([blob]);
      };
    },

    dialogVisibleCancel() {
      this.dialogVisible = false;
      this.$refs.DRCImport.clearFiles();
    },
    importDialogClose() {
      this.dialogVisible = false;
      this.$refs.DRCImport.clearFiles();
    },

    // 导出文件
    exportJson() {
      function testReplacer(key, value) {
        //key为对象属性名，value为对象属性值，会遍历testObj或testArr来执行该函数
        if (key == "xsdklfsjdflsfds") {
          value = value.toUpperCase();
        }
        return value;
      }
      let name = "drc-export.json";
      let data = JSON.stringify(this.logs, testReplacer, 2);

      let blob = new Blob([data]); //  创建 blob 对象
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob); //  创建一个 URL 对象并传给 a 的 href
      link.download = name; //  设置下载的默认文件名
      link.click();
    },

    // 错误信息格式化回车换行等等
    errorFormatHtml(task_id, message) {
      if (!message) return;
      let msg = message.split("\n");
      let new_msg = "";
      for (let i = 0; i < msg.length; i++) {
        let item = msg[i];
        if (item.indexOf(task_id) >= 0) {
          let m = item.match(/line \d+/);
          if (m) {
            let linenumber = parseInt(m[0].split(" ")[1]) - 5;
            item = item.replace(m[0], `line ${linenumber}`); //NOSONAR
          }
        }
        item = item.replace(/\s/g, "&nbsp;");
        new_msg += `<p>${item}</p></br>`;
      }
      return new_msg;
    },

    setDrcFile(file) {},

    getGdsFile() {
      let stage = this.layout.stage;

      let id = stage.qedaLib.fileID;
      let qedaLib = stage.qedaLib;
      let lib = qedaLib.QLIB;

      lib.write_gds(id);
      let file = window.QGdstk.FS.readFile(id);

      this.gds_file = new Blob([file]);
    },

    dialogVisibleOk() {
      this.uploadFile();
    },

    uploadFile() {
      let formData = new FormData(); //新建表单对象
      formData.append("files", this.gds_file, "test.gds"); //把文件二进制对象添加到表单对象里
      formData.append("files", this.drc_file, "test.py"); //把文件二进制对象添加到表单对象里

      apiDrcUpload(formData).then((data) => {
        if (data.code == 200) {
          let file_md5 = data.data;
          this.dialogVisible = false;
          this.file_md5 = file_md5;
          this.taskRun(file_md5);
        } else {
          this.$message.error(data.message);
        }
        this.$refs.DRCImport.clearFiles();
      });
    },

    taskRun(file_md5) {
      apiDrcTaskRun({ filename: file_md5, cellname: this.cell_name }).then(
        (data) => {
          if (data.code == 200) {
            this.logs = [];
            this.currentRow.name = "";
            this.currentRow.description = "";
            this.currentRow.data = [];
            this.progress_percent = 0;
            this.progress_status = "warning";
            this.task_stop_enable = true;
            this.task_timer = setInterval(() => {
              apiDrcTaskResult(data.data).then((data) => {
                if (data.code == 200) {
                  if (data.data.task_status == "SUCCESS") {
                    this.progress_percent = 100;
                    this.progress_status = "success";
                    this.task_stop_enable = false;

                    this.logs = JSON.parse(data.data.task_result);

                    for (let i = 0; i < this.logs.length; i++) {
                      this.logs[i].data = this.logs[i].data.splice(0, 999);
                    }
                    clearInterval(this.task_timer);
                  } else {
                    if (this.progress_percent < 90) this.progress_percent += 3;
                  }
                }
                if (data.code == 500) {
                  clearInterval(this.task_timer);
                  this.progress_percent = 0;
                  this.progress_status = "warning";
                  this.task_stop_enable = false;
                  this.$message({
                    dangerouslyUseHTMLString: true,
                    message: this.errorFormatHtml(
                      this.file_md5,
                      data.data.task_result
                    ),
                    type: "error",
                    duration: 2000,
                  });
                }
              });
            }, 1000);
          }
        }
      );
    },

    // 暂停任务
    taskStop() {
      if (this.task_timer) {
        clearInterval(this.task_timer);
        this.progress_percent = 0;
        this.progress_status = "warning";
        this.task_stop_enable = false;
      }
    },

    progressFormat(percentage) {
      this.progressStatus(percentage);
      return percentage === 100 ? "完成" : `${percentage}%`;
    },
    progressStatus(percentage) {
      this.progress_status = percentage === 100 ? "success" : "warning";
    },

    setCurrent(row) {
      this.$refs.logsTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      if (val) {
        this.setCurrent(val);
        this.currentRow = val;
      }
    },
    tabHandleChange() {},
    numberHandleClicked(val) {
      // edge_pairs/polygons/edges
      if (
        this.currentRow.type === "polygons" ||
        this.currentRow.type === "edges"
      ) {
        this.coordinateX = val[0][0].toFixed(3);
        this.coordinateY = val[0][1].toFixed(3);
      } else if (this.currentRow.type === "points") {
        this.coordinateX = val[0].toFixed(3);
        this.coordinateY = val[1].toFixed(3);
      } else {
        this.coordinateX = val[0][0][0].toFixed(3);
        this.coordinateY = val[0][0][1].toFixed(3);
      }

      let info = {
        type: this.currentRow.type,
        data: val,
      };
      bus.$emit("drcClicked", info);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  height: 100%;
  overflow-y: auto;
  // justify-content: space-between;
  .topRegion {
    height: 420px;
  }
  .bottomRegion {
    height: 489px;
    display: flex;
    flex-direction: column;
  }
}

// .el-message--error {
//   white-space: pre-line;
// }
// .el-message--success {
//   white-space: pre-line;
// }

.title {
  width: 100%;
  height: 34px;
  line-height: 34px;
  background: #f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .name {
    margin-left: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .exportBtn1 {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #0c7de6;
    /deep/.el-link--inner {
      margin-left: 3px;
      height: 34px;
      line-height: 34px;
    }
  }
  .exportBtn2 {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #b8d0f7;
    /deep/.el-link--inner {
      margin-left: 3px;
      height: 34px;
      line-height: 34px;
    }
  }
  .button {
    margin-top: 11px;
    margin-right: 14px;
    line-height: 34px;
  }
}

.stopBtn1 {
  color: #de5454;
  height: 34px;
  line-height: 34px;
  .icon-zhongzhi {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
  }
}
.stopBtn2 {
  color: #f0aab3;
  height: 34px;
  line-height: 34px;
  .icon-zhongzhi {
    font-size: 24px;
    margin-right: 10px;
  }
}
.stopBtn3 {
  color: #1eb963;
  height: 34px;
  line-height: 34px;
  margin-right: 10px;
  font-size: 12px;
}

/deep/.el-progress__text {
  display: none;
}
/deep/.el-progress-bar {
  padding-right: 20px;
}

.row {
  width: 100%;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /deep/.el-progress {
    flex: 1;
    height: 34px;
    line-height: 34px;
    padding-left: 10px;
  }
}

.logs {
  margin-right: 1px;
  border-right: 1px solid #ddd;
}

/deep/.el-table {
  .el-table__row {
    height: 31px;
    td:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      .cell {
        padding: 0 !important;
      }
    }
    .cell {
      font-size: 12px;
      line-height: 31px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    td:last-child {
      padding-left: 10px;
      .cell {
        padding: 0 !important;
      }
    }
  }
  .el-table__cell {
    padding: 0 !important;
  }
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: #dce6f8;
}
/deep/.el-table__body tr.current-row > td.el-table__cell {
  background-color: #dce6f8;
}

/deep/.el-collapse-item {
  .el-collapse-item__header {
    background-color: #dddddd;
  }
}

.cardHeader2 {
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  background: #eee;
  border-bottom: 1px B1 #d1d1d1;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  strong {
    height: 100%;
    display: inline-block;
    margin-left: 20px;
  }
}

.arrow {
  float: right;
  margin-right: 10px;
  cursor: pointer;
}
.ruleSeenData {
  font-size: 12px;
  margin: 10px;
  height: 101px;
  overflow-y: scroll;
}

.errorCodeSeenData {
  font-size: 12px;
  margin: 10px 20px;
  flex: 1;
  overflow-y: scroll;
  .number {
    display: inline-block;
    width: 11px;
    margin-right: 40px;
    height: 20px;
    font-size: 12px;
    color: #0c7de6;
    text-decoration: none;
    cursor: pointer;
  }
}

.errorCoordinateSeenData {
  font-size: 13px;
  margin: 5px 10px;
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  span:first-child {
    margin-right: 20px;
  }
}

.el-button {
  width: 77px;
  height: 28px;
  border-radius: 4px;
  padding: 0px 0px;
  font-size: 12px;
  text-align: center;
}

/deep/.el-dialog {
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  overflow: hidden;
  .el-dialog__body {
    height: 80px;
    padding: 20px 15px;
    .upload-demo {
      height: 30px;
      display: flex;
      flex-direction: row;
      .el-upload-list {
        margin-left: 10px;
        line-height: 30px;
      }
      .el-upload-list__item {
        margin-top: 0px;
      }
      .el-upload-list__item-name {
        line-height: 30px;
        max-width: 350px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .ok-button {
    width: 79px;
    height: 30px;
    background: #b0b0b0;
    border-radius: 4px;
    color: #ffffff;
  }
  .cancel-button {
    width: 79px;
    height: 30px;
    background: #b0b0b0;
    border-radius: 4px;
    color: #ffffff;
  }

  .el-select__caret {
    color: #a3a3a3 !important;
  }
  .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  .el-input__icon {
    line-height: inherit;
  }

  .el-input__suffix-inner {
    display: inline-block;
  }
  .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
}
/deep/.el-button--primary {
  width: 80px;
  height: 30px;
  background-color: #4385f2;
  border-color: #4385f2;
  color: #ffffff;
}
/deep/.el-button--default {
  width: 80px;
  height: 30px;
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}
</style>
