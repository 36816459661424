<template>
  <div>
    <!-- 存到器件库 -->
    <div class="saveToLibraryDialog">
      <el-dialog :modal="false" :title="$t('messages.SavetoLibrary')" :visible.sync="saveToLibraryDialogVisible"
        :close-on-click-modal="false" :before-close="saveToLibraryDialogClose" @open="saveStart">
        <el-form :model="saveToLibraryForm" :rules="rules" ref="saveToLibraryForm" class="demo-ruleForm">

          <div class="inputArea">
            <span class="inputInfo">{{$t('messages.Group')}}:</span>
            <el-form-item prop="form_group">
              <el-select v-model="saveToLibraryForm.form_group" filterable :placeholder="$t('messages.placeholder_0')"
                @change="groupChange">
                <el-option v-for="item in options_group" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="inputArea" v-if="saveToLibraryForm.form_group==0">
            <span class="inputInfo">{{ $t('messages.select') }}:</span>
            <el-select v-model="saveToLibraryForm.library_id" filterable :placeholder="$t('messages.placeholder_0')">
              <el-option v-for="item in libraryOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="inputArea" v-if="saveToLibraryForm.form_group == 1">
            <span class="inputInfo">{{ $t('messages.teamSelected') }}:</span>
            <el-select v-model="saveToLibraryForm.value_teamGroup" filterable
              :placeholder="$t('messages.placeholder_0')">
              <el-option v-for="item in options_teamName" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>

          <!-- <div class="inputArea" v-if="saveToLibraryForm.form_group==$t('messages.TeamLibrary')">
            <span class="inputInfo">{{$t('messages.teamSelected')}}:</span>
            <el-form-item prop="form_teamName">
              <el-select v-model="saveToLibraryForm.form_teamName" filterable :placeholder="$t('messages.placeholder_0')" @change="groupSelectTeam">
                <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name" :value="item.snow_id">
                </el-option>
              </el-select>
            </el-form-item>
          </div> -->

          <div class="inputArea">
            <span class="inputInfo">{{$t('messages.name')}}:</span>
            <el-form-item prop="form_deviceName" ref="fileNameRule">
              <el-input v-model="saveToLibraryForm.form_deviceName" :placeholder="$t('messages.placeholder_1')"
                :maxlength="50"></el-input>
            </el-form-item>
          </div>

          <div class="labelSelect">
            <span class="inputInfo">{{$t('messages.Label')}}:</span>
            <el-form-item prop="form_deviceLabel" ref="labelNameRule">
              <el-select v-model="saveToLibraryForm.form_deviceLabel" multiple :multiple-limit="15" filterable
                allow-create default-first-option :placeholder="$t('messages.placeholder_0')">
                <el-option v-for="item in options_deviceCategory" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="textAreaSelect">
            <span class="inputInfo">{{$t('messages.note')}}:</span>
            <el-form-item prop="form_notes">
              <el-input type="textarea" v-model="saveToLibraryForm.form_notes" autosize maxlength="128" show-word-limit>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">

          <el-button type="primary" :loading="saveLoading" class="deactivate" ref="saveToLibraryBtn"
            @click="saveToLibrarySureButton(0)" size="mini" disabled>{{$t("messages.sure")}}</el-button>
          <el-button @click="saveToLibraryCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>

      </el-dialog>
    </div>

    <!-- 存到器件库覆盖提醒 -->
    <div class="errWindow">
      <el-dialog :modal="false" :title="$t('messages.coverWarning')" :visible.sync="coverDialogVisible"
        :close-on-click-modal="false">
        <div class="info">
          <i style="width:22px;height:22px;padding-right:10px;color:#EC3838;font-size:22px"
            class='iconfont icon-jinggao'></i>
          <span>{{$t('messages.powerTips_23')}}</span><br />
        </div>
        <span slot="footer" class="dialog-footer">
          <div>
            <el-button type="primary" @click="coverDialogSureBtn" size="mini">{{$t("messages.sure")}}</el-button>
            <el-button @click="coverDialogCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import bus from "@/components/common/bus";
import { disableBtn, activateBtn } from "./function/fileListPublicFn.js";
import { getLibraryList } from "@/api/api";
import {
  getTeamList_api,
  createMyComponent_api,
  createTeamComponent_api,
} from "@/api/file/file.js";
import {
  getOSCompleteCellData,
  getCompleteCellData,
} from "./function/fileListPublicFn.js";
export default {
  data() {
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    let labelNameRule = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error(this.$t("messages.fileTip4")));
      } else {
        let show = true;
        for (let i in value) {
          let tempValue = [];
          if (value[i].name == undefined) {
            tempValue = value[i];
          } else {
            tempValue = value[i].name;
          }

          let len = 0;
          for (let j = 0; j < tempValue.length; j++) {
            len++;
          }

          if (len > 10) {
            show = false;
          }
        }
        if (!show) {
          callback(new Error(this.$t("messages.fileTip3")));
        } else {
          callback();
        }
      }
    };
    return {
      currentNode: null,
      saveToLibraryDialogVisible: false,
      saveToDeviceTeamInfo: {},
      saveToLibraryForm: {
        form_group: 0,
        form_teamName: "",
        form_deviceName: "",
        form_deviceLabel: [],
        form_notes: "",
        library_id: "",
        value_teamGroup: "",
      },
      libraryOptions: [],
      options_group: [
        {
          // 我的器件库
          label: this.$t("messages.MyLibrary"),
          value: 0,
        },
        {
          // 团队器件库
          label: this.$t("messages.TeamLibrary"),
          value: 1,
        },
      ],
      options_deviceCategory: [
        {
          value: "超导",
        },
        {
          value: "半导",
        },
      ],
      options_teamName: [],

      rules: {
        form_deviceName: [{ validator: fileNameRule, trigger: "blur" }],
        form_deviceLabel: [
          { validator: labelNameRule, trigger: "change", type: "array" },
        ],
      },

      //覆盖弹窗显示
      coverDialogVisible: false,
      saveLoading: false,
    };
  },
  mounted() {
    bus.$on("contextMenuSaveToLib", (data) => {
      this.currentNode = data;
      this.saveToLibraryDialogVisible = true;
      this.options_teamName = this.$store.state.teamNameList;
    });
  },

  methods: {
    async saveStart() {
      let res = await getLibraryList();
      if (res.code == 200000) {
        this.libraryOptions = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
      let team_res = await getTeamList_api({
        start: 0,
        rows: 50,
        key_word: "",
      });
      if (team_res.code == 200000) {
        let team_objs = team_res.data.data;
        this.options_teamName = team_objs.map((item) => {
          return {
            value: item.snow_team_id,
            label: item.team_name,
            gns: item.gns,
          };
        });
      }
    },
    //存到器件库
    saveToLibraryDialogBtnActive() {
      if (this.saveToLibraryDialogVisible) {
        if (
          (this.saveToLibraryForm.form_group !== "" ||
            this.saveToLibraryForm.form_teamName !== "") &&
          this.saveToLibraryForm.form_deviceName !== "" &&
          this.saveToLibraryForm.form_deviceLabel !== ""
        ) {
          activateBtn(this.$refs.saveToLibraryBtn);
        } else {
          disableBtn(this.$refs.saveToLibraryBtn);
        }
      }
    },
    //库选择
    groupChange(data) {
      this.saveToLibraryForm.form_group = data;
    },

    //团队选择
    groupSelectTeam(data) {
      this.saveToLibraryForm.form_teamName = data;
      this.options_teamName.forEach((item) => {
        if (item.snow_id == data) {
          this.saveToDeviceTeamInfo = item;
        }
      });
    },

    //确认按钮
    saveToLibrarySureButton: _.debounce(async function (cover) {
      if (this.saveToLibraryForm.form_group == 0) {
        // 个人
        if (
          !this.saveToLibraryForm.library_id ||
          !this.saveToLibraryForm.form_deviceName ||
          this.saveToLibraryForm.form_deviceLabel.length == 0
        ) {
          this.$message.error(this.$t("messages.paramsIncomplate"));
          return;
        }
      } else if (this.saveToLibraryForm.form_group == 1) {
        if (
          !this.saveToLibraryForm.value_teamGroup ||
          !this.saveToLibraryForm.form_deviceName ||
          this.saveToLibraryForm.form_deviceLabel.length == 0
        ) {
          this.$message.error(this.$t("messages.paramsIncomplate"));
          return;
        }
      }
      this.saveLoading = true;
      const projectId = this.currentNode.parent.parent.data.snow_id,
        fileId = this.currentNode.parent.data.snow_id,
        cellId = this.currentNode.data.snow_id;
      let cell_data = await getCompleteCellData(projectId, fileId, cellId);

      cell_data.name = this.saveToLibraryForm.form_deviceName;
      let cell_refs = cell_data.depend_cells(-1);

      let lib = new Kernel.Library(this.saveToLibraryForm.form_deviceName);
      lib.add_cell(cell_data);
      for (let i = 0; i < cell_refs.length; i++) {
        lib.add_cell(cell_refs[i]);
      }
      // // 转换gds数据
      // let lib_binaryData = lib.to_gds()
      // let size = lib_binaryData.size()
      // let binaryArray = []
      // for (let i = 0; i < size; i++) {
      //     binaryArray.push(lib_binaryData.get(i))
      // }

      lib.write_gds(lib.name);
      let data_u8 = Kernel.FS.readFile(lib.name);
      // let data_u8 = new Uint8Array(binaryArray);
      let formData = new FormData();
      if (this.saveToLibraryForm.form_group == 0) {
        formData.append("name", this.saveToLibraryForm.form_deviceName);
        formData.append("unit", lib.unit);
        formData.append("precision", lib.precision);
        formData.append("label_list", this.saveToLibraryForm.form_deviceLabel);
        formData.append("notes", this.saveToLibraryForm.form_notes);
        formData.append("content", new Blob([data_u8]));
        formData.append("is_cover", cover);
        formData.append("cell_component_type", 1);
        formData.append("library_snow_id", this.saveToLibraryForm.library_id);
        try {
          let res = await createMyComponent_api(formData);
          if (res.code == 200000) {
            if (res.data.name_exists && res.data.name_exists.length > 0) {
              this.coverDialogVisible = true;
            } else {
              this.$message.success(this.$t("messages.saveSuccess"));
              this.cancelSubmitReplacePcellName();
            }
          }
        } catch {
          this.saveLoading = false;
        }
        this.saveLoading = false;
      } else if (this.saveToLibraryForm.form_group == 1) {
        formData.append("name", this.saveToLibraryForm.form_deviceName);
        formData.append("unit", lib.unit);
        formData.append("precision", lib.precision);
        formData.append("label_list", this.saveToLibraryForm.form_deviceLabel);
        formData.append("notes", this.saveToLibraryForm.form_notes);
        formData.append("content", new Blob([data_u8]));
        formData.append("is_cover", cover);
        formData.append("cell_component_type", 1);
        formData.append("team_id", this.saveToLibraryForm.value_teamGroup);
        try {
          let res = await createTeamComponent_api(formData);
          if (res.code == 200000) {
            if (res.data.name_exists && res.data.name_exists.length > 0) {
              this.coverDialogVisible = true;
            } else {
              this.$message.success(this.$t("messages.saveSuccess"));
              this.cancelSubmitReplacePcellName();
            }
          }
        } catch {
          this.saveLoading = false;
        }
        this.saveLoading = false;
      }
    }, 500),
    // async saveToLibrarySureButton(cover) {

    // },

    cancelSubmitReplacePcellName() {
      this.coverDialogVisible = false;
      this.saveToLibraryCancelButton();
    },

    //存到器件库取消按钮
    saveToLibraryCancelButton() {
      this.saveToLibraryDialogVisible = false;
      this.resetSaveToLibrary();
    },

    //关闭按钮
    saveToLibraryDialogClose() {
      this.saveToLibraryDialogVisible = false;
      this.resetSaveToLibrary();
    },

    //取消按钮
    saveToLibraryCancelButton() {
      this.saveToLibraryDialogVisible = false;
      this.resetSaveToLibrary();
    },

    //重置表单
    resetSaveToLibrary() {
      this.$refs.saveToLibraryForm.resetFields();
    },

    //覆盖确认
    coverDialogSureBtn() {
      this.saveToLibrarySureButton(1);
    },

    //覆盖取消
    coverDialogCancelBtn() {
      this.cancelSubmitReplacePcellName();
    },
  },
  watch: {
    "saveToLibraryForm.form_group"(val) {
      this.saveToLibraryDialogBtnActive();
    },
    "saveToLibraryForm.form_teamName"(val) {
      this.saveToLibraryDialogBtnActive();
    },
    "saveToLibraryForm.form_deviceName"(val) {
      this.saveToLibraryDialogBtnActive();
    },
    "saveToLibraryForm.form_deviceLabel"(val) {
      this.saveToLibraryDialogBtnActive();
    },
  },
};
</script>

<style lang="less" scoped>
.saveToLibraryDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 347px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 20px 20px 8px 20px;
    font-weight: 400;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .inputInfo::before {
      content: "*";
      color: red;
      margin-right: 5px;
    }
    /deep/.el-select {
      width: 566px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 566px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
  }
  .labelSelect {
    position: relative;
    width: 100%;
    min-height: 30px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .inputInfo::before {
      content: "*";
      color: red;
      margin-right: 5px;
    }
    /deep/.el-select {
      width: 566px;
      border-radius: 2px;
      .el-input__inner {
        height: 30px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .el-select__tags {
        .el-tag {
          background-color: #ffffff;
          color: #333;
        }
      }
    }
  }
  .textAreaSelect {
    position: relative;
    width: 100%;
    min-height: 30px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .inputInfo::before {
      content: "\00a0\00a0";
      color: red;
      margin-right: 5px;
    }
    /deep/.el-textarea {
      width: 566px;
      .el-textarea__inner {
        background-color: #f4f4f4;
      }
      .el-input__count {
        background-color: #f4f4f4;
      }
    }
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}

//错误弹窗
.errWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 132px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>