<template>
  <div class="symbolWindow">
    <el-dialog :modal="false" :title="$t('messages.openSymbol')" :visible.sync="signDialogVisible"
      :close-on-click-modal="false">
      <div class="symbolWindowBox">
        <div class="symbolWindowInfo">
          <el-tabs v-model="activeIndex" v-for="(item,index) in symbolList" :key="index" :tab-position="tabPosition"
            @tab-click="handleClick">
            <el-tab-pane :label="item.name" :name="`${index}`"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="symbolWindow2D">
          <div class="imgBox">
            <canvas id='encapsulation2D_canvas' @mousewheel="mouseWheel($event)" @mousedown="mouseDownCanvas($event)"
              @mousemove="mouseMoveCanvas($event)" @mouseup="mouseUpCanvas($event)"></canvas>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import bus from "@/components/common/bus";
import { QedaSchemaStage } from "@/utils/graphic_schema/qeda-schema-stage";
import { SchemaDisplayConfig } from "@/utils/graphic_schema/render-util";
import QedaSchemaAxis from "@/utils/graphic_schema/qeda-schema-axis";
import { buildSymbolPath } from "@/utils/graphic_schema/layout/layout-util";
import { getAssociatedCell } from "../fileList/function/fileListPublicFn";
export default {
  data() {
    return {
      signDialogVisible: false,
      tabPosition: "left",
      //符号数组
      symbolList: [],
      activeIndex: "0",
      stage: {},
    };
  },
  methods: {
    get2DCanvas(schema, symbol, width, height) {
      this.axis = new QedaSchemaAxis(width, height, 1.0e-6, 1.0e-9);
      this.axis.rulerX.width = width;
      this.axis.rulerY.height = height;
      this.config = new SchemaDisplayConfig();
      let data = this.getCenterTranFn(symbol.bounding_box, width, height);
      this.axis.scale = data.scale;
      this.axis.dx = data.dx;
      this.axis.dy = data.dy;
      this.axis.updateValue();
      buildSymbolPath(symbol);
      schema.add_symbol_ins(symbol);
      let containerId = "encapsulation2D";
      this.config.onMouseWheel(this.axis.scale);
      this.config.showGrid = false;
      this.config.showXY = false;
      setTimeout(() => {
        let canvas = document.getElementById("encapsulation2D_canvas");
        canvas.width = this.axis.width;
        canvas.height = this.axis.height;
        this.stage = new QedaSchemaStage(
          this.axis,
          containerId,
          schema,
          this.config
        );

        this.stage.render();
      }, 0);
    },
    mouseDownCanvas(event) {
      this.axis.setDrag(true, 1);
    },
    mouseMoveCanvas(event) {
      this.axis.onMouseMove(event);
      this.axis.updateValue();
      this.stage.render();
    },
    mouseUpCanvas(event) {
      this.axis.setDrag(false, 0);
    },
    mouseWheel(event) {
      this.axis.onMouseWheel(event, false);
      this.config.onMouseWheel(this.axis.scale);
      this.axis.updateValue();
      this.stage.render();
    },
    getCenterTranFn(box, width, height) {
      const s = 1 / 2;
      const window_width = width * s;
      const window_height = height * s;
      const box_width = box[1][0] - box[0][0];
      const box_height = box[1][1] - box[0][1];
      let scale = 1;
      if (box_height > box_width) {
        scale = window_height / box_height;
      } else {
        scale = window_width / box_width;
      }
      let dx = width / 2 - (box[0][0] + box_width / 2) * scale;
      let dy = height / 2 + (box[0][1] + box_height / 2) * scale;
      return {
        scale: scale,
        dx: dx,
        dy: dy,
      };
    },
    //更新2d图
    update2D() {
      let symbol = this.symbolList[Number(this.activeIndex)];

      let symbolIns = new Kernel.SymbolIns();
      symbolIns.symbol = symbol;
      let width = 365;
      let height = 365;
      let schema = new Kernel.Schema();
      this.get2DCanvas(schema, symbolIns, width, height);
    },
    handleClick() {
      this.update2D();
    },
    recFileCall(data) {
      if (data.children.length) {
        data.children.forEach((item) => {
          if (item.constructor.name !== "Schema") {
            this.symbolList.unshift(item);
          }
          this.recFileCall(item);
        });
      }
    },
  },
  mounted() {
    bus.$on("openSymbol", async (data) => {
      this.signDialogVisible = true;

      this.symbolList = await getAssociatedCell(
        this.$store.state.proInfo.projectId,
        this.$store.state.fileInfo.fileId,
        data
      );

      if (this.symbolList.length) {
        this.update2D();
      } else {
        let width = 365;
        let height = 365;
        this.axis = new QedaSchemaAxis(width, height, 1.0e-6, 1.0e-9);
        let schema = new Kernel.Schema();
        let containerId = "encapsulation2D";
        this.config = new SchemaDisplayConfig();
        this.stage = new QedaSchemaStage(
          this.axis,
          containerId,
          schema,
          this.config
        );

        this.stage.render();
      }
    });
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.symbolWindow {
  /deep/.el-dialog {
    width: 770px;
    min-height: 471px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
  }
  .symbolWindowBox {
    display: flex;
    flex-direction: row;
    justify-content: left;
    .symbolWindowInfo {
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #f4f4f4;
      width: 210px;
      box-sizing: border-box;
      padding: 15px 10px;
      /deep/.el-tabs__active-bar {
        display: none;
      }
      /deep/.el-tabs__item {
        text-align: left;
        padding: 0;
        height: 30px;
        line-height: 30px;
      }
      /deep/.el-tabs__item .is-active {
        padding: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        display: none;
      }
      /deep/.el-tabs__item:nth-child(2) {
        padding: 0 !important;
      }
    }
    .symbolWindow2D {
      border: 1px solid #ddd;
      border-radius: 4px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f4f4f4;
      margin-left: 10px;
      .imgBox {
        width: 365px;
        height: 365px;
        #encapsulation2D_canvas {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>