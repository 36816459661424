<template>
  <div class="replaceSymbolWindow">
    <el-dialog :modal="false" :title="$t('messages.symbolSubstitution')" :visible.sync="replaceSymbolDialogVisible"
      :close-on-click-modal="false">
      <div class="symbolList">
        <el-tabs v-model="activeIndex" v-for="(item,index) in symbolList" :key="index" tab-position="left"
          @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="`${index}`"></el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="replaceSymbolSureButton" size="mini"
          ref="replaceSymbolSureButton">{{$t("messages.sure")}}</el-button>
        <el-button @click="replaceSymbolCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import {
  getAssociatedCell,
  getCellIndexData,
  isCircle,
  getCompleteCellData,
  batchGetCellFromTableByFileId,
  permissionsJudgmentFn,
  isCellOpened,
  binaryDataToUTF8,
  batchGetCellByCellIdList,
} from "../fileList/function/fileListPublicFn";
import {
  normalCreateFileFn,
  deleteCell,
  setFileParams,
} from "../fileList/function/fileListApiFn";
import {
  getObjectData,
  getCurrentBoardFolderName,
  batchSaveDataFn,
  batchSaveDataFn_fileSize,
} from "../fileList/function/objectStorageFn";
export default {
  data() {
    return {
      replaceSymbolDialogVisible: false,
      symbolList: [],
      activeIndex: "0",
      replaceId: "",
      replaceName: "",
      replaceSymbol: {},
      beReplacedSymbol: {},
      schemaFile: {},
      beReplacedCtor: "",
      replaceCtor: "",
      fileGns: "",
      fileNode: {},
      RCSymbolNode: {},
    };
  },
  methods: {
    async replaceSymbolSureButton() {
      this.replaceSymbolDialogVisible = false;
      let newSymbol = {};
      let fileId = this.fileNode.data.snow_id;
      let projectId = this.fileNode.parent.data.snow_id;
      let projectRadio = this.fileNode.data.isTeam ? 2 : 1;
      let beReplacedParentSchemaArr = [];
      let cellId_list = []

      //深拷贝替换的符号
      newSymbol = this.replaceSymbol.deep_copy(0);

      //获取当前用户已打开的画板中的原理图
      let cellObjList = batchGetCellFromTableByFileId(fileId);

      let schemaObjList = cellObjList.filter(
        (item) => item.cell.type.value === 3
      );

      let schemaList = schemaObjList.map((item) => item.cell);

      // let schemaIdList = schemaList.map(item => item.cell.snow_id)
      for (let i = 0; i < schemaList.length; i++) {
        let lock = false;
        let isAssociated = false;
        //先判断是否有调用被替换的符号
        let associatedCellArr = schemaList[i].depend_symbol_schemas(-1);

        if (
          associatedCellArr.some(
            (item) => item.snow_id === this.beReplacedSymbol.snow_id
          )
        ) {
          isAssociated = true;
        } else {
          isAssociated = false;
        }

        //判断是否正在被使用
        if (isAssociated) {
          if (this.fileNode.data.isTeam) {
            let cellGns = `${this.fileNode.data.gns}/${schemaList[i].snow_id}`;
            let teamRuleData = await permissionsJudgmentFn(cellGns);
            let teamRule = teamRuleData.teamRule;
            if (teamRule == "可删除、编辑") {
              lock = await isCellOpened(schemaList[i].snow_id);
            }
          }

          //先判断该原理图是否正在被其他人使用
          if (lock) {
            return;
          } else {
            beReplacedParentSchemaArr.push(schemaList[i]);
          }
        }
      }

      let beReplacedParentSchemaIdArr = beReplacedParentSchemaArr.map(
        (item) => item.snow_id
      );
      //获取哪些原理图调用了这个符号,以备后面替换
      let cellIndexData = await getCellIndexData(fileId);

      for (let i = 0; i < cellIndexData.length; i++) {
        if (
          cellIndexData[i].cells.some(
            (item) => item.snow_id === this.beReplacedSymbol.snow_id
          )
        ) {
          if (
            !beReplacedParentSchemaIdArr.some(
              (item) => item === cellIndexData[i].snow_id
            )
          ) {
            let lock = false;
            //是个人文件还是团队文件
            if (this.fileNode.data.isTeam) {
              let cellGns = `${this.fileNode.data.gns}/${cellIndexData[i].snow_id}`;
              let teamRuleData = await permissionsJudgmentFn(cellGns);
              let teamRule = teamRuleData.teamRule;
              if (teamRule == "可删除、编辑") {
                lock = await isCellOpened(cellIndexData[i].snow_id);
              }
            }

            //先判断该原理图是否正在被其他人使用
            if (lock) {
              return;
            } else {
              let parentSchema = await getCompleteCellData(
                projectId,
                fileId,
                cellIndexData[i].snow_id
              );
              if (parentSchema !== null) {
                beReplacedParentSchemaArr.push(parentSchema);
              }
            }
          }
        }
      }

      //闭环判断
      for (let i = 0; i < beReplacedParentSchemaArr.length; i++) {
        if (isCircle(newSymbol, beReplacedParentSchemaArr[i])) {
          bus.$emit("errDialog", this.$t("messages.replaceClosedLoop"));
          return;
        }
      }

      this.schemaFile = await getObjectData(fileId);
      newSymbol.name = this.beReplacedName;
      newSymbol.short_name = this.beReplacedShortName;
      let newType = 4;
      if (newSymbol.type.value === 2) {
        this.schemaFile.add_capsymbol(newSymbol);
        let associatedCellList = newSymbol.depend_symbol_schemas(1);
        let cells = [];
        for (let i = 0; i < associatedCellList.length; i++) {
          cells.push({ snow_id: associatedCellList[i].snow_id });
        }
        cellIndexData.push({ snow_id: newSymbol.snow_id, cells });
        newType = 5;
      } else if (newSymbol.type.value === 1) {
        this.schemaFile.add_symbol(newSymbol);
        cellIndexData.push({ snow_id: newSymbol.snow_id, cells: [] });
      }

      //在原理图中做符号替换
      // let batch_data = {};
      let schemaBinaryDataObj = {};
      let replaceSchemaList = this.relpaceSchemaChange(
        newSymbol,
        beReplacedParentSchemaArr
      );
      bus.$emit("reRenderReplaceBoard");
      replaceSchemaList.forEach((item) => {
        //替换后索引修改
        cellIndexData.forEach((indexData) => {
          if (indexData.snow_id === item.snow_id) {
            let associatedCellList = item.depend_symbol_schemas(1);
            let cells = [];
            for (let i = 0; i < associatedCellList.length; i++) {
              cells.push({ snow_id: associatedCellList[i].snow_id });
            }
            indexData.cells = cells;
          }
        });

        //保存数据准备
        let cellBinaryDataObj = item.dump_file(true, 0);
        schemaBinaryDataObj = { ...schemaBinaryDataObj, ...cellBinaryDataObj };
        // let schema_gns = `${this.fileNode.data.gns}/${item.snow_id}`;
        // batch_data[item.snow_id] = {
        //   folder_name: getCurrentBoardFolderName(schema_gns),
        //   file_type: "DIAGRAM_FILE",
        // };
        cellId_list.push(item.snow_id)
      });

      //删除节点再重新新建节点
      let delRes = await deleteCell(
        this.RCSymbolNode.data.gns,
        this.RCSymbolNode.data.category
      );
      if (delRes.code === 200000) {
        //索引数据修改
        let index = cellIndexData.findIndex(
          (item) => item.snow_id === this.beReplacedSymbol.snow_id
        );
        if (index !== -1) {
          cellIndexData.splice(index, 1);
        }

        let all_data = {
          project_list: [],
          file_list: [],
          cell_list: [],
          snow_team_id: "",
          accessor_type: "",
        };
        all_data.snow_team_id = this.fileNode.parent.parent.data.snow_id;
        all_data.accessor_type = projectRadio;
        let cell_gns = `${this.fileNode.data.gns}/${newSymbol.snow_id}`;
        all_data.cell_list.push({
          name: newSymbol.name,
          snow_id: newSymbol.snow_id,
          gns: cell_gns,
          parent_gns: this.fileNode.data.gns,
          parent_snow_id: fileId,
          type: newType,
        });
        let createRes = await normalCreateFileFn(all_data);
        if (createRes.code === 200000) {
          let cellBinaryDataObj = newSymbol.dump_file(true, 0);

          if (this.beReplacedCtor === 2) {
            this.schemaFile.remove_capsymbol(this.beReplacedSymbol);
          } else if (this.beReplacedCtor === 1) {
            this.schemaFile.remove_symbol(this.beReplacedSymbol);
          }

          let fileBinaryDataObj = this.schemaFile.dump_file(true, 0);
          let binaryDataObj = {
            ...fileBinaryDataObj,
            ...schemaBinaryDataObj,
            ...cellBinaryDataObj,
          };

          let u8Array = binaryDataToUTF8(binaryDataObj);

          // batch_data[fileId] = {
          //   folder_name: getCurrentBoardFolderName(this.fileNode.data.gns),
          //   file_type: "DIAGRAM_FILE",
          // };
          // batch_data[newSymbol.snow_id] = {
          //   folder_name: getCurrentBoardFolderName(cell_gns),
          //   file_type: "SYMBOLS_FILE",
          // };
          cellId_list.push(newSymbol.snow_id)
          await setFileParams(fileId, cellIndexData);
          // await batchSaveDataFn(u8Array, batch_data);
          await batchSaveDataFn_fileSize(u8Array, projectId, fileId, 'DIAGRAM_FILE', cellId_list);

          bus.$emit("reRenderReplaceBoard");
          bus.$emit("replaceSymbolRefresh", fileId);
        }
      }
    },

    //原理图中符号替换
    relpaceSchemaChange(newSymbol, beReplacedParentSchemaArr) {
      let replaceSchemaList = [];
      for (let i = 0; i < beReplacedParentSchemaArr.length; i++) {
        let schema = beReplacedParentSchemaArr[i];
        let isChange = false;
        let symbolIns = [...schema.symbol_ins, ...schema.capsymbol_ins];

        for (let j = 0; j < symbolIns.length; j++) {
          if (symbolIns[j].symbol.snow_id === this.beReplacedSymbol.snow_id) {
            symbolIns[j].symbol = newSymbol;
            isChange = true;
          }
        }
        if (isChange) {
          replaceSchemaList.push(schema);
        }
      }

      return replaceSchemaList;
    },

    replaceSymbolCancelButton() {
      this.replaceSymbolDialogVisible = false;
    },
    handleClick() {
      this.replaceCtor = this.symbolList[this.activeIndex].type.value;
      this.replaceSymbol = this.symbolList[this.activeIndex];
    },
  },
  mounted() {
    bus.$on("contextMenuSymbolReplace", async () => {
      this.RCSymbolNode = this.$store.state.currentNode;
      this.fileNode = this.$store.state.currentNode.parent;

      let symbolIdList = [];
      this.symbolList.splice(0, this.symbolList.length)
      this.fileNode.childNodes.forEach((item) => {
        if (item.data.cell_type === 4 || item.data.cell_type === 5) {
          symbolIdList.push(item.data.snow_id);
        }
      });

      let allCellData = await batchGetCellByCellIdList(
        this.fileNode.parent.data.snow_id,
        this.fileNode.data.snow_id,
        symbolIdList
      );
      for (let i = 0; i < allCellData.length; i++) {
        if (symbolIdList.some((item) => item === allCellData[i].snow_id)) {
          this.symbolList.push(allCellData[i]);
        }
      }

      let index = this.symbolList.findIndex(
        (item) => item.snow_id == this.RCSymbolNode.data.snow_id
      );
      this.beReplacedSymbol = this.symbolList.splice(index, 1)[0];

      if (this.symbolList.length) {
        this.beReplacedName = this.beReplacedSymbol.name;
        this.beReplacedShortName = this.beReplacedSymbol.short_name;
        this.beReplacedCtor = this.beReplacedSymbol.type.value;
        this.replaceCtor = this.symbolList[0].type.value;
        this.replaceSymbol = this.symbolList[0];
      }
      this.replaceSymbolDialogVisible = true;
    });
  },
  beforeDestroy() {
    bus.$off("contextMenuSymbolReplace");
  },
};
</script>

<style lang="less" scoped>
.replaceSymbolWindow {
  /deep/.el-dialog {
    width: 400px;
    min-height: 470px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
    min-height: 470px;
    .symbolList {
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #f4f4f4;
      width: 360px;
      min-height: 470px;
      box-sizing: border-box;
      padding: 20px 20px 20px 20px;
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__item {
        text-align: left;
        padding: 0;
        height: 30px;
        line-height: 30px;
      }
      .el-tabs__item .is-active {
        padding: 0;
      }
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__item:nth-child(2) {
        padding: 0 !important;
      }
    }
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>