<template>
  <div class="collCow" ref="collCow">
    <div class="formHeader" @click="formHeaderClicked">
      <span :title="title">{{ title }}</span>
    </div>
    <div class="content-attr">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import bus from "../common/bus";
export default {
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      rawData: "",
      compareData: "", //对比的数据
      attrWidth: 90, //盒子设置的宽度
    };
  },
  methods: {
    formHeaderClicked(row) { //点击头部折叠展开
      const collCow = this.$refs.collCow;
      let width = collCow.offsetWidth;
      if (width == this.attrWidth) {
        //展开
        collCow.style.width = "auto";
        width = collCow.offsetWidth;
        collCow.style.width = this.attrWidth + "px";
        let f = document.body.offsetWidth; // 必加//NOSONAR
        if (width < 220) {
          collCow.style.minWidth = 220 + "px";
          return;
        }
        collCow.style.width = width + "px";
        setTimeout(() => {
          collCow.style.minWidth = width + "px";
        }, 301);
      } else {
        //收缩
        collCow.style.width = this.attrWidth + "px";
        collCow.style.minWidth = "0px";
      }
    },
  },
  mounted() {
    bus.$on("deUpdate", (data) => {
      // 接受数据不同重绘
      if (this.rawData != this.compareData) {
        let collCow=null
        if (data.children[0].children[3]) {
           collCow = data.children[0].children[3];
        }else{
            collCow = data.children[0].children[2];
        }     
        collCow.style.transition = "none"; //折叠时间
        let width = collCow.offsetWidth;
        collCow.style.width = this.attrWidth + "px";
        collCow.style.minWidth = "0px";
        setTimeout(() => {
          //展开
          collCow.style.transition = "width 0.3s"; //折叠时间
          collCow.style.width = "auto";
          width = collCow.offsetWidth;
          collCow.style.width = this.attrWidth + "px";
          let f = document.body.offsetWidth; // 必加//NOSONAR
          collCow.style.width = width + "px";
        }, 100);
        this.rawData = this.compareData;
      }
    });
    // 接受数据用来对比
    bus.$on("isDraw", (data) => {
      this.compareData = data;
    });
  },
};
</script>
<style lang="less" scoped>
.collCow {
  width: 90px;
  overflow: hidden;
  transition: width 0.3s; //折叠时间
  min-width: none;
}
.formHeader {
  margin: 0;
  color: #72767b;
  font-size: 12px;
  background: #eee;
  text-align: center;
  display: flex;
  align-items: center;
  width: 90px !important;
  min-width: 90px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.header-small {
  padding: 2px 0 2px 2px;
  font-size: 11px;
}
.formSectionHeader {
  margin: 0;
  padding: 5px 0 5px 5px;
  color: #72767b;
  font-size: 14px;
  background: #eee;
}
.formHeader > span {
  width: 100%;
}
.formHeader > span.collapsed::after {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  margin-top: -3px;
}
.formHeader > span::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ccc;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}
.content-attr {
  min-width: 220px;
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  > div {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
  // 示意图
  /deep/ .annotaions {
    display: block;
    width: 100%;
    height: 100%;
    background-size: 100% 100% !important;
  }
}
.collCow:first-child {
  .content-attr {
    padding-top: 0 !important;
  }
}
</style>