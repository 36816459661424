<template>
  <div class="errWindow">
    <el-dialog :modal="false" :title="errTitle" :visible.sync="errDialogVisible" :close-on-click-modal="false">
      <div class="info">
        <i style="width:22px;height:22px;padding-right:10px;color:#EC3838;font-size:22px"
          :class="isError?'iconfont icon-sousuoshanchu':'iconfont icon-jinggao'"></i>
        <span>{{errInfo}}</span><br />
      </div>
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button type="primary" @click="sureBtn" size="mini">{{$t("messages.sure")}}</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
export default {
  data() {
    return {
      errDialogVisible: false, //错误弹窗显示
      errTitle: "", //错误提示标题
      errInfo: "", //错误提示信息
      isError: true, //icon图标
    };
  },
  methods: {
    errDialog(title, info, err) {
      this.errTitle = title;
      this.errInfo = info;
      this.isError = err;
      this.errDialogVisible = true;
    },

    sureBtn() {
      this.errDialogVisible = false;
      bus.$emit("ending", true);
      bus.$emit("screen_lock", false);
    },
  },
  mounted() {
    bus.$on("errDialog", (data) => {
      this.errDialog(this.$t("messages.wrong"), data, true);
    });
  },
  beforeDestroy() {
    bus.$off("errDialog");
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__wrapper {
  z-index: 20001 !important;
}
//错误弹窗
.errWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 132px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>