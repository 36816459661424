<template>
  <div>
    <div class="cardHeader">
      <!-- 头部标题 -->
      <div class="header-tabs">
        <div class="header-tabs__item">{{ $t("messages.checkRule") }}</div>
        <div class="header-tabs__item2">
          <el-link class="exportBtn" icon="iconfont icon-daochu" @click="exportTxtReport">
            {{ $t("messages.export") }}</el-link>
        </div>
      </div>
      <div class="lines"></div>
    </div>
    <div class="card-body">
      <div>
        <div class="start">{{$t("messages.startRuleCheck")}}</div>
        <div class="start-time">{{all_res.start}}</div>
      </div>
      <div style="padding:5px 0;">
        <div>
          <div class="net"></div> {{$t("messages.net")}}
        </div>
        <div v-if="Object.keys(all_res.hang_net_check_result).length>0">
          <div :class="errorlevel(all_res.hang_net_check_level)">
            {{all_res.hang_net_check_level == 2 ? $t("messages.ErrorTitle"):$t("messages.WarningTitle")}}{{$t("messages.netError")}}
            <!-- <div class="error_info">项目1</div> -->
            <div @click="itemClick(item, 'hang_net_check_result')" class="error_info"
              v-for="item, key in all_res.hang_net_check_result" :key="key">{{item.display_name}}</div>
          </div>
        </div>
        <div v-if="all_res.hang_pin_check_result.length>0">
          <div :class="errorlevel(all_res.hang_pin_check_level)">
            {{all_res.hang_pin_check_level == 2 ? $t("messages.ErrorTitle"):$t("messages.WarningTitle")}}{{$t("messages.pinError")}}
            <div @click="itemClick(item, 'hang_pin_check_result')" class="error_info"
              v-for="item, key in all_res.hang_pin_check_result" :key="key">{{item.display_name}}</div>
          </div>
        </div>
        <div v-if="Object.keys(all_res.pin_net_check_result).length!==0">
          <div :class="errorlevel(all_res.pin_net_check_level)">
            {{all_res.pin_net_check_level == 2 ? $t("messages.ErrorTitle"):$t("messages.WarningTitle")}}{{$t("messages.pinWarning")}}
            <div @click="itemClick(item, 'pin_net_check_result')" class="error_info"
              v-for="item, key in all_res.pin_net_check_result" :key="key">{{item.display_name}}</div>
          </div>
        </div>
      </div>
      <div style="padding:5px 0;">
        <div>
          <div class="net"></div> {{$t("messages.symbol")}}
        </div>
        <div v-if="all_res.hang_symbol_check_result.length>0">
          <div :class="errorlevel(all_res.hang_symbol_check_level)">
            {{all_res.hang_symbol_check_level == 2 ? $t("messages.ErrorTitle"):$t("messages.WarningTitle")}}{{$t("messages.symbolPinError")}}
            <div @click="itemClick(item, 'hang_symbol_check_result')" class="error_info"
              v-for="item, key in all_res.hang_symbol_check_result" :key="key">{{item.display_name}}</div>
          </div>
        </div>
        <div v-if="all_res.symbol_connect_check_result.length>0">
          <div :class="errorlevel(all_res.symbol_connect_check_level)">
            {{all_res.symbol_connect_check_level == 2 ? $t("messages.ErrorTitle"):$t("messages.WarningTitle")}}{{$t("messages.symbolPortTypeError")}}
            <div @click="itemClick(item, 'symbol_connect_check_result')" class="error_info"
              v-for="item, key in all_res.symbol_connect_check_result" :key="key">{{item.display_name}}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="start">{{$t("messages.EndRuleCheck")}}</div>
        <div class="start-time">{{all_res.end}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../common/bus";
export default {
  data() {
    return {
      all_res: {
        start: "",
        end: "",
        hang_net_check_result: [], //
        hang_pin_check_result: [],
        pin_net_check_result: [],
        hang_symbol_check_result: [],
        symbol_connect_check_result: [],
      },
      res_data: null,
      reportDownloading: false,
    };
  },

  methods: {
    itemClick(ptr, dic_name) {
      bus.$emit("clickCheckedObj", ptr);
      return;
    },
    errorlevel(level) {
      // 1:忽略，2：错误，3：警告
      if (level == 2) {
        return "error_title";
      } else if (level == 3) {
        return "warning_title";
      }
      return "error_title";
    },
    exportTxtReport() {
      if (!this.reportDownloading) return;
      let str = "";
      let startStr = `开始设计规则检查:    ${this.all_res.start}\r\n`;
      let endStr = `结束设计规则检查:    ${this.all_res.end}\r\n`;
      if (this.all_res.hang_net_check_result.length > 0) {
        str += "[错误]网络连接的端口数目错误:";
        for (let i in this.all_res.hang_net_check_result) {
          str += this.all_res.hang_net_check_result[i].display_name + " ";
        }
        str += "\r\n";
      }
      if (this.all_res.hang_pin_check_result.length > 0) {
        str += "[错误]引脚未连接:";
        for (let i in this.all_res.hang_pin_check_result) {
          str += this.all_res.hang_pin_check_result[i].display_name + " ";
        }
        str += "\r\n";
      }
      if (this.all_res.pin_net_check_result.length > 0) {
        str += "[警告]连接到网络上的引脚，网络和引脚名 称不一致:";
        for (let i in this.all_res.pin_net_check_result) {
          str += this.all_res.pin_net_check_result[i].display_name + " ";
        }
        str += "\r\n";
      }
      if (this.all_res.hang_symbol_check_result.length > 0) {
        str += "[错误]符号的端口未连接:";
        for (let i in this.all_res.hang_symbol_check_result) {
          str += this.all_res.hang_symbol_check_result[i].display_name + " ";
        }
        str += "\r\n";
      }
      if (this.all_res.symbol_connect_check_result.length > 0) {
        str += "[错误]符号端口连接类型错误:";
        for (let i in this.all_res.symbol_connect_check_result) {
          str += this.all_res.symbol_connect_check_result[i].display_name + " ";
        }
        str += "\r\n";
      }
      let allStr = startStr + str + endStr;
      let export_blob = new Blob([allStr]);
      let save_link = document.createElement("a");
      save_link.href = window.URL.createObjectURL(export_blob);
      save_link.download = "Report" + this.all_res.start + ".txt";
      this.fakeClick(save_link);
    },
    fakeClick(obj) {
      let ev = document.createEvent("MouseEvents");
      ev.initMouseEvent(
        //NOSONAR
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      obj.dispatchEvent(ev);
    },
  },
  mounted() {
    bus.$on("checkSchemaResult", (data) => {
      this.all_res.hang_net_check_result = [];
      this.all_res.hang_pin_check_result = [];
      this.all_res.pin_net_check_result = [];
      this.all_res.hang_symbol_check_result = [];
      this.all_res.symbol_connect_check_result = [];
      this.all_res.hang_net_check_level = data.config[0].selector;
      this.all_res.hang_pin_check_level = data.config[2].selector;
      this.all_res.pin_net_check_level = data.config[1].selector;
      this.all_res.hang_symbol_check_level = data.config[3].selector;
      this.all_res.symbol_connect_check_level = data.config[4].selector;
      // hang_net_check_result is {"net_name": [CpLine]}
      Object.keys(data.hang_net_check_result).forEach((item) => {
        let obj = {};
        obj.display_name = item;
        obj.ptr = data.hang_net_check_result[item];

        obj.bounding_box = [
          [Number.MAX_VALUE, Number.MAX_VALUE],
          [-Number.MAX_VALUE, -Number.MAX_VALUE],
        ];
        obj.ptr.forEach((cpline) => {
          for (let i = 0; i < obj.bounding_box.length; i++) {
            for (let j = 0; j < obj.bounding_box[i].length; j++) {
              if (
                i == 0 &&
                cpline.bounding_box[i][j] < obj.bounding_box[i][j]
              ) {
                obj.bounding_box[i][j] = cpline.bounding_box[i][j];
              }
              if (
                i == 1 &&
                cpline.bounding_box[i][j] > obj.bounding_box[i][j]
              ) {
                obj.bounding_box[i][j] = cpline.bounding_box[i][j];
              }
            }
          }
        });
        this.all_res.hang_net_check_result.push(obj);
      });
      // hang_pin_check_result is [Pin]
      data.hang_pin_check_result.forEach((item) => {
        let obj = {};
        obj.display_name = item.name.text;
        obj.ptr = item;
        obj.bounding_box = item.bounding_box;
        this.all_res.hang_pin_check_result.push(obj);
      });
      // pin_net_check is {Pin: [CpLine]}
      Object.keys(data.pin_net_check).forEach((item) => {
        let obj = {};
        obj.ptr = data.pin_net_check[item];

        obj.display_name =
          obj.ptr.pin.name.text + "---" + obj.ptr.lines[0].net_name;
        obj.bounding_box = [
          [Number.MAX_VALUE, Number.MAX_VALUE],
          [-Number.MAX_VALUE, -Number.MAX_VALUE],
        ];
        obj.ptr.lines.forEach((cpline) => {
          for (let i = 0; i < obj.bounding_box.length; i++) {
            for (let j = 0; j < obj.bounding_box[i].length; j++) {
              if (
                i == 0 &&
                cpline.bounding_box[i][j] < obj.bounding_box[i][j]
              ) {
                obj.bounding_box[i][j] = cpline.bounding_box[i][j];
              }
              if (
                i == 1 &&
                cpline.bounding_box[i][j] > obj.bounding_box[i][j]
              ) {
                obj.bounding_box[i][j] = cpline.bounding_box[i][j];
              }
            }
          }
        });
        for (let i = 0; i < obj.bounding_box.length; i++) {
          for (let j = 0; j < obj.bounding_box[i].length; j++) {
            if (
              i == 0 &&
              obj.ptr.pin.bounding_box[i][j] < obj.bounding_box[i][j]
            ) {
              obj.bounding_box[i][j] = obj.ptr.pin.bounding_box[i][j];
            }
            if (
              i == 1 &&
              obj.ptr.pin.bounding_box[i][j] > obj.bounding_box[i][j]
            ) {
              obj.bounding_box[i][j] = obj.ptr.pin.bounding_box[i][j];
            }
          }
        }
        this.all_res.pin_net_check_result.push(obj);
      });
      // hang_symbol_check_result is [SymbolIns]
      data.hang_symbol_check_result.forEach((item) => {
        let obj = {};
        obj.display_name = item.ins_name;
        obj.ptr = item;
        obj.bounding_box = item.bounding_box;
        this.all_res.hang_symbol_check_result.push(obj);
      });
      // symbol_connect_check_result is [[Port, Port]]
      data.symbol_connect_check_result.forEach((item) => {
        let obj = {};
        let port_0_name = item[0]
          ? item[0].belong_to.ins_name + ":" + item[0].text
          : "NULL";
        let port_1_name = item[1]
          ? item[1].belong_to.ins_name + ":" + item[1].text
          : "NULL";
        obj.display_name = port_0_name + "---" + port_1_name;
        obj.ptr = item;
        obj.bounding_box = [
          [Number.MAX_VALUE, Number.MAX_VALUE],
          [-Number.MAX_VALUE, -Number.MAX_VALUE],
        ];
        obj.ptr.forEach((port) => {
          for (let i = 0; i < obj.bounding_box.length; i++) {
            for (let j = 0; j < obj.bounding_box[i].length; j++) {
              if (i == 0 && port && port.pos[j] - 10 < obj.bounding_box[i][j]) {
                obj.bounding_box[i][j] = port.pos[j] - 10;
              }
              if (i == 1 && port && port.pos[j] + 10 > obj.bounding_box[i][j]) {
                obj.bounding_box[i][j] = port.pos[j] + 10;
              }
            }
          }
        });
        this.all_res.symbol_connect_check_result.push(obj);
      });
      this.all_res.start = data.start;
      this.all_res.end = data.end;
      this.res_data = data;
      this.reportDownloading = true;
      bus.$emit("openRuleLog", true);
    });
  },
};
</script>

<style lang="less" scoped>
.header-tabs {
  display: flex;
  width: 100%;
  background: #f4f4f4;
  border-bottom: 1px solid #dddddd;

  .header-tabs__item {
    padding: 7px;
    background: #f4f4f4;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    width: 50%;
    // cursor: pointer;
  }

  .header-tabs__item2 {
    padding: 5px;
    text-align: right;
    background: #f4f4f4;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    width: 50%;
  }
  .exportBtn {
    color: #0c7de6;
  }
}
.card-body {
  font-size: 12px;
  padding: 12px;
  .start {
    display: inline-block;
    color: #333333;
  }
  .start-time {
    display: inline-block;
    color: #838383;
    padding-left: 10px;
  }
  .net {
    display: inline-block;
    height: 10px;
    width: 2px;
    background: #378ddd;
  }
  .error_title {
    display: inline-block;
    color: #ff0000;
    padding: 5px 0;
  }
  .warning_title {
    display: inline-block;
    color: #ff9600;
  }
  .error_info {
    color: #2f88e8;
    padding: 0 2px;
    cursor: pointer;
  }
}
</style>