<template>
  <div class="SchemaSaveAsDialog">
    <el-dialog :modal="false" :title="$t('messages.saveAs')" :visible.sync="saveAsDialogVisible" :close-on-click-modal="false" :before-close="saveAsDialogClose">
      <el-form :model="saveAsForm" :rules="rules" ref="saveAsForm" class="demo-ruleForm">
        <!-- 项目归属 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.itemBelong")}}:</span>
          <el-form-item prop="form_projectRadio">
            <div class="radioArea">
              <el-radio v-model="saveAsForm.form_projectRadio" label="1" @change="projectBelong">{{$t("messages.person")}}</el-radio>
              <el-radio v-model="saveAsForm.form_projectRadio" label="2" @change="projectBelong">{{$t("messages.team")}}</el-radio>
            </div>
          </el-form-item>
        </div>

        <!-- 用户/团队名 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.userAndTeamName")}}:</span>
          <el-form-item prop="form_userName" v-if="saveAsForm.form_projectRadio==='1'">
            <el-select class="userSelect" v-model="saveAsForm.form_userName" filterable :placeholder="$t('messages.personName')" disabled>
              <el-option v-for="item in options_userName" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="form_teamName" v-if="saveAsForm.form_projectRadio==='2'">
            <el-select v-model="saveAsForm.form_teamName" :placeholder="$t('messages.teamName')" @change="teamNameSelect">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name" :value="item.snow_id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <!-- 项目名 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.projectName")}}:</span>
          <i class="el-icon-arrow-down projectNameIcon" ref="projectNameIcon"></i>
          <el-form-item prop="form_projectName" ref="projectNameRule">
            <el-autocomplete @focus="projectNameFocus" @blur="projectNameBlur" class="inline-input" v-model="saveAsForm.form_projectName" :fetch-suggestions="projectNameSearch" :placeholder="$t('messages.pleaseEnterProjectName')" @select="handleSelectProName" :maxlength="50">
            </el-autocomplete>
          </el-form-item>
        </div>

        <!-- 文件名 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.fileName")}}:</span>
          <i class="el-icon-arrow-down fileNameIcon" ref="fileNameIcon"></i>
          <el-form-item prop="form_fileName" ref="fileNameRule">
            <el-autocomplete @focus="fileNameFocus" @blur="fileNameBlur" class="inline-input" v-model="saveAsForm.form_fileName" :fetch-suggestions="fileNameSearch" :placeholder="$t('messages.pleaseEnterFileName')" @select="handleSelectFileName" :maxlength="50">
            </el-autocomplete>
          </el-form-item>
        </div>

        <!-- 原理图名称 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.SchematicName")}}:</span>
          <el-form-item prop="form_saveAsName">
            <el-input v-model="saveAsForm.form_saveAsName" :placeholder="$t('messages.pleaseEnterSchematicName')" :maxlength="50"></el-input>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="deactivate" ref="saveAsDialogBtn" @click="saveAsDialogSureButton" size="mini" disabled>{{$t("messages.sure")}}</el-button>
        <el-button @click="saveAsDialogCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import {
  disableBtn,
  activateBtn,
  getUserProNameList,
  getTeamProjectNameList,
  getCellFromTable,
  binaryDataToUTF8,
  getCellIndexData,
  deepCopyRemaneSchema,
} from "./function/fileListPublicFn.js";
import {
  getObjectData,
  getFolderName,
  batchSaveDataFn,
  batchSaveDataFn_fileSize,
} from "./function/objectStorageFn";
import {
  getFileList,
  searchFileList,
  setFileParams,
  normalCreateFileFn,
  getCellList,
} from "./function/fileListApiFn.js";
export default {
  data() {
    let projectNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else if (!/^[\s&()~+-_\[\]0-9a-zA-Z\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else {
        callback();
      }
    };
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    return {
      saveAsDialogVisible: false,
      saveAsForm: {
        form_projectRadio: "1",
        form_userName: "",
        form_teamName: "",
        form_projectName: "",
        form_fileName: "",
        form_saveAsName: "",
      },
      cell_type: "1", //另存为单元的种类
      options_userName: [], //个人用户数据数组
      options_teamName: [], //团队数据数组
      options_proName: [], //项目数据数组
      options_fileName: [], //文件数据数组
      userId: "", //个人id
      user_gns: "", //个人GNS
      team_gns: "", //团队GNS
      teamId: "", //团队id
      project_gns: "", //项目GNS
      file_gns: "", //文件GNS

      rules: {
        form_projectName: [{ validator: projectNameRule, trigger: "blur" }],
        form_fileName: [{ validator: fileNameRule, trigger: "blur" }],
        form_saveAsName: [{ validator: fileNameRule, trigger: "blur" }],
      },
    };
  },
  mounted() {
    bus.$on("saveAs", (data) => {
      this.saveAsDialogVisible = true;
      this.saveAsForm.form_userName = localStorage.getItem("userName");
      this.userId = localStorage.getItem("userId");
      this.options_teamName = this.$store.state.teamNameList;
    });
  },
  methods: {
    saveAsDialogBtnActive() {
      if (this.saveAsDialogVisible) {
        if (
          (this.saveAsForm.form_userName !== "" ||
            this.saveAsForm.form_teamName !== "") &&
          this.saveAsForm.form_projectName !== "" &&
          this.saveAsForm.form_fileName !== "" &&
          this.saveAsForm.form_saveAsName !== ""
        ) {
          activateBtn(this.$refs.saveAsDialogBtn);
        } else {
          disableBtn(this.$refs.saveAsDialogBtn);
        }
      }
    },

    //设置表单数据
    setFormTeam(teamName, teamId, team_gns) {
      this.saveAsForm.form_teamName = teamName;
      this.teamId = teamId;
      this.team_gns = team_gns;
    },

    setFormProject(projectRadio, projectName, projectGns) {
      this.saveAsForm.form_projectRadio = projectRadio;
      this.saveAsForm.form_projectName = projectName;
      this.project_gns = projectGns;
    },

    setFormFile(fileName, fileGns) {
      this.saveAsForm.form_fileName = fileName;
      this.file_gns = fileGns;
    },

    setFormCell(saveAsName) {
      this.saveAsForm.form_saveAsName = saveAsName;
    },

    //项目所属
    projectBelong() {
      this.saveAsForm.form_userName = localStorage.getItem("userName");
      this.setFormTeam("", "", "");
      this.setFormProject(this.saveAsForm.form_projectRadio, "", "");
      this.setFormFile("", "");
      this.setFormCell("");
    },

    //团队名选择
    teamNameSelect(data) {
      this.options_teamName.forEach((item) => {
        if (item.snow_id === data) {
          this.setFormTeam(item.name, item.snow_id, item.gns);
          this.setFormProject(this.saveAsForm.form_projectRadio, "", "");
          this.setFormFile("", "");
          this.setFormCell("");
        }
      });
    },

    //项目选择聚焦
    async projectNameFocus() {
      this.$refs.projectNameIcon.classList.add("inputActive");
      if (this.saveAsForm.form_projectRadio === "1") {
        await this.getUserProName();
      } else {
        await this.getTeamProjectName();
      }
    },

    async getUserProName() {
      this.options_proName = await getUserProNameList(0, 50);
    },

    async getTeamProjectName() {
      this.options_proName = await getTeamProjectNameList(
        0,
        50,
        this.saveAsForm.form_teamName,
        this.teamId,
        this.team_gns
      );
    },

    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.match(queryString) !== null;
      };
    },

    //项目搜索
    projectNameSearch(queryString, cb) {
      let options = this.options_proName;
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      // callback 返回建议列表的数据

      if (results.length != 0) {
        if (results[0].value === this.saveAsForm.form_projectName) {
          this.project_gns = results[0].project_gns;
        } else {
          this.project_gns = "";
        }
      } else {
        this.project_gns = "";
      }

      this.file_gns = "";
      this.saveAsForm.form_fileName = "";
      this.saveAsForm.form_saveAsName = "";
      cb(results);
    },

    //选择项目名
    handleSelectProName(item) {
      this.saveAsForm.form_projectName = item.value;
      this.project_gns = item.project_gns;
      setTimeout(() => {
        this.$refs.projectNameRule.clearValidate();
      }, 500);
    },

    //项目选择失焦
    projectNameBlur() {
      this.$refs.projectNameIcon.classList.remove("inputActive");
    },

    //文件选择聚焦
    async fileNameFocus() {
      this.options_fileName.splice(0, this.options_fileName.length);
      this.$refs.fileNameIcon.classList.add("inputActive");
      let res = {};
      if (this.project_gns !== "") {
        if (this.saveAsForm.form_projectRadio === "1") {
          res = await searchFileList(0, 200, this.project_gns, "个人文件", 3);
        } else {
          res = await searchFileList(0, 200, this.project_gns, "团队文件", 3);
        }
        if (res.code == 200000) {
          this.file_gns = "";
          res.data.data.forEach((item) => {
            this.options_fileName.push({
              value: item._source.name,
              file_gns: item._source.gns,
            });
          });
        }
      }
    },

    //文件选择失焦
    fileNameBlur() {
      this.$refs.fileNameIcon.classList.remove("inputActive");
    },

    //文件搜索
    fileNameSearch(queryString, cb) {
      let options = this.options_fileName;
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      if (this.project_gns != "") {
        if (results.length != 0) {
          if (results[0].value === this.saveAsForm.form_fileName) {
            this.file_gns = results[0].file_gns;
          } else {
            this.file_gns = "";
          }
        } else {
          this.file_gns = "";
        }
      } else {
        this.file_gns = "";
      }
      this.saveAsForm.form_saveAsName = "";
      cb(results);
    },

    //选择文件名
    handleSelectFileName(item) {
      this.saveAsForm.form_fileName = item.value;
      this.file_gns = item.file_gns;
      this.$refs.fileNameRule.clearValidate();
    },

    //另存为确认
    async saveAsDialogSureButton() {
      let isTeam = this.saveAsForm.form_projectRadio === "1" ? false : true;
      let projectId = "";
      let projectGns = "";
      let fileId = "";
      let fileGns = "";
      let project = {};
      let schemaFile = {};
      let cellIndexData = [];
      let associatedCellList = [];
      let cellId_list = [];
      let cellBinaryDataObj = {};
      let all_data = {
        project_list: [],
        file_list: [],
        cell_list: [],
        snow_team_id: "",
        accessor_type: "",
      };

      let batch_data = {};
      let u8Array = [];
      let cellId = this.$store.state.cellInfo.cellId;
      let schema = getCellFromTable(cellId);
      let saveAsList = schema.deep_copy_logic();
      let saveAsSchema = saveAsList.filter(
        (item) => item.name === schema.name
      )[0];

      let topId = isTeam ? this.teamId : "";
      let topGns = isTeam ? this.team_gns : "";

      all_data.snow_team_id = topId;
      all_data.accessor_type = isTeam ? 2 : 1;

      if (this.project_gns !== "") {
        projectId = this.project_gns.split("/")[this.project_gns.split("/").length - 1];
        project = await getObjectData(projectId);
        projectGns = this.project_gns;
      } else {
        project = new Kernel.Project();
        projectId = project.snow_id;
        project.name = this.saveAsForm.form_projectName;
        projectGns = isTeam
          ? `gns://${topId}/${projectId}`
          : `gns://${projectId}`;
        all_data.project_list.push({
          name: this.saveAsForm.form_projectName,
          snow_id: projectId,
          gns: projectGns,
          parent_gns: topGns,
          parent_snow_id: topId,
          type: "",
        });
      }

      if (this.file_gns !== "") {
        fileId = this.file_gns.split("/")[this.file_gns.split("/").length - 1];
        schemaFile = await getObjectData(fileId);
        cellIndexData = await getCellIndexData(fileId);
        if (cellIndexData === null) {
          cellIndexData = [];
        }
        fileGns = this.file_gns;
      } else {
        schemaFile = new Kernel.SchemaFile();
        fileId = schemaFile.snow_id;
        project.add_schemafile(schemaFile);
        fileGns = `${projectGns}/${fileId}`;
        batch_data[projectId] = {
          folder_name: getFolderName(topId, projectGns, isTeam),
          file_type: "LAYOUT_FILE",
        };

        all_data.file_list.push({
          name: this.saveAsForm.form_fileName,
          snow_id: fileId,
          gns: fileGns,
          parent_gns: projectGns,
          parent_snow_id: projectId,
          type: 3,
        });
      }

      schemaFile.name = this.saveAsForm.form_fileName;
      saveAsSchema.name = this.saveAsForm.form_saveAsName;

      //重命名
      let targetFileNode = {
        data: { gns: fileGns, snow_id: fileId },
        childNodes: [],
      };
      let cellList_res = await getCellList(
        0,
        500,
        fileGns,
        isTeam ? "团队cell" : "个人cell"
      );
      if (cellList_res.code === 200000) {
        for (let j = 0; j < cellList_res.data.data.length; j++) {
          targetFileNode.childNodes.push({
            data: {
              name: cellList_res.data.data[j]._source.name,
              snow_id: cellList_res.data.data[j]._source.snow_id,
              gns: cellList_res.data.data[j]._source.gns,
              isTeam: isTeam,
              cell_type: cellList_res.data.data[j]._source.types,
            },
          });
        }
      }

      saveAsSchema = deepCopyRemaneSchema(saveAsSchema, targetFileNode);
      saveAsSchema.name = this.saveAsForm.form_saveAsName;
      associatedCellList = saveAsSchema.depend_symbol_schemas(-1);
      associatedCellList.push(saveAsSchema);

      for (let i = 0; i < associatedCellList.length; i++) {
        let cellIndexNew = {
          snow_id: associatedCellList[i].snow_id,
          cells: [],
        };
        let cellGns = `${fileGns}/${associatedCellList[i].snow_id}`;
        let cells = [];
        if (associatedCellList[i].type.value === 1) {
          all_data.cell_list.push({
            name: associatedCellList[i].name,
            snow_id: associatedCellList[i].snow_id,
            gns: cellGns,
            parent_gns: fileGns,
            parent_snow_id: fileId,
            type: 4,
          });
          schemaFile.add_symbol(associatedCellList[i]);
          // batch_data[associatedCellList[i].snow_id] = {
          //   folder_name: getFolderName(topId, cellGns, isTeam),
          //   file_type: "SYMBOLS_FILE",
          // };
        } else if (associatedCellList[i].type.value === 2) {
          all_data.cell_list.push({
            name: associatedCellList[i].name,
            snow_id: associatedCellList[i].snow_id,
            gns: cellGns,
            parent_gns: fileGns,
            parent_snow_id: fileId,
            type: 5,
          });
          // batch_data[associatedCellList[i].snow_id] = {
          //   folder_name: getFolderName(topId, cellGns, isTeam),
          //   file_type: "SYMBOLS_FILE",
          // };
          schemaFile.add_capsymbol(associatedCellList[i]);
          if (associatedCellList[i].schema !== null)
            cells.push({ snow_id: associatedCellList[i].schema.snow_id });
        } else if (associatedCellList[i].type.value === 3) {
          all_data.cell_list.push({
            name: associatedCellList[i].name,
            snow_id: associatedCellList[i].snow_id,
            gns: cellGns,
            parent_gns: fileGns,
            parent_snow_id: fileId,
            type: 3,
          });
          // batch_data[associatedCellList[i].snow_id] = {
          //   folder_name: getFolderName(topId, cellGns, isTeam),
          //   file_type: "DIAGRAM_FILE",
          // };
          schemaFile.add_schema(associatedCellList[i]);
          let symbolIns = [
            ...associatedCellList[i].symbol_ins,
            ...associatedCellList[i].capsymbol_ins,
          ];
          for (let j = 0; j < symbolIns.length; j++) {
            cells.push({ snow_id: symbolIns[j].symbol.snow_id });
          }
        }
        cellId_list.push(associatedCellList[i].snow_id)
        cellIndexNew.cells = cells;
        cellIndexData.push(cellIndexNew);
        let binaryDataObj = associatedCellList[i].dump_file(true, 0);
        cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj };
      }
      // batch_data[fileId] = {
      //   folder_name: getFolderName(topId, fileGns, isTeam),
      //   file_type: "DIAGRAM_FILE",
      // };
      let fileBinaryDataObj = schemaFile.dump_file(true, 0);
      fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj };
      let projectBinaryDataObj = project.dump_file(true, 0);
      projectBinaryDataObj = { ...projectBinaryDataObj, ...fileBinaryDataObj };
      u8Array = binaryDataToUTF8(projectBinaryDataObj);

      let res = await normalCreateFileFn(all_data);
      if (res.code === 200000) {
        await setFileParams(fileId, cellIndexData);
        // await batchSaveDataFn(u8Array, batch_data);
        await batchSaveDataFn_fileSize(u8Array, projectId, fileId, 'DIAGRAM_FILE', cellId_list);

        let topData = {
          topId,
          isTeam,
        };
        let projectData = {
          name: this.saveAsForm.form_projectName,
          snow_id: projectId,
          gns: projectGns,
          isTeam,
        };
        let fileData = {
          name: this.saveAsForm.form_fileName,
          snow_id: fileId,
          gns: fileGns,
          isTeam,
        };
        let expandData = [{ category: "CELL", topData, projectData, fileData }];

        bus.$emit("refreshNode", expandData);
        setTimeout(() => {
          this.$message.success("另存为成功");
        }, 500);
      }
      this.resetSaveAsDialog();
      this.saveAsDialogVisible = false;
    },

    //另存为取消
    saveAsDialogCancelButton() {
      this.resetSaveAsDialog();
      this.saveAsDialogVisible = false;
    },

    //另存为关闭
    saveAsDialogClose() {
      this.resetSaveAsDialog();
      this.saveAsDialogVisible = false;
    },

    //另存为重置
    resetSaveAsDialog() {
      this.$refs.saveAsForm.resetFields();
      this.userId = "";
      this.user_gns = "";
      this.team_gns = "";
      this.teamId = "";
      this.project_gns = "";
      this.file_gns = "";
      this.setFormTeam("", "", "");
      this.setFormProject("1", "", "");
      this.setFormFile("", "");
      this.setFormCell("");
    },
  },
  watch: {
    "saveAsForm.form_userName"(val) {
      this.saveAsDialogBtnActive();
    },
    "saveAsForm.form_teamName"(val) {
      this.saveAsDialogBtnActive();
    },
    "saveAsForm.form_projectName"(val) {
      this.saveAsDialogBtnActive();
    },
    "saveAsForm.form_fileName"(val) {
      this.saveAsDialogBtnActive();
    },
    "saveAsForm.form_saveAsName"(val) {
      this.saveAsDialogBtnActive();
    },
  },
};
</script>

<style lang="less" scoped>
.SchemaSaveAsDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 480px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .userSelect {
      /deep/.el-input__inner {
        color: #c0c4cc !important;
      }
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .projectNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .fileNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
    }
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>