<template>
  <div class="home" @click="closeHandling" @contextmenu.prevent>
    <canvas id="fill_canvas" width="250" height="150" style="display: none;"></canvas>
    <!-- 头部导航 -->
    <header>
      <!-- 引入头部组件 -->
      <Header />
    </header>
    <!-- 工具栏 -->
    <Sidebar class="sidebar" v-if="cell_type == '1' || cell_type == '2'" :readOnly="readOnly"></Sidebar>
    <SchematicSidebar class="sidebar" v-else-if="cell_type == '3'" :readOnly="readOnly"></SchematicSidebar>
    <EmptySidebar class="sidebar" v-else></EmptySidebar>
    <content>
      <div v-show="loading" class="mask">
        <el-table v-loading="loading" :element-loading-text="$t('messages.waitLoading')"
          style="width: 100%; height: 100%" background="#fff"></el-table>
      </div>
      <!-- 文件列表 -->
      <div id="files" ref="files" style="width: 220px">
        <Scale v-show="!isShrinkLeft"></Scale>
        <div id="dragLineLeft">
          <div id="leftDragBtn" @click="leftDragBtnClcik">
            <img alt=""
              :src="isShrinkLeft ? require('../assets/icons/file/shousuo-zuo.svg') : require('../assets/icons/file/shousuo-you.svg')"
              draggable="false" />
          </div>
        </div>
      </div>
      <!-- 画板 -->
      <!-- 出口 -->
      <router-view></router-view>
      <!-- 参数 -->
      <div class="layers" id="layers" style="width: 0px" v-if="isSelectFileData"
        :style="{ 'min-width': isOpenCell ? '288px' : '0px' }">
        <div class="readOnlyMask" v-if="readOnly && !selectRuleLog"></div>
        <div id="rightDragBtn" @click="rightDragBtnClcik">
          <img alt=""
            :src="isShrinkRight ? require('../assets/icons/file/shousuo-you.svg') : require('../assets/icons/file/shousuo-zuo.svg')"
            draggable="false" />
        </div>
        <div id="dragLineRight"></div>
        <OpenSymbol />
        <Encapsulation />
        <OutputNetlist />
        <SaveToSymbolLibrary />
        <ReplaceSymbol />
        <CreateNewFileDialog />
        <WarningDialog />
        <SaveToLibDialog />
        <ImportDialog />
        <SchemaSaveAsDialog />
        <CopyToDialog />
        <DeleteDialog />
        <!-- <Right v-show="selectLayer"></Right> -->
        <LayerList v-show="selectLayer"></LayerList>
        <Device v-show="selectConp"></Device>
        <Log v-show="selectLog"></Log>
        <Drc v-show="selectDrc"></Drc>
        <RuleLog v-show="selectRuleLog"></RuleLog>
        <Symbols v-show="selectSymbol"></Symbols>
      </div>
      <div v-show="isShowRightMenu">
        <div class="rightMenu">
          <!-- <div class="rightMenu" v-if="!rightDisabled"> -->
          <!-- <div class="readOnlyMask" v-if="readOnly"></div> -->
          <!-- 图层 -->
          <div v-if="cell_type == '1' || cell_type == '2'" :class="{ active: selectLayer, rightMenu__item: true }"
            @click="toLayer">
            {{ $t('messages.layer') }}
          </div>
          <!-- 器件库 -->
          <div v-if="cell_type == '1' || cell_type == '2'" :class="{ active: selectConp, rightMenu__item: true }"
            @click="toDeviceTab">{{ $t('messages.component') }}</div>
          <!-- 符号 -->
          <div v-else-if="cell_type == '3'" :class="{ active: selectSymbol, rightMenu__item: true }"
            @click="toSymbolTab">
            {{ $t('messages.Symbol') }}
          </div>
          <!-- 日志 -->
          <div v-if="cell_type == '1'" :class="{ active: selectLog, rightMenu__item: true }" @click="toLog">
            {{ $t('messages.record') }}
          </div>
          <!-- DRC -->
          <div v-if="cell_type == '1' || cell_type == '2'" :class="{ active: selectDrc, rightMenu__item: true }"
            @click="toDrcTab">DRC</div>
          <div v-if="cell_type == '3'" :class="{ active: selectRuleLog, rightMenu__item: true }" @click="toRuleLog">
            {{ $t('messages.record') }}
          </div>
        </div>
        <!-- <div class="rightMenu" v-else>
          <div v-if="cell_type == '1' || cell_type == '2'" class="rightMenu__item disabled">{{ $t('messages.layer') }}</div>
          <div v-if="cell_type == '1' || cell_type == '2'" class="rightMenu__item disabled">{{ $t('messages.component') }}</div>
          <div v-else-if="cell_type == '3'" class="rightMenu__item disabled">{{ $t('messages.symbol') }}</div>
          <div class="rightMenu__item disabled">{{ $t('messages.record') }}</div>
        </div> -->
      </div>
      <div class="drcMiniWindow" v-drag v-show="drcDrag && isOpenDrc">
        <div class="medium">
          <div class="centers"><span>{{$t('messages.Drc')}}</span></div>
          <div class="icons">
            <!-- <i class="el-icon-minus" style="font-size: 24px" @click="minusDrc"></i> -->
            <i class="el-icon-full-screen" style="font-size: 24px" @click="maxDrc"></i>
            <i class="el-icon-close" style="font-size: 24px" @click="closeDrc"></i>
          </div>
        </div>
      </div>
    </content>
  </div>
</template>
<script>
import bus from "../components/common/bus.js";
import Header from "@/components/homes/header/Header.vue";
import Sidebar from "@/components/homes/sidebar/Sidebar.vue";
import SchematicSidebar from "@/components/homes/sidebar/SchematicSidebar.vue";
import EmptySidebar from "@/components/homes/sidebar/EmptySidebar.vue";
import Right from "@/components/homes/Right.vue";
import LayerList from "@/components/homes/rightList/LayerList.vue";
import AutoTool from "@/components/homes/AutoTool.vue";
import Scale from "@/components/homes/Scale.vue"; //文件列表板块
import OpenSymbol from "@/components/homes/fileList/OpenSymbol.vue"; //打开符号
import Encapsulation from "@/components/homes/fileList/Encapsulation.vue"; //封装
import OutputNetlist from "@/components/homes/fileList/OutputNetlist.vue"; //输出网表
import SaveToSymbolLibrary from "@/components/homes/fileList/SaveToSymbolLibrary.vue"; //存到符号库
import ReplaceSymbol from "@/components/homes/fileList/ReplaceSymbol.vue"; //符号替换
import CreateNewFileDialog from "@/components/homes/fileList/CreateNewFileDialog.vue"; //新建页面
import WarningDialog from "@/components/homes/fileList/WarningDialog.vue"; //报错组件
import SaveToLibDialog from "@/components/homes/fileList/SaveToLibDialog.vue"; //存到器件库组件
import ImportDialog from "@/components/homes/fileList/ImportDialog.vue"; //导入组件
import SchemaSaveAsDialog from "@/components/homes/fileList/SchemaSaveAsDialog.vue"; //原理图另存为组件
import CopyToDialog from "@/components/homes/fileList/CopyToDialog.vue"; //复制到组件
import DeleteDialog from "@/components/homes/fileList/DeleteDialog.vue"; //删除组件
import DeviceProperties from "@/components/homes/DeviceProperties.vue";
import DiyGraph from "@/components/homes/DiyGraph.vue";
import Device from "@/components/homes/Device.vue";
import Log from "@/components/homes/Log.vue";
import Drc from "@/components/tools/drc/index.vue";
import RuleLog from "@/components/homes/RuleLog.vue";
import Symbols from "@/components/homes/Symbol.vue";
import { pingCookie } from "@/api/login/regist";
export default {
  data() {
    return {
      autoToolSeen: true,
      timer: null,
      isSelectFileData: true,
      selectLayer: false,
      selectConp: false,
      selectLog: false,
      selectDrc: false,
      selectRuleLog: false,
      selectSymbol: false,
      resizeBox: null,
      currentBoxLeft: null,
      currentBoxright: null,
      isShrinkLeft: false,
      isShrinkRight: false,
      startWidthLeft: 0,
      startWidthRight: 0,
      rightDisabled: true,
      currentCell: this.$store.state.cellInfo,
      //遮罩
      screenLock: null,
      loading: false,
      isShowRightMenu: false,
      isOpenCell: false,

      //选择的状态
      selectState: "selectLayer",
      //自适应防抖
      antiShakeFlag: 20,
      //切换工具栏
      cell_type: "0",
      //只读模式
      readOnly: false,
      drcDrag: false,
      isOpenDrc: false,
    };
  },
  components: {
    Header,
    Sidebar,
    SchematicSidebar,
    EmptySidebar,
    Right,
    LayerList, //图层列表
    AutoTool,
    Scale,
    OpenSymbol,
    Encapsulation,
    OutputNetlist,
    SaveToSymbolLibrary,
    ReplaceSymbol,
    CreateNewFileDialog,
    WarningDialog,
    SaveToLibDialog,
    ImportDialog,
    SchemaSaveAsDialog,
    CopyToDialog,
    DeleteDialog,
    DeviceProperties,
    DiyGraph,
    Device,
    Log,
    Drc,
    Symbols,
    RuleLog,
  },
  created() {
    if (localStorage.getItem("user")) {
      this.$store.commit("setUserName", localStorage.getItem("userName"));
    }
  },
  directives: {
    drag(el, binding, vnode) {
      let clientOffset = {};
      el.onmousedown = function (e) {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        clientOffset.clientX = event.clientX;
        clientOffset.clientY = event.clientY;
        document.onmousemove = function (e) {
          el.style.left = e.pageX - disx + "px";
          el.style.top = e.pageY - disy + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
      el.addEventListener("mouseup", (event) => {
        //鼠标抬起后的事件，判断是拖拽还是点击
        let clientX = event.clientX;
        let clientY = event.clientY;
        if (
          clientX === clientOffset.clientX &&
          clientY === clientOffset.clientY
        ) {
          //这里触发点击事件，将click方法移入这里
          let that = vnode.context; //通过vnode参数来获取this,此时的that就可以获取到js中的this
        } else {
          return; //这里是拖拽事件
        }
      });
    },
  },
  mounted() {
    let self = this;
    bus.$on("openRuleLog", (data) => {
      this.selectTab("RuleLog");
    });
    bus.$on("loading", (data) => {
      if (data) {
        this.loading = true;
      }
    });
    bus.$on("ending", (data) => {
      if (data) {
        this.loading = false;
      }
    });
    bus.$on("screen_lock", (data) => {
      if (data) {
        const options = {
          lock: true,
          text: "加载中，请稍后......",
        };
        this.screenLock = this.$loading(options);
      } else {
        if (this.screenLock) {
          this.screenLock.close();
        }
      }
    });
    bus.$on("transforming", (data) => {
      if (data) {
        const options = {
          lock: true,
          text: this.$t("messages.transforming"),
        };
        this.screenLock = this.$loading(options);
      } else {
        if (this.screenLock) {
          this.screenLock.close();
        }
      }
    });
    self.$bus.$on("setAutoTool", (data) => {
      self.autoToolSeen = data;
    });
    window.onload = function (event) {
      event = event || window.event; //NOSONAR
      if (event) {
        self.$bus.$emit("getProjectList", true);
      }
    };
    this.timer = setInterval(() => {
      pingCookie().then((res) => {
        if (res.code === 401) {
          this.$router.replace("/login");
        }
      });
    }, 1000 * 60 * 10);
    bus.$on("changeSelectFileDataStatus", (msg) => {
      this.isSelectFileData = msg;
    });
    bus.$on("changeMenuAndSidebar", (data) => {
      this.cell_type = data;
      if (data == "3") {
        this.selectTab("Symbol");
        this.selectState = "selectSymbol";
      } else if (data == "1" || data == "2") {
        this.selectTab("Layer");
        this.selectState = "selectLayer";
      }
    });
    bus.$on("drcDialog", (data) => {
      this.selectTab("Drc");
    });
    this.dragControllerLeft();
    this.dragControllerRight();
    bus.$on("minusDrcDialog", (data) => {
      this.drcDrag = true;
    });
    bus.$on("openDRCtools", (data) => {
      this.toDrcTab(data);
    });
    bus.$on("updateDrcVisible", (status) => {
      this.drcDrag = status;
    });
    bus.$on("updateOpenDRCStatus", (status) => {
      this.isOpenDrc = status;
    });
    bus.$on("closeDRCVisible", () => {
      this.closeDrc();
    });

    // document.addEventListener("click", this.tableClick)
  },
  beforeDestroy() {
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
    });
    bus.$off("changeSelectFileDataStatus");
    bus.$off("controlRight");
    bus.$off("changeMenuAndSidebar");
    bus.$off("OpeninReadOnly");
    // document.removeEventListener("click", this.tableClick)
  },
  methods: {
    // tableClick() {

    //   let nodeList = document.querySelectorAll('.bar-button')
    //   nodeList.forEach(item => {

    //     item.classList.remove("open")
    //   })
    // },
    selectTabCancel() {
      this.selectLayer = false;
      this.selectConp = false;
      this.selectLog = false;
      this.selectDrc = false;
      this.selectSymbol = false;
      this.selectRuleLog = false;
    },
    selectTab(name) {
      this.selectTabCancel();
      if (name == "Layer") {
        this.selectLayer = true;
        this.selectState = "selectLayer";
      }
      if (name == "Conp") {
        this.selectConp = true;
        this.selectState = "selectConp";
      }
      if (name == "Log") {
        this.selectLog = true;
        this.selectState = "selectLog";
      }
      if (name == "Drc") {
        this.selectDrc = true;
        this.selectState = "selectDrc";
      }
      if (name == "Symbol") {
        this.selectSymbol = true;
        this.selectState = "selectSymbol";
      }
      if (name == "RuleLog") {
        this.selectRuleLog = true;
      }
    },
    closeHandling() {
      this.$bus.$emit("closeHandling", false);
    },
    //左边文件列表收缩
    dragControllerLeft() {
      let dragLineLeft = document.getElementById("dragLineLeft");
      this.currentBoxLeft = document.getElementById("files");
      dragLineLeft.addEventListener("pointerdown", this.onMouseDownLeft);
    },
    onMouseDownLeft(e) {
      document.addEventListener("pointermove", this.onMousemoveLeft);
      document.addEventListener("pointerup", this.onMouseupLeft);
    },
    onMousemoveLeft(e) {
      let endX = e.clientX;
      if (endX >= 220 && endX <= window.innerWidth / 3) {
        this.currentBoxLeft.style.width = endX + "px";
      }
      bus.$emit("resizeLayer");
    },
    onMouseupLeft(e) {
      document.removeEventListener("pointermove", this.onMousemoveLeft);
      document.removeEventListener("pointerdown", this.onMouseDownLeft);
    },
    //右边图层列表收缩
    dragControllerRight() {
      let dragLineRight = document.getElementById("dragLineRight");
      this.currentBoxRight = document.getElementById("layers");
      dragLineRight.addEventListener("mousedown", this.onMouseDownRight);
    },
    onMouseDownRight(e) {
      document.addEventListener("pointermove", this.onMousemoveRight);
      document.addEventListener("pointerup", this.onMouseupRight);
    },
    onMousemoveRight(e) {
      let endX = e.clientX;
      if (
        endX <= window.innerWidth - 300 &&
        endX >= (2 * window.innerWidth) / 3
      ) {
        this.currentBoxRight.style.width = window.innerWidth - endX - 30 + "px";
      }
      bus.$emit("resizeLayer");
    },
    onMouseupRight(e) {
      document.removeEventListener("pointermove", this.onMousemoveRight);
      document.removeEventListener("pointerdown", this.onMouseDownRight);
    },
    //收缩按钮两边收缩
    leftDragBtnClcik() {
      this.currentBoxLeft = document.getElementById("files");
      if (!this.isShrinkLeft) {
        this.startWidthLeft = JSON.parse(
          JSON.stringify(this.currentBoxLeft.style.width)
        );
        this.currentBoxLeft.style.width = 0 + "px";
        this.isShrinkLeft = true;
      } else {
        this.currentBoxLeft.style.width = this.startWidthLeft;
        this.isShrinkLeft = false;
      }
      bus.$emit("resizeLayer");
    },
    rightDragBtnClcik() {
      if (this.isOpenCell) {
        this.currentBoxRight = document.getElementById("layers");
        if (!this.isShrinkRight) {
          this.startWidthRight = JSON.parse(
            JSON.stringify(this.currentBoxRight.style.width)
          );
          this.currentBoxRight.style.width = 0 + "px";
          this.currentBoxRight.style.minWidth = 0 + "px";
          this.isShowRightMenu = false;
          this.isShrinkRight = true;
          this.selectTabCancel();
        } else {
          this.currentBoxRight.style.width = this.startWidthRight;
          if (this.isOpenCell) {
            this.currentBoxRight.style.minWidth = 300 + "px";
            this.isShowRightMenu = true;
            if (this.selectState === "selectLayer") {
              this.selectLayer = true;
            } else if (this.selectState === "selectConp") {
              this.selectConp = true;
            } else if (this.selectState === "selectLog") {
              this.selectLog = true;
            } else if (this.selectState === "selectSymbol") {
              this.selectSymbol = true;
            } else if (this.selectState === "selectDrc") {
              this.selectDrc = true;
            }
          } else {
            this.currentBoxRight.style.minWidth = 0 + "px";
          }
          this.isShrinkRight = false;
        }
        bus.$emit("resizeLayer");
        setTimeout(() => {
          bus.$emit("resizeLayer");
        });
      }
    },
    toDeviceTab() {
      this.selectTab("Conp");
      bus.$emit("initDeviceTab", true);
    },
    toLayer() {
      this.selectTab("Layer");
    },
    toLog() {
      this.selectTab("Log");
    },
    toDrcTab(data) {
      this.selectTab("Drc");
      bus.$emit("initDeviceTab", true);
      bus.$emit("initDrcTab", data);
    },
    toRuleLog() {
      this.selectTab("RuleLog");
    },
    toSymbolTab() {
      this.selectTab("Symbol");
      bus.$emit("initSymbolTab", true);
    },
    maxDrc() {
      this.drcDrag = false;
      bus.$emit("maxDrcDialog", true);
    },
    closeDrc() {
      this.isOpenDrc = false;
      this.drcDrag = false;
      bus.$emit("closeDrcDialog", true);
    },
  },
  computed: {
    getOpenedTab() {
      return JSON.parse(JSON.stringify(this.$store.state.openedTab));
    },
    cellInfo() {
      return JSON.parse(JSON.stringify(this.$store.state.cellInfo));
    },
  },
  watch: {
    getOpenedTab: {
      handler(val, valOld) {
        if (val.length > valOld.length) {
          this.isOpenCell = true;
          if (this.selectState === "selectLayer") {
            this.selectLayer = true;
          } else if (this.selectState === "selectConp") {
            this.selectConp = true;
          } else if (this.selectState === "selectLog") {
            this.selectLog = true;
          } else if (this.selectState === "selectSymbol") {
            this.selectSymbol = true;
          } else if (this.selectState === "selectDrc") {
            this.selectDrc = true;
          }
          this.isShowRightMenu = true;
          document.getElementById("layers").style.width = 300 + "px";
          document.getElementById("layers").style.minWidth = 300 + "px";
        } else if (val.length == 0) {
          this.isOpenCell = false;
          this.isShowRightMenu = false;
          this.selectTabCancel();
          document.getElementById("layers").style.width = this.startWidthRight =
            0 + "px";
          document.getElementById("layers").style.minWidth = 0 + "px";
        }
      },
    },
    cellInfo(val) {
      this.readOnly = val.isReadOnly;
    },
  },
};
</script>
<style lang="less" scoped>
.sidebar {
  z-index: 99;
}
.disabled {
  cursor: not-allowed !important;
}
.home {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  content {
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;
    overflow: hidden;
    #files {
      position: relative;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
    }
    #dragLineLeft {
      position: absolute;
      width: 3px;
      height: 100%;
      top: 0;
      right: -5px;
      border: 1px solid #dddddd;
      background-color: #ffffff;
      cursor: pointer;
      pointer-events: auto;
      user-select: none;
      z-index: 1;
    }
    #leftDragBtn {
      width: 22px;
      height: 60px;
      top: 50%;
      left: -11px;
      position: absolute;
      cursor: pointer;
      overflow: hidden;
      z-index: 2;
      img {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        left: -80%;
        pointer-events: auto;
      }
    }
    .layers {
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      position: relative;
      border-right: 1px solid #dddddd;
      .readOnlyMask {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    #rightDragBtn {
      width: 22px;
      height: 60px;
      position: absolute;
      top: 50%;
      left: -14px;
      cursor: pointer;
      overflow: hidden;
      z-index: 2;
      img {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        left: -80%;
        pointer-events: auto;
      }
    }
    #dragLineRight {
      position: absolute;
      width: 3px;
      height: 100%;
      top: 0;
      left: -4px;
      background-color: #ffffff;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      cursor: pointer;
      pointer-events: auto;
      z-index: 1;
      user-select: none;
    }
    .rightMenu {
      display: flex;
      flex-direction: column;
      width: 24px;
      text-align: center;
      background: #f2f5f8;
      border: 1px solid #dddddd;
      align-items: center;
      position: relative;
      .readOnlyMask {
        position: absolute;
        width: 26px;
        height: 100%;
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.3);
      }
      .rightMenu__item {
        background: #ffffff;
        border: 1px solid #dddddd;
        border-left: none;
        padding: 20px 3px;
        cursor: pointer;
        color: #333333;
        font-size: 14px;
        writing-mode: vertical-lr;
        position: relative;
      }
      .active {
        background: #0c7de6;
        color: #ffffff;
      }
    }
    //遮罩
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.drcMiniWindow:hover {
  cursor: pointer;
}
.drcMiniWindow {
  width: 260px;
  height: 30px;
  background: #f4f4f4;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  z-index: 333;
  .medium {
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: flex;
    div {
      flex: 1;
    }
    .centers {
      span {
        width: 100%;
        font-size: 13px !important;
        text-align: center;
        font-size: 16px;
        color: #606266;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .icons {
      display: flex;
      justify-content: flex-end;
      i {
        color: #5f6368;
        font-size: 18px !important;
        display: block;
        padding: 0 7px;
        height: 30px;
        line-height: 30px;
      }
      i:hover {
        background: #dcdfe6;
        cursor: pointer;
      }
      .el-icon-close:hover {
        background: #f00;
        color: #fff;
      }
    }
  }
}
</style>
