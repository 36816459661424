<template>
  <div class="newProjectDialog">
    <el-dialog :modal="false" :title="$t('messages.new')" :visible.sync="createFileDialogVisible" :close-on-click-modal="false" :before-close="createFileDialogClose">
      <el-form :model="createFileForm" :rules="rules" ref="createFileForm" class="demo-ruleForm">
        <!-- 项目归属 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.itemBelong")}}:</span>
          <el-form-item prop="form_projectRadio">
            <div class="radioArea">
              <el-radio v-model="createFileForm.form_projectRadio" label="1" @change="projectBelong" :disabled="userListRClick?false:true">{{$t("messages.person")}}</el-radio>
              <el-radio v-model="createFileForm.form_projectRadio" label="2" @change="projectBelong" :disabled="teamListRClick?false:true">{{$t("messages.team")}}</el-radio>
            </div>
          </el-form-item>
        </div>

        <!-- 用户/团队名 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.userAndTeamName")}}:</span>
          <el-form-item prop="form_userName" v-if="createFileForm.form_projectRadio==='1'">
            <el-select class="userSelect" v-model="createFileForm.form_userName" filterable :placeholder="$t('messages.personName')" disabled>
              <el-option v-for="item in options_userName" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="form_teamName" v-if="createFileForm.form_projectRadio==='2'">
            <el-select v-model="createFileForm.form_teamName" :placeholder="$t('messages.teamName')" @change="teamNameSelect" :disabled="createFromHeader?false:true">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name" :value="item.snow_id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <!-- 项目名 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.projectName")}}:</span>
          <i class="el-icon-arrow-down projectNameIcon" ref="projectNameIcon"></i>
          <el-form-item prop="form_projectName" ref="projectNameRule">
            <el-autocomplete @focus="projectNameFocus" @blur="projectNameBlur" class="inline-input" v-model="createFileForm.form_projectName" :fetch-suggestions="projectNameSearch" :placeholder="$t('messages.pleaseEnterProjectName')" @select="handleSelectProName" :maxlength="50">
            </el-autocomplete>
          </el-form-item>
        </div>

        <!-- 文件类型 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.fileType")}}:</span>
          <div class="radioArea">
            <el-form-item prop="form_fileRadio">
              <el-radio v-model="createFileForm.form_fileRadio" label="1" @change="changeFileType">{{$t("messages.LayoutFile")}}</el-radio>
              <el-radio v-model="createFileForm.form_fileRadio" label="3" @change="changeFileType">{{$t("messages.SchematicFile")}}</el-radio>
            </el-form-item>
          </div>
        </div>

        <!-- 文件名 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.fileName")}}:</span>
          <i class="el-icon-arrow-down fileNameIcon" ref="fileNameIcon"></i>
          <el-form-item prop="form_fileName" ref="fileNameRule">
            <el-autocomplete @focus="fileNameFocus" @blur="fileNameBlur" class="inline-input" v-model="createFileForm.form_fileName" :fetch-suggestions="fileNameSearch" :placeholder="$t('messages.pleaseEnterFileName')" @select="handleSelectFileName" :maxlength="50">
            </el-autocomplete>
          </el-form-item>
        </div>

        <!-- 单元类型 -->
        <div class="inputArea" v-if="createFileForm.form_fileRadio==='1'">
          <span class="inputInfo">{{$t("messages.cellType")}}:</span>
          <div class="radioArea">
            <el-form-item prop="form_cellRadio">
              <el-radio v-model="createFileForm.form_cellRadio" label="1" @change="changeCellType">{{$t("messages.fileCell")}}</el-radio>
              <el-radio v-model="createFileForm.form_cellRadio" label="2" @change="changeCellType">{{$t("messages.componentCell")}}
              </el-radio>
            </el-form-item>
          </div>
        </div>

        <!-- 版图单元名称 -->
        <div class="inputArea" v-if="createFileForm.form_fileRadio==='1'">
          <span class="inputInfo">{{$t("messages.cellName")}}:</span>
          <el-form-item prop="form_cellName">
            <el-input v-model="createFileForm.form_cellName" :placeholder="$t('messages.pleaseEnterCellName')" :maxlength="50"></el-input>
          </el-form-item>
        </div>

        <!-- 原理图名称 -->
        <div class="inputArea" v-if="createFileForm.form_fileRadio==='3'">
          <span class="inputInfo">{{$t("messages.SchematicName")}}:</span>
          <el-form-item prop="form_schemaName">
            <el-input v-model="createFileForm.form_schemaName" :placeholder="$t('messages.pleaseEnterSchematicName')" :maxlength="50"></el-input>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">

        <el-button type="primary" class="deactivate" ref="createFileDialogBtn" @click="createFileDialogSureButton" size="mini" disabled>{{$t("messages.sure")}}</el-button>
        <el-button @click="createFileDialogCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import {
  disableBtn,
  activateBtn,
  binaryDataToUTF8,
  getParentNodeFn,
  getUserProNameList,
  getTeamProjectNameList,
  getCellIndexData,
} from "./function/fileListPublicFn.js";
import {
  batchSaveDataFn,
  batchSaveDataFn_fileSize,
  getObjectData,
  getFolderName,
} from "./function/objectStorageFn";
import { createNewFileLayerList } from "../rightList/layerListFn.js";
import {
  getLayerInfo,
  addLayerInfo,
  createNewCell,
  searchFileList,
  setFileParams,
} from "./function/fileListApiFn.js";
export default {
  data() {
    let projectNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else if (!/^[\s&()~+-_\[\]0-9a-zA-Z\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error(this.$t("messages.fileTip1")));
      } else {
        callback();
      }
    };
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    return {
      //新建窗口表单数据
      createFileForm: {
        form_userName: "", //选中的用户名
        form_teamName: "", //选中的团队名
        form_projectRadio: "1", //项目归属选项
        form_projectName: "", //项目名
        form_fileRadio: "1", //文件类型 1,3
        form_fileName: "", //文件名
        form_cellRadio: "1", //版图单元类型 1,2
        form_cellName: "", //单元名
        form_schemaName: "", //原理图名
      },

      //规则检查
      rules: {
        form_projectName: [{ validator: projectNameRule, trigger: "blur" }],
        form_fileName: [{ validator: fileNameRule, trigger: "blur" }],
        form_cellName: [{ validator: fileNameRule, trigger: "blur" }],
        form_schemaName: [{ validator: fileNameRule, trigger: "blur" }],
      },

      createFileDialogVisible: false, //新建页面显示
      userListRClick: true, //右键个人用户节点
      teamListRClick: true, //右键团队节点
      options_userName: [], //个人用户数据数组
      options_teamName: [], //团队数据数组
      options_proName: [], //项目数据数组
      options_fileName: [], //文件数据数组
      createFromHeader: false, //是否是顶部导航栏新建
      userId: "", //个人id
      user_gns: "", //个人GNS
      team_gns: "", //团队GNS
      teamId: "", //团队id
      project_gns: "", //项目GNS
      file_gns: "", //文件GNS
    };
  },

  created() { },
  mounted() {
    bus.$on("contextMenuCreateCell", (data) => {
      this.createFileDialogVisible = true;
      this.createFromHeader = false;
      this.createFileForm.form_userName = localStorage.getItem("userName");
      this.userId = localStorage.getItem("userId");
      this.options_teamName = this.$store.state.teamNameList;

      let topNode = {};
      let projectNode = {};
      let fileNode = {};
      let currentNode = this.$store.getters.getCurrentNode;
      let nodeObj = getParentNodeFn(currentNode);
      topNode = nodeObj.topNode;
      projectNode = nodeObj.projectNode;
      fileNode = nodeObj.fileNode;

      if (topNode.data.isTeam === false) {
        this.setFormTeam("", "", "");
      } else {
        this.setFormTeam(
          topNode.data.name,
          topNode.data.snow_id,
          topNode.data.gns
        );
      }

      if (currentNode.level > 2) {
        if (projectNode.data.isTeam === false) {
          this.setFormProject("1", projectNode.data.name, projectNode.data.gns);
        } else {
          this.setFormProject("2", projectNode.data.name, projectNode.data.gns);
        }
      } else {
        if (projectNode.data.isTeam === false) {
          this.setFormProject("1", "", "");
        } else {
          this.setFormProject("2", "", "");
        }
      }

      if (currentNode.level > 3) {
        if (fileNode.data.file_type === 1) {
          this.setFormFile("1", fileNode.data.name, fileNode.data.gns);
        } else {
          this.setFormFile("3", fileNode.data.name, fileNode.data.gns);
        }
      } else {
        if (currentNode.level === 3) {
          if (fileNode.data.file_type === 1) {
            this.setFormFile("1", "", "");
          } else {
            this.setFormFile("3", "", "");
          }
        } else {
          this.setFormFile("1", "", "");
        }
      }
      this.setFormCell("1", "", "");

      if (this.createFileForm.form_projectRadio == "1") {
        this.teamListRClick = false;
        this.userListRClick = true;
      } else {
        this.userListRClick = false;
        this.teamListRClick = true;
      }
    });

    bus.$on("headerCreateCell", (data) => {
      this.createFileDialogVisible = true;
      this.createFromHeader = true;

      this.createFileForm.form_userName = localStorage.getItem("userName");
      this.userId = localStorage.getItem("userId");
      this.options_teamName = this.$store.state.teamNameList;
      this.teamListRClick = true;
      this.userListRClick = true;
    });
  },
  beforeDestroy() {
    bus.$off("contextMenuCreateCell");
    bus.$off("headerCreateCell");
  },
  methods: {
    createFileDialogBtnActive() {
      if (this.createFileDialogVisible) {
        if (
          (this.createFileForm.form_userName !== "" ||
            this.createFileForm.form_teamName !== "") &&
          this.createFileForm.form_projectName !== "" &&
          this.createFileForm.form_fileName !== "" &&
          (this.createFileForm.form_cellName !== "" ||
            this.createFileForm.form_schemaName !== "")
        ) {
          activateBtn(this.$refs.createFileDialogBtn);
        } else {
          disableBtn(this.$refs.createFileDialogBtn);
        }
      }
    },

    //设置表单数据
    setFormTeam(teamName, teamId, team_gns) {
      this.createFileForm.form_teamName = teamName;
      this.teamId = teamId;
      this.team_gns = team_gns;
    },

    setFormProject(projectRadio, projectName, projectGns) {
      this.createFileForm.form_projectRadio = projectRadio;
      this.createFileForm.form_projectName = projectName;
      this.project_gns = projectGns;
    },

    setFormFile(fileRadio, fileName, fileGns) {
      this.createFileForm.form_fileRadio = fileRadio;
      this.createFileForm.form_fileName = fileName;
      this.file_gns = fileGns;
    },

    setFormCell(cellRadio, cellName, schemaName) {
      this.createFileForm.form_cellRadio = cellRadio;
      this.createFileForm.form_cellName = cellName;
      this.createFileForm.form_schemaName = schemaName;
    },

    //项目归属
    projectBelong() {
      this.createFileForm.form_userName = localStorage.getItem("userName");
      this.setFormTeam("", "", "");
      this.setFormProject(this.createFileForm.form_projectRadio, "", "");
      this.setFormFile("1", "", "");
      this.setFormCell("1", "", "");
    },

    //团队选择
    teamNameSelect(data) {
      this.options_teamName.forEach((item) => {
        if (item.snow_id === data) {
          this.setFormTeam(item.name, item.snow_id, item.gns);
          this.setFormProject(this.createFileForm.form_projectRadio, "", "");
          this.setFormFile("1", "", "");
          this.setFormCell("1", "", "");
        }
      });
    },

    //项目选择聚焦
    async projectNameFocus() {
      this.$refs.projectNameIcon.classList.add("inputActive");
      if (this.createFileForm.form_projectRadio === "1") {
        await this.getUserProName();
      } else {
        await this.getTeamProjectName();
      }
    },

    //获取个人项目列表
    async getUserProName() {
      this.options_proName = await getUserProNameList(0, 50);
    },

    async getTeamProjectName() {
      this.options_proName = await getTeamProjectNameList(
        0,
        50,
        this.createFileForm.form_teamName,
        this.teamId,
        this.team_gns
      );
    },

    //项目选择失焦
    projectNameBlur() {
      this.$refs.projectNameIcon.classList.remove("inputActive");
    },

    //项目搜索
    projectNameSearch(queryString, cb) {
      let options = this.options_proName;
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      // callback 返回建议列表的数据

      if (results.length != 0) {
        if (results[0].value === this.createFileForm.form_projectName) {
          this.project_gns = results[0].project_gns;
        } else {
          this.project_gns = "";
        }
      } else {
        this.project_gns = "";
      }

      this.file_gns = "";
      this.createFileForm.form_fileName = "";
      this.createFileForm.form_cellName = "";
      this.createFileForm.form_schemaName = "";
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.match(queryString) !== null;
      };
    },

    //选择项目名
    handleSelectProName(item) {
      this.createFileForm.form_projectName = item.value;
      this.project_gns = item.project_gns;
      setTimeout(() => {
        this.$refs.projectNameRule.clearValidate();
      }, 500);
    },

    //切换文件类型
    changeFileType() {
      this.setFormFile(this.createFileForm.form_fileRadio, "", "");
      if (this.createFileForm.form_fileRadio === "1") {
        this.setFormCell("1", "", "");
      } else {
        this.setFormCell("3", "", "");
      }
    },

    //文件选择聚焦
    async fileNameFocus() {
      this.options_fileName.splice(0, this.options_fileName.length);
      this.$refs.fileNameIcon.classList.add("inputActive");
      let res = {};
      if (this.project_gns !== "") {
        if (this.createFileForm.form_projectRadio === "1") {
          res = await searchFileList(
            0,
            200,
            this.project_gns,
            "个人文件",
            Number(this.createFileForm.form_fileRadio)
          );
        } else {
          res = await searchFileList(
            0,
            200,
            this.project_gns,
            "团队文件",
            Number(this.createFileForm.form_fileRadio)
          );
        }
        if (res.code === 200000) {
          this.file_gns = "";
          res.data.data.forEach((item) => {
            this.options_fileName.push({
              value: item._source.name,
              file_gns: item._source.gns,
            });
          });
        }
      }
    },

    //文件选择失焦
    fileNameBlur() {
      this.$refs.fileNameIcon.classList.remove("inputActive");
    },

    //文件搜索
    fileNameSearch(queryString, cb) {
      let options = this.options_fileName;
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      if (this.project_gns != "") {
        if (results.length != 0) {
          if (results[0].value === this.createFileForm.form_fileName) {
            this.file_gns = results[0].file_gns;
          } else {
            this.file_gns = "";
          }
        } else {
          this.file_gns = "";
        }
      } else {
        this.file_gns = "";
      }
      this.createFileForm.form_cellName = "";
      this.createFileForm.form_schemaName = "";
      cb(results);
    },

    //选择文件名
    handleSelectFileName(item) {
      this.createFileForm.form_fileName = item.value;
      this.file_gns = item.file_gns;
      this.$refs.fileNameRule.clearValidate();
    },

    //单元类型选择
    changeCellType() {
      this.createFileForm.form_cellName = "";
      this.createFileForm.form_schemaName = "";
    },

    //新建页面确认
    createFileDialogSureButton() {
      this.$refs.createFileForm.validate(async (valid) => {
        if (valid) {
          this.createFileDialogVisible = false;
          let accessor_id = "";
          if (this.createFileForm.form_projectRadio == "1") {
            accessor_id = this.userId;
          } else if (this.createFileForm.form_projectRadio == "2") {
            accessor_id = this.teamId;
          }
          bus.$emit("createNewFile", true);
          await this.createNewFileFn(accessor_id);
          this.resetCreateFileDialog();
        } else {
          return false;
        }
      });
    },

    async createNewFileFn(accessor_id) {
      let isTeam = false;
      let project_snow_id = "";
      let file_snow_id = "";
      let cell_snow_id = "";
      let project = {};
      let schemaFile = {};
      let schema = {};
      // let symbol = {}
      // let capSymbol = {}
      let layout = {};
      let cell = {};
      let cellIndexData = [];
      let hasCellIndexData = false;

      //创建/获取项目
      if (this.project_gns !== "") {
        //先判断表中是否存在此project
        project_snow_id =
          this.project_gns.split("/")[this.project_gns.split("/").length - 1];
        project = await getObjectData(project_snow_id);
      } else {
        project = new Kernel.Project();
        project_snow_id = project.snow_id;
        project.name = this.createFileForm.form_projectName;
      }

      if (this.createFileForm.form_fileRadio === "1") {
        //创建/获取文件
        if (this.file_gns !== "") {
          file_snow_id =
            this.file_gns.split("/")[this.file_gns.split("/").length - 1];
          layout = await getObjectData(file_snow_id);
          //获取索引数据
          cellIndexData = await getCellIndexData(file_snow_id);
        } else {
          layout = new Kernel.Library(this.createFileForm.form_fileName);
          file_snow_id = layout.snow_id;
          project.add_library(layout);
        }
        //创建单元

        cell = new Kernel.Cell(this.createFileForm.form_cellName);
        cell_snow_id = cell.snow_id;
        layout.add_cell(cell);
      } else if (this.createFileForm.form_fileRadio === "3") {
        if (this.file_gns !== "") {
          file_snow_id =
            this.file_gns.split("/")[this.file_gns.split("/").length - 1];
          schemaFile = await getObjectData(file_snow_id);
          //获取索引数据
          cellIndexData = await getCellIndexData(file_snow_id);
        } else {
          schemaFile = new Kernel.SchemaFile();
          file_snow_id = schemaFile.snow_id;
          project.add_schemafile(schemaFile);
        }
        schemaFile.name = this.createFileForm.form_fileName;
        schema = new Kernel.Schema();
        schema.name = this.createFileForm.form_schemaName;
        schemaFile.add_schema(schema);
        cell_snow_id = schema.snow_id;
      }

      //如果已有索引数据，调用设置接口
      hasCellIndexData = cellIndexData.length > 0 ? true : false;
      cellIndexData.push({ snow_id: cell_snow_id, cells: [] });

      //新建层级
      let type = null;
      if (this.project_gns === "") {
        type = 1;
      } else if (this.file_gns === "") {
        type = 2;
      } else {
        type = 3;
      }

      if (this.createFileForm.form_projectRadio == "1") {
        isTeam = false;
      } else if (this.createFileForm.form_projectRadio == "2") {
        isTeam = true;
      }

      bus.$emit("loading", true);
      if (this.createFileForm.form_fileRadio === "1") {
        //accessor_type, accessor_id, project_name, project_gns, project_snow_id, file_name, file_type, file_gns, file_snow_id, cell_name ,cell_type, cell_snow_id
        let res = await createNewCell(
          Number(this.createFileForm.form_projectRadio),
          accessor_id,
          this.createFileForm.form_projectName,
          this.project_gns,
          project_snow_id,
          this.createFileForm.form_fileName,
          Number(this.createFileForm.form_fileRadio),
          this.file_gns,
          file_snow_id,
          cellIndexData,
          this.createFileForm.form_cellName,
          Number(this.createFileForm.form_cellRadio),
          cell_snow_id
        );
        if (res.code === 200000) {
          let projectBinaryDataObj = project.dump_file(true, 0);
          let fileBinaryDataObj = layout.dump_file(true, 0);
          let cellBinaryDataObj = cell.dump_file(true, 0);

          let binaryDataObj = {
            ...projectBinaryDataObj,
            ...fileBinaryDataObj,
            ...cellBinaryDataObj,
          };

          let u8Array = binaryDataToUTF8(binaryDataObj);
          // let batch_data = {};
          // if (Object.keys(projectBinaryDataObj).length !== 0) {
          //   batch_data[project_snow_id] = {
          //     folder_name: getFolderName(
          //       accessor_id,
          //       res.data.project.gns,
          //       isTeam
          //     ),
          //     file_type: "LAYOUT_FILE",
          //   };
          // }
          // batch_data[file_snow_id] = {
          //   folder_name: getFolderName(accessor_id, res.data.file.gns, isTeam),
          //   file_type: "LAYOUT_FILE",
          // };
          // batch_data[cell_snow_id] = {
          //   folder_name: getFolderName(accessor_id, res.data.cell.gns, isTeam),
          //   file_type: "LAYOUT_FILE",
          // };
          // await batchSaveDataFn(u8Array, batch_data);

          await batchSaveDataFn_fileSize(u8Array, project_snow_id, file_snow_id, 'LAYOUT_FILE', [cell_snow_id])

          let topData = {
            topId: isTeam ? accessor_id : "",
            createFromHeader: this.createFromHeader,
          };
          let projectData = {
            name: res.data.project.name,
            snow_id: res.data.project.snow_id,
            gns: res.data.project.gns,
            isTeam,
          };
          let fileData = {
            name: res.data.file.name,
            snow_id: res.data.file.snow_id,
            gns: res.data.file.gns,
            file_type: res.data.file.file_type,
            isTeam,
          };
          let cellData = {
            name: res.data.cell.name,
            snow_id: res.data.cell.snow_id,
            gns: res.data.cell.gns,
            cell_type: res.data.cell.cell_type,
            isTeam,
          };

          bus.$emit("createCellRefreshTreeNode", {
            topData,
            projectData,
            fileData,
            cellData,
            type,
          });
          if (hasCellIndexData) {
            await setFileParams(file_snow_id, cellIndexData);
          }

          //获取文件图层列表数据
          let fileLayerDataRes = await getLayerInfo(file_snow_id);
          if (fileLayerDataRes.code === 200000) {
            if (fileLayerDataRes.data.data.length === 0) {
              let layer_infos = createNewFileLayerList();

              addLayerInfo(res.data.file.gns, layer_infos);
            }
          }
        }
      } else if (this.createFileForm.form_fileRadio === "3") {
        let res = await createNewCell(
          Number(this.createFileForm.form_projectRadio),
          accessor_id,
          this.createFileForm.form_projectName,
          this.project_gns,
          project_snow_id,
          this.createFileForm.form_fileName,
          Number(this.createFileForm.form_fileRadio),
          this.file_gns,
          file_snow_id,
          cellIndexData,
          this.createFileForm.form_schemaName,
          3,
          cell_snow_id
        );
        if (res.code === 200000) {
          let projectBinaryDataObj = project.dump_file(true, 0);
          let fileBinaryDataObj = schemaFile.dump_file(true, 0);
          let cellBinaryDataObj = schema.dump_file(true, 0);
          // let cellBinaryDataObj = symbol.dump_file(true, 0)
          // let cellBinaryDataObj = capSymbol.dump_file(true, 0)
          let binaryDataObj = {
            ...projectBinaryDataObj,
            ...fileBinaryDataObj,
            ...cellBinaryDataObj,
          };
          let u8Array = binaryDataToUTF8(binaryDataObj);

          // let batch_data = {};
          // if (Object.keys(projectBinaryDataObj).length !== 0) {
          //   batch_data[project_snow_id] = {
          //     folder_name: getFolderName(
          //       accessor_id,
          //       res.data.project.gns,
          //       isTeam
          //     ),
          //     file_type: "LAYOUT_FILE",
          //   };
          // }
          // batch_data[file_snow_id] = {
          //   folder_name: getFolderName(accessor_id, res.data.file.gns, isTeam),
          //   file_type: "DIAGRAM_FILE",
          // };
          // batch_data[cell_snow_id] = {
          //   folder_name: getFolderName(accessor_id, res.data.cell.gns, isTeam),
          //   file_type: "DIAGRAM_FILE",
          // };

          // await batchSaveDataFn(u8Array, batch_data);

          await batchSaveDataFn_fileSize(u8Array, project_snow_id, file_snow_id, 'DIAGRAM_FILE', [cell_snow_id])

          let topData = {
            topId: isTeam ? accessor_id : "",
            createFromHeader: this.createFromHeader,
          };
          let projectData = {
            name: res.data.project.name,
            snow_id: res.data.project.snow_id,
            gns: res.data.project.gns,
            isTeam,
          };
          let fileData = {
            name: res.data.file.name,
            snow_id: res.data.file.snow_id,
            gns: res.data.file.gns,
            file_type: res.data.file.file_type,
            isTeam,
          };
          let cellData = {
            name: res.data.cell.name,
            snow_id: res.data.cell.snow_id,
            gns: res.data.cell.gns,
            cell_type: res.data.cell.cell_type,
            isTeam,
          };

          bus.$emit("createCellRefreshTreeNode", {
            topData,
            projectData,
            fileData,
            cellData,
            type,
          });
          if (hasCellIndexData) {
            await setFileParams(file_snow_id, cellIndexData);
          }
        }
      }
    },

    //新建页面取消
    createFileDialogCancelButton() {
      this.resetCreateFileDialog();
      this.createFileDialogVisible = false;
    },

    //窗口关闭按钮
    createFileDialogClose() {
      this.resetCreateFileDialog();
      this.createFileDialogVisible = false;
      if (this.$store.state.treeNameList.length === 0) {
        bus.$emit("createButtonVisible", true);
      }
    },

    //重置新建页面
    resetCreateFileDialog() {
      this.$refs.createFileForm.resetFields();
      this.userId = "";
      this.user_gns = "";
      this.team_gns = "";
      this.teamId = "";
      this.project_gns = "";
      this.file_gns = "";
      this.setFormTeam("", "", "");
      this.setFormProject("1", "", "");
      this.setFormFile("1", "", "");
      this.setFormCell("1", "", "");
    },
  },
  watch: {
    "createFileForm.form_userName"(val) {
      this.createFileDialogBtnActive();
    },
    "createFileForm.form_teamName"(val) {
      this.createFileDialogBtnActive();
    },
    "createFileForm.form_projectName"(val) {
      this.createFileDialogBtnActive();
    },
    "createFileForm.form_fileName"(val) {
      this.createFileDialogBtnActive();
    },
    "createFileForm.form_cellName"(val) {
      this.createFileDialogBtnActive();
    },
    "createFileForm.form_schemaName"(val) {
      this.createFileDialogBtnActive();
    },
  },
};
</script>

<style lang="less" scoped>
//新建弹窗
.newProjectDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 480px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .userSelect {
      /deep/.el-input__inner {
        color: #c0c4cc !important;
      }
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio {
        width: 258px;
        margin-right: 0px;
      }
    }
    .projectNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .fileNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
    }
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>