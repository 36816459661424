<template>
  <div class="right" id="right" ref="right" style="max-height:900px" onselectstart="javascript:return false;">
    <canvas id="fill_img_temp" width=250 height=250 style="display: none;"></canvas>
    <canvas id="border_img_temp" width=350 height=180 style="display: none;"></canvas>
    <!-- <img alt="" id="fill_img" width="250" height="150" />
    <img alt="" id="fill_img2" width="25" height="15" /> -->
    <div class="cardHeader">
      <!-- 头部标题 -->
      <span>{{ $t("messages.layerPropertyList") }}</span>
      <el-link :underline="false" @click="exportDRCFile"><span class="iconfont icon-daochu"></span>{{$t("messages.export")}}</el-link>
    </div>

    <!-- 图层库切换 -->
    <div class="layerSelector">
      <el-select popper-class="optionClass" v-model="value_layerLibrary" @change="changeLayerDatas">
        <el-option v-for="item in $t('messages.options_layerLibrarySelector')" :key="item.label" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class="layerTable">
      <el-table :data="layerDatas" :height="tableHeight" @header-click="clickHeader" @row-contextmenu="onContextMenu" id="layerDatasTable" class="layerDatasTable" ref="layerDatasTable" @current-change="layerDatasCurrentChange" highlight-current-row>
        <el-table-column width="45" align="center" :label="$t('messages.layer')" cell-style>
          <template slot-scope="scope">
            <img :src="scope.row.image" style="width:25px;height:15px;" />
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div>
              <span @dblclick="changeLayerName(scope.$index, scope.row)" v-show="!scope.row.showNameInput">{{scope.row.layerName}}</span>
              <el-input :ref='"layers_name"+scope.$index' @blur="resetNameBlur(scope.row)" clearable @keyup.enter.native="resetNameEnter($event)" v-show="scope.row.showNameInput" size="mini" v-model="scope.row.layerName"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="42" align="center" :label="$t('messages.output')" v-if="isShowExport">
          <template slot-scope="scope">
            <input type="checkbox" v-model="scope.row.isExport" name="layerOutput" @change="exportChangeFn(scope.row)">
          </template>
        </el-table-column>
        <el-table-column width="35" align="center" :label="$t('messages.lock')" v-if="isShowLock">
          <template slot-scope="scope">
            <i @click="scope.row.lock=false;lockClick(scope.row);" class="el-icon-lock" style="font-size:16px" v-if="scope.row.lock"></i>
            <input type="checkbox" v-model="scope.row.lock" name="layerOutput" @change="lockClick(scope.row)" v-else>
          </template>
        </el-table-column>
        <el-table-column width="35" align="center" :label="$t('messages.hide')" v-if="isShowHide">
          <template slot-scope="scope">
            <img @click="scope.row.hide=false;scope.row.lock=false;hideClick(scope.row);" style="height: 18px" src="../../../assets/icons/layer/隐藏.svg" v-if="scope.row.hide" />
            <input type="checkbox" v-model="scope.row.hide" name="layerOutput" @change="hideClick(scope.row)" v-else>
          </template>
        </el-table-column>
        <el-table-column width="55" align="center" :label="$t('messages.NO')" v-if="isShowNumber">
          <template slot-scope="scope">
            <div :title="scope.row.layerNumber" @dblclick="changeLayerNumber(scope.$index, scope.row)" v-show="!scope.row.showNumInput">{{scope.row.layerNumber}}</div>
            <el-input :ref='"layer_number"+scope.$index' @blur="resetNumberBlur(scope.row,scope.$index)" clearable @keyup.enter.native="resetNumberEnter($event)" v-show="scope.row.showNumInput" size="mini" v-model="scope.row.layerNumber"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div class="layer-btn">
        <button class="layer-btn__btn" @click="addNewLayerRule" :disabled="value_layerLibrary==0?false:'disabled'"><i class="iconfont icon-tianjia2"></i></button>
        <button class="layer-btn__btn" @click="mergeLayerBtn" :disabled="value_layerLibrary==0?'disabled':false"><i class="iconfont icon-hebing1"></i></button>
        <button class="layer-btn__btn" @click="delLayerBtn"><i class="iconfont icon-shanchu"></i></button>
      </div>

      <div class="fill-div">
        <div class="fillColor">
          <div class="cardHeader2">
            <!-- 头部标题 -->
            <strong>{{$t('messages.fillColor')}}</strong>
            <div class="arrow" @click="fillColorSeen=!fillColorSeen">
              <span class="el-icon-arrow-down" v-if="fillColorSeen"></span>
              <span class="el-icon-arrow-up" v-else></span>
            </div>
          </div>
          <div v-show="fillColorSeen" class="colorControl">
            <div class="fillColor__body">
              <div v-for="item, index in fillColorDatas" :key="index" :style="{'background': item}" class="fillColor__item" @click="selectFillColor(item, index)"></div>
            </div>
            <div class="fillColor__btn">
              <el-color-picker v-model="fillColorSelect" @change="fillColorPickerChange"></el-color-picker>
            </div>

          </div>
        </div>
        <div class="borderColor">
          <div class="cardHeader2">
            <!-- 头部标题 -->
            <strong>{{$t('messages.borderColor')}}</strong>
            <div class="arrow" @click="borderColorSeen=!borderColorSeen">
              <span class="el-icon-arrow-down" v-if="borderColorSeen"></span>
              <span class="el-icon-arrow-up" v-else></span>
            </div>
          </div>
          <div v-show="borderColorSeen" class="colorControl">
            <div class="borderColor__body">
              <div v-for="item, index in borderColorDatas" :key="index" :style="{'background': item}" class="borderColor__item" @click="selectBorderColor(item, index)"></div>
            </div>
            <div class="fillColor__btn">
              <el-color-picker v-model="borderColorSelect" @change="borderColorPickerChange"></el-color-picker>
            </div>
          </div>
        </div>
        <div class="fillShape">
          <div class="cardHeader2">
            <!-- 头部标题 -->
            <strong>{{$t('messages.fillShape')}}</strong>
            <div class="arrow" @click="fillShapeSeen=!fillShapeSeen">
              <span class="el-icon-arrow-down" v-if="fillShapeSeen"></span>
              <span class="el-icon-arrow-up" v-else></span>
            </div>
          </div>
          <div class="fillShape__body" v-show="fillShapeSeen">
            <div v-for="item, index in fillShapeDatas" :key="index" class="fillShape__item">
              <img :src="item.image" style="width:18px;height:18px;cursor:pointer" @click="getFillShape(item.shape)" />
            </div>
            <!-- <button @click="fillShapeDialogVisible = true">更多...</button> -->
          </div>
        </div>
        <div class="borderSquare">
          <div class="cardHeader2">
            <!-- 头部标题 -->
            <strong>{{$t('messages.borderShape')}}</strong>
            <div class="arrow" @click="borderSquareSeen=!borderSquareSeen">
              <span class="el-icon-arrow-down" v-if="borderSquareSeen"></span>
              <span class="el-icon-arrow-up" v-else></span>
            </div>
          </div>
          <div class="borderSquare__body" v-show="borderSquareSeen">
            <div v-for="item, index in bordershapeDatas" :key="index" class="borderSquare__item">
              <img :src="item.image" style="width:35px;height:18px;cursor:pointer" @click="getBorderSquare(item.border)" />
            </div>
          </div>
        </div>
      </div>

      <div class="mergeLayer">
        <el-dialog :title="$t('messages.mergeLayer')" :visible.sync="mergeLayerVisible" width="350px" :modal="false">
          <div>
            <el-table :height="400" :data="layerDatas" :show-header="false" @selection-change="mergeSelectionChange" class="mergeLayerTable" ref="mergeLayerTable">
              <el-table-column align="center" width="55">
                <template slot-scope="scope">
                  <div :title="scope.row.layerNumber">{{scope.row.layerNumber}}</div>
                </template>
              </el-table-column>
              <el-table-column width="35">
                <template slot-scope="scope">
                  <img :src="scope.row.image" style="width:25px;height:15px;" />
                </template>
              </el-table-column>
              <el-table-column prop="layerName" fit></el-table-column>
              <el-table-column type="selection" width="55"></el-table-column>
            </el-table>
            <div class="mergeToBox">
              <div class="merge__title">{{$t("messages.mergedLayer")}}</div>
              <el-select popper-class="optionClass" v-model="mergeLayer" :placeholder="$t('messages.selectMergedLayer')" @change="selectMergeLayer">
                <el-option v-for="item in mergeLayerSelection" :key="item.layerNumber" :label="item.layerName" :value="item.layerName">
                  <span style="width:30px" :title="item.layerNumber">{{ item.layerNumber }}</span>
                  <img :src="item.image" style="width:25px;height:15px;" />
                  <span style="">{{ item.layerName }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="sure" size="mini" @click="mergeLayerSureBtn" ref="mergeSureBtn" disabled>
              {{$t("messages.sure")}}</el-button>
            <el-button type="cancel" @click="mergeLayerCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
          </span>
        </el-dialog>
      </div>

      <div>
        <div class="delWindow">
          <el-dialog :title="value_layerLibrary==0?$t('messages.deleteLayer'):$t('messages.clearLayer')" :visible.sync="delLayerVisible" width="330px" :modal="false">
            <div>
              <el-table :data="layerDatas" :show-header="false" @selection-change="delSelectionChange" ref="delLayerTable" class="delLayerTable">
                <!-- <el-table-column prop="layerNumber" width="45"></el-table-column> -->
                <el-table-column align="center" width="55">
                  <template slot-scope="scope">
                    <div :title="scope.row.layerNumber">{{scope.row.layerNumber}}</div>
                  </template>
                </el-table-column>
                <el-table-column width="35">
                  <template slot-scope="scope">
                    <img :src="scope.row.image" style="width:25px;height:15px;" />
                  </template>
                </el-table-column>
                <el-table-column prop="layerName"></el-table-column>
                <el-table-column type="selection" width="35"></el-table-column>
              </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="mini" class="deactivate" ref="delWindowBtn" disabled @click="openDelDialog">
                {{$t("messages.delete")}}</el-button>
              <el-button @click="cancelDelDialog" size="mini">{{$t("messages.cancel")}}</el-button>
            </span>
          </el-dialog>
        </div>

        <div class="deleteDialog">
          <el-dialog :modal="false" :title="$t('messages.Warning')" :visible.sync="delDialogVisible" :close-on-click-modal="false">
            <div class="info">
              <i style="width:22px;height:22px;padding-right:10px;color:#EC3838;font-size:22px" class="iconfont icon-jinggao"></i>
              <span>{{$t('messages.powerTips_24')}}</span><br />
            </div>
            <span class="deleteName">{{deleteLayerName}}</span>
            <span slot="footer" class="dialog-footer">
              <el-checkbox v-model="userKnowChecked" @change="userKnow">{{$t('messages.powerTips_13')}}</el-checkbox>
              <div>
                <el-button type="primary" class="deactivate" ref="delSureBtn" @click="delDialogSureBtn" disabled size="mini">{{$t("messages.sure")}}</el-button>
                <el-button @click="delDialogCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
              </div>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "@/components/common/bus";
import router from "@/router";
import { getFillAndBorderImgs } from "../../../utils/graphic_f32/qeda-graphics";
import {
  addNewLayerFn,
  addSpecifyNewLayerFn,
  getFileLayerListFn,
  getFileLayerListFromTable,
  getDBFileLayerData,
  updateFileLayers,
  saveNewLayerFn,
  editLayerDataFn,
  editLayerDatasFn,
  mergeCellLayer,
  setFileLayerList,
  deleteCellDataByLayers,
  contrastCellDataByLayerId,
  sortLayerByNumber,
} from "./layerListFn.js";
import { generateFillImg } from "@/utils/graphic_cell/utils/layer-util.js";
import {
  saveCellFn,
  getDrfFromTable,
  getTfFromTable,
  batchGetCellFromTableByFileId,
  getCellFromTable,
} from "../fileList/function/fileListPublicFn";
import {
  deleteLayerInfo,
  getPermission,
  selectLockCell,
} from "../fileList/function/fileListApiFn.js";
import {
  AddLayerOption,
  DeleteLayerOption,
  MergeLayerOption,
  ListInfoOption,
  HideLayerOption,
  LockLayerOption,
  OutputLayerOption,
  NumberLayerOption,
} from "../fileList/function/contextMenu.js";
import { saveJSON, downloadBlob, deepClone } from "@/utils/utils";
import { addLayerListMessageServe } from "../../../utils/message_serve/layerList-message.js";
export default {
  data() {
    return {
      value_layerLibrary: 0,
      selcetLayer: {}, //列表上选中的图层
      updateImgLayer: {}, //需要更新图片的图层
      tableHeight: 376, //列表高度
      cellLayerNumbers: [], //单元图层编号
      layerDatas: [], //当前操作的图层列表
      currentFileId: null, //当前图层列表fileId
      layerPointer: 0, //新建图层指针
      lockPointer: 0, //锁定状态指针
      hidePointer: 0, //隐藏状态指针
      isShowExport: false, //是否显示导出
      isShowLock: true, //是否显示锁定
      isShowHide: true, //是否显示隐藏
      isShowNumber: true, //是否显示图层编号
      fileSnowId: "",
      cellSnowId: "",
      teamRule: false, //团队权限
      index: null, //文件图层对应在状态机中的位置
      isOpenCell: false, //是否打开了cell
      layerNumberOld: null, //旧图层编号
      layerNameOld: "", //旧图层名
      layerNumberArr: [], //图层编号列表

      fillColorSeen: true,
      borderColorSeen: true,
      fillShapeSeen: true,
      borderSquareSeen: true,
      fillColorSelect: "#FFFFFF",
      fillColorDatas: [
        "#FFFFFF",
        "#FFE4E1",
        "#FFF5EE",
        "#D2B48C",
        "#DAA520",
        "#FFFFF0",
        "#00FF00",
        "#00FA9A",
        "#00FFFF",
        "#4682B4",
        "#191970",
        "#4B0082",
        "#D8BFD8",
        "#FFB6C1",
        "#0000FF",
        "#C0C0C0",
        "#FF0000",
        "#FF4500",
        "#CD853F",
        "#FFA500",
        "#FFFACD",
        "#7CFC00",
        "#F0FFF0",
        "#008080",
        "#B0E0E6",
        "#B0C4DE",
        "#483D8B",
        "#8B008B",
        "#0C7DE6",
        "#FF1493",
        "#000000",
        "#800000",
        "#FA8072",
        "#8B4513",
        "#FAEBD7",
        "#FFF8DC",
        "#FAFAD2",
        "#32CD32",
        "#7FFFAA",
        "#AFEEEE",
        "#F0F8FF",
        "#0000CD",
        "#9932CC",
        "#DA70D6",
        "#FF00FF",
      ],
      borderColorSelect: "#FFFFFF",
      borderColorDatas: [
        "#FFFFFF",
        "#FFE4E1",
        "#FFF5EE",
        "#D2B48C",
        "#DAA520",
        "#FFFFF0",
        "#00FF00",
        "#00FA9A",
        "#00FFFF",
        "#4682B4",
        "#191970",
        "#4B0082",
        "#D8BFD8",
        "#FFB6C1",
        "#0000FF",
        "#C0C0C0",
        "#FF0000",
        "#FF4500",
        "#CD853F",
        "#FFA500",
        "#FFFACD",
        "#7CFC00",
        "#F0FFF0",
        "#008080",
        "#B0E0E6",
        "#B0C4DE",
        "#483D8B",
        "#8B008B",
        "#0C7DE6",
        "#FF1493",
        "#000000",
        "#800000",
        "#FA8072",
        "#8B4513",
        "#FAEBD7",
        "#FFF8DC",
        "#FAFAD2",
        "#32CD32",
        "#7FFFAA",
        "#AFEEEE",
        "#F0F8FF",
        "#0000CD",
        "#9932CC",
        "#DA70D6",
        "#FF00FF",
      ],
      fillShapeDatas: [
        { image: "", shape: "F0", name: "F0" },
        { image: "", shape: "F1", name: "F1" },
        { image: "", shape: "F2", name: "F2" },
        { image: "", shape: "F3", name: "F3" },
        { image: "", shape: "F4", name: "F4" },
        { image: "", shape: "F5", name: "F5" },
        { image: "", shape: "F6", name: "F6" },
        { image: "", shape: "F7", name: "F7" },
        { image: "", shape: "F8", name: "F8" },
        { image: "", shape: "F9", name: "F9" },
        { image: "", shape: "F10", name: "F10" },
        { image: "", shape: "F11", name: "F11" },
        { image: "", shape: "F12", name: "F12" },
        { image: "", shape: "F13", name: "F13" },
        { image: "", shape: "F14", name: "F14" },
      ],
      bordershapeDatas: [
        { image: "", border: "B1", name: "纯色" },
        { image: "", border: "B2", name: "纯色" },
        { image: "", border: "B3", name: "纯色" },
        { image: "", border: "B4", name: "纯色" },
        { image: "", border: "B5", name: "纯色" },
      ],

      fillShapeDialogVisible: false, //填充形状选择弹窗
      fillShapeList: [
        { image: "", shape: "F1", name: "F1" },
        { image: "", shape: "F2", name: "F2" },
        { image: "", shape: "F3", name: "F3" },
        { image: "", shape: "F4", name: "F4" },
        { image: "", shape: "F5", name: "F5" },
        { image: "", shape: "F6", name: "F6" },
        { image: "", shape: "F7", name: "F7" },
        { image: "", shape: "F8", name: "F8" },
        { image: "", shape: "F9", name: "F9" },
        { image: "", shape: "F10", name: "F10" },
        { image: "", shape: "F11", name: "F11" },
        { image: "", shape: "F12", name: "F12" },
        { image: "", shape: "F13", name: "F13" },
        { image: "", shape: "F14", name: "F14" },
        { image: "", shape: "F1", name: "F1" },
        { image: "", shape: "F1", name: "F1" },
        { image: "", shape: "F1", name: "F1" },
      ], //更多填充形状
      layerState: {},

      mergeLayerVisible: false,
      mergeLayer: "", //选择合并到的图层
      mergeLayerSelection: [], //被选中的要合并的图层

      delLayerVisible: false, //删除选择窗口
      delLayerSection: [],
      delDialogVisible: false, //删除提示窗口
      userKnowChecked: false,
      deleteLayerName: "",
      file_gns: "", //当前画板文件gns
      cell_gns: "", //当前画板单元gns
      select_fillColor_index: 0,
      select_borderColor_index: 0,

      //pdk
      pdkDrf: {},
      pdkTf: {},
    };
  },
  mounted() {
    const result = getFillAndBorderImgs();
    bus.$on("openLayerList", async (data) => {
      // 打开版图/原理图,获取状态机中存储的图层数据

      this.isOpenCell = true;
      let node = data[0];
      let projectId = data[4][1].projectId;
      let fileId = data[4][2].fileId;

      let activeNode = this.$store.state.activeNode;
      this.file_gns = activeNode.parent.data.gns;
      this.cell_gns = activeNode.data.gns;

      //先获取pdk数据
      this.getDRF_TF_info(projectId);
      result.fillImgs.forEach((img, i) => {
        this.fillShapeDatas[i].image = img;
      });
      result.borderImgs.forEach((img, i) => {
        this.bordershapeDatas[i].image = img;
      });
      await this.getComponentFileLayerList(fileId);

      await this.mergeTFLayer();
      //打开画板
      let openedTab = this.$store.state.openedTab;
      let tabNum = openedTab.findIndex((item) => item.route == data[1]);
      let routeInfo = {
        route: data[1],
        isReadOnly: data[2],
      };

      if (tabNum === -1) {
        // 该标签当前没有打开

        this.$store.commit("addTab", routeInfo);
      } else {
        // 该标签是已经打开过的，需要激活此标签页

        this.$store.commit("activeTab", routeInfo);
        bus.$emit("ending", true);
      }

      router.push({
        path: "/home/layout/board",
        query: {
          id: data[3],
        },
      });

      if (node.data.cell_type == "1") {
        bus.$emit("fileBarIcon", "1");
      } else if (node.data.cell_type == "2") {
        bus.$emit("fileBarIcon", "2");
      }

      bus.$emit("addHistoryCell", node.data.snow_id);
      bus.$emit("openCell", data[4]); //打开画板

      if (
        node.data.isTeam &&
        (node.data.cell_type === 1 || node.data.cell_type === 2)
      ) {
        if (node.parent.data.layerWs == null) {
          let WS = addLayerListMessageServe(node.parent);
          node.parent.data.layerWs = WS;
        }
      }
    });
    //右键添加图层
    bus.$on("contextMenuAddLayerOption", (data) => {
      this.addNewLayerRule();
    });
    //右键删除图层
    bus.$on("contextMenuDeleteLayer", async (data) => {
      this.delLayerBtn();
    });
    //右键合并图层
    bus.$on("contextMenuMergeLayer", () => {
      if (this.value_layerLibrary === 1) {
        this.mergeLayerBtn();
      }
    });
    //右键显示隐藏图层按钮
    bus.$on("contextMenuHideLayer", () => {
      this.isShowHide = !this.isShowHide;
    });
    //右键显示锁定图层按钮
    bus.$on("contextMenuLockLayer", () => {
      this.isShowLock = !this.isShowLock;
    });
    //右键显示输出图层按钮
    bus.$on("contextMenuOutputLayer", () => {
      this.isShowExport = !this.isShowExport;
    });
    //右键显示图层编号按钮
    bus.$on("contextMenuNumberLayer", async () => {
      this.isShowNumber = !this.isShowNumber;
    });

    //文件图层列表更新
    bus.$on("updateFileLayers", async (arr) => {
      await this.updateFileLayersFn(arr);
    });

    //webpad图层更新完成
    bus.$on("WBPadLayerUpdate", async (arr) => {
      await this.updateFileLayersFn(arr);
      bus.$emit("WBPadLayerUpdateComplete", true);
    });

    //共面波导图层更新完成
    bus.$on("WaveGuideLayerUpdate", async (arr) => {
      await this.updateFileLayersFn(arr);
      bus.$emit("WaveGuideLayerUpdateComplete", true);
    });

    //切换不同文件图层列表
    bus.$on("changeLayerList", async (data) => {
      let fileId = data[2].fileId;
      let activeNode = this.$store.state.activeNode;
      this.file_gns = activeNode.parent.data.gns;
      this.cell_gns = activeNode.data.gns;

      if (fileId !== this.currentFileId) {
        await this.getComponentFileLayerList(fileId);
      } else {
        //更新单元图层列表数据
        this.value_layerLibrary = 0;
        let fileLayerDataObj = await getFileLayerListFn(fileId);
        this.$store.commit(
          "setCurrentFileLayer",
          fileLayerDataObj.fileLayerDatas
        );
        this.setCurrentCellLayer(fileLayerDataObj.fileLayerDatas);
        this.layerState = fileLayerDataObj.layerState;
        this.layerDatas = fileLayerDataObj.fileLayerDatas;
      }
    });

    bus.$on("msg_layerUpdate", async (data) => {
      let type = data.func;
      let info = data.funcInfo;
      let fileId = this.$store.state.fileInfo.fileId;
      let fileLayerDataObj = await getFileLayerListFromTable(fileId);

      if (fileLayerDataObj !== null) {
        if (type == "layerUpdate") {
          let updateLayerList = data.fileLayerList;
          this.updateLayerListByMsg(updateLayerList, fileLayerDataObj, fileId);
        } else if (type == "layerNumberChange") {
          let updateLayerList = data.fileLayerList;
          this.updateLayerListByMsg(updateLayerList, fileLayerDataObj, fileId);

          this.cellLayerNumberChange();
        } else if (type == "layerDelete") {
          let updateLayerList = data.fileLayerList;
          this.updateLayerListByMsg(updateLayerList, fileLayerDataObj, fileId);

          let delLayerNumberList = info.delLayerNumberList;
          let cellList = [];
          let cellIdList = info.cellIdList;
          for (let i = 0; i < cellIdList.length; i++) {
            let cell = getCellFromTable(cellIdList[i]);
            if (cell !== null) {
              cellList.push(cell);
            }
          }
          if (cellList) {
            this.cellLayerDelete(cellList, delLayerNumberList);
          }
          bus.$emit("delLayer", true);
        } else if (type == "layerClear") {
          let cellId = info.cellId;
          let cell = getCellFromTable(cellId);
          if (cell) {
            let delLayerNumberList = info.delLayerNumberList;
            this.cellLayerClear(cell, delLayerNumberList);
            bus.$emit("delLayer", true);
          }
        } else if (type == "layerMerge") {
          let cellList = [];
          let cellIdList = info.cellIdList;
          for (let i = 0; i < cellIdList.length; i++) {
            let cell = getCellFromTable(cellIdList[i]);
            if (cell !== null) {
              cellList.push(cell);
            }
          }
          if (cellList) {
            let selectionObj = info.selectionObj;
            let toLayer = info.toLayer;
            cellList.forEach((item) => {
              mergeCellLayer(item, selectionObj, toLayer);
            });
            bus.$emit("mergeLayer", true);
          }
        }
      }
    });

    bus.$on("layerUpdate", (data) => {
      this.sendLayerUpdateMsg(this.$store.state.activeNode, "layerUpdate", {});
    });

    bus.$on("getNewLayerList", async (data) => {
      let fileLayerDataObj = await getDBFileLayerData(data);
      let fileInfo = {
        fileId: data,
      };
      setFileLayerList(fileLayerDataObj);
      let imgData = generateFillImg("All", fileInfo);
      this.getImgData(imgData, fileLayerDataObj.fileLayerDatas);
      //如果当前单元的文件图层是该文件图层
      if (this.$store.state.fileInfo.fileId === data) {
        if (this.value_layerLibrary === 0) {
          this.$store.commit(
            "setCurrentFileLayer",
            fileLayerDataObj.fileLayerDatas
          );
          this.layerDatas = fileLayerDataObj.fileLayerDatas;
          this.layerState = fileLayerDataObj.layerState;
        } else {
          this.setCurrentCellLayer(fileLayerDataObj.fileLayerDatas);
          this.layerDatas = this.$store.state.cellLayerList;
          this.layerState = fileLayerDataObj.layerState;
        }
      }
    });

    bus.$on("updateCellLayerList", () => {
      this.setCurrentCellLayer(this.$store.state.fileLayerList);
    });

    // 导出图层
    bus.$on("exportLayers", () => {
      this.exportDRCFile();
    });

    bus.$on("PDKLoadEnd", async (projectId) => {
      //判断所有文件图层列表的projectId是否有匹配的
      let fileId = this.$store.state.fileInfo.fileId;
      let currentProjectId = this.$store.state.proInfo.projectId;
      if (currentProjectId === projectId) {
        bus.$emit("loading", true);
        this.getDRF_TF_info(projectId);
        result.fillImgs.forEach((img, i) => {
          this.fillShapeDatas[i].image = img;
        });
        result.borderImgs.forEach((img, i) => {
          this.bordershapeDatas[i].image = img;
        });
        await this.getComponentFileLayerList(fileId);
        await this.mergeTFLayer();
        bus.$emit("ending", true);
        //消息同步,该项目下所有已打开版图文件的图层都需要重新更新
        // this.sendLayerUpdateMsg(this.$store.state.activeNode, "layerUpdate", {})
      }
    });

    // bus.$on("PDKLoadUpdateFileLayers", fileIdList => {

    //   let fileLayerList = []
    //   let fileLayerListTable = this.$store.state.fileLayerListTable
    //   for (let i = 0; i < fileLayerListTable.length; i++) {
    //     for (let j = 0; j < fileIdList.length; j++) {
    //       if (fileLayerListTable[i].fileId === fileIdList[j]) {

    //         fileLayerList.push(fileLayerListTable[i])
    //       }
    //     }
    //   }

    //   //先判断当前打开的画板是否有
    // })
  },
  beforeDestroy() {
    bus.$off("openLayerList");
    bus.$off("contextMenuAddLayerOption");
    bus.$off("contextMenuDeleteLayer");
    bus.$off("contextMenuMergeLayer");
    bus.$off("contextMenuHideLayer");
    bus.$off("contextMenuLockLayer");
    bus.$off("contextMenuOutputLayer");
    bus.$off("contextMenuNumberLayer");
    bus.$off("updateFileLayers");
    bus.$off("WBPadLayerUpdate");
    bus.$off("WaveGuideLayerUpdate");
    bus.$off("changeLayerList");
    bus.$off("msg_layerUpdate");
    bus.$off("layerUpdate");
    bus.$off("getNewLayerList");
    bus.$off("updateCellLayerList");
    bus.$off("exportLayers");
    bus.$off("PDKLoadEnd");
  },
  methods: {
    //pdk数据加载
    getDRF_TF_info(projectId) {
      //先获取PDKdrf,tf数据
      this.pdkDrf = getDrfFromTable(projectId);
      this.pdkTf = getTfFromTable(projectId);
      if (this.pdkDrf !== null && this.pdkTf !== null) {
        //填充颜色
        let pdkFillColorDatas = [];
        let drDefineColor = this.pdkDrf.drDefineColor;
        for (const key in drDefineColor) {
          pdkFillColorDatas.push(drDefineColor[key]);
        }

        this.fillColorDatas = pdkFillColorDatas;

        //填充形状
        let pdkFillShapeDatas = [];
        let drDefineStyle = this.pdkDrf.drDefineStyle;
        for (const key in drDefineStyle) {
          pdkFillShapeDatas.push({
            image: "",
            shape: drDefineStyle[key],
            name: drDefineStyle[key],
          });
        }

        this.fillShapeDatas = pdkFillShapeDatas;

        //边框颜色
        let pdkBorderColorDatas = [];
        let drDefineOutlineColor = this.pdkDrf.drDefineOutlineColor;
        for (const key in drDefineOutlineColor) {
          pdkBorderColorDatas.push(drDefineOutlineColor[key]);
        }

        this.borderColorDatas = pdkBorderColorDatas;

        //边框形状
        let pdkBordershapeDatas = [];
        let drDefineLineStyle = this.pdkDrf.drDefineLineStyle;
        for (const key in drDefineLineStyle) {
          pdkBordershapeDatas.push({
            image: "",
            shape: drDefineLineStyle[key],
            name: "纯色",
          });
        }

        this.bordershapeDatas = pdkBordershapeDatas;
      } else {
        return;
      }
    },

    //合并tf图层和文件图层
    async mergeTFLayer() {
      let that = this;
      let newLayerList = [];
      if (that.pdkTf !== null) {
        let length = that.pdkTf.layerDefinitions.length;
        if (length) {
          for (let i = 0; i < length; i++) {
            let layer = that.layerDatas.find((elem) => {
              return elem.layerNumber === that.pdkTf.layerDefinitions[i].Layer;
            });
            if (that.pdkDrf.drDefinePacket) {
              let packet = that.pdkDrf.drDefinePacket.filter((pac) => {
                return pac.PacketName === that.pdkTf.layerDefinitions[i].Packet;
              })[0];

              if (!layer) {
                if (packet.OutlineColor === "") {
                  packet.OutlineColor = "#ffffff";
                }
                if (packet.LineStyle === "") {
                  packet.LineStyle = "B1";
                }
                if (packet.Stipple === "") {
                  packet.Stipple = "F0";
                }
                if (packet.FillColor === "") {
                  packet.FillColor = "#ffffff";
                }
                let newLayerData = {
                  layerName: that.pdkTf.layerDefinitions[i].Name,
                  border: packet.LineStyle,
                  borderColor: packet.OutlineColor,
                  shape: packet.Stipple,
                  color: packet.FillColor,
                  layerNumber: that.pdkTf.layerDefinitions[i].Layer,
                };
                let newLayer = addSpecifyNewLayerFn(
                  that.layerDatas,
                  newLayerData
                );
                newLayerList.push(newLayer);
                // let res = await saveNewLayerFn([newLayer], this.file_gns)
                // if (res.code === 200000) {
                //   this.layerDatas.push(newLayer)
                // }
              } else {
                if (packet) {
                  if (packet.OutlineColor === "") {
                    packet.OutlineColor = "#ffffff";
                  }
                  if (packet.LineStyle === "") {
                    packet.LineStyle = "B1";
                  }
                  if (packet.Stipple === "") {
                    packet.Stipple = "F0";
                  }
                  if (packet.FillColor === "") {
                    packet.FillColor = "#ffffff";
                  }
                  let isEdit = false;
                  if (layer.layerName != that.pdkTf.layerDefinitions[i].Name) {
                    isEdit = true;
                    layer.layerName = that.pdkTf.layerDefinitions[i].Name;
                  }
                  if (layer.border != packet.LineStyle) {
                    isEdit = true;
                    layer.border = packet.LineStyle;
                  }
                  if (layer.borderColor != packet.OutlineColor) {
                    isEdit = true;
                    layer.borderColor = packet.OutlineColor;
                  }
                  if (layer.shape != packet.Stipple) {
                    isEdit = true;
                    layer.shape = packet.Stipple;
                  }
                  if (layer.color != packet.FillColor) {
                    isEdit = true;
                    layer.color = packet.FillColor;
                  }
                  layer.hide = !that.pdkTf.layerDefinitions[i].Visible;
                  layer.isExport = that.pdkTf.layerDefinitions[i].Export;
                  layer.lock = that.pdkTf.layerDefinitions[i].Lock;

                  if (isEdit) {
                    let edit_data = {
                      layerName: layer.layerName,
                      border: layer.border,
                      borderColor: layer.borderColor,
                      shape: layer.shape,
                      color: layer.color,
                    };
                    editLayerDatasFn(
                      that.file_gns,
                      layer.layerNumber,
                      edit_data
                    );
                  }
                }
              }
            }
          }
          if (newLayerList.length) {
            let res = await saveNewLayerFn(newLayerList, that.file_gns);
            if (res.code === 200000) {
              newLayerList.forEach((item) => {
                that.layerDatas.push(item);
              });
            }
          }
        }

        that.layerState.updateLayerMats(that.layerDatas);
        let imgData = generateFillImg("All", that.$store.state.fileInfo);
        that.getImgData(imgData, that.layerDatas);
      } else {
        return;
      }
    },

    onContextMenu(row, column, event) {
      this.$refs.layerDatasTable.setCurrentRow(row);
      let children = [
        new HideLayerOption(
          this.$t("messages.hide"),
          true,
          "iconfont icon-gougou",
          this.isShowHide,
          false
        ),
        new LockLayerOption(
          this.$t("messages.lock"),
          false,
          "iconfont icon-gougou",
          this.isShowLock,
          false
        ),
        new OutputLayerOption(
          this.$t("messages.output"),
          false,
          "iconfont icon-gougou",
          this.isShowExport,
          false
        ),
        new NumberLayerOption(
          this.$t("messages.NO"),
          false,
          "iconfont icon-gougou",
          this.isShowNumber,
          false
        ),
      ];
      if (this.value_layerLibrary === 0) {
        this.$contextmenu({
          items: [
            new AddLayerOption(
              this.$t("messages.addLayer"),
              true,
              "iconfont icon-tianjia",
              false
            ),
            new DeleteLayerOption(
              this.$t("messages.deleteLayer"),
              false,
              "iconfont icon-shanchu",
              false
            ),
            new MergeLayerOption(
              this.$t("messages.mergeLayer"),
              false,
              "iconfont icon-hebing1",
              true
            ),
            new ListInfoOption(
              this.$t("messages.listInformation"),
              false,
              "",
              false,
              children
            ),
          ],
          event, // 鼠标事件信息
          customClass: "custom-class", // 自定义菜单 class
          zIndex: 10000, // 菜单样式 z-index
          minWidth: 170, // 主菜单最小宽度
        });
      } else {
        this.$contextmenu({
          items: [
            new AddLayerOption(
              this.$t("messages.addLayer"),
              true,
              "iconfont icon-tianjia",
              true
            ),
            new DeleteLayerOption(
              this.$t("messages.clearLayer"),
              false,
              "iconfont icon-shanchu",
              false
            ),
            new MergeLayerOption(
              this.$t("messages.mergeLayer"),
              false,
              "iconfont icon-hebing1",
              false
            ),
            new ListInfoOption(
              this.$t("messages.listInformation"),
              false,
              "",
              false,
              children
            ),
          ],
          event, // 鼠标事件信息
          customClass: "custom-class", // 自定义菜单 class
          zIndex: 10000, // 菜单样式 z-index
          minWidth: 170, // 主菜单最小宽度
        });
      }
    },

    //图层同步发送消息
    sendLayerUpdateMsg(node, func, info) {
      let fileNode = node.parent;
      let fileId = fileNode.data.snow_id;
      let fileLayerDataObj = getFileLayerListFromTable(fileId);
      let fileLayerList = JSON.parse(
        JSON.stringify(fileLayerDataObj.fileLayerDatas)
      );
      for (let i = 0; i < fileLayerList.length; i++) {
        fileLayerList[i].image = "";
        fileLayerList[i].showNameInput = false;
        fileLayerList[i].showNumInput = false;
      }
      //涉及修改的单元id列表
      let data = {
        fileId,
        fileLayerList: fileLayerList,
        func,
        funcInfo: info,
      };

      fileNode.data.layerWs.sendMsg(data);
    },

    //图层同步接收消息图层列表刷新
    updateLayerListByMsg(updateLayerList, fileLayerDataObj, fileId) {
      let oldFileLayerList = fileLayerDataObj.fileLayerDatas;
      for (let i = 0; i < updateLayerList.length; i++) {
        for (let j = 0; j < oldFileLayerList.length; j++) {
          if (updateLayerList[i].id === oldFileLayerList[j].id) {
            updateLayerList[i].lock = oldFileLayerList[j].lock;
            updateLayerList[i].hide = oldFileLayerList[j].hide;
          }
        }
      }

      this.layerState.updateLayerMats(updateLayerList);
      let newFileLayerListObj = {
        fileId,
        fileLayerDatas: updateLayerList,
        layerState: this.layerState,
      };

      setFileLayerList(newFileLayerListObj);
      let imgData = generateFillImg("All", this.$store.state.fileInfo);

      this.getImgData(imgData, newFileLayerListObj.fileLayerDatas);
      if (this.value_layerLibrary === 0) {
        this.$store.commit(
          "setCurrentFileLayer",
          newFileLayerListObj.fileLayerDatas
        );
        this.layerDatas = newFileLayerListObj.fileLayerDatas;
      } else {
        this.setCurrentCellLayer(newFileLayerListObj.fileLayerDatas);
        this.layerDatas = this.$store.state.cellLayerList;
      }
    },

    //文件图层更新方法
    async updateFileLayersFn(arr) {
      let activeNode = this.$store.state.activeNode;
      this.file_gns = activeNode.parent.data.gns;
      this.cell_gns = activeNode.data.gns;
      let fileId = this.$store.state.fileInfo.fileId;
      let fileLayerDataObj = getFileLayerListFromTable(fileId);
      let fileLayerList = fileLayerDataObj.fileLayerDatas;

      let newLayerList = updateFileLayers(arr, fileLayerList);

      if (newLayerList !== null) {
        if (newLayerList.length > 0) {
          this.layerState.updateLayerMats(newLayerList);
          for (let i = 0; i < newLayerList.length; i++) {
            this.updateImgLayer = newLayerList[i];
            let imgData = generateFillImg(
              this.updateImgLayer.layerNumber,
              this.$store.state.fileInfo
            );
            this.getImgData(imgData, this.updateImgLayer);
          }

          await saveNewLayerFn(newLayerList, this.file_gns);

          if (this.$store.state.activeNode.data.isTeam) {
            this.sendLayerUpdateMsg(
              this.$store.state.activeNode,
              "layerUpdate",
              {}
            );
          }
        } else {
        }
      } else {
        return;
      }
    },

    //标题点击
    clickHeader(column) {
      if (column.label == this.$t("messages.lock")) {
        let allLock = false;
        if (this.lockPointer == 0) {
          this.layerDatas.forEach((item) => {
            // if (item.layerName != this.selcetLayer.layerName) {
            item.lock = true;
            allLock = true;
            // }
          });
          this.lockPointer = 1;
        } else {
          this.layerDatas.forEach((item) => {
            // if (item.layerName != this.selcetLayer.layerName) {
            item.lock = false;
            allLock = false;
            // }
          });
          this.lockPointer = 0;
        }
        this.layerState.updateLayerMats(this.layerDatas);
        bus.$emit("allLock", allLock);
      } else if (column.label == this.$t("messages.hide")) {
        let allHide = false;
        if (this.hidePointer == 0) {
          this.layerDatas.forEach((item) => {
            // if (item.layerName != this.selcetLayer.layerName) {
            item.lock = true;
            item.hide = true;
            allHide = true;
            // }
          });
          this.hidePointer = 1;
        } else {
          this.layerDatas.forEach((item) => {
            // if (item.layerName != this.selcetLayer.layerName) {
            item.lock = false;
            item.hide = false;
            allHide = false;
            // }
          });
          this.hidePointer = 0;
        }
        this.layerState.updateLayerMats(this.layerDatas);
        bus.$emit("allHide", allHide);
      } else if (column.label == this.$t("messages.output")) {
      }
    },

    //改变图层库选择
    changeLayerDatas(val) {
      this.value_layerLibrary = val;
      let fileId = this.$store.state.fileInfo.fileId;
      let fileLayerDataObj = getFileLayerListFromTable(fileId);

      let fileLayerDatas = fileLayerDataObj.fileLayerDatas;
      if (this.value_layerLibrary === 0) {
        fileLayerDatas = sortLayerByNumber(fileLayerDatas);
        this.$store.commit("setCurrentFileLayer", fileLayerDatas);
        this.layerDatas = fileLayerDatas;
      } else {
        this.setCurrentCellLayer(fileLayerDatas);
        this.layerDatas = this.$store.state.cellLayerList;
      }
      if (this.layerDatas.length > 0) {
        this.toggleSelection(this.layerDatas[0]);
      }
    },

    //通过fileId获取完整文件图层数据
    async getComponentFileLayerList(fileId) {
      this.value_layerLibrary = 0;
      let fileLayerDataObj = await getFileLayerListFn(fileId);

      this.layerState = fileLayerDataObj.layerState;
      this.layerDatas = fileLayerDataObj.fileLayerDatas;
      this.currentFileId = fileLayerDataObj.fileId;
      if (this.layerDatas.length) {
        let imgData = generateFillImg("All", this.$store.state.fileInfo);
        this.getImgData(imgData, this.layerDatas);
      }

      //同时存储当前版图/单元图层列表
      this.$store.commit(
        "setCurrentFileLayer",
        fileLayerDataObj.fileLayerDatas
      );
      this.setCurrentCellLayer(this.layerDatas);
      this.toggleSelection(this.layerDatas[0]);
    },

    // 设置当前单元图层数据
    setCurrentCellLayer(fileLayerDatas) {
      let cell = this.$store.state.activeCell;
      let cellLayerList = [];
      let cellIdList = cell.ids;

      let depend_cellIdList = cell.depend_ids();
      cellIdList = [...new Set([...cellIdList, ...depend_cellIdList])];
      let size = cellIdList.length;
      for (let i = 0; i < size; i++) {
        let cellLayer = fileLayerDatas.filter(
          (item) => item.id === cellIdList[i]
        )[0];
        cellLayerList.unshift(cellLayer);
      }
      cellLayerList = sortLayerByNumber(cellLayerList);
      this.$store.commit("setCurrentCellLayer", cellLayerList);
    },

    //自动刷新图片
    updateRow() {
      let index = this.layerDatas.findIndex(
        (item) => item.layerNumber === this.updateImgLayer.layerNumber
      );

      this.$set(this.layerDatas, index, {});
      setTimeout(() => {
        this.$set(this.layerDatas, index, this.updateImgLayer);
        this.toggleSelection(this.layerDatas[index]);
        if (this.$store.state.activeNode.data.isTeam) {
          this.sendLayerUpdateMsg(
            this.$store.state.activeNode,
            "layerUpdate",
            {}
          );
        }
      }, 10);
    },

    //图层图片获取
    getImgData(imgData, layerDatas) {
      if (imgData.layerNumber === "All") {
        for (let i = 0; i < layerDatas.length; i++) {
          layerDatas[i].image = imgData.imgs[i];
        }
      } else {
        layerDatas.image = imgData.imgs[0];
      }
    },

    //单击图层列表切换图层选择
    layerDatasCurrentChange(val) {
      if (val !== null) {
        this.selcetLayer = val;
        this.fillColorSelect = val.color;
        this.borderColorSelect = val.borderColor;
        bus.$emit("changeLayerNum", val);
        this.layerState.layer = val.layerNumber;
        this.layerState.layerId = val.id;
        this.$store.commit("setSelectedLayer", val);
      }
    },

    // 自动选择图层
    toggleSelection(row) {
      setTimeout(() => {
        if (this.$refs.layerDatasTable.data.length > 0) {
          this.$refs.layerDatasTable.setCurrentRow(row);
          this.selcetLayer = row;
          this.fillColorSelect = row.color;
          this.borderColorSelect = row.borderColor;
          bus.$emit("changeLayerNum", row);
          this.layerState.layer = row.layerNumber;
          this.layerState.layerId = row.id;
          this.$store.commit("setSelectedLayer", row);
        }
      }, 10);
    },

    //修改图层名
    async changeLayerName(index, row) {
      if (this.$store.state.activeNode.data.isTeam) {
        let fileGns = this.$store.state.activeNode.parent.data.gns;
        let teamRule = await this.getTeamRule(fileGns);
        if (teamRule == "可删除、编辑") {
          row.showNameInput = true;
          this.layerNameOld = row.layerName;
          setTimeout(() => {
            this.$refs["layers_name" + index].focus();
            this.$refs["layers_name" + index].select();
          }, 1);
        } else {
          bus.$emit("errDialog", this.$t("messages.userProjectPermission"));
        }
      } else {
        row.showNameInput = true;
        this.layerNameOld = row.layerName;
        setTimeout(() => {
          this.$refs["layers_name" + index].focus();
          this.$refs["layers_name" + index].select();
        }, 1);
      }
    },

    //修改图层名失焦
    resetNameBlur(row) {
      // editLayerDataFn(this.file_gns, this.selcetLayer.layerNumber, "color", color)

      if (
        row.layerName !== "" &&
        /^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(row.layerName) &&
        [...row.layerName][0] !== " " &&
        [...row.layerName][[...row.layerName].length - 1] !== " "
      ) {
        if ([...row.layerName].length > 0 && [...row.layerName].length <= 10) {
          this.$message({
            message: this.$t("messages.modifiedLayerInfo"),
            type: "success",
          });
          editLayerDataFn(
            this.file_gns,
            row.layerNumber,
            "layerName",
            row.layerName
          );

          if (this.$store.state.activeNode.data.isTeam) {
            this.sendLayerUpdateMsg(
              this.$store.state.activeNode,
              "layerUpdate",
              {}
            );
          }
          row.showNameInput = false;
        } else {
          bus.$emit("errDialog", this.$t("messages.LayerNameCannotEmpty"));
          row.showNameInput = true;
        }
      } else if (row.layerName === "") {
        row.layerName = this.layerNameOld;
        row.showNameInput = false;
      } else {
        bus.$emit("errDialog", this.$t("messages.enterLayerNameFormat"));
        row.showNameInput = true;
      }
    },

    //重命名回车
    resetNameEnter(event) {
      event.target.blur();
    },

    //输出
    exportChangeFn(row) { },

    //锁定点击
    lockClick(row) {
      this.layerState.updateLayerMats([row]);
      bus.$emit("lockClick", row);
    },

    //隐藏点击
    hideClick(row) {
      this.layerState.updateLayerMats([row]);
      bus.$emit("hideClick", row);
    },

    //修改图层编号
    async changeLayerNumber(index, row) {
      if (this.$store.state.activeNode.data.isTeam) {
        let fileGns = this.$store.state.activeNode.parent.data.gns;
        let teamRule = await this.getTeamRule(fileGns);
        if (teamRule == "可删除、编辑") {
          row.showNumInput = true;
          this.layerNumberOld = row.layerNumber;
          this.layerNumberArr.splice(0, this.layerNumberArr.length);
          this.layerDatas.forEach((item) => {
            this.layerNumberArr.push(item.layerNumber);
          });
          setTimeout(() => {
            this.$refs["layer_number" + index].focus();
            this.$refs["layer_number" + index].select();
          }, 1);
        } else {
          bus.$emit("errDialog", this.$t("messages.userProjectPermission"));
        }
      } else {
        row.showNumInput = true;
        this.layerNumberOld = row.layerNumber;
        this.layerNumberArr.splice(0, this.layerNumberArr.length);
        this.layerDatas.forEach((item) => {
          this.layerNumberArr.push(item.layerNumber);
        });
        setTimeout(() => {
          this.$refs["layer_number" + index].focus();
          this.$refs["layer_number" + index].select();
        }, 1);
      }
    },

    //修改图层编号失焦
    resetNumberBlur(row, index) {
      // editLayerDataFn(this.file_gns, this.selcetLayer.layerNumber, "color", color)
      if (!isNaN(row.layerNumber)) {
        let number = Number(row.layerNumber);

        if (/^[1-9][0-9]*$/.test(number) || number === 0) {
          if (row.layerNumber == "" && parseInt(row.layerNumber) !== 0) {
            row.layerNumber = this.layerNumberOld;
            row.showNumInput = false;
          } else if (
            parseInt(row.layerNumber) === 0 &&
            this.layerNumberArr.findIndex((item) => item === 0) === -1
          ) {
            row.layerNumber = 0;
            if (parseInt(row.layerNumber) !== this.layerNumberOld) {
              this.$message({
                message: this.$t("messages.modifiedLayerInfo"),
                type: "success",
              });

              editLayerDataFn(
                this.file_gns,
                this.layerNumberOld,
                "layerNumber",
                row.layerNumber
              );
              this.layerState.changeLayerNumber(
                this.layerNumberOld,
                row.layerNumber
              );
              this.layerState.updateLayerMats(this.layerDatas);

              bus.$emit("changeLayerNumber", {
                oldNumber: this.layerNumberOld,
                newNumber: row.layerNumber,
                layerData: row,
              });

              bus.$emit("changeLayerNum", row);

              this.cellLayerNumberChange();
              if (this.$store.state.activeNode.data.isTeam) {
                this.sendLayerUpdateMsg(
                  this.$store.state.activeNode,
                  "layerNumberChange",
                  {}
                );
              }
            }

            row.showNumInput = false;
          } else if (number >= 0 && number <= 4294967295) {
            if (
              !this.layerNumberArr.some(
                (item) => item === parseInt(row.layerNumber)
              ) &&
              parseInt(row.layerNumber) !== this.layerNumberOld
            ) {
              this.$message({
                message: this.$t("messages.modifiedLayerInfo"),
                type: "success",
              });
              row.showNumInput = false;
              row.layerNumber = parseInt(row.layerNumber);

              editLayerDataFn(
                this.file_gns,
                this.layerNumberOld,
                "layerNumber",
                row.layerNumber
              );
              this.layerState.changeLayerNumber(
                this.layerNumberOld,
                row.layerNumber
              );
              this.layerState.updateLayerMats(this.layerDatas);

              bus.$emit("changeLayerNumber", {
                oldNumber: this.layerNumberOld,
                newNumber: row.layerNumber,
                layerData: row,
              });

              bus.$emit("changeLayerNum", row);

              this.cellLayerNumberChange();

              if (this.$store.state.activeNode.data.isTeam) {
                this.sendLayerUpdateMsg(
                  this.$store.state.activeNode,
                  "layerNumberChange",
                  {}
                );
              }
            } else if (parseInt(row.layerNumber) === this.layerNumberOld) {
              row.showNumInput = false;
            } else {
              bus.$emit(
                "errDialog",
                this.$t("messages.enterCorrectLayerNumber")
              );
              row.layerNumber = this.layerNumberOld;
              this.$refs["layer_number" + index].select();
              row.showNumInput = true;
            }
          } else {
            bus.$emit("errDialog", this.$t("messages.enterCorrectLayerNumber"));
            row.layerNumber = this.layerNumberOld;
            this.$refs["layer_number" + index].select();
            row.showNumInput = true;
          }
        } else {
          bus.$emit("errDialog", this.$t("messages.enterCorrectLayerNumber"));
          row.layerNumber = this.layerNumberOld;
          this.$refs["layer_number" + index].select();
          row.showNumInput = true;
        }
      } else {
        bus.$emit("errDialog", this.$t("messages.enterCorrectLayerNumber"));
        row.layerNumber = this.layerNumberOld;
        this.$refs["layer_number" + index].select();
        row.showNumInput = true;
      }
    },

    //编号修改时修改图形
    async cellLayerNumberChange() {
      // let cell = this.$store.state.activeCell
      // let associatedCellList = cell.depend_cells(-1);
      // associatedCellList.push(cell);
      // associatedCellList = await contrastCellDataByLayerId(
      //   this.$store.state.fileInfo.fileId,
      //   associatedCellList
      // );

      let cellObjList = batchGetCellFromTableByFileId(
        this.$store.state.fileInfo.fileId
      );

      let cellList = cellObjList.map((item) => item.cell);
      await contrastCellDataByLayerId(
        this.$store.state.fileInfo.fileId,
        cellList
      );
    },

    //修改图层编号回车
    resetNumberEnter(event) {
      event.target.blur();
    },

    //添加图层
    async addNewLayer() {
      let newLayer = addNewLayerFn(this.layerDatas, this.layerPointer);
      if (newLayer !== false) {
        let res = await saveNewLayerFn([newLayer], this.file_gns);
        if (res.code === 200000) {
          this.layerDatas.push(newLayer);
          this.layerState.updateLayerMats(this.layerDatas);
          this.updateImgLayer = newLayer;
          let imgData = generateFillImg(
            this.updateImgLayer.layerNumber,
            this.$store.state.fileInfo
          );
          this.getImgData(imgData, this.updateImgLayer);

          if (this.layerPointer < 9) {
            this.layerPointer++;
          } else {
            this.layerPointer = 0;
          }

          //自动选择新建的图层
          this.toggleSelection(newLayer);
          this.$nextTick(() => {
            this.$refs.layerDatasTable.bodyWrapper.scrollTop =
              this.$refs.layerDatasTable.bodyWrapper.scrollHeight;
          });
          if (this.$store.state.activeNode.data.isTeam) {
            this.sendLayerUpdateMsg(
              this.$store.state.activeNode,
              "layerUpdate",
              {}
            );
          }
        }
      }
    },

    //新建图层权限
    async addNewLayerRule() {
      if (this.$store.state.activeNode.data.isTeam) {
        let teamRule = await this.getTeamRule(this.file_gns);
        if (teamRule == "可删除、编辑") {
          this.addNewLayer();
        } else {
          // bus.$emit("errDialog", this.$t('messages.userProjectPermission'));
          bus.$emit("errDialog", "用户没有该文件权限");
          return;
        }
      } else {
        this.addNewLayer();
      }
    },

    //合并图层
    mergeLayerBtn() {
      if (this.isOpenCell) {
        if (this.value_layerLibrary == 1) {
          this.mergeLayerVisible = true;
        } else if (this.value_layerLibrary == 0) {
          return;
        }
      } else {
        return;
      }
    },

    mergeSureBtnClassList() {
      if (this.mergeLayer !== "" && this.mergeLayerSelection.length > 1) {
        this.activateBtn(this.$refs.mergeSureBtn);
      } else {
        this.disableBtn(this.$refs.mergeSureBtn);
      }
    },

    mergeSelectionChange(val) {
      let mergeVal = val.sort((a, b) => {
        return a.layerNumber - b.layerNumber;
      });
      this.mergeLayerSelection = mergeVal; //所选的需要合并操作的图层
      this.mergeSureBtnClassList(mergeVal);
    },

    selectMergeLayer(val) {
      if (this.mergeLayerSelection.length < 2) {
        this.mergeLayer = "";
      } else {
        this.mergeLayer = val;
        this.mergeSureBtnClassList();
      }
    },

    async mergeLayerSureBtn() {
      //判断是否是团队,有无权限,需要保存整个树上的数据
      let mergeRule = false;

      let cell = this.$store.state.activeCell;
      let needMergeCellList = [];
      let associatedCellList = cell.depend_cells(-1);
      let size = associatedCellList.length;
      let mergelayerNumberList = this.mergeLayerSelection.map(
        (item) => item.layerNumber
      );

      for (let j = 0; j < size; j++) {
        let layerArr = associatedCellList[j].layers;

        const normalArr = [...layerArr, ...mergelayerNumberList];

        const setArr = [...new Set(normalArr)];
        if (normalArr.length !== setArr.length) {
          needMergeCellList.push(associatedCellList[j]);
        }
      }
      needMergeCellList.push(cell);

      if (!this.$store.state.activeNode.data.isTeam) {
        //是个人
        mergeRule = true;
      } else {
        //是团队
        for (let i = 0; i < needMergeCellList.length; i++) {
          let cellId = needMergeCellList[i].snow_id;
          let cellGns = `${this.file_gns}/${cellId}`;
          //判断是否有文件权限
          let teamRule = await this.getTeamRule(cellGns);
          if (teamRule == "可删除、编辑") {
            //判断是否有人打开
            let lock = await this.getCellLock(cellId);
            if (lock) {
              mergeRule = false;
              bus.$emit("errDialog", i18n.t("messages.fileTip12"));
              this.resetMergeLayerDialog();
              return;
            } else {
              mergeRule = true;
            }
          } else {
            bus.$emit("errDialog", i18n.t("messages.userCellPermission"));
            mergeRule = false;
            this.resetMergeLayerDialog();
            return;
          }
        }
      }

      if (mergeRule) {
        let mergeLayerData = {}; //选择的合并到的图层
        this.layerDatas.forEach((item) => {
          if (this.mergeLayer == item.layerName) {
            mergeLayerData = JSON.parse(JSON.stringify(item));
          }
        });
        this.filterMergeLayer(mergeLayerData);

        let mergeToData = this.layerDatas.filter((item) => {
          return item.layerName === this.mergeLayer;
        });
        //默认选择被合并到的图层
        this.$store.commit("setCurrentCellLayer", this.layerDatas);
        this.$refs.layerDatasTable.setCurrentRow(mergeToData[0]);
        // 传参-合并图层对应的编号
        let selectionObj = {};
        this.mergeLayerSelection.forEach((item) => {
          let layerNumber = item.layerNumber;
          selectionObj[layerNumber] = item.id;
        });
        let toLayer = {
          num: mergeToData[0].layerNumber,
          id: mergeToData[0].id,
        };

        needMergeCellList.forEach((item) => {
          mergeCellLayer(item, selectionObj, toLayer);
        });

        bus.$emit("mergeLayer", true);
        let cellIdList = [];
        for (let i = 0; i < needMergeCellList.length; i++) {
          let cellId = needMergeCellList[i].snow_id;
          cellIdList.push(cellId);
          let cellGns = `${this.file_gns}/${cellId}`;
          await saveCellFn(cellId, cellGns);
        }

        if (this.$store.state.activeNode.data.isTeam) {
          //是团队,发送消息
          this.sendLayerUpdateMsg(this.$store.state.activeNode, "layerMerge", {
            toLayer,
            selectionObj,
            cellIdList,
          });
        }

        this.resetMergeLayerDialog();
      }
    },

    mergeLayerCancelBtn() {
      this.resetMergeLayerDialog();
    },

    resetMergeLayerDialog() {
      this.mergeLayerVisible = false;
      this.mergeLayer = "";
      this.mergeLayerSelection.splice(0, this.mergeLayerSelection.length);
      this.$refs.mergeLayerTable.clearSelection();
    },

    //过滤合并的图层
    filterMergeLayer(mergeLayerData) {
      let delLayerArr = this.mergeLayerSelection.filter(
        (item) => item.layerNumber !== mergeLayerData.layerNumber
      );
      let afterMergeLayerDatas = this.layerDatas.filter((v) =>
        delLayerArr.every((val) => val.layerNumber != v.layerNumber)
      ); //删除所选后的
      this.layerDatas.splice(0, this.layerDatas.length);

      this.layerDatas = afterMergeLayerDatas;
    },

    //删除图层
    async delLayerBtn() {
      if (this.$store.state.activeNode.data.isTeam) {
        let fileGns = this.$store.state.activeNode.parent.data.gns;
        let teamRule = await this.getTeamRule(fileGns);
        if (teamRule == "可删除、编辑") {
          if (this.isOpenCell) {
            this.delLayerVisible = true;
          } else {
            return;
          }
        } else {
          // bus.$emit("errDialog", this.$t('messages.userProjectPermission'));
          bus.$emit("errDialog", "用户没有该文件权限");
        }
      } else {
        if (this.isOpenCell) {
          this.delLayerVisible = true;
        } else {
          return;
        }
      }
    },

    //删除选项选择
    delSelectionChange(val) {
      this.delLayerSection = val;
      this.delWindowActive();
    },

    //删除按钮禁用
    delWindowActive() {
      if (this.delLayerSection.length === 0) {
        this.disableBtn(this.$refs.delWindowBtn);
      } else {
        this.activateBtn(this.$refs.delWindowBtn);
      }
    },

    //打开删除提示页面
    openDelDialog() {
      this.delDialogVisible = true;
      this.deleteLayerName = "";
      this.delLayerSection.sort((a, b) => {
        return a.layerNumber - b.layerNumber;
      });
      this.delLayerSection.forEach((item) => {
        this.deleteLayerName += ` ${item.layerName}`;
      });
    },

    //删除提示取消
    cancelDelDialog() {
      this.delLayerVisible = false;
      this.$refs.delLayerTable.clearSelection();
    },

    //已知
    userKnow() {
      if (!this.userKnowChecked) {
        this.disableBtn(this.$refs.delSureBtn);
      } else {
        this.activateBtn(this.$refs.delSureBtn);
      }
    },

    //删除确认
    async delDialogSureBtn() {
      //清空图层只保存当前画板数据,删除图层不需要保存任何数据
      let deleteRule = false;
      let delLayerNumberList = [];
      if (!this.$store.state.activeNode.data.isTeam) {
        //是个人
        deleteRule = true;
      } else {
        //是团队
        let cell = this.$store.state.activeCell;
        let associatedCellList = cell.depend_cells(-1);
        associatedCellList.push(cell);
        let size = associatedCellList.length;
        for (let i = 0; i < size; i++) {
          let cellId = associatedCellList[i].snow_id;
          let cellGns = `${this.file_gns}/${cellId}`;
          //判断是否有文件权限
          let teamRule = await this.getTeamRule(cellGns);
          if (teamRule == "可删除、编辑") {
            //判断是否有人打开
            let lock = await this.getCellLock(cellId);
            if (lock) {
              deleteRule = false;
              bus.$emit("errDialog", i18n.t("messages.fileTip12"));
              this.resetDelDialog();
              return;
            } else {
              deleteRule = true;
            }
          } else {
            bus.$emit("errDialog", i18n.t("messages.userCellPermission"));
            deleteRule = false;
            this.resetDelDialog();
            return;
          }
        }
      }
      if (deleteRule) {
        if (this.value_layerLibrary === 1) {
          let delLayerArr = this.delLayerSection.slice(0);
          this.layerDatas = this.filterDelLayer(this.layerDatas);
          this.delLayerSection.splice(0, this.delLayerSection.length);
          this.$store.commit("setCurrentCellLayer", this.layerDatas);

          let delLayerNumberList = delLayerArr.map((item) => item.layerNumber);
          let cell = this.$store.state.activeCell;
          cell = this.cellLayerClear(cell, delLayerNumberList);
          let saveCellGns = `${this.file_gns}/${cell.snow_id}`;
          await saveCellFn(cell.snow_id, saveCellGns);
          bus.$emit("delLayer", true);

          if (this.$store.state.activeNode.data.isTeam) {
            //是团队,发送消息
            this.sendLayerUpdateMsg(
              this.$store.state.activeNode,
              "layerClear",
              { delLayerNumberList, cellId: cell.snow_id }
            );
          }
        } else if (this.value_layerLibrary === 0) {
          //先判断权限
          let fileId = this.$store.state.fileInfo.fileId;

          let delLayerArr = this.delLayerSection.slice(0);
          this.layerDatas = this.filterDelLayer(this.layerDatas);
          this.delLayerSection.splice(0, this.delLayerSection.length);

          delLayerArr.forEach((item) => {
            this.layerState.deletLayerMats(item.layerNumber);
          });

          if (this.layerDatas.length === 0) {
            this.layerState.layer = null;
            this.layerState.layerId = null;
            this.selcetLayer = null;
            this.fillColorSelect = null;
            this.borderColorSelect = null;
            this.$store.commit("setSelectedLayer", null);
          } else {
            this.toggleSelection(this.layerDatas[0]);
          }

          let fileLayerListObj = {
            fileId,
            fileLayerDatas: this.layerDatas,
            layerState: this.layerState,
          };

          //回传图层列表数据到状态机,删除数据
          setFileLayerList(fileLayerListObj);
          this.$store.commit(
            "setCurrentFileLayer",
            fileLayerListObj.fileLayerDatas
          );
          //清除画板数据
          delLayerNumberList = delLayerArr.map((item) => item.layerNumber);

          // let cell = this.$store.state.activeCell
          // let associatedCellList = cell.depend_cells(-1)
          // associatedCellList.push(cell)
          // let cellIdList = this.cellLayerDelete(associatedCellList, delLayerNumberList)
          let cellObjList = batchGetCellFromTableByFileId(
            this.$store.state.fileInfo.fileId
          );

          let cellList = cellObjList.map((item) => item.cell);
          let cellIdList = this.cellLayerDelete(cellList, delLayerNumberList);
          await deleteLayerInfo(this.file_gns, delLayerNumberList);
          if (this.$store.state.activeNode.data.isTeam) {
            //是团队,发送消息
            this.sendLayerUpdateMsg(
              this.$store.state.activeNode,
              "layerDelete",
              { delLayerNumberList, cellIdList }
            );
          }
          bus.$emit("delLayer", true);
        }
        this.resetDelDialog();
      }
    },

    //删除取消
    delDialogCancelBtn() {
      this.resetDelDialog();
    },

    resetDelDialog() {
      this.delDialogVisible = false;
      this.userKnowChecked = false;
      this.delLayerVisible = false;
      this.userKnow();
      this.delLayerSection.splice(0, this.delLayerSection.length);
    },

    //过滤删除图层
    filterDelLayer(layerDatas) {
      let afterDelFileLayer = layerDatas.filter((v) =>
        this.delLayerSection.every((val) => val.layerNumber != v.layerNumber)
      );
      layerDatas.splice(0, layerDatas.length);
      layerDatas = afterDelFileLayer;
      return layerDatas;
    },

    //删除图层对单元的修改
    cellLayerDelete(cellList, delLayerNumberList) {
      let size = cellList.length;
      let cellIdList = [];
      for (let i = 0; i < size; i++) {
        cellIdList.push(cellList[i].snow_id);
        deleteCellDataByLayers(cellList[i], delLayerNumberList);
      }
      return cellIdList;
    },

    //清空图层对当前单元的修改
    cellLayerClear(cell, delLayerNumberList) {
      cell = deleteCellDataByLayers(cell, delLayerNumberList);
      return cell;
    },

    // 修改样式更新方法
    updataStyle() {
      this.layerState.updateLayerMats([this.selcetLayer]);
      let imgData = generateFillImg(
        this.selcetLayer.layerNumber,
        this.$store.state.fileInfo
      );
      this.updateImgLayer = this.selcetLayer;
      this.getImgData(imgData, this.updateImgLayer);
      this.updateRow();
    },

    //填充颜色
    selectFillColor(color, index) {
      this.fillColorSelect = color;
      this.select_fillColor_index = index;

      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.color = color;
        editLayerDataFn(
          this.file_gns,
          this.selcetLayer.layerNumber,
          "color",
          color
        );
        this.updataStyle();
        bus.$emit("layerChange", this.selcetLayer);
      }
    },

    //选择自定义颜色
    fillColorPickerChange(color) {
      this.fillColorSelect = color;
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.color = color;
        editLayerDataFn(
          this.file_gns,
          this.selcetLayer.layerNumber,
          "color",
          color
        );
        this.updataStyle();
        bus.$emit("layerChange", this.selcetLayer);
      }
      this.fillColorDatas[this.select_fillColor_index] = color;
    },

    //边框颜色
    selectBorderColor(color, index) {
      this.borderColorSelect = color;
      this.select_borderColor_index = index;
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.borderColor = color;
        editLayerDataFn(
          this.file_gns,
          this.selcetLayer.layerNumber,
          "borderColor",
          color
        );
        this.updataStyle();
        bus.$emit("layerChange", this.selcetLayer);
      }
    },

    //选择自定义边框颜色
    borderColorPickerChange(color) {
      this.borderColorSelect = color;
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.borderColor = color;
        editLayerDataFn(
          this.file_gns,
          this.selcetLayer.layerNumber,
          "borderColor",
          color
        );
        this.updataStyle();
        bus.$emit("layerChange", this.selcetLayer);
      }
      this.borderColorDatas[this.select_borderColor_index] = color;
    },

    // //单击填充形状
    getFillShape(val) {
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.shape = val;
        editLayerDataFn(
          this.file_gns,
          this.selcetLayer.layerNumber,
          "shape",
          val
        );
        this.updataStyle();
        bus.$emit("layerChange", this.selcetLayer);
      }
    },

    //单击边框形状
    getBorderSquare(val) {
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.border = val;
        editLayerDataFn(
          this.file_gns,
          this.selcetLayer.layerNumber,
          "border",
          val
        );
        this.updataStyle();
        bus.$emit("layerChange", this.selcetLayer);
      }
    },

    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    exportDRCFile() {
      let current_file_layers = this.$store.state.fileLayerList;
      let drDefinePacket_data = [],
        tf_data = [];
      for (let i = 0; i < current_file_layers.length; i++) {
        let layer_obj = {
          PacketName: `${current_file_layers[i].layerName}*`,
          Stipple: current_file_layers[i].shape,
          LineStyle: current_file_layers[i].border,
          FillColor: current_file_layers[i].color,
          OutlineColor: current_file_layers[i].borderColor,
        };
        let tf_obj = {
          Name: current_file_layers[i].layerName,
          Layer: current_file_layers[i].layerNumber,
          Packet: `${current_file_layers[i].layerName}*`,
          Lock: current_file_layers[i].lock,
          Visible: !current_file_layers[i].hide,
          Export: current_file_layers[i].isExport,
        };
        tf_data.push(tf_obj);
        drDefinePacket_data.push(layer_obj);
      }
      let save_obj = {
        drDefineColor: this.fillColorDatas,
        drDefineStyle: this.fillShapeDatas.map((item) => {
          return item.name;
        }),
        drDefineLineStyle: this.bordershapeDatas.map((item) => {
          return item.border;
        }),
        drDefineOutlineColor: this.borderColorDatas,
        drDefinePacket: drDefinePacket_data,
      };
      let save_tf_json = {
        layerDefinitions: tf_data,
      };
      let tf_json_blob = saveJSON(save_tf_json, "tf");
      let def_json_blob = saveJSON(save_obj, "drf");
      downloadBlob(def_json_blob, `drf.json`);
      downloadBlob(tf_json_blob, `tf.json`);
    },

    ////图层操作中的权限验证
    //获取当前用户在当前...中的权限
    async getTeamRule(fileGns) {
      let permissionRes = await getPermission(fileGns);
      if (permissionRes.code === 200000) {
        let teamRule = permissionRes.data.permission;
        return teamRule;
      }
    },

    //获取指定单元是否被锁定
    async getCellLock(cellId) {
      let lock = "";
      let lockCellRes = await selectLockCell(cellId);

      if (lockCellRes.code === 200000) {
        if (lockCellRes.message == "not lock") {
          lock = false;
        } else if (lockCellRes.message == "locked") {
          //被锁定时判断是否是当前已打开过的画板
          if (this.$store.state.openedTab.length) {
            let isOpened = false;
            for (const i in this.$store.state.openedTab) {
              let gnsCellId = this.$store.state.openedTab[i].route
                .split("=")
              [
                this.$store.state.openedTab[i].route.split("=").length - 1
              ].split("/")[1];
              if (gnsCellId === cellId) {
                isOpened = true;
                break;
              }
            }
            if (isOpened) {
              lock = false;
            } else {
              lock = true;
            }
          } else {
            lock = true;
          }
        }
      }
      return lock;
    },
  },
  watch: {
    fillColorSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -74;
        } else {
          t_h = +74;
        }
        this.tableHeight += t_h;
      },
    },
    borderColorSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -74;
        } else {
          t_h = +74;
        }
        this.tableHeight += t_h;
      },
    },
    fillShapeSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -84;
        } else {
          t_h = +84;
        }
        this.tableHeight += t_h;
      },
    },
    borderSquareSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -54;
        } else {
          t_h = +54;
        }
        this.tableHeight += t_h;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.spanBtn {
  cursor: pointer;
}
.right {
  min-width: 300px;
  font-size: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .cardHeader {
    height: 34px;
    line-height: 34px;
    background: #f4f4f4;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    span {
      height: 100%;
      display: inline-block;
      margin-left: 20px;
    }
    .el-icon-plus:before,
    .el-icon-minus:before {
      height: 100%;
      display: inline-block;
      line-height: 40px;
      margin-right: 20px;
    }
    .el-link {
      width: calc(100% - 80px);
      font-size: 12px;
      vertical-align: top;
      text-align: right;
      color: #0c7de6;
      justify-content: right;
      margin-right: 5px;
    }
    .iconfont {
      font-size: 16px !important;
      vertical-align: middle;
      color: #0c7de6;
      margin-right: 5px;
    }
  }

  // 图层库切换
  .layerSelector {
    /deep/.el-select {
      margin: 10px;
      width: 90%;
      height: 26px;
      .el-input {
        .el-input__inner {
          width: 100%;
          height: 26px;
          padding-left: 8px;
          background-color: #fff !important;
        }
        .el-input__icon {
          line-height: 26px;
        }
      }
    }
  }

  .cardHeader2 {
    height: 30px;
    line-height: 30px;
    background: #eee;
    border-bottom: 1px B1 #d1d1d1;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    strong {
      height: 100%;
      display: inline-block;
      margin-left: 20px;
    }
  }
  .arrow {
    float: right;
    margin-right: 10px;
    cursor: pointer;
  }

  .layerTable {
    margin-right: 3px;
    border: 1px solid #dddddd;
    border-bottom: none;
  }

  /deep/ .el-table {
    font-size: 12px;
    .el-input__inner {
      padding: 0 !important;
      border-radius: 0 !important;
      background: #fff !important;
      border: 1px B1 #0c7de6 !important;
      height: 31px;
      line-height: 31px;
    }
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-input__clear {
          justify-content: right;
        }
      }
    }
    .el-input--mini .el-input__icon {
      line-height: 31px !important;
      display: flex;
      align-items: center;
    }
  }
  // /deep/.el-table__body-wrapper::-webkit-scrollbar{
  // background-color: #dddddd;
  // }
  ::-webkit-scrollbar-thumb {
    //滚动条颜色
    background-color: #dddddd;
  }
  ::-webkit-scrollbar {
    //滚动条宽度
    width: 3px;
  }

  /deep/ .el-table__cell {
    padding: 0 !important;
  }
  /deep/ .cell {
    div {
      width: 100%;
    }
    padding: 0 !important;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: center;
  }
  .layerDatasTable {
    /deep/.has-gutter {
      tr th {
        text-align: center;
      }
      tr th:nth-child(2) {
        text-align: left;
      }
    }
    /deep/.el-table__row {
      height: 31px;
      td:nth-child(1) {
        .cell > img {
          margin: 0 10px;
        }
      }
      .cell {
        line-height: 31px;
        justify-content: center;
      }
      td:last-child {
        .cell > div {
          max-width: 55px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    /deep/.is-scrolling-none,
    /deep/.is-scrolling-right,
    /deep/.is-scrolling-left {
      height: 352px !important;
      // overflow: auto;
    }
  }

  .layer-btn {
    display: flex;
    justify-content: right;
    flex-direction: row;
    .layer-btn__btn {
      font-size: 15px;
      margin: 5px 6px;
      cursor: pointer;
      background: #fff;
      border: none;
    }
  }
  .colorControl {
    width: 276px;
    height: 74px;
    display: flex;
    flex-direction: column;
  }
  .fillColor__body,
  .borderColor__body {
    display: flex;
    height: 54px;
    flex-wrap: wrap;
    width: 276px;
    padding-left: 4px;
  }
  .fillShape__body {
    display: flex;
    height: 84px;
    flex-wrap: wrap;
    width: 276px;
    button {
      height: 20px;
      line-height: 20px;
      background-color: #d3d3d3;
      border: none;
      width: 276px;
      color: #666666;
      cursor: pointer;
    }
  }
  .borderSquare__body {
    display: flex;
    flex-wrap: wrap;
    width: 276px;
  }
  .fillColor__btn {
    width: 100%;
    text-align: center;
    background: #fff;
    border: 1px B1 #d3d3d3;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    height: 18px;
    cursor: pointer;
  }
  /deep/.el-color-picker {
    width: 100%;
    height: 18px;
  }
  /deep/.el-color-picker__trigger {
    height: 100%;
    width: 100%;
    border: none;
    padding: 0;
    .el-icon-arrow-down {
      color: #ccc !important;
    }
  }
  /deep/.el-color-picker__color {
    border: none;
  }
  /deep/.el-color-picker__color-inner {
    height: 100%;
    width: 100%;
  }
  .fillColor__item,
  .borderColor__item {
    width: 16px;
    height: 16px;
    margin: 1px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }
  .fillShape__item:hover,
  .borderSquare__item:hover,
  .fillColor__item:hover,
  .borderColor__item:hover {
    transition: transform 0.1s;
    transform: scale(1.1);
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
  }
  .fillShape__item {
    width: 18px;
    height: 18px;
    margin: 7px 8px;
  }
  .borderSquare__item {
    width: 35px;
    height: 18px;
    margin: 7px 7px;
  }
  /deep/ .el-dialog {
    top: 30%;
    .el-dialog__body {
      padding: 15px !important;
    }
  }
  .merge__title {
    display: inline-block;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-right: none;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }

  // .selectClass {
  //   width: 68%;
  //   display: inline-block;
  //   ::v-deep {
  //     .el-input {
  //       height: 30px !important;
  //     }
  //     .el-input__prefix,
  //     .el-input__suffix {
  //       height: 30px;
  //     }
  //     .el-input__inner {
  //       height: 30px;
  //       line-height: 30px;
  //       border-radius: 0;
  //     }
  //     .el-input__suffix {
  //       top: 5px;
  //     }
  //     .el-input__icon {
  //       line-height: inherit;
  //     }

  //     .el-input__suffix-inner {
  //       display: inline-block;
  //     }
  //   }
  // }

  .el-button {
    border-radius: 4px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    padding: 8px 26px;
  }
  .el-button--cancel {
    background: #b0b0b0;
  }
  .el-button--sure {
    background: #4385f2;
  }
  .el-button--delete {
    border: 1px B1 #d44051;
    color: #d44051;
  }

  //删除图层页面
  .delWindow {
    /deep/.el-dialog {
      width: 337px;
      min-height: 249px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
    }
    /deep/.el-dialog__body {
      padding: 0px !important;
      font-weight: 400;
      min-height: 147px;
      max-height: 405px;
      overflow-y: scroll;
      .el-input__inner {
        width: 220px;
      }
      .el-input__suffix {
        right: 10px;
      }
    }
    /deep/.el-table__row {
      .cell {
        height: 30px;
        margin-left: 10px;
      }
      td:first-child {
        .cell > div {
          max-width: 55px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    /deep/.el-dialog__footer {
      padding: 20px 10px 10px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: right;
    }
    .deactivate {
      background-color: #aac6f5 !important;
      border-color: #aac6f5 !important;
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
  }

  //删除弹窗
  .deleteDialog {
    /deep/.el-dialog {
      width: 575px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
    }

    /deep/.el-dialog__body {
      padding: 20px 20px 90px 20px !important;
      font-weight: 400;
    }
    .info {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
    }
    .deleteName {
      padding-left: 32px;
      color: #0c7de6;
    }
    /deep/.el-dialog__footer {
      padding: 10px 20px 29px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .deactivate {
      background-color: #aac6f5 !important;
      border-color: #aac6f5 !important;
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
  }

  //合并图层弹窗
  .mergeLayer {
    /deep/.el-dialog {
      width: 350px;
      max-height: 536px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
      overflow: hidden;
    }
    /deep/.el-dialog__body {
      padding: 0px !important;
      font-weight: 400;
      .mergeToBox {
        width: 340px;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 8px;
      }
      .merge__title {
        height: 28px;
        line-height: 28px;
        padding: 0 5px;
      }
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        width: 220px;
        border-radius: 0%;
        padding: 0 10px;
      }
      .el-input__suffix {
        right: 10px;
        .el-input__icon {
          line-height: 30px;
        }
      }
    }
    /deep/.el-table__row {
      .cell {
        height: 30px;
        margin-left: 10px;
        align-items: center;
        justify-content: center;
      }
      td:first-child {
        .cell > div {
          max-width: 55px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    /deep/.el-dialog__footer {
      padding: 10px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  //填充形状弹窗
  .fillShapeWindow {
    /deep/.el-dialog {
      width: 337px;
      max-height: 505px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
      overflow: hidden;
    }

    /deep/.el-dialog__body {
      padding: 0px !important;
      font-weight: 400;
      max-height: 390px;
      overflow: scroll;
    }
    .info {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
    }
    /deep/.el-table__row {
      .cell {
        height: 40px;
        margin-left: 10px;
      }
    }

    /deep/.el-dialog__footer {
      padding: 0px 10px 10px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: right;
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
  }
  /deep/.el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #dce6f8;
  }
  /deep/.el-table__body tr.current-row > td.el-table__cell {
    background-color: #dce6f8;
  }
}
</style>