<template>
  <div class="netListWindow">
    <el-dialog :modal="false" :title="$t('messages.outputNetlist')" :visible.sync="netListDialogVisible" :close-on-click-modal="false">
      <div class="netlistInfo">
        <div id="netText"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" plain @click="netlistDownloadButton" size="mini">{{$t("messages.download")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import { downloadFileData } from './fileUtils';
import {
  getCellFromTable,
} from "./function/fileListPublicFn.js";
export default {
  data() {
    return {
      netListDialogVisible: false,
      netText: "",
      netTextStr: "",
    }
  },
  mounted() {
    bus.$on("openNetlist", data => {
      // Schema.net_table(project_name, file_name, schema_name)
      this.netListDialogVisible = true
      let proName = this.$store.state.proInfo.name
      let fileName = this.$store.state.fileInfo.name
      let cellId = this.$store.state.cellInfo.cellId
      let schema = getCellFromTable(cellId)
      this.netTextStr = schema.net_table(proName, fileName, schema.name)
      let netTextArr = this.netTextStr.split('\n')
      setTimeout(() => {
        let fatherDiv = document.getElementById("netText")
        if (fatherDiv.firstChild !== null) {
          let element
          while ((element = fatherDiv.firstChild)) {
            element.remove();
          }
        }
        netTextArr.forEach(item => {
          let textDiv = document.createElement('div')
          textDiv.innerHTML = item
          fatherDiv.appendChild(textDiv)
        });
      }, 0)
    })
  },
  methods: {
    netlistDownloadButton() {
      let encoder = new TextEncoder('utf8')
      let data_u8 = encoder.encode(this.netTextStr)

      downloadFileData(data_u8, "netlist", "txt")
    },
  },
  beforeDestroy() {
    bus.$off("openNetlist")
  }
}
</script>

<style lang="less" scoped>
.netListWindow {
  /deep/.el-dialog {
    width: 636px;
    min-height: 379px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    height: 305px;
    padding: 10px 20px 10px 20px;
    font-weight: 400;
    box-sizing: border-box;
    overflow-y: scroll;
    #netText {
      white-space: pre;
      font-family: "宋体" !important; //NOSONAR
    }
  }
  /deep/.el-dialog__footer {
    text-align: left;
    border-top: 1px solid #dddddd;
    padding: 10px 20px 10px;
  }
}
</style>