<template>
  <div class="dproperties" onselectstart="javascript:return false;">
    <div class="cardHeader" v-drag>
      <!-- 头部标题 -->
      <strong>{{$t('messages.customGraphicsProperty')}}</strong>
      <!-- +-号 -->
      <span v-if="cellBarSeen" style="float: right" class="el-icon-minus spanBtn" @click="setCellBar"></span>
      <span v-else style="float: right" class="el-icon-plus spanBtn" @click="setCellBar"></span>
    </div>
    <!-- 折叠面板主体 -->
    <div class="graphMain" v-if="objData" v-show="cellBarSeen">
      <div class="formItem" v-if="objData.attrs.draw_type == 'dogleg' || objData.attrs.draw_type == 'polygon'">
        <div class="formHeader">
          {{$t('messages.name')}}&nbsp; <el-input size="mini" v-model="objData.attrs.name" style="width: 80%;">
          </el-input>
        </div>
        <div class="formHeader">
          {{$t('messages.layerNumber')}}&nbsp;
          <el-select v-model="layerSelect" collapse-tags @change="layerChange" style="width: 54%" size="mini"
            placeholder="请选择">
            <el-option v-for="item in layerOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="colorShow" :style="{backgroundColor: layerInfo[layerSelect].color}"></div>
        </div>
        <div class="formBody">
          <el-form :model="objData">
            <div style="font-size: 12px;">{{$t('messages.coordinateList')}}</div>
            <el-table :data="objData.attrs.draw_points" :row-style="{height:0+'px'}" :cell-style="{padding:0+'px'}"
              :header-cell-style="{background:'#F5F7FA',color:'#606266', height:'10px', padding:'0px'}" height="250">
              <!-- <el-table-column type="index" label="序号" width="50"></el-table-column> -->
              <el-table-column prop="x" width="105" :label="$t('messages.xCoordinate')">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.x"><i slot="suffix"
                      style="font-style: normal; ">{{baseUnit}}</i></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="y" :label="$t('messages.yCoordinate')">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.y"><i slot="suffix"
                      style="font-style: normal; ">{{baseUnit}}</i></el-input>
                </template>
              </el-table-column>
              <el-table-column width="52">
                <template slot-scope="scope">
                  <span class="el-icon-plus pointOp" style="color: green;"
                    @click.stop="addPointChild(scope.row, scope.$index)"></span>
                  <span class="el-icon-delete pointOp" style="color: red;"
                    @click.stop="delPointChild(scope.row, scope.$index)"></span>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
          <br>
          <div style="text-align:center;">
            <el-button size="mini" icon="el-icon-check" type="primary" @click="valChange(objData)">
              {{$t('messages.confirmChanges')}}</el-button>
          </div>
          <!-- <el-button size="mini" icon="el-icon-delete" type="danger" @click="delGraph(objData)">删除</el-button> -->
        </div>
      </div>
      <div class="formItem" v-if="objData.attrs.draw_type == 'line'">
        <div class="formHeader">
          {{$t('messages.name')}}&nbsp; <el-input size="mini" v-model="objData.attrs.name" style="width: 80%;">
          </el-input>
        </div>
        <div class="formHeader">
          {{$t('messages.layerNumber')}}&nbsp;
          <el-select v-model="layerSelect" collapse-tags @change="layerChange" style="width: 54%" size="mini"
            placeholder="请选择">
            <el-option v-for="item in layerOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="colorShow" :style="{backgroundColor: layerInfo[layerSelect].color}"></div>
        </div>
        <div class="formBody">
          <el-form :model="objData">
            <div style="font-size: 12px;">{{$t('messages.coordinateList')}}</div>
            <el-table :data="objData.attrs.draw_points" :row-style="{height:0+'px'}" :cell-style="{padding:0+'px'}"
              :header-cell-style="{background:'#F5F7FA',color:'#606266', height:'10px', padding:'0px'}" height="83">
              <!-- <el-table-column type="index" label="序号" width="50"></el-table-column> -->
              <el-table-column prop="x" :label="$t('messages.xCoordinate')">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.x"><i slot="suffix"
                      style="font-style: normal; ">{{baseUnit}}</i></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="y" :label="$t('messages.yCoordinate')">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.y"><i slot="suffix"
                      style="font-style: normal; ">{{baseUnit}}</i></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
          <br>
          <div style="text-align:center;">
            <el-button size="mini" icon="el-icon-check" type="primary" @click="valChange(objData)">
              {{$t('messages.confirmChanges')}}</el-button>
          </div>
          <!-- <el-button size="mini" icon="el-icon-delete" type="danger" @click="delGraph(objData)">删除</el-button> -->
        </div>
      </div>
      <div class="formItem" v-if="objData.attrs.draw_type == 'circle'">
        <div class="formHeader">
          {{$t('messages.name')}}&nbsp; <el-input size="mini" v-model="objData.attrs.name" style="width: 80%;">
          </el-input>
        </div>
        <div class="formHeader">
          {{$t('messages.layerNumber')}}&nbsp;
          <el-select v-model="layerSelect" collapse-tags @change="layerChange" style="width: 54%" size="mini"
            placeholder="请选择">
            <el-option v-for="item in layerOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="colorShow" :style="{backgroundColor: layerInfo[layerSelect].color}"></div>
        </div>
        <div class="formBody">
          <el-form :model="objData">
            <div style="font-size: 12px;">{{$t('messages.centerCoordinate')}}</div>
            <div style="display: flex;height: 40px;">
              <el-form-item label="x">
                <el-input class="formInput" size="mini" v-model="objData.attrs.draw_points.x"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
              <el-form-item label="y">
                <el-input class="formInput" size="mini" v-model="objData.attrs.draw_points.y"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
            </div>
            <div style="font-size: 12px;">{{$t('messages.radiusInformation')}}</div>
            <div>
              <el-form-item>
                <span slot="label" style="font-size: 12px;">radiusX</span>
                <el-input class="formInput2" size="mini" v-model="objData.attrs.draw_points.radiusX"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
              <el-form-item>
                <span slot="label" style="font-size: 12px;">radiusY</span>
                <el-input class="formInput2" size="mini" v-model="objData.attrs.draw_points.radiusY"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
            </div>
          </el-form>
          <br>
          <div style="text-align:center;">
            <el-button size="mini" icon="el-icon-check" type="primary" @click="valChange(objData)">
              {{$t('messages.confirmChanges')}}</el-button>
          </div>
          <!-- <el-button size="mini" icon="el-icon-delete" type="danger" @click="delGraph(objData)">删除</el-button> -->
        </div>
      </div>
      <div class="formItem" v-if="objData.attrs.draw_type == 'rectangle'">
        <div class="formHeader">
          {{$t('messages.name')}}&nbsp; <el-input size="mini" v-model="objData.attrs.name" style="width: 80%;">
          </el-input>
        </div>
        <div class="formHeader">
          {{$t('messages.layerNumber')}}&nbsp;
          <el-select v-model="layerSelect" collapse-tags @change="layerChange" style="width: 54%" size="mini"
            placeholder="请选择">
            <el-option v-for="item in layerOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="colorShow" :style="{backgroundColor: layerInfo[layerSelect].color}"></div>
        </div>
        <div class="formBody">
          <el-form :model="objData">
            <div style="font-size: 12px;">{{$t('messages.lowerLeftCornerCoordinates')}}</div>
            <div style="display: flex;height: 40px;">
              <el-form-item label="x">
                <el-input class="formInput" size="mini" v-model="objData.attrs.draw_points.x"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
              <el-form-item label="y">
                <el-input class="formInput" size="mini" v-model="objData.attrs.draw_points.y"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
            </div>
            <div style="font-size: 12px;">{{$t('messages.perimeter')}}</div>
            <div>
              <el-form-item>
                <span slot="label" style="font-size: 12px;">{{$t('messages.width')}}</span>
                <el-input class="formInput2" size="mini" v-model="objData.attrs.draw_points.width"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
              <el-form-item>
                <span slot="label" style="font-size: 12px;">{{$t('messages.height')}}</span>
                <el-input class="formInput2" size="mini" v-model="objData.attrs.draw_points.height"><i slot="suffix"
                    style="font-style: normal; ">{{baseUnit}}</i></el-input>
              </el-form-item>
            </div>
          </el-form>
          <br>
          <div style="text-align:center;">
            <el-button size="mini" icon="el-icon-check" type="primary" @click="valChange(objData)">
              {{$t('messages.confirmChanges')}}</el-button>
          </div>
          <!-- <el-button size="mini" icon="el-icon-delete" type="danger" @click="delGraph(objData)">删除</el-button> -->
        </div>
      </div>
    </div>
    <div class="graphMain" v-else>
      <div class="noneDevice">{{$t('messages.noneGraph')}}</div>
    </div>
  </div>
</template>
<script>
import bus from "../common/bus";
import { deepClone, uniqueObject } from "@/utils/utils";
import { unitTransform } from "@/utils/pixi/qeda-pixi-data";//NOSONAR
import AttrsContainer from "./AttrsContainer.vue";
export default {
  components: {
    AttrsContainer,
  },
  data() {
    return {
      cellBarSeen: true,
      objData: null,
      layerInfo: [],
      layerSelect: null,
      layerOptions: [],
      baseUnit: "um",
      unit_scale: 0.001,
    };
  },
  mounted() {
    bus.$on("setAlldata", (data) => {
      if (!data) {
        this.objData = { attrs: { draw_type: null } };
      } else {
        this.objData = deepClone(data);
        if (!this.objData.attrs.obj_layer_list) {
          this.objData.attrs.obj_layer_list = [0];
        }
        this.layerSelect = this.objData.attrs.obj_layer_list[0];
        this.oldObjData = deepClone(data);
      }
    });
    this.$bus.$on("clearDiyGraph", (data) => {
      this.objData = { attrs: { draw_type: null } };
    });
    this.$bus.$on("getLayerData", (data) => {
      this.getLayerInfo(data);
    });
    bus.$on("setbaseUnit", (data) => {
      this.baseUnit = data.unit;
      this.unit_scale = data.unit_scale;
    });
    this.$bus.$on("createNewLayer", (data) => {
      this.getLayerInfo(data);
    });
  },
  methods: {
    setCellBar() {
      this.cellBarSeen = !this.cellBarSeen;
    },
    delGraph(data) {
      bus.$emit("deleteGraph", data);
    },
    valChange(value) {
      let linePoints = value.attrs.draw_points;
      for (let i = 0; i < linePoints.length; i++) {
        linePoints[i].x = Number(linePoints[i].x);
        linePoints[i].y = Number(linePoints[i].y);
      }
      if (
        uniqueObject(linePoints).length < 2 &&
        value.attrs.draw_type == "line"
      ) {
        this.$message.error("去除重复点后坐标个数小于2");
        return false;
      }
      bus.$emit("changeGrapgData", value, this.oldObjData);
      this.oldObjData = deepClone(value);
    },
    //获取图层信息
    getLayerInfo(data) {
      this.layerInfo = data;
      this.layerOptions = [];
      for (let i = 0; i < this.layerInfo.length; i++) {
        let options = {
          value: this.layerInfo[i].layerNumber,
          label: this.layerInfo[i].layerNumber,
        };
        this.layerOptions.push(options);
      }
    },
    layerChange(val) {
      this.objData.attrs.obj_layer_list = [val];
    },
    addPointChild(val, index) {
      this.objData.attrs.draw_points.splice(index, 0, deepClone(val));
    },
    delPointChild(val, index) {
      if (this.objData.attrs.draw_points.length > 3) {
        this.objData.attrs.draw_points.splice(index, 1);
      } else {
        this.$message.error("最小坐标点个数为3");
      }
    },
  },
  directives: {
    /*自定义拖拽*/
    drag: {
      inserted: function (el, binding, vnode) {//NOSONAR
        let odiv = el.parentNode; // 获取元素的父级 也就是这个移动的窗口
        el.onmousedown = function (eve) {
          eve = eve || window.event;//NOSONAR
          let mx = eve.pageX; // 鼠标点击时的x坐标位置
          let my = eve.pageY; // 鼠标点击时的y坐标位置
          let dleft = odiv.offsetLeft; // 窗口初始的位置
          let dtop = odiv.offsetTop;
          let clientWidth = document.documentElement.clientWidth; // 页面的宽
          let oWidth = odiv.clientWidth; // 窗口的宽度
          let maxX = clientWidth - oWidth; // x轴能移动的最大距离
          let clientHeight = document.documentElement.clientHeight; // 页面的高
          let oHeight = odiv.clientHeight; // 窗口的高度
          let maxY = clientHeight - oHeight; // y轴能移动的最大距离
          document.onmousemove = function (e) {
            e.preventDefault();
            let x = e.pageX; // 移动时鼠标X坐标
            let y = e.pageY; // 移动时鼠标Y坐标
            let left = x - mx + dleft; // 移动后对话框新的left值
            let Top = y - my + dtop; // 移动后对话框新的Top值
            if (left < 0) {
              left = 0;
            }
            if (left > maxX) {
              left = maxX;
            }
            if (Top < 0) {
              Top = 0;
            }
            if (Top > maxY) {
              Top = maxY;
            }
            odiv.style.left = left + "px";
            odiv.style.top = Top + "px";
            odiv.style.marginLeft = 0;
            odiv.style.marginTop = 0;
          };
          document.onmouseup = function () {
            document.onmouseup = "";
            document.onmousemove = "";
          };
        };
      },
    },
  },
};
</script>
<style lang="less" scoped>
.dproperties {
  position: fixed;
  left: 40px;
  top: 56%;
  z-index: 999;
  width: 280px;
  font-size: 12px;
  border-radius: 4px;
  max-height: 650px;
  overflow: auto;
  background-color: #eee;
  /deep/ .el-collapse {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
  }
  /deep/ .el-collapse-item__wrap {
    margin-top: 0 !important;
  }
}
.cardHeader {
  height: 40px;
  line-height: 40px;
  background: #eee;
  border-bottom: 1px solid #d1d1d1;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  cursor: move;
  strong {
    height: 100%;
    display: inline-block;
    margin-left: 20px;
  }
  .el-icon-plus:before,
  .el-icon-minus:before {
    height: 100%;
    display: inline-block;
    line-height: 40px;
    margin-right: 20px;
  }
}
.cellBar {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.formHeader {
  margin: 0;
  padding: 5px 0 5px 5px;
  color: #72767b;
  font-size: 12px;
  background: #eee;
}
.formItem {
  padding: 5px;
}
.formBody {
  margin: 0;
  padding: 5px 0 5px 5px;
  color: #72767b;
  font-size: 12px;
  /deep/ .el-form-item {
    margin-bottom: 0px !important;
  }
}
.formInput {
  width: 80%;
}
.formInput2 {
  width: 60%;
}
.col {
  padding: 5px !important;
}
.graphMain {
  max-height: 600px;
  overflow: auto;
  /deep/.el-table {
    font-size: 13px;
  }
}
.noneDevice {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.colorShow {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.pointOp {
  padding: 1px;
  cursor: pointer;
}
</style>