// import * as PIXI from "pixi.js";
import PIXI from '../../utils/pixi/pixi'
import { colorTransform } from '../../utils/utils'
import { FakeUtil } from './fakeKonva'
import { Canvg, AnimateTransformElement, presets } from 'canvg'
import bus from './bus'
import { generateUUID } from '@/components/common/tools'

const ATTR_LIST = {
  File: ['name', 'precision', 'unit', 'cells'],
  Stage: ['cell_name', 'layers', 'layer_names', 'layer_data'],
  Group: ['layer_numer', 'group_obj_id', 'is_group_obj', 'obj_type', 'draggable', 'custom_attrs'],
  Layer: ['layer_number', 'layer_name', 'layer_lock', 'layer_hide', 'layer_color'],
  Shape: ['custom_attrs', 'obj_O', 'obj_area', 'obj_canvas', 'obj_type'],
  Image: ['custom_attrs', 'obj_O', 'obj_area', 'obj_canvas', 'obj_type'],
}
const PIXI_BASIC_GEOMETRIC_LIST = ['POLY', 'RECT', 'CIRC', 'ELIP', 'RREC']
const RENDER_TYPE_CANVAS = 1
const RENDER_TYPE_CANVAS_TO_SVG = 2
const RENDER_TYPE_SVG_CODE = 3
const RENDER_TYPE_SVG_JSON = 4

function renderResonator1(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  const { L1, H1, L2, H2, L3, r, W1, W2, n, L4, H3, L5, H4, L6, layer, datatype } = custom_attrs
  let isDirectionLeft = (n - 3) % 4 == 0
  let x_pad = W1 / 2.0
  let r2 = r - W2 - W1

  ctx.lineWidth = W1

  var point_cap_a = isDirectionLeft ? [W1 / 2.0 + r + L2 + r2 + W1 * 0.5 + W2 * 0.5, W1 * 0.5 + H4] : [W1 / 2.0 + L3 - L2 + W1 * 0.5 + W2 * 0.5, W1 * 0.5 + H4]
  ctx.moveTo(...point_cap_a)
  var point_cap_b = [point_cap_a[0] - L6 / 2.0, point_cap_a[1]]
  ctx.lineTo(...point_cap_b)
  var point_cap_c = [point_cap_b[0], point_cap_b[1] - H4]
  ctx.lineTo(...point_cap_c)
  var point_cap_d = [point_cap_c[0] - L5, point_cap_c[1]]
  ctx.lineTo(...point_cap_d)
  var point_cap_e = [point_cap_d[0], point_cap_d[1] + H3]
  ctx.lineTo(...point_cap_e)

  var point_a = isDirectionLeft ? [W1 / 2.0 + r + L2 + r2, W1 * 0.5 + H3] : [W1 / 2.0 + L3 - L2, W1 * 0.5 + H3]
  ctx.lineTo(...point_a)
  let point_c = [point_a[0], point_a[1] + H2 + (isDirectionLeft ? r2 : r)]
  let point_e = isDirectionLeft ? [point_c[0] - r2 - L2, point_c[1]] : [W1 * 0.5 + r + L3, point_c[1]]
  ctx.arcTo(...point_c, ...point_e, isDirectionLeft ? r2 : r)
  ctx.lineTo(...point_e)

  var last_path_a_point = point_e
  const LEVEL_HEIGHT = 2 * r + 2 * r2
  var PATH_A_BASE_HEIGHT = point_c[1]
  for (var cornerIndex = 0; cornerIndex < (n - 3) / 2; cornerIndex += 1) {
    var level = Math.floor(cornerIndex / 2)
    if (isDirectionLeft) {
      if (cornerIndex % 2 == 0) {
        var point_f = [W1 * 0.5, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT]
        var point_g = [point_f[0], point_f[1] + r]
        ctx.arcTo(...point_f, ...point_g, r)
        var point_h = [point_g[0], point_g[1] + r]
        var point_i = [point_h[0] + r, point_h[1]]
        ctx.arcTo(...point_h, ...point_i, r)
        last_path_a_point = [x_pad + r + L2, point_i[1]]
        ctx.lineTo(...last_path_a_point)
      } else {
        var point_l = [x_pad + r + L3 + r2, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT + 2 * r]
        var point_m = [point_l[0], point_l[1] + r2]
        ctx.arcTo(...point_l, ...point_m, r2)
        var point_n = [point_m[0], point_m[1] + r2]
        var point_o = [point_n[0] - r2, point_n[1]]
        ctx.arcTo(...point_n, ...point_o, r2)
        last_path_a_point = [x_pad + r + L2, point_o[1]]
        ctx.lineTo(...last_path_a_point)
      }
    } else {
      if (cornerIndex % 2 == 0) {
        var point_f = [W1 * 0.5 + r + L3 + r2, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT]
        var point_g = [point_f[0], point_f[1] + r2]
        ctx.arcTo(...point_f, ...point_g, r2)
        var point_h = [point_g[0], point_g[1] + r2]
        var point_i = [point_h[0] - r2, point_h[1]]
        ctx.arcTo(...point_h, ...point_i, r2)
        last_path_a_point = [x_pad + L3 - L2, point_i[1]]
        ctx.lineTo(...last_path_a_point)
      } else {
        var point_l = [x_pad, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT + 2 * r2]
        var point_m = [point_l[0], point_l[1] + r]
        ctx.arcTo(...point_l, ...point_m, r)
        var point_n = [point_m[0], point_m[1] + r]
        var point_o = [point_n[0] + r, point_n[1]]
        ctx.arcTo(...point_n, ...point_o, r)
        last_path_a_point = [x_pad + r + L2, point_o[1]]
        ctx.lineTo(...last_path_a_point)
      }
    }
  }
  let point_r = [last_path_a_point[0] - L2 - r, last_path_a_point[1]]
  let point_s = [point_r[0], point_r[1] + r]
  ctx.arcTo(...point_r, ...point_s, r)
  let point_t = [point_s[0], point_s[1] + H1]
  ctx.lineTo(...point_t)
  let point_u = [point_t[0], point_t[1] + r]
  let point_v = [point_u[0] + r, point_u[1]]
  ctx.arcTo(...point_u, ...point_v, r)
  let point_w = [point_v[0] + L1, point_v[1]]
  ctx.lineTo(...point_w)

  // path b
  var point_cap_a2 = isDirectionLeft ? [W1 / 2.0 + r + L2 + r2 + W1 * 0.5 + W2 * 0.5, W1 * 0.5 + H4] : [W1 / 2.0 + L3 - L2 + W1 * 0.5 + W2 * 0.5, W1 * 0.5 + H4]
  ctx.moveTo(...point_cap_a2)
  var point_cap_b2 = [point_cap_a2[0] + L6 / 2.0, point_cap_a2[1]]
  ctx.lineTo(...point_cap_b2)
  var point_cap_c2 = [point_cap_b2[0], point_cap_b2[1] - H4]
  ctx.lineTo(...point_cap_c2)
  var point_cap_d2 = [point_cap_c2[0] + L5, point_cap_c2[1]]
  ctx.lineTo(...point_cap_d2)
  var point_cap_e2 = [point_cap_d2[0], point_cap_d2[1] + H3]
  ctx.lineTo(...point_cap_e2)

  var point_a2 = isDirectionLeft ? [point_a[0] + W1 + W2, point_a[1]] : [x_pad + r + L3 - L2 - r2, W1 * 0.5 + H3]
  ctx.lineTo(...point_a2)
  let point_b2 = [point_a2[0], point_a2[1] + H2]
  ctx.lineTo(...point_b2)
  let point_c2 = [point_a2[0], point_a2[1] + H2 + (isDirectionLeft ? r : r2)]
  let point_d2 = [isDirectionLeft ? point_a2[0] - r : point_a2[0] + r2, point_c2[1]]
  ctx.arcTo(...point_c2, ...point_d2, isDirectionLeft ? r : r2)
  var last_path_b_point = point_d2
  for (var level = 0; level < (n - 3) / 2; level += 1) {
    if (isDirectionLeft) {
      if (level % 2 == 0) {
        var point_e2 = [last_path_b_point[0] - L2, last_path_b_point[1]]
        ctx.lineTo(...point_e2)
        var point_f2 = [point_e2[0] - r2, point_e2[1]]
        var point_g2 = [point_f2[0], point_f2[1] + r2]
        ctx.arcTo(...point_f2, ...point_g2, r2)
        var point_h2 = [point_g2[0], point_g2[1] + r2]
        var point_i2 = [point_h2[0] + r2, point_h2[1]]
        ctx.arcTo(...point_h2, ...point_i2, r2)
        last_path_b_point = [x_pad + r + L2, point_i2[1]]
        ctx.lineTo(...last_path_b_point)
      } else {
        var point_k2 = [last_path_b_point[0] + L3 - L2, last_path_b_point[1]]
        ctx.lineTo(...point_k2)
        var point_l2 = [point_k2[0] + r, point_k2[1]]
        var point_m2 = [point_l2[0], point_l2[1] + r]
        ctx.arcTo(...point_l2, ...point_m2, r)
        var point_n2 = [point_m2[0], point_m2[1] + r]
        var point_o2 = [point_n2[0] - r, point_n2[1]]
        ctx.arcTo(...point_n2, ...point_o2, r)
        last_path_b_point = [x_pad + r + L2, point_o2[1]]
        ctx.lineTo(...last_path_b_point)
      }
    } else {
      if (level % 2 == 0) {
        var point_e2 = [last_path_b_point[0] + L2, last_path_b_point[1]]
        ctx.lineTo(...point_e2)
        var point_f2 = [point_e2[0] + r, point_e2[1]]
        var point_g2 = [point_f2[0], point_f2[1] + r]
        ctx.arcTo(...point_f2, ...point_g2, r)
        var point_h2 = [point_g2[0], point_g2[1] + r]
        var point_i2 = [point_h2[0] - r, point_h2[1]]
        ctx.arcTo(...point_h2, ...point_i2, r)
        last_path_b_point = [x_pad + L3 - L2 - r2, point_i2[1]]
        ctx.lineTo(...last_path_b_point)
      } else {
        var point_k2 = [x_pad + r, last_path_b_point[1]]
        ctx.lineTo(...point_k2)
        var point_l2 = [point_k2[0] - r2, point_k2[1]]
        var point_m2 = [point_l2[0], point_l2[1] + r2]
        ctx.arcTo(...point_l2, ...point_m2, r2)
        var point_n2 = [point_m2[0], point_m2[1] + r2]
        var point_o2 = [point_n2[0] + r2, point_n2[1]]
        ctx.arcTo(...point_n2, ...point_o2, r2)
        last_path_b_point = [x_pad + r + L3 - L2, point_o2[1]]
        ctx.lineTo(...last_path_b_point)
      }
    }
  }
  let point_q2 = [isDirectionLeft ? last_path_b_point[0] - L2 : x_pad + r, last_path_b_point[1]]
  ctx.lineTo(...point_q2)
  let point_r2 = [point_q2[0] - r2, point_q2[1]]
  let point_s2 = [point_r2[0], point_r2[1] + r2]
  ctx.arcTo(...point_r2, ...point_s2, r2)
  let point_t2 = [point_s2[0], point_s2[1] + H1]
  ctx.lineTo(...point_t2)
  let point_u2 = [point_t2[0], point_t2[1] + r2]
  let point_v2 = [point_u2[0] + r2, point_u2[1]]
  ctx.arcTo(...point_u2, ...point_v2, r2)
  let point_w2 = [point_v2[0] + L1, point_v2[1]]
  ctx.lineTo(...point_w2)

  ctx.stroke()
}

function renderCap1QubitResonator(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  var { L1, L2, L3, L4, L5, L6, W1, W2, layer, datatype } = custom_attrs
  L6 = L2 - L4 + (W1 + W2) * 0.5
  let x_pad = W1 / 2.0

  ctx.beginPath()
  ctx.lineWidth = W1

  var point_a = [W1 / 2.0 + L2, W1 * 0.5 + L3 + L1]
  ctx.moveTo(...point_a)

  let point_b = [point_a[0], W1 * 0.5 + L3]
  ctx.lineTo(...point_b)
  let point_c = [x_pad, W1 * 0.5 + L3]
  ctx.lineTo(...point_c)

  let point_d = [x_pad, W1 * 0.5]
  ctx.lineTo(...point_d)

  let point_e = [x_pad + L4, W1 * 0.5]
  ctx.lineTo(...point_e)

  let point_f = [x_pad + L4, W1 * 0.5 + L5]
  ctx.lineTo(...point_f)

  let point_g = [point_f[0] + 2 * L6, point_f[1]]
  ctx.lineTo(...point_g)

  let point_h = [point_g[0], W1 * 0.5]
  ctx.lineTo(...point_h)

  let point_i = [point_h[0] + L4, W1 * 0.5]
  ctx.lineTo(...point_i)

  let point_j = [point_i[0], W1 * 0.5 + L3]
  ctx.lineTo(...point_j)

  let point_k = [point_j[0] - L2, W1 * 0.5 + L3]
  ctx.lineTo(...point_k)

  let point_l = [point_k[0], W1 * 0.5 + L3 + L1]
  ctx.lineTo(...point_l)
  ctx.stroke()
}

function renderPad1(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  let color = getLayerColor(shapeLayerNumberList[0])
  ctx.strokeStyle = color
  ctx.fillStyle = color
  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  var { L1, L2, L3, L4, L5, w1, w2, layer, datatype } = custom_attrs
  ctx.beginPath()
  // ctx.lineWidth = w1;
  var point_a = [0, L5 * 0.5 - w2 * 0.5]
  ctx.moveTo(...point_a)

  let point_b = [point_a[0], point_a[1] - w1]
  ctx.lineTo(...point_b)
  let point_c = [L1, 0]
  ctx.lineTo(...point_c)

  let point_d = [L1 + L2 + L3, 0]
  ctx.lineTo(...point_d)

  let point_e = [point_d[0], L5]
  ctx.lineTo(...point_e)

  let point_f = [point_c[0], L5]
  ctx.lineTo(...point_f)

  let point_g = [0, L5 / 2.0 + w2 * 0.5 + w1]
  ctx.lineTo(...point_g)

  let point_h = [0, L5 / 2.0 + w2 * 0.5]
  ctx.lineTo(...point_h)

  let point_i = [point_c[0], L5 - (L5 - L4) / 2.0]
  ctx.lineTo(...point_i)

  let point_j = [L1 + L2, point_i[1]]
  ctx.lineTo(...point_j)

  let point_k = [L1 + L2, (L5 - L4) / 2.0]
  ctx.lineTo(...point_k)

  let point_l = [point_c[0], point_k[1]]
  ctx.lineTo(...point_l)
  ctx.closePath()
  ctx.fill()
}

function renderCPWGFormation1(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { L1, L2, R, W1, W2, layer, datatype } = custom_attrs

  ctx.beginPath()
  ctx.lineWidth = W1

  var point_a = [W1 / 2.0, W1 / 2.0 + R + L2]
  ctx.moveTo(...point_a)

  let point_b = [W1 / 2.0, W1 / 2.0]
  let point_c = [W1 / 2.0 + R + L1, W1 / 2.0]
  ctx.arcTo(...point_b, ...point_c, R)
  ctx.lineTo(...point_c)

  let point_d = [W1 / 2.0 + R + L1 + R, W1 / 2.0]
  let point_e = [W1 / 2.0 + R + L1 + R, W1 / 2.0 + R + L2]
  ctx.arcTo(...point_d, ...point_e, R)
  ctx.lineTo(...point_e)

  let R2 = R - W1 - W2
  var point_aa = [W1 + W2 + W1 / 2.0, W1 / 2.0 + R + L2]
  ctx.moveTo(...point_aa)

  let point_bb = [W1 + W2 + W1 / 2.0, W1 + W2 + W1 / 2.0]
  let point_cc = [W1 / 2.0 + R + L1, W1 + W2 + W1 / 2.0]
  ctx.arcTo(...point_bb, ...point_cc, R2)
  ctx.lineTo(...point_cc)

  let point_dd = [W1 / 2.0 + R + L1 + R2, W1 + W2 + W1 / 2.0]
  let point_ee = [W1 / 2.0 + R + L1 + R2, W1 / 2.0 + R + L2]
  ctx.arcTo(...point_dd, ...point_ee, R2)
  ctx.lineTo(...point_ee)

  ctx.stroke()
}

function renderXYControlLine(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { L1, L2, L3, layer, datatype } = custom_attrs

  ctx.beginPath()
  ctx.lineWidth = L2

  ctx.moveTo(L2 / 2.0, 0)
  ctx.lineTo(L2 / 2.0, L1 - L2 / 2.0)
  ctx.lineTo(L2 + L3 + L2 / 2.0, L1 - L2 / 2.0)
  ctx.lineTo(L2 + L3 + L2 / 2.0, 0)

  ctx.stroke()
}

function renderZControlLine(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])
  ctx.fillStyle = getLayerColor(shapeLayerNumberList[0])

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { d1, d2, d3, d4, L1, L2, L3, L4, layer, datatype } = custom_attrs

  ctx.beginPath()
  ctx.rect(d1 / 2.0 - d4 / 2.0 - d3, 0, d3, L1)

  ctx.rect(0, L1, d1, L2)
  ctx.rect(d1 / 2.0 + d4 / 2.0, 0, d3, L1 - L3 - L4)
  ctx.rect(d1 / 2.0 + d4 / 2.0, L1 - L3 - L4, d2, L4)

  ctx.fill()
}

function renderXZLineTail1(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])
  ctx.fillStyle = getLayerColor(shapeLayerNumberList[0])

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { L1, L2, L3, R, layer, datatype } = custom_attrs
  let max_r = R + L2 / 2.0 + L1 / 2.0
  let min_r = R - L2 / 2.0 - L1 / 2.0

  ctx.beginPath()
  ctx.lineWidth = L1
  // path_a
  ctx.moveTo(L1 / 2.0, R * 2 + L3 * 2)
  ctx.lineTo(L1 / 2.0, R * 2 + L3)
  ctx.arcTo(...[L1 / 2.0, R * 2 + L3 - max_r], ...[L1 / 2.0 + max_r, R * 2 + L3 - max_r], max_r)
  ctx.arcTo(...[L1 / 2.0 + max_r + min_r, R * 2 + L3 - max_r], ...[L1 / 2.0 + max_r + min_r, R * 2 + L3 - max_r - min_r], min_r)
  ctx.lineTo(L1 / 2.0 + max_r + min_r, 0)

  // path_b
  ctx.moveTo(L1 + L2 + L1 / 2.0, R * 2 + L3 * 2)
  ctx.lineTo(L1 + L2 + L1 / 2.0, R * 2 + L3)
  ctx.arcTo(...[L1 + L2 + L1 / 2.0, R * 2 + L3 - min_r], ...[L1 + L2 + L1 / 2.0 + min_r, R * 2 + L3 - min_r], min_r)
  ctx.arcTo(...[L1 + L2 + L1 / 2.0 + min_r + max_r, R * 2 + L3 - min_r], ...[L1 + L2 + L1 / 2.0 + min_r + max_r, R * 2 + L3 - min_r - max_r], max_r)
  ctx.lineTo(L1 + L2 + L1 / 2.0 + min_r + max_r, 0)

  ctx.stroke()
}

function renderXZLineTail2(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])
  ctx.fillStyle = getLayerColor(shapeLayerNumberList[0])

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { L1, L2, L3, L4, d, layer, datatype } = custom_attrs

  ctx.beginPath()
  var points = [
    [L3 + L4 / 2.0 - L2 / 2.0 - L1, d],
    [0, 0],
    [L3, 0],
    [L3 + L4 / 2.0 - L2 / 2.0, d],
  ]
  ctx.moveTo(...points[0])
  ctx.lineTo(...points[1])
  ctx.lineTo(...points[2])
  ctx.lineTo(...points[3])
  ctx.closePath()
  ctx.fill()

  ctx.beginPath()
  points = [
    [L3 + L4 / 2.0 + L2 / 2.0, d],
    [L3 + L4, 0],
    [L3 + L4 + L3, 0],
    [L3 + L4 / 2.0 + L2 / 2.0 + L1, d],
  ]
  ctx.moveTo(...points[0])
  ctx.lineTo(...points[1])
  ctx.lineTo(...points[2])
  ctx.lineTo(...points[3])
  ctx.closePath()
  ctx.fill()
}

function renderCap4Qubit(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])
  ctx.fillStyle = getLayerColor(shapeLayerNumberList[0])

  ctx.transform(1, 0, 0, 1, 0, 0)
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { L1, L2, L3, L4, L5, L6, L7, W1, W2, W3, W4, layer, datatype } = custom_attrs

  ctx.beginPath()
  ctx.moveTo(0, 0)
  ctx.lineTo(L3, 0)
  ctx.lineTo(L3, W2)
  ctx.lineTo(L3 - L2, W2)
  ctx.lineTo(L3 - L2, W2 + W1)
  ctx.lineTo(L3 - L2 - L1, W2 + W1)
  ctx.lineTo(L3 - L2 - L1, W2)
  ctx.lineTo(0, W2)
  ctx.lineTo(0, 0)
  ctx.closePath()
  ctx.fill()

  ctx.globalCompositeOperation = 'xor'
  ctx.beginPath()
  // cxt.moveTo((L3 - L4) * 0.5 -L7, (W2 - W4) * 0.5);
  ctx.lineTo((L3 - L4) * 0.5 - L7, (W2 - W4) * 0.5)
  ctx.lineTo((L3 - L4) * 0.5 - L7 + L6, (W2 - W4) * 0.5)
  ctx.lineTo((L3 - L4) * 0.5 - L7 + L6, (W2 - W4) * 0.5 + W4)
  ctx.lineTo((L3 - L4) * 0.5 - L7 + L6 - L5, (W2 - W4) * 0.5 + W4)
  ctx.lineTo((L3 - L4) * 0.5 - L7 + L6 - L5, (W2 - W4) * 0.5 + W4 + W3)
  ctx.lineTo(L3 - L2 - L1 + (L1 - L4) * 0.5, (W2 - W4) * 0.5 + W4 + W3)
  ctx.lineTo(L3 - L2 - L1 + (L1 - L4) * 0.5, (W2 - W4) * 0.5 + W4)
  ctx.lineTo((L3 - L4) * 0.5 - L7, (W2 - W4) * 0.5 + W4)
  ctx.lineTo((L3 - L4) * 0.5 - L7, (W2 - W4) * 0.5)
  ctx.closePath()
  ctx.fill()
}

function renderCap2Qubit(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])
  ctx.fillStyle = getLayerColor(shapeLayerNumberList[0])

  ctx.transform(1, 0, 0, 1, 0, 0)
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { L1, L2, L3, L4, L5, W1, W2, W3, W4, W5, W6, W7, W8 = (W3 - W6) * 0.5, W9 = W4 + W2 + W8 - W7, L6 = (L1 - L4) * 0.5 + L2 + L3 - L5, layer, datatype } = custom_attrs

  ctx.beginPath()
  ctx.moveTo(0, W2 + W4)
  ctx.lineTo(L3, W2 + W4)
  ctx.lineTo(L3, W4)
  ctx.lineTo(L3 + L2, W4)
  ctx.lineTo(L3 + L2, 0)
  ctx.lineTo(L3 + L2 + L1, 0)
  ctx.lineTo(L3 + L2 + L1, W4)
  ctx.lineTo(L3 + L2 + L1 + L2, W4)
  ctx.lineTo(L3 + L2 + L1 + L2, W4 + W2)
  ctx.lineTo(L3 + L2 + L1 + L2 + L3, W4 + W2)
  ctx.lineTo(L3 + L2 + L1 + L2 + L3, W4 + W2 + W3)
  ctx.lineTo(L3 + L2 + L1 + L2, W4 + W2 + W3)
  ctx.lineTo(L3 + L2 + L1 + L2, W4 + W2 + W3 + W2)
  ctx.lineTo(L3 + L2 + L1, W4 + W2 + W3 + W2)
  ctx.lineTo(L3 + L2 + L1, W4 + W2 + W3 + W2 + W1)
  ctx.lineTo(L3 + L2, W4 + W2 + W3 + W2 + W1)
  ctx.lineTo(L3 + L2, W4 + W2 + W3 + W2)
  ctx.lineTo(L3, W4 + W2 + W3 + W2)
  ctx.lineTo(L3, W4 + W2 + W3)
  ctx.lineTo(0, W4 + W2 + W3)
  ctx.moveTo(0, W2 + W4)

  ctx.closePath()
  ctx.fill()

  ctx.globalCompositeOperation = 'xor'
  ctx.beginPath()
  // cxt.moveTo((L3 - L4) * 0.5 -L7, (W2 - W4) * 0.5);
  ctx.lineTo(L6, W4 + W2 + W8)
  ctx.lineTo(L6 + L5, W4 + W2 + W8)
  ctx.lineTo(L6 + L5, W9)
  ctx.lineTo(L6 + L5 + L4, W9)
  ctx.lineTo(L6 + L5 + L4, W4 + W2 + W8)
  ctx.lineTo(L6 + L5 + L4 + L5, W4 + W2 + W8)
  ctx.lineTo(L6 + L5 + L4 + L5, W4 + W2 + W8 + W6)
  ctx.lineTo(L6 + L5 + L4, W4 + W2 + W8 + W6)
  ctx.lineTo(L6 + L5 + L4, W4 + W2 + W8 + W6 + W5)
  ctx.lineTo(L6 + L5, W4 + W2 + W8 + W6 + W5)
  ctx.lineTo(L6 + L5, W4 + W2 + W8 + W6)
  ctx.lineTo(L6, W4 + W2 + W8 + W6)
  ctx.lineTo(L6, W4 + W2 + W8)
  ctx.closePath()
  ctx.fill()
}

function renderCap6Qubit(ctx, shape) {
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])
  ctx.fillStyle = getLayerColor(shapeLayerNumberList[0])

  ctx.transform(1, 0, 0, 1, 0, 0)
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let {
    a0,
    a1,
    a2,
    a3,
    a4,
    a5,
    a6,
    a7,
    a8,
    b0,
    b1,
    b2,
    b3,
    b4,
    b6 = (a5 - b3) * 0.5,
    b5 = a7 + b6 - b4,
    b8 = (a8 - b0) * 0.5,
    b7 = b8 + a6 - b2,
    b9 = b2 + b7 - a4 - a2,
    layer,
    datatype,
  } = custom_attrs

  ctx.beginPath()
  ctx.lineTo(0, a7)
  ctx.lineTo(a6, a7)
  ctx.lineTo(a6, 0)
  ctx.lineTo(a6 + a8, 0)
  ctx.lineTo(a6 + a8, a7)
  ctx.lineTo(a6 + a8 + a6, a7)
  ctx.lineTo(a6 + a8 + a6, a7 + a5)
  ctx.lineTo(a6 + a8 + a6 - a4, a7 + a5)
  ctx.lineTo(a6 + a8 + a6 - a4, a7 + a5 + a3)
  ctx.lineTo(a6 + a8 + a6 - a4 - a2, a7 + a5 + a3)
  ctx.lineTo(a6 + a8 + a6 - a4 - a2, a7 + a5 + a3 + a1)
  ctx.lineTo(a6 + a8 + a6 - a4 - a2 - a0, a7 + a5 + a3 + a1)
  ctx.lineTo(a6 + a8 + a6 - a4 - a2 - a0, a7 + a5 + a3)
  ctx.lineTo(a6 + a1, a7 + a5 + a3)
  ctx.lineTo(a6, a7 + a5 + a3)
  ctx.lineTo(a6, a7 + a5)
  ctx.lineTo(0, a7 + a5)
  ctx.lineTo(0, a7)

  ctx.closePath()
  ctx.fill()

  ctx.globalCompositeOperation = 'xor'
  ctx.beginPath()
  ctx.moveTo(b7, a7 + b6)
  ctx.lineTo(b7 + b2, a7 + b6)
  ctx.lineTo(b7 + b2, a7 + b6 - b4)
  ctx.lineTo(b7 + b2 + b0, a7 + b6 - b4)
  ctx.lineTo(b7 + b2 + b0, a7 + b6)
  ctx.lineTo(b7 + b2 + b0 + b2, a7 + b6)
  ctx.lineTo(b7 + b2 + b0 + b2, a7 + b6 + b3)
  ctx.lineTo(b7 + b2 + b0, a7 + b6 + b3)
  ctx.lineTo(b7 + b2 + b0, a7 + b6 + b3 + b1)
  ctx.lineTo(b7 + b2, a7 + b6 + b3 + b1)
  ctx.lineTo(b7 + b2, a7 + b6 + b3)
  ctx.lineTo(b7, a7 + b6 + b3)
  ctx.lineTo(b7, a7 + b6)
  ctx.closePath()
  ctx.fill()
}

function hitFunc(context, shape) {
  context.beginPath()
  context.rect(0, 0, shape.attrs.width, shape.attrs.height)
  context.closePath()
  context.fillStrokeShape(this)
}

function newCustomShape(data) {
  let attrs = data.attrs
  let { obj_type, custom_attrs } = attrs
  var createAttrs = {
    ...attrs,
    ...{
      // fill: "rgba(255, 255, 255, 0.1)",
      custom_attrs: custom_attrs,
      sceneFunc: RENDER_FUNC_MAP[obj_type],
      hitFunc: hitFunc,
      draggable: true,
      name: 'object',
    },
  }
  let newShape = new Konva.Shape(createAttrs)
  for (let [k, v] of Object.entries(attrs)) {
    newShape.setAttr(k, v)
  }
  newShape.className = 'Shape'
  return newShape
}

function convertLayerToFormularData() {}

var randomColor = function () {
  return 'white'
}

function generateLayerColor(index, layerNumber) {
  let LAYER_COLOR_LIST = ['#5555FF', '#AAA961', '#F5BDBB', '#B988BF', '#D2D2D3', '#55FF55', '#55AAAA', '#FF5555', '#0000AA', '#00AA00', '#005555', '#AA0000', '#550055', '#555500', '#000000']
  if (layerNumber < LAYER_COLOR_LIST.length) {
    return LAYER_COLOR_LIST[layerNumber]
  }
  if (index < LAYER_COLOR_LIST.length) {
    return LAYER_COLOR_LIST[index]
  }
  return randomColor()
}

function getLayerColor(layerNumber) {
  let layerColorRow = window.layerTableDataDict[layerNumber]
  if (!layerColorRow) {
    return generateLayerColor(layerNumber, layerNumber)
  }
  return layerColorRow.hide ? 'rgba(0, 0, 0, 0)' : layerColorRow.color
  // let color = '#5555FF';
  // return color.replace(/#/, "0x");
  // return '#5555FF';
}

/// formular -> canvas

function newStageFromSerializedData(cellObject, geometricRightClickedHandler) {
  if (cellObject.attrs.dependencies) {
    window.dependencies = cellObject.attrs.dependencies
  }
  let rootContainer = _createNode(cellObject, geometricRightClickedHandler)
  return {
    rootContainer,
  }
}

function newContainer(nodeObject) {
  let rootContainer = new PIXI.Container()
  rootContainer.width = app.width
  rootContainer.height = app.height
  return rootContainer
}

function subscribeDraggable(target) {
  // function onDragStart(event) {
  //   this.data = event.data;
  //   this.dragging = true;
  // }

  // function onDragEnd(event) {
  // 	this.dragging = false;
  // 	this.data = null;
  // }

  // function onDragMove(event) {
  // 	if (this.dragging) {
  // 		const newPosition = this.data.getLocalPosition(this.parent);
  // 		this.x = newPosition.x;
  // 		this.y = newPosition.y;
  // 	}
  // }
  function onDragStart(event) {
    if (!this.dragging) {
      this.data = event.data
      this.dragging = true
      this.oldpoint = { x: this.x, y: this.y }
      this.dragPoint = event.data.getLocalPosition(this.parent)
      this.dragPoint.x -= this.x
      this.dragPoint.y -= this.y
    }
  }

  function onDragEnd() {
    if (this.dragging) {
      this.dragging = false
      // set the interaction data to null
      this.data = null
      if (this.newpoint != undefined && this.oldpoint != undefined) {
        if (this.newpoint.x - this.oldpoint.x != 0 || this.newpoint.y - this.oldpoint.y != 0) {
          bus.$emit('componentDragEnd', target)
        }
      }
    }
  }

  function onDragMove(e) {
    if (this.dragging) {
      const newPosition = this.data.getLocalPosition(this.parent)
      this.x = newPosition.x - this.dragPoint.x
      this.y = newPosition.y - this.dragPoint.y
      this.newpoint = { x: this.x, y: this.y }
      bus.$emit('componentDidClicked', e, target)
    }
  }

  target.interactive = true
  target
    .on('mousedown', onDragStart)
    .on('touchstart', onDragStart)
    .on('mouseup', onDragEnd)
    .on('mouseupoutside', onDragEnd)
    .on('touchend', onDragEnd)
    .on('touchendoutside', onDragEnd)
    .on('mousemove', onDragMove)
    .on('touchmove', onDragMove)
}

function subscribeClickEvent(target) {
  function onClick(e) {
    bus.$emit('componentDidClicked', e, target)
  }
  target.interactive = true
  target.on('pointerdown', onClick)
}

function renderResonatorFPDesign(ctx, shape) {
  // let ctx = new PIXI.Graphics();
  // ctx.x = 170;
  // ctx.y = 170;
  // ctx.rotation = 0.5;
  // ctx.backgroundColor = 0xFF0000;

  // ctx.fillStyle = 'red';
  // ctx.fillRect(0, 0, shape.attrs.width, shape.attrs.height);
  // return ctx;
  let shapeLayerNumberList = shape.attrs.obj_layer_list
  ctx.strokeStyle = getLayerColor(shapeLayerNumberList[0])
  // ctx.transform(1, 0, 0, -1, 0, shape.attrs.height);

  if (shape.attrs.flip) {
    ctx.transform(-1, 0, 0, 1, shape.attrs.width, 0)
  } else {
    ctx.transform(1, 0, 0, 1, 0, 0)
  }
  var custom_attrs = {}
  shape.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  const { L1, H1, L2, H2, L3, r, W1, W2, n } = custom_attrs

  // ctx.width = shape.attrs.width;
  // ctx.height = shape.attrs.height;

  let isDirectionLeft = (n - 3) % 4 == 0
  let x_pad = W1 / 2.0
  let r2 = r - W2 - W1

  // ctx.lineStyle(W1, 0xFF3300, 1);
  // ctx.lineStyle(W1, strokeColor, 1);
  ctx.beginPath()
  ctx.lineWidth = W1

  var point_a
  if (isDirectionLeft) {
    point_a = [W1 / 2.0 + r + L2 + r2, 0]
  } else {
    point_a = [W1 / 2.0 + L3 - L2, 0]
  }
  ctx.moveTo(...point_a)
  let point_c = [point_a[0], point_a[1] + H2 + (isDirectionLeft ? r2 : r)]
  let point_e = isDirectionLeft ? [point_c[0] - r2 - L2, point_c[1]] : [W1 * 0.5 + r + L3, point_c[1]]
  ctx.arcTo(...point_c, ...point_e, isDirectionLeft ? r2 : r)
  ctx.lineTo(...point_e)

  // var PATH_A_BASE_HEIGHT = point_c[1];
  // var point_f = isDirectionLeft?[W1*0.5, PATH_A_BASE_HEIGHT]:[W1*0.5+r+L3+r2, PATH_A_BASE_HEIGHT];
  // var point_g = [point_f[0], point_f[1] + isDirectionLeft ? r:r2];

  var last_path_a_point = point_e
  const LEVEL_HEIGHT = 2 * r + 2 * r2
  var PATH_A_BASE_HEIGHT = point_c[1]
  for (var cornerIndex = 0; cornerIndex < (n - 3) / 2; cornerIndex += 1) {
    var level = Math.floor(cornerIndex / 2)
    if (isDirectionLeft) {
      if (cornerIndex % 2 == 0) {
        var point_f = [W1 * 0.5, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT]
        var point_g = [point_f[0], point_f[1] + r]
        ctx.arcTo(...point_f, ...point_g, r)
        var point_h = [point_g[0], point_g[1] + r]
        var point_i = [point_h[0] + r, point_h[1]]
        ctx.arcTo(...point_h, ...point_i, r)
        last_path_a_point = [x_pad + r + L2, point_i[1]]
        ctx.lineTo(...last_path_a_point)
      } else {
        var point_l = [x_pad + r + L3 + r2, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT + 2 * r]
        var point_m = [point_l[0], point_l[1] + r2]
        ctx.arcTo(...point_l, ...point_m, r2)
        var point_n = [point_m[0], point_m[1] + r2]
        var point_o = [point_n[0] - r2, point_n[1]]
        ctx.arcTo(...point_n, ...point_o, r2)
        last_path_a_point = [x_pad + r + L2, point_o[1]]
        ctx.lineTo(...last_path_a_point)
      }
    } else {
      if (cornerIndex % 2 == 0) {
        var point_f = [W1 * 0.5 + r + L3 + r2, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT]
        var point_g = [point_f[0], point_f[1] + r2]
        ctx.arcTo(...point_f, ...point_g, r2)
        var point_h = [point_g[0], point_g[1] + r2]
        var point_i = [point_h[0] - r2, point_h[1]]
        ctx.arcTo(...point_h, ...point_i, r2)
        last_path_a_point = [x_pad + L3 - L2, point_i[1]]
        ctx.lineTo(...last_path_a_point)
      } else {
        var point_l = [x_pad, level * LEVEL_HEIGHT + PATH_A_BASE_HEIGHT + 2 * r2]
        var point_m = [point_l[0], point_l[1] + r]
        ctx.arcTo(...point_l, ...point_m, r)
        var point_n = [point_m[0], point_m[1] + r]
        var point_o = [point_n[0] + r, point_n[1]]
        ctx.arcTo(...point_n, ...point_o, r)
        last_path_a_point = [x_pad + r + L2, point_o[1]]
        ctx.lineTo(...last_path_a_point)
      }
    }
  }
  let point_r = [last_path_a_point[0] - L2 - r, last_path_a_point[1]]
  let point_s = [point_r[0], point_r[1] + r]
  ctx.arcTo(...point_r, ...point_s, r)
  let point_t = [point_s[0], point_s[1] + H1]
  ctx.lineTo(...point_t)
  let point_u = [point_t[0], point_t[1] + r]
  let point_v = [point_u[0] + r, point_u[1]]
  ctx.arcTo(...point_u, ...point_v, r)
  let point_w = [point_v[0] + L1, point_v[1]]
  ctx.lineTo(...point_w)

  // path b
  var point_a2
  if (isDirectionLeft) {
    point_a2 = [point_a[0] + W1 + W2, point_a[1]]
  } else {
    point_a2 = [x_pad + r + L3 - L2 - r2, 0]
  }
  ctx.moveTo(...point_a2)
  let point_b2 = [point_a2[0], point_a2[1] + H2]
  ctx.lineTo(...point_b2)
  let point_c2 = [point_a2[0], point_a2[1] + H2 + (isDirectionLeft ? r : r2)]
  let point_d2 = [isDirectionLeft ? point_a2[0] - r : point_a2[0] + r2, point_c2[1]]
  ctx.arcTo(...point_c2, ...point_d2, isDirectionLeft ? r : r2)
  var last_path_b_point = point_d2
  for (var level = 0; level < (n - 3) / 2; level += 1) {
    if (isDirectionLeft) {
      if (level % 2 == 0) {
        var point_e2 = [last_path_b_point[0] - L2, last_path_b_point[1]]
        ctx.lineTo(...point_e2)
        var point_f2 = [point_e2[0] - r2, point_e2[1]]
        var point_g2 = [point_f2[0], point_f2[1] + r2]
        ctx.arcTo(...point_f2, ...point_g2, r2)
        var point_h2 = [point_g2[0], point_g2[1] + r2]
        var point_i2 = [point_h2[0] + r2, point_h2[1]]
        ctx.arcTo(...point_h2, ...point_i2, r2)
        last_path_b_point = [x_pad + r + L2, point_i2[1]]
        ctx.lineTo(...last_path_b_point)
      } else {
        var point_k2 = [last_path_b_point[0] + L3 - L2, last_path_b_point[1]]
        ctx.lineTo(...point_k2)
        var point_l2 = [point_k2[0] + r, point_k2[1]]
        var point_m2 = [point_l2[0], point_l2[1] + r]
        ctx.arcTo(...point_l2, ...point_m2, r)
        var point_n2 = [point_m2[0], point_m2[1] + r]
        var point_o2 = [point_n2[0] - r, point_n2[1]]
        ctx.arcTo(...point_n2, ...point_o2, r)
        last_path_b_point = [x_pad + r + L2, point_o2[1]]
        ctx.lineTo(...last_path_b_point)
      }
    } else {
      if (level % 2 == 0) {
        var point_e2 = [last_path_b_point[0] + L2, last_path_b_point[1]]
        ctx.lineTo(...point_e2)
        var point_f2 = [point_e2[0] + r, point_e2[1]]
        var point_g2 = [point_f2[0], point_f2[1] + r]
        ctx.arcTo(...point_f2, ...point_g2, r)
        var point_h2 = [point_g2[0], point_g2[1] + r]
        var point_i2 = [point_h2[0] - r, point_h2[1]]
        ctx.arcTo(...point_h2, ...point_i2, r)
        last_path_b_point = [x_pad + L3 - L2 - r2, point_i2[1]]
        ctx.lineTo(...last_path_b_point)
      } else {
        var point_k2 = [x_pad + r, last_path_b_point[1]]
        ctx.lineTo(...point_k2)
        var point_l2 = [point_k2[0] - r2, point_k2[1]]
        var point_m2 = [point_l2[0], point_l2[1] + r2]
        ctx.arcTo(...point_l2, ...point_m2, r2)
        var point_n2 = [point_m2[0], point_m2[1] + r2]
        var point_o2 = [point_n2[0] + r2, point_n2[1]]
        ctx.arcTo(...point_n2, ...point_o2, r2)
        last_path_b_point = [x_pad + r + L3 - L2, point_o2[1]]
        ctx.lineTo(...last_path_b_point)
      }
    }
  }
  let point_q2 = [isDirectionLeft ? last_path_b_point[0] - L2 : x_pad + r, last_path_b_point[1]]
  ctx.lineTo(...point_q2)
  let point_r2 = [point_q2[0] - r2, point_q2[1]]
  let point_s2 = [point_r2[0], point_r2[1] + r2]
  ctx.arcTo(...point_r2, ...point_s2, r2)
  let point_t2 = [point_s2[0], point_s2[1] + H1]
  ctx.lineTo(...point_t2)
  let point_u2 = [point_t2[0], point_t2[1] + r2]
  let point_v2 = [point_u2[0] + r2, point_u2[1]]
  ctx.arcTo(...point_u2, ...point_v2, r2)
  let point_w2 = [point_v2[0] + L1, point_v2[1]]
  ctx.lineTo(...point_w2)

  ctx.stroke()
  // sprite.mask = ctx;
  // return sprite;
}

function newCanvasRenderComponent(nodeObject, renderFunc) {
  const canvas = new OffscreenCanvas(nodeObject.attrs.width, nodeObject.attrs.height)
  const ctx = canvas.getContext('2d')
  renderFunc(ctx, nodeObject)
  return newSpriteFrom(canvas, nodeObject)
}

const PIXI_CANVAS_RENDER_MAP = {
  resonator_fp_design: renderResonatorFPDesign,
  resonator_1: renderResonator1,
  cap1_qubit_resonator: renderCap1QubitResonator,
  pad1: renderPad1,
  // 'cap1_qubit': renderCap1Qubit,
  cpwg_formation_1: renderCPWGFormation1,
  // 'squid_1': renderSquid1,
  // '100bit_air_bridge': render100BitAirBridge,
  xy_control_line: renderXYControlLine,
  z_control_line: renderZControlLine,
  xz_line_tail_1: renderXZLineTail1,
  xz_line_tail_2: renderXZLineTail2,
  cap4_qubit: renderCap4Qubit,
  cap2_qubit: renderCap2Qubit,
  cap6_qubit: renderCap6Qubit,
}

function newGraphics(nodeObject) {
  if (nodeObject.attrs.obj_is_component) {
    let obj_type = nodeObject.attrs.obj_type
    let pixiObjectFunc = PIXI_OBJECT_MAP[obj_type]
    return pixiObjectFunc(nodeObject)
  }
  return new PIXI.Graphics()
}

function newComponent(nodeObject) {
  if (nodeObject.attrs.obj_is_component) {
    const { className, attrs } = nodeObject
    if (attrs.obj_render_type == RENDER_TYPE_CANVAS) {
      let obj_type = nodeObject.attrs.obj_type
      let pixiObjectFunc = PIXI_CANVAS_RENDER_MAP[obj_type]
      return newCanvasRenderComponent(nodeObject, pixiObjectFunc)
    } else if (attrs.obj_render_type == RENDER_TYPE_CANVAS_TO_SVG) {
      return newCanvasToSvgComponent(data)
    } else if (attrs.obj_render_type == RENDER_TYPE_SVG_CODE) {
      return newComponentFromSvgCode(nodeObject, attrs.svg_code)
    } else if (attrs.obj_render_type == RENDER_TYPE_SVG_JSON) {
      return newSvgJsonComponent(nodeObject)
    }
  }
  return new PIXI.Sprite()
}

function newSprite(nodeObject) {
  if (nodeObject.attrs.obj_type == 'reference') {
    let ref_cell_name = nodeObject.attrs.ref_cell
    var refNodeObject = window.dependencies[ref_cell_name]
    let svgStr = svgStrFromSvgJson(refNodeObject)

    let svgSource = new PIXI.SVGResource(svgStr)
    const sprite = PIXI.Sprite.from(svgSource)
    sprite.obj_data = nodeObject
    // subscribeClickEvent(sprite);
    // subscribeDraggable(sprite);

    let origin = nodeObject.attrs.origin
    let scaleX = nodeObject.attrs.scaleX
    let rotation = nodeObject.attrs.rotation || 0
    if (origin) {
      let [x, y] = origin
      let angleRadian = (rotation * Math.PI) / 180.0
      let [newX, newY] = rotatePoint([0, 0], angleRadian, [x, y])
      sprite.x = newX * scaleX
      sprite.y = newY * scaleX
      sprite.scale.x = scaleX
      sprite.scale.y = scaleX
      sprite.rotation = angleRadian
    }
    return sprite
  }
}

function newNode(nodeObject, Class) {
  if (nodeObject.attrs.obj_is_component) {
    return newComponent(nodeObject)
  }
  if (nodeObject.attrs.obj_is_import) {
    return newSvgJsonComponent(nodeObject)
  }
  if (Class === PIXI.Container) {
    return newContainer(nodeObject)
  }
  if (Class === PIXI.Graphics) {
    return newGraphics(nodeObject)
  }
  if (Class === PIXI.Sprite) {
    return newSprite(nodeObject)
  }
  return null
}

function newBasicGeometric(nodeObject) {
  let graph = new PIXI.Graphics()
  if (!nodeObject.attrs.obj_layer_list) {
    nodeObject.attrs.obj_layer_list = [0]
  }
  graph.lineStyle(1, colorTransform(getLayerColor(nodeObject.attrs.obj_layer_list[0])), 1)
  graph.name = nodeObject.attrs.name
  graph.draw_type = nodeObject.attrs.draw_type
  graph.draw_points = nodeObject.attrs.draw_points
  graph.obj_layer_list = nodeObject.attrs.obj_layer_list
  graph.uuid = nodeObject.attrs.uuid
  graph.unitInfo = nodeObject.attrs.unitInfo
  switch (nodeObject.attrs.shape_type) {
    case PIXI.SHAPES.POLY:
      let point_list = nodeObject.attrs.point_list
      for (let i = 0; i < point_list.length; i++) {
        if (i === 0) {
          graph.moveTo(...point_list[0])
        } else {
          graph.lineTo(...point_list[i])
        }
      }
      break
    case PIXI.SHAPES.RECT:
      graph.drawRect(nodeObject.attrs.x, nodeObject.attrs.y, nodeObject.attrs.width, nodeObject.attrs.height)
      break
    case PIXI.SHAPES.ELIP:
      graph.drawEllipse(nodeObject.attrs.x, nodeObject.attrs.y, nodeObject.attrs.width, nodeObject.attrs.height)
      break
    default:
      break
  }
  return graph
}

function _createNode(obj, geometricRightClickedHandler) {
  var className = obj.className,
    children = obj.children,
    no,
    len,
    n
  // if container was passed in, add it to attrs
  if ('Graphics' == className && obj.attrs && !isNaN(obj.attrs.shape_type)) {
    return newBasicGeometric(obj)
    // return null;
  }
  if ('Sprite' == className && !obj.attrs.obj_is_component) {
    return newSprite(obj)
  }
  if (!PIXI[className]) {
    // className = 'Container';
    console.error('className error ', className)
    return
  }
  const Class = PIXI[className]
  no = newNode(obj, Class)
  // setNodeAttrs(className, no, obj.attrs)
  no.setAttrs(obj.attrs)
  if (children) {
    len = children.length
    for (n = 0; n < len; n++) {
      let child = _createNode(children[n], geometricRightClickedHandler)
      if (child) {
        no.addChild(child)
      }
    }
  }
  return no
}

/// canvas -> formular

function renderCap1Qubit(data) {
  var { width, height } = data.attrs
  var custom_attrs = {}
  data.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  var { L1, L2, L3, L4, L5, W1, W2, W3, W4, W5 } = custom_attrs
  var rect = new Rectangle({
    x: (L3 - L1) / 2.0,
    y: 0,
    width: L1,
    height: W1,
  })
  var path = new Path.Rectangle(rect)
  path.fillColor = '#5655F6'
  path = path.unite(new Path.Rectangle(0, (W1 - W3) / 2.0, L3, W3))
  path = path.exclude(new Path.Rectangle((L3 - L5) / 2.0, (W1 - W5) / 2.0, (L5 - L4) / 2.0, W5))
  path = path.exclude(new Path.Rectangle((L3 - L4) / 2.0, (W1 - W4) / 2.0, L4, W4))
  path = path.exclude(new Path.Rectangle((L3 + L4) / 2.0, (W1 - W5) / 2.0, (L5 - L4) / 2.0, W5))
  path = path.exclude(new Path.Rectangle((L3 - L2) / 2.0, (W1 - W2) / 2.0, L2, (W2 - W4) / 2.0))
  path = path.exclude(new Path.Rectangle((L3 - L2) / 2.0, (W1 + W4) / 2.0, L2, (W2 - W4) / 2.0))

  var result = path
  var svgPathElement = result.exportSVG()
  let svgStr = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">` + svgPathElement.outerHTML + '</svg>'
  return svgStr
}

function renderSquid1(data) {
  // extract property
  var { width, height } = data.attrs
  var custom_attrs = {}
  data.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  // var {L1,L2,w1,w2,L3,L4,L5,L6,a0,a1,a2,a3,b0,b1,b2,c1,c2,c3,d1,d2,w3,w4,w5} = custom_attrs;
  var { L1, L2, w1, w2, L3, L4, L5, L6, a0, a1, a2, a3, b0, b1, b2, c1, c2, c3, d1, d2, w5 } = custom_attrs
  // canvas adjust
  var matrix = [1, 0, 0, -1, 0, height]
  matrix = new Matrix(...matrix)
  // window.paper.project.view._context.transform(...metrix);
  window.paper.project.view.matrix = matrix
  //
  var path = new Path.Rectangle(0, 0, a3, b2)
  path.fillColor = '#5655F6'
  path = path.unite(new Path.Rectangle(a2, b2, a1, b1))

  path = path.unite(new Path.Rectangle(w2 - a3, 0, a3, b2))
  path = path.unite(new Path.Rectangle(w2 - a3 + a2, b2, a1, b1))

  path = path.unite(new Path.Rectangle(w2 / 2.0 - a0 / 2.0, b2 + w1 - (b0 - b1), a0, b0))

  // layer2
  // nouse: w3 w4
  let w3 = (c3 - a1) / 2.0
  let w4 = d2 - b1
  var path2 = new Path.Rectangle(a2 - w3, b2, c3, d2)
  let LAYER_COLOR_LIST = ['#5655F6', '#AAA961', '#F5BDBB', '#B988BF', '#D2D2D3']
  path2.fillColor = LAYER_COLOR_LIST[1]
  path2 = path2.unite(new Path.Rectangle(a3 / 2.0 - c1 / 2.0, b2 + d2, c1, d1))
  var copied = path2.clone()
  copied.bounds.x = w2 - a3 + (a2 - w3)
  path2 = path2.unite(copied)
  path2 = path2.unite(new Path.Rectangle(w2 / 2.0 - c3 / 2.0, b2 + w1 - b1 - w5, c3, d2))
  path2 = path2.unite(new Path.Rectangle(w2 / 2.0 - c1 / 2.0, b2 + w1 - b1 - w5 - d1, c1, d1))

  path2 = path2.unite(new Path.Rectangle(a3 / 2.0 - c1 / 2.0, b2 + d2 + d1 - L5, L1, L4 + L5))
  path2 = path2.unite(new Path.Rectangle(w2 - a3 / 2.0 - c1 / 2.0, b2 + d2 + d1 - L5, L1 + 0.004, L4 + L5))
  path2 = path2.unite(new Path.Rectangle(a3 / 2.0 - c1 / 2.0 - L3, b2 + d2 + d1 + L6, L3 * 2 + L1 * 2 + 0.004 + L2, L1))

  // export
  let paths = [path, path2]
  var svgStr = ''
  for (let p of paths) {
    // var svgPathElement = p.exportSVG({applyMatrix:true});
    var svgPathElement = p.exportSVG()
    // svgPathElement.setAttribute('transform', `matrix(1 0 0 -1 0 ${height})`);
    svgStr += svgPathElement.outerHTML
  }
  // svgStr = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" transform="matrix(1 0 0 -1 0 ${height})">` + svgStr + `</svg>`;
  svgStr = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">` + svgStr + `</svg>`
  return svgStr
}

function render100BitAirBridge(data) {
  let LAYER_COLOR_LIST = ['#5655F6', '#AAA961', '#F5BDBB', '#B988BF', '#D2D2D3']
  // extract property
  var { width, height } = data.attrs
  var custom_attrs = {}
  data.attrs.custom_attrs.forEach(v => {
    custom_attrs[v.field] = v.value
  })
  let { L1, L2, L3, L4, L5 } = custom_attrs
  // canvas adjust
  let matrix = new Matrix(1, 0, 0, -1, 0, height)
  window.paper.project.view.matrix = matrix
  // layer3
  var path = new Path.Rectangle(0, 0, L2, L3)
  path.fillColor = LAYER_COLOR_LIST[3]
  path = path.unite(new Path.Rectangle(0, L1 - L3, L2, L3))

  // layer4
  // nouse: w3 w4
  var path2 = new Path.Rectangle((L2 - L4) / 2.0, (L1 - L5) / 2.0, L4, L5)
  path2.fillColor = LAYER_COLOR_LIST[4]

  // export
  let paths = [path, path2]
  var svgStr = ''
  for (let p of paths) {
    // var svgPathElement = p.exportSVG({applyMatrix:true});
    var svgPathElement = p.exportSVG()
    // svgPathElement.setAttribute('transform', `matrix(1 0 0 -1 0 ${height})`);
    svgStr += svgPathElement.outerHTML
  }
  // svgStr = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" transform="matrix(1 0 0 -1 0 ${height})">` + svgStr + `</svg>`;
  svgStr = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">` + svgStr + `</svg>`
  return svgStr
}

const CANVAS_TO_SVG_RENDER_MAP = {
  cap1_qubit: renderCap1Qubit,
  squid_1: renderSquid1,
  '100bit_air_bridge': render100BitAirBridge,
}

function svgOffScreenCavans(width, height, svgStr) {
  const canvas = new OffscreenCanvas(width, height)
  const ctx = canvas.getContext('2d')
  const v = Canvg.fromString(ctx, svgStr, presets.offscreen())
  v.start({
    scaleWidth: width,
    scaleHeight: height,
  })
  return canvas
}

function newSpriteFrom(fromParam, obj_data) {
  // fromParam: string | BaseTexture | ImageSource | Texture
  // string: image path/url/data
  // ImageSource: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement | ImageBitmap
  const sprite = PIXI.Sprite.from(fromParam)
  sprite.obj_data = obj_data
  subscribeDraggable(sprite)
  subscribeClickEvent(sprite)
  return sprite
}

function newComponentFromSvgCode(data, svgCode) {
  // method 1: to canvas
  // var width = data.attrs.width || data.attrs.obj_width;
  // var height = data.attrs.height || data.attrs.obj_height;

  // let canvas = svgOffScreenCavans(width, height, svgCode);
  // return newSpriteFrom(canvas, data);

  // method 2:
  // // var texture = PIXI.Texture.from('data:image/svg+xml;charset=utf8,' + svgCode); // 也可以
  // var texture = PIXI.Texture.from(svgCode);
  // return newSpriteFrom(texture, data);

  // method 3:
  // let svgSource = new PIXI.SVGResource(svgCode)
  // var texture = new PIXI.BaseTexture(svgSource);
  // return newSpriteFrom(texture, data);

  // method 4:
  let svgSource = new PIXI.SVGResource(svgCode)
  return newSpriteFrom(svgSource, data)
}

function newCanvasToSvgComponent(data, toAttrs) {
  // Note: Not Implements
}

function getSvgStyle(layerNumber) {
  return 'fill: ' + getLayerColor(layerNumber)
}

function formularDataToSvgString(width, height, layerList, svgData, flip) {
  var svgStr = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" 
    height="${height}" viewBox="0 0 ${width} ${height}"><defs>
    <style type="text/css">`
  // TODO: datatype暂时没有使用，相同layer使用相同的class
  for (let ld of svgData.layer_datatypes) {
    var { layer, datatype } = ld
    var color = getLayerColor(layer)
    svgStr += `.l${layer}d${datatype} {stroke: ${color}; fill: ${color};}\n`
  }
  svgStr += '</style>'
  // TODO: 暂时没有处理dependecies
  // for cell in self.get_dependencies(True):
  // cell.to_svg(outfile, scaling, precision, "")
  svgStr += '</defs>'
  svgStr += '<g>'
  for (let item of svgData.polygons) {
    var points = ''
    for (var j = 0; j < item.points.length; j++) {
      points = points + `${item.points[j][0]},${item.points[j][1]} `
    }
    points = points.substr(0, points.length - 1)

    if (flip) {
      svgStr += `<polygon class="l${item.l}d${item.d}" points="${points}" transform="matrix(-1 0 0 1 ${width} 0)"/>`
    } else {
      svgStr += `<polygon class="l${item.l}d${item.d}" points="${points}"/>`
    }
  }
  for (let path of svgData.paths) {
    var points = path.points
    var d = ''
    for (var j = 0; j < points.length; j++) {
      if (j === 0) {
        d = d + 'M' + points[j][0] + ' ' + points[j][1] + ' '
      } else {
        d = d + 'L' + points[j][0] + ' ' + points[j][1] + ' '
      }
    }
    d = d.substr(0, d.length - 1)
    if (flip) {
      svgStr += `<path class="l${path.l}d${path.d}" d="${d}"  transform="matrix(-1 0 0 1 ${width} 0)"/>`
    } else {
      svgStr += `<path class="l${path.l}d${path.d}" d="${d}" />`
    }
  }

  for (let labelStr of svgData.labels) {
    svgStr += labelStr
  }

  for (let reference of svgData.references) {
    let { name, translate, rotation, x_reflection, magnification } = reference
    var transform = ''
    transform += `translate(${translate[0]} ${translate[1]})`
    if (rotation) {
      transform += ` rotate(${rotation})`
    }
    if (x_reflection) {
      transform += ' scale(1, -1)'
    }
    if (magnification) {
      transform += ` scale(${magnification})`
    }
    svgStr += `<use transform="${transform}" xlink:href="#${name.replaceAll('#', '_')}" />`
  }

  svgStr += '</g></svg>'
  return svgStr
}

function svgStrFromSvgJson(data) {
  var width = data.attrs.width || data.attrs.obj_width
  var height = data.attrs.height || data.attrs.obj_height
  let layerList = data.attrs.obj_layer_list
  let svgData = data.attrs.svg_json
  let flip = data.attrs.flip
  return formularDataToSvgString(width, height, layerList, svgData, flip)
}

function newSvgJsonComponent(data) {
  let svgStr = svgStrFromSvgJson(data)
  return newComponentFromSvgCode(data, svgStr)
}

function redrawComponent(node, data) {
  let parentNode = node.parent
  if (!data) {
    node.syncAttrsToObjectData()
    data = node.obj_data
  }

  parentNode.removeChild(node)
  node.destroy()
  let c = newComponent(data)
  c.setAttrs(data.attrs)
  parentNode.addChildAt(c, parentNode.length - 1)
  return c
}

//图层创建
function buildInitialLayers() {
  var result = []
  for (let layerNumber = 0; layerNumber < 5; layerNumber++) {
    var layerName = ''
    if (layerNumber == 0) {
      layerName = 'base'
    } else {
      layerName = `Layer ${layerNumber}`
    }
    let layerDict = {
      layerName: layerName,
      lock: false,
      hide: false,
      color: generateLayerColor(layerNumber, layerNumber),
      layerNumber: layerNumber,
      layerNameEdit: false,
      layerNumberEdit: false,
    }
    result.push(layerDict)
  }
  return result
}

//图层添加
function addNewLayer(layerNumber) {
  let newLayer = {
    layerName: `Layer ${layerNumber}`,
    lock: false,
    hide: false,
    color: generateLayerColor(layerNumber, layerNumber),
    layerNumber: layerNumber,
    layerNameEdit: false,
    layerNumberEdit: false,
  }
  return newLayer
}

PIXI.Container.prototype.setAttrs = function (attrs) {
  var finalAttrs = {
    x: attrs.x || 0,
    y: attrs.y || 0,
  }
  // 修改为使用canvas或svg更改
  // if (attrs.flip !== undefined) {
  //     // finalAttrs.flip = attrs.flip;
  //     if (attrs.flip) {
  //         this.setTransform()
  //     } else {
  //         this.setTransform()
  //     }
  // }
  if (attrs.scaleX !== undefined || attrs.scaleY !== undefined) {
    finalAttrs.scale = {
      x: attrs.scaleX || 1,
      y: attrs.scaleY || 1,
    }
  }
  if (attrs.name) {
    finalAttrs.name = attrs.name
  }
  if (attrs.rotation !== undefined) {
    finalAttrs.rotation = attrs.rotation
  }
  if (attrs.pivotX !== undefined || attrs.pivotY !== undefined) {
    finalAttrs.pivot = {
      x: attrs.pivotX || 0,
      y: attrs.pivotY || 0,
    }
  }
  Object.entries(finalAttrs).forEach(([k, v]) => {
    this[k] = v
  })

  // if (this.getClassName() != 'Container') {
  if (this.getClassName() == 'Sprite' && attrs.obj_is_component) {
    ;(this.anchor.x = 0.5), (this.anchor.y = 0.5)
  }
}

PIXI.Container.prototype.getClassName = function () {
  return this.constructor.name
}

PIXI.Container.prototype.getAttrs = function () {
  let base = {
    x: this.x,
    y: this.y,
  }
  var scaleAttr =
    this.scale.x == 1 && this.scale.y == 1
      ? {}
      : {
          scaleX: this.scale.x,
          scaleY: this.scale.y,
        }
  var nameAttr = this.name
    ? {
        name: this.name,
      }
    : {}
  var rotationAttr =
    this.rotation == 0
      ? {}
      : {
          rotation: this.rotation,
        }
  var pivotAttr =
    this.pivot.x == 0 && this.pivot.y == 0
      ? {}
      : {
          pivotX: this.pivot.x,
          pivotY: this.pivot.y,
        }
  return {
    ...base,
    ...scaleAttr,
    ...nameAttr,
    ...rotationAttr,
    ...pivotAttr,
  }
}

PIXI.Container.prototype.syncAttrsToObjectData = function () {
  if (!this.obj_data) {
    this.obj_data = {}
  }
  if (!this.obj_data.attrs) {
    this.obj_data.attrs = {}
  }
  var obj_attrs = this.obj_data.attrs,
    attrs = this.getAttrs(),
    key,
    val,
    getter,
    defaultValue,
    nonPlainObject

  for (key in attrs) {
    val = attrs[key]
    // if value is object and object is not plain
    // like class instance, we should skip it and to not include
    nonPlainObject = FakeUtil.isObject(val) && !FakeUtil._isPlainObject(val) && !FakeUtil._isArray(val)
    if (nonPlainObject) {
      continue
    }
    getter = typeof this[key] === 'function' && this[key]
    // remove attr value so that we can extract the default value from the getter
    delete attrs[key]
    defaultValue = getter ? getter.call(this) : null
    // restore attr value
    attrs[key] = val
    if (defaultValue !== val) {
      obj_attrs[key] = val
    }
  }
  // hard code
  if (this.getClassName() == 'Graphics') {
    this.obj_data = serializeGraphDataExtended(this, obj_attrs)
    return
  }
  // end-hard
}

PIXI.Container.prototype.toObject = function () {
  this.syncAttrsToObjectData()
  let obj_data = this.obj_data || {}
  obj_data.children = []
  this.children.forEach(child => {
    obj_data.children.push(child.toObject())
  })
  obj_data.className = this.getClassName()
  return obj_data
}

function serializeLayerData() {
  var lastLayerNumber = 0
  var layerData = []
  for (let [layerNumber, tableRow] of Object.entries(window.layerTableDataDict)) {
    var singleLayerDict = {}
    if (!isNaN(layerNumber)) {
      layerNumber = parseInt(layerNumber)
    } else {
      layerNumber = lastLayerNumber
    }
    lastLayerNumber += 1
    singleLayerDict['layer_number'] = layerNumber
    singleLayerDict['layer_lock'] = tableRow.lock || false
    singleLayerDict['layer_hide'] = tableRow.hide || false
    singleLayerDict['layer_color'] = tableRow.color || generateLayerColor(index, layerNumber)
    singleLayerDict['layer_name'] = tableRow.layerName || '' + layerNumber
    layerData.push(singleLayerDict)
  }
  return layerData
}

function serializeCellData(rootContainer) {
  // cleanup
  rootContainer.children.forEach(function (child) {
    if (child.getClassName() == 'Graphics' && !child._geometry.graphicsData[0]) rootContainer.removeChild(child)
  })
  // serialize
  let contentObject = rootContainer.toObject()
  contentObject.layer_data = serializeLayerData()
  // let data = serializeGraphBoardData(graph_board)
  // extendSerializeCellDataForGraphBoard(contentObject, data);
  return contentObject
}

function extendSerializeCellDataForGraphBoard(contentObject, graph_board_children) {
  let children = contentObject.children
  children.push(...graph_board_children)
}

function serializeGraphDataExtended(graph, attrs) {
  let graphicsData = graph._geometry.graphicsData[0]
  // graphicsData.fillStyle
  // alpha: (...)
  // color: (...)
  // matrix: (...)
  // texture: (...)
  // visible:
  // graphicsData.holes
  // graphicsData.lineStyle
  // graphicsData.matrix
  // graphicsData.points
  // graphicsData.type
  let shape = graphicsData.shape
  let type = graphicsData.type
  // let points = graphicsData.points;
  // POLY = 0,
  // RECT = 1,
  // CIRC = 2,
  // ELIP = 3,
  // RREC = 4
  if (!attrs) {
    attrs = {}
  }
  var className = 'Graphics'
  attrs.shape_type = type
  attrs.draw_type = graph.draw_type
  attrs.draw_points = graph.draw_points
  attrs.obj_layer_list = graph.obj_layer_list ? graph.obj_layer_list : [0]
  attrs.uuid = graph.uuid ? graph.uuid : generateUUID()
  attrs.name = graph.name
  attrs.unitInfo = graph.unitInfo ? graph.unitInfo : { unit: 'um', unit_scale: 0.001 }
  switch (type) {
    case PIXI.SHAPES.POLY:
      // line broken-line polygon
      // attrs.points
      let points = shape.points
      if (points.length < 2) {
        return
      }
      var point_list = []
      for (let index = 0; index < points.length / 2; index++) {
        if (index * 2 + 1 >= points.length) {
          break
        }
        point_list.push([points[index * 2], points[index * 2 + 1]])
      }
      if (graph.draw_type === 'polygon') {
        point_list.push([points[0], points[1]])
      }
      attrs.point_list = point_list
      // attrs.x = points[0];
      // attrs.y = points[1];
      break
    case PIXI.SHAPES.RECT:
      attrs.width = shape.width
      attrs.height = shape.height
      attrs.x = shape.x
      attrs.y = shape.y
      break
    case PIXI.SHAPES.CIRC:
      break
    case PIXI.SHAPES.ELIP:
      attrs.width = shape.width
      attrs.height = shape.height
      attrs.x = shape.x
      attrs.y = shape.y
      break
    case PIXI.SHAPES.RREC:
      break
    default:
      break
  }
  // if (window.unit_scale) {
  //     attrs.scaleX = 1.0 / window.unit_scale;
  // }
  // scaleX 是um为单位相对于当前版图基本单位(现在使用的事nm)的放大倍数
  attrs.scaleX = 1000
  return {
    attrs: attrs,
    uuid: graph.uuid,
    className: className,
  }
}

// function serializeGraphBoardData(graph_board) {
//     let children = graph_board.children;
//     if (!children) {
//         return null;
//     }
//     return children.map((graph) => serializeGraphData(graph))
// }

function rotatePoint(rotationCenter, angleRadian, point) {
  let [centerX, centerY] = rotationCenter
  let [x, y] = point
  let newX = (x - centerX) * Math.cos(angleRadian) - (y - centerY) * Math.sin(angleRadian) + centerX
  let newY = (x - centerX) * Math.sin(angleRadian) + (y - centerY) * Math.cos(angleRadian) + centerY
  // let newX = (x - centerX)* Math.cos(angle) + (y - centerY) * Math.sin(angle) + centerX;
  // let newY = -(x - centerX)* Math.sin(angle) + (y - centerY) * Math.cos(angle) + centerY;
  return [newX, newY]
}

function getGeometricOrigin(geometric) {
  // let {
  //     x: geometricX,
  //     y: geometricY,
  //     width,
  //     height
  // } = geometric.getBounds();
  // let centerX = geometric.x;
  // let centerY = geometric.y;
  // let rotation = geometric.rotation || 0;
  // let [originX, originY] = rotatePoint([centerX, centerY], -rotation, [
  //     geometricX,
  //     geometricY,
  // ]);
  // return [originX, originY];
  // let centerX = geometric.x;
  // let centerY = geometric.y;
  // let width = geometric.obj_data.attrs.width, height = geometric.obj_data.attrs.height;
  // let originX = centerX - width / 2.0;
  // let originY = centerY - height / 2.0;
  // return [originX, originY];
}

function buildComponentOForm(geometric) {
  // set anchor as 0.5
  let width = geometric.obj_data.attrs.width,
    height = geometric.obj_data.attrs.height
  let centerX = geometric.x / 1000
  let centerY = geometric.y / 1000
  let rotation = geometric.rotation || 0
  let formData = []
  let flip = !!geometric.obj_data.attrs.flip
  for (let [index, o] of Object.entries(geometric.obj_data.attrs.obj_O)) {
    var [x, y] = rotatePoint([centerX, centerY], rotation, [centerX - width / 2.0 + (flip ? width - o.x : o.x), centerY - height / 2.0 + o.y])
    if (index == 0 && geometric.obj_data.attrs.obj_O.length == 1) {
      formData.push({
        x: x,
        y: y,
        name: 'O',
        index: 0,
      })
      break
    }
    formData.push({
      x: x,
      y: y,
      name: `O${parseInt(index) + 1}`,
      index: index,
    })
  }
  return formData
}

function buildComponentCustomAttrsForm(geometric) {
  let custom_attrs = []
  for (let item of geometric.obj_data.attrs.custom_attrs) {
    let copiedItem = {}
    custom_attrs.push(Object.assign(copiedItem, item))
  }
  return custom_attrs
}

function changeOPosition(geometric, newO, pointOData) {
  let width = geometric.obj_data.attrs.width,
    height = geometric.obj_data.attrs.height
  let centerX = geometric.x
  let centerY = geometric.y
  let rotation = geometric.rotation || 0
  let flip = !!geometric.obj_data.attrs.flip
  let [oldOX, oldOY] = rotatePoint([centerX, centerY], rotation, [centerX - width / 2.0 + (flip ? width - pointOData.x : pointOData.x), centerY - height / 2.0 + pointOData.y])
  let oX = newO.x,
    oY = newO.y
  let deltaX = oX - oldOX,
    deltaY = oY - oldOY
  return {
    x: geometric.x + deltaX,
    y: geometric.y + deltaY,
  }
}

function buildGeometricBasicAttrsForm(geometric, lang) {
  var rotation = geometric.rotation || 0
  rotation = (rotation * 180) / Math.PI
  let centerX = geometric.x,
    centerY = geometric.y
  if (lang == 'zh') {
    return {
      '中心点 X': centerX / 1000,
      '中心点 Y': centerY / 1000,
      旋转: rotation,
    }
  } else if (lang == 'en') {
    return {
      'Center X': centerX / 1000,
      'Center Y': centerY / 1000,
      Rotate: rotation,
    }
  }
}

function changeBasicAttrs(geometric, key, value) {
  var value = parseFloat(value)
  if (key == '中心点 X') {
    geometric.x = value * 1000
  } else if (key == '中心点 Y') {
    geometric.y = value * 1000
  } else if (key == '旋转') {
    value = (value / 180.0) * Math.PI
    geometric.rotation = value
    geometric.obj_data.attrs.rotation = value
  }
  /*       if (key == 'Center X') {
            geometric.x = value;
        } else if (key == 'Center Y') {
            geometric.y = value;
        } else if (key == 'rotation') {
            geometric.rotation = value / 180.0 * Math.PI;
        } */
}

function updateComponentData(geometric, custom_attrs) {
  let obj_data = geometric.obj_data
  if (custom_attrs) {
    obj_data.attrs.custom_attrs = custom_attrs
  }
  obj_data.attrs.x = geometric.x
  obj_data.attrs.y = geometric.y
  return Object.assign({}, obj_data)
}

export {
  newStageFromSerializedData,
  newComponent,
  _createNode,
  redrawComponent,
  generateLayerColor,
  buildInitialLayers,
  addNewLayer,
  subscribeDraggable,
  serializeCellData,
  buildComponentOForm,
  buildComponentCustomAttrsForm,
  changeOPosition,
  buildGeometricBasicAttrsForm,
  changeBasicAttrs,
  updateComponentData,
  getLayerColor,
}
