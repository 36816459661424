<template>
  <div class="container" onselectstart="javascript:return false;">
    <div class="app-logo">
      <el-image :src="appLogo"></el-image>
    </div>
    <div class="changeLang">
      <span @click="changeLangCN()" :class="[CNActive ? 'active_login' : 'deactive_login']">中</span>
      <span class="login-head-lang-line">|</span>
      <span @click="changeLangEN()" :class="[ENActive ? 'active_login' : 'deactive_login']">EN</span>
    </div>
    <div class="loginContainer">
      <!-- 注册 -->
      <transition name="el-fade-in-linear">
        <el-form ref="rform" :rules="rrules" :model="rform" class="login-form" v-show="register">
          <div class="login-logo">
            <div style="float: left; padding-left: 53px; padding-top: 60px; padding-bottom: 38px; padding-right: 180px">
              <div class="window-logo">
                <el-image :src="Qlayout"></el-image>
              </div>
            </div>
            <div style="padding-top: 60px; padding-bottom: 38px">
              <span style="font-size: 20px; color: #fff">{{ $t('messages.haveAccount') }}</span>
              <el-link type="primary" :underline="false" style="vertical-align: top">
                <div style="padding-bottom: 1px; border-bottom: 1px solid #409eff; font-size: 20px" @click="toLogin">
                  {{ $t('messages.login') }}
                </div>
              </el-link>
            </div>
          </div>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="username">
            <el-input v-model="rform.tel" :placeholder="$t('messages.phoneEmail')" autocomplete="off"
              type="tel"></el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="code">
            <el-input v-model="rform.code" style="width: 60%" onkeyup="value=value.replace(/[^\d]/g,'')"
              :placeholder="$t('messages.enterVerificationCode')"></el-input>
            <button type="button"
              style="width: 30%; margin-left: 10%; font-size: 14px; background: linear-gradient(0deg, #154b8c, #327ad1); border: none; color: #fff; border-radius: 8px; padding: 8px 0; cursor: pointer"
              :disabled="codeDisable.disabled" @click="getCode">
              {{ codeStatus }}
            </button>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="password">
            <el-input v-model="rform.password" type="password" :placeholder="$t('messages.enterPassword')"
              @blur="checkPassStrong('rform', rform.password, 2)"></el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="repassword">
            <el-input v-model="rform.repassword" type="password" :placeholder="$t('messages.enterPassword2')"
              @blur="checkPassStrong('rform', rform.repassword, 3)"></el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 60px; padding-top: 20px">
            <el-button
              style="width: 100%; font-size: 18px; background: linear-gradient(0deg, #154b8c, #327ad1); border: none; color: #fff; border-radius: 8px"
              type="primary" @click="registForm">{{ $t('messages.register') }}</el-button>
          </el-form-item>
        </el-form>
      </transition>
      <!-- 登录 -->
      <transition name="el-fade-in-linear">
        <el-form ref="form" :rules="rules" :model="form" class="login-form" v-show="isLogin">
          <div class="login-logo">
            <div style="float: left; padding-left: 53px; padding-top: 60px; padding-bottom: 38px; padding-right: 146px">
              <div class="window-logo">
                <el-image :src="Qlayout"></el-image>
              </div>
            </div>
            <div style="padding-top: 60px; padding-bottom: 38px">
              <el-link type="primary" :underline="false">
                <div style="padding-bottom: 1px; border-bottom: 1px solid #409eff; font-size: 20px" @click="toRegist">
                  {{ $t('messages.register') }}
                </div>
              </el-link>
            </div>
          </div>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="username">
            <el-input v-model="form.username" :placeholder="$t('messages.phoneEmail')">
              <el-image style="height: 26px; vertical-align: middle" slot="prefix" :src="userIcon"
                fit="scale-down"></el-image>
            </el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="password">
            <el-input v-model="form.password" :type="passw" @keypress.native.enter="onEnterPress('form')"
              :placeholder="$t('messages.enterPassword')">
              <el-image style="height: 26px; vertical-align: middle" slot="prefix" :src="passIcon"
                fit="scale-down"></el-image>
              <!-- <el-image style="height: 26px; vertical-align: middle" slot="suffix" :src="icon" fit="scale-down" @click="showPass"></el-image> -->
            </el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px" prop="imageCode" v-show="needImageCode">
            <el-input v-model="form.imageCode" @keypress.native.enter="onEnterPress('form')"
              :placeholder="$t('messages.enterVerificationCode')">
              <el-image style="height: 26px; vertical-align: middle; cursor: pointer" slot="suffix" :src="imgCodeUrl"
                fit="scale-down" @click="changeImage"></el-image>
            </el-input>
          </el-form-item>
          <div style="padding-left: 53px; width: 355px; padding-bottom: 20px">
            <div>
              <span style="font-size: 16px; color: #68acff; cursor: pointer"
                @click="toForgetPas">{{ $t('messages.forgotPassword') }}</span>
            </div>
          </div>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 60px; padding-top: 20px">
            <el-button
              style="width: 100%; font-size: 18px; background: linear-gradient(0deg, #154b8c, #327ad1); border: none; color: #fff; border-radius: 8px"
              @click="submitForm" :loading="loginLoading">{{ $t('messages.login') }}</el-button>
          </el-form-item>
          <div class="tips">{{ $t('messages.loginTips') }}</div>
        </el-form>
      </transition>
      <transition name="el-fade-in-linear">
        <el-form ref="fform" :model="fform" :rules="frules" class="login-form" v-show="forgetPas">
          <div class="login-logo">
            <div style="float: left; padding-left: 53px; padding-top: 60px; padding-bottom: 38px; padding-right: 140px">
              <div class="window-logo">
                <el-image :src="Qlayout"></el-image>
              </div>
            </div>
            <div style="padding-top: 60px; padding-bottom: 38px">
              <!-- <span style="font-size:20px;color:#FFF;">已有账号？</span> -->
              <el-link type="primary" :underline="false" style="vertical-align: top">
                <div style="padding-bottom: 1px; border-bottom: 1px solid #409eff; font-size: 20px" @click="toLogin">
                  {{ $t('messages.returnLogin') }}
                </div>
              </el-link>
            </div>
          </div>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="username">
            <el-input v-model="fform.tel" :placeholder="$t('messages.phoneEmail')" autocomplete="off"
              type="tel"></el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="code">
            <el-input v-model="fform.code" style="width: 60%" onkeyup="value=value.replace(/[^\d]/g,'')"
              :placeholder="$t('messages.enterVerificationCode')"></el-input>
            <button type="button"
              style="width: 30%; margin-left: 10%; font-size: 14px; background: linear-gradient(0deg, #154b8c, #327ad1); border: none; color: #fff; border-radius: 8px; padding: 8px 0; cursor: pointer"
              :disabled="codeDisable.disabled" @click="getCodeForget">
              {{ codeStatus }}
            </button>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="password">
            <el-input v-model="fform.password" type="password" :placeholder="$t('messages.enterPassword')"
              @blur="checkPassStrong('fform', fform.password, 2)"></el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 20px" prop="repassword">
            <el-input v-model="fform.repassword" type="password" :placeholder="$t('messages.enterPassword2')"
              @blur="checkPassStrong('fform', fform.password, 3)"></el-input>
          </el-form-item>
          <el-form-item style="padding-left: 53px; width: 355px; padding-bottom: 60px; padding-top: 20px">
            <el-button
              style="width: 100%; font-size: 18px; background: linear-gradient(0deg, #154b8c, #327ad1); border: none; color: #fff; border-radius: 8px"
              type="primary" @click="submitReset">{{ $t('messages.reset') }}</el-button>
          </el-form-item>
        </el-form>
      </transition>
    </div>
    <!-- <el-dialog
      title="公告"
      :visible.sync="AndialogVisible"
      width="30%"
      :before-close="handleClose">
        <p>1.2版本将于10月20日停用，请还在使用1.2版本的用户尽快前往使用全新的2.0版本。</p>
        <p>请注意：所有v1.2版本文件于9月24日均已迁移至2.0版本，新产生的文件请手动导出GDS文件2.0版本。</p>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="AndialogVisible = false">确 定</el-button>
        </span>
    </el-dialog> -->
  </div>
</template>
<script>
import Md5 from "js-md5";
import { getSuitSize, setCookie } from "../../utils/utils.js"; //NOSONAR
import logo from "../../assets/denglu-logo.svg";
import QlayoutLogo from "../../assets/QLayout.svg";
import userIcon from "../../assets/denglu-yonghu.png";
import passIcon from "../../assets/denglu-mima.png";
import hidePass from "../../assets/denglu-mima-chakanzhong.png";
import checkPass from "../../assets/denglu-mima-yingcangzho.png";
import { login_api, checkpwd_api } from "@/api/login/login.js";
import { getMinioInfo_api } from "@/api/objectStorage/objectStorage.js";
import { getUserInfo_api } from "@/api/user/user.js";
import { pingCookie } from "@/api/login/regist";
import {
  getTelCode,
  registTel,
  getImageCode,
  patchChangePassword,
  getEMailCode,
  registEmail,
} from "@/api/login/regist.js";
import { saveUserCookie } from "@/api/api";
import bus from "../common/bus";
export default {
  components: {},
  data() {
    return {
      AndialogVisible: true,
      register: false,
      forgetPas: false,
      isLogin: true,
      loginLoading: false,
      appLogo: logo,
      Qlayout: QlayoutLogo,
      userIcon: userIcon,
      passIcon: passIcon,
      imgCodeUrl: "",
      remember: true,
      nums: 60,
      needImageCode: false,
      clock: null,
      codeStatus: this.$t("messages.getVerificationCode"),
      codeDisable: { disabled: false, type: "success" },
      passw: "password",
      icon: checkPass,
      form: {
        username: "",
        password: "",
        imageCode: "",
      },
      rform: {
        tel: "",
        password: "",
        repassword: "",
        code: "",
      },
      fform: {
        tel: "",
        password: "",
        repassword: "",
        code: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t("messages.userNameEmpty"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("messages.passwordEmpty"),
            trigger: "change",
          },
          {
            min: 6,
            max: 20,
            message: this.$t("messages.passwordlength"),
            trigger: "change",
          },
        ],
      },
      rrules: {
        tel: [
          {
            required: true,
            message: this.$t("messages.getVerificationCode"),
            trigger: "change",
          },
          {
            min: 11,
            max: 11,
            message: this.$t("messages.phoneNumberlength"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("messages.passwordEmpty"),
            trigger: "change",
          },
          {
            min: 6,
            max: 20,
            message: this.$t("messages.passwordlength"),
            trigger: "change",
          },
        ],
        repassword: [
          {
            required: true,
            message: this.$t("messages.passwordEmpty2"),
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("messages.verificationCodeEmpty"),
            trigger: "change",
          },
        ],
      },
      frules: {
        tel: [
          {
            required: true,
            message: this.$t("messages.getVerificationCode"),
            trigger: "change",
          },
          {
            min: 11,
            max: 11,
            message: this.$t("messages.phoneNumberlength"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("messages.passwordEmpty"),
            trigger: "change",
          },
          {
            min: 6,
            max: 20,
            message: this.$t("messages.passwordlength"),
            trigger: "change",
          },
        ],
        repassword: [
          {
            required: true,
            message: this.$t("messages.passwordEmpty2"),
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("messages.verificationCodeEmpty"),
            trigger: "change",
          },
        ],
      },
      CNActive: true,
      ENActive: false,
      passStrong: false,
    };
  },
  mounted() {
    bus.$on("loginFailed", (status) => {
      this.loginLoading = false;
    });
  },
  created() {
    if (localStorage.getItem("lang")) {
      window.lang = localStorage.getItem("lang");
      this.$i18n.locale = window.lang;
    }
    this.rules.username[0].message = this.$t("messages.userNameEmpty");
    this.rules.password[0].message = this.$t("messages.passwordEmpty");
    this.rules.password[1].message = this.$t("messages.passwordlength");
    this.codeStatus = this.$t("messages.getVerificationCode");
    this.rrules.tel[0].message = this.$t("messages.getVerificationCode");
    this.rrules.tel[1].message = this.$t("messages.phoneNumberlength");
    this.rrules.password[0].message = this.$t("messages.passwordEmpty");
    this.rrules.password[1].message = this.$t("messages.passwordlength");
    this.rrules.repassword[0].message = this.$t("messages.passwordEmpty2");
    this.rrules.code[0].message = this.$t("messages.verificationCodeEmpty");
    this.frules.tel[0].message = this.$t("messages.getVerificationCode");
    this.frules.tel[1].message = this.$t("messages.phoneNumberlength");
    this.frules.password[0].message = this.$t("messages.passwordEmpty");
    this.frules.password[1].message = this.$t("messages.passwordlength");
    this.frules.repassword[0].message = this.$t("messages.passwordEmpty2");
    this.frules.code[0].message = this.$t("messages.verificationCodeEmpty");
    this.width = getSuitSize().width;
    this.height = getSuitSize().height;
    if (window.lang === "zh") {
      this.ENActive = false;
      this.CNActive = true;
    } else {
      this.ENActive = true;
      this.CNActive = false;
    }
    pingCookie()
    getUserInfo_api();
  },
  methods: {
    handleClose(done) {
      done();
    },
    onEnterPress() {
      this.submitForm();
    },
    checkEmail(val) {
      let regNum = /^\w+@[a-z0-9]+\.[a-z]+$/i;
      if (regNum.test(val)) {
        return true;
      }
      return false;
    },
    submitForm() {
      this.loginLoading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          login_api(
            {
              username: this.form.username,
              password: Md5(this.form.password),
              imageCode: this.form.imageCode,
              sign: "qcloud_pc_tel_pwd_login_submit",
            },
            {
              "Origin-Language": window.lang,
            }
          ).then((res) => {
            if (res.success) {
              getUserInfo_api().then((res2) => {
                localStorage.setItem("userId", res2.data.user_id);
                this.$store.commit("setUserName", res2.data.display_name);
                localStorage.setItem("userName", res2.data.display_name);
              });
              localStorage.setItem("user", this.form.username);
              this.$message.success(res.message);
              this.getProjectData();
              this.$store.state.token = res.obj.token;
              this.$cookie.set("domain", ".originqc.com.cn");
              let time = parseInt(new Date().getTime() / 1000); //NOSONAR
              getMinioInfo_api({}).then((res3) => {
                getMinioInfo_api({})
                  .then((res3) => {
                    localStorage.setItem("apiKey", res3.data.apiKey);
                    localStorage.setItem("bucket_name", res3.data.bucketName);
                    localStorage.setItem("minio_name", res3.data.userName);
                  })
                  .catch((err) => {});
              });
              saveUserCookie().then((res) => {
                if (res.code == 200000) {
                  localStorage.setItem("qid", res.data.cookie);
                }
              });
              let timer = setTimeout(() => {
                //NOSONAR
                this.$router.push("./home");
                this.loginLoading = false;
              }, 1000);
            } else if (res.code === 505) {
              this.changeImage();
              this.needImageCode = true;
            } else {
              if (this.needImageCode) {
                this.changeImage();
              }
              this.$message.error(res.message);
            }
            this.loginLoading = false;
          });
        } else {
          this.loginLoading = false;
          return false;
        }
      });
    },
    async getProjectData() {
      //打开列表
      this.$bus.$emit("openList", false);
      this.$bus.$emit("showFiles", true);
    },
    registForm() {
      if (!this.passStrong) return;
      this.$refs.rform.validate((valid) => {
        if (valid) {
          if (this.rform.password !== this.rform.repassword) {
            this.$message.error(this.$t("messages.twoPasswordsInconsistent"));
            return;
          }
          let parmas = {
            tel: this.rform.tel,
            password: Md5(this.rform.password),
            repeat_password: Md5(this.rform.repassword),
            code: this.rform.code,
          };
          let headers = {
            "Origin-Language": window.lang,
          };
          if (this.checkEmail(this.rform.tel)) {
            let params = {
              email: this.rform.tel,
              password: Md5(this.rform.password),
              repeat_password: Md5(this.rform.repassword),
              code: this.rform.code,
              sign: "qcloud_pc_register_email_getcode",
            };
            registEmail(params, headers).then((res) => {
              if (res.success) {
                this.$message.success(this.$t("messages.registerSuccessful"));
                this.rform = {
                  username: "",
                  password: "",
                  repassword: "",
                  email: "",
                };
                setTimeout(() => {
                  location.reload();
                }, 500);
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            registTel(parmas, headers).then((res) => {
              if (res.success) {
                this.$message.success(this.$t("messages.registerSuccessful"));
                this.rform = {
                  username: "",
                  password: "",
                  repassword: "",
                  email: "",
                };
                setTimeout(() => {
                  location.reload();
                }, 500);
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    getCode() {
      if (this.rform.tel) {
        let params = {
          tel: this.rform.tel,
          type: "register",
        };
        let headers = {
          "Origin-Language": window.lang,
        };
        if (this.checkEmail(this.rform.tel)) {
          let params = {
            email: this.rform.tel,
            type: 1,
            sign: "qcloud_pc_register_email_getcode",
          };
          getEMailCode(params, headers).then((res) => {
            if (res.success) {
              this.codeDisable.disabled = true;
              this.setCode();
              this.$message.success(this.$t("messages.verificationCodeSent"));
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          getTelCode(params).then((res) => {
            if (res.success) {
              this.codeDisable.disabled = true;
              this.setCode();
              this.$message.success(this.$t("messages.verificationCodeSent"));
            } else {
              this.$message.error(res.message);
            }
          });
        }
      }
    },
    getCodeForget() {
      let params = {
        tel: this.fform.tel,
        type: "forgetPwd",
        sign: "qcloud_pc_resetPwd_tel_getcode",
      };
      let headers = {
        "Origin-Language": window.lang,
      };
      if (this.checkEmail(this.fform.tel)) {
        let params = {
          email: this.fform.tel,
          type: "3",
          sign: "qcloud_pc_resetPwd_email_getcode",
        };
        getEMailCode(params, headers).then((res) => {
          if (res.success) {
            this.codeDisable.disabled = true;
            this.codeDisable.type = "info";
            this.setCode();
            this.$message.success(this.$t("messages.verificationCodeSent"));
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        getTelCode(params).then((res) => {
          if (res.success) {
            this.codeDisable.disabled = true;
            this.codeDisable.type = "info";
            this.setCode();
            this.$message.success(this.$t("messages.verificationCodeSent"));
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    submitReset() {
      if (!this.passStrong) return;
      this.$refs.fform.validate(async (valid) => {
        if (valid) {
          let params = {
            username: this.fform.tel,
            newPwd: Md5(this.fform.password),
            confirmPwd: Md5(this.fform.repassword),
            code: this.fform.code,
            type: this.checkEmail(this.fform.tel) ? 2 : 1,
            sign: this.checkEmail(this.fform.tel)
              ? "qcloud_pc_resetPwd_email_getcode"
              : "qcloud_pc_resetPwd_tel_getcode",
          };
          let headers = {
            "Origin-Language": window.lang,
          };
          let res = await patchChangePassword(params, headers);
          if (res.success) {
            this.$message.success(res.message);
            this.resetForm("fform");
            setTimeout(() => {
              localStorage.clear();
              this.$router.push("/login");
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        } else {
          return false;
        }
      });
    },
    setCode() {
      let _that = this;
      function doLoop() {
        if (_that.nums > 0) {
          _that.codeStatus = _that.nums + " s";
        } else {
          clearInterval(_that.clock);
          _that.nums = 60;
          _that.codeStatus = _that.$t("messages.getVerificationCode");
          _that.codeDisable.type = "success";
          _that.codeDisable.disabled = false;
        }
      }
      _that.clock = setInterval(function () {
        _that.nums--;
        doLoop();
      }, 1000);
    },
    showPass() {
      if (this.passw == "text") {
        this.passw = "password";
        //更换图标
        this.icon = checkPass;
      } else {
        this.passw = "text";
        this.icon = hidePass;
      }
    },
    phoneChange(e) {
      const tel = this.rform.tel;
      const pattern = new RegExp("^1[345789][0-9]{9}$", "i"); //NOSONAR
      if (!tel) {
        return;
      }
      if (!pattern.test(tel)) {
        this.$message.error(this.$t("messages.enterValidPhoneNumber"));
        this.rform.tel = "";
      }
    },
    changeImage() {
      if (!this.form.username) {
        return;
      }
      getImageCode({ username: this.form.username }).then((res) => {
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = () => {
          this.imgCodeUrl = reader.result;
        };
      });
    },
    toLogin() {
      this.isLogin = true;
      this.register = false;
      this.forgetPas = false;
      this.$refs["fform"].resetFields();
      this.$refs["rform"].resetFields();
    },
    toRegist() {
      this.isLogin = false;
      this.register = true;
      this.forgetPas = false;
      this.$refs["fform"].resetFields();
      this.$refs["form"].resetFields();
    },
    toForgetPas() {
      this.isLogin = false;
      this.register = false;
      this.forgetPas = true;
      this.$refs["rform"].resetFields();
      this.$refs["form"].resetFields();
    },
    changeLangCN() {
      window.location.reload();
      this.ENActive = false;
      this.CNActive = true;
      window.lang = "zh";
      this.$i18n.locale = window.lang;
      this.$bus.$emit("changeLang", "zh");
      localStorage.setItem("lang", "zh");
    },
    changeLangEN() {
      window.location.reload();
      this.ENActive = true;
      this.CNActive = false;
      window.lang = "en";
      this.$i18n.locale = window.lang;
      this.$bus.$emit("changeLang", "en");
      localStorage.setItem("lang", "en");
    },
    async checkPassStrong(form, val, index) {
      let params = {
        password: val,
      };
      try {
        let res = await checkpwd_api(params);
        if (res.success) {
          this.passStrong = true;
        } else {
          this.passStrong = false;
          this.$refs[form].fields[index].validateMessage = res.message;
          this.$refs[form].fields[index].validateState = "error";
        }
      } catch (e) {}
    },
  },
};
</script>
<style lang="scss">
.container {
  background: url("../../assets/denglu-beijing.png");
  background-size: cover;
}
.app-logo {
  position: absolute;
  width: 341px;
  height: 46px;
  left: 40px;
  top: -70px;
}
.changeLang {
  position: absolute;
  right: 40px;
  top: 50px;
  color: #fff;
  cursor: pointer;
}
.login-head-lang-line {
  margin: 0 14px;
}
.window-logo {
  margin-top: -45px;
  width: 120px;
  height: 25px;
}
.loginContainer {
  position: absolute;
  left: 37%;
  top: 20%;
  width: 462px;
  height: auto;
  z-index: 1;
  box-shadow: 2px 2px 10px 5px rgb(38, 73, 117);
  border-radius: 8px;
  .el-textarea__inner,
  .el-input__inner {
    background: transparent !important;
    color: #fff !important;
    border: 1px solid #1757a4 !important;
    border-radius: 8px !important;
  }
  .el-input__inner::placeholder {
    color: #6b7c90;
    font-weight: 400;
  }
  /* 谷歌 */
  .el-input__inner::-webkit-input-placeholder {
    color: #6b7c90;
    font-weight: 400;
  }
  /* 火狐 */
  .el-input__inner:-moz-placeholder {
    color: #6b7c90;
    font-weight: 400;
  }
  /*ie*/
  .el-input__inner:-ms-input-placeholder {
    color: #6b7c90;
    font-weight: 400;
  }
}
.login-logo {
  width: 100%;
  max-height: 31px;
  display: inline;
}
.loginHead {
  color: #fff;
  text-align: center;
  padding: 20px;
}
#object {
  padding: 20px;
  height: 100px;
}
.fffcolor {
  color: #fff !important;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.active_login {
  font-weight: bold;
}
.deactive_login {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.3);
}
.tips {
  color: #fff;
  font-size: 12px;
  text-align: center;
  position: relative;
  top: -40px;
}
</style>
