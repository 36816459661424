<template>
  <div class="deleteWindow">
    <el-dialog :modal="false" :title="$t('messages.Warning')" :visible.sync="delDialogVisible"
      :close-on-click-modal="false" :before-close="delDialogClose">
      <div class="info">
        <i style="width:22px;height:22px;padding-right:10px;color:#EC3838;font-size:22px"
          class="iconfont icon-jinggao"></i>
        <span>{{$t('messages.powerTips_22')}}</span><br />
      </div>
      <span class="deleteData">{{deleteData}}</span>
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="userKnowChecked" @change="userKnow">{{$t('messages.powerTips_13')}}</el-checkbox>
        <div>
          <el-button type="primary" class="deactivate" ref="sureBtn" @click="delDialogSureButton" disabled
            size="mini">{{$t("messages.sure")}}</el-button>
          <el-button @click="delDialogCancel" size="mini">{{$t("messages.cancel")}}</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/components/common/bus";
import {
  disableBtn,
  activateBtn,
  getParentNodeFn,
  getCellIndexData,
  batchGetCellFromTableByFileId,
  deleteCellFn,
} from "./function/fileListPublicFn.js";
import {
  deleteCell,
  getFileList,
  getCellList,
  setFileParams,
} from "./function/fileListApiFn";
export default {
  data() {
    return {
      delDialogVisible: false, //删除页面显示
      userKnowChecked: false, //用户已知
      deleteData: "", //删除提示信息
      delRouteArr: [], //删除包括的路由
      delSnowIdArr: [], //删除文件列表历史记录包括的snow_id
      delFileIdArr: [], //删除文件时包含的文件的snow_id
      currentNode: {},
      topNode: {},
      projectNode: {},
      fileNode: {},
      cellNode: {},
    };
  },

  mounted() {
    bus.$on("contextMenuDelete", (data) => {
      this.delDialogVisible = true;
      this.currentNode = this.$store.getters.getCurrentNode;
      let nodeObj = getParentNodeFn(this.currentNode);
      this.topNode = nodeObj.topNode;
      this.projectNode = nodeObj.projectNode;
      this.fileNode = nodeObj.fileNode;
      this.cellNode = nodeObj.cellNode;
    });
  },
  methods: {
    delDialogCancel() {
      this.resetDelDialog();
    },
    delDialogClose() {
      this.resetDelDialog();
    },
    resetDelDialog() {
      this.userKnowChecked = false;
      this.delDialogVisible = false;
      this.currentNode = {};
      this.topNode = {};
      this.projectNode = {};
      this.fileNode = {};
      this.cellNode = {};
      this.delRouteArr = [];
      this.delSnowIdArr = [];
      this.deleteData = "";
    },
    async delDialogSureButton() {
      bus.$emit("loading", true);
      let file_category_child = "";
      let cell_category_child = "";
      if (this.currentNode.data.isTeam) {
        file_category_child = "团队文件";
        cell_category_child = "团队cell";
      } else {
        file_category_child = "个人文件";
        cell_category_child = "个人cell";
      }
      this.delDialogVisible = false;
      this.delRouteArr.splice(0, this.delRouteArr.length);
      this.delSnowIdArr.splice(0, this.delSnowIdArr.length);
      if (this.currentNode.level === 4) {
        let route = `/home/layout/board?id=${this.fileNode.data.snow_id}/${this.cellNode.data.snow_id}`;
        this.delRouteArr.push(route);
        this.delSnowIdArr.push(this.currentNode.data.snow_id);
        bus.$emit("deleteCell", this.cellNode.data.name);
      } else if (this.currentNode.level === 3) {
        this.delSnowIdArr.push(this.currentNode.data.snow_id);
        //获取文件子节点数据
        let res = await getCellList(
          0,
          500,
          this.fileNode.data.gns,
          cell_category_child
        );
        if (res.code === 200000) {
          res.data.data.forEach((item) => {
            this.delSnowIdArr.push(item._source.snow_id);
            let route = `/home/layout/board?id=${this.fileNode.data.snow_id}/${item._source.snow_id}`;
            this.delRouteArr.push(route);
          });
        }
      } else if (this.currentNode.level === 2) {
        this.delSnowIdArr.push(this.currentNode.data.snow_id);
        let res = await getFileList(
          0,
          500,
          this.projectNode.data.gns,
          file_category_child
        );
        if (res.code === 200000) {
          for (let i = 0; i < res.data.data.length; i++) {
            this.delSnowIdArr.push(res.data.data[i]._source.snow_id);
            this.delFileIdArr.push(res.data.data[i]._source.snow_id);
            let res2 = await getCellList(
              0,
              500,
              res.data.data[i]._source.gns,
              cell_category_child
            );
            if (res2.code === 200000) {
              for (let j = 0; j < res2.data.data.length; j++) {
                this.delSnowIdArr.push(res2.data.data[j]._source.snow_id);
                let route = `/home/layout/board?id=${res.data.data[i]._source.snow_id}/${res2.data.data[j]._source.snow_id}`;
                this.delRouteArr.push(route);
              }
            }
          }
        }
      }

      this.useDeleteApi();
    },

    async useDeleteApi() {
      let res = await deleteCell(
        this.currentNode.data.gns,
        this.currentNode.data.category
      );
      if (res.code === 200000) {
        this.$store.commit("removeTab", this.delRouteArr);
        let refreshCellList = [];
        if (this.currentNode.level === 2) {
          let projectId = this.currentNode.data.snow_id;
          this.$store.state.cellTable = this.$store.state.cellTable.filter(
            (item) => item.projectId !== projectId
          );
        } else if (this.currentNode.level === 3) {
          let fileId = this.currentNode.data.snow_id;
          this.$store.state.cellTable = this.$store.state.cellTable.filter(
            (item) => item.fileId !== fileId
          );
        } else if (this.currentNode.level === 4) {
          //删除单元数据,需要改写索引文件
          // 获取索引数据
          // let fileId = this.currentNode.parent.data.snow_id
          // let cellId = this.currentNode.data.snow_id
          // let cellIndexData = await getCellIndexData(fileId)
          // let cellIndex = cellIndexData.findIndex(item => item.snow_id === cellId)
          // cellIndexData.splice(cellIndex, 1)
          // for (let i = 0; i < cellIndexData.length; i++) {
          //   let index = cellIndexData[i].cells.findIndex(item => item.snow_id === cellId)
          //   if (index !== -1) {
          //     cellIndexData[i].cells.splice(index, 1)
          //   }
          // }

          // await setFileParams(fileId, cellIndexData)

          // //删除cell表中的缓存数据
          // this.$store.commit('removeFromCellTable', cellId)
          // //删除单元的同时,删除已有缓存单元中对应引用的数据
          // let cellObjList = batchGetCellFromTableByFileId(fileId)

          // //如果是版图文件中的删除,需要将对应ref重新指向null
          // if (this.currentNode.parent.data.file_type === 1) {
          //   let cellList = cellObjList.map(item => item.cell)

          //   this.redirectRef(cellList, cellId)
          //   refreshCellList = cellList
          // }
          // //如果是原理图文件中的删除
          // else {
          //   //删除原理图,查找封装符号
          //   if (this.currentNode.data.cell_type === 3) {
          //     let capSymbolObjList = cellObjList.filter(item => item.cell.type.value === 2)

          //     let capSymbolList = capSymbolObjList.map(item => item.cell)

          //     this.redirectSymbol(capSymbolList, cellId)
          //     refreshCellList = capSymbolList
          //   }
          //   // 删除符号/封装符号,查找原理图
          //   else {
          //     let schemaObjList = cellObjList.filter(item => item.cell.type.value === 3)

          //     let schemaList = schemaObjList.map(item => item.cell)

          //     this.redirectSchema(schemaList, cellId)
          //     refreshCellList = schemaList
          //   }

          // }
          refreshCellList = await deleteCellFn(this.currentNode);
        }
        let delInfo = {
          delNode: this.currentNode,
          refreshCellList,
        };
        // 更新历史记录
        bus.$emit("updateHistory", "all");
        bus.$emit("deleteNode", delInfo);
        this.resetDelDialog();
        bus.$emit("ending", true);
        this.$message.success(this.$t("messages.deleteSuccess"));
      }
    },

    // //cell中重定向第一层ref中的指向
    // redirectRef(cellList, delCellName) {
    //   let cellLength = cellList.length
    //   for (let i = 0; i < cellLength; i++) {
    //     let referens = cellList[i].referens
    //     let refLength = referens.length
    //     for (let j = 0; j < refLength; j++) {
    //       if (referens[j].cell == null) {
    //         continue
    //       } else if (referens[j].name === delCellName) {

    //         referens[j].cell = null
    //       }
    //     }
    //   }
    //   bus.$emit("reRenderReplaceBoard")
    // },

    //cell中重定向第一层ref中的指向
    // redirectRef(cellList, delCellId) {
    //   let cellLength = cellList.length
    //   for (let i = 0; i < cellLength; i++) {
    //     let referens = cellList[i].referens
    //     let refLength = referens.length
    //     for (let j = 0; j < refLength; j++) {

    //       if (referens[j].cell == null) {
    //         continue
    //       } else if (referens[j].cell_snow_id === delCellId) {

    //         // referens[j].cell = null
    //         cellList[i].remove_reference(referens[j])
    //       }
    //     }
    //   }
    //   bus.$emit("reRenderReplaceBoard")
    // },

    // //删除原理图重定向capsymbol中的schema
    // redirectSymbol(capSymbolList, delSchemaId) {
    //   let capLength = capSymbolList.length
    //   for (let i = 0; i < capLength; i++) {

    //     if (capSymbolList[i].schema == null) {
    //       continue
    //     } else if (capSymbolList[i].schema.snow_id === delSchemaId) {
    //       capSymbolList[i].schema.delete_logic()
    //     }
    //   }
    //   bus.$emit("reRenderReplaceBoard")
    // },

    // //删除符号封装符号重定向schema的ins的symbol
    // redirectSchema(schemaList, delSymbolId) {

    //   let schemaLength = schemaList.length
    //   for (let i = 0; i < schemaLength; i++) {
    //     let ins = [...schemaList[i].symbol_ins, ...schemaList[i].capsymbol_ins]

    //     let insLength = ins.length
    //     for (let j = 0; j < insLength; j++) {
    //       if (ins[j].symbol == null) {
    //         continue
    //       } else if (ins[j].symbol.snow_id === delSymbolId) {
    //         ins[j].symbol.delete_logic()
    //       }
    //     }
    //   }
    //   bus.$emit("reRenderReplaceBoard")
    // },

    //获取当前已打开画板属于那些文件
    // getFileIdOfDrawBoard() {
    //   let allFileIdArr = []
    //   this.$store.state.openedTab.forEach(item => {
    //     allFileIdArr.push(item.route.split('/')[item.route.split('/').length - 2].split('=')[1])
    //   })
    //   return [...new Set(allFileIdArr)]
    // },

    userKnow() {
      if (!this.userKnowChecked) {
        disableBtn(this.$refs.sureBtn);
      } else {
        activateBtn(this.$refs.sureBtn);
      }
    },
  },
  beforeDestroy() {
    bus.$off("contextMenuDelete");
  },
  watch: {
    userKnowChecked() {
      this.userKnow();
    },
  },
};
</script>

<style lang="less" scoped>
.deleteWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 249px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 90px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  .deleteData {
    padding-left: 32px;
    color: #0c7de6;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>