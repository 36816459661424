<template>
  <div>
    <!-- 存到符号库弹窗 -->
    <div class="saveToSymbolLibraryDialog">
      <el-dialog :modal="false" :title="$t('messages.saveToSymbolLibrary')"
        :visible.sync="saveToSymbolLibraryDialogVisible" :close-on-click-modal="false"
        :before-close="saveToLibraryDialogClose">
        <el-form :model="saveToSymbolLibraryForm" :rules="rules" ref="saveToSymbolLibraryForm" class="demo-ruleForm">
          <div class="inputArea">
            <span class="inputInfo">{{$t('messages.Group')}}:</span>
            <el-form-item prop="form_group">
              <el-select v-model="saveToSymbolLibraryForm.form_group" filterable
                :placeholder="$t('messages.placeholder_0')" @change="groupChange">
                <el-option v-for="item in options_group" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="inputArea" v-if="saveToSymbolLibraryForm.form_group==$t('messages.TeamSymbolLibrary')">
            <span class="inputInfo">{{$t('messages.teamSelected')}}:</span>
            <el-select v-model="saveToSymbolLibraryForm.form_teamGroup" filterable
              :placeholder="$t('messages.placeholder_0')" @change="groupSelectTeam">
              <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name" :value="item.snow_id">
              </el-option>
            </el-select>
          </div>

          <div class="inputArea" v-if="saveToSymbolLibraryForm.form_group==$t('messages.PersonalSymbolLibrary')">
            <span class="inputInfo">{{ $t('messages.select') }}:</span>
            <el-select v-model="saveToSymbolLibraryForm.form_userGroup" filterable
              :placeholder="$t('messages.placeholder_0')" @change="groupSelectUser">
              <el-option v-for="item in options_userGroup" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="inputArea">
            <span class="inputInfo">{{$t('messages.name')}}:</span>
            <el-form-item prop="form_symbolName">
              <el-input v-model="saveToSymbolLibraryForm.form_symbolName" :placeholder="$t('messages.placeholder_1')"
                :maxlength="50"></el-input>
            </el-form-item>
            <!-- <el-input v-model="saveToSymbolLibraryForm.form_deviceName" placeholder="请输入器件名称"></el-input> -->
          </div>
          <div class="labelSelect">
            <span class="inputInfo">{{$t('messages.Label')}}:</span>
            <el-form-item prop="form_symbolLabel">
              <el-select v-model="saveToSymbolLibraryForm.form_symbolLabel" multiple :multiple-limit="15" filterable
                allow-create default-first-option :placeholder="$t('messages.placeholder_0')">
                <el-option v-for="item in options_symbolCategory" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="textAreaSelect">
            <span class="inputInfo">{{$t('messages.note')}}:</span>
            <el-form-item prop="form_notes">
              <el-input type="textarea" v-model="saveToSymbolLibraryForm.form_notes" autosize maxlength="128"
                show-word-limit>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="deactivate" ref="saveToLibrarySureBtn" @click="saveToLibrarySureButton"
            size="mini" disabled>{{$t("messages.sure")}}</el-button>
          <el-button @click="saveToLibraryCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 存到符号库覆盖提醒 -->
    <div class="errWindow">
      <el-dialog :modal="false" :title="$t('messages.coverWarning')" :visible.sync="coverDialogVisible"
        :close-on-click-modal="false">
        <div class="info">
          <i style="width:22px;height:22px;padding-right:10px;color:#EC3838;font-size:22px"
            class='iconfont icon-jinggao'></i>
          <span>{{$t('messages.powerTips_26')}}</span><br />
        </div>
        <span slot="footer" class="dialog-footer">
          <div>
            <el-button type="primary" @click="coverDialogSureBtn" size="mini">{{$t("messages.sure")}}</el-button>
            <el-button @click="coverDialogCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Md5 from "js-md5";
import bus from "@/components/common/bus";
import {
  saveMySymbol_api, //保存个人符号
  saveTeamSymbol_api, //保存团队符号
  savePublicSymbol_api, //保存公用符号//NOSONAR
  saveDiagramFile_api,
} from "@/api/objectStorage/objectStorage.js";
import {
  getOSCompleteCellData,
  getCellIndexData,
  copyComponentCellFn,
  binaryDataToUTF8,
  mergeU8Array,
} from "./function/fileListPublicFn.js";
import {
  getUserSymbolLibInfo,
  saveToUserSymbolLib,
  saveToTeamSymbolLib,
} from "./function/fileListApiFn.js";
export default {
  data() {
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        //NOSONAR
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    let labelNameRule = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error(this.$t("messages.fileTip4")));
      } else {
        let show = true;
        for (let i in value) {
          let tempValue = [];
          if (value[i].name == undefined) {
            tempValue = value[i]; //NOSONAR
          } else {
            tempValue = value[i].name; //NOSONAR
          }
          let len = 0;
          for (let j = 0; j < tempValue.length; j++) {
            // //单字节加1
            len++;
          }
          if (len > 20) {
            show = false;
          }
        }
        if (!show) {
          callback(new Error(this.$t("messages.fileTip3")));
        } else {
          callback();
        }
      }
    };
    return {
      saveToSymbolLibraryDialogVisible: false,
      options_group: [
        {
          value: this.$t("messages.PersonalSymbolLibrary"),
          label: this.$t("messages.PersonalSymbolLibrary"),
        },
        {
          value: this.$t("messages.TeamSymbolLibrary"),
          label: this.$t("messages.TeamSymbolLibrary"),
        },
      ], //组别数据
      options_teamName: [], //团队列表
      options_userGroup: [], //个人库列表
      teamId: "",
      library_snow_id: "",
      options_symbolCategory: [
        {
          value: this.$t("messages.Superconductivity"),
        },
        {
          value: this.$t("messages.semiconductor"),
        },
        {
          value: this.$t("messages.resonators"),
        },
      ], //组别数据
      saveToSymbolLibraryForm: {
        form_group: this.$t("messages.PersonalSymbolLibrary"), //组别
        form_userGroup: "", //个人库选择
        form_teamGroup: "", //团队选择
        form_symbolName: "", //名称
        form_symbolLabel: [], //标签
        form_notes: "", //注释
      },
      rules: {
        form_symbolName: [{ validator: fileNameRule, trigger: "blur" }],
        form_symbolLabel: [
          { validator: labelNameRule, trigger: "change", type: "array" },
        ],
      },
      node: "", //当前节点
      debounce: true, //防抖
      coverDialogVisible: false,
      coverFormData: null,
      fileNode: {}, //当前节点的文件节点
      projectNode: {}, //当前节点的项目节点
    };
  },
  mounted() {
    //通知准备存到符号库
    bus.$on("contextMenuSaveToSymbolLib", async (data) => {
      this.saveToSymbolLibraryDialogVisible = true;
      let currentNode = this.$store.getters.getCurrentNode;

      this.node = currentNode;
      this.fileNode = currentNode.parent;
      this.projectNode = currentNode.parent.parent;
      this.options_teamName = this.$store.state.teamNameList;
      this.options_userGroup.splice(0, this.options_userGroup.length);
      let res = await getUserSymbolLibInfo();
      if (res.code === 200000) {
        res.data.data.forEach((item) => {
          this.options_userGroup.push({
            value: item.snow_id,
            snow_id: item.snow_id,
            label: item.name,
          });
        });
      }
    });

    bus.$on("changeLang", (data) => {
      this.options_group = [
        {
          value: this.$t("messages.PersonalSymbolLibrary"),
          label: this.$t("messages.PersonalSymbolLibrary"),
        },
        {
          value: this.$t("messages.TeamSymbolLibrary"),
          label: this.$t("messages.TeamSymbolLibrary"),
        },
      ];
      this.options_symbolCategory = [
        {
          value: this.$t("messages.Superconductivity"),
        },
        {
          value: this.$t("messages.semiconductor"),
        },
        {
          value: this.$t("messages.resonators"),
        },
      ];
    });
  },
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    //所有输入框全部填写才能激活确定
    saveToLibraryDialogBtnActive() {
      if (
        this.saveToSymbolLibraryForm.form_group !== "" &&
        this.saveToSymbolLibraryForm.form_symbolName !== "" &&
        this.saveToSymbolLibraryForm.form_symbolLabel.length > 0
      ) {
        if (
          this.saveToSymbolLibraryForm.form_group ==
          this.$t("messages.TeamSymbolLibrary")
        ) {
          if (this.saveToSymbolLibraryForm.form_teamGroup !== "") {
            this.activateBtn(this.$refs.saveToLibrarySureBtn);
          } else {
            this.disableBtn(this.$refs.saveToLibrarySureBtn);
          }
        } else {
          this.activateBtn(this.$refs.saveToLibrarySureBtn);
        }
      } else {
        this.disableBtn(this.$refs.saveToLibrarySureBtn);
      }
    },
    saveToLibraryDialogClose() {
      this.saveToSymbolLibraryDialogVisible = false;
    },
    groupChange(data) {},
    groupSelectTeam(data) {
      this.options_teamName.forEach((item) => {
        if (item.snow_id == data) {
          this.teamId = item.snow_id;
        }
      });
    },
    groupSelectUser(data) {
      this.options_userGroup.forEach((item) => {
        if (item.snow_id == data) {
          this.library_snow_id = item.snow_id;
        }
      });
    },
    saveToLibrarySureButton() {
      if (this.debounce) {
        this.$refs.saveToSymbolLibraryForm.validate(async (valid) => {
          if (valid) {
            bus.$emit("loading", true);
            this.debounce = false;
            this.saveToSymbolLibraryDialogVisible = false;

            let projectId = this.projectNode.data.snow_id;
            let fileId = this.fileNode.data.snow_id;
            let cellId = this.node.data.snow_id;
            // 先获取对象存储中的符号数据
            let saveSymbol = await getOSCompleteCellData(
              projectId,
              fileId,
              cellId,
              false
            );

            if (saveSymbol.type.value === 1) {
              saveSymbol = saveSymbol.deep_copy(0);
            } else if (saveSymbol.type.value === 2) {
              saveSymbol = copyComponentCellFn(saveSymbol);
            }

            saveSymbol.name = this.saveToSymbolLibraryForm.form_symbolName;
            saveSymbol.short_name =
              this.saveToSymbolLibraryForm.form_symbolName;

            let binaryDataObj = {};
            let cellBinaryDataObj = {};
            let associatedCellList = [];
            if (saveSymbol.type.value === 2) {
              associatedCellList = saveSymbol.depend_symbol_schemas(-1);
            }
            associatedCellList.push(saveSymbol);

            for (let i = 0; i < associatedCellList.length; i++) {
              cellBinaryDataObj = associatedCellList[i].dump_file(true, 0);
              binaryDataObj = { ...binaryDataObj, ...cellBinaryDataObj };
            }

            let cellInfoList = binaryDataToUTF8(binaryDataObj);
            let u8ArrayList = [];
            cellInfoList.forEach((item) => {
              u8ArrayList.push(item.data_u8);
            });
            let u8Array = mergeU8Array(u8ArrayList);

            let label_list =
              this.saveToSymbolLibraryForm.form_symbolLabel.join(",");
            let formData = new FormData();
            formData.append(
              "name",
              this.saveToSymbolLibraryForm.form_symbolName
            );
            formData.append("label_list", label_list);
            formData.append("notes", this.saveToSymbolLibraryForm.form_notes);
            formData.append("is_cover", 0);
            formData.append("content", new Blob([u8Array]));
            formData.append("symbol_type", "1");
            let res = {};
            if (
              this.saveToSymbolLibraryForm.form_group ==
              this.$t("messages.PersonalSymbolLibrary")
            ) {
              formData.append("library_snow_id", this.library_snow_id);
              res = await saveMySymbol_api(formData);
            } else if (
              this.saveToSymbolLibraryForm.form_group ==
              this.$t("messages.TeamSymbolLibrary")
            ) {
              formData.append("team_id", this.teamId);
              res = await saveTeamSymbol_api(formData);
            }
            if (res.code === 200000) {
              bus.$emit("ending", true);
              if (
                res.data.hasOwnProperty("name_exists") &&
                res.data.name_exists.length
              ) {
                this.coverFormData = formData;
                this.coverDialogVisible = true;
              } else {
                this.$message({
                  type: "success",
                  message: this.$t("messages.successSaveToSymbolLib"),
                });
                this.resetSaveToLibrary();
              }
            }
            // else if (res.code === 405336) {
            //   bus.$emit("ending", true);
            //   this.coverFormData = formData
            //   this.coverDialogVisible = true;
            // }
            else {
              bus.$emit("ending", true);
              bus.$emit("errDialog", res.message);
              this.resetSaveToLibrary();
            }
          }
        });
      }
      setTimeout(() => {
        this.debounce = true;
      }, 1000);
    },
    saveToLibraryCancelButton() {
      this.resetSaveToLibrary();
      this.saveToSymbolLibraryDialogVisible = false;
    },
    resetSaveToLibrary() {
      this.$refs.saveToSymbolLibraryForm.resetFields();
    },

    //覆盖确认
    async coverDialogSureBtn() {
      if (this.debounce) {
        this.debounce = false;
        this.coverDialogVisible = false;
        let formData = this.coverFormData;
        formData.set("is_cover", 1);
        if (
          this.saveToSymbolLibraryForm.form_group ==
          this.$t("messages.PersonalSymbolLibrary")
        ) {
          let res = await saveMySymbol_api(formData);
          if (res.code === 200000) {
            this.$message({
              type: "success",
              message: this.$t("messages.saveSuccess"),
            });
            this.resetSaveToLibrary();
          }
          // if (res.code === 200000) {
          //   if (res.data.name_exists.length) {

          //     this.coverFormData = formData
          //     this.coverDialogVisible = true;
          //   } else {
          //     this.$message({
          //       type: "success",
          //       message: this.$t('messages.saveSuccess'),
          //     });
          //     this.resetSaveToLibrary();
          //   }
          // }
        } else if (
          this.saveToSymbolLibraryForm.form_group ==
          this.$t("messages.TeamSymbolLibrary")
        ) {
          let res = await saveTeamSymbol_api(formData);
          if (res.code === 200000) {
            this.$message({
              type: "success",
              message: this.$t("messages.saveSuccess"),
            });
            this.resetSaveToLibrary();
          }
          // if (res.code === 200000) {
          // if (res.data.name_exists.length) {

          //   this.coverFormData = formData
          //   this.coverDialogVisible = true;
          // } else {
          //   this.$message({
          //     type: "success",
          //     message: this.$t('messages.saveSuccess'),
          //   });
          //   this.resetSaveToLibrary();
          // }
          // }
        }
      }
      setTimeout(() => {
        this.debounce = true;
      }, 1000);
    },
    //覆盖取消
    coverDialogCancelBtn() {
      this.coverDialogVisible = false;
    },
  },
  watch: {
    "saveToSymbolLibraryForm.form_group"() {
      this.saveToLibraryDialogBtnActive();
    },
    "saveToSymbolLibraryForm.form_symbolName"() {
      this.saveToLibraryDialogBtnActive();
    },
    "saveToSymbolLibraryForm.form_symbolLabel"() {
      this.saveToLibraryDialogBtnActive();
    },
  },
};
</script>
<style lang="less" scoped>
//存到器件库弹窗
.saveToSymbolLibraryDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 347px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 20px 20px 8px 20px;
    font-weight: 400;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 566px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 566px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
  }
  .labelSelect {
    position: relative;
    width: 100%;
    min-height: 30px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 566px;
      border-radius: 2px;
      .el-input__inner {
        height: 30px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .el-select__tags {
        .el-tag {
          background-color: #ffffff;
          color: #333;
        }
      }
    }
  }
  .textAreaSelect {
    position: relative;
    width: 100%;
    min-height: 30px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-textarea {
      width: 566px;
      .el-textarea__inner {
        background-color: #f4f4f4;
      }
      .el-input__count {
        background-color: #f4f4f4;
      }
    }
    /deep/.el-input__count {
      line-height: 24px;
    }
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//错误弹窗
.errWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 132px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>