<template>
  <div class="right" onselectstart="javascript:return false;">
    <div class="cardHeader" v-drag>
      <!-- 头部标题 -->
      <strong>自动工具</strong>

      <!-- +-号 -->
      <span v-if="cellBarSeen" style="float: right" class="el-icon-minus spanBtn" @click="setCellBar"></span>
      <span v-else style="float: right" class="el-icon-plus spanBtn" @click="setCellBar"></span>
    </div>

    <!-- 折叠面板主体 -->
    <el-collapse-transition>
      <el-tabs type="border-card" v-if="cellBarSeen">

        <!-- 操作 -->
        <el-tab-pane :label="$t('messages.operation')">
          <el-row :gutter="10" style="margin-top:10px;">
            <el-col :span="24">
              <div class="filter-container" style="margin:5px;">
                <span>SN:</span>
                <el-input class="filter-item" v-model="sn" controls-position="right" size="mini"
                  style="width:100px;margin:10px;"></el-input>
                <el-button class="filter-item" type='success' size="mini" @click="onRun('connect_mqtt')"> 连接mqtt
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:10px;">
            <el-col :span="24">
              <div class="filter-container" style="margin:5px;">
                <el-button class="filter-item" type='warning' size="mini" @click="onRun('load_gds')"> 加载gds </el-button>
                <el-button class="filter-item" type='success' size="mini" @click="onRun('export_gds')"> 导出gds
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:0px;">
            <el-col :span="24">
              <div class="filter-container" style="margin:5px;">
                <el-button class="filter-item" type='primary' size="mini" @click="onRun('check_line')"> 检测线 </el-button>
                <el-button class="filter-item" type='primary' size="mini" @click="onRun('wbpad')"> 放置WBPad </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:0px;">
            <el-col :span="24">
              <div class="filter-container" style="margin:5px;">
                <el-button class="filter-item" type='primary' size="mini" @click="onRun('inlinepad')"> 放置InLinePad
                </el-button>
                <el-button class="filter-item" type='primary' size="mini" @click="onRun('airbridge')"> 放置AirBridge
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:0px;">
            <el-col :span="24">
              <div class="filter-container" style="margin:5px;">
                <el-button class="filter-item" type='primary' size="mini" @click="onRun('tinpad')"> 放置TiNPad&Inpillar
                </el-button>
                <el-button class="filter-item" type='primary' size="mini" @click="onRun('wave_guide')"> 生成共面波导
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>

        <!-- 通用属性 -->
        <el-tab-pane label="报告">
        </el-tab-pane>
      </el-tabs>

    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sn: "d85ed312e62e",
      // sn: '00e18cc801d0',
      cellBarSeen: true,
    };
  },
  directives: {
    /*自定义拖拽*/
    drag: {
      inserted: function (el, binding, vnode) {//NOSONAR
        let odiv = el.parentNode; // 获取元素的父级 也就是这个移动的窗口
        el.onmousedown = function (eve) {
          eve = eve || window.event;//NOSONAR
          let mx = eve.pageX; // 鼠标点击时的x坐标位置
          let my = eve.pageY; // 鼠标点击时的y坐标位置
          let dleft = odiv.offsetLeft; // 窗口初始的位置
          let dtop = odiv.offsetTop;
          let clientWidth = document.documentElement.clientWidth; // 页面的宽
          let oWidth = odiv.clientWidth; // 窗口的宽度
          let maxX = clientWidth - oWidth; // x轴能移动的最大距离
          let clientHeight = document.documentElement.clientHeight; // 页面的高
          let oHeight = odiv.clientHeight; // 窗口的高度
          let maxY = clientHeight - oHeight; // y轴能移动的最大距离
          document.onmousemove = function (e) {
            e.preventDefault();
            let x = e.pageX; // 移动时鼠标X坐标
            let y = e.pageY; // 移动时鼠标Y坐标
            let left = x - mx + dleft; // 移动后对话框新的left值
            let Top = y - my + dtop; // 移动后对话框新的Top值
            if (left < 0) {
              left = 0;
            }
            if (left > maxX) {
              left = maxX;
            }
            if (Top < 0) {
              Top = 0;
            }
            if (Top > maxY) {
              Top = maxY;
            }
            odiv.style.left = left + "px";
            odiv.style.top = Top + "px";
            odiv.style.marginLeft = 0;
            odiv.style.marginTop = 0;
          };
          document.onmouseup = function () {
            document.onmouseup = "";
            document.onmousemove = "";
          };
        };
      },
    },
  },

  mounted() {},

  created() {},
  methods: {
    handleChange(val) {
      // 传入参数
      this.$bus.$emit("setGrid", this.form.grid);
      this.$bus.$emit("setAxis", this.form.axis);
    },
    onRun(code) {
      let data = {
        code: code,
        msg: this.sn,
      };
      this.$bus.$emit("AutoTool", data);
    },
    onSubmit() {},
    setCellBar() {
      this.cellBarSeen = !this.cellBarSeen;
    },
  },
};
</script>

<style lang="less" scoped>
.spanBtn {
  cursor: pointer;
}
.right {
  position: fixed;
  right: 0;
  top: 560px;
  z-index: 999;
  width: 280px;
  height: 300px;
  font-size: 12px;
  border-radius: 4px;
  overflow: hidden;
  .cardHeader {
    height: 40px;
    line-height: 40px;
    background: #eee;
    border-bottom: 1px solid #d1d1d1;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: move;
    strong {
      height: 100%;
      display: inline-block;
      margin-left: 20px;
    }
    .el-icon-plus:before,
    .el-icon-minus:before {
      height: 100%;
      display: inline-block;
      line-height: 40px;
      margin-right: 20px;
    }
  }

  .el-tabs {
    height: 100%;
    padding: 0 6px 6px;
    border: none;
    background-color: #e9e9e9;
    display: flex;
    flex-direction: column;
    /deep/.el-tabs__header {
      background-color: #e9e9e9;
      text-align: center;
    }
    /deep/.el-tabs__nav {
      width: 100%;
      display: flex;
    }
    /deep/ .el-tabs__item {
      background-color: #e9e9e9;
      border-color: #e9e9e9;
      padding: 0 10px !important;
      font-size: 12px;
      flex: 1;
    }

    /deep/.el-tabs__item:not(.is-disabled):hover {
      color: #005ce5;
    }
    /deep/ .el-tabs__item.is-active {
      background-color: #e9e9e9;
      border-color: #e9e9e9;
      color: #005ce5;
      font-weight: bold;
    }
    /deep/ .el-tabs__content {
      padding: 0;
      background-color: #ffffff;
      height: 95%;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.16);
      opacity: 1;
    }
    /deep/ .el-tab-pane {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .layerProperties {
      box-sizing: border-box;
      padding: 5px;
      flex: 2;
      border-bottom: 1px solid #707070;
      table {
        width: 200px;
        height: 40px;
        text-align: center;
        margin: 10px 39px 0 27px;
        th {
          font-weight: normal;
          text-align: center;
        }
      }
    }
    .layerEditing {
      box-sizing: border-box;
      padding: 5px;
      flex: 1;
      border-bottom: 1px solid #707070;

      button {
        margin: 8px 10px;
        width: 66px;
        height: 20px;
        border-radius: 4px;
        font-size: 12px;
        outline: none;
        cursor: pointer;
      }
      .newlayer {
        border: 1px solid #1296db;
        color: #1296db;
      }
      .editlayer {
        border: 1px solid #1c78a8;
        color: #1c78a8;
        background-color: #dbd9ff;
      }
      .dellayer {
        border: 1px solid #d56d6d;
        color: #d56d6d;
        background-color: #ffd1d1;
      }
    }
    .tree {
      flex: 3;
      box-sizing: border-box;
      padding: 5px 20px;
    }

    //器件属性
    .deviceProperties {
      /deep/.el-collapse-item__header {
        background-color: #eee;
        line-height: 20px;
        height: 20px;
        color: #949494;
        padding-left: 10px;
      }
      .el-form-item {
        width: 100%;
        margin: 0;
      }
      /deep/ .el-collapse-item__wrap {
        margin-top: 10px;
      }
      /deep/ .el-form-item__label {
        width: 40px !important;
        text-align: center;
        padding: 0;
        padding-left: 10px;
        font-size: 12px;
      }
      /deep/ .el-form-item__content {
        margin-left: 40px !important;
      }

      /deep/ .el-input--suffix {
        width: 200px;
      }
      /deep/ .el-input__inner {
        height: 25px;
        font-size: 12px;
      }
      /deep/ .el-collapse-item__wrap {
        border: none;
      }
      .el-input {
        font-size: 12px;
      }

      .applyBtn {
        background-color: #419fff;
        outline: none;
        border: none;
        color: #fff;
        padding: 10px 25px;
        border-radius: 5px;
        margin: 10px 0 0 30px;
        cursor: pointer;
      }
    }

    //通用属性
    .commonProperties {
      margin-top: 10px;
      color: #707070;
      font-weight: bold;
      .el-form-item {
        width: 265px;
        margin: 0;
      }
      /deep/ .el-form-item__label {
        width: 80px !important;
        text-align: left;
        padding: 0;
        padding-left: 20px;
        font-size: 12px;
      }

      /deep/ .el-form-item__content {
        margin-left: 120px !important;
      }

      /deep/.el-input {
        width: 110px;
      }
      /deep/ .el-input__inner {
        height: 25px;
        width: 110px;
        text-align: center;
        font-size: 12px;
      }
      /deep/ .zoomFactor .el-input__inner {
        width: 75px;
      }
      /deep/ .zoomFactor .el-button {
        width: 35px;
        padding: 5px 19px 5px 19px;
      }
      /deep/ .zoomFactor .el-input-group__append {
        padding: 0 10px;
        width: 35px;
      }
      /deep/ .el-input-group {
        vertical-align: baseline;
      }
      /deep/ .el-input__icon {
        line-height: 38px;
      }
      .coordinatePosition {
        margin-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: column;

        div {
          width: 100%;
          display: flex;
          align-items: center;
          span {
            width: 50%;
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>