import { getUserGnsPermission_api, getFileChannels_api } from '@/api/file/file.js'
import bus from '@/components/common/bus'
export const addLayerListMessageServe = function (node) {
  let url
  if (location.host.indexOf('localhost') === 0 || location.host.indexOf('192.168.10.19:8080') === 0) {
    //本地环境

    url = 'ws://' + location.host + '/socket/ws/chats/' + node.data.snow_id + '/'
  } else {
    url = 'wss://' + location.host + '/ws/chats/' + node.data.snow_id + '/'
  }
  let WS = new LayerListWebSocket(url, node)
  return WS
}

export class LayerListWebSocket {
  constructor(wsurl, node) {
    this.wsurl = wsurl
    this.ws = new WebSocket(wsurl)
    this.node = node
    this.ws.onopen = this.onopen.bind(this)
    this.ws.onmessage = this.onmessage.bind(this)
    this.ws.onclose = this.onclose.bind(this)
    this.ws.onerror = this.onerror.bind(this)
    this.lockReconnect = false //是否真正建立连接
    this.timeout = 58 * 1000 //58秒一次心跳
    this.timeoutObj = null //心跳心跳倒计时
    this.serverTimeoutObj = null //心跳倒计时
    this.timeoutnum = null //断开 重连倒计时
  }
  initWebpack() {
    this.ws = new WebSocket(this.wsurl)
    this.ws.onopen = this.onopen.bind(this)
    this.ws.onmessage = this.onmessage.bind(this)
    this.ws.onclose = this.onclose.bind(this)
    this.ws.onerror = this.onerror.bind(this)
  }
  onopen() {
    // this.ws.send('Hello Server!')
    this.start()
  }
  start() {
    //开启心跳

    this.timeoutObj && clearTimeout(this.timeoutObj)
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
    let that = this
    this.timeoutObj = setTimeout(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，

      if (that.ws.readyState === 1) {
        //如果连接正常
        // that.ws.send(JSON.stringify(heartbeat))
      } else {
        //否则重连
        that.reconnect()
      }
      that.serverTimeoutObj = setTimeout(function () {
        //超时关闭
        that.ws.close()
        that.reconnect()
      }, that.timeout)
    }, this.timeout)
  }
  reconnect() {
    //重新连接
    var that = this
    if (that.lockReconnect) {
      return
    }
    that.lockReconnect = true
    //没连接上会一直重连，设置延迟避免请求过多
    that.timeoutnum && clearTimeout(that.timeoutnum)
    that.timeoutnum = setTimeout(function () {
      //新连接
      that.initWebpack()
      that.lockReconnect = false
      that.isFirstGet = true
    }, 5000)
  }
  reset() {
    //重置心跳
    var that = this
    //清除时间
    clearTimeout(that.timeoutObj)
    clearTimeout(that.serverTimeoutObj)
    //重启心跳
    this.start()
  }

  onmessage(e) {
    const data = JSON.parse(e.data)

    const current_user_id = localStorage.getItem('userId')

    // this.cell.js_obj.history = []
    if (data.message.user_id !== current_user_id) {
      applyMsgToLayerList(this.node, data.message.data)
    }
    // if (JSON.parse(e.data).message !== 'ping') {
    //   window.dispatchEvent(
    //     new CustomEvent('onmessageWS', {
    //       detail: {
    //         data: JSON.parse(e.data),
    //       },
    //     })
    //   )
    // }
    //处理数据的地方
    this.reset()
  }

  onclose(e) {
    this.ws.close()
  }
  onerror(e) {
    //重连
    this.reconnect()
  }

  //发送消息
  async sendMsg(data) {
    let that = this
    const qid = localStorage.getItem('qid')
    const user_id = localStorage.getItem('userId')
    let to_user = await getUserGnsPermission_api({ gns: that.node.data.gns })
    const params = {
      message: { data, code: 200, user_id },
      to_user: to_user.data,
      qid,
      event: 'LAYER',
      user_id,
      gns: that.node.data.gns,
      type: 'NOTICE',
    }

    that.ws.send(JSON.stringify(params))
  }
}

function applyMsgToLayerList(node, data) {
  if (data.func == 'layerUpdate') {
    bus.$emit('msg_layerUpdate', data)
  } else if (data.func == 'layerNumberChange') {
    bus.$emit('msg_layerUpdate', data)
  } else if (data.func == 'layerDelete') {
    bus.$emit('msg_layerUpdate', data)
  } else if (data.func == 'layerClear') {
    bus.$emit('msg_layerUpdate', data)
  } else if (data.func == 'layerMerge') {
    bus.$emit('msg_layerUpdate', data)
  }
}
