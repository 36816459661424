<template>
  <div class="device" @click="handleAll" @mouseleave="handleLeave" onselectstart="javascript:return false;"
    style="min-width: 285px;">
    <div class="cardHeader">
      <!-- 头部标题 -->
      <div class="header-tabs">
        <div :class="{active: selectDevice, 'header-tabs__item': true}"
          @click="selectDevice=true, selectParamsDevice=false, mineSymbolClick()">{{ $t("messages.SymbolLibrary") }}
        </div>
        <!-- <div :class="{active: selectParamsDevice, 'header-tabs__item': true}"
          @click="selectDevice=false, selectParamsDevice=true, paramsSymbolClick()">
          {{ $t("messages.paramsComponentLibrary") }}</div> -->
      </div>
      <div class="lines"></div>
    </div>
    <div class="device-menu" v-if="selectDevice">
      <div class="device-menu__menu">
        <img alt="" id="device-menu" class="menu-icon" @click="selectSymbolGroup=!selectSymbolGroup"
          @mouseover="selectSymbolGroup=true" height="18px" src="../../assets/icons/devices/器件库图标.svg">
        <span>{{$t("messages."+symbolTypeName)}}</span>
        <transition name="el-fade-in-linear">
          <div class="menu-icon__menu" id="menu-icon__menu" v-show="selectSymbolGroup">
            <div :class="{active: selectMineSymbol, 'menu-icon__menu__item': true}" @click="mineSymbolClick">
              {{ $t("messages.MySymbol") }}</div>
            <div :class="{active: selectTeamSymbol, 'menu-icon__menu__item': true}" @click="teamSymbolClick">
              {{ $t("messages.TeamSymbol") }}</div>
            <div :class="{active: selectPublicSymbol, 'menu-icon__menu__item': true}" @click="publicSymbolClick">
              {{ $t("messages.PublicSymbol") }}
            </div>
            <div :class="{active: selectOfficialSymbol, 'menu-icon__menu__item': true}" @click="officialSymbolClick">
              {{ $t("messages.OfficialSymbol") }}</div>
            <div :class="{active: selectPDKSymbol, 'menu-icon__menu__item': true}" @click="PDKSymbolClick">
              {{ $t("messages.PDKSymbol") }}</div>
          </div>
        </transition>
      </div>
      <div style="z-index: 99;">
        <el-select class="input-with-select" style="max-height: 30px;overFlow-x: hidden;" v-model="selectSearchKeyType"
          slot="prepend" :placeholder="$t('messages.placeholder_0')" :disabled="!selectStatus">
          <el-option v-for="item in selectSearchKeyTypeOptions" :key="item.value" :label="$t('messages.'+item.label)"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input :placeholder="$t('messages.searchForKeyword')" v-model="searchKey" @keyup.enter.native="searchDevice"
          class="input-with-input">
        </el-input>
        <el-select size="mini" class="menu-select" v-model="selectTeam" v-if="selectTeamSymbol" :loading="teamLoading"
          @change="changeGetSymbolTeamData">
          <el-option v-for="item in TeamOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" class="menu-select" v-model="selectSymbolLibrary" v-if="selectMineSymbol"
          @change="changeGetSymbolMineData">
          <el-option v-for="item in symbolLibraryOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- <el-select size="mini" class="menu-select" v-model="selectType">
          <el-option
            v-for="item in selectTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" class="menu-select" v-model="selectsymbolType">
          <el-option
            v-for="item in selectDeviceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
      </div>
    </div>
    <div class="device-menu" v-if="selectParamsDevice">
      <div class="device-menu__menu">
        <!-- <img alt="" class="menu-icon" height="18px" src="../../assets/personalCenter/分享.svg"/> -->
        <span>{{$t('messages.OfficialParametrizedLibrary')}}</span>
      </div>
      <div>
        <el-select class="input-with-select" v-model="selectSearchKeyType" slot="prepend"
          :placeholder="$t('messages.searchForKeyword')" disabled>
          <el-option v-for="item in selectSearchKeyTypeOptions" :key="item.value" :label="$t('messages.'+item.label)"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input :placeholder="$t('messages.searchForKeyword')" v-model="searchKey" @keyup.enter.native="searchDevice"
          class="input-with-input">
        </el-input>
        <!-- <el-select size="mini" class="menu-select" v-model="selectTeam">
          <el-option
            v-for="item in TeamOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
        <!-- <el-select size="mini" class="menu-select" v-model="selectType">
          <el-option
            v-for="item in selectTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" class="menu-select" v-model="selectsymbolType">
          <el-option
            v-for="item in selectDeviceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
      </div>
    </div>
    <div class="device-list">
      <div class="device-list__header">
        <div>{{$t('messages.ComponentName')}}</div>
      </div>
      <div id="symbol_list" class="infinite-list-wrapper" v-loading="deviceLoading">
        <div style="overflow:auto;height:513px;" class="list" v-if="symbolList.length > 0 && !selectOfficialSymbol"
          v-infinite-scroll="load" :infinite-scroll-disabled="deviceDisabled" infinite-scroll-distance="10"
          :infinite-scroll-immediate-check="false">
          <div v-for="i, k in symbolList" :class="{'list-item': true, clicked: selectIndex==i.name+k}" :key="k"
            @click="ClickItem(i, k)" @mouseover="MouseHoverItem(i)" @mouseleave="note=defaultNote"
            @dblclick.stop="setDevice(i)">
            <div @contextmenu.prevent="onContextmenu($event,i)"
              :class="{'list-item__name': true, nopower: i.symbol_permission==1}"
              :title="i.symbol_permission==1?`${$t('messages.noDevicePower_1')}${i.team_name}${$t('messages.noDevicePower_2')}`:`${$t('messages.name')}：${i.name}\n${$t('messages.createTeam')}：${i.team_name}`"
              v-if="selectTeamSymbol">{{ i.name }}</div>
            <div class="list-item__name" @contextmenu.prevent="onContextmenu($event,i)"
              :title="i.display_name?`${$t('messages.name')}：${i.name}\n${$t('messages.Creator')}：${i.display_name}`: `${$t('messages.name')}：${i.name}\n${$t('messages.createTeam')}：${i.team_name}`"
              v-else-if="selectPublicSymbol">{{ i.name }}</div>
            <div class="list-item__name" :title="`${$t('messages.name')}：${i.name}`"
              @contextmenu.prevent="onContextmenu($event,i)" v-else>
              {{ i.name }}</div>
            <div class="list-item__button">
              <el-button @click.stop="checkInfo(i)" :loading="getInfoLoading" type="text" size="small">
                {{$t('messages.Details')}}</el-button>
            </div>
          </div>
        </div>
        <!-- <ul style="overflow:auto;height:510px;" class="list" v-if="symbolList.length > 0 && selectOfficialSymbol"
          v-infinite-scroll="load" :infinite-scroll-disabled="deviceDisabled" infinite-scroll-distance="10"
          :infinite-scroll-immediate-check="false">
          <li v-for="i, k in symbolList" :class="{'list-item': true, clicked: selectIndex==k}" :key="k"
            @click="ClickItem(i, k)" @mouseover="MouseHoverItem(i)" @mouseleave="note=defaultNote"
            @dblclick="setDevice(i)">
            <div class="list-item__name" :title="i.name">{{ i.name }}</div>
            <div class="list-item__button">
              <el-button @click="checkParamsInfo(i)" :loading="getInfoLoading" type="text" size="small">
                {{$t('messages.Details')}}</el-button>
            </div>
          </li>
        </ul> -->
        <ul style="overflow:auto;height:513px;" class="list" v-if="symbolList.length > 0 && selectOfficialSymbol">
          <li v-for="v, k in symbolList" :key="k">
            <div style="font-size:13px;padding:5px;border-bottom:1px solid #eeeeee;font-weight:bold;">
              {{v.category_name}}</div>
            <div v-for="item, key  in v.data" :key="key"
              :class="{'list-item': true, clicked: selectIndex==item.name+key}" @click="ClickItem(item, key)"
              @mouseover="MouseHoverItem(item)" @mouseleave="note=defaultNote" @dblclick.stop="setDevice(item)">
              <div class="list-item__name" :title="item.name">{{ item.name }}</div>
              <div class="list-item__button">
                <el-button @click.stop="checkInfo(item)" :loading="getInfoLoading" type="text" size="small">
                  {{$t('messages.Details')}}</el-button>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="symbolList.length==0" style="padding: 10px;color: #DDDDDD;font-size: 12px;text-align: center;">
          {{$t('messages.noData')}}
        </div>
        <!-- <p v-if="loading" style="font-size:12px;text-align:center;padding:5px;">加载中...</p>
        <p v-if="noMore" style="font-size:12px;text-align:center;padding:5px;">暂无更多数据</p> -->
      </div>
      <!-- <el-table :data="symbolList" :show-header="false" :height="tableHeight">
        <el-table-column prop="name"></el-table-column>
        <el-table-column fixed="right" width="50" align="center">
          <template slot-scope="scope">
            <el-button @click="checkInfo(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table> -->
    </div>
    <div class="device-note">
      <div class="device-note__header">
        <div>{{$t('messages.note')}}</div>
        <div class="arrow" @click="deviceNoteSeen=!deviceNoteSeen">
          <span class="el-icon-arrow-down" v-if="deviceNoteSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="device-note__body" v-show="deviceNoteSeen">
        <el-tooltip class="item" effect="dark" :content="note" placement="bottom">
          <div class="body-info" v-if="note">{{note}}</div>
          <div class="body-info" v-else>-</div>
        </el-tooltip>
        <div class="body-btn" @click="editNote" v-if="!selectOfficialSymbol">{{$t('messages.edit')}}</div>
      </div>
    </div>
    <!-- <div class="device-mark">
      <div class="device-mark__header">
        <div>标注图</div>
        <div class="arrow" @click="deviceMarkSeen=!deviceMarkSeen">
          <span class="el-icon-arrow-down" v-if="deviceMarkSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="device-mark__body" v-show="deviceMarkSeen">
        <img alt="" class="mark-img" src="../../assets/personalCenter/无团队.jpg"/>
      </div>
    </div> -->
    <div class="device-2D">
      <div class="device-2D__header">
        <div class="Dheader-tabs">
          <div class="Dheader-tabs__item" @click="select2D=true, select3D=false">{{$t('messages.img_2D')}}</div>
          <!-- <div :class="{active: select2D, 'Dheader-tabs__item': true}" @click="select2D=true, select3D=false">2D图</div>
          <div :class="{active: select3D, 'Dheader-tabs__item': true}" @click="select2D=false, select3D=true">3D图</div> -->
        </div>
        <div class="arrow" @click="deviceDSeen=!deviceDSeen">
          <span class="el-icon-arrow-down" v-if="deviceDSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="device-2D__body" v-show="deviceDSeen">
        <canvas id="device-2D_canvas" width="150" height="150" @mousewheel="mouseWheel($event)"
          @mousedown="mouseDownCanvas($event)" @mousemove="mouseMoveCanvas($event)"
          @mouseup="mouseUpCanvas($event)"></canvas>
      </div>
    </div>
    <el-dialog :title="$t('messages.note')" :visible.sync="noteVisible" width="606px" :before-close="handleClose">
      <div class="note-edit-input">
        <el-input type="textarea" :placeholder="$t('messages.placeholder_1')" :rows="5" maxlength="128" show-word-limit
          v-model="noteCopy">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUpdateNote" :loading="UpdateNoteLoading">
          {{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="noteVisible = false" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.ComponentDetails')" :visible.sync="SymbolDialogVisible" width="886px"
      :before-close="handleClose" :modal="false" @opened="SymbolDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div :class="{active: selectSymbolAttrTab, 'Header-tabs__item': true}"
                @click="selectSymbolAttrTab=true, selectParamsSymbolTab=false">{{$t('messages.attrs')}}
              </div>
              <div :class="{active: selectParamsSymbolTab, 'Header-tabs__item': true}"
                @click="selectSymbolAttrTab=false, selectParamsSymbolTab=true">{{$t('messages.params')}}
              </div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectSymbolAttrTab">
            <div class="tool-main__normal">
              <div class="main-group2">
                <div class="main-label">{{$t('messages.name')}}：</div>
                <el-input style="width: 230px;" v-if="selectSymbolInfo" v-model="selectSymbolInfo.name"
                  disabled></el-input>
              </div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectParamsSymbolTab && selectObj">
            <div class="tool-main__normal">
              <div class="main-group2">
                <div style="padding: 7px 0;" v-if="Object.keys(selectObj.port_type_and_name).length>0">
                  <div class="main-label">{{$t('messages.portCount')}}：</div>
                  <div class="main-params">
                    {{$t('messages.toolCount')}}
                    <el-input-number style="width: 256px;margin-left:15px;"
                      v-model="Object.keys(selectObj.port_type_and_name).length" @input="portCountChange" :min="3"
                      :max="10" :controls="false"
                      v-if="selectObj.function_name=='qubit_single'||selectObj.function_name=='qubit_double'">
                    </el-input-number>
                    <el-input-number style="width: 256px;margin-left:15px;"
                      v-model="Object.keys(selectObj.port_type_and_name).length" @input="portCountChange" :min="1"
                      :max="10" :controls="false" v-else-if="selectObj.function_name=='cpw'">
                    </el-input-number>
                    <el-input-number style="width: 256px;margin-left:15px;"
                      v-model="Object.keys(selectObj.port_type_and_name).length" disabled :controls="false" v-else>
                    </el-input-number>
                  </div>
                </div>
                <div style="padding: 7px 0;" v-if="Object.keys(selectObj.port_type_and_name).length>0">
                  <div class="main-label">{{$t('messages.port')}}：</div>
                  <div style="padding-left:188px;">
                    <div v-for="item, key in Object.keys(selectObj.port_type_and_name)" :key="key">
                      <div class="port-item">
                        {{$t('messages.type')}}<el-input style="width: 100px;margin-left:15px;"
                          v-model="selectObj.port_type_and_name[item]" disabled></el-input>
                      </div>
                      <div class="port-item">
                        {{$t('messages.name')}}<el-input style="width: 100px;margin-left:15px;"
                          v-model="Object.keys(selectObj.port_type_and_name)[key]" disabled></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="target_conf">
                  <div>
                    <div class="main-label">{{$t('messages.params')}}</div>
                    <div class="main-params">
                      <el-radio v-model="params_radio" @change="radioChange" label="1"
                        v-if="target_conf.el.length>0">{{$t('messages.elcParams')}}</el-radio>
                      <el-radio v-model="params_radio" @change="radioChange" label="2"
                        v-if="target_conf.ck.length>0">{{$t('messages.controlParams')}}</el-radio>
                    </div>
                  </div>
                  <div class="params-item" v-if="params_radio=='1'">
                    <div v-for="v, k in target_conf.el" :key="k">
                      <div class="main-label">
                        {{v.name}}
                        <el-tooltip class="item" effect="dark"
                          :content="`${v.name}(${transParamsName(v.name, selectObj.function_name)})`" placement="right">
                          <span class="el-icon-question icon-en"></span>
                        </el-tooltip>
                      </div>
                      <el-input v-if="v.min==null" style="width: 280px;" v-model="v.value" disabled></el-input>
                      <el-input-number v-else style="width: 280px;" :min="v.min" :max="v.max" v-model="v.value"
                        :controls="false" @blur="changeParams(v)">
                      </el-input-number>
                      <div class="unit" v-if="v.min!=null">{{v.min}}~{{v.max}}{{v.unit}}</div>
                      <div class="unit" v-else>{{v.unit}}</div>
                    </div>
                  </div>
                  <div class="params-item" v-if="params_radio=='2'">
                    <div v-for="v, k in target_conf.ck" :key="k">
                      <div class="main-label">
                        {{v.name}}
                        <el-tooltip class="item" effect="dark"
                          :content="`${v.name}(${transParamsName(v.name, selectObj.function_name)})`" placement="right">
                          <span class="el-icon-question icon-en"></span>
                        </el-tooltip>
                      </div>
                      <el-input v-if="v.min==null" style="width: 280px;" v-model="v.value" disabled></el-input>
                      <el-input-number v-else style="width: 280px;" :min="v.min" :max="v.max" v-model="v.value"
                        :controls="false" @blur="changeParams(v)">
                      </el-input-number>
                      <div class="unit" v-if="v.min!=null">{{v.min}}~{{v.max}}{{v.unit}}</div>
                      <div class="unit" v-else>{{v.unit}}</div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div>{{$t('messages.noParamsData')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="symbol_params">
          <div class="symbol_params__header">
            <div style="padding:10px;">{{$t('messages.img_2D')}}</div>
          </div>
          <div class="symbol_params__body" id="2d-canvas">
            <canvas id="symbol_params_canvas" @mousewheel="mouseWheelForParams($event)"
              @mousedown="mouseDownCanvasForParams($event)" @mousemove="mouseMoveCanvasForParams($event)"
              @mouseup="mouseUpCanvasForParams($event)"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="setDevice(false)">{{$t('messages.set')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyToVisible" width="570px" :before-close="handleClose"
      :modal="false" @opened="copyToOpened">
      <div class="dialog-body2">
        <div class="export-group" style="margin-bottom:20px;">
          <div class="group-txt">{{ $t('messages.Group') }}：</div>
          <div class="group-select">
            <el-select style="width: 400px" class="componentSelect" size="mini" v-model="copy_library_type">
              <el-option v-for="item in $t('messages.copyToSymbolTypeOptions')" :key="item.label" :label="item.label"
                :value="item.value" :disabled="!selectOfficialSymbol && item.value==3 || item.value == 4"></el-option>
            </el-select>
          </div>
        </div>
        <div class="export-group" v-show="copy_library_type == 1">
          <div class="group-txt">{{ $t('messages.select') }}：</div>
          <div class="group-select">
            <el-select style="width: 400px" class="componentSelect" size="mini" v-model="copy_team_id">
              <el-option v-for="item in teamOptionList" :key="item.label" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="export-group" v-show="copy_library_type == 0">
          <div class="group-txt">{{ $t('messages.select') }}：</div>
          <div class="group-select">
            <div class="group-select">
              <el-select style="width: 400px" class="componentSelect" size="mini" v-model="copy_library_id">
                <el-option v-for="item in libraryOptions" :key="item.label" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitCopySymbol(null)"
          :disabled="(copy_library_type==0 && copy_library_id=='') || (copy_library_type==1 && copy_team_id=='')">{{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="cancelCopyDevice" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import bus from "../common/bus";
import { deepCopy, deepClone } from "@/utils/utils";
import {
  renameSchemaName,
  copyComponentCellFn,
  binaryDataToUTF8,
  mergeU8Array,
} from "@/components/homes/fileList/function/fileListPublicFn";
import {
  // getCompleteDataFn,
  getObjectDataFn,
  downloadFileData,
} from "@/components/homes/fileList/function/objectStorageFn";
import {
  getTeamList_api,
  createMyComponent_api,
  createTeamComponent_api,
} from "@/api/file/file.js";
const Device = require("../../utils/qeda-component.js");
var deviceCollection = new Device(); //NOSONAR
const AutoToolCanvasComponent = require("../../utils/tools/canvas_component.js");
var canvas2D = new AutoToolCanvasComponent(); //NOSONAR
import {
  getSymbolMine,
  getSymbolTeam,
  getSymbolPublic,
  getSymbolOfficial,
  updateSymbolNote,
  getSymbolInfo,
  getTeamList,
  deleteSymbol,
  getSymbolList,
} from "@/api/api.js";
import { QedaSchemaStage } from "@/utils/graphic_schema/qeda-schema-stage";
import { SchemaDisplayConfig } from "@/utils/graphic_schema/render-util";
import QedaSchemaAxis from "@/utils/graphic_schema/qeda-schema-axis";
import { buildSymbolPath } from "@/utils/graphic_schema/layout/layout-util";
import {
  getPDKInfo_api,
  saveMySymbol_api,
  saveTeamSymbol_api,
} from "@/api/objectStorage/objectStorage.js";
var time = null; //NOSONAR
export default {
  data() {
    return {
      list_stage: null,
      params_stage: null,
      selectDevice: true,
      selectParamsDevice: false,
      selectTeam: "",
      selectType: "超导",
      selectsymbolType: "谐振腔",
      selectSearchKeyType: 1,
      selectSymbolGroup: false,
      selectMineSymbol: true,
      selectTeamSymbol: false,
      selectPublicSymbol: false,
      selectOfficialSymbol: false,
      selectPDKSymbol: false,
      symbolTypeName: this.$t("messages.MySymbol"),
      TeamOptions: [],
      selectTypeOptions: [{ label: "超导", value: "超导" }],
      selectDeviceOptions: [{ label: "谐振腔", value: "谐振腔" }],
      selectSearchKeyTypeOptions: [
        { label: "Symbol", value: 1 },
        { label: "Creator", value: 2 },
      ],
      searchKey: "",
      symbolList: [],
      tableHeight: 490,
      note: "",
      defaultNote: "-",
      noteCopy: "",
      noteVisible: false,
      SymbolDialogVisible: false,
      paramsSymbolDialogVisible: false,
      select2D: true,
      select3D: false,
      deviceNoteSeen: true,
      deviceMarkSeen: true,
      deviceDSeen: true,
      next_page: false,
      loading: false,
      noMore: false,
      start: 0,
      rows: 100,
      symbolType: 1,
      symbolSearch: false,
      deviceLoading: false,
      selectStatus: true,
      selectIndex: null,
      selectSymbolInfo: null,
      getInfoLoading: false,
      UpdateNoteLoading: false,
      edit_symbol_type: 1,
      teamLoading: false,
      target_obj: null,
      deviceConf: { params: "1" },
      params_radio: "1",
      selectParamsDeviceParams: {},
      selectDevicePatams: {},
      selectSymbolAttrTab: true,
      selectParamsSymbolTab: false,
      all_layer: this.$store.state.layerDatas,
      deviceDisabled: true,
      pad_length: null,
      stage: {},
      config: {},
      currentSchema: {},
      capSymbol: {},
      selectObj: null,
      target_conf: null,
      qubit_port: this.$t("messages.qubit_port"),
      cpw_port: this.$t("messages.cpw_port"),
      all_port: this.$t("messages.all_port"),
      pdklib: null,
      selectSymbolLibrary: "1",
      symbolLibraryOptions: [],
      select_symbol_type: 0,
      currentSymbol: null,
      copyToVisible: false,
      copy_library_type: 0,
      teamOptionList: [],
      copy_team_id: "",
      copy_library_id: "",
      libraryOptions: [],
    };
  },
  computed: {},
  methods: {
    handleClose(done) {
      this.deviceConf = { params: "1" };
      try {
        document.getElementById("ann-image").style.display = "none";
      } catch (error) {}
      done();
    },
    transParamsName(name, function_name) {
      return this.$t("messages.symbolConf_en")[`${name}_${function_name}`];
    },
    getCenterTranFn(box, width, height) {
      const s = 1 / 2;
      const window_width = width * s;
      const window_height = height * s;
      const box_width = box[1][0] - box[0][0];
      const box_height = box[1][1] - box[0][1];
      let scale = 1;
      if (box_height > box_width) {
        scale = window_height / box_height;
      } else {
        scale = window_width / box_width;
      }
      let dx = width / 2 - (box[0][0] + box_width / 2) * scale;
      let dy = height / 2 + (box[0][1] + box_height / 2) * scale;
      return {
        scale: scale,
        dx: dx,
        dy: dy,
      };
    },
    init2DCanvas(schema, symbol, width, height, parent_class, id) {
      let axis = new QedaSchemaAxis(width, height, 1.0e-6, 1.0e-9);
      axis.rulerX.width = width;
      axis.rulerY.height = height;
      let config = new SchemaDisplayConfig();
      let data = this.getCenterTranFn(symbol.bounding_box, width, height);
      axis.scale = data.scale;
      axis.dx = data.dx;
      axis.dy = data.dy;
      axis.updateValue();
      buildSymbolPath(symbol);

      schema.add_symbol_ins(symbol);
      let containerId = parent_class;
      config.onMouseWheel(axis.scale);
      config.showGrid = false;
      config.showXY = false;
      let canvas = document.getElementById(id);
      canvas.width = axis.width;
      canvas.height = axis.height;
      let stage = new QedaSchemaStage(axis, containerId, schema, config);
      return stage;
    },
    get2DCanvas(stage, symbol, width, height, parent_class, id) {
      let schema = new Kernel.Schema();
      if (!stage) {
        stage = this.init2DCanvas(
          schema,
          symbol,
          width,
          height,
          parent_class,
          id
        );
      }
      let axis = stage.myaxis;
      axis.rulerX.width = width;
      axis.rulerY.height = height;
      let config = stage.config;
      let data = this.getCenterTranFn(symbol.bounding_box, width, height);
      axis.scale = data.scale;
      axis.dx = data.dx;
      axis.dy = data.dy;
      axis.updateValue();
      buildSymbolPath(symbol);
      schema.add_symbol_ins(symbol);
      stage.schema = schema;
      config.onMouseWheel(axis.scale);
      config.showGrid = false;
      config.showXY = false;
      stage.render();
      this.list_stage = stage;
    },
    get2DCanvasParams(stage, symbol, width, height, parent_class, id) {
      let schema = new Kernel.Schema();
      if (!stage) {
        stage = this.init2DCanvas(
          schema,
          symbol,
          width,
          height,
          parent_class,
          id
        );
      }
      let axis = stage.myaxis;
      axis.rulerX.width = width;
      axis.rulerY.height = height;
      let config = stage.config;
      let data = this.getCenterTranFn(symbol.bounding_box, width, height);
      axis.scale = data.scale;
      axis.dx = data.dx;
      axis.dy = data.dy;
      axis.updateValue();
      buildSymbolPath(symbol);
      schema.add_symbol_ins(symbol);
      stage.schema = schema;
      config.onMouseWheel(axis.scale);
      config.showGrid = false;
      config.showXY = false;
      stage.render();
      this.params_stage = stage;
    },
    mouseDownCanvas(event) {
      this.list_stage.myaxis.setDrag(true, 1);
    },
    mouseMoveCanvas(event) {
      this.list_stage.myaxis.onMouseMove(event);
      this.list_stage.myaxis.updateValue();
      this.list_stage.render();
    },
    mouseUpCanvas(event) {
      this.list_stage.myaxis.setDrag(false, 0);
    },
    mouseWheel(event) {
      this.list_stage.myaxis.onMouseWheel(event, false);
      this.list_stage.config.onMouseWheel(this.list_stage.myaxis.scale);
      this.list_stage.myaxis.updateValue();
      this.list_stage.render();
    },
    mouseDownCanvasForParams(event) {
      try {
        this.params_stage.myaxis.setDrag(true, 1);
      } catch (e) {}
    },
    mouseMoveCanvasForParams(event) {
      try {
        this.params_stage.myaxis.onMouseMove(event);
        this.params_stage.myaxis.updateValue();
        this.params_stage.render();
      } catch (e) {}
    },
    mouseUpCanvasForParams(event) {
      try {
        this.params_stage.myaxis.setDrag(false, 0);
      } catch (e) {}
    },
    mouseWheelForParams(event) {
      try {
        this.params_stage.myaxis.onMouseWheel(event, false);
        this.params_stage.config.onMouseWheel(this.params_stage.myaxis.scale);
        this.params_stage.myaxis.updateValue();
        this.params_stage.render();
      } catch (e) {}
    },
    async onContextmenu(event, node) {
      if (this.selectOfficialSymbol) return;
      if (this.selectTeamSymbol && node.symbol_permission !== 3) return;
      this.$contextmenu({
        items: [
          // {
          //   label: this.$t('messages.copyTo'),
          //   icon: 'iconfont icon-fuzhi',
          //   onClick: () => {
          //     this.copyToVisible = true;
          //     this.currentSymbol = node;
          //   }
          // },
          {
            label: this.$t("messages.delete"),
            onClick: () => {
              if (this.selectMineSymbol) {
                let params = {
                  symbol_group: 1,
                  snow_ids: [node.snow_id],
                  team_id: "",
                };
                deleteSymbol(params).then((res) => {
                  if (res.code / 1000 === 200) {
                    this.$message.success("删除成功");
                    setTimeout(() => {
                      this.symbolList = [];
                      this.getSymbolMineData();
                    }, 500);
                  } else {
                    this.$message.error(res.message);
                  }
                });
              }
              if (this.selectTeamSymbol) {
                let params = {
                  symbol_group: 2,
                  snow_ids: [node.snow_id],
                  team_id: node.team_id,
                };
                deleteSymbol(params).then((res) => {
                  if (res.code / 1000 === 200) {
                    this.$message.success("删除成功");
                    setTimeout(() => {
                      this.symbolList = [];
                      this.getSymbolTeamData();
                    }, 500);
                  } else {
                    this.$message.error(res.message);
                  }
                });
              }
              if (this.selectPublicSymbol) {
                let params = {
                  symbol_group: 3,
                  snow_ids: [node.snow_id],
                  team_id: "",
                };
                deleteSymbol(params).then((res) => {
                  if (res.code / 1000 === 200) {
                    this.$message.success("删除成功");
                    setTimeout(() => {
                      this.symbolList = [];
                      this.getSymbolPublicData();
                    }, 500);
                  } else {
                    this.$message.error(res.message);
                  }
                });
              }
            },
            icon: "iconfont icon-shanchu",
          },
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 1000, // 菜单样式 z-index
        minWidth: 170, // 主菜单最小宽度
      });
    },
    checkInfo(row) {
      if (this.selectTeamSymbol && row.symbol_permission == 1) {
        return;
      }
      this.getSymbolInfoData(row);
      this.ClickItem(row);
    },
    editNote() {
      if (this.selectIndex == null) {
        return;
      }
      this.noteCopy = deepCopy(this.note);
      this.noteVisible = true;
    },
    getTeamListData(start, rows) {
      let params = {
        start: start,
        rows: rows,
        key_word: "",
      };
      this.teamLoading = true;
      getTeamList(params).then((res) => {
        if (res.code / 1000 === 200) {
          let team_list = [];
          if (res.data.data.length > 0) {
            team_list = res.data.data.map((item) => {
              const ob = { selected: false };
              return Object.assign(item, ob);
            });
            this.TeamOptions = team_list.map((item) => {
              const dicts = {
                label: item.team_name,
                value: item.snow_team_id,
              };
              return dicts;
            });
          }
          this.TeamOptions.unshift({ label: "全部团队", value: "" });
        } else {
          this.$message.error(res.message);
        }
        this.teamLoading = false;
      });
    },
    submitUpdateNote() {
      this.noteVisible = false;
      this.note = this.noteCopy;
      if (this.noteCopy != this.selectObj.notes) {
        const params = {
          snow_id: this.selectObj.snow_id,
          symbol_type: this.edit_symbol_type,
          notes: this.noteCopy,
        };
        this.UpdateNoteLoading = true;
        updateSymbolNote(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.selectObj.notes = this.noteCopy;
            this.$message.success("更新成功");
          } else {
            this.$message.error(res.message);
          }
          this.UpdateNoteLoading = false;
        });
      }
    },
    axiosSymbolMineData: _.debounce(function (params) {
      getSymbolMine(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data;
          this.symbolList = this.symbolList.concat(data_list);
          this.next_page = res.data.is_next;
          this.symbolSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    changeGetSymbolMineData() {
      this.symbolList = [];
      this.start = 0;
      this.getSymbolMineData();
    },
    getSymbolMineData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        symbol_type:
          this.select_symbol_type == 0 ? [1, 2] : [this.select_symbol_type],
        library_snow_id: this.selectSymbolLibrary,
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosSymbolMineData(params);
    },
    axiosSymbolTeamData: _.debounce(function (params) {
      getSymbolTeam(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data;
          this.symbolList = this.symbolList.concat(data_list);
          this.next_page = res.data.is_next;
          this.symbolSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    changeGetSymbolTeamData() {
      this.symbolList = [];
      this.start = 0;
      this.getSymbolTeamData();
    },
    getSymbolTeamData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        team_id: this.selectTeam,
        search_type: this.selectSearchKeyType,
        symbol_type:
          this.select_symbol_type == 0 ? [1, 2] : [this.select_symbol_type],
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosSymbolTeamData(params);
    },
    axiosSymbolPublicData: _.debounce(function (params) {
      getSymbolPublic(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data;
          this.symbolList = this.symbolList.concat(data_list);
          this.next_page = res.data.is_next;
          this.symbolSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    getSymbolPublicData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        symbol_type:
          this.select_symbol_type == 0 ? [1, 2] : [this.select_symbol_type],
        search_type: this.selectSearchKeyType,
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosSymbolPublicData(params);
    },
    axiosSymbolOfficialData: _.debounce(function (params) {
      getSymbolOfficial(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data;
          this.symbolList = this.symbolList.concat(data_list);
          this.next_page = res.data.is_next;
          this.symbolSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    getSymbolOfficialData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        symbol_type:
          this.select_symbol_type == 0 ? [1, 2] : [this.select_symbol_type],
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosSymbolOfficialData(params);
    },
    async getPDK(proj_gns) {
      let pdk_res = await getPDKInfo_api({ project_snow_id: proj_gns });
      if (pdk_res.code === 200000) {
        let config = pdk_res.data.url_data;
        if (config.lib) {
          // 获取drf文件内容并解析为json
          let lib_u8 = await getObjectDataFn(config.lib);
          let lib_fr = new FileReader();
          lib_fr.readAsText(new Blob([lib_u8]));
          let result = new Promise((resolve) => {
            lib_fr.onload = (evt) => {
              resolve(evt);
            };
          });
          await result.then((evt) => {
            this.pdklib = JSON.parse(evt.target.result);
          });
        }
      } else {
        this.pdklib = null;
      }
    },
    async analysisPDK(arr) {
      return new Promise((resolve) => {
        let pdk_arr = [];
        arr.forEach((item) => {
          item.data.forEach((symbol_data) => {
            if (this.pdklib.Symbol.includes(symbol_data.name)) {
              pdk_arr.push(symbol_data);
            }
          });
        });
        resolve(pdk_arr);
      });
    },
    axiosPDKSymbolData: _.debounce(async function (params) {
      let res = await getSymbolOfficial(params);
      if (res.code == 200000) {
        const data_list = res.data.data;
        // 加载PDK.Lib
        const proj_gns = this.$store.state.proInfo.projectId;
        this.symbolList = this.symbolList.concat(data_list);
        await this.getPDK(proj_gns);
        if (this.pdklib && this.pdklib.Symbol) {
          let validPDKSymbol = await this.analysisPDK(this.symbolList);
          this.symbolList = [];
          this.symbolList = validPDKSymbol;
        } else {
          this.symbolList = [];
        }
        this.next_page = res.data.is_next;
        this.symbolSearch = false;
        if (this.next_page) {
          this.loading = true;
          this.noMore = false;
          this.start = res.data.next;
          this.deviceDisabled = false;
        } else {
          this.loading = false;
          this.noMore = true;
        }
      }
      this.deviceLoading = false;
    }, 500),
    async getSymbolPDKData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        symbol_type:
          this.select_symbol_type == 0 ? [1, 2] : [this.select_symbol_type],
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosPDKSymbolData(params);
    },
    axiosDeviceParamsData: _.debounce(function (params) {
      getSymbolOfficial(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data.map((item) => {
            return item._source;
          });
          this.symbolList = this.symbolList.concat(data_list);
          this.next_page = res.data.is_next;
          this.symbolSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    getDeviceParamsData() {
      if (!this.next_page) {
        return;
      }
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        symbol_type:
          this.select_symbol_type == 0 ? [1, 2] : [this.select_symbol_type],
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosDeviceParamsData(params);
    },
    mineSymbolClickInit() {
      this.start = 0;
      this.selectDevice = true;
      this.selectParamsDevice = false;
      this.selectSymbolGroup = false;
      this.selectMineSymbol = true;
      this.selectTeamSymbol = false;
      this.selectPublicSymbol = false;
      this.selectOfficialSymbol = false;
      this.selectPDKSymbol = false;
      this.symbolTypeName = "MySymbol";
      this.symbolType = 1;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.edit_symbol_type = 1;
      this.selectSearchKeyType = 1;
      this.selectObj = null;
    },
    async mineSymbolClick() {
      if (this.symbolTypeName) this.start = 0;
      this.selectDevice = true;
      this.selectParamsDevice = false;
      this.selectSymbolGroup = false;
      this.selectMineSymbol = true;
      this.selectTeamSymbol = false;
      this.selectPublicSymbol = false;
      this.selectOfficialSymbol = false;
      this.selectPDKSymbol = false;
      this.symbolTypeName = "MySymbol";
      this.symbolType = 1;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.edit_symbol_type = 1;
      this.selectSearchKeyType = 1;
      this.selectObj = null;
      this.getSymbolMineData();
      let res_1 = await getSymbolList();
      if (res_1.code == 200000) {
        this.symbolLibraryOptions = res_1.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
    },
    teamSymbolClick() {
      this.start = 0;
      this.selectSymbolGroup = false;
      this.selectMineSymbol = false;
      this.selectTeamSymbol = true;
      this.selectPublicSymbol = false;
      this.selectOfficialSymbol = false;
      this.selectPDKSymbol = false;
      this.symbolTypeName = "TeamSymbol";
      this.symbolType = 1;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.selectStatus = true;
      this.selectIndex = null;
      this.note = "-";
      this.edit_symbol_type = 2;
      this.selectSearchKeyType = 1;
      this.selectObj = null;
      this.getSymbolTeamData();
      this.getTeamListData(0, 100);
    },
    publicSymbolClick() {
      this.start = 0;
      this.selectSymbolGroup = false;
      this.selectMineSymbol = false;
      this.selectTeamSymbol = false;
      this.selectPublicSymbol = true;
      this.selectOfficialSymbol = false;
      this.selectPDKSymbol = false;
      this.symbolTypeName = "PublicSymbol";
      this.symbolType = 1;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.selectStatus = true;
      this.selectIndex = null;
      this.note = "-";
      this.edit_symbol_type = 3;
      this.selectSearchKeyType = 1;
      this.selectObj = null;
      this.getSymbolPublicData();
    },
    officialSymbolClick() {
      this.start = 0;
      this.selectSymbolGroup = false;
      this.selectMineSymbol = false;
      this.selectTeamSymbol = false;
      this.selectPublicSymbol = false;
      this.selectOfficialSymbol = true;
      this.selectPDKSymbol = false;
      this.symbolTypeName = "OfficialSymbol";
      this.symbolType = 1;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.selectSearchKeyType = 1;
      this.selectObj = null;
      this.getSymbolOfficialData();
    },
    PDKSymbolClick() {
      this.start = 0;
      this.selectSymbolGroup = false;
      this.selectMineSymbol = false;
      this.selectTeamSymbol = false;
      this.selectPublicSymbol = false;
      this.selectOfficialSymbol = false;
      this.selectPDKSymbol = true;
      this.symbolTypeName = "PDKSymbol";
      this.symbolType = 1;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.selectSearchKeyType = 1;
      this.selectObj = null;
      this.getSymbolPDKData();
    },
    paramsSymbolClick() {
      this.start = 0;
      this.selectSymbolGroup = false;
      this.selectMineSymbol = false;
      this.selectTeamSymbol = false;
      this.selectPublicSymbol = false;
      this.selectOfficialSymbol = false;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.selectSearchKeyType = 1;
      this.selectObj = null;
      this.getDeviceParamsData();
    },
    handleAll(e) {
      const foo = document.getElementById("device-menu");
      if (foo) {
        if (!foo.contains(e.target)) {
          this.selectSymbolGroup = false;
        }
      }
    },
    handleLeave(e) {
      const foo = document.getElementById("menu-icon__menu");
      if (foo) {
        if (!foo.contains(e.target)) {
          this.selectSymbolGroup = false;
        }
      }
    },
    load() {
      const value = this.symbolList[this.start];
      if (value) {
        return;
      }
      if (this.selectMineSymbol) {
        if (this.next_page) {
          this.getSymbolMineData();
        }
      }
      if (this.selectTeamSymbol) {
        if (this.next_page) {
          this.getSymbolTeamData();
        }
      }
      if (this.selectPublicSymbol) {
        if (this.next_page) {
          this.getSymbolPublicData();
        }
      }
      if (this.selectOfficialSymbol) {
        if (this.next_page) {
          this.getSymbolOfficialData();
        }
      }
      if (this.selectPDKSymbol) {
        if (this.next_page) {
          this.getSymbolPDKData();
        }
      }
    },
    searchDevice() {
      this.start = 0;
      this.symbolList = [];
      this.symbolSearch = true;
      this.next_page = true;
      this.load();
    },
    transformParamsType(type) {
      if (type == "1") {
        return Kernel.VarType.ELECTROSTATIC;
      }
      if (type == "2") {
        return Kernel.VarType.MEASUREMENT_CONTROL;
      }
    },
    setObjConf(row, func_name) {
      this.target_conf = deepClone(this.$t("messages.symbolConf")[func_name]);
      this.setRowConf(row, this.target_conf);
    },
    setRowConf(row, conf) {
      if (conf) {
        conf.el.forEach((item) => {
          row.symbol.var_info.set(item.name + ",#,el", String(item.value));
        });
        conf.ck.forEach((item) => {
          row.symbol.var_info.set(item.name + ",#,ck", String(item.value));
        });
      }
    },
    transPortType(port_type) {
      if (Kernel.PortType.X == port_type) {
        return this.all_port.X;
      } else if (Kernel.PortType.Z == port_type) {
        return this.all_port.Z;
      } else if (Kernel.PortType.Res == port_type) {
        return this.all_port.Res;
      } else if (Kernel.PortType.Q == port_type) {
        return this.all_port.Q;
      } else if (Kernel.PortType.B == port_type) {
        return this.all_port.B;
      } else {
        return "";
      }
    },
    getPort(row, count) {
      if (
        row.function_name == "qubit_single" ||
        row.function_name == "qubit_double"
      ) {
        let res = {},
          all_keys = Object.keys(this.qubit_port).slice(0, count);
        all_keys.forEach((key) => {
          res[key] = this.qubit_port[key];
        });
        return res;
      } else if (row.function_name == "cpw") {
        let res = {},
          all_keys = Object.keys(this.cpw_port).slice(0, count);
        all_keys.forEach((key) => {
          res[key] = this.cpw_port[key];
        });
        return res;
      } else {
        let ports = row.ports;
        let res = {};
        for (let i = 0; i < ports.length; i++) {
          res[row.ports[i].text] = this.transPortType(row.ports[i].port_type);
        }
        return res;
      }
    },
    MouseHoverItem(row) {
      this.note = row.notes;
    },
    ClickItem(row, index) {
      if (this.selectTeamSymbol && row.symbol_permission == 1) {
        return;
      }
      clearTimeout(time);
      time = setTimeout(() => {
        this.clickInfo(row, index);
      }, 100);
    },
    async clickInfo(row, index) {
      if (this.selectTeamSymbol && row.symbol_permission == 1) {
        return;
      }

      if (!this.selectSymbolInfo) {
        let params = {
          snow_id: row.snow_id,
        };
        let info_res = await getSymbolInfo(params);
        this.selectSymbolInfo = deepClone(info_res.data);
      }
      this.selectSymbolAttrTab = true;
      this.selectParamsSymbolTab = false;
      this.params_radio = "1";
      this.defaultNote = row.notes;
      this.selectIndex = row.name + index;
      this.selectObj = row;
      this.currentSymbol = row;
      try {
        this.target_obj = await this.getTargetObj(row);
        this.selectObj.port_type_and_name = this.getPort(this.target_obj, 0);
        this.get2DCanvas(
          this.list_stage,
          this.target_obj,
          244,
          154,
          "device-2D",
          "device-2D_canvas"
        );
        this.getInfoLoading = false;
      } catch (e) {
        this.getInfoLoading = false;
      }
    },
    async getTargetObj(row) {
      let target_obj = new window.Kernel.SymbolIns();

      if (row.symbol_group == "官方符号库") {
        let newSymbol;
        if (row.function_name == "qubit_single") {
          newSymbol = Kernel.qubit_single();
          this.selectObj.port_type_and_name = this.getPort(row, 4);
        } else if (row.function_name == "qubit_double") {
          newSymbol = Kernel.qubit_double();
          this.selectObj.port_type_and_name = this.getPort(row, 4);
        } else if (row.function_name == "cpw") {
          newSymbol = Kernel.cpw();
          this.selectObj.port_type_and_name = this.getPort(row, 2);
        } else {
          newSymbol = Kernel[row.function_name]();
          this.selectObj.port_type_and_name = this.getPort(
            newSymbol,
            newSymbol.ports.length
          );
        }
        target_obj.symbol = renameSchemaName(
          newSymbol.snow_id,
          [newSymbol],
          this.$store.state.activeNode.parent
        );
        this.setObjConf(target_obj, row.function_name);
        target_obj.symbol.var_type = this.transformParamsType(
          this.params_radio
        );
      } else {
        let fileData_ = await getObjectDataFn(row.file_url);

        let parse_file_list = Kernel.parse_file(fileData_);
        let file_lib = {};
        // let file_lib = copyComponentCellFn(Kernel.parse_file(fileData_)[0]);
        if (parse_file_list.length > 1) {
          let topCapSymbol = parse_file_list.filter(
            (item) => item.name === row.name && item.type.value === 2
          )[0];
          file_lib = copyComponentCellFn(topCapSymbol);
        } else {
          file_lib = copyComponentCellFn(Kernel.parse_file(fileData_)[0]);
        }
        let files = [file_lib];
        if (file_lib.$$?.ptrType.name.replace("*", "") == "CapSymbol") {
          files = files.concat(file_lib.depend_symbol_schemas(-1));
        }

        // let file_lib = Kernel.parse_file(fileData_)[0];
        // target_obj = new Kernel.SymbolIns();
        target_obj.symbol = renameSchemaName(
          file_lib.snow_id,
          files,
          this.$store.state.activeNode.parent
        );
        target_obj.ins_name = row.name;
      }
      return target_obj;
    },
    async setDevice(row) {
      if (this.selectTeamSymbol && row.symbol_permission == 1) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t("messages.waitLoading"),
        fullscreen: true,
      });
      // if (!this.target_obj) {
      //   setTimeout(() => {
      //     this.setDevice();
      //   }, 500);
      // }
      if (row) {
        this.selectObj = row;
        this.target_obj = await this.getTargetObj(row);
      }

      clearTimeout(time);
      this.SymbolDialogVisible = false;
      this.paramsSymbolDialogVisible = false;
      this.target_obj.symbol.var_type = this.transformParamsType(
        this.params_radio
      );
      this.setRowConf(this.target_obj, this.target_conf);
      bus.$emit("addNewSymbol", this.target_obj);
      setTimeout(() => {
        this.target_obj = null;
        loading.close();
      }, 100);
      return;
    },
    async getSymbolInfoData(row) {
      this.selectSymbolInfo = null;
      let params = {
        snow_id: row.snow_id,
      };
      this.getInfoLoading = true;
      let res = await getSymbolInfo(params);
      this.selectSymbolInfo = deepClone(res.data);

      this.SymbolDialogVisible = true;
      this.getInfoLoading = false;
    },
    SymbolDialogOpened() {
      setTimeout(() => {
        this.get2DCanvasParams(
          this.params_stage,
          this.target_obj,
          244,
          244,
          "symbol_params",
          "symbol_params_canvas"
        );

        if (!this.selectSymbolInfo) {
          let params = {
            snow_id: this.selectObj.snow_id,
          };
          getSymbolInfo(params).then((res) => {
            this.selectSymbolInfo = deepClone(res.data);
          });
        }
      }, 500);
    },
    changeParams(params) {},
    varTypeTransform(varType) {
      if (varType == Kernel.VarType.ELECTROSTATIC) {
        return "1";
      } else if (varType == Kernel.VarType.MEASUREMENT_CONTROL) {
        return "2";
      } else if (varType == "1") {
        return Kernel.VarType.ELECTROSTATIC;
      } else if (varType == "2") {
        return Kernel.VarType.MEASUREMENT_CONTROL;
      }
    },
    portCountChange(val) {
      this.selectObj.port_type_and_name = this.getPort(this.selectObj, val);
      if (this.selectObj.function_name == "qubit_single") {
        Kernel.set_qubit_single_port(this.target_obj.symbol, val);
      } else if (this.selectObj.function_name == "qubit_double") {
        Kernel.set_qubit_double_port(this.target_obj.symbol, val);
      } else if (this.selectObj.function_name == "cpw") {
        Kernel.set_cpw_port(this.target_obj.symbol, val);
      }

      this.get2DCanvasParams(
        this.params_stage,
        this.target_obj,
        244,
        244,
        "symbol_params",
        "symbol_params_canvas"
      );
    },
    radioChange(val) {
      this.target_obj.symbol.var_type = this.varTypeTransform(val);

      this.get2DCanvasParams(
        this.params_stage,
        this.target_obj,
        244,
        244,
        "symbol_params",
        "symbol_params_canvas"
      );
    },
    cancelCopyDevice() {
      this.copyToVisible = false;
      this.copy_library_id = "";
      this.copy_library_type = 0;
      this.copy_team_id = "";
    },
    async copyToOpened() {
      this.teamOptionList = await this.getTeamOptions();
      this.libraryOptions = await this.getLibraryOptions();
    },
    async getTeamOptions() {
      let all_data = [];
      let team_res = await getTeamList_api({
        start: 0,
        rows: 50,
        key_word: "",
      });
      if (team_res.code == 200000) {
        let team_objs = team_res.data.data;
        for (let i = 0; i < team_objs.length; i++) {
          let team_obj = {
            value: team_objs[i].snow_team_id,
            label: team_objs[i].team_name,
            gns: team_objs[i].gns,
            snow_id: team_objs[i].snow_team_id,
            children: [],
          };
          all_data.push(team_obj);
        }
      }
      return all_data;
    },
    async getLibraryOptions() {
      let all_data = [];
      let res = await getSymbolList();
      if (res.code == 200000) {
        all_data = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
      return all_data;
    },
    async submitCopySymbol(name) {
      let fileData_ = await getObjectDataFn(this.currentSymbol.file_url);
      let saveSymbol = Kernel.parse_file(fileData_)[0];
      if (saveSymbol.type.value === 1) {
        saveSymbol = saveSymbol.deep_copy(0);
      } else if (saveSymbol.type.value === 2) {
        saveSymbol = copyComponentCellFn(saveSymbol);
      }

      let binaryDataObj = {};
      let cellBinaryDataObj = {};
      let associatedCellList = [saveSymbol];
      if (saveSymbol.$$?.ptrType.name.replace("*", "") == "CapSymbol") {
        associatedCellList = associatedCellList.concat(
          saveSymbol.depend_symbol_schemas(-1)
        );
      }
      for (let i = 0; i < associatedCellList.length; i++) {
        cellBinaryDataObj = associatedCellList[i].dump_file(true, 0);
        binaryDataObj = { ...binaryDataObj, ...cellBinaryDataObj };
      }

      let cellInfoList = binaryDataToUTF8(binaryDataObj);
      let u8ArrayList = [];
      cellInfoList.forEach((item) => {
        u8ArrayList.push(item.data_u8);
      });
      let u8Array = mergeU8Array(u8ArrayList);

      let formData = new FormData();
      formData.append("name", name || this.currentSymbol.name);
      formData.append("label_list", this.currentSymbol.label);
      formData.append("notes", this.currentSymbol.notes);
      formData.append("is_cover", 0);
      formData.append("content", new Blob([u8Array]));
      formData.append("symbol_type", "1");
      formData.append(
        "cell_component_type",
        this.currentSymbol.cell_component_type
      );
      // formData.append('library_snow_id', this.copy_library_id)
      let res = {};
      if (this.copy_library_type == 0) {
        formData.append("library_snow_id", this.copy_library_id);
        res = await saveMySymbol_api(formData);
      } else if (this.copy_library_type == 1) {
        formData.append("team_id", this.copy_team_id);
        res = await saveTeamSymbol_api(formData);
      }
      if (res.code == 200000) {
        if (res.data.name_exists && res.data.name_exists.length > 0) {
          let name_fb = (name += "(1)");
          await submitCopyDevice(name_fb);
        } else {
          this.$message.success(this.$t("messages.saveSuccess"));
          this.cancelCopyDevice();
        }
      }
    },
  },
  created() {
    this.mineSymbolClick();
  },
  mounted() {
    this.layerOptions = this.all_layer.map((item) => {
      return { label: item.layerName, value: item.layerNumber };
    });
    bus.$on("initSymbolTab", (data) => {
      this.mineSymbolClick();
    });
    bus.$on("symbolVueUpdatePDK", async (project_gns) => {
      await this.getPDK(project_gns);
      if (this.selectPDKSymbol) {
        this.start = 0;
        this.symbolType = 1;
        this.symbolList = [];
        this.symbolSearch = true;
        this.next_page = true;
        this.selectStatus = false;
        this.selectIndex = null;
        this.note = "-";
        this.selectSearchKeyType = 1;
        this.selectObj = null;
        await this.getSymbolPDKData();
      }
    });
  },
  watch: {
    selectParamsDevice: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = +38;
        } else {
          t_h = -38;
        }
        this.tableHeight += t_h;
        document.getElementById("symbol_list").style.height =
          this.tableHeight + "px";
      },
    },
    symbolList: {
      deep: true,
      handler(val) {
        //NOSONAR
        if (this.selectTeamSymbol || this.selectMineSymbol) {
          document.getElementById("symbol_list").style.height = "472px";
        } else {
          document.getElementById("symbol_list").style.height = "513px";
        }
      },
    },
    deviceNoteSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -30;
        } else {
          t_h = +30;
        }
        this.tableHeight += t_h;
        document.getElementById("symbol_list").style.height =
          this.tableHeight + "px";
      },
    },
    deviceMarkSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -150;
        } else {
          t_h = +150;
        }
        this.tableHeight += t_h;
        document.getElementById("symbol_list").style.height =
          this.tableHeight + "px";
      },
    },
    deviceDSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -140;
        } else {
          t_h = 140;
        }
        this.tableHeight += t_h;
        document.getElementById("symbol_list").style.height =
          this.tableHeight + "px";
      },
    },
  },
};
</script>
<style lang="less" scoped>
.device {
  margin-top: 1px;
  border: 1px solid #dddddd;
}
.header-tabs {
  display: flex;
  width: 100%;
  background: #f4f4f4;
  border-bottom: 1px solid #dddddd;
  .header-tabs__item {
    padding: 7px;
    background: #f4f4f4;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }
  .active {
    background: #fff;
    position: relative;
    top: 1px;
  }
}
.device-menu {
  height: auto;
  padding-left: 10px;
  padding-top: 5px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
  .device-menu__menu {
    margin-bottom: 5px;
    max-height: 21px;
    span {
      font-size: 13px;
      font-weight: bold;
      color: #333333;
    }
    .menu-icon__menu {
      width: 151px;
      background: #ffffff;
      border: 1px solid #dddddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      z-index: 9999;
      position: absolute;
      margin-top: 3px;
      padding: 5px 0;
      .menu-icon__menu__item {
        background: #fefefe;
        padding: 8px 20px;
        font-size: 12px;
      }
      .menu-icon__menu__item:hover {
        background: #dce6f8;
        cursor: pointer;
      }
      .active {
        color: #0c7de6;
      }
    }
  }
  .menu-icon {
    vertical-align: middle;
    margin-right: 12px;
    cursor: pointer;
  }
  .menu-select {
    width: 93%;
    margin: 5px 0;
    /deep/ .el-input__inner {
      background: transparent !important;
      border-radius: 1px !important;
    }
  }
  .menu-input {
    width: 66%;
    margin-bottom: 5px;
    /deep/ .el-input__inner {
      background: transparent !important;
      border-radius: 4px !important;
    }
  }
  .default {
    cursor: pointer;
    background: transparent;
    border: 1px solid #0c7de6;
    border-radius: 4px;
    width: 58px;
    height: 28px;
    font-size: 13px;
    font-weight: 400;
    color: #0c7de6;
    margin-left: 12px;
  }
}
.clicked {
  background: #dce6f8;
}
.list-item {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  .list-item__name {
    display: inline-block;
    width: calc(100% - 45px);
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    text-indent: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: 4px;
  }
  .list-item__button {
    width: 45px;
    text-align: center;
    display: inline-block;
    box-shadow: -2px 0px 10px 0px rgba(0, 47, 132, 0.1);
  }
  .nopower {
    color: #dddddd !important;
  }
}
.list-item:hover {
  background: #dce6f8;
}
.device-list {
  .device-list__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    div {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      padding: 5px;
    }
  }
  /deep/ .el-table .el-table__cell {
    padding: 0 !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
}
.device-note {
  .device-note__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .arrow {
      position: absolute;
      top: 0;
      right: 8px;
      cursor: pointer;
    }
    div {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      padding: 5px;
    }
  }
  .device-note__body {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #dddddd;
    .body-info {
      width: calc(100% - 45px);
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      top: -2px;
      color: #333333;
      padding-top: 8px;
      text-indent: 10px;
      cursor: auto;
    }
    .body-btn {
      width: 45px;
      background: #ffffff;
      box-shadow: -2px 0px 10px 0px rgba(0, 47, 132, 0.1);
      font-size: 13px;
      font-weight: 400;
      color: #0c7de6;
      text-align: center;
      padding: 5px 5px;
      cursor: pointer;
    }
  }
}
.device-mark {
  .device-mark__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .arrow {
      position: absolute;
      top: 0;
      right: 8px;
      cursor: pointer;
    }
    div {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      padding: 5px;
    }
  }
  .device-mark__body {
    text-align: center;
    border-bottom: 1px solid #dddddd;
    img {
      height: 120px;
    }
  }
}
.device-2D {
  .device-2D__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .arrow {
      position: absolute;
      top: 5px;
      right: 12px;
      cursor: pointer;
    }
    .Dheader-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
    }
    div {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .device-2D__body {
    height: 180px;
    text-align: center;
    img {
      height: 125px;
    }
  }
}
/deep/ .el-dialog__header {
  width: 100%;
}
/deep/ .el-dialog__body {
  padding: 10px 20px !important;
  font-size: 13px;
  .dialog-body {
    display: flex;
  }
}
.note-edit-input {
  margin-top: 10px;
}
.el-button--cancel {
  background: #b0b0b0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.el-button--sure {
  background: #4385f2;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.input-with-select {
  width: 100px;
  height: 30px;
  display: inline-block;
  /deep/ .el-input__inner {
    height: 30px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #659cf7 !important;
    padding: 5px 10px !important;
    color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
  /deep/ .el-input-group__prepend {
    border: none !important;
  }
  /deep/ .el-input__prefix,
  .el-input__suffix {
    height: 30px;
  }
  /deep/ .el-select__caret {
    color: #fff !important;
  }
  /deep/ .el-input__suffix {
    top: 5px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }
  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
}
.input-with-input {
  width: 170px;
  height: 30px;
  position: relative;
  display: inline-block;
  left: -2px;
  /deep/ .el-input__inner {
    height: 30px;
    font-size: 12px !important;
    background: transparent;
    padding: 5px 10px !important;
    border-radius: 0px 5px 5px 0px;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 580px;
  display: inline-block;
  margin-right: 11px;
  max-height: 770px;
  height: auto;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .inline-title {
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 10px;
      margin-right: 5px;
    }
    div {
      font-size: 13px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rile-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input-number {
            width: auto;
          }
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 60px;
        }
        /deep/ .el-input-number {
          width: auto;
        }
        .el-input {
          width: auto;
        }
        /deep/ .el-input__inner {
          width: 60px;
          height: 28px;
          line-height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
      .main-group2 {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 35%;
          .icon-en {
            color: #4385f2;
            cursor: pointer;
          }
        }
        .main-params {
          display: inline-block;
        }
        .el-input {
          width: 73%;
        }
        /deep/ .el-input__inner {
          height: 34px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          display: inline-block;
        }
        /deep/ .el-input__inner[disabled],
        input:disabled,
        input.disabled {
          background: #cccccc !important;
          -webkit-opacity: 1;
          opacity: 1;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input {
          width: 300px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
}
.symbol_params {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  min-height: 330px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .symbol_params__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.symbol_params__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  overflow-y: auto;
  #ann-image {
    margin: auto;
  }
  #symbol_params_canvas {
    margin: auto;
  }
  .ann-image {
    width: 100%;
    margin: auto;
  }
}
.el-select-dropdown__item {
  padding-left: 5px !important;
}
.port-item {
  display: inline-block;
  margin-right: 17px;
  padding: 7px 0;
}
.params-item {
  padding: 7px 0;
  position: relative;
  .unit {
    width: 90px;
    text-align: right;
    display: inline-block;
    position: absolute;
    right: 80px;
    padding: 10px;
    font-size: 12px;
    color: #999999;
  }
}
.export-group {
  width: 100%;
  display: flex;
  margin: 10px;
  font-size: 13px;
  color: #333333;
  align-items: center;
  justify-content: space-between;
  // .group-txt {
  //   width: 20%;
  //   position: relative;
  //   top: 6px;
  // }
}
</style>
