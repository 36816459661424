import bus from '@/components/common/bus'

class ContextMenu {
  //右键菜单类
  constructor(label, divided, icon, disabled) {
    this.label = label
    this.divided = divided
    this.icon = icon
    this.disabled = disabled
  }
}

export class CreateCellOption extends ContextMenu {
  //新建类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuCreateCell')
    // bus.$emit("saveAs")//test
    // bus.$emit("contextMenuSaveToLib")//test
  }
}

export class ReNameOption extends ContextMenu {
  //重命名类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuReName')
  }
}

export class DeleteOption extends ContextMenu {
  //删除类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuDelete')
  }
}

export class CopyOption extends ContextMenu {
  //复制类
  constructor(label, divided, icon, disabled, copy_type, copy_data) {
    super(label, divided, icon, disabled)
    this.copy_type = copy_type
    this.copy_data = copy_data
  }
  onClick() {
    bus.$emit('contextMenuCopy', { copy_type: this.copy_type, copy_data: this.copy_data })
    // bus.$emit("transmitCopyToData", this.copy_data)
  }
}

export class SaveToLibOption extends ContextMenu {
  //存到器件库类
  constructor(label, divided, icon, disabled, node_data) {
    super(label, divided, icon, disabled)
    this.node_data = node_data
  }
  onClick() {
    bus.$emit('contextMenuSaveToLib', this.node_data)
  }
}

export class InstanceOption extends ContextMenu {
  //调用类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuInstance', true)
  }
}

export class ReadOnlyOption extends ContextMenu {
  //只读类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuReadOnly', true)
  }
}

export class SaveToSymbolLibOption extends ContextMenu {
  //存到符号库类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuSaveToSymbolLib')
  }
}

export class SymbolReplaceOption extends ContextMenu {
  //符号替换类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuSymbolReplace')
  }
}

export class AddLayerOption extends ContextMenu {
  //添加图层类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuAddLayerOption')
  }
}
export class DeleteLayerOption extends ContextMenu {
  //删除图层类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuDeleteLayer')
  }
}
export class MergeLayerOption extends ContextMenu {
  //合并图层类
  constructor(label, divided, icon, disabled) {
    super(label, divided, icon, disabled)
  }
  onClick() {
    bus.$emit('contextMenuMergeLayer')
  }
}
export class ListInfoOption extends ContextMenu {
  //列表信息类
  constructor(label, divided, icon, disabled, children) {
    super(label, divided, icon, disabled)
    this.children = children
  }
  onClick() {
    bus.$emit('contextMenuListInfo')
  }
}

export class HideLayerOption extends ContextMenu {
  //隐藏图层类
  constructor(label, divided, icon, isShowHide, disabled) {
    super(label, divided, disabled)
    this.icon = isShowHide ? icon : ''
  }
  onClick() {
    bus.$emit('contextMenuHideLayer')
  }
}

export class LockLayerOption extends ContextMenu {
  //锁定图层类
  constructor(label, divided, icon, isShowLock, disabled) {
    super(label, divided, disabled)
    this.icon = isShowLock ? icon : ''
  }
  onClick() {
    bus.$emit('contextMenuLockLayer')
  }
}

export class OutputLayerOption extends ContextMenu {
  //输出图层类
  constructor(label, divided, icon, isShowExport, disabled) {
    super(label, divided, disabled)
    this.icon = isShowExport ? icon : ''
  }
  onClick() {
    bus.$emit('contextMenuOutputLayer')
  }
}

export class NumberLayerOption extends ContextMenu {
  //图层编号类
  constructor(label, divided, icon, isShowNumber, disabled) {
    super(label, divided, disabled)
    this.icon = isShowNumber ? icon : ''
  }
  onClick() {
    bus.$emit('contextMenuNumberLayer')
  }
}
