<template>
  <div>
    <div class="side">
      <!-- 版图工具栏 -->
      <div class="layoutToolbar">
        <!-- 简单编辑图标 -->
        <div class="simpleEdit_layout">
          <div class="toolbarIcon" v-for="(item, index) in editIcon_layout" :key="index">
            <button @click="simpleEditActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
              @mouseleave="item.isShowInfo = false">
              <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
              <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
            </button>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="middleLine"></div>
        <!-- 画板属性  -->
        <div class="boardProperty_layout">
          <el-checkbox v-model="adsorption" @change="openAdsorption">{{ $t('messages.adsorption') }}</el-checkbox>
          <div class="toolbarIcon" v-for="(item, index) in boardPropertyIcon_layout" :key="index">
            <button @click="boardPropertyActiveFn(index)" @mouseenter="item.isShowInfo = true"
              @mouseleave="item.isShowInfo = false">
              <img alt="" :src="!item.changeSelect ? item.src2 : item.src1" v-if="!item.btnDisabled" />
              <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
              <div class="dropInfo" v-if="item.isShowInfo">
                <div v-if="item.changeSelect">{{ $t('messages.' + item.cn_en) }}</div>
                <div v-else>{{ $t('messages.' + item.cn_en2) }}</div>
              </div>
            </button>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="middleLine"></div>
        <!-- 吸附精度调整 -->
        <div class="precisionAdjustment_layout">
          <div class="inputArea">
            <span style="padding: 0 5px">{{ $t('messages.gridAdsorptionAccuracy') }}:</span>
            <el-input v-model="precision" @focus="getPrecisionOld" @blur="getPrecision"></el-input>
            <span style="padding-right: 5px">um</span>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="middleLine"></div>
        <!-- 绘图图标 -->
        <div class="draw_layout">
          <div class="toolbarIcon" v-for="(item, index) in drawIcon_layout" :key="index">
            <button @click="drawActiveFn(index)" @mouseenter="item.isShowInfo = true"
              @mouseleave="item.isShowInfo = false">
              <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
              <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
            </button>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="middleLine"></div>
        <!-- 复杂编辑图标 -->
        <div class="complexEdit_layout">
          <div class="toolbarIcon" v-for="(item, index) in complexEditIcon_layout" :key="index">
            <button onclick='this.blur()' @click="complexEditActiveFn(index)"
              v-if="item.label != '水平翻转' && item.label != '垂直翻转' && item.label != '线对齐' && item.label != '点对齐'"
              @mouseenter="item.isShowInfo = true" @mouseleave="item.isShowInfo = false">
              <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
              <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
            </button>
            <button @click="flipSelectBtnClickFn(item)" v-else-if="item.label == '水平翻转' || item.label == '垂直翻转'"
              @mouseenter="item.isShowInfo = true" @mouseleave="
                item.isShowInfo = false
                item.flipSelect = false
              ">
              <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
              <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
              <div class="dropMenu" v-if="item.flipSelect">
                <div class="dropItem" @click="dropItemClickFn('horizontalFlipDialog', index)">
                  <span style="font-size: 20px; padding-right: 6px" class="iconfont icon-shuipingfanzhuan"></span>
                  <span>{{ $t('messages.FlipHorizontal') }}</span>
                </div>
                <div class="dropItem" @click="dropItemClickFn('verticalFlipDialog', index)">
                  <span style="font-size: 20px; padding-right: 6px" class="iconfont icon-chuizhifanzhuan"></span>
                  <span>{{ $t('messages.FlipVertical') }}</span>
                </div>
              </div>
            </button>
            <button @click="alignmentSelectBtnClickFn(item)" v-else-if="item.label == '线对齐' || item.label == '点对齐'"
              @mouseenter="item.isShowInfo = true" @mouseleave="
                item.isShowInfo = false
                item.alignmentSelect = false
              ">
              <img alt="" :src="item.isActive ? item.src2 : item.src1" v-if="!item.btnDisabled" />
              <img alt="" :src="!item.btnDisabled ? item.src2 : item.src3" v-if="item.btnDisabled" />
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
              <div class="dropMenu" v-if="item.alignmentSelect" @mouseenter="item.isShowInfo = false">
                <div class="dropItem" @click="dropItemClickFn('pointAlignmentDialog', index)">
                  <span style="font-size: 20px; padding-right: 6px" class="iconfont icon-dianduiqi"></span>
                  <span>{{ $t('messages.PointAlign') }}</span>
                </div>
                <div class="dropItem" @click="dropItemClickFn('lineAlignmentDialog', index)">
                  <span style="font-size: 20px; padding-right: 6px" class="iconfont icon-xianduiqi"></span>
                  <span>{{ $t('messages.LineAlign') }}</span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="middleLine"></div>
        <!-- 坐标 -->
        <div class="coordinate_layout" v-if="showCoordinate">
          <span style="padding-left: 10px">X:</span>
          <span style="padding-right: 12px; min-width: 40px">{{ x }}</span>
          <span>Y:</span>
          <span style="min-width: 40px">{{ y }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "../../common/bus";
export default {
  props: {
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      //只读
      isReadOnly: false,
      //版图对应工具栏
      // 简单编辑图标
      editIcon_layout: [
        {
          src1: require("../../../assets/icons/sidebar/保存.svg"), //未激活图标
          src2: require("../../../assets/icons/sidebar/保存2.svg"), //激活图标
          src3: require("../../../assets/icons/sidebar/保存3.svg"), //禁用图标
          label: "保存", //提示信息
          cn_en: "save", //中英文切换
          isActive: false, //是否激活
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "save", //调用方法
        },
        {
          src1: require("../../../assets/icons/sidebar/剪切.svg"),
          src2: require("../../../assets/icons/sidebar/剪切2.svg"),
          src3: require("../../../assets/icons/sidebar/剪切3.svg"),
          label: "剪切",
          cn_en: "cut",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "cut",
        },
        {
          src1: require("../../../assets/icons/sidebar/复制.svg"),
          src2: require("../../../assets/icons/sidebar/复制2.svg"),
          src3: require("../../../assets/icons/sidebar/复制3.svg"),
          label: "复制",
          cn_en: "copy",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "copy",
        },
        {
          src1: require("../../../assets/icons/sidebar/粘贴.svg"),
          src2: require("../../../assets/icons/sidebar/粘贴2.svg"),
          src3: require("../../../assets/icons/sidebar/粘贴3.svg"),
          label: "粘贴",
          cn_en: "paste",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "paste",
        },
        {
          src1: require("../../../assets/icons/sidebar/撤销.svg"),
          src2: require("../../../assets/icons/sidebar/撤销2.svg"),
          src3: require("../../../assets/icons/sidebar/撤销3.svg"),
          label: "撤销",
          cn_en: "undo",
          isActive: false,
          btnDisabled: true,
          isShowInfo: false,
          func: "undo",
        },
        {
          src1: require("../../../assets/icons/sidebar/恢复.svg"),
          src2: require("../../../assets/icons/sidebar/恢复2.svg"),
          src3: require("../../../assets/icons/sidebar/恢复3.svg"),
          label: "恢复",
          cn_en: "redo",
          isActive: false,
          btnDisabled: true,
          isShowInfo: false,
          func: "redo",
        },
      ],
      adsorption: false, //吸附
      boardPropertyIcon_layout: [
        {
          src1: require("../../../assets/icons/sidebar/任意角度.svg"),
          src2: require("../../../assets/icons/sidebar/斜角吸附.svg"),
          src3: require("../../../assets/icons/sidebar/任意角度2.svg"),
          label: "任意角度",
          cn_en: "anyAngle",
          label2: "斜角吸附",
          cn_en2: "obliqueAdsorption",
          changeSelect: true,
          btnDisabled: false,
          isShowInfo: false,
          func: "angle",
        },
        {
          src1: require("../../../assets/icons/sidebar/角度锁定.svg"),
          src2: require("../../../assets/icons/sidebar/任意拉伸.svg"),
          src3: require("../../../assets/icons/sidebar/角度锁定2.svg"),
          label: "角度锁定",
          cn_en: "angleLock",
          label2: "任意拉伸",
          cn_en2: "anyStretch",
          changeSelect: true,
          btnDisabled: false,
          isShowInfo: false,
          func: "angleLock",
        },
        {
          src1: require("../../../assets/icons/sidebar/整体选择.svg"),
          src2: require("../../../assets/icons/sidebar/部分选择.svg"),
          src3: require("../../../assets/icons/sidebar/整体选择2.svg"),
          label: "整体选择",
          cn_en: "FullSelect",
          label2: "部分选择",
          cn_en2: "PartialSelect",
          changeSelect: true,
          btnDisabled: false,
          isShowInfo: false,
          func: "selectBox",
        },
      ],
      //格点吸附精度
      precision: 1,
      precisionOld: null,
      // 绘图部分图标
      drawIcon_layout: [
        {
          src1: require("../../../assets/icons/sidebar/线.svg"),
          src2: require("../../../assets/icons/sidebar/线2.svg"),
          src3: require("../../../assets/icons/sidebar/线3.svg"),
          label: "线",
          cn_en: "Line",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "dogleg",
        },
        {
          src1: require("../../../assets/icons/sidebar/圆.svg"),
          src2: require("../../../assets/icons/sidebar/圆2.svg"),
          src3: require("../../../assets/icons/sidebar/圆3.svg"),
          label: "圆",
          cn_en: "Circle",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "circle",
        },
        {
          src1: require("../../../assets/icons/sidebar/矩形.svg"),
          src2: require("../../../assets/icons/sidebar/矩形2.svg"),
          src3: require("../../../assets/icons/sidebar/矩形3.svg"),
          label: "矩形",
          cn_en: "Rectangle",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "rectangle",
        },
        {
          src1: require("../../../assets/icons/sidebar/多边形.svg"),
          src2: require("../../../assets/icons/sidebar/多边形2.svg"),
          src3: require("../../../assets/icons/sidebar/多边形3.svg"),
          label: "多边形",
          cn_en: "Polygon",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "polygon",
        },
        {
          src1: require("../../../assets/icons/sidebar/文本图形.svg"),
          src2: require("../../../assets/icons/sidebar/文本图形2.svg"),
          src3: require("../../../assets/icons/sidebar/文本图形3.svg"),
          label: "文本图形",
          cn_en: "Text",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "textGraphics",
        },
      ],
      isDraw: false,
      //复杂编辑
      complexEditIcon_layout: [
        {
          src1: require("../../../assets/icons/sidebar/移动.svg"),
          src2: require("../../../assets/icons/sidebar/移动2.svg"),
          src3: require("../../../assets/icons/sidebar/移动3.svg"),
          label: "移动",
          cn_en: "Move",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "move",
        },
        {
          src1: require("../../../assets/icons/sidebar/拉伸.svg"),
          src2: require("../../../assets/icons/sidebar/拉伸2.svg"),
          src3: require("../../../assets/icons/sidebar/拉伸3.svg"),
          label: "拉伸",
          cn_en: "draw",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "stretch",
        },
        {
          src1: require("../../../assets/icons/sidebar/旋转.svg"),
          src2: require("../../../assets/icons/sidebar/旋转2.svg"),
          src3: require("../../../assets/icons/sidebar/旋转3.svg"),
          label: "旋转",
          cn_en: "Rotate",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "rotate",
        },
        {
          src1: require("../../../assets/icons/sidebar/左右翻转-角标.svg"),
          src2: require("../../../assets/icons/sidebar/左右翻转-角标2.svg"),
          src3: require("../../../assets/icons/sidebar/左右翻转-角标3.svg"),
          label: "水平翻转",
          cn_en: "FlipHorizontal",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          flipSelect: false,
        },
        {
          src1: require("../../../assets/icons/sidebar/合并.svg"),
          src2: require("../../../assets/icons/sidebar/合并2.svg"),
          src3: require("../../../assets/icons/sidebar/合并3.svg"),
          label: "合并",
          cn_en: "Merge",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "merge",
        },
        {
          src1: require("../../../assets/icons/sidebar/区域切除.svg"),
          src2: require("../../../assets/icons/sidebar/区域切除2.svg"),
          src3: require("../../../assets/icons/sidebar/区域切除3.svg"),
          label: "区域切除",
          cn_en: "Nibble",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "areaExcision",
        },
        {
          src1: require("../../../assets/icons/sidebar/切割.svg"),
          src2: require("../../../assets/icons/sidebar/切割2.svg"),
          src3: require("../../../assets/icons/sidebar/切割3.svg"),
          label: "切割",
          cn_en: "Slice",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "cutting",
        },
        {
          src1: require("../../../assets/icons/sidebar/点对齐-角标.svg"),
          src2: require("../../../assets/icons/sidebar/点对齐-角标2.svg"),
          src3: require("../../../assets/icons/sidebar/点对齐-角标3.svg"),
          label: "点对齐",
          cn_en: "PointAlign",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          alignmentSelect: false,
        },
        {
          src1: require("../../../assets/icons/sidebar/左对齐.svg"),
          src2: require("../../../assets/icons/sidebar/左对齐2.svg"),
          src3: require("../../../assets/icons/sidebar/左对齐3.svg"),
          label: "对齐",
          cn_en: "Align",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "alignment",
        },
        {
          src1: require("../../../assets/icons/sidebar/布尔运算.svg"),
          src2: require("../../../assets/icons/sidebar/布尔运算2.svg"),
          src3: require("../../../assets/icons/sidebar/布尔运算3.svg"),
          label: "布尔运算",
          cn_en: "Boolean",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "boolean",
        },
        {
          src1: require("../../../assets/icons/sidebar/阵列.svg"),
          src2: require("../../../assets/icons/sidebar/阵列2.svg"),
          src3: require("../../../assets/icons/sidebar/阵列3.svg"),
          label: "阵列复制",
          cn_en: "ArrayCopy",
          isActive: false,
          btnDisabled: false,
          isShowInfo: false,
          func: "matrix",
        },
      ],
      x: 0,
      y: 0,
      showCoordinate: true,
      FileActive: true,
      batchCreateCell: false, //是否添加了器件需要批量创建cell
    };
  },
  mounted() {
    let that = this;
    this.isReadOnly = that.readOnly;
    bus.$on("openCoordinate", (data) => {
      this.showCoordinate = !this.showCoordinate;
    });
    this.$bus.$on("changeLang", (data) => {
      // ];
    });
    bus.$on("cancelSidebarSelect", (data) => {
      this.cancelSelectAll();
    });
    bus.$on("siderBarActive", (data) => {
      if (data === "move") {
        that.complexEditIcon_layout[0].isActive = true;
      } else if (data === "stretch") {
        that.complexEditIcon_layout[1].isActive = true;
        this.boardPropertyIcon_layout[2].changeSelect = false;
        setTimeout(() => {
          this.cancelSelectAll();
        }, 200);
      } else if (data === "overallSelection") {
        this.boardPropertyIcon_layout[2].changeSelect = true;
      } else if (data === "partialSelection") {
        this.boardPropertyIcon_layout[2].changeSelect = false;
      } else if (data === "rotate") {
        this.complexEditIcon_layout[2].isActive = true;
      } else if (data === "dogleg") {
        this.cancelSelectAll();
        this.drawIcon_layout[0].isActive = true;
      } else if (data === "rectangle") {
        this.cancelSelectAll();
        this.drawIcon_layout[2].isActive = true;
      } else if (data === "polygon") {
        this.cancelSelectAll();
        this.drawIcon_layout[3].isActive = true;
      } else if (data === "circle") {
        this.cancelSelectAll();
        this.drawIcon_layout[1].isActive = true;
      } else if (data === "textGraphics") {
        this.cancelSelectAll();
        this.drawIcon_layout[4].isActive = true;
      }
    });
    bus.$on("getMousePoint", (data) => {
      this.x = data[0];
      this.y = data[1];
    });
    bus.$on("getCurrentLayout", (data) => {
      if (data !== null) {
        let redoLen = data.HISTORY.redoStack.length;
        let undoLen = data.HISTORY.undoStack.length;
        if (redoLen === 0) {
          this.editIcon_layout[5].btnDisabled = true;
        } else if (redoLen > 0) {
          this.editIcon_layout[5].btnDisabled = false;
        }
        if (undoLen === 0) {
          this.editIcon_layout[4].btnDisabled = true;
        } else if (undoLen > 0) {
          this.editIcon_layout[4].btnDisabled = false;
        }
      }
    });
    // bus.$on("updateFileTree", (data) => {
    //   if (Array.isArray(data)) {
    //     this.batchCreateCell = true;
    //   }
    // });
    bus.$on("boardDesign", (data) => {
      this.precision = this.$store.state.precision;
    });
    bus.$on("esc", (data) => {
      this.cancelSelectAll();
      if (this.boardPropertyIcon_layout[2].changeSelect) {
        bus.$emit("overallSelection", true);
      } else {
        bus.$emit("partialSelection", true);
      }
    });

    document.addEventListener("keydown", this.keyDown);
  },
  beforeDestroy() {
    bus.$off("openCoordinate");
    bus.$off("cancelSidebarSelect");
    bus.$off("siderBarActive");
    bus.$off("getMousePoint");
    // bus.$off("updateFileTree");
    bus.$off("getCurrentLayout");
    bus.$off("boardDesign");
    bus.$off("esc");
    document.removeEventListener("keydown", this.keyDown);
  },
  methods: {
    keyDown(e) {
      let that = this;
      if (
        that.$store.state.cellInfo.cell_type == "1" ||
        that.$store.state.cellInfo.cell_type == "2"
      ) {
        const key = e.key.toLowerCase();
        let isInput = false;
        let objs = document.querySelectorAll("div[aria-label]"); //''对象编辑
        //判断弹窗是否打开
        objs.forEach((obj) => {
          if (obj.getAttribute("role") == "dialog") {
            if (obj.parentNode.style.display !== "none") {
              isInput = true;
            }
          }
        });
        if (!isInput) {
          if (key == "l") {
            bus.$emit("label", true);
          } else if (
            key == "s" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //拉伸
            bus.$emit("partialSelection", true);
            that.boardPropertyIcon_layout[2].changeSelect = false;
            that.complexEditIcon_layout[1].isActive = true;
            that.setSidebarStateFn();
            setTimeout(() => {
              that.cancelSelectAll();
            }, 200);
          } else if (
            key == "s" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //角度锁定
            if (!that.boardPropertyIcon_layout[2].changeSelect) {
              if (that.boardPropertyIcon_layout[1].changeSelect) {
                bus.$emit("anyStretch", true);
              } else {
                bus.$emit("lockStretch", true);
              }
              that.boardPropertyIcon_layout[1].changeSelect =
                !that.boardPropertyIcon_layout[1].changeSelect;
              that.setSidebarStateFn();
            }
          } else if (
            key == "s" &&
            e.shiftKey === false &&
            e.ctrlKey === true &&
            e.altKey === false
          ) {
            e.preventDefault();

            bus.$emit("saveFile", true);
          } else if (
            key == "f" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //选择模式
            if (that.boardPropertyIcon_layout[2].changeSelect) {
              bus.$emit("partialSelection", true);
            } else {
              bus.$emit("overallSelection", true);
            }
            that.boardPropertyIcon_layout[2].changeSelect =
              !that.boardPropertyIcon_layout[2].changeSelect;
            that.setSidebarStateFn();
          } else if (
            key == "g" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //吸附
            that.adsorption = !that.adsorption;
            that.$store.commit("setAdsorptionState", that.adsorption);
            bus.$emit("adsorption", that.adsorption);
            that.setSidebarStateFn();
          } else if (
            key == "r" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //斜角吸附/任意角度
            that.boardPropertyIcon_layout[0].changeSelect =
              !that.boardPropertyIcon_layout[0].changeSelect;
            if (that.boardPropertyIcon_layout[0].changeSelect) {
              bus.$emit("anyAngle", true);
            } else {
              bus.$emit("angledAdsorption", true);
            }
            that.setSidebarStateFn();
          } else if (
            key == "e" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //线
            that.cancelSelectAll();
            bus.$emit("func", "dogleg");
            that.drawIcon_layout[0].isActive = true;
          } else if (
            key == "r" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //矩形
            that.cancelSelectAll();
            bus.$emit("func", "rectangle");
            that.drawIcon_layout[2].isActive = true;
          } else if (
            key == "p" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //多边形
            that.cancelSelectAll();
            bus.$emit("func", "polygon");
            that.drawIcon_layout[3].isActive = true;
          } else if (
            key == "p" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            bus.$emit("keyPoint", true);
          } else if (
            key == "a" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //快速对齐
            that.complexEditIcon_layout[7].alignmentSelect = true;
          } else if (
            key == "v" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //画板缩小
            bus.$emit("boardReduction", true);
          } else if (
            key == "v" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //区域放大
            bus.$emit("regionalAmplification", true);
          } else if (
            key == "x" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //进入下层
            bus.$emit("enterlowerLevel", true);
          } else if (
            key == "x" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //编辑下层
            bus.$emit("editLowerLevel", true);
          } else if (
            key == "b" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //返回上层
            bus.$emit("backUpperLevel", true);
          } else if (
            key == "q" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //打开属性面板
            bus.$emit("openAttrsDialog", true);
          } else if (
            key == "r" &&
            e.shiftKey === true &&
            e.ctrlKey === false &&
            e.altKey === true
          ) {
            //旋转
            that.complexEditIcon_layout[2].isActive = true;
            bus.$emit("rotate", true);
          }
          if (
            key == "escape" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //退出弹窗
            that.cancelSelectAll();
            if (that.boardPropertyIcon_layout[2].changeSelect) {
              bus.$emit("overallSelection", true);
            } else {
              bus.$emit("partialSelection", true);
            }
          }
        } else {
          if (
            key == "escape" &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false
          ) {
            //退出弹窗
            bus.$emit("closeDialog", true);
            that.cancelSelectAll();
            if (that.boardPropertyIcon_layout[2].changeSelect) {
              bus.$emit("overallSelection", true);
            } else {
              bus.$emit("partialSelection", true);
            }
          }
        }
      }
    },

    simpleEditActiveFn(item, index) {
      if (!item.btnDisabled) {
        this.editIcon_layout[index].isActive = true;
        if (this.editIcon_layout[index].func == "save") {
          // this.returnToSelect();
          // bus.$emit("save", true);

          // if (this.batchCreateCell) {
          //   bus.$emit("saveFile", true);
          //   this.batchCreateCell = false;
          // }
          this.cancelSelectAll();
          this.returnToSelect();
          bus.$emit("saveFile", true);
        } else if (this.editIcon_layout[index].func == "cut") {
          if (this.isDraw) {
            this.cancelSelectAll();
            this.returnToSelect();
          } else {
            bus.$emit("cut", true);
          }
        } else if (this.editIcon_layout[index].func == "copy") {
          if (this.isDraw) {
            this.cancelSelectAll();
            this.returnToSelect();
          } else {
            bus.$emit("copy", true);
          }
        } else if (this.editIcon_layout[index].func == "paste") {
          if (this.isDraw) {
            this.cancelSelectAll();
            this.returnToSelect();
          } else {
            bus.$emit("paste", true);
          }
        } else if (this.editIcon_layout[index].func == "undo") {
          this.returnToSelect();
          bus.$emit("undo", true);
        } else if (this.editIcon_layout[index].func == "redo") {
          this.returnToSelect();
          bus.$emit("redo", true);
        }
        setTimeout(() => {
          this.cancelSelectAll();
        }, 200);
      }
    },
    openAdsorption() {
      this.$store.commit("setAdsorptionState", this.adsorption);
      bus.$emit("adsorption", this.adsorption);
    },
    // 获取格点吸附精度初始值
    getPrecisionOld() {
      this.precisionOld = this.$store.state.precision;
    },
    // 获取格点吸附精度
    getPrecision() {
      if (
        Number(this.precision) >= 0.001 &&
        Number(this.precision) <= 10000 &&
        /^[1-9]\d{0,4}(\.\d{1,3})?$|^0(\.\d{1,3})?$/.test(
          Number(this.precision)
        )
      ) {
        this.$store.commit("setPrecision", this.precision);
        bus.$emit("getPrecision", this.precision);
      } else {
        bus.$emit("errDialog", "吸附精度范围限制在0.001~10000um");
        this.precision = this.precisionOld;
      }
    },
    boardPropertyActiveFn(index) {
      this.cancelSelectAll();
      this.boardPropertyIcon_layout[index].changeSelect =
        !this.boardPropertyIcon_layout[index].changeSelect;
      if (this.boardPropertyIcon_layout[index].func === "angle") {
        if (this.boardPropertyIcon_layout[index].changeSelect) {
          bus.$emit("anyAngle", true);
        } else {
          bus.$emit("angledAdsorption", true);
        }
      } else if (this.boardPropertyIcon_layout[index].func === "angleLock") {
        if (this.boardPropertyIcon_layout[index].changeSelect) {
          bus.$emit("lockStretch", true);
        } else {
          bus.$emit("anyStretch", true);
        }
      } else if (this.boardPropertyIcon_layout[index].func === "selectBox") {
        if (this.boardPropertyIcon_layout[index].changeSelect) {
          bus.$emit("overallSelection", true);
        } else {
          bus.$emit("partialSelection", true);
        }
      }
      this.setSidebarStateFn();
    },
    setSidebarStateFn() {
      let borderPropertyState = [];
      this.boardPropertyIcon_layout.forEach((item) => {
        if (item.func == "angle") {
          if (item.changeSelect) {
            borderPropertyState.push({ func: "anyAngle" });
          } else {
            borderPropertyState.push({ func: "angledAdsorption" });
          }
        } else if (item.func == "angleLock") {
          if (item.changeSelect) {
            borderPropertyState.push({ func: "lockStretch" });
          } else {
            borderPropertyState.push({ func: "anyStretch" });
          }
        } else if (item.func == "selectBox") {
          if (item.changeSelect) {
            borderPropertyState.push({ func: "overallSelection" });
          } else {
            borderPropertyState.push({ func: "partialSelection" });
          }
        }
      });

      this.$store.commit("setSidebarState", borderPropertyState);
    },
    drawActiveFn(index) {
      this.cancelSelectAll();
      this.isDraw = true;
      bus.$emit("siderBarDrawActive", true);
      this.drawIcon_layout[index].isActive = true;
      if (this.drawIcon_layout[index].func !== "textGraphics") {
        bus.$emit("func", this.drawIcon_layout[index].func);
      } else {
        bus.$emit("textGraphics", true);
      }
    },
    complexEditActiveFn(index) {
      if (this.isDraw) {
        this.cancelSelectAll();
        this.returnToSelect();
      } else {
        this.cancelSelectAll();
        this.complexEditIcon_layout[index].isActive = true;
        if (this.complexEditIcon_layout[index].func == "move") {
          bus.$emit("move", true);
        } else if (this.complexEditIcon_layout[index].func == "stretch") {
          this.boardPropertyIcon_layout[2].changeSelect = false;
          bus.$emit("partialSelection", true);
          setTimeout(() => {
            this.cancelSelectAll();
          }, 200);
        } else if (this.complexEditIcon_layout[index].func == "rotate") {
          bus.$emit("rotate", true);
        } else if (this.complexEditIcon_layout[index].func == "merge") {
          bus.$emit("merge", true);
          setTimeout(() => {
            this.cancelSelectAll();
          }, 200);
        } else if (this.complexEditIcon_layout[index].func == "areaExcision") {
          bus.$emit("areaExcision", true);
          setTimeout(() => {
            this.cancelSelectAll();
          }, 200);
        } else if (this.complexEditIcon_layout[index].func == "cutting") {
          bus.$emit("cutting", true);
          setTimeout(() => {
            this.cancelSelectAll();
          }, 200);
        } else if (this.complexEditIcon_layout[index].func == "alignment") {
          bus.$emit("alignmentDialog", true);
        } else if (this.complexEditIcon_layout[index].func == "boolean") {
          bus.$emit("booleanDialog", true);
        } else if (this.complexEditIcon_layout[index].func == "matrix") {
          bus.$emit("matrixDialog", true);
        }
      }
    },
    flipSelectBtnClickFn(item) {
      if (this.isDraw) {
        this.cancelSelectAll();
        this.returnToSelect();
      } else {
        item.flipSelect = !item.flipSelect;
        item.isShowInfo = false;
      }
    },
    alignmentSelectBtnClickFn(item) {
      if (this.isDraw) {
        this.cancelSelectAll();
        this.returnToSelect();
      } else {
        item.alignmentSelect = !item.alignmentSelect;
        item.isShowInfo = false;
      }
    },
    dropItemClickFn(data, index) {
      this.cancelSelectAll();
      this.complexEditIcon_layout[index].isActive = true;
      bus.$emit(data, true);
      if (data == "horizontalFlipDialog") {
        this.complexEditIcon_layout[
          index
        ].src1 = require("../../../assets/icons/sidebar/左右翻转-角标.svg");
        this.complexEditIcon_layout[
          index
        ].src2 = require("../../../assets/icons/sidebar/左右翻转-角标2.svg");
        this.complexEditIcon_layout[index].label = "水平翻转";
        this.complexEditIcon_layout[index].cn_en = "FlipHorizontal";
      } else if (data == "verticalFlipDialog") {
        this.complexEditIcon_layout[
          index
        ].src1 = require("../../../assets/icons/sidebar/上下翻转-角标.svg");
        this.complexEditIcon_layout[
          index
        ].src2 = require("../../../assets/icons/sidebar/上下翻转-角标2.svg");
        this.complexEditIcon_layout[index].label = "垂直翻转";
        this.complexEditIcon_layout[index].cn_en = "FlipVertical";
      } else if (data == "pointAlignmentDialog") {
        this.complexEditIcon_layout[
          index
        ].src1 = require("../../../assets/icons/sidebar/点对齐-角标.svg");
        this.complexEditIcon_layout[
          index
        ].src2 = require("../../../assets/icons/sidebar/点对齐-角标2.svg");
        this.complexEditIcon_layout[index].label = "点对齐";
        this.complexEditIcon_layout[index].cn_en = "PointAlign";
      } else if (data == "lineAlignmentDialog") {
        this.complexEditIcon_layout[
          index
        ].src1 = require("../../../assets/icons/sidebar/线对齐-角标.svg");
        this.complexEditIcon_layout[
          index
        ].src2 = require("../../../assets/icons/sidebar/线对齐-角标2.svg");
        this.complexEditIcon_layout[index].label = "线对齐";
        this.complexEditIcon_layout[index].cn_en = "LineAlign";
      }
    },
    //取消所有图标的激活状态,进入选择模式
    cancelSelectAll() {
      this.editIcon_layout.forEach((item) => {
        item.isActive = false;
      });
      this.drawIcon_layout.forEach((item) => {
        item.isActive = false;
      });
      this.complexEditIcon_layout.forEach((item) => {
        item.isActive = false;
      });
      this.isDraw = false;
      this.complexEditIcon_layout[7].alignmentSelect = false;
    },
    //返回选择模式
    returnToSelect() {
      if (this.boardPropertyIcon_layout[2].changeSelect) {
        bus.$emit("overallSelection", true);
      } else {
        bus.$emit("partialSelection", true);
      }
    },
  },
  created() {
    this.$store.commit("setAdsorptionState", this.adsorption);
    this.$store.commit("setPrecision", this.precision);
    this.setSidebarStateFn();
  },
  computed: {
    cellInfo() {
      return JSON.parse(JSON.stringify(this.$store.state.cellInfo));
    },
  },
  watch: {
    cellInfo(val) {
      this.isReadOnly = val.isReadOnly;
    },
    isReadOnly(val) {
      if (val) {
        this.editIcon_layout.forEach((item) => {
          item.btnDisabled = true;
        });
        this.boardPropertyIcon_layout.forEach((item) => {
          item.btnDisabled = true;
        });
        this.drawIcon_layout.forEach((item) => {
          item.btnDisabled = true;
        });
        this.complexEditIcon_layout.forEach((item) => {
          item.btnDisabled = true;
        });
      } else {
        this.editIcon_layout.forEach((item) => {
          item.btnDisabled = false;
        });
        this.boardPropertyIcon_layout.forEach((item) => {
          item.btnDisabled = false;
        });
        this.drawIcon_layout.forEach((item) => {
          item.btnDisabled = false;
        });
        this.complexEditIcon_layout.forEach((item) => {
          item.btnDisabled = false;
        });
      }
    },
  },
};
</script>
<style>
/* 文字提示 */
.el-tooltip__popper.is-dark {
  background-color: rgb(189, 185, 185) !important;
  color: #fff !important;
}
</style>
<style lang="less" scoped>
.hideSide {
  height: 0px;
}
.side {
  width: 100%;
  height: 30px;
  user-select: none;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: flex-start;
  .layoutToolbar {
    font-size: 12px;
    width: 100%;
    height: 30px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.17);
    z-index: 999;
    background: #ffffff;
    display: flex;
    align-items: center;
    .simpleEdit_layout,
    .simpleEdit_schematicDiagram,
    .boardProperty_layout,
    .precisionAdjustment,
    .draw_layout,
    .draw_schematicDiagram,
    .complexEdit_layout,
    .complexEdit_schematicDiagram,
    .graphicOperation_schematicDiagram,
    .highlight_schematicDiagram,
    .coordinate_layout,
    .coordinate_schematicDiagram {
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
    }
    .precisionAdjustment {
      .inputArea {
        width: 100%;
      }
    }
    .inputArea {
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 50px;
        margin-right: 5px;
        .el-input__inner {
          background-color: #ffffff !important;
          height: 24px;
          width: 50px;
          text-align: center;
          padding: 0;
        }
      }
    }
    /deep/.el-checkbox__label {
      font-size: 12px;
      padding: 0 5px;
    }
    /deep/.el-checkbox__input {
      padding-left: 5px;
    }
    .toolbarIcon {
      margin: 0 5px;
      display: inline-block;
      position: relative;
      height: 30px;
      line-height: 30px;
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        height: 30px;
        display: flex;
        width: 24px;
        align-items: center;
        // display: flex;
        // align-items: center;
        justify-content: center;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .dropInfo {
        z-index: 1000;
        position: absolute;
        display: inline-block;
        top: 30px;
        left: 0;
        height: 24px;
        white-space: nowrap;
        padding: 0 10px;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
        background-color: #ffffff;
        // display: flex;
        align-items: center;
        div {
          display: inline-block;
        }
      }
      .dropMenu {
        position: absolute;
        top: 30px;
        left: 0;
        width: 111px;
        height: 57px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
        background-color: #ffffff;
        .dropItem {
          height: 57px;
          width: 101px;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .dropItem:hover {
          background-color: #d7e1f2;
          color: #0c7de6;
          height: 57px;
          width: 101px;
        }
      }
    }
  }
}
.middleLine {
  width: 1px;
  height: 25px;
  background: #999999;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
