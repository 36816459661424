<template>
  <div class="device" @click="handleAll" @mouseleave="handleLeave" onselectstart="javascript:return false;"
    style="min-width: 285px;">
    <div class="cardHeader">
      <!-- 头部标题 -->
      <div class="header-tabs">
        <div class="header-tabs__item">{{ $t("messages.Library") }}</div>
      </div>
      <div class="lines"></div>
    </div>
    <div class="device-menu">
      <div class="device-menu__menu">
        <img alt="" id="device-menu" class="menu-icon" @click="selectDeviceGroup=!selectDeviceGroup"
          @mouseover="selectDeviceGroup=true" height="18px" src="../../assets/icons/devices/器件库图标.svg">
        <span>{{$t("messages."+deviceTypeName)}}</span>
        <transition name="el-fade-in-linear">
          <div class="menu-icon__menu" id="menu-icon__menu" v-show="selectDeviceGroup">
            <div :class="{active: selectMineDevice, 'menu-icon__menu__item': true}" @click="mineDeviceClick(false)">
              {{ $t("messages.MyLibrary") }}</div>
            <div :class="{active: selectTeamDevice, 'menu-icon__menu__item': true}" @click="teamDeviceClick(false)">
              {{ $t("messages.TeamLibrary") }}</div>
            <div :class="{active: selectPublicDevice, 'menu-icon__menu__item': true}" @click="publicDeviceClick(false)">
              {{ $t("messages.PublicLibrary") }}
            </div>
            <div :class="{active: selectOfficialDevice, 'menu-icon__menu__item': true}"
              @click="officialDeviceClick(false)">
              {{ $t("messages.OfficialLibrary") }}</div>
            <div :class="{active: selectPDKDevice, 'menu-icon__menu__item': true}" @click="PDKDeviceClick(false)">
              {{ $t("messages.PDKLibrary") }}</div>
          </div>
        </transition>
      </div>
      <div style="z-index: 99;">
        <el-select class="input-with-select" style="max-height: 30px;overFlow-x: hidden;" v-model="selectSearchKeyType"
          slot="prepend" :placeholder="$t('messages.placeholder_0')" :disabled="!selectStatus">
          <el-option v-for="item in selectSearchKeyTypeOptions" :key="item.value" :label="$t('messages.'+item.label)"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input :placeholder="$t('messages.searchForKeyword')" v-model="searchKey" @keyup.enter.native="searchDevice"
          class="input-with-input">
        </el-input>
        <el-select size="mini" class="menu-select" v-model="selectTeam" v-if="selectTeamDevice" :loading="teamLoading"
          @change="changeGetDeviceTeamData">
          <el-option v-for="item in TeamOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" class="menu-select" v-model="selectComponentLibrary" v-if="selectMineDevice"
          @change="changeGetDeviceMineData">
          <el-option v-for="item in componentLibraryOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" class="menu-select" v-model="selectDeviceType" @change="changeDeviceType"
          :disabled="selectMineDevice || selectTeamDevice || selectPublicDevice">
          <el-option v-for="item in $t('messages.toolComponentOptions')" :key="String(item.value)" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <!-- <el-select size="mini" class="menu-select" v-model="selectType">
          <el-option
            v-for="item in selectTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" class="menu-select" v-model="selectDeviceType">
          <el-option
            v-for="item in selectDeviceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
      </div>
    </div>
    <div class="device-list">
      <div class="device-list__header">
        <div>{{$t('messages.ComponentName')}}</div>
      </div>
      <div id="device_list" class="infinite-list-wrapper" v-loading="deviceLoading">
        <ul style="overflow:auto;height:100%;" class="list" v-if="deviceList.length > 0" v-infinite-scroll="load"
          :infinite-scroll-disabled="deviceDisabled" infinite-scroll-distance="10"
          :infinite-scroll-immediate-check="false">
          <li v-for="i, k in deviceList" :class="{'list-item': true, clicked: selectIndex==k}" :key="k"
            @click="ClickItem(i, k)" @mouseover="MouseHoverItem(i)" @mouseleave="note=defaultNote"
            @dblclick="setDevice(i, k)">
            <div @contextmenu.prevent="onContextmenu($event,i)"
              :class="{'list-item__name': true, nopower: i.component_permission==1}"
              :title="i.component_permission==1?`${$t('messages.noDevicePower_1')}${i.team_name}${$t('messages.noDevicePower_2')}`:`${$t('messages.name')}：${i.name}\n${$t('messages.createTeam')}：${i.team_name}`"
              v-if="selectTeamDevice"><span :class="i.icon"></span>{{ i.name }}</div>
            <div class="list-item__name" @contextmenu.prevent="onContextmenu($event,i)"
              :title="i.display_name?`${$t('messages.name')}：${i.name}\n${$t('messages.Creator')}：${i.display_name}`: `${$t('messages.name')}：${i.name}\n${$t('messages.createTeam')}：${i.team_name}`"
              v-else-if="selectPublicDevice"><span :class="i.icon"></span>{{ i.name }}</div>
            <div class="list-item__name" :title="`${$t('messages.name')}：${i.name}`"
              @contextmenu.prevent="onOfficialContextmenu($event,i)" v-else-if="selectOfficialDevice">
              <span :class="i.icon"></span>{{ i.name }}
            </div>
            <div class="list-item__name" :title="`${$t('messages.name')}：${i.name}`"
              @contextmenu.prevent="onContextmenu($event,i)" v-else>
              <span :class="i.icon"></span>{{ i.name }}
            </div>
            <div class="list-item__button">
              <el-button @click="checkInfo(i)" :loading="getInfoLoading" type="text" size="small">
                {{$t('messages.Details')}}</el-button>
            </div>
          </li>
        </ul>
        <div v-if="deviceList.length==0" style="padding: 10px;color: #DDDDDD;font-size: 12px;text-align: center;">
          {{$t('messages.noData')}}
        </div>
        <!-- <p v-if="loading" style="font-size:12px;text-align:center;padding:5px;">加载中...</p>
        <p v-if="noMore" style="font-size:12px;text-align:center;padding:5px;">暂无更多数据</p> -->
      </div>
      <!-- <el-table :data="deviceList" :show-header="false" :height="tableHeight">
        <el-table-column prop="name"></el-table-column>
        <el-table-column fixed="right" width="50" align="center">
          <template slot-scope="scope">
            <el-button @click="checkInfo(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table> -->
    </div>
    <div class="device-note">
      <div class="device-note__header">
        <div>{{$t('messages.note')}}</div>
        <div class="arrow" @click="deviceNoteSeen=!deviceNoteSeen">
          <span class="el-icon-arrow-down" v-if="deviceNoteSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="device-note__body" v-show="deviceNoteSeen">
        <el-tooltip class="item" effect="dark" :content="note" placement="bottom">
          <div class="body-info" v-if="note">{{note}}</div>
          <div class="body-info" v-else>-</div>
        </el-tooltip>
        <div class="body-btn" @click="editNote" v-show="!selectOfficialDevice && selectDeviceType==1">
          {{$t('messages.edit')}}</div>
      </div>
    </div>
    <!-- <div class="device-mark">
      <div class="device-mark__header">
        <div>标注图</div>
        <div class="arrow" @click="deviceMarkSeen=!deviceMarkSeen">
          <span class="el-icon-arrow-down" v-if="deviceMarkSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="device-mark__body" v-show="deviceMarkSeen">
        <img alt="" class="mark-img" src="../../assets/personalCenter/无团队.jpg"/>
      </div>
    </div> -->
    <div class="device-2D">
      <div class="device-2D__header">
        <div class="Dheader-tabs">
          <div class="Dheader-tabs__item" @click="select2D=true, select3D=false">{{$t('messages.img_2D')}}</div>
          <!-- <div :class="{active: select2D, 'Dheader-tabs__item': true}" @click="select2D=true, select3D=false">2D图</div>
          <div :class="{active: select3D, 'Dheader-tabs__item': true}" @click="select2D=false, select3D=true">3D图</div> -->
        </div>
        <div class="arrow" @click="deviceDSeen=!deviceDSeen">
          <span class="el-icon-arrow-down" v-if="deviceDSeen"></span>
          <span class="el-icon-arrow-up" v-else></span>
        </div>
      </div>
      <div class="device-2D__body" v-show="deviceDSeen">
        <canvas id="device_mark" width="150" height="150"></canvas>
      </div>
    </div>
    <el-dialog :title="$t('messages.note')" :visible.sync="noteVisible" width="606px" :before-close="handleClose">
      <div class="note-edit-input">
        <el-input type="textarea" :placeholder="$t('messages.placeholder_1')" :rows="5" maxlength="128" show-word-limit
          v-model="noteCopy">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUpdateNote" :loading="UpdateNoteLoading">
          {{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="noteVisible = false" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.ComponentDetails')" :visible.sync="DeviceDialogVisible" width="726px"
      :before-close="handleClose" :modal="false" @opened="DeviceDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div class="Header-tabs__item">{{$t('messages.attrs')}}</div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectDeviceInfo">
            <div class="tool-main__normal">
              <div class="main-group2">
                <div class="main-label">{{$t('messages.name')}}：</div>
                <el-input style="width: 230px;" v-model="selectDeviceInfo.name" disabled></el-input>
              </div>
              <div class="main-group2">
                <div class="main-label">{{$t('messages.Rotate')}}：</div>
                <el-input-number style="width: 175px;" v-model="deviceConf.rotate" :controls="false"></el-input-number>
                &nbsp;<div style="font-size: 18px;display: inline-block;">°</div>
              </div>
            </div>
            <!-- <div class="inline-title">图层修改</div>
            <div class="tool-main__normal">
              <div class="main-group2" v-if="selectDeviceInfo">
                <div v-for="k, i in Object.keys(selectDevicePatams)" :key="i">
                  <div style="margin-bottom:10px;" v-if="k.indexOf('datatype')==-1 && k.indexOf('layer')!=-1">
                    <div class="main-label">{{k}}</div>
                    <el-select style="width: 230px;" v-model="selectDevicePatams.layer">
                      <el-option
                        v-for="item in layerOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        <div style="padding-left:10px;">{{item.label}}</div>
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div v-if="selectDevicePatams.tolerance">
              <div class="inline-title">圆弧精度修改</div>
              <div class="tool-main__normal">
                <div class="main-group2" v-if="selectDeviceInfo">
                  <div class="main-label">tolerance</div>
                  <el-input style="width: 230px;" v-model="selectDevicePatams.tolerance"></el-input>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding:10px;">{{$t('messages.img_2D')}}</div>
          </div>
          <div class="Ann_image__body" id="2d-canvas">
            <canvas id="ann-image"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="setDevice(false, false)">{{$t('messages.set')}}</el-button>
        <!-- <el-button type="sure" size="mini" @click="DeviceDialogVisible = true">应用</el-button>
        <el-button type="sure" size="mini" @click="DeviceDialogVisible = true">{{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="DeviceDialogVisible = false" size="mini">{{$t("messages.cancel")}}</el-button> -->
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.paramsComponentDetails')" :visible.sync="paramsDeviceDialogVisible" width="1100px"
      :before-close="handleClose" :modal="false" @opened="paramsDeviceDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div :class="{active: selectParamsDeviceAttrTab, 'Header-tabs__item': true}"
                @click="selectParamsDeviceAttrTab=true, selectParamsDeviceParamsTab=false">{{$t('messages.attrs')}}
              </div>
              <div :class="{active: selectParamsDeviceParamsTab, 'Header-tabs__item': true}"
                @click="selectParamsDeviceAttrTab=false, selectParamsDeviceParamsTab=true">{{$t('messages.params')}}
              </div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectParamsDeviceAttrTab && selectDeviceInfo">
            <div class="tool-main__normal">
              <div class="main-group2">
                <div class="main-label">{{$t('messages.name')}}：</div>
                <el-input style="width: 230px;" v-model="selectDeviceInfo.name" disabled></el-input>
              </div>
              <div class="main-group2">
                <div class="main-label">{{$t('messages.Rotate')}}：</div>
                <el-input-number style="width: 175px;" v-model="deviceConf.rotate" :controls="false"></el-input-number>
                &nbsp;<div style="font-size: 18px;display: inline-block;">°</div>
              </div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectParamsDeviceParamsTab && selectDeviceType==2">
            <div class="tool-main__normal">
              <div class="main-group2">
                <div v-if="pad_length">
                  <div class="main-label">{{$t('messages.totalLength')}}：</div>
                  <el-input-number style="width: 230px;" v-model="pad_length" :controls="false" disabled>
                  </el-input-number>&nbsp;um
                </div>
                <div v-for="k in Object.keys(selectParamsDeviceParams)" :key="k">
                  <div style="margin-bottom:10px;" v-if="k.indexOf('datatype')==-1 && k.indexOf('layer')==-1">
                    <div v-if="k.indexOf('tolerance')!=-1" title="数值越小越接近圆，数值越大越接近多边形">
                      <div class="main-label">
                        {{k}}({{$t('messages.tolerance')}})({{selectParamsDeviceParams[k].min}}~{{selectParamsDeviceParams[k].max}})
                      </div>
                      <el-input-number style="width: 230px;" :min="selectParamsDeviceParams[k].min"
                        :max="selectParamsDeviceParams[k].max" v-model="selectParamsDeviceParams[k].value"
                        :controls="false" @blur="changeParams"></el-input-number>&nbsp;um
                    </div>
                    <div v-else-if="k.indexOf('GapType')!=-1">
                      <div class="main-label">{{k}}</div>
                      <el-select v-model.trim="selectParamsDeviceParams[k].value" @change="changeParams"
                        style="width: 275px;">
                        <el-option v-for="item in selectParamsDeviceParams[k].options" :key="item.value"
                          :label="item.label" :value="item.value">
                          <div style="padding-left:10px;">{{item.label}}</div>
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      <div class="main-label">
                        {{k}}({{selectParamsDeviceParams[k].min}}~{{selectParamsDeviceParams[k].max}})</div>
                      <el-input-number style="width: 230px;" :min="selectParamsDeviceParams[k].min"
                        :max="selectParamsDeviceParams[k].max" v-model="selectParamsDeviceParams[k].value"
                        :controls="false" @blur="changeParams"></el-input-number>&nbsp;um
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="mark_img" class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding:10px;">标注图</div>
          </div>
          <div class="Ann_image__body">
            <div class="ann-image" v-loading="markImgLoading">
              <div v-for="k, v in mark_img_list" :key="v">
                <viewer>
                  <img alt="" :src="k">
                </viewer>
              </div>
            </div>
          </div>
        </div> -->
        <div class="Ann_image" style="margin-right: 10px;" v-if="selectDeviceInfo">
          <div class="Ann_image__header">
            <div style="padding:10px;">{{$t('messages.NoteFigure')}}</div>
          </div>
          <div class="Ann_image__body" v-if="selectDeviceInfo.mark_img && selectDeviceType==2">
            <div class="ann-image">
              <div style="cursor: pointer;" v-for="v, k in selectDeviceInfo.mark_img.mark_img" :key="k">
                <viewer>
                  <img alt="" :src="v">
                </viewer>
              </div>
            </div>
          </div>
        </div>
        <div class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding:10px;">{{$t('messages.img_2D')}}</div>
          </div>
          <div class="Ann_image__body" id="2d-canvas-params">
            <canvas id="device-ann-image"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="setDevice(false, false)">{{$t('messages.set')}}</el-button>
        <!-- <el-button type="sure" size="mini" @click="DeviceDialogVisible = true">应用</el-button>
        <el-button type="sure" size="mini" @click="DeviceDialogVisible = true">{{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="DeviceDialogVisible = false" size="mini">{{$t("messages.cancel")}}</el-button> -->
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyToVisible" width="570px" :before-close="handleClose"
      :modal="false" @opened="copyToOpened">
      <div class="dialog-body2">
        <div class="export-group" style="margin-bottom:20px;">
          <div class="group-txt">{{ $t('messages.Group') }}：</div>
          <div class="group-select">
            <el-select style="width: 400px" class="componentSelect" size="mini" v-model="copy_library_type">
              <el-option v-for="item in $t('messages.copyToTypeOptions')" :key="item.label" :label="item.label"
                :value="item.value" :disabled="!selectOfficialDevice && item.value==3 || item.value == 4"></el-option>
            </el-select>
          </div>
        </div>
        <div class="export-group" v-show="copy_library_type == 1">
          <div class="group-txt">{{ $t('messages.select') }}：</div>
          <div class="group-select">
            <el-select style="width: 400px" class="componentSelect" size="mini" v-model="copy_team_id">
              <el-option v-for="item in teamOptionList" :key="item.label" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="export-group" v-show="copy_library_type == 0">
          <div class="group-txt">{{ $t('messages.select') }}：</div>
          <div class="group-select">
            <div class="group-select">
              <el-select style="width: 400px" class="componentSelect" size="mini" v-model="copy_library_id">
                <el-option v-for="item in libraryOptions" :key="item.label" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" :loading="copyLoading" size="mini" @click="submitCopyDevice(null, null)"
          :disabled="(copy_library_type==0 && copy_library_id=='') || (copy_library_type==1 && copy_team_id=='')">{{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="cancelCopyDevice" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import bus from "../common/bus";
import { deepCopy } from "@/utils/utils";
import { getCellFunc, decompressPropStr } from "../../utils/getcell";
import { getPDKCellFunc } from "../../utils/pdk/getPDKCell.js";
import {
  getObjectDataFn,
  downloadFileData,
} from "@/components/homes/fileList/function/objectStorageFn";
import {
  fileKernelCell2Gdstk,
  renameCellName,
  cellToGDSu8,
} from "@/components/homes/fileList/function/fileListPublicFn";
const Device = require("../../utils/qeda-component.js");
const PDKDevice = require("../../utils/pdk/qeda-device_pdk.js");
var deviceCollection = new Device(); //NOSONAR
var pdkDeviceCollection = new PDKDevice(); //NOSONAR
const AutoToolCanvasComponent = require("../../utils/tools/canvas_component.js");
var canvas2D = new AutoToolCanvasComponent(); //NOSONAR
import {
  getDeviceMine,
  getDeviceTeam,
  getDevicePublic,
  getDeviceOfficial,
  updateDeviceNote,
  getDeviceInfo,
  getTeamList,
  deleteDevice,
  getLibraryList,
} from "@/api/api.js";
import {
  getTeamList_api,
  createMyComponent_api,
  createTeamComponent_api,
} from "@/api/file/file.js";
import {
  QedaLibrary,
  QedaCell, //NOSONAR
  QedaReference, //NOSONAR
  getLabelBox, //NOSONAR
} from "@/utils/graphic_f32/qeda-graphics";
import { getPDKInfo_api } from "@/api/objectStorage/objectStorage.js";
var timer = null;
const BigNumber = require("bignumber.js");
export default {
  data() {
    return {
      selectDevice: true,
      selectTeam: "",
      selectType: "超导",
      selectDeviceType: 1,
      selectSearchKeyType: 1,
      selectDeviceGroup: false,
      selectParamsDeviceGroup: false,
      selectMineDevice: true,
      selectTeamDevice: false,
      selectPublicDevice: false,
      selectOfficialDevice: false,
      selectPDKDevice: false,
      deviceTypeName: this.$t("messages.MyLibrary"),
      TeamOptions: [],
      selectSearchKeyTypeOptions: [
        { label: "Component", value: 1 },
        { label: "Creator", value: 2 },
      ],
      searchKey: "",
      deviceList: [],
      tableHeight: 490,
      note: "",
      defaultNote: "-",
      noteCopy: "",
      noteVisible: false,
      DeviceDialogVisible: false,
      paramsDeviceDialogVisible: false,
      select2D: true,
      select3D: false,
      deviceNoteSeen: true,
      deviceMarkSeen: true,
      deviceDSeen: true,
      next_page: false,
      loading: false,
      noMore: false,
      start: 0,
      rows: 30,
      deviceType: 1,
      deviceSearch: false,
      deviceLoading: false,
      selectStatus: true,
      selectIndex: null,
      selectDeviceInfo: null,
      getInfoLoading: false,
      UpdateNoteLoading: false,
      edit_component_type: 1,
      teamLoading: false,
      target_obj: null,
      deviceConf: { points: [0, 0], rotate: 0 },
      selectParamsDeviceParams: {},
      selectDevicePatams: {},
      selectParamsDeviceAttrTab: true,
      selectParamsDeviceParamsTab: false,
      all_layer: this.$store.state.layerDatas,
      deviceDisabled: true,
      pad_length: null,
      pdklib: null,
      selectComponentLibrary: "1",
      componentLibraryOptions: [],
      cell_node: null,
      currentDevice: null,
      copyToVisible: false,
      copy_library_type: 0,
      teamOptionList: [],
      copy_team_id: "",
      copy_library_id: "",
      libraryOptions: [],
      copyLoading: false,
    };
  },
  computed: {},
  methods: {
    handleClose(done) {
      this.deviceConf = { points: [0, 0], rotate: 0 };
      try {
        document.getElementById("ann-image").style.display = "none";
      } catch (error) {}
      try {
        document.getElementById("device-ann-image").style.display = "none";
      } catch (error) {}
      done();
    },
    async onContextmenu(event, node) {
      if (this.selectOfficialDevice) return;
      if (this.selectTeamDevice && node.component_permission !== 3) return;
      this.$contextmenu({
        items: [
          {
            label: this.$t("messages.copyTo"),
            icon: "iconfont icon-fuzhi",
            onClick: () => {
              this.copyToVisible = true;
              this.currentDevice = node;
            },
          },
          {
            label: this.$t("messages.delete"),
            onClick: async () => {
              if (this.selectMineDevice) {
                let params = {
                  component_type: 1,
                  snow_id: [node.snow_id],
                  team_id: "",
                };
                try {
                  let res = await deleteDevice(params);
                  if (res.code == 200000) {
                    this.$message.success(this.$t("messages.deleteSuccess"));
                    setTimeout(() => {
                      this.deviceList = [];
                      this.getDeviceMineData();
                    }, 1000);
                  }
                } catch {}
              }
              if (this.selectTeamDevice) {
                let params = {
                  component_type: 2,
                  snow_id: [node.snow_id],
                  team_id: node.team_id,
                };
                try {
                  let res = await deleteDevice(params);
                  if (res.code == 200000) {
                    this.$message.success(this.$t("messages.deleteSuccess"));
                    setTimeout(() => {
                      this.deviceList = [];
                      this.getDeviceTeamData();
                    }, 1000);
                  }
                } catch {}
              }
              if (this.selectPublicDevice) {
                let params = {
                  component_type: 3,
                  snow_id: [node.snow_id],
                  team_id: "",
                };
                try {
                  let res = await deleteDevice(params);
                  if (res.code == 200000) {
                    this.$message.success(this.$t("messages.deleteSuccess"));
                    setTimeout(() => {
                      this.deviceList = [];
                      this.getDevicePublicData();
                    }, 1000);
                  }
                } catch {}
              }
            },
            icon: "iconfont icon-shanchu",
          },
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 1000, // 菜单样式 z-index
        minWidth: 170, // 主菜单最小宽度
      });
    },
    onOfficialContextmenu(event, node) {
      if (this.selectOfficialDevice) return;
      this.$contextmenu({
        items: [
          {
            label: this.$t("messages.copyTo"),
            icon: "iconfont icon-fuzhi",
            onClick: () => {
              this.copyToVisible = true;
              this.currentDevice = node;
            },
          },
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 1000, // 菜单样式 z-index
        minWidth: 170, // 主菜单最小宽度
      });
    },
    async checkInfo(row) {
      if (this.selectTeamDevice && row.component_permission == 1) {
        return;
      }
      this.pad_length = null;
      this.getInfoLoading = true;
      this.currentDevice = row;
      if (this.selectDeviceType == 2) {
        if (this.selectOfficialDevice) {
          this.selectParamsDeviceParams = deepCopy(
            deviceCollection.getParams(row.function_name)
          );
        } else if (this.selectPDKDevice) {
          this.selectParamsDeviceParams = deepCopy(
            pdkDeviceCollection.getParams(row.function_name)
          );
        } else {
          this.selectParamsDeviceParams = deepCopy(row.render_attrs);
        }
        this.selectParamsDeviceAttrTab = true;
        this.selectParamsDeviceParamsTab = false;
        this.paramsDeviceDialogVisible = true;
      } else {
        this.DeviceDialogVisible = true;
      }
      this.selectDeviceInfo = await this.getDeviceInfoData(row);
      this.getInfoLoading = false;
    },
    // checkParamsInfo(row) {
    //   this.pad_length = null;
    //   if (this.selectDeviceType == 2) {
    //     this.selectParamsDeviceParams = deepCopy(
    //       deviceCollection.getParams(row.function_name)
    //     );
    //   } else if (this.selectPDKParamsDevice) {
    //     this.selectParamsDeviceParams = deepCopy(
    //       pdkDeviceCollection.getParams(row.function_name)
    //     );
    //   }

    //   this.selectParamsDeviceAttrTab = true;
    //   this.selectParamsDeviceParamsTab = false;
    //   this.getParamsDeviceInfoData(row);
    // },
    editNote() {
      if (this.selectIndex == null) {
        return;
      }
      this.noteCopy = deepCopy(this.note);
      this.noteVisible = true;
    },
    getTeamListData(start, rows) {
      let params = {
        start: start,
        rows: rows,
        key_word: "",
      };
      this.teamLoading = true;
      getTeamList(params).then((res) => {
        if (res.code / 1000 === 200) {
          let team_list = [];
          if (res.data.data.length > 0) {
            team_list = res.data.data.map((item) => {
              const ob = { selected: false };
              return Object.assign(item, ob);
            });
            this.TeamOptions = team_list.map((item) => {
              const dicts = {
                label: item.team_name,
                value: item.snow_team_id,
              };
              return dicts;
            });
          }
          this.TeamOptions.unshift({ label: "全部团队", value: "" });
        } else {
          this.$message.error(res.message);
        }
        this.teamLoading = false;
      });
    },
    submitUpdateNote() {
      this.noteVisible = false;
      this.note = this.noteCopy;
      if (this.noteCopy != this.deviceList[this.selectIndex].notes) {
        const params = {
          snow_id: this.deviceList[this.selectIndex].snow_id,
          component_type: this.edit_component_type,
          notes: this.noteCopy,
        };
        this.UpdateNoteLoading = true;
        updateDeviceNote(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.deviceList[this.selectIndex].notes = this.noteCopy;
            this.$message.success("更新成功");
          } else {
            this.$message.error(res.message);
          }
          this.UpdateNoteLoading = false;
        });
      }
    },
    changeGetDeviceMineData() {
      this.deviceList = [];
      this.start = 0;
      this.getDeviceMineData();
    },
    axiosMineData: _.debounce(async function (params) {
      await getDeviceMine(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data.map((item) => {
            return item._source;
          });
          this.deviceList = this.deviceList.concat(data_list);
          this.next_page = res.data.is_next;
          this.deviceSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    async getDeviceMineData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        cell_component_type:
          this.selectDeviceType == 0 ? [1, 2] : [this.selectDeviceType],
        library_snow_id: this.selectComponentLibrary,
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosMineData(params);
    },
    changeGetDeviceTeamData() {
      this.deviceList = [];
      this.start = 0;
      this.getDeviceTeamData();
    },
    axiosTeamData: _.debounce(async function (params) {
      await getDeviceTeam(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data.map((item) => {
            return item._source;
          });
          this.deviceList = this.deviceList.concat(data_list);
          this.next_page = res.data.is_next;
          this.deviceSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    getDeviceTeamData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        cell_component_type:
          this.selectDeviceType == 0 ? [1, 2] : [this.selectDeviceType],
        team_id: this.selectTeam,
        search_type: this.selectSearchKeyType,
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosTeamData(params);
    },
    axiosPublicData: _.debounce(async function (params) {
      await getDevicePublic(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data.map((item) => {
            return item._source;
          });
          this.deviceList = this.deviceList.concat(data_list);
          this.next_page = res.data.is_next;
          this.deviceSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    getDevicePublicData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        cell_component_type:
          this.selectDeviceType == 0 ? [1, 2] : [this.selectDeviceType],
        search_type: this.selectSearchKeyType,
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosPublicData(params);
    },
    async getPDK(proj_gns) {
      let pdk_res = await getPDKInfo_api({ project_snow_id: proj_gns });
      if (pdk_res.code === 200000) {
        let config = pdk_res.data.url_data;
        if (config.lib) {
          // 获取drf文件内容并解析为json
          let lib_u8 = await getObjectDataFn(config.lib);
          let lib_fr = new FileReader();
          lib_fr.readAsText(new Blob([lib_u8]));
          let result = new Promise((resolve) => {
            lib_fr.onload = (evt) => {
              resolve(evt);
            };
          });
          await result.then((evt) => {
            this.pdklib = JSON.parse(evt.target.result);
          });
        }
      } else {
        this.pdklib = null;
      }
    },
    axiosOfficialData: _.debounce(async function (params) {
      await getDeviceOfficial(params).then((res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data.map((item) => {
            return item._source;
          });
          this.deviceList = this.deviceList.concat(data_list);
          this.next_page = res.data.is_next;
          this.deviceSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    getDeviceOfficialData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        cell_component_type:
          this.selectDeviceType == 0 ? [1, 2] : [this.selectDeviceType],
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosOfficialData(params);
    },
    axiosPDKData: _.debounce(async function (params) {
      await getDeviceOfficial(params).then(async (res) => {
        if (res.code / 1000 === 200) {
          const data_list = res.data.data.map((item) => {
            return item._source;
          });
          // 加载PDK.Lib
          let proj_gns = this.$store.state.proInfo.projectId;
          await this.getPDK(proj_gns);
          this.deviceList = this.deviceList.concat(data_list);
          // 根据PDK.Lib过滤器件库列表
          let pdk_file = null;
          if (this.pdklib) {
            pdk_file =
              this.selectDeviceType == 2
                ? this.pdklib.ParaCell
                : this.pdklib.FixCell;
          }
          if (this.pdklib && pdk_file) {
            let validPDKDevice = this.deviceList.filter((device) =>
              pdk_file.includes(device.name)
            );
            this.deviceList = validPDKDevice;
          } else {
            this.deviceList = [];
          }
          this.next_page = res.data.is_next;
          this.deviceSearch = false;
          if (this.next_page) {
            this.loading = true;
            this.noMore = false;
            this.start = res.data.next;
            this.deviceDisabled = false;
          } else {
            this.loading = false;
            this.noMore = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.deviceLoading = false;
      });
    }, 500),
    async getDevicePDKData() {
      const params = {
        start: this.start,
        rows: this.rows,
        key_words: this.searchKey,
        cell_component_type:
          this.selectDeviceType == 1 ? [3] : [this.selectDeviceType],
      };
      this.deviceLoading = true;
      this.deviceDisabled = true;
      this.axiosPDKData(params);
    },
    async mineDeviceClick(status) {
      this.start = 0;
      this.selectDevice = true;
      this.selectDeviceGroup = false;
      this.selectMineDevice = true;
      this.selectTeamDevice = false;
      this.selectPublicDevice = false;
      this.selectOfficialDevice = false;
      this.selectPDKDevice = false;
      this.deviceTypeName = "MyLibrary";
      this.deviceType = 1;
      this.deviceList = [];
      this.deviceSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.edit_component_type = 1;
      this.selectSearchKeyType = 1;
      if (!status) {
        this.selectDeviceType = 1;
      }
      this.getDeviceMineData();
      let res_1 = await getLibraryList();
      if (res_1.code == 200000) {
        this.componentLibraryOptions = res_1.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
    },
    teamDeviceClick(status) {
      this.start = 0;
      this.selectDeviceGroup = false;
      this.selectMineDevice = false;
      this.selectTeamDevice = true;
      this.selectPublicDevice = false;
      this.selectOfficialDevice = false;
      this.selectPDKDevice = false;
      this.deviceTypeName = "TeamLibrary";
      this.deviceType = 1;
      this.deviceList = [];
      this.deviceSearch = true;
      this.next_page = true;
      this.selectStatus = true;
      this.selectIndex = null;
      this.note = "-";
      this.edit_component_type = 2;
      this.selectSearchKeyType = 1;
      if (!status) {
        this.selectDeviceType = 1;
      }
      this.getDeviceTeamData();
      this.getTeamListData(0, 100);
    },
    publicDeviceClick(status) {
      this.start = 0;
      this.selectDeviceGroup = false;
      this.selectMineDevice = false;
      this.selectTeamDevice = false;
      this.selectPublicDevice = true;
      this.selectOfficialDevice = false;
      this.selectPDKDevice = false;
      this.deviceTypeName = "PublicLibrary";
      this.deviceType = 1;
      this.deviceList = [];
      this.deviceSearch = true;
      this.next_page = true;
      this.selectStatus = true;
      this.selectIndex = null;
      this.note = "-";
      this.edit_component_type = 3;
      this.selectSearchKeyType = 1;
      if (!status) {
        this.selectDeviceType = 1;
      }
      this.getDevicePublicData();
    },
    officialDeviceClick(status) {
      this.start = 0;
      this.selectDeviceGroup = false;
      this.selectMineDevice = false;
      this.selectTeamDevice = false;
      this.selectPublicDevice = false;
      this.selectOfficialDevice = true;
      this.selectPDKDevice = false;
      this.deviceTypeName = "OfficialLibrary";
      this.deviceType = 1;
      this.deviceList = [];
      this.deviceSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.selectSearchKeyType = 1;
      if (!status) {
        this.selectDeviceType = 1;
      }
      this.getDeviceOfficialData();
    },
    PDKDeviceClick(status) {
      this.start = 0;
      this.selectDeviceGroup = false;
      this.selectMineDevice = false;
      this.selectTeamDevice = false;
      this.selectPublicDevice = false;
      this.selectOfficialDevice = false;
      this.selectPDKDevice = true;
      this.deviceTypeName = "PDKLibrary";
      this.deviceType = 1;
      this.deviceList = [];
      this.deviceSearch = true;
      this.next_page = true;
      this.selectStatus = false;
      this.selectIndex = null;
      this.note = "-";
      this.selectSearchKeyType = 1;
      if (!status) {
        this.selectDeviceType = 1;
      }
      this.getDevicePDKData();
    },
    load() {
      const value = this.deviceList[this.start];
      if (value) {
        return;
      }
      if (this.selectMineDevice) {
        if (this.next_page) {
          this.getDeviceMineData();
        }
      }
      if (this.selectTeamDevice) {
        if (this.next_page) {
          this.getDeviceTeamData();
        }
      }
      if (this.selectPublicDevice) {
        if (this.next_page) {
          this.getDevicePublicData();
        }
      }
      if (this.selectOfficialDevice) {
        if (this.next_page) {
          this.getDeviceOfficialData();
        }
      }
      if (this.selectPDKDevice) {
        if (this.next_page) {
          this.getDevicePDKData();
        }
      }
    },
    handleAll(e) {
      const foo = document.getElementById("device-menu");
      if (foo) {
        if (!foo.contains(e.target)) {
          this.selectDeviceGroup = false;
          this.selectParamsDeviceGroup = false;
        }
      }
    },
    handleLeave(e) {
      const foo = document.getElementById("menu-icon__menu");
      if (foo) {
        if (!foo.contains(e.target)) {
          this.selectDeviceGroup = false;
          this.selectParamsDeviceGroup = false;
        }
      }
    },
    searchDevice() {
      this.start = 0;
      this.deviceList = [];
      this.deviceSearch = true;
      this.next_page = true;
      this.load();
    },
    MouseHoverItem(row) {
      this.note = row.notes;
    },
    async getTargetObj(row, params) {
      let cell_obj = null;
      if (this.selectDeviceType == 2 && this.selectOfficialDevice) {
        if (!params) {
          this.selectParamsDeviceParams = deepCopy(
            deviceCollection.getParams(row.name)
          );
        }
        let file_gds_lib = this.getCell(row.name);

        file_gds_lib.write_gds(row.name);
        let data_u8 = window.QGdstk.FS.readFile(row.name);
        Kernel.FS.writeFile(row.name, data_u8);
      } else if (this.selectDeviceType == 2 && this.selectPDKDevice) {
        if (!params) {
          this.selectParamsDeviceParams = deepCopy(
            pdkDeviceCollection.getParams(row.name)
          );
        }
        let file_gds_lib = this.getPDKCell(row.name);

        file_gds_lib.write_gds(row.name);
        let data_u8 = window.QGdstk.FS.readFile(row.name);
        Kernel.FS.writeFile(row.name, data_u8);
      } else {
        this.selectDeviceInfo = await this.getDeviceInfoData(row);
        let data_u8 = await getObjectDataFn(this.selectDeviceInfo.file_url);

        Kernel.FS.writeFile(row.name, data_u8);
      }
      let file_lib = new Kernel.Library(row.name);
      file_lib.read_gds(row.name);

      cell_obj = file_lib.cells.filter((cell) => cell.name == row.name)[0];
      // cellId, targetCellList, currentFileNode

      let currentCellNode = this.$store.state.activeNode;
      let currentFileNode = currentCellNode.parent;
      let cell_refs = cell_obj.depend_cells(-1);

      cell_obj = renameCellName(
        cell_obj.snow_id,
        [cell_obj, ...cell_refs],
        currentFileNode
      );

      return cell_obj;
    },
    async updateTargetObj(row) {
      let cell_obj = null;
      let name = this.target_obj.name;
      if (this.selectOfficialDevice) {
        let file_gds_lib = this.getCell(row.name);

        file_gds_lib.write_gds(row.name);
        let data_u8 = window.QGdstk.FS.readFile(row.name);
        Kernel.FS.writeFile(row.name, data_u8);
      } else if (this.selectPDKDevice) {
        let file_gds_lib = this.getPDKCell(row.name);

        file_gds_lib.write_gds(row.name);
        let data_u8 = window.QGdstk.FS.readFile(row.name);
        Kernel.FS.writeFile(row.name, data_u8);
      } else {
        this.selectDeviceInfo = await this.getDeviceInfoData(row);
        let data_u8 = await getObjectDataFn(this.selectDeviceInfo.file_url);

        Kernel.FS.writeFile(row.name, data_u8);
      }
      let file_lib = new Kernel.Library(row.name);
      file_lib.read_gds(row.name);

      cell_obj = file_lib.cells.filter((cell) => cell.name == row.name)[0];
      cell_obj.name = name;
      return cell_obj;
    },
    async ClickItem(row, index) {
      if (
        this.currentDevice &&
        this.currentDevice.name == row.name &&
        this.currentDevice.cell_component_type == row.cell_component_type &&
        row.snow_id == this.currentDevice.snow_id
      ) {
        return;
      }
      if (timer) {
        clearTimeout(timer);
      }
      this.defaultNote = row.notes;
      this.selectIndex = index;
      this.currentDevice = row;
      timer = setTimeout(async () => {
        if (this.selectTeamDevice && row.component_permission == 1) {
          return;
        }
        this.target_obj = await this.getTargetObj(row, null);
        let gds_obj = fileKernelCell2Gdstk(this.target_obj);
        canvas2D.render(
          document.getElementById("device_mark"),
          gds_obj,
          gds_obj.name
        );
      }, 300);
    },
    async setDevice(row, index) {
      if (this.selectTeamDevice && row.component_permission == 1) {
        return;
      }
      if (timer) {
        clearTimeout(timer);
      }
      if (index !== false) {
        this.selectIndex = index;
        this.defaultNote = row.notes;

        this.target_obj = await this.getTargetObj(row, null);
      } else {
        this.target_obj = await this.getTargetObj(this.selectDeviceInfo, true);
      }
      this.DeviceDialogVisible = false;
      this.paramsDeviceDialogVisible = false;
      let gds_obj = fileKernelCell2Gdstk(this.target_obj);
      canvas2D.render(
        document.getElementById("device_mark"),
        gds_obj,
        gds_obj.name
      );
      let ref = new Kernel.Reference();
      ref.cell = this.target_obj;
      let rotation = this.applyAngleTransform(null, this.deviceConf.rotate);
      ref.rotation = rotation;
      ref.cell.pcell = new Kernel.PCell();
      let labels = ref.cell.labels;
      if (labels.length > 0) {
        for (let j in labels) {
          if (labels[j].get_gds_property(2)) {
            let label_attr = decompressPropStr(labels[j].get_gds_property(2));
            let params_json = {};
            params_json.function_name = label_attr.function_name;
            params_json.params = label_attr.params;
            params_json.is_params_device = label_attr.is_params_device;
            ref.cell.pcell.paras = JSON.stringify(params_json);
            break;
          }
        }
      }
      bus.$emit("addNewDevice", ref);
      this.deviceConf = { points: [0, 0], rotate: 0 };
    },
    applyAngleTransform(rotation, angle) {
      if (rotation && !angle) {
        let res = new BigNumber(rotation * (360 / (Math.PI * 2)));
        return Number(res.toFixed(0));
      } else if (!rotation && angle) {
        let res = new BigNumber(Math.PI * 2 * (angle / 360));
        return Number(res.toFixed(2));
      } else {
        return 0;
      }
    },
    async getDeviceInfoData(row) {
      let params = {
        snow_id: row.snow_id,
      };
      let res = await getDeviceInfo(params);
      let data = {};
      if (res.code === 200000) {
        data = res.data.data[0];
      }
      return data;
    },
    async getSelectInfo(row) {
      let res_info = null;
      let params = {
        snow_id: row.snow_id,
      };
      await getDeviceInfo(params).then((res) => {
        if (res.code / 1000 === 200) {
          res_info = res.data.data[0];
        }
      });
      return res_info;
    },
    async paramsDeviceDialogOpened() {
      this.target_obj = await this.getTargetObj(this.selectDeviceInfo, null);
      let img_element = document.getElementById("device-ann-image");
      if (!img_element) {
        let img_element_parent = document.getElementById("2d-canvas-params");
        img_element = document.createElement("canvas");
        img_element.setAttribute("id", "device-ann-image");
        img_element.style.margin = "auto";
        img_element_parent.appendChild(img_element);
      }
      img_element.style.display = "inline-block";

      img_element.style.width = 244 + "px";
      img_element.style.height = 244 + "px";
      img_element.setAttribute("width", "244");
      img_element.setAttribute("height", "244");
      let gds_obj = fileKernelCell2Gdstk(this.target_obj);

      canvas2D.render(img_element, gds_obj, gds_obj.name);
    },
    async DeviceDialogOpened() {
      this.target_obj = await this.getTargetObj(this.selectDeviceInfo, null);
      let img_element = document.getElementById("ann-image");
      if (!img_element) {
        let img_element_parent = document.getElementById("2d-canvas");
        img_element = document.createElement("canvas");
        img_element.setAttribute("id", "ann-image");
        img_element.style.margin = "auto";
        img_element_parent.appendChild(img_element);
      }
      img_element.style.display = "inline-block";
      img_element.style.width = 244 + "px";
      img_element.style.height = 244 + "px";
      img_element.setAttribute("width", "244");
      img_element.setAttribute("height", "244");
      let gds_obj = fileKernelCell2Gdstk(this.target_obj);
      canvas2D.render(img_element, gds_obj, gds_obj.name);
    },
    getParamsDeviceInfoData(row) {
      let params = {
        snow_id: row.snow_id,
      };
      this.getInfoLoading = true;
      getDeviceInfo(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.selectDeviceInfo = res.data.data[0];
          this.paramsDeviceDialogVisible = true;
          this.deviceConf = { points: [0, 0], rotate: 0 };
        } else {
          this.$message.error(res.message);
        }
        this.getInfoLoading = false;
      });
    },
    getCell(name) {
      // this.selectParamsDeviceParams = deepCopy(
      //   deviceCollection.getParams(name)
      // );
      let obj = getCellFunc(name, this.selectParamsDeviceParams, false);
      this.setPadLength(obj);
      return obj;
    },
    getPDKCell(name) {
      // this.selectParamsDeviceParams = deepCopy(
      //   pdkDeviceCollection.getParams(name)
      // );
      let obj = getPDKCellFunc(name, this.selectParamsDeviceParams, false);
      return obj;
    },
    async changeParams() {
      document.getElementById("device-ann-image").style.display =
        "inline-block";
      this.target_obj = await this.updateTargetObj(this.selectDeviceInfo);
      setTimeout(() => {
        let gds_obj = fileKernelCell2Gdstk(this.target_obj);
        canvas2D.render(
          document.getElementById("device-ann-image"),
          gds_obj,
          gds_obj.name
        );
      }, 500);
    },
    setPadLength(target_obj) {
      let real_labels = target_obj.cells[0].labels;
      real_labels.forEach((item) => {
        if (item.get_gds_property(4)) {
          this.pad_length = item.get_gds_property(4);
        }
      });
    },
    changeDeviceType() {
      if (this.selectMineDevice) {
        this.mineDeviceClick(true);
      } else if (this.selectTeamDevice) {
        this.teamDeviceClick(true);
      } else if (this.selectPublicDevice) {
        this.publicDeviceClick(true);
      } else if (this.selectOfficialDevice) {
        this.officialDeviceClick(true);
      } else if (this.selectPDKDevice) {
        this.PDKDeviceClick(true);
      }
    },
    async copyToOpened() {
      this.teamOptionList = await this.getTeamOptions();
      this.libraryOptions = await this.getLibraryOptions();
    },
    async getTeamOptions() {
      let all_data = [];
      let team_res = await getTeamList_api({
        start: 0,
        rows: 50,
        key_word: "",
      });
      if (team_res.code == 200000) {
        let team_objs = team_res.data.data;
        for (let i = 0; i < team_objs.length; i++) {
          let team_obj = {
            value: team_objs[i].snow_team_id,
            label: team_objs[i].team_name,
            gns: team_objs[i].gns,
            snow_id: team_objs[i].snow_team_id,
            children: [],
          };
          all_data.push(team_obj);
        }
      }
      return all_data;
    },
    async getLibraryOptions() {
      let all_data = [];
      let res = await getLibraryList();
      if (res.code == 200000) {
        all_data = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
      return all_data;
    },
    async submitCopyDevice(name, cell_obj) {
      let rename = function (name, rename_status) {
        if (rename_status) {
          let reg = /\((.+?)\)/g;
          let name_number_list = name.match(reg);

          if (!name_number_list) {
            return `${name}(1)`;
          } else {
            let name_pre = name.split(name.match(reg)[0])[0];
            let name_number = name_number_list.pop();
            let current_index = Number(name_number.split("(")[1].split(")")[0]);
            let current_new_name = `${name_pre}${name_number_list.join("")}(${
              current_index + 1
            })`;
            return current_new_name;
          }
        } else {
          return `${name}(1)`;
        }
      };
      this.copyLoading = true;
      this.selectDeviceInfo = await this.getDeviceInfoData(this.currentDevice);
      if (!cell_obj) {
        cell_obj = await this.getTargetObj(this.selectDeviceInfo, null);
      }
      cell_obj.name = name || this.currentDevice.name;
      let data_u8 = cellToGDSu8(cell_obj);
      let formData = new FormData();
      if (this.currentDevice.mark_img) {
        formData.append("mark_img", this.currentDevice.mark_img);
      }
      if (this.copy_library_type == 0) {
        formData.append("name", name || this.currentDevice.name);
        formData.append("unit", 1e-6);
        formData.append("precision", 1e-2);
        formData.append("label_list", this.currentDevice.label);
        formData.append("notes", this.currentDevice.notes);
        formData.append("content", new Blob([data_u8]));
        formData.append("is_cover", 0);
        formData.append(
          "cell_component_type",
          this.currentDevice.cell_component_type
        );
        formData.append("library_snow_id", this.copy_library_id);
        formData.append(
          "render_attrs",
          Object.keys(this.currentDevice.render_attrs).length > 0
            ? JSON.stringify(this.currentDevice.render_attrs)
            : "{}"
        );
        try {
          let res = await createMyComponent_api(formData);
          if (res.code == 200000) {
            if (res.data.name_exists && res.data.name_exists.length > 0) {
              let name_fb = name
                ? rename(name, true)
                : rename(this.currentDevice.name, false);
              await this.submitCopyDevice(name_fb, cell_obj);
            } else {
              this.$message.success(this.$t("messages.saveSuccess"));
              this.cancelCopyDevice();
            }
          }
        } catch (e) {
          this.copyLoading = false;
          this.$message.error(this.$t("messages.operationFailed"));
        }
        this.copyLoading = false;
      } else if (this.copy_library_type == 1) {
        formData.append("name", name || this.currentDevice.name);
        formData.append("unit", 1e-6);
        formData.append("precision", 1e-2);
        formData.append("label_list", this.currentDevice.label);
        formData.append("notes", this.currentDevice.notes);
        formData.append("content", new Blob([data_u8]));
        formData.append("is_cover", 0);
        formData.append(
          "cell_component_type",
          this.currentDevice.cell_component_type
        );
        formData.append("team_id", this.copy_team_id);
        formData.append(
          "render_attrs",
          Object.keys(this.currentDevice.render_attrs).length > 0
            ? JSON.stringify(this.currentDevice.render_attrs)
            : "{}"
        );
        try {
          let res = await createTeamComponent_api(formData);
          if (res.code == 200000) {
            if (res.data.name_exists && res.data.name_exists.length > 0) {
              let name_fb = name
                ? rename(name, true)
                : rename(this.currentDevice.name, false);
              await this.submitCopyDevice(name_fb, cell_obj);
            } else {
              this.$message.success(this.$t("messages.saveSuccess"));
              this.cancelCopyDevice();
            }
          }
        } catch {
          this.copyLoading = false;
          this.$message.error(this.$t("messages.operationFailed"));
        }
        this.copyLoading = false;
      }
    },
    cancelCopyDevice() {
      this.copyToVisible = false;
      this.copy_library_id = "";
      this.copy_library_type = 0;
      this.copy_team_id = "";
    },
  },
  created() {
    this.mineDeviceClick(false);
  },
  mounted() {
    this.layerOptions = this.all_layer.map((item) => {
      return { label: item.layerName, value: item.layerNumber };
    });
    bus.$on("initDeviceTab", (data) => {
      this.mineDeviceClick(false);
    });
    bus.$on("deviceVueUpdatePDK", async (project_gns) => {
      await this.getPDK(project_gns);
      if (this.selectPDKDevice) {
        this.start = 0;
        this.deviceTypeName = "PDKLibrary";
        this.deviceType = 1;
        this.deviceList = [];
        this.deviceSearch = true;
        this.next_page = true;
        this.selectStatus = false;
        this.selectIndex = null;
        this.note = "-";
        this.selectSearchKeyType = 1;
        await this.getDevicePDKData();
      }
    });

    bus.$on("setTreeNode", (data) => {
      this.cell_node = data;
    });
  },
  watch: {
    deviceList: {
      deep: true,
      handler(val) {
        if (val) {
          if (this.selectTeamDevice || this.selectMineDevice) {
            document.getElementById("device_list").style.height = "434px";
          } else {
            document.getElementById("device_list").style.height = "472px";
          }
        } else {
          if (this.selectTeamDevice || this.selectMineDevice) {
            document.getElementById("device_list").style.height = "384px";
          } else {
            document.getElementById("device_list").style.height = "452px";
          }
        }
      },
    },
    deviceNoteSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -30;
        } else {
          t_h = +30;
        }
        this.tableHeight += t_h;
        document.getElementById("device_list").style.height =
          this.tableHeight + "px";
      },
    },
    deviceMarkSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -150;
        } else {
          t_h = +150;
        }
        this.tableHeight += t_h;
        document.getElementById("device_list").style.height =
          this.tableHeight + "px";
      },
    },
    deviceDSeen: {
      deep: true,
      handler(val) {
        let t_h = 0;
        if (val) {
          t_h = -140;
        } else {
          t_h = 140;
        }
        this.tableHeight += t_h;
        document.getElementById("device_list").style.height =
          this.tableHeight + "px";
      },
    },
  },
};
</script>
<style lang="less" scoped>
.device {
  margin-top: 1px;
  border: 1px solid #dddddd;
}
.header-tabs {
  display: flex;
  width: 100%;
  background: #f4f4f4;
  border-bottom: 1px solid #dddddd;
  .header-tabs__item {
    padding: 7px;
    background: #f4f4f4;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }
  .active {
    background: #fff;
    position: relative;
    top: 1px;
  }
}
.device-menu {
  height: auto;
  padding-left: 10px;
  padding-top: 5px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
  .device-menu__menu {
    margin-bottom: 5px;
    max-height: 21px;
    span {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .menu-icon__menu {
      width: 151px;
      background: #ffffff;
      border: 1px solid #dddddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      z-index: 9999;
      position: absolute;
      margin-top: 3px;
      padding: 5px 0;
      .menu-icon__menu__item {
        background: #fefefe;
        padding: 8px 20px;
        font-size: 12px;
      }
      .menu-icon__menu__item:hover {
        background: #dce6f8;
        cursor: pointer;
      }
      .active {
        color: #0c7de6;
      }
    }
  }
  .menu-icon {
    vertical-align: middle;
    margin-right: 12px;
    cursor: pointer;
  }
  .menu-select {
    width: 93%;
    margin: 5px 0;
    /deep/ .el-input__inner {
      background: transparent !important;
      border-radius: 1px !important;
    }
  }
  .menu-input {
    width: 66%;
    margin-bottom: 5px;
    /deep/ .el-input__inner {
      background: transparent !important;
      border-radius: 4px !important;
    }
  }
  .default {
    cursor: pointer;
    background: transparent;
    border: 1px solid #0c7de6;
    border-radius: 4px;
    width: 58px;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: #0c7de6;
    margin-left: 12px;
  }
}
.clicked {
  background: #dce6f8;
}
.list-item {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  .list-item__name {
    display: inline-block;
    width: calc(100% - 45px);
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    text-indent: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: 4px;
  }
  .list-item__button {
    width: 45px;
    text-align: center;
    display: inline-block;
    box-shadow: -2px 0px 10px 0px rgba(0, 47, 132, 0.1);
  }
  .nopower {
    color: #dddddd !important;
  }
}
.list-item:hover {
  background: #dce6f8;
}
.device-list {
  .device-list__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    div {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding: 5px;
    }
  }
  /deep/ .el-table .el-table__cell {
    padding: 0 !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
}
.device-note {
  .device-note__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .arrow {
      position: absolute;
      top: 0;
      right: 8px;
      cursor: pointer;
    }
    div {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding: 5px;
    }
  }
  .device-note__body {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #dddddd;
    height: 30px;
    padding: 0 2px;
    .body-info {
      width: 83%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      top: -2px;
      color: #333333;
      padding-top: 8px;
      text-indent: 10px;
      cursor: auto;
    }
    .body-btn {
      background: #ffffff;
      box-shadow: -2px 0px 10px 0px rgba(0, 47, 132, 0.1);
      font-size: 14px;
      font-weight: 400;
      color: #0c7de6;
      padding: 5px 10px;
      cursor: pointer;
    }
  }
}
.device-mark {
  .device-mark__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .arrow {
      position: absolute;
      top: 0;
      right: 8px;
      cursor: pointer;
    }
    div {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding: 5px;
    }
  }
  .device-mark__body {
    text-align: center;
    border-bottom: 1px solid #dddddd;
    img {
      height: 120px;
    }
  }
}
.device-2D {
  .device-2D__header {
    background: #f4f4f4;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .arrow {
      position: absolute;
      top: 5px;
      right: 12px;
      cursor: pointer;
    }
    .Dheader-tabs__item {
      display: inline-block;
      // cursor: pointer;
      padding: 5px 6px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .device-2D__body {
    height: 150px;
    text-align: center;
    img {
      height: 125px;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 10px 20px !important;
  .dialog-body {
    display: flex;
  }
}
.note-edit-input {
  margin-top: 10px;
}
.el-button--cancel {
  background: #b0b0b0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.el-button--sure {
  background: #4385f2;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.input-with-select {
  width: 100px;
  height: 30px;
  display: inline-block;
  /deep/ .el-input__inner {
    height: 30px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #659cf7 !important;
    padding: 5px 10px !important;
    color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
  /deep/ .el-input-group__prepend {
    border: none !important;
  }
  /deep/ .el-input__prefix,
  .el-input__suffix {
    height: 30px;
  }
  /deep/ .el-select__caret {
    color: #fff !important;
  }
  /deep/ .el-input__suffix {
    top: 5px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }
  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
}
.input-with-input {
  width: 170px;
  height: 30px;
  position: relative;
  display: inline-block;
  left: -2px;
  /deep/ .el-input__inner {
    height: 30px;
    font-size: 12px !important;
    background: transparent;
    padding: 5px 10px !important;
    border-radius: 0px 5px 5px 0px;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;
  max-height: 770px;
  height: auto;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .inline-title {
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 10px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rile-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input-number {
            width: auto;
          }
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 35%;
        }
        /deep/ .el-input-number {
          width: auto;
        }
        .el-input {
          width: auto;
        }
        /deep/ .el-input__inner {
          width: 60px;
          height: 28px;
          line-height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
      .main-group2 {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 35%;
        }
        .el-input {
          width: 73%;
        }
        /deep/ .el-input__inner {
          height: 34px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          display: inline-block;
        }
        /deep/ .el-input__inner[disabled],
        input:disabled,
        input.disabled {
          background: #cccccc !important;
          -webkit-opacity: 1;
          opacity: 1;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input {
          width: 300px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  min-height: 330px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  overflow-y: auto;
  #ann-image {
    margin: auto;
  }
  #device-ann-image {
    margin: auto;
  }
  .ann-image {
    width: 100%;
    margin: auto;
  }
}
.el-select-dropdown__item {
  padding-left: 5px !important;
}
.export-group {
  width: 100%;
  display: flex;
  margin: 10px;
  font-size: 13px;
  color: #333333;
  align-items: center;
  justify-content: space-between;
  // .group-txt {
  //   width: 20%;
  //   position: relative;
  //   top: 6px;
  // }
}
</style>
